// ReusableComponent.js
import React from "react";

import { Box } from "@mui/material";
import googlePlay from "../../assets/Images/gApp.svg";
import applePlay from "../../assets/Images/appleApp.svg";
import { APP_LINKS } from "../layout/socialLinks";

const StoreButtons = () => {
  return (
    <Box
      style={{
        display: "flex",
        gap: "8px",
      }}
    >
      <img
        src={googlePlay}
        alt="Google Play"
        width="140px"
        style={{
          cursor: "pointer",
          display: "block",
          zIndex: 10,
        }}
        onClick={() => window.open(APP_LINKS.googlePlay, "_blank")}
      />
      <img
        src={applePlay}
        alt="App Store"
        width="140px"
        style={{
          cursor: "pointer",
          display: "block",
          zIndex: 10,
        }}
        onClick={() => window.open(APP_LINKS.appleStore, "_blank")}
      />
    </Box>
  );
};

export default StoreButtons;
