import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Divider,
  Stack,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  Dialog,
} from "@mui/material";
import Stock from "./components/Stock";
import InfoList from "./components/InfoList";
import Profile from "./components/Profile";
import ButtonsGroup from "./components/ButtonsGroup";
import ImageGallery from "./components/ImageGallery";
import TabsComponent from "./components/TabsComponent";
import { FaLocationDot } from "react-icons/fa6";
import { message } from "antd";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../configs/firebase";
import { IoIosHeart } from "react-icons/io";
import { CiHeart } from "react-icons/ci";
import { BiDotsVerticalRounded } from "react-icons/bi";
import useFormattedDate from "../../utils/useFormattedDate";
import {
  setCurrentProduct,
  setGoBackEditService,
} from "../../redux/slices/appStateSlice";
import { useDispatch } from "react-redux";
import LocationMapModal from "../../components/common/LocationMapModal";
import DeleteBox from "../../components/common/DeleteBox";

const ProductDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = location.pathname;
  const pathParts = path.split("/");
  const pathName = pathParts[pathParts.length - 2];
  const currentProduct = useSelector((state) => state.appState.currentProduct);
  const [categoryName, setCategoryName] = useState(
    location.state?.nameToDisplay || ""
  );
  const [product, setProduct] = useState(currentProduct);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const formattedDate = useFormattedDate(currentProduct?.createdAt);
  const registeredSince = currentProduct?.createdAt ? formattedDate : "N/A";
  const [addressPath, setAddressPath] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [favoriteClicked, setFavoriteClicked] = useState(false);
  const user = useSelector((state) => state.appState.userData);
  const [isMapOpen, setMapOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down(1124));
  // Confirmation Dialog State
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (
      user &&
      !(Array.isArray(user) && user?.length === 0) &&
      typeof userData === "object" &&
      user?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  useEffect(() => {
    setProduct(currentProduct);
  }, [id, currentProduct]);
  
  useEffect(() => {
    const fetchFavoriteStatus = async () => {
      if (!user || !user.uid) return;
      try {
        const userFavoritesRef = collection(
          db,
          "users",
          user.uid,
          "favoriteProducts"
        );
        const q = query(userFavoritesRef, where("productModel.id", "==", id));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setFavoriteClicked(true);
        }
      } catch (error) {
        console.error("Error fetching favorite status: ", error);
      }
    };

    fetchFavoriteStatus();
  }, [user, id]);

  useEffect(() => {
    if (pathName === "service-details") {
      setAddressPath(
        `${currentProduct?.businessAddress?.addressLineOne || ""}${
          currentProduct?.businessAddress?.city
            ? `, ${currentProduct?.businessAddress?.city}`
            : ""
        }${
          currentProduct?.businessAddress?.state
            ? `, ${currentProduct?.businessAddress?.state}`
            : ""
        }${
          currentProduct?.businessAddress?.country
            ? `, ${currentProduct?.businessAddress?.country}`
            : ""
        }`
      );
    } else {
      setAddressPath(
        `${currentProduct?.shippingAddress?.addressLineOne || ""}${
          currentProduct?.shippingAddress?.city
            ? `, ${currentProduct?.shippingAddress?.city}`
            : ""
        }${
          currentProduct?.shippingAddress?.state
            ? `, ${currentProduct?.shippingAddress?.state}`
            : ""
        }${
          currentProduct?.shippingAddress?.country
            ? `, ${currentProduct?.shippingAddress?.country}`
            : ""
        }`
      );
    }
  }, [currentProduct, pathName]);

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      if (!isLoggedIn) return;

      const userFavoritesRef = collection(
        db,
        "users",
        user.uid,
        "favoriteProducts"
      );
      const q = query(
        userFavoritesRef,
        where("productModel.id", "==", currentProduct?.id)
      );
      const querySnapshot = await getDocs(q);

      setFavoriteClicked(!querySnapshot.empty);
    };

    checkFavoriteStatus();
  }, [currentProduct?.id, isLoggedIn, user?.uid]);
    
  const handleAddToFavorite = async () => {
    if (!isLoggedIn) {
    
      message.error("You need to be logged in to add items to favorites.");
      navigate('/login')
      return;
    }

    const userFavoritesRef = collection(
      db,
      "users",
      user.uid,
      "favoriteProducts"
    );
    const q = query(
      userFavoritesRef,
      where("productModel.id", "==", currentProduct.id)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docId = querySnapshot.docs[0].id;
      await deleteDoc(doc(userFavoritesRef, docId));
      setFavoriteClicked(false);
      message.success("Item removed from favorites!");
    } else {
      const { _highlightResult, objectID, path, ...itemDataWithoutHighlight } =
        currentProduct;
      const productModel = {
        ...itemDataWithoutHighlight,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(userFavoritesRef, { productModel });
      setFavoriteClicked(true);
      message.success("Item added to favorites!");
    }
  };

  const fetchProductOrService = async () => {
    setIsLoading(true);
    try {
      const collectionName =
        pathName === "service-details" ? "services" : "products";
      const docRef = doc(db, collectionName, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const fetchedProduct = { id: docSnap.id, ...docSnap.data() };
        setProduct(fetchedProduct);
        dispatch(setCurrentProduct(fetchedProduct));
      } else {
        console.error("Product not found  .");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      message.error("Error fetching product details.");
    } finally {
      setIsLoading(false);
    }
  };

  const getCategoryName = async () => {
    try {
      setIsLoading(true);
      const collectionName =
        pathName === "service-details"
          ? "servicesCategories"
          : "itemCategories";
      const ref = await db.collection(collectionName).get();
      const mappedItems = ref.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
      const category = mappedItems.find(
        (item) => item.id === currentProduct?.categoryId
      );
      const result = category ? category.name : null;
      setCategoryName(result);
    } catch (error) {
      console.error("Error Get category Name: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoryName();
  }, [id, currentProduct]);

  useEffect(() => {
    if (id !== currentProduct?.id) {
      fetchProductOrService();
    } else {
      setProduct(currentProduct);
      setIsLoading(false);
    }
  }, [currentProduct, id, pathName]);

  if (!product || isLoading) {
    return <Loader />;
  }

  console.log(currentProduct, "filteredUser");

  const handleOpenMap = () => setMapOpen(true);
  const handleCloseMap = () => setMapOpen(false);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentId(null);
  };
  const handleUpdate = () => {
    if (pathName === "service-details") {
      dispatch(setGoBackEditService(false));
      navigate(`/selling/edit-service/${currentId}`);
    } else {
      navigate(`/selling/edit-item/${currentId}`);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleClose();
  };

  const handleDeleteButtonClick = () => {
    setDeleteModalVisible(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteLoading(true);
    try {
      const collectionName =
        pathName === "service-details" ? "services" : "products";
      const docRef = doc(db, collectionName, currentProduct.id);
      await updateDoc(docRef, {
        isDeleted: true,
        deletedAt: serverTimestamp(),
      });

      message.success("Item deleted successfully");

      setDeleteModalVisible(false);
      navigate("/selling");
    } catch (error) {
      message.error("Error deleting item");
      console.error("Error deleting item: ", error);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <Box as="section" paddingX={3}>
      {product ? (
        <Grid
          container
          alignItems="flex-start"
          sx={{ fontFamily: theme.typography.fontFamily }}
        >
          <Grid item xs={12} lg={5} marginBottom={{ xs: 2, md: 2, lg: 0 }}>
            <ImageGallery currentProduct={currentProduct} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={7}
            sx={{
              borderRadius: "8px",
              backgroundColor: theme.palette.primary.light,
              width: "49rem",
              padding: "25px",
            }}
          >
            <Box flexDirection="column" display="flex" gap={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                  padding: "0px 10px 0 10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.primary.light,
                    fontSize: "14px",
                    background: theme.palette.status.background,
                    borderRadius: "50px",
                    padding: "6px",
                  }}
                >
                  {categoryName}
                </Typography>
                {currentProduct?.uid === user?.uid && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      position: "relative",
                      top: isMediumScreen ? "23px" : "0",
                      zIndex: 3,
                    }}
                  >
                    <Box />
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) =>
                        handleClick(event, currentProduct?.id)
                      }
                      sx={{
                        background: "transparent",
                        borderRadius: "50%",
                        padding: "0",
                        minWidth: "auto",
                      }}
                    >
                      <BiDotsVerticalRounded
                        style={{
                          fontSize: "30px",
                          color: theme.palette.primary.darkColor,
                        }}
                      />
                    </Button>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{
                        "& .MuiPaper-root": {
                          boxShadow: `3px 3px 5px -2px ${theme.palette.customShadows.light}`,
                        },
                      }}
                    >
                      <MenuItem onClick={handleUpdate}>Update</MenuItem>
                      <MenuItem onClick={handleDeleteButtonClick}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
                {/* Confirmation Dialog */}
                <Dialog
                  open={isDeleteModalVisible}
                  onClose={handleCloseDeleteDialog}
                  aria-labelledby="delete-dialog-title"
                  aria-describedby="delete-dialog-description"
                >
                  <DeleteBox
                    itemToDelete={
                      pathName === "service-details" ? "service" : "product"
                    }
                    isLoading={isDeleteLoading}
                    onCancel={handleCloseDeleteDialog}
                    onDelete={handleConfirmDelete}
                  />
                </Dialog>
              </Stack>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                  },
                }}
                flexDirection="column"
                display="flex"
                justifyContent="space-between"
                gap={1}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <FaLocationDot />
                  <Typography
                    variant="caption"
                    onClick={handleOpenMap}
                    sx={{ cursor: "pointer" }}
                  >
                    Address: {addressPath} <br />{" "}
                    {currentProduct?.shippingAddress?.addressLineTwo &&
                      `Apt/Suite: ${currentProduct?.shippingAddress?.addressLineTwo}`}
                  </Typography>
                  <LocationMapModal
                    open={isMapOpen}
                    onClose={handleCloseMap}
                    lat={product?._geoloc?.lat}
                    lng={product?._geoloc?.lng}
                    address={
                      pathName === "service-details"
                        ? currentProduct?.businessAddress
                        : currentProduct?.shippingAddress
                    }
                  />
                </Stack>
                {currentProduct?.createdAt && (
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "capitalize",
                      color: theme.palette.primary.darkMain,
                    }}
                  >
                    {registeredSince}
                  </Typography>
                )}

                <Typography
                  variant="h2"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "700",
                    lineHeight: {
                      md: "37.5px",
                      xs: "20px",
                    },
                    fontSize: {
                      md: "25px",
                      xs: "18px",
                    },
                    width: {
                      md: "100%",
                      xs: "80%",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  {currentProduct?.title}
                </Typography>

                <Box
                  sx={{
                    paddingX: "16px",
                    paddingY: "3px",
                    borderRadius: "4px",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                    minWidth: "3.75rem",
                    width: "fit-content",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "700" }}>
                    {currentProduct?.contactForPrice === true
                      ? "Contact for Price"
                      : `$${currentProduct?.price}`}
                  </Typography>
                </Box>

                <Stock pathName={pathName} currentProduct={currentProduct} />
              </Box>
              <Divider />
              {pathName !== "service-details" && (
                <>
                  <InfoList
                    pathName={pathName}
                    currentProduct={currentProduct}
                  />
                  <Divider />{" "}
                </>
              )}
              <Profile pathName={pathName} currentProduct={currentProduct} />
              <Divider />
              <ButtonsGroup
                key={user}
                pathName={pathName}
                currentProduct={currentProduct}
                user={user}
              />
              {!currentProduct?.isSold &&
                !currentProduct?.isDraft &&
                !currentProduct?.contactForPrice && (
                  <>
                    <Divider />
                    {pathName !== "service-details" && (
                      <Stack
                        sx={{
                          width: {
                            xs: "100%",
                            md: "37.5rem",
                          },
                          cursor: "pointer",
                        }}
                        direction={{ xs: "column", md: "row" }}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack
                          onClick={handleAddToFavorite}
                          direction="row"
                          marginBottom={{ xs: 2, md: 0 }}
                          gap={1}
                          alignItems="center"
                        >
                          {favoriteClicked ? (
                            <IoIosHeart color="red" size={24} />
                          ) : (
                            <CiHeart size={24} />
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              textTransform: "capitalize",
                              color: theme.palette.primary.darkMain,
                            }}
                          >
                            add to wishlist
                          </Typography>
                        </Stack>

                        {/* <Stack  direction="row" gap={1} alignItems="center" >
                   <Typography variant="body2" sx={{textTransform:"capitalize",color:theme.palette.primary.darkMain}}>
                     share product:
                   </Typography>
                   <PiCopyLight color={theme.palette.neutral.darkBlueGrey} />
                   <FaFacebook color={theme.palette.primary.main}/>
                   <RiTwitterXLine color={theme.palette.neutral.darkBlueGrey} />
                   <FaPinterestP color={theme.palette.neutral.darkBlueGrey} />
                 </Stack> */}
                      </Stack>
                    )}
                  </>
                )}
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ marginY: "30px" }}>
            <TabsComponent
              pathName={pathName}
              currentProduct={currentProduct}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography
          variant="h4"
          component="h1"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          data not found
        </Typography>
      )}
    </Box>
  );
};

export default ProductDetails;
