import React, { useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FaStar } from "react-icons/fa";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../configs/firebase";

const Ratings = ({ currentUser }) => {
  const theme = useTheme();
  const [ratingPercentages, setRatingPercentages] = useState({
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  });

  useEffect(() => {
    const fetchRatings = async () => {
      const reviewsCollectionRef = collection(
        db,
        "users",
        currentUser?.uid,
        "reviews"
      );

      const reviewDocs = await getDocs(reviewsCollectionRef);
      const ratingsCount = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
      let totalReviews = 0;

      reviewDocs.forEach((doc) => {
        const rating = doc.data().rating;
        if (ratingsCount[rating] !== undefined) {
          ratingsCount[rating] += 1;
          totalReviews += 1;
        }
      });

      const calculatedPercentages = {};
      for (let star = 1; star <= 5; star++) {
        calculatedPercentages[star] =
          totalReviews > 0 ? (ratingsCount[star] / totalReviews) * 100 : 0;
      }
      setRatingPercentages(calculatedPercentages);
    };

    if (currentUser?.uid) {
      fetchRatings();
    }
  }, [currentUser]);

  const renderStars = (count) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <FaStar
          key={i}
          style={{
            color:
              i < count
                ? theme.palette.status.star
                : theme.palette.gray.overlayGray,
            width: "25px",
            height: "25px",
            marginRight: "5px",
          }}
        />
      );
    }
    return stars;
  };

  return (
    <Stack
      sx={{
        padding: 2,
        backgroundColor: theme.palette.primary.light,
        borderRadius: "10px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
        width: { xs: "100%", sm: "70%", xl: "50%" },
        margin: "0 auto",
      }}
      direction={{ xs: "column", sm: "row" }}
      gap={2}
    >
      <Stack>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: theme.palette.dark.charcoalBlack,
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          Rating
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", color: theme.palette.dark.colorCart }}
        >
          Overall Rating
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            color: theme.palette.primary.main,
            textAlign: "center",
          }}
        >
          {currentUser?.reviewsRate?.toFixed(1)}
        </Typography>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          {renderStars(Math.round(currentUser?.reviewsRate))}
        </Box>

        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: theme.palette.gray.overlayGray }}
        >
          Based on {currentUser?.reviewsCount} ratings
        </Typography>
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        {[5, 4, 3, 2, 1].map((star) => (
          <Box
            key={star}
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 1,
              padding: "0 15px",
              mb: 3,
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, color: theme.palette.gray.overlayGray }}
            >
            {star} {star === 1 ? "Star" : "Stars"}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={ratingPercentages[star]}
              sx={{
                flexGrow: 1,
                height: "20px",
                margin: "0 10px",
                borderRadius: "5px",
                backgroundColor: theme.palette.neutral.rating,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            />
            <Typography variant="body2" sx={{ width: "30px" }}>
              {ratingPercentages[star]?.toFixed(0)}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default Ratings;
