import React, { useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { collection, doc, setDoc, serverTimestamp } from "firebase/firestore";
import { message as antdMessage } from "antd";
import { auth, db } from "../../configs/firebase";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Field, Form, Formik } from "formik";
import Loader from "../../components/common/Loader";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import LocationMapModal from "../../components/common/LocationMapModal";
import { BASE_URL } from "../../components/layout/constants";

export const SendContactUs = async (title, messages) => {
  try {
    const currentUser = auth.currentUser;
    const email = auth.currentUser.email;

    const message = messages;

    if (!currentUser) throw new Error("User is not authenticated");

    const userToken = await currentUser.getIdToken();
    const url = `${BASE_URL}userApi/helpCenterRequestEmail`;
    const headers = {
      "Content-Type": "application/json",
      authorization: userToken,
    };

    const body = JSON.stringify({ email, title, message });
    const response = await fetch(url, {
      method: "POST",
      headers,
      body,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to send the request.");
    }
    const responseData = await response.json();
    if (responseData?.isSuccess) {
      antdMessage.success(
        responseData?.message || "Help center request sent successfully."
      );
    } else {
      antdMessage.error(responseData?.message || "Unknown error occurred.");
    }
  } catch (error) {
    console.error("Error sending help center request:", error);
    antdMessage.error(`Error: ${error.message}`);
  }
};

const ContactUS = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isMapOpen, setMapOpen] = useState(false);
  const phoneNumber = "+1 (708) 807-0018";
  const inputStyles = {
    label: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "21px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: theme.palette.primary.Charcoal,
    },

    btn: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22.4px",
      textAlign: "center",
      padding: "13px 40px",
      borderRadius: "10px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "170px",
      height: "50px",
    },
  };

  const initialFormData = {
    name: "",
    email: "",
    title: "",
    message: "",
  };

  const currentSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);

    const { title, message } = values;

    try {
      await SendContactUs(title, message);
      resetForm();
    } catch (error) {
      console.error("Error submitting contact us form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenMap = () => setMapOpen(true);
  const handleCloseMap = () => setMapOpen(false);

  return (
    <Grid container spacing={5} justifyContent="center">
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          margin: {
            xs: "0 10px",
            md: "0",
          },
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontFamily: theme.typography.headerFont,
            fontSize: "1.5rem",

            marginBottom: "15px",
            textAlign: "left",
            color: theme.palette.primary.Charcoal,
          }}
        >
          Send us a message
        </Typography>
        <Formik
          initialValues={initialFormData}
          validationSchema={currentSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <label style={inputStyles.label}>
                  Title <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  name="title"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, " ");

                    const formattedValue = inputValue
                      .replace(/(?:^|\.\s+)(\w)/g, (match) =>
                        match.toUpperCase()
                      )
                      .replace(/^\w/, (match) => match.toUpperCase());

                    setFieldValue("title", formattedValue);
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
                {errors.title && touched.title ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.title}
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <label style={inputStyles.label}>
                  Message <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  as="textarea"
                  name="message"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, " ");

                    const formattedValue = inputValue
                      .replace(/(?:^|\.\s+)(\w)/g, (match) =>
                        match.toUpperCase()
                      )
                      .replace(/^\w/, (match) => match.toUpperCase());

                    setFieldValue("message", formattedValue);
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    height: "130px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
                {errors.message && touched.message ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.message}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <button
                  type="submit"
                  style={{
                    ...inputStyles.btn,
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.Charcoal,
                    border: `1px solid ${theme.palette.primary.Charcoal}`,
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader color={theme.palette.primary.light} />
                  ) : (
                    "Send Message"
                  )}
                </button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          margin: {
            xs: "0 10px",
            md: "0",
          },
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontFamily: theme.typography.headerFont,
            fontSize: "1.5rem",

            marginBottom: "15px",
            textAlign: "left",
            color: theme.palette.primary.Charcoal,
            textTransform: "capitalize",
          }}
        >
          contact information
        </Typography>
        <Box
          sx={{
            background: theme.palette.primary.light,
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "center",
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <FaLocationDot
              style={{
                marginRight: "20px",
                fontSize: "30px",
                marginBottom: "15px",
              }}
            />
            <Typography
              variant="body1"
              onClick={handleOpenMap}
              sx={{ marginBottom: "15px", cursor: "pointer" }}
            >
              617 58th street Kenosha, 53140
            </Typography>
            <LocationMapModal
              open={isMapOpen}
              onClose={handleCloseMap}
              lat={42.58294}
              lng={-87.8187}
              address={{
                addressLineOne: "617 58th street",
                city: "Kenosha",
                state: "Wisconsin",
                country: "USA",
              }}
            />
          </Box>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "center",
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <FaPhoneAlt
              style={{
                marginRight: "20px",
                fontSize: "30px",
                marginBottom: "15px",
              }}
            />
            <Typography variant="body1" sx={{ marginBottom: "15px" }}>
              <a
                href={`tel:${phoneNumber}`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                {phoneNumber}
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoMdMail
              style={{
                marginRight: "20px",
                fontSize: "30px",
                marginBottom: "15px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                marginBottom: "15px",
                color: theme.palette.dark.charcoalBlack,
              }}
            >
              {/* ayoung7550@aol.com */}
              <a
                href="mailto:andy@truckpartsmart.com"
                style={{
                  textDecoration: "none",
                  color: theme.palette.dark.charcoalBlack,
                }}
              >
                andy@truckpartsmart.com
              </a>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactUS;
