import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import StatusDisplay from "../../../../../components/common/StatusDisplay";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { IoWalletOutline } from "react-icons/io5";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import DisclaimerDialog from "./DisclaimerDialog";
import PayoutSuccessDialog from "./PayoutSuccessDialog";

const TransactionsTableRow = ({
  order,
  theme,
  activeTab,
  ordersLoading,
  handleCollectMoney,
  isSuccessDialogOpen,
  setIsSuccessDialogOpen,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [openDisclaimerDialog, setOpenDisclaimerDialog] = useState(false);

  const goToSellerPage = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/sellers/seller-details/${id}`);
  };

  const handleOpenDisclaimerDialog = () => {
    setOpenDisclaimerDialog(true);
  };

  const handleCloseDisclaimerDialog = () => {
    setOpenDisclaimerDialog(false);
  };

  // Handle confirmation dialog actions
  const handleOpenConfirmDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };
  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
  };
  const getTotalColor = () => {
    if (activeTab === "seller") {
      return theme?.palette.neutral.MoustacheGrey;
    } else if (activeTab === "buyer" && order?.total) {
      return theme?.palette.status.darkRed;
    } else if (activeTab === "buyer" && order?.refundedAmount) {
      return theme?.palette.status.darkGreen;
    } else {
      return theme?.palette.neutral.MoustacheGrey;
    }
  };
  return (
    <TableRow>
      <TableCell
        sx={{
          color: theme?.palette.neutral.MoustacheGrey,
          fontSize: "14px",
          textTransform: "capitalize",
        }}
      >
        <Link to={`/orderDetails/${order?.orderId}`} style={{color:theme?.palette.neutral.MoustacheGrey, fontWeight:600}}>
          #{order?.order_no}
        </Link>
      </TableCell>
      <TableCell
        sx={{
          color: theme?.palette.neutral.MoustacheGrey,
          fontSize: "14px",
          textTransform: "capitalize",
        }}
      >
        {format(order?.createdAt?.toDate(), "MM/dd/yyyy")}
      </TableCell>
      <TableCell
        sx={{
          color: theme?.palette.neutral.MoustacheGrey,
          fontSize: "14px",
          textTransform: "capitalize",
        }}
      >
        {order?.status === "withheld" && (
          <Stack direction="row" gap={1} alignItems="center">
            <StatusDisplay status={order?.status} />
            <Tooltip
              title={
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: theme.palette.primary.light,
                    maxWidth: "200px",
                  }}
                >
                  Your funds are currently withheld due to pending verification.
                  Please check your account for details.
                </Typography>
              }
              arrow
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={handleOpenDisclaimerDialog}
              >
                <AiOutlineExclamationCircle size={16} />
              </Box>
            </Tooltip>
            <DisclaimerDialog
              handleCloseDisclaimerDialog={handleCloseDisclaimerDialog}
              openDisclaimerDialog={openDisclaimerDialog}
            />
          </Stack>
        )}
        {order?.status !== "withheld" && (
          <StatusDisplay status={isSuccessDialogOpen ? 'precessing' : order?.status} />
        )}
      </TableCell>
      <TableCell
        sx={{
          color: theme?.palette.neutral.MoustacheGrey,
          fontSize: "14px",
          textTransform: "capitalize",
          cursor: "pointer",
        }}
        onClick={() =>
          goToSellerPage(
            activeTab === "seller" ? order?.buyer_id : order?.seller_id
          )
        }
      >
        {activeTab === "seller"
          ? order?.buyerName?.length > 15
            ? `${order?.buyerName.substring(0, 15)}...`
            : order?.buyerName
          : order?.sellerName
          ? order?.sellerName.length > 15
            ? `${order?.sellerName.substring(0, 15)}...`
            : order?.sellerName
          : "No seller name"}
      </TableCell>
      <TableCell
        sx={{
          color: getTotalColor(),
          fontSize: "14px",
          textTransform: "capitalize",
        }}
      >
        {activeTab === "seller" ? (
          <Stack
            gap={1}
            justifyItems="center"
            direction="column-reverse"
            sx={{ textAlign: "center" }}
          >
          
            {!isSuccessDialogOpen && order?.status === "pending" && (
              <Button
                startIcon={
                  isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <IoWalletOutline />
                  )
                }
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.main,
                  fontWeight: 500,
                  fontSize: "12px",
                  padding: "6px 5px",
                  textTransform: "capitalize",
                  borderRadius: "40px",
                  border: "1px solid",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                  },
                }}
                onClick={handleOpenConfirmDialog}
              >
                {isLoading ? "Processing..." : "Collect "}
              </Button>
            )}
            <>
              {order?.netAmount && "$"}
              {Number.isInteger(order?.netAmount)
                ? order?.netAmount
                : order?.netAmount?.toFixed(2)}
            </>
          </Stack>
        ) : (
          <>
            {order?.total && "-"}
            {order?.refundedAmount && "+"}
            {(order?.total || order?.refundedAmount) && "$"}
            {Number.isInteger(order?.total ?? order?.refundedAmount)
              ? order?.total ?? order?.refundedAmount
              : (order?.total ?? order?.refundedAmount)?.toFixed(2)}
          </>
        )}
      </TableCell>
      {/* <TableCell>
        {!ordersLoading && activeTab === "seller" ? (
          <Box>
            {(() => {
              switch (order?.status) {
                case "withheld":
                  return (
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                    >
                      funds withheld
                    </Typography>
                  );

                case "paid":
                  return (
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                    >
                      payout completed
                    </Typography>
                  );

                case "processing":
                  return (
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                    >
                      wait until the payment is processed
                    </Typography>
                  );

                case "pending":
                  return (
                    <Button
                      startIcon={
                        isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <IoWalletOutline />
                        )
                      }
                      sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                        padding: { xs: "6px 15px", sm: "6px 25px" },
                        textTransform: "capitalize",
                        borderRadius: "40px",
                        transition: "all 0.3s ease-in-out",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.light,
                        },
                      }}
                      onClick={handleOpenConfirmDialog}
                    >
                      {isLoading ? "Processing..." : "Collect Money"}
                    </Button>
                  );

                default:
                  return (
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                      variant="body2"
                    >
                      no action is available.
                    </Typography>
                  );
              }
            })()}
          </Box>
        ) : (
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 500,
              textTransform: "capitalize",
            }}
            variant="body2"
          >
            no action is available
          </Typography>
        )}
      </TableCell> */}
      {/* Confirmation Dialog */}
      <Dialog open={isConfirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Payout</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to collect the payout for this order? This
            action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmDialog}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsLoading(true);
              setIsConfirmDialogOpen(false);
              handleCollectMoney(order?.id, order?.seller_id);
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
            }}
            sx={{
              textTransform: "capitalize",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.light,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <PayoutSuccessDialog
        open={isSuccessDialogOpen}
        onClose={handleCloseSuccessDialog}
      />
    </TableRow>
  );
};

export default TransactionsTableRow;
