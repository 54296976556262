import { Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { LuBoxes } from "react-icons/lu";
import profilePlaceholder from "../../../assets/Images/profilePlaceholder.png";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo } from "react";

const UserChatCard = React.memo(
  ({ theme, chat, onClick, currentUser, activeTab, activeChatId }) => {
    const navigate = useNavigate();

    // Calculate unread messages for each tab

    const formattedDate = chat.updatedAt
      ? format(chat.updatedAt.toDate(), "h:mm a")
      : "";

    const userUnreadMessages =
      chat?.unRead?.user1?.uid === currentUser.uid
        ? chat.unRead.user1.numOfMessages
        : chat?.unRead?.user2?.uid === currentUser.uid
        ? chat.unRead.user2.numOfMessages
        : 0;

    const isActive = chat.id === activeChatId;

    const mapUserTypeToTab = (userType) => {
      switch (userType) {
        case "serviceProvider":
          return "Services Providers";
        case "seller":
          return "Sellers";
        case "all":
          return "All";
        default:
          return "Unknown";
      }
    };

    const goToSellerPage = (id, userType) => {
      const selectedTab = mapUserTypeToTab(userType);
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(`/sellers/seller-details/${id}`, { state: { selectedTab } });
    };

    // Determine the chat display name
    const chatDisplayName = useMemo(() => {
      const { userData } = chat || {};
      const { businessInfo, name, firstName, lastName } = userData || {};
      
      const displayName =
        businessInfo?.name ||
        businessInfo?.businessName ||
        name ||
        `${firstName} ${lastName}`;

      return displayName?.length > 25
        ? `${displayName.substring(0, 25)}...`
        : displayName;
    }, [chat?.userData]);

    return (
      <Stack
        direction="row"
        onClick={() => onClick(chat)}
        gap={2}
        sx={{
          borderRadius: "8px",
          padding: "5px 25px",
          backgroundColor: isActive
            ? theme.palette.primary.lightSky
            : theme.palette.primary.light,
          "&:hover": {
            backgroundColor: theme.palette.primary.sky,
          },
          [theme.breakpoints.down("sm")]: {
            padding: "5px 15px",
            gap: 1,
          },
        }}
      >
        <Stack alignItems="center" justifyContent="center">
          <img
            src={
              chat?.userData?.profilePicture
                ? chat?.userData?.profilePicture
                : profilePlaceholder
            }
            alt={chat?.userData?.firstName}
            style={{
              width: "48px",
              height: "48px",
              objectFit: "cover",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            loading="lazy"
            onClick={() =>
              goToSellerPage(chat?.userData?.uid, chat?.userData?.userType)
            }
          />
        </Stack>
        <Stack flexGrow={1}>
          {chat?.itemData && activeTab === "items" && (
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                variant="h6"
                sx={{
                  [theme.breakpoints.down("sm")]: { fontSize: "0.9rem" },
                  [theme.breakpoints.down("xl")]: { fontSize: "1rem" },
                }}
              >
                {chat?.itemData?.title?.length > 10
                  ? `${chat?.itemData?.title.substring(0, 10)}...`
                  : chat?.itemData?.title}
              </Typography>

              <LuBoxes />
            </Stack>
          )}
          {chat?.serviceData && activeTab === "services" && (
            <Typography
              variant="h6"
              sx={{
                [theme.breakpoints.down("sm")]: { fontSize: "0.9rem" },
                [theme.breakpoints.down("xl")]: { fontSize: "1rem" },
              }}
            >
              {chat?.serviceData?.title?.length > 10
                ? `${chat?.serviceData?.title.substring(0, 10)}...`
                : chat?.serviceData?.title}
            </Typography>
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.primary.purpleBlack,
                fontWeight: "600",
                textTransform: "capitalize",
                cursor: "pointer",
                [theme.breakpoints.down("sm")]: { fontSize: "0.8rem" },
                [theme.breakpoints.down("xl")]: { fontSize: "0.7rem" },
              }}
              onClick={() =>
                goToSellerPage(chat?.userData?.uid, chat?.userData?.userType)
              }
            >
              {/* {chat?.userData?.businessInfo?.name
                ? chat?.userData?.businessInfo?.name?.length > 25
                  ? `${chat?.userData?.businessInfo?.name?.substring(0, 25)}...`
                  : chat?.userData?.businessInfo?.name
                : `${chat?.userData?.firstName} ${chat?.userData?.lastName}`
                    .length > 25
                ? `${`${chat?.userData?.firstName} ${chat?.userData?.lastName}`.substring(
                    0,
                    25
                  )}...`
                : `${chat?.userData?.firstName} ${chat?.userData?.lastName}`} */}
              {chatDisplayName}
            </Typography>
            <Typography
              variant="overline"
              sx={{
                textTransform: "uppercase",
                color: theme.palette.primary.trueBlack,
                opacity: "40%",
                fontFamily: theme.typography.headerFont,
                [theme.breakpoints.down("sm")]: { fontSize: "0.7rem" },
              }}
            >
              {formattedDate}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="overline"
              sx={{
                color: theme.palette.primary.purpleBlack,
                fontFamily: theme.typography.headerFont,
                textTransform: "capitalize",
                opacity: "60%",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "0.75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                },
              }}
            >
              {chat?.lastMessage?.message?.length > 25
                ? `${chat?.lastMessage?.message.substring(0, 25)}...`
                : chat?.lastMessage?.message}
            </Typography>
            {userUnreadMessages > 0 && (
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  borderRadius: "50%",
                  [theme.breakpoints.down("sm")]: {
                    width: "20px",
                    height: "20px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    [theme.breakpoints.down("sm")]: { fontSize: "10px" },
                  }}
                >
                  {userUnreadMessages}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }
);

export default UserChatCard;
