import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
  Modal as MuiModal,
  CircularProgress,
} from "@mui/material";
import CircularStepProgress from "../components/CircularStepProgress";
import IOSSwitch from "../../../components/common/IOSSwitch";
import { IoIosArrowForward } from "react-icons/io";
import { FaBuildingColumns } from "react-icons/fa6";
import { BiEditAlt } from "react-icons/bi";
import map from "../../../assets/Images/map.png";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  clearFormAddServiceData,
  setFormAddServiceData,
  setGoBackAddService,
  setNewItemAdded,
} from "../../../redux/slices/appStateSlice";
import {
  db,
  storageSdk as storage,
  storageSdk,
} from "../../../configs/firebase";
import { Timestamp } from "firebase/firestore";
import { message, Modal as AntModal } from "antd";
import EditAddressModal from "../components/EditAddressModal";
import { useNavigate } from "react-router-dom";
import {
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import ReusableAddressModal from "../../../components/common/ReusableAddressModal";
import { GoDotFill } from "react-icons/go";
import { formatAddress } from "../../../utils/reusableFunctions";

import ShippingAddressSelector from "../../cart/components/ShippingAddressSelector";
import saveDraftService from "../../../utils/saveDraftService";

const serviceBillingDetailsSchema = Yup.object().shape({
  contactForPrice: Yup.boolean().required("Contact for price is required"),
  price: Yup.number()
    .min(1, "Price cannot be negative at least 1")
    .when("contactForPrice", {
      is: false,
      then: (schema) => schema.required("Please enter price"),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  // isOnline: Yup.boolean().required("Allow payment option is required"),
  isLocalAddress: Yup.boolean().required("Local pickup option is required"),
});

const ServiceBillingDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalSteps = useSelector((state) => state.appState.serviceTotalPages);
  const currentStep = useSelector((state) => state.appState.serviceActiveStep);
  const userData = useSelector((state) => state.appState.userData);
  const formAddServiceData = useSelector(
    (state) => state.appState.formAddServiceData
  );
  const [buttonClicked, setButtonClicked] = useState(""); // Track which button is clicked
  const [editAddress, setEditAddress] = useState(false);
  const isFormTouched = useSelector((state) => state.appState.isFormTouched);
  const [isLoading, setIsLoading] = useState(false);
  const { businessInfo } = userData;
  const [chooseAddressEnabled, setChooseAddressEnabled] = useState(false);

  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [addressSelectorMode, setAddressSelectorMode] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const formattedAddress = formatAddress(
    chooseAddressEnabled
      ? selectedAddress || formAddServiceData?.businessAddress
      : formAddServiceData?.businessAddress
  );

  const [selectedAddressId, setSelectedAddressId] = useState(
    userData?.businessInfo?.address ? userData?.businessInfo?.address?.id : null
  );

  const commonStyles = {
    boxPadding: { paddingX: "30px" },
    formBox: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "32px",
      padding: "10px 20px",
    },
    labelText: {
      color: theme.palette.primary.darkMain,
      fontWeight: "600",
      textTransform: "capitalize",
    },
    inputStyle: {
      backgroundColor: theme.palette.primary.background,
      width: "15.25rem",
      padding: "19px 16px",
      borderRadius: "8px",
      "& .MuiInputBase-input": {
        color: theme.palette.primary.lightDoveGrey,
      },
    },
    switchStack: {
      backgroundColor: theme.palette.primary.background,
      borderRadius: "8px",
      padding: "14px 16px",
    },
    infoText: {
      color: theme.palette.dark.colorCart,
      fontWeight: "600",
      textTransform: "capitalize",
    },
    buttonStyle: {
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.main,
      fontSize: "14px",
      fontWeight: "700",
      padding: "0 32px",
      borderRadius: "5px",
      height: "56px",
      width: {
        xs: "100%",
        sm: "11.25rem",
      },
      marginY: {
        sm: "20px",
        xs: "10px",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.light,
      },
    },
    dashedBox: {
      border: `dashed 1px ${theme.palette.primary.dashedGrey}`,
      padding: "16px",
      width: { xs: "100%", sm: "21.438rem" },
      borderRadius: "10px",
      marginY: "20px",
    },
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "1px solid white",
    p: 4,
  };

  useEffect(() => {
    if (businessInfo?.address) {
      const initialBusinessAddress = {
        _geoloc: {
          lat: businessInfo?.address?.lat || 0,
          lng: businessInfo?.address?.lng || 0,
        },
        businessAddress: {
          addressLineOne: businessInfo?.address?.addressLineOne || "",
          addressLineTwo: businessInfo?.address?.addressLineTwo || "",
          city: businessInfo?.address?.city || "",
          name: businessInfo?.address?.name || "",
          state: businessInfo?.address?.state || "",
          zipCode: businessInfo?.address?.zipCode || "",
          country: businessInfo?.address?.country || "",
          lat: businessInfo?.address?.lat || 0,
          lng: businessInfo?.address?.lng || 0,
          placeId: businessInfo?.address?.placeId || "",
        },
      };
      // Dispatch to set formAddServiceData with the initial values
      dispatch(setFormAddServiceData(initialBusinessAddress));
    }
  }, [businessInfo]);

  // Helper function to upload file to Firebase Storage under the user's UID folder
  const uploadFileToStorage = async (file) => {
    const storageRef = storage.ref();
    const userUid = userData?.uid;
    const fileRef = storageRef.child(
      `services/${userUid}/${file.name}-${Date.now()}`
    ); // Create a unique file path for this user's service
    await fileRef.put(file);
    const downloadURL = await fileRef.getDownloadURL();
    return downloadURL;
  };

  const validateShippingMethod = (isLocalPickup) => {
    if (!isLocalPickup) {
      message.error("Please select local Pickup.");
      return false;
    }
    return true;
  };

  const submitForm = async (values) => {
    if (!validateShippingMethod(values.isLocalAddress)) {
      return;
    }
    setIsLoading(true);

    dispatch(
      setFormAddServiceData({
        price: values?.price,
        contactForPrice: values?.contactForPrice,
        isOnline: values?.contactForPrice ? false : values?.isOnline,
        isLocalAddress: values?.isLocalAddress,
      })
    );

    let imageURLs = [];
    let videoURL = formAddServiceData?.video || null;

    try {
      // Upload image files to Firebase Storage and collect download URLs

      saveDraftService({
        userData,
        formAddServiceData,
        values: false, // Clear draft
      });

      if (formAddServiceData?.images && formAddServiceData?.images.length > 0) {
        const imageUploadPromises = formAddServiceData?.images?.map(
          async (image) => {
            if (typeof image === "string" && image.startsWith("https")) {
              return image;
            } else {
              return await uploadFileToStorage(image);
            }
          }
        );
        imageURLs = await Promise.all(imageUploadPromises);
      }

      // Upload video file (if any) to Firebase Storage and get the download URL
      if (
        formAddServiceData?.video &&
        !formAddServiceData?.video.startsWith("https")
      ) {
        videoURL = await uploadFileToStorage(formAddServiceData?.video);
      }
      // Step 3: Compute the final service object
      const newDocRef = db.collection("services").doc();
      const newServiceId = newDocRef.id;
      const { updatedAt, createdAt, isDefault, id, ...restAddress } =
        chooseAddressEnabled
          ? selectedAddress || formAddServiceData?.businessAddress
          : formAddServiceData?.businessAddress;

      const serviceModel = {
        ...formAddServiceData,
        id: newServiceId,
        uid: userData?.uid,
        businessAddress: restAddress || null,
        _geoloc: {
          lat:
            (chooseAddressEnabled
              ? selectedAddress?.lat || formAddServiceData?.businessAddress?.lat
              : formAddServiceData?.businessAddress.lat) || 0,
          lng:
            (chooseAddressEnabled
              ? selectedAddress?.lng || formAddServiceData?.businessAddress?.lng
              : formAddServiceData?.businessAddress.lng) || 0,
        },
        contactForPrice: values?.contactForPrice,
        isOnline: false,
        isDeleted: false,
        // isOnline: values.contactForPrice ? false : values.isOnline,
        isLocalAddress: values?.isLocalAddress,
        price: values.price ? Number(values.price) : null,
        name: businessInfo?.name || null,
        profilePicture: userData?.profilePicture || null,
        // images: imageURLs,
        images: formAddServiceData.images,
        video: videoURL,
        isActive: buttonClicked === "listService", // Set true for listing, false for draft
        isDraft: buttonClicked === "addToDraft", // Set true for draft, false for listing
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        registeredSince: userData?.registeredSince,
      };

      await newDocRef.set(serviceModel);
      // If the userType is "seller", update the userType to "all" in the users collection
      if (userData?.userType === "seller" && userData?.isDeleted === false) {
        const userDocRef = db.collection("users").doc(userData?.uid);
        await userDocRef.update({
          userType: "all",
        });
      }
      message.success({
        content: "Service submitted successfully",
        duration: 5,
      });
      dispatch(clearFormAddServiceData());
      dispatch(setNewItemAdded(true));
      navigate("/selling?type=services");
    } catch (error) {
      message.error({
        content: "Failed to submit service. Please try again",
        duration: 5,
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewAddressModalOpen = () => {
    setIsNewAddressModalOpen(true);
  };
  const inputRef = useRef(null);
  const handleFocus = () => {
    const inputElement = inputRef.current;

    const preventScroll = (e) => {
      e.preventDefault();
    };

    inputElement.addEventListener("wheel", preventScroll);

    // Clean up the event listener when the input loses focus
    inputElement.addEventListener("blur", () => {
      inputElement.removeEventListener("wheel", preventScroll);
    });
  };
  return (
    <Formik
      initialValues={{
        contactForPrice: false,
        price: null,
        // isOnline: true,
        isLocalAddress: true,
      }}
      validationSchema={serviceBillingDetailsSchema}
      onSubmit={submitForm}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form>
          <Box sx={commonStyles.boxPadding}>
            <Box sx={commonStyles.formBox}>
              {/* Header */}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: theme.palette.dark.colorCart,
                    fontSize: "24px",
                    fontFamily: theme.typography.headerFont,
                  }}
                >
                  Add Service
                </Typography>
                <CircularStepProgress
                  steps={totalSteps}
                  currentStep={currentStep}
                />
              </Stack>

              {/* Body */}
              <Stack sx={{ width: { xs: "100%", sm: "90%" } }}>
                {/* Price Section */}
                <Box sx={{ marginY: "10px" }}>
                  <Typography
                    variant="body2"
                    sx={{ ...commonStyles.labelText, marginBottom: "10px" }}
                  >
                    price <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    gap={3}
                  >
                    <TextField
                      variant="standard"
                      name="price"
                      type="number"
                      onBlur={handleBlur}
                      sx={{
                        ...commonStyles.inputStyle,
                        backgroundColor: values.contactForPrice
                          ? theme.palette.primary.brownGrey
                          : theme.palette.primary.light,
                        padding: "13px",
                        color: theme.palette.primary.doveGrey,
                        border: `1px solid ${theme.palette.gray.borderFaild}`,
                        marginTop: "8px",
                        borderRadius: "10px",
                        "& .MuiInputBase-input": {
                          color: values.contactForPrice
                            ? theme.palette.primary.doveGrey
                            : theme.palette.primary.lightDoveGrey,
                        },
                      }}
                      value={values.contactForPrice ? "" : values.price}
                      placeholder={
                        values.contactForPrice
                          ? "Contact for Price"
                          : "Enter Price"
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value.startsWith(".")) {
                          value = "0.";
                        }
                        const decimalIndex = value.indexOf(".");
                        if (decimalIndex !== -1) {
                          value = value.slice(0, decimalIndex + 3);
                        }

                        setFieldValue("price", parseFloat(value));
                      }}
                      InputProps={{
                        readOnly: values.contactForPrice,
                        disableUnderline: true,
                      }}
                      onFocus={handleFocus}
                      inputRef={inputRef}
                      error={touched.price && Boolean(errors.price)}
                    />
                    <FormGroup
                      sx={{
                        ...commonStyles.inputStyle,
                        padding: "9px 16px !important",
                        width: "12.25rem",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.contactForPrice}
                            name="contactForPrice"
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              setFieldValue("contactForPrice", isChecked);
                              if (!isChecked) {
                                setFieldValue("price", "");
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        }
                        label="contact for price"
                        componentsProps={{
                          typography: {
                            sx: {
                              fontSize: "12px",
                              color: theme.palette.dark.colorCart,
                              fontWeight: "500",
                              textTransform: "capitalize",
                            },
                          },
                        }}
                      />
                    </FormGroup>
                  </Stack>
                  <ErrorMessage
                    name="price"
                    component="div"
                    style={{ color: "red", marginTop: "4px" }}
                  />
                </Box>

                <Divider />

                {/* Local Pickup Section */}
                {/* <Stack gap={1} sx={{ marginY: "10px", paddingY: "10px" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={commonStyles.switchStack}
                  >
                    <Typography variant="body1" sx={commonStyles.infoText}>
                      local pickup
                    </Typography>
                    <IOSSwitch
                      checked={values.isLocalAddress}
                      onChange={(event) =>
                        setFieldValue("isLocalAddress", event.target.checked)
                      }
                      onBlur={handleBlur}
                      name="isLocalAddress"
                    />
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <GoDotFill
                      size="17px"
                      color={theme.palette.dark.dustyBlue}
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme.palette.dark.dustyBlue,
                        fontStyle: "italic",
                        fontWeight: "500",
                      }}
                    >
                      By selecting the local pickup option Users need to know
                      business or house address to pickup parts
                    </Typography>
                  </Stack>
                </Stack> */}

                {/* <Divider /> */}

                {/* Shipping Address Section */}

                <Stack gap={1} sx={{ marginY: "10px" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body2"
                      sx={{ ...commonStyles.labelText, marginLeft: "7px" }}
                    >
                      shipping address
                    </Typography>
                    <Button
                      onClick={() =>
                        setChooseAddressEnabled(!chooseAddressEnabled)
                      }
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {chooseAddressEnabled ? "Cancel" : "Choose Address"}
                    </Button>
                  </Stack>
                  {chooseAddressEnabled && (
                    <>
                      <ShippingAddressSelector
                        currentUser={userData}
                        onSelectAddress={(address) =>
                          setSelectedAddressId(address?.id)
                        }
                        isModalOpen={isNewAddressModalOpen}
                        setIsModalOpen={setIsNewAddressModalOpen}
                        selectValue={addressSelectorMode}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddressId={selectedAddressId}
                      />
                      <Button
                        variant="outlined"
                        onClick={handleNewAddressModalOpen}
                        sx={{
                          width: "fit-content",
                          margin: "10px 0",
                          textTransform: "capitalize",
                        }}
                      >
                        add new address
                      </Button>
                    </>
                  )}

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      padding: "8px 16px",
                      borderRadius: "8px",
                      boxShadow: `0px 6px 30px 0px ${theme.palette.customShadows.darkBlack}`,
                      marginY: "10px",
                    }}
                  >
                    <Stack direction="row" alignItems="center" gap={1.5}>
                      <img
                        loading="lazy"
                        src={map}
                        alt="map"
                        style={{ objectFit: "contain" }}
                      />
                      <Stack justifyContent="center" gap={0.5}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.dark.colorCart,
                            fontWeight: "700",
                          }}
                        >
                          {chooseAddressEnabled
                            ? selectedAddress?.name ||
                              formAddServiceData?.businessAddress?.name
                            : formAddServiceData?.businessAddress?.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: theme.palette.gray.colorSummary,
                            fontWeight: "500",
                          }}
                        >
                          {formattedAddress || "Address not available"}
                          {/* {formAddServiceData?.businessAddress?.addressLineOne} */}
                        </Typography>
                        {chooseAddressEnabled &&
                          (selectedAddress?.addressLineTwo ||
                            formAddServiceData?.businessAddress
                              ?.addressLineTwo) && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.gray.colorSummary,
                                fontWeight: "500",
                              }}
                            >
                              Apt/Suite :{" "}
                              {selectedAddress?.addressLineTwo ||
                                formAddServiceData?.businessAddress
                                  ?.addressLineTwo}
                            </Typography>
                          )}
                        {!chooseAddressEnabled &&
                          formAddServiceData?.businessAddress
                            ?.addressLineTwo && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.gray.colorSummary,
                                fontWeight: "500",
                              }}
                            >
                              Apt/Suite :{" "}
                              {
                                formAddServiceData?.businessAddress
                                  ?.addressLineTwo
                              }
                            </Typography>
                          )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  direction={{
                    xs: "column",
                    sm: "row",
                  }}
                  justifyContent="space-between"
                >
                  <Stack
                    direction={{
                      sm: "row",
                      xs: "column",
                    }}
                    gap={{
                      xs: 0,
                      sm: 3,
                    }}
                  >
                    <Button
                      onClick={() => {
                        dispatch(setGoBackAddService(true));
                        navigate(-1);
                        dispatch(
                          setFormAddServiceData({
                            price: values.price,
                            contactForPrice: values.contactForPrice,
                            isOnline: values.contactForPrice
                              ? false
                              : values.isOnline,
                            isLocalAddress: values.isLocalAddress,
                          })
                        );
                      }}
                      variant="outlined"
                      sx={{
                        ...commonStyles.buttonStyle,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                      }}
                    >
                      go back
                    </Button>
                    <Button
                      disabled={isLoading}
                      onClick={() => setButtonClicked("addToDraft")}
                      type="submit"
                      variant="outlined"
                      sx={{
                        ...commonStyles.buttonStyle,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                      }}
                    >
                      {isLoading && buttonClicked === "addToDraft" ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Add to Draft"
                      )}
                    </Button>
                  </Stack>
                  {/* Next Button */}
                  <Button
                    disabled={isLoading}
                    onClick={() => setButtonClicked("listService")}
                    type="submit"
                    variant="outlined"
                    sx={commonStyles.buttonStyle}
                  >
                    {isLoading && buttonClicked === "listService" ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      "List Service"
                    )}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ServiceBillingDetails;
