import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../../configs/firebase";
import Loader from "../../components/common/Loader";
import { setServicesCategories } from "../../redux/slices/appStateSlice";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { message } from "antd";
import SearchBar from "../../components/common/SearchBar";
import ItemSection from "../../components/common/ItemSection";

const Services = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayedServices, setDisplayedServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrderControle, setSortOrderControle] = useState("default");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSortOpen, setIsSortOpen] = useState(false);

  useEffect(() => {
    getServiceCategory();
  }, []);

  useEffect(() => {
    filterServices();
  }, [searchTerm, services, sortOrderControle]);

  const getServiceCategory = async () => {
    setIsLoading(true);
    try {
      const docs = db.collection("servicesCategories").orderBy("index", "asc");

      let serviceCategory = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          serviceCategory.push(doc.data());
        });
        setServices(serviceCategory);
        setDisplayedServices(serviceCategory);
        dispatch(setServicesCategories(serviceCategory));
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      message.error(
        "There was an error fetching the services. Please try again later."
      );
      setIsLoading(false);
    }
  };

  const filterServices = () => {
    let filtered = services;

    // Filter by search term
    if (searchTerm?.trim() !== "") {
      filtered = services?.filter((category) =>
        category?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
    }

    // Create a shallow copy of the array before sorting
    filtered = [...filtered]; // or filtered.slice()

    // Sort by importance (default) or alphabetically
    if (sortOrderControle === "alphabetically") {
      filtered.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      filtered.sort((a, b) => a.index - b.index); // importance is determined by 'index'
    }

    setDisplayedServices(filtered);
  };

  const handleSortOrderChange = (value) => {
    setSortOrderControle(value);
  };
  const handleSortDropdownToggle = (isOpen) => {
    setIsSortOpen(isOpen);
  };

  const goToServicePage = (id, name) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // navigate(`/services/${id}`, { state: { name }} );
    navigate(`/filtered-services?service-Id=${id}&service-name=${name}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section style={{ margin: "0px 25px 0px 25px" }}>
        <Box
          sx={{
            marginBottom: displayedServices?.length > 0 && "20px",
            width: "100%",
            maxWidth: "729px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            sortOrderControle={sortOrderControle}
            handleSortOrderChange={handleSortOrderChange}
            handleSortDropdownToggle={handleSortDropdownToggle}
            placeholder="Search Service..."
          />
        </Box>

        {isLoading ? (
          <Grid
            container
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <Loader />
          </Grid>
        ) : displayedServices?.length > 0 ? (
          <Grid
            container
            sx={{
              justifyContent: "center",
              position: "relative",
              bottom: "50px",
              marginTop: isSmallScreen ? (isSortOpen ? "80px" : "0px") : "80px",
            }}
          >
            {displayedServices?.map((item, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                xl={1.7}
                key={item?.id}
                sx={{
                  justifyContent: "center",
                  paddingRight: "0",
                }}
              >
                <ItemSection
                  item={item}
                  index={index}
                  key={item?.id}
                  height
                  widthCategorie
                  paddingCategories
                  marginRightCategorie
                  goToPage={goToServicePage}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            No services available.
          </Typography>
        )}
      </section>
    </>
  );
};

export default Services;
