import { Box, useTheme } from "@mui/material"
import { IoShieldCheckmark } from "react-icons/io5";
const VerifiedBox=()=> {
  const theme = useTheme();
  return (
    <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      width: "20px",
      height: "20px",
      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
      borderRadius: "50%",
    }}
  >
    <IoShieldCheckmark color={`${theme.palette.primary.main}`} /> 
  </Box>
  )
}

export default VerifiedBox
