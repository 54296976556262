import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import illustrationSuccess from "../../assets/Images/Illustration Success.png";
import { db } from "../../configs/firebase";
import { useNavigate } from "react-router-dom";

const SuccessOrder = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [orderNo, setOrderNo] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestOrder = async () => {
      try {
        const ordersRef = collection(db, "orders");
        const latestOrderQuery = query(
          ordersRef,
          orderBy("createdAt", "desc"),
          limit(1)
        );
        const querySnapshot = await getDocs(latestOrderQuery);

        if (!querySnapshot.empty) {
          const latestOrder = querySnapshot.docs[0];
          setOrderNo(latestOrder?.order_no);
          setOrderId(latestOrder?.id);
        } else {
          setOrderNo(null);
        }
      } catch (error) {
        console.error("Error fetching the latest order:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLatestOrder();
  }, []);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      component="section"
      sx={{
        height: { xs: "auto", sm: "60vh" },
        mb: 10,
        px: 2,
      }}
    >
      <Stack
        gap={3}
        justifyContent="center"
        alignItems="center"
        sx={{
          bgcolor: theme.palette.primary.light,
          p: { xs: 2, sm: 4 },
          borderRadius: "30px",
          width: { xs: "90%", sm: "70%", md: "50%" },
          textAlign: "center",
        }}
      >
        <img
          src={illustrationSuccess}
          alt="Illustration Success"
          loading="lazy"
        />
        <Typography
          variant="h4"
          sx={{
            // textTransform: "capitalize",
            fontSize: { xs: "1.5rem", sm: "2rem" },
            fontWeight: "bold",
          }}
        >
          Your order has been successfully placed!
        </Typography>

        {loading ? (
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              maxWidth: "80%",
              // textTransform: "capitalize",
              fontSize: { xs: "0.9rem", sm: "1rem" },
            }}
          >
            Loading your order details...
          </Typography>
        ) : orderNo ? (
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              maxWidth: "80%",
              // textTransform: "capitalize",
              fontSize: { xs: "0.9rem", sm: "1rem" },
            }}
          >
            Order number:{" "}
            <Box component="span" sx={{ color: theme.palette.primary.main }}>
              #{orderNo}
            </Box>
          </Typography>
        ) : (
          ""
        )}

        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            maxWidth: "80%",
            // textTransform: "capitalize",
            fontSize: { xs: "0.9rem", sm: "1rem" },
          }}
        >
          Thank you for shopping with us, you will receive a confirmation email
          shortly with all the details of your order
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={2}
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Button
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.primary.main}`,
              padding: { xs: "10px 20px", sm: "12px 30px" },
              fontSize: "14px",
              fontWeight: "600",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
              },
            }}
            // onClick={() => navigate(orderId ? `/orderDetails/${orderId}` : "/profile?page=orders")}
            onClick={() => navigate(`/orderDetails/${orderId}`)}
          >
            View order details
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              color: theme.palette.primary.light,
              backgroundColor: theme.palette.primary.main,
              shadow: "none",
              padding: { xs: "10px 20px", sm: "12px 30px" },
              fontSize: "14px",
              fontWeight: "600",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.light,
                boxShadow: "none",
              },
            }}
            onClick={() => navigate("/categories")}
          >
            Continue shopping
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SuccessOrder;
