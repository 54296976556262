import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPrompt from "../../../components/common/CustomPrompt";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { setFormAddItemData } from "../../../redux/slices/appStateSlice";
import { Field, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";
import { RiDeleteBin6Line } from "react-icons/ri";
import CircleProgress from "../../../components/common/CircularProgress";
import { db } from "../../../configs/firebase";
import { message } from "antd";
import validationSchemas from "./validationSchemas";
import saveDraftProduct from "../../../utils/saveDraftProduct";

const AdditionalDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.appState.userData);
  const [isDirty, setIsDirty] = useState(false);
  const [showHeaders, setShowHeaders] = useState(false);

  const [productDataStep2, setProductDataStep2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [vehicleFormErrors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const initialSetup = sessionStorage.getItem("initialSetup");
  const [draftProduct, setDraftProduct] = useState(null);

  const [carData, setCarData] = useState({
    brand: "",
    model: "",

    year: "",
  });

  const formAddItemData = useSelector(
    (state) => state.appState.formAddItemData
  );
  const [carsList, setCarsList] = useState(
    formAddItemData?.carsList || productDataStep2?.carsList || []
  );
  const [isContactForPrice, setIsContactForPrice] = useState(() => {
    return (
      formAddItemData?.contactForPrice ??
      (productDataStep2 && productDataStep2?.contactForPrice) ??
      null
    );
  });
  const [priceDisabled, setPriceDisabled] = useState(
    formAddItemData?.contactForPrice ??
      productDataStep2?.contactForPrice ??
      false
  );

  useEffect(() => {
    if (productDataStep2) {
      setPriceDisabled(productDataStep2?.contactForPrice || false);
    }
  }, [productDataStep2]);

  const currentSchema = validationSchemas[`step${"Two"}`];

  const inputStyles = {
    common: {
      width: "100%",
      padding: "13px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      borderRadius: "10px",
      marginTop: "8px",
    },
    label: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "21px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: theme.palette.primary.Charcoal,
    },
    subTitle: {
      fontSize: ".7rem",
      color: theme.palette.dark.dustyBlue,
      margin: "5px 20px 20px 20px",
      fontWeight: 500,
      lineHeight: "1rem",
      fontStyle: "italic",
      fontFamily: "Poppins",
    },
    btn: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22.4px",
      textAlign: "center",
      padding: "13px 40px",
      borderRadius: "5px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "170px",
      height: "50px",
    },
    focusBlur: {
      outline: "none",
      border: `1px solid ${theme.palette.gray.borderFaild}`,
    },
    inputColor: {
      color: theme.palette.primary.darkColor,
      background: theme.palette.primary.light,
    },
  };

  /**Fuction Update ****/
  const fetchProductData = useCallback(async () => {
    try {
      const productRef = db.collection("products").doc(productId);
      const doc = await productRef.get();
      if (doc.exists) {
        const data = doc.data();
        setProductDataStep2(data);
      } else {
        message.error("Product not found");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      message.error("Failed to fetch product data.");
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      fetchProductData();
    }
  }, [productId, fetchProductData]);

  useEffect(() => {
    if (productDataStep2 && productDataStep2?.carsList) {
      setCarsList(productDataStep2?.carsList);
    }
  }, [productDataStep2]);

  const handleNext = async (validateForm, values, setErrors) => {
    const errors = await validateForm(values);
    if (values.contactForPrice === true) {
      const newErrors = { ...errors };
      delete newErrors.price;
      setErrors(newErrors);
    }
    if (Object.keys(errors)?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
        handleSubmit(values);
        saveDraftProduct({
          userData,
          formAddItemData,
          values: values,
        });
        setIsLoading(false);
      }, 1000);
    } else {
      setErrors(errors);
    }
  };

  const handlePrevious = () => {
    setIsDirty(true);
    window.scrollTo({ top: 0, behavior: "smooth" });

    const params = new URLSearchParams({
      retainRedux: "true",
      step: currentStep - 1,
    });

    if (productId) {
      navigate(`/selling/edit-item/${productId}?${params.toString()}`, {
        state: { retainRedux: true },
      });
    } else {
      navigate("/selling/add-item", { state: { retainRedux: true } });
    }
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const navigateToNextPage = () => {
    setIsDirty(true);
    if (currentStep === 1 || currentStep === 2) {
      navigate(
        `/selling/billing-details${productId ? `/${productId}?mode=edit` : ""}`
      );
    }
  };

  const handleSubmit = async (values) => {
    if (values?.contactForPrice === true) {
      values.price = null;
    }
    values.carsList = carsList;

    const updatedData = { ...formAddItemData, ...values };
    setTimeout(() => {
      dispatch(setFormAddItemData(updatedData));
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigateToNextPage();
    }, 0);
  };
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window?.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleCarDataChange = (e) => {
    const { name, value } = e.target;
    setCarData({ ...carData, [name]: value });
    setErrors({ ...vehicleFormErrors, [name]: "" });
  };

  const validateCarData = () => {
    let formErrors = {};
    if (!carData.brand) formErrors.brand = "Please select a car brand";
    if (!carData.model) formErrors.model = "Please enter the car model";
    if (!carData.year) formErrors.year = "Please enter the car year";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const addCarToList = () => {
    if (validateCarData()) {
      const updatedCarsList = [...carsList, carData];
      setCarsList(updatedCarsList);
      setCarData({ brand: "", model: "", year: "" });
      setOpen(false);
      setShowHeaders(true);
    } else {
      setOpen(true);
    }
  };

  const handleRemoveCar = (index) => {
    const updatedCarsList = carsList.filter((_, i) => i !== index);
    setCarsList(updatedCarsList);
    if (updatedCarsList.length === 0) {
      setShowHeaders(false);
    }
    dispatch(
      setFormAddItemData({ ...formAddItemData, carsList: updatedCarsList })
    );
  };

  // Fetch the draft product from Firebase
  useEffect(() => {
    const fetchDraftProduct = async () => {
      if (userData?.uid) {
        const userDoc = await db.collection("users").doc(userData?.uid).get();
        if (userDoc?.exists) {
          setDraftProduct(userDoc.data()?.draftProduct || null);
        }
      }
    };

    fetchDraftProduct();
  }, [userData]);

  const initialFormDataStep2 = {
    quantity:
      draftProduct?.quantity ||
      formAddItemData?.quantity ||
      productDataStep2?.quantity ||
      1,
    warranty:
      draftProduct?.warranty ||
      formAddItemData?.warranty ||
      productDataStep2?.warranty ||
      "",
    dimentions:
      draftProduct?.dimentions ||
      formAddItemData?.dimentions ||
      productDataStep2?.dimentions ||
      "",
    weight:
      draftProduct?.weight ||
      formAddItemData?.weight ||
      productDataStep2?.weight ||
      "",
    price:
      draftProduct?.price ||
      formAddItemData?.price ||
      productDataStep2?.price ||
      null,
    contactForPrice:
      draftProduct?.contactForPrice ||
      formAddItemData?.contactForPrice ||
      productDataStep2?.contactForPrice ||
      false,
    carsList:
      draftProduct?.carsList ||
      formAddItemData?.carsList ||
      productDataStep2?.carsList ||
      [],
  };

  const handleWheel = (e) => {
    e.preventDefault();
  };
  const inputRef = useRef(null);
  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel, { passive: false });
      return () => {
        inputElement.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);
  return (
    <div
      style={{
        padding: "20px",
        margin: "0px 25px",
        background: theme.palette.primary.light,
        borderRadius: "10px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          marginBottom: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2
          style={{
            fontFamily: theme.typography.headerFont,
            fontSize: "1.5rem",
            fontWeight: 500,
            lineHeight: "27px",
            textAlign: "left",
            color: theme.palette.primary.Charcoal,
          }}
        >
          Add Products
        </h2>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <CircleProgress step={initialSetup === "true" ? 3 : 2} />{" "}
          </Box>{" "}
        </Box>
      </Box>
      <Formik
        // enableReinitialize={!!productDataStep2}
        enableReinitialize={true}
        initialValues={initialFormDataStep2}
        validationSchema={currentSchema}
        validationContext={{ isContactForPrice }}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldError,
          validateForm,
          setErrors,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <label style={inputStyles.common}>
                  Price <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  name="price"
                  type="number"
                  min="1"
                  inputMode="numeric"
                  disabled={values.contactForPrice}
                  value={values.contactForPrice ? "" : values.price}
                  placeholder={
                    values.contactForPrice ? "Contact for Price" : "Enter Price"
                  }
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value.startsWith(".")) {
                      value = "0.";
                    }
                    const decimalIndex = value.indexOf(".");
                    if (decimalIndex !== -1) {
                      value = value.slice(0, decimalIndex + 3);
                    }

                    setFieldValue("price", parseFloat(value));
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.doveGrey,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: values.contactForPrice
                      ? theme.palette.primary.brownGrey
                      : theme.palette.primary.light,
                    // pointerEvents: priceDisabled ? "none" : "auto",
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  innerRef={inputRef}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
                {errors?.price && touched?.price && !values?.contactForPrice ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.price}
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.contactForPrice}
                      name="contactForPrice"
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setIsContactForPrice(checked);
                        setFieldValue("contactForPrice", checked);
                        if (checked) {
                          setFieldValue("price", "");
                          setFieldError("price", "");
                        }
                      }}
                      sx={{ fontSize: { md: "14px", lg: "14px" } }}
                    />
                  }
                  label="Contact for price"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: { md: "14px", lg: "14px", xl: "1rem" },
                    },
                    background: theme.palette.primary.background,
                    marginTop: { xs: "0", md: "30px" },
                    // padding: "4px 20px",
                    padding: {
                      xs: "4px 20px",
                      md: "6px 3px 6px 0",
                      xl: "4px 13px",
                    },
                    marginLeft: "0px",
                    borderRadius: "10px",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <label style={inputStyles.label}>
                  Quantity <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  name="quantity"
                  type="number"
                  min="1"
                  inputMode="numeric"
                  onPaste={preventPasteNegative}
                  // onKeyPress={preventMinus}
                  onKeyPress={(e) => {
                    if (e.key === "." || e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value === "") {
                      setFieldValue("quantity", null);
                      return;
                    }
                    if (parseFloat(!/^[0-9]*\.?[0-9]{0,2}$/.test(value))) {
                      return;
                    }
                    value = value.replace(/^0+(?!\.)/, 1);
                    setFieldValue("quantity", parseFloat(value));
                  }}
                  style={{
                    ...inputStyles.common,
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
                {errors.quantity && touched.quantity ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.quantity}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} md={4}>
                <label style={inputStyles.label}>warranty (optional)</label>
                <Field
                  name="warranty"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, " ");

                    setFieldValue("warranty", inputValue);
                  }}
                  placeholder="Ex: 1 year, 90 days"
                  style={{
                    ...inputStyles.common,
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <label style={inputStyles.label}>Dimentions (optional)</label>
                <Field
                  name="dimentions"
                  placeholder="Ex: 10 inches, 3 meters"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, " ");

                    setFieldValue("dimentions", inputValue);
                  }}
                  style={{
                    ...inputStyles.common,
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <label style={inputStyles.common}>Weight (optional)</label>
                <Field
                  name="weight"
                  placeholder="Ex: 10 Ibs, 5kg"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, " ");

                    setFieldValue("weight", inputValue);
                  }}
                  style={{
                    ...inputStyles.common,
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: theme.palette.primary.background,
                    padding: "14px 12px",
                    borderRadius: "10px",
                  }}
                >
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "21px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: theme.palette.primary.darkMain,
                      textTransform: "capitalize",
                    }}
                  >
                    vehicle info (optional)
                  </label>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "700",
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Add
                  </Typography>
                </Box>
              </Grid>

              {/* Display Saved Cars */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 2,
                    maxHeight: "300px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  {Array.isArray(carsList) &&
                    carsList.map((car, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          background: "white",
                          borderRadius: "10px",
                          padding: "10px",
                          boxShadow: `0 0 5px ${theme.palette.primary.shadowInput}`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            marginRight: "8px",
                          }}
                        >
                          {car.brand}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            marginRight: "8px",
                            color: theme.palette.gray.overlayBlack,
                          }}
                        >
                          | {car.model} | {car.year}
                        </Typography>
                        <IconButton
                          color="error"
                          onClick={() => handleRemoveCar(index)}
                        >
                          <RiDeleteBin6Line color={theme.palette.primary.red} />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <button
                  onClick={() => {
                    handlePrevious(values);
                  }}
                  style={{
                    ...inputStyles.btn,
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light,
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  Previous
                </button>
                <button
                  type="submit"
                  onClick={() => handleNext(validateForm, values, setErrors)}
                  style={{
                    ...inputStyles.btn,
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader color={theme.palette.primary.light} />
                  ) : (
                    "Next"
                  )}
                </button>
              </Grid>
            </Grid>
            {/* Vehicle Info Dialog */}
            <Dialog
              open={open}
              fullWidth
              onClose={() => setOpen(false)}
              key={JSON.stringify(errors)}
            >
              <DialogTitle>Vehicle Info</DialogTitle>
              <DialogContent sx={{ paddingTop: "20px" }}>
                <TextField
                  name="brand"
                  label="Vehicle Brand"
                  value={carData?.brand}
                  error={Boolean(vehicleFormErrors?.brand)}
                  onChange={handleCarDataChange}
                  fullWidth
                  sx={{
                    marginBottom: vehicleFormErrors?.brand ? "0px" : "20px",
                    marginTop: "20px",
                  }}
                />
                {vehicleFormErrors?.brand && (
                  <Typography
                    sx={{
                      color: theme.palette.primary.red,
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    {vehicleFormErrors?.brand}
                  </Typography>
                )}
                <TextField
                  name="model"
                  label="Vehicle Model *"
                  value={carData.model}
                  error={Boolean(vehicleFormErrors?.model)}
                  onChange={handleCarDataChange}
                  fullWidth
                  sx={{
                    marginBottom: vehicleFormErrors?.model ? "0px" : "20px",
                  }}
                />
                {vehicleFormErrors?.model && (
                  <Typography
                    sx={{
                      color: theme.palette.primary.red,
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    {vehicleFormErrors?.model}
                  </Typography>
                )}

                <TextField
                  name="year"
                  label="Vehicle Year *"
                  placeholder="Enter a year (not in the future)"
                  value={carData.year}
                  error={Boolean(vehicleFormErrors?.year)}
                  onChange={(e) => {
                    const value = e.target?.value;
                    const currentYear = new Date().getFullYear();

                    if (/^\d{0,4}$/.test(value)) {
                      if (value !== "" && parseInt(value) > currentYear) {
                        setErrors((prev) => ({
                          ...prev,
                          year: "Year cannot be in the future",
                        }));
                      } else {
                        setErrors((prev) => ({
                          ...prev,
                          year: "",
                        }));
                        handleCarDataChange(e);
                      }
                    }
                  }}
                  fullWidth
                  sx={{
                    marginBottom: vehicleFormErrors?.model ? "0px" : "20px",
                  }}
                />
                {vehicleFormErrors?.year && (
                  <Typography
                    sx={{
                      color: theme.palette.primary.red,
                      fontSize: "12px",
                      marginBottom: "15px",
                    }}
                  >
                    {vehicleFormErrors?.year}
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button
                  onClick={addCarToList}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
      <CustomPrompt when={isDirty} toNavigate={"/selling"} />
    </div>
  );
};

export default AdditionalDetails;
