import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  productsIndex,
  serviceIndex,
  productsAscIndex,
  productsDescIndex,
  serviceAscIndex,
  serviceDescIndex,
  productsNearestIndex,
  servicesNearestIndex,
} from "../../configs/algoliaIndexes";
import { message } from "antd";
import Loader from "../../components/common/Loader";
import {
  useTheme,
  Grid,
  PaginationItem,
  Pagination,
  Stack,
} from "@mui/material";
import ItemsProducts from "../../components/common/ItemsProducts";
import FilterSidebar from "./components/FilterSidebar";
import ItemsControl from "../../components/common/ItemsControl";
import { setCurrentProduct } from "../../redux/slices/appStateSlice";
import MapView from "./components/MapView";

const AirSystem = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [radius, setRadius] = useState(Math.round(500 * 1609.34));
  const { pathname, search } = useLocation();

  const pathSegments = pathname.split("/").filter(Boolean);
  const type = pathSegments[0];

  const query = new URLSearchParams(search);
  const name =
    pathname === "/filtered-services"
      ? query.get("service-name")
      : query.get("category-name");

  const [selectedName, setSelectedName] = useState(name);
  const categoryName = query.get("category-name");
  const servicesId = query.get("service-Id") || null;
  const categoryId = query.get("category-Id") || null;
  const serviceName = query.get("service-name");
  const userData = useSelector((state) => state.appState.userData);
  const [categoryIdProducts, setCategoryIdProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [minPriceAlgolia, setMinPriceAlgolia] = useState(null);
  const [maxPriceAlgolia, setMaxPriceAlgolia] = useState(null);
  const [sortOrderControle, setSortOrderControle] = useState("default");
  const [sortOrderPrice, setSortOrderPrice] = useState(null);
  
  const [selectedIdCatOrServ, setSelectedIdCatOrServ] = useState(null);
  const [selectedNameCatOrServ, setSelectedNameCatOrServ] = useState("");
  const [listingNearMe, setListingNearMe] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const viewFromUrl = query.get("view") || "list";
  const [showMap, setShowMap] = useState(viewFromUrl === "map");
  const [userLocation, setUserLocation] = useState({ lat: null, lng: null });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const isLoggedIn =
    userData &&
    !(Array.isArray(userData) && userData?.length === 0) &&
    typeof userData === "object" &&
    userData?.uid;

  const handleLocationChange = (newLatLng) => setLatLng(newLatLng);
  const handleRadiusChange = (newRadius) => setRadius(newRadius);

  const handleViewChange = (view) => {
    setShowMap(view === "map");
    query.set("view", view);
    navigate(`?${query.toString()}`);
  };

  const getIndex = useMemo(() => {
    if (listingNearMe && sortOrderControle === "default") {
      if (type === "filtered-categories") {
        return productsNearestIndex;
      }
      if (type === "filtered-services") {
        return servicesNearestIndex;
      }
    }

    if (sortOrderControle === "price_asc") {
      return type === "filtered-categories"
        ? productsAscIndex
        : serviceAscIndex;
    }
    if (sortOrderControle === "price_desc") {
      return type === "filtered-categories"
        ? productsDescIndex
        : serviceDescIndex;
    }

    return type === "filtered-categories" ? productsIndex : serviceIndex;
  }, [type, sortOrderControle, listingNearMe]);

  const filter = useMemo(() => {
    let baseFilter = "";
    if (type === "filtered-categories") {
      baseFilter = selectedIdCatOrServ
        ? `categoryId:${selectedIdCatOrServ}`
        : `categoryId:${categoryId}`;
    } else if (type === "filtered-services") {
      baseFilter = selectedIdCatOrServ
        ? `categoryId:${selectedIdCatOrServ}`
        : `categoryId:${servicesId}`;
    }
    const notDeleted =
      "isActive:true AND isDeleted:false AND isDraft:false AND isSold:false";
    baseFilter = baseFilter ? `${baseFilter} AND ${notDeleted}` : notDeleted;

    if (minPriceAlgolia && maxPriceAlgolia) {
      return `${baseFilter} AND (price:${Number(minPriceAlgolia)} TO ${Number(
        maxPriceAlgolia
      )})`;
    }

    return baseFilter;
  }, [
    type,
    categoryId,
    selectedIdCatOrServ,
    servicesId,
    minPriceAlgolia,
    maxPriceAlgolia,
  ]);

  const fetchProducts = useCallback(async () => {
    if (!getIndex) {
      console.warn("Missing index or coordinates for Algolia query.");
      return;
    }
    setIsLoading(true);
    try {
      const algoliaQuery = {
        filters: filter,
        hitsPerPage: showMap ? 1000 : 9,
        page: showMap ? 0 : currentPage - 1,
        query: searchTerm || "",

        // Check if listingNearMe is true, and allow user to override with custom address and radius
        ...(listingNearMe &&
        !latLng?.lat &&
        !latLng?.lng &&
        userLocation?.lat != null &&
        userLocation?.lng != null
          ? {
              aroundLatLng: `${userLocation?.lat}, ${userLocation?.lng}`,
              aroundRadius: "all", // Allow custom radius even with listingNearMe
            }
          : latLng?.lat != null && latLng?.lng != null
          ? {
              aroundLatLng: `${latLng.lat}, ${latLng.lng}`,
              aroundRadius: radius,
            }
          : {}),
      };
      const { hits, nbPages, nbHits } = await getIndex.search("", algoliaQuery);

      setTotalPages(showMap ? 1 : nbPages);
      setTotalItems(nbHits);
      setCategoryIdProducts(hits);
    } catch (error) {
      console.error(
        "Error fetching data from Algolia:",
        error.message || error
      );
      message.error(
        "An error occurred while fetching data. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    getIndex,
    showMap,
    radius,
    sortOrderControle,
    searchTerm,
    filter,
    sortOrderPrice,
    listingNearMe,
    latLng?.lat,
    latLng?.lng,
    userLocation?.lat,
    userLocation?.lng,
    currentPage,
  ]);

  useEffect(() => {
    fetchProducts(currentPage);
  }, [fetchProducts, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchProducts(value);
    window.scrollTo({ top: 500, behavior: "smooth" });
  };

  const handleSortOrderChange = (value) => {
    if (value !== "default") {
      setSelectedFilters((prev) => {
        const updatedFilters = (prev || []).filter(
          (filter) => !filter?.startsWith("price_")
        );
        return [...updatedFilters, value];
      });
    }
    if (value === "default") {
      setSelectedFilters((prev) => {
        return (prev || []).filter((filter) => !filter?.startsWith("price_"));
      });
    }
    setSortOrder(value);

    setSortOrderControle(value);
    setCurrentPage(1);
    setSortOrderPrice(null);
  };

  const goToProductPage = (id, product, nameToDisplay) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setCurrentProduct(product));
    if (type === "filtered-services") {
      navigate(`/service-details/${id}`, { state: { nameToDisplay } });
    } else {
      navigate(`/product/${id}`, { state: { nameToDisplay } });
    }
  };

  const handleOpenModal = () => {
    if (navigator.permissions && navigator?.permissions?.query) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          console.log(result, "result"); // Check what result is returned
          if (result?.state === "granted") {
            console.log("Geolocation permission granted");
            handleRequestLocation();
          } else if (result?.state === "prompt" || result?.state === "denied") {
            setListingNearMe(null);
            message.error(
              "Access to location denied. Please enable location access in your browser settings and try again."
            );
          }
        })
        .catch((error) => {
          console.warn("Permissions API query failed:", error);
          console.error("Permission query error:", error);
          handleRequestLocation(); // Fallback
        });
    } else {
      console.warn(
        "Permissions API is not supported, falling back to geolocation request."
      );
      handleRequestLocation(); // Fallback
    }
  };

  const handleRequestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          if (localStorage.getItem("locationPermission") !== "granted") {
            localStorage.setItem("locationPermission", "granted");
          }
        },
        (error) => {
          if (error.code === 1) {
            // User denied Geolocation
            setListingNearMe(null);
            message.error(
              "Access to location denied. Please enable location access in your browser settings."
            );
          } else {
            console.error("Error fetching location:", error);
            message.error("Failed to retrieve location. Please try again.");
          }
        }
      );
    } else {
      message.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const locationPermission = localStorage.getItem("locationPermission");
    if (locationPermission === "granted") {
      handleRequestLocation();
    } else {
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          if (result?.state === "granted") {
            handleRequestLocation();
          }
        });
      } else {
        // Fallback logic if permissions API is not available
        message.error("Geolocation is not supported in this browser..");
      }
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const categoryId = queryParams.get("category-Id");
    const serviceId = queryParams.get("service-Id");
    const initialFilter = categoryId || serviceId || null;

    setSelectedIdCatOrServ(initialFilter);

    if (initialFilter && !(selectedFilters || []).includes(initialFilter)) {
      setSelectedFilters((prev) => [...(prev || []), initialFilter]);
    }
  }, [search]);

  const updateSelectedFilters = (filter) => {
    if (!selectedFilters.includes(filter)) {
      setSelectedFilters((prev) => [...prev, filter]);
    }
  };
  
  
  return (
    <section style={{ margin: "0px 25px 0px 25px" }}>
      <Grid container spacing={2}>
        {/* Sidebar */}
        <Grid item xs={12} md={3.4}>
          <FilterSidebar
            onSearchChange={setSearchTerm}
            selectedName={selectedName}
            setSelectedName={setSelectedName}
            type={type}
            setSortOrderPrice={setSortOrderPrice}
            setSortOrderControle={setSortOrderControle}
            setSelectedIdCatOrServ={setSelectedIdCatOrServ}
            selectedIdCatOrServ={selectedIdCatOrServ}
            setMinPriceAlgolia={setMinPriceAlgolia}
            setMaxPriceAlgolia={setMaxPriceAlgolia}
            onLocationChange={handleLocationChange}
            onRadiusChange={handleRadiusChange}
            setSelectedNameCatOrServ={setSelectedNameCatOrServ}
            listingNearMe={listingNearMe}
            setListingNearMe={setListingNearMe}
            openLocationModal={handleOpenModal}
            selectedFilters={selectedFilters}
            updateSelectedFilters={updateSelectedFilters}
            setSelectedFilters={setSelectedFilters}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
        </Grid>

        {/* Main Content */}
        <Grid
          item
          xs={12}
          md={8.5}
          sx={{
            background: theme.palette.primary.light,
            padding: 2.2,
            borderRadius: "15px",
          }}
        >
          <ItemsControl
            totalItems={totalItems}
            type={type}
            categoryName={name}
            selectedNameCatOrServ={selectedNameCatOrServ}
            sortOrderControle={sortOrderControle}
            handleSortOrderChange={handleSortOrderChange}
            setShowMap={handleViewChange}
            showMap={showMap}
          />

          {/* Conditionally Render Map or Products */}
          {showMap && categoryIdProducts?.length > 0 ? (
            <Grid container>
              <MapView
                products={categoryIdProducts}
                type={type}
                goToProductPage={goToProductPage}
              />
            </Grid>
          ) : isLoading && !categoryIdProducts?.length ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <Loader />
            </Grid>
          ) : categoryIdProducts?.length > 0 ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {categoryIdProducts?.map((product) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xxl={3}
                  key={product?.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 3,
                  }}
                >
                  <ItemsProducts
                    item={product}
                    index={product?.id}
                    isLoggedIn={isLoggedIn}
                    goToPage={goToProductPage}
                    selectedName={name}
                    selectedNameCatOrServ={selectedNameCatOrServ}
                    type={type}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <img
                src={require("../../assets/Images/not_found.png")}
                alt="No data available"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
          )}

          <Stack
            spacing={2}
            sx={{
              mt: 4,
              paddingBottom: "35px",
            }}
          >
            {totalPages > 1 && !showMap && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& ul": {
                    justifyContent: "center",
                    marginTop: "25px",
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      margin: "auto",
                      justifyContent: "center",
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                        fontWeight: "bold",
                      },
                      "&:hover": {
                        backgroundColor: `${theme.palette.primary.main} !important`,
                        color: theme.palette.primary.light,
                      },
                      borderRadius: "50%",
                      marginRight: "5px",
                      minWidth: "40px",
                    }}
                  />
                )}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </section>
  );
};

export default AirSystem;
