import React, { useCallback, useEffect, useState } from "react";
import UserCard from "./component/UserCard";
import { db } from "../../configs/firebase";
import {
  Box,
  Grid,
  InputAdornment,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Loader from "../../components/common/Loader";
import CustomPagination from "../../components/common/CustomPagention";
import noSeller from "../../assets/Images/no-Items.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Algolia_App_Id,
  Algolia_Search_Api_Key,
} from "../../components/layout/constants";
import algoliasearch from "algoliasearch";
import { FiSearch } from "react-icons/fi";

const Sellers = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const productsPerPage = 8;

  const [sellerProfiles, setSellerProfiles] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.trim();
    if (searchTerm === "") {
      setSearchTerm("");
    } else if (event.key === "Enter" && searchTerm) {
      setSearchTerm(searchTerm);
    }
  };

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const typeFromURL = currentParams.get("type") || "All";
    setSelectedTab(typeFromURL);
    const tabIndex = sellerProfiles.findIndex(
      (tab) => tab.name === typeFromURL
    );
    setSelectedIndex(tabIndex >= 0 ? tabIndex : 0);
  }, [location.search, sellerProfiles]);

  const fetchSellerProfiles = async () => {
    setIsLoading(true);
    try {
      const sellerProfilesRef = db
        .collection("sellerProfiles")
        .orderBy("index", "asc");
      const sellerProfilesSnapshot = await sellerProfilesRef.get();
      const profiles = sellerProfilesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSellerProfiles(profiles);
    } catch (error) {
      console.error("Error fetching seller profiles:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSellerProfiles();
  }, []);

  const fetchUsersFromAlgolia = useCallback(async () => {
    setIsLoading(true);
    try {
      const mappedUserType = mapTabToUserType(selectedTab);
      const client = algoliasearch(Algolia_App_Id, Algolia_Search_Api_Key);

      // Construct the filter query dynamically
      let filterQuery = `isDeleted:false`;
      if (mappedUserType) {
        if (mappedUserType === "seller") {
          filterQuery += ` AND (userType:seller OR userType:all)`;
        } else if (mappedUserType === "serviceProvider") {
          filterQuery += ` AND (userType:serviceProvider OR userType:all)`;
        } else if (mappedUserType === "all") {
          filterQuery += ` AND (userType:seller OR userType:serviceProvider OR userType:all)`;
        }
      }

      console.log(filterQuery, "filterQuery");

      const { hits, nbPages } = await client
        .initIndex("users")
        .search(searchTerm, {
          hitsPerPage: productsPerPage,
          page: currentPage - 1,
          filters: filterQuery,
        });

      setFilteredUsers(hits);
      setTotalPages(nbPages || 1);

    } catch (error) {
      console.error("Error fetching users from Algolia:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedTab, searchTerm, currentPage]);

  useEffect(() => {
    fetchUsersFromAlgolia();
  }, [fetchUsersFromAlgolia]);

  // Map the selectedTab to the correct userType
  const mapTabToUserType = (tab) => {
    switch (tab) {
      case "Services Providers":
        return "serviceProvider";
      case "Sellers":
        return "seller";
      case "All":
        return "all";
      default:
        return tab.toLowerCase();
    }
  };

  const handleTabClick = (index, name) => {
    setSelectedIndex(index);
    setSelectedTab(name);
    const currentParams = new URLSearchParams(location.search);
    currentParams.set("type", name);
    navigate(`${location.pathname}?${currentParams.toString()}`);

    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    window.scrollTo({ top: 500, behavior: "smooth" });
    setCurrentPage(value);
  };

  const goToSellerPage = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/sellers/seller-details/${id}`, { state: { selectedTab } });
  };

  return (
    <Box sx={{ margin: "0 25px" }}>
      <Grid
        container
        sx={{
          background: theme.palette.primary.light,
          justifyContent: "space-between",
          mb: { xs: 0, md: 6 },
          alignItems: "center",
          borderRadius: '10px',
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",

            background: theme.palette.primary.light,
            padding: "10px",
            borderRadius: "10px",
            overflowX: "auto",
            whiteSpace: "nowrap",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {sellerProfiles?.map(({ name, id }, index) => (
            <ListItem
              key={id}
              onClick={() => handleTabClick(index, name)}
              sx={{
                padding: "7px 27px",
                borderRadius: "15px",
                width: "auto !important",
                cursor: "pointer",
                border:
                  selectedIndex === index
                    ? `1px solid ${theme.palette.primary.main}`
                    : `1px solid ${theme.palette.gray.borderFaild}`,
                background:
                  selectedIndex === index
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
                color:
                  selectedIndex === index
                    ? theme.palette.primary.light
                    : theme.palette.primary.darkMain,
                "&:hover": {
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                },
                flexShrink: 0,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {name}
              </Typography>
            </ListItem>
          ))}
        </Box>

        <Grid item xs={12} sm={3} md={5}>
          <TextField
            placeholder="Search"
            onChange={handleSearchChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSearchChange(event);
              }
            }}
            sx={{
              background: theme.palette.primary.light,
              width: "100%",
              height: "48px",
              fontSize: "16px",
              borderRadius: "10px",
              outline: "none",
              marginRight: "10px",
              my: "10px",

              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                marginRight: "20px",
                "&:hover fieldset": {
                  borderColor: theme.palette.primary.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary.main,
                },
              },
              "& .MuiInputBase-input": {
                padding: "0px",
                height: "48px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.primary.light}`,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: "24px",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {isLoading ? (
        <Grid
          container
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Loader />
        </Grid>
      ) : filteredUsers?.length > 0 ? (
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          {filteredUsers?.map((user) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={2.5}
              lg={3}
              xxl={3}
              key={user?.uid}
              sx={{
                marginBottom: 3,
              }}
            >
              <UserCard key={user.uid} user={user} goToPage={goToSellerPage} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            minHeight: "100vh",
            flexDirection: "column",
          }}
        >
          <img
            src={noSeller}
            alt="No data available"
            style={{ maxWidth: "100%", height: "auto" }}
          />

          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.typography.headerFont,
              color: theme.palette.dark.charcoalBlack,
            }}
          >
            {" "}
            No users found for {selectedTab}{" "}
          </Typography>
        </Grid>
      )}
    
      {filteredUsers.length > 0 && (
        <CustomPagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default Sellers;
