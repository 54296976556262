import {
  Box,
  Stack,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ProductActionButtons from "./ProductActionButtons";
import { IoRemove, IoAdd } from "react-icons/io5";

import { useEffect, useState } from "react";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../configs/firebase";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCartProducts,
  setCurrentProduct,
  setUserData,
} from "../../../redux/slices/appStateSlice";
import { useDispatch } from "react-redux";
import { calculateCheckout } from "../../../components/common/calculateCheckout";

const ProductItem = ({
  product,
  theme,
  handleQuantityIncrement,
  handleQuantityDecrement,
  handleRemoveFromCart,
  allProducts,
}) => {
  // Find the product from allProducts to check its available quantity
  const productInAllProducts = allProducts.find((p) => p.id === product?.id);
  const [sellerData, setSellerData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedShippingOption, setSelectedShippingOption] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.appState.userData);
  const cartProducts = useSelector((state) => state.appState.cartProducts);
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const [categoryName, setCategoryName] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const shippingOptions = [];

  useEffect(() => {
    const fetchSellerData = async () => {
      if (product?.seller_id) {
        try {
          const sellerDocRef = doc(db, "users", product.seller_id);
          const sellerDoc = await getDoc(sellerDocRef);
          if (sellerDoc.exists()) {
            setSellerData(sellerDoc.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching seller data: ", error);
        }
      }
    };

    fetchSellerData();
  }, [product]);

  useEffect(() => {
    if (
      user &&
      !(Array.isArray(user) && user?.length === 0) &&
      typeof user === "object" &&
      user?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productDocRef = doc(db, "products", product.id);
        const productDoc = await getDoc(productDocRef);
        if (productDoc.exists()) {
          setProductDetails(productDoc.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product details: ", error);
      }
    };

    fetchProductDetails();
  }, [product?.id]);

  const handleGoToProfile = () => {
    if (isLoggedIn) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(`/sellers/seller-details/${sellerData?.uid}`);
    } else {
      navigate("/login");
    }
  };
  const getCategoryName = (categoryId) => {
    const category = itemsCategories?.find((cat) => cat?.id === categoryId);
    return category ? category?.name : "Unknown Category";
  };
  const goToProductPage = (id) => {
    if (categoryName) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch(setCurrentProduct(productDetails));
      navigate(`/product/${id}`, { state: { nameToDisplay: categoryName } });
    }
  };
  useEffect(() => {
    if (productDetails) {
      const name = getCategoryName(productDetails?.categoryId);
      setCategoryName(name);
    }
  }, [productDetails]);

  useEffect(() => {
    if (productDetails) {
      if (productDetails?.isLocalPickup) {
        shippingOptions.push({ name: "Local Pickup", price: 0 });
      }
      if (productDetails?.companies) {
        shippingOptions.push(...productDetails.companies);
      }

      const initialShippingOption = shippingOptions.reduce((lowest, option) =>
        option.price < lowest.price ? option : lowest
      );
      setSelectedShippingOption(initialShippingOption.price);

      const updatedCartProducts = cartProducts.map((item) =>
        item.id === product?.id
          ? { ...item, shipping_price: initialShippingOption.price }
          : item
      );

      dispatch(setCartProducts(updatedCartProducts));
      dispatch(
        setUserData({
          ...user,
          cart: { ...user?.cart, items: updatedCartProducts },
        })
      );
    }
  }, [productDetails]);

  const handleShippingOptionChange = async (event) => {
    const selectedPrice = event.target.value;
    const selectedOption = shippingOptions.find(
      (option) => option.price === selectedPrice
    );
    setSelectedShippingOption(selectedPrice);

    const isLocalPickup = selectedOption?.name === "Local Pickup";
    // Update the cart with the new shipping price
    const updatedCartProducts = cartProducts?.map((item) =>
      item.id === product?.id
        ? {
            ...item,
            shipping_price: selectedPrice,
            shipping_company: isLocalPickup ? null : selectedOption?.name,
            isLocalPickup: isLocalPickup,
          }
        : item
    );

    dispatch(setCartProducts(updatedCartProducts));

    // Update Firebase with the new cart
    dispatch(
      setUserData({
        ...user,
        cart: { ...user?.cart, items: updatedCartProducts },
      })
    );

    try {
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, {
        "cart.items": updatedCartProducts,
      });

      await calculateCheckout(user?.uid, dispatch);
    } catch (error) {
      console.error(
        "Error updating shipping cost or calculating totals:",
        error
      );
    }
  };

  if (productDetails?.isLocalPickup) {
    shippingOptions.push({ name: "Local Pickup", price: 0 });
  }
  if (productDetails?.companies) {
    shippingOptions.push(...productDetails.companies);
  }

  console.log(shippingOptions, "shippingOptions");
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderBottom: `1px solid ${theme.palette.neutral.grayBorder}`,
        paddingBottom: "10px",
        marginBottom: "10px",
      }}
    >
      <Stack direction="row" alignItems="center" width="100%" gap={2}>
        <img
          src={product?.image}
          alt={product?.title}
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "8px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => goToProductPage(product?.id)}
        />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.darkBlack,
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={() => goToProductPage(product?.id)}
          >
            {product?.title}
          </Typography>
          {product?.isSold && (
            <Typography
              variant="body2"
              sx={{ color: "red", fontWeight: "bold" }}
            >
              Out of Stock
            </Typography>
          )}
          {product?.isDraft && (
            <Typography
              variant="body2"
              sx={{ color: "red", fontWeight: "bold" }}
            >
              Out of Stock
            </Typography>
          )}
          {product?.contactForPrice && (
            <Typography
              variant="body2"
              sx={{ color: "orange", fontWeight: "bold" }}
            >
              Contact Seller for Price
            </Typography>
          )}
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.primary.lightWhiteGrey,
              letterSpacing: "0.8px",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
            onClick={handleGoToProfile}
          >
            seller:{" "}
            {sellerData?.businessInfo?.name
              ? sellerData?.businessInfo?.name?.length > 15
                ? `${sellerData?.businessInfo?.name.substring(0, 15)}...`
                : sellerData?.businessInfo?.name
              : ""}
          </Typography>

          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            gap={1}
          >
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.primary.lightWhiteGrey,
                letterSpacing: "0.8px",
                textTransform: "capitalize",
              }}
            >
              shipping cost:
            </Typography>
            {shippingOptions.length > 0 && (
              <FormControl>
                <Select
                  value={product?.shipping_price || selectedShippingOption}
                  onChange={handleShippingOptionChange}
                  sx={{
                    maxWidth: "200px",
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "8px",
                    fontSize: "14px",
                    padding: "5px 5px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    "& .MuiSelect-select": {
                      padding: "10px 14px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.secondary.main,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.primary.main,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.primary.dark,
                    },
                  }}
                >
                  {shippingOptions?.map((option, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                      }}
                      value={option?.price}
                    >
                      {option?.name} - {option?.price > 0 && "$"}
                      {option?.price === 0 ? "Free" : option?.price?.toFixed(2)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>

          <ProductActionButtons
            handleRemoveFromCart={handleRemoveFromCart}
            theme={theme}
            product={product}
          />
        </Box>
      </Stack>
      {/* quantity control */}
      <Stack
        direction={{ xs: "row-reverse", sm: "column" }}
        sx={{
          width: { xs: "100%", sm: "auto" },
          marginTop: { xs: "10px", sm: "0" },
        }}
        alignItems={{ xs: "center", sm: "flex-end" }}
        justifyContent={{ xs: "space-between", sm: "flex-end" }}
        gap={1}
      >
        <Typography
          sx={{
            color: theme.palette.primary.darkBlack,
            textAlign: "end",
          }}
          variant="h6"
        >
          ${(product?.price * product?.quantity)?.toFixed(2)}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            borderRadius: "10.625rem",
            padding: "8px",
            border: `1px solid ${theme.palette.neutral.lightGrayBorder}`,
            width: "6.563rem",
          }}
        >
          <IconButton
            onClick={() => handleQuantityDecrement(product?.id)}
            disabled={product?.quantity === 1}
            sx={{
              backgroundColor:
                product?.quantity !== 1
                  ? theme.palette.primary.main
                  : theme.palette.primary.light,
              width: "24px",
              height: "24px",
              border:
                product?.quantity !== 1
                  ? `1px solid ${theme.palette.primary.main}`
                  : `1px solid ${theme.palette.primary.lightGray}`,
              color: theme.palette.primary.light,
              "&:hover": {
                backgroundColor:
                  product?.quantity !== 1
                    ? theme.palette.primary.main
                    : theme.palette.primary.lightGray,
                color:
                  product?.quantity !== 1
                    ? theme.palette.primary.light
                    : theme.palette.primary.darkBlack,
              },
            }}
            size="small"
          >
            <IoRemove />
          </IconButton>
          <Typography variant="body1">{product?.quantity}</Typography>
          <IconButton
            onClick={() => handleQuantityIncrement(product?.id)}
            disabled={
              productInAllProducts &&
              product?.quantity === productInAllProducts?.quantity
            } // Disable when quantity equals available stock
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: "25px",
              height: "25px",
              border:
                productInAllProducts &&
                product?.quantity === productInAllProducts?.quantity
                  ? `1px solid ${theme.palette.primary.lightGray}`
                  : `1px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.light,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.light,
              },
            }}
            size="small"
          >
            <IoAdd />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProductItem;
