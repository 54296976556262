import React, { useState } from 'react';
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Box, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { setFormTouched } from '../../../../redux/slices/appStateSlice';
import { useDispatch } from 'react-redux';

const PasswordInput = ({ label, id, inputStyle, sharedStyles, field, form }) => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch=useDispatch()
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleInputChange = (event) => {
    field.onChange(event); 
    dispatch(setFormTouched())
  };

  return (
    <Box>
      <InputLabel htmlFor={id} sx={inputStyle}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        sx={{ ...sharedStyles, width: {
          xs:"100%",
          xl:"90%"
        } }}
        onInput={handleInputChange}
        type={showPassword ? "text" : "password"}
        {...field}
        error={form.touched[field.name] && Boolean(form.errors[field.name])}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={`toggle ${label.toLowerCase()} visibility`}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <BsEye /> : <BsEyeSlash />}
            </IconButton>
          </InputAdornment>
        }
      />
      {form.touched[field.name] && form.errors[field.name] && (
        <Typography variant="caption" sx={{display:"block", marginTop:"10px"}}  style={{ color: "red" }}>
          {form.errors[field.name]}
        </Typography>
      )}
    </Box>
  );
};

export default PasswordInput;
