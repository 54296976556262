import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { IoRemoveSharp } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";
import { db } from "../../../configs/firebase";
import {
  handleQuantityDecrement,
  handleQuantityIncrement,
  updateCartInFirebase,
} from "../../../utils/cartUtils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCartProducts } from "../../../redux/slices/appStateSlice";

const Cart = ({ onCartDataChange, isCartEmpty }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [products, setProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const [sellerData, setSellerData] = useState([]);

  const [sellerIds, setSellerIds] = useState([]);
  const [filteredItemsBySeller, setFilteredItemsBySeller] = useState({});
  const [totalBySeller, setTotalBySeller] = useState({});
  const userData = useSelector((state) => state.appState.userData);
  const cartProducts = useSelector((state) => state.appState.cartProducts);
  const [selectedDeliveryOptions, setSelectedDeliveryOptions] = useState({});


  useEffect(() => {
    if (cartProducts?.length === 0) {
      navigate("/");
    }
  }, [cartProducts]);

  useEffect(() => {
    if (
      userData &&
      Object.keys(userData)?.length !== 0 &&
      userData?.cart &&
      userData?.cart?.items &&
      userData?.cart?.items?.length > 0
    ) {
      fetchProductsData(userData?.cart?.items);
      // calculateSubtotal(userData?.cart?.items);
      fetchUserData(userData?.cart?.items);
    }
    setIsLoading(false);
  }, [userData]);

  const fetchProductsData = async (cartItems) => {
    try {
      const productIds = cartItems?.map((item) => item?.id);
      const productsRef = db
        .collection("products")
        .where("isDeleted", "==", false);
      const query = productsRef.where("id", "in", productIds);

      query.onSnapshot((querySnapshot) => {
        let fetchedProducts = [];
        querySnapshot.forEach((doc) => {
          fetchedProducts.push(doc.data());
        });
        setProductData(fetchedProducts);
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  };

  const fetchUserData = async (cartItems) => {
    try {
      const sellerIds = cartItems?.map((item) => item?.seller_id);
      setSellerIds(sellerIds);
      const productsRef = db.collection("users");
      const query = productsRef.where("uid", "in", sellerIds).where("isDeleted", "==", false);

      query.onSnapshot((querySnapshot) => {
        let fetchedSellers = [];
        querySnapshot.forEach((doc) => {
          fetchedSellers.push(doc.data());
        });
        setSellerData(fetchedSellers);

        filterItemsBySeller(cartItems, sellerIds);
      });
    } catch (error) {
      console.error("Error fetching fetchedSellers:", error);
      setIsLoading(false);
    }
  };

  const filterItemsBySeller = (cartItems, sellerIds) => {
    let filteredItems = {};
    let totals = {};

    sellerIds.forEach((sellerUid) => {
      const itemsForSeller = cartItems?.filter(
        (item) => item?.seller_id === sellerUid
      );

      filteredItems[sellerUid] = itemsForSeller;

      // Calculate subtotal and shipping price for each seller
      const sellerTotal = itemsForSeller?.reduce((acc, item) => {
        return acc + item?.price * item?.quantity;
      }, 0);

      const shippingTotal = itemsForSeller?.reduce((acc, item) => {
        return acc + item?.shipping_price;
      }, 0);

      // Update the totals for this seller
      totals[sellerUid] = {
        subtotal: sellerTotal,
        shipping_price: shippingTotal,
        total: sellerTotal + shippingTotal, // The final total for this seller
      };
    });

    setTotalBySeller(totals);
    setFilteredItemsBySeller(filteredItems);

    onCartDataChange(filteredItems, totals, sellerData, sellerIds);
  };

  const calculateSubtotal = (items) => {
    const total = items.reduce((acc, item) => {
      return acc + item?.price * item?.quantity;
    }, 0);

    setSubtotal(total);
  };

  const handleIncrease = (id) => {
    handleQuantityIncrement(
      id,
      productData, // Pass the product data for stock verification
      cartProducts, // Current products in the cart
      userData, // Current user data
      dispatch, // Redux dispatch to update state
      ({ subtotal, tax, totalWithTax }) => {
        setSubtotal(subtotal);
        setTotalBySeller({ ...totalBySeller, tax, totalWithTax });
      }
    );
  };

  const handleDecrease = (id) => {
    handleQuantityDecrement(
      id,
      cartProducts, // Current products in the cart
      userData, // Current user data
      dispatch, // Redux dispatch to update state
      ({ subtotal, tax, totalWithTax }) => {
        setSubtotal(subtotal);
        setTotalBySeller({ ...totalBySeller, tax, totalWithTax });
      }
    );
  };

  useEffect(() => {
    if (
      Object.keys(filteredItemsBySeller)?.length > 0 &&
      Object.keys(totalBySeller)?.length > 0
    ) {
      onCartDataChange(
        filteredItemsBySeller,
        totalBySeller,
        sellerData,
        sellerIds,
        productData,
        selectedDeliveryOptions
      );
    }
  }, [
    filteredItemsBySeller,
    totalBySeller,
    sellerData,
    sellerIds,
    productData,
    selectedDeliveryOptions,
  ]);

  const handleDeliveryChange = (productId, company) => {
    const selectedCompany = JSON.parse(company);
    setSelectedDeliveryOptions((prevOptions) => ({
      ...prevOptions,
      [productId]: selectedCompany,
    }));

    const updatedCartProducts = cartProducts.map((item) => {
      if (item?.id === productId) {
        return {
          ...item,
          shipping_price:
            selectedCompany?.name === "Local Pickup" ? 0 : selectedCompany?.price,
        };
      }
      return item;
    });

    dispatch(setCartProducts(updatedCartProducts));
    calculateSubtotal(updatedCartProducts);
    updateCartInFirebase(userData, updatedCartProducts);
  };

  const calculateTotalShippingPrice = (items) => {
    return items?.reduce((acc, item) => {
      const selectedOption = selectedDeliveryOptions[item?.id];
      return (
        acc +
        (selectedOption ? selectedOption?.price : item?.shipping_price || 0)
      );
    }, 0);
  };
  useEffect(() => {
    if (
      productData?.length > 0 &&
      Object.keys(selectedDeliveryOptions)?.length === 0
    ) {
      const initialDeliveryOptions = {};

      const updatedCartProducts = cartProducts.map((item) => {
        const product = productData.find((p) => p?.id === item?.id);

        if (product?.companies?.length > 0) {
          const selectedCompany = product?.companies?.find(
            (company) => company?.price === item?.shipping_price
          );

          // If shipping_price is matched, use that; otherwise, set to Local Pickup or default to first option
          initialDeliveryOptions[item?.id] = selectedCompany || {
            name: "Local Pickup",
            price: 0,
          };

          return {
            ...item,
            shipping_price: initialDeliveryOptions[item?.id].price,
          };
        } else {
          // If no companies available, default to Local Pickup
          initialDeliveryOptions[item?.id] = { name: "Local Pickup", price: 0 };
          return { ...item, shipping_price: 0 };
        }
      });

      setSelectedDeliveryOptions(initialDeliveryOptions);
      calculateSubtotal(updatedCartProducts);
    }
  }, [productData, cartProducts, selectedDeliveryOptions]);
  const totalPrice = subtotal + calculateTotalShippingPrice(cartProducts);


  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ margin: "0 9px" }}>
      <Box
        sx={{
          maxHeight: "500px",
          overflowY: "auto",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {isCartEmpty ? (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            Your cart is now empty. Thank you for your order!
          </Typography>
        ) : cartProducts?.length > 0 ? (
          cartProducts?.map((product, index) => {
            return (
              <Box
                key={product?.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom:
                    index !== cartProducts?.length - 1
                      ? `1px solid ${theme.palette.secondary.background}`
                      : "none",
                  marginBottom: "15px",
                  paddingBottom: "10px",
                  paddingX: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                    alignItems: {
                      xs: "flex-start",
                      sm: "center",
                    },
                    width: {
                      xs: "30%",
                      sm: "40%",
                    },
                  }}
                >
                  <Box sx={{ width: "85px", height: "80px" }}>
                    <img
                      src={product?.image}
                      alt="img-productCart"
                      width="100%"
                      height="100%"
                      style={{ objectFit: "cover", borderRadius: "10px" }}
                    />
                  </Box>
                  <Box sx={{ marginLeft: "15px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        color: theme.palette.dark.colorCart,
                      }}
                    >
                      {product?.title?.length > 15
                        ? `${product.title.substring(0, 15)}...`
                        : product.title}
                    </Typography>
                    {(product?.isSold ||
                      product?.isDraft ||
                      product?.contactForPrice) && (
                      <Typography
                        variant="body2"
                        sx={{ color: "red", fontWeight: "bold" }}
                      >
                        {product?.isSold
                          ? "Out of Stock"
                          : product?.isDraft
                          ? "Out of Stock"
                          : "Contact Seller for Price"}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Select
                  sx={{
                    width: "25%",
                    maxWidth: "300px",
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "8px",
                    padding: "5px 10px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    "& .MuiSelect-select": {
                      padding: "10px 14px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.secondary.main,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.primary.main,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.primary.dark,
                    },
                  }}
                  value={
                    selectedDeliveryOptions[product?.id]
                      ? JSON.stringify(selectedDeliveryOptions[product?.id])
                      : ""
                  }
                  onChange={(e) =>
                    handleDeliveryChange(product.id, e.target.value)
                  }
                >
                  {productData.find((p) => p.id === product?.id)
                    ?.isLocalPickup && (
                    <MenuItem
                      value={JSON.stringify({ name: "Local Pickup", price: 0 })}
                    >
                      Local Pickup
                    </MenuItem>
                  )}
                  {productData
                    .find((p) => p?.id === product?.id)
                    ?.companies?.map((company) => (
                      <MenuItem
                        key={company?.name}
                        value={JSON.stringify(company)}
                      >
                        {company?.name} - ${company?.price}
                      </MenuItem>
                    ))}
                </Select>

                <Stack gap={1} sx={{ width: "20%" }}>
                  {product?.price && product?.quantity ? (
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 800, textAlign: "end" }}
                      >
                        $
                        {Math.round(product?.price * product?.quantity * 100) /
                          100}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body1" sx={{ textAlign: "end" }}>
                      0
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <IconButton
                      onClick={() => handleDecrease(product?.id)}
                      sx={{
                        border: `1px solid ${theme.palette.secondary.background}`,
                        padding: "5px !important",
                        backgroundColor: theme.palette.action.selected,
                      }}
                      disabled={product?.quantity <= 1}
                    >
                      <IoRemoveSharp style={{ fontSize: "1.4rem" }} />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        padding: "0 10px",
                      }}
                    >
                      {product?.quantity}
                    </Typography>
                    <IconButton
                      onClick={() => handleIncrease(product?.id)}
                      sx={{
                        border: `1px solid ${theme.palette.secondary.background}`,
                        padding: "5px !important",
                        background: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                        },
                      }}
                      disabled={
                        product?.quantity >=
                        productData?.find((p) => p?.id === product?.id)
                          ?.quantity
                      }
                    >
                      <GoPlus style={{ fontSize: "1.4rem" }} />
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            );
          })
        ) : (
          <Typography variant="body1">No products in the cart</Typography>
        )}
      </Box>
      {/* Summary Section */}
      <Box
        sx={{
          marginTop: "50px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: theme.palette.text.colorSummary,
            fontSize: "1.3rem",
            marginBottom: "20px",
          }}
        >
          Summary:
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, color: theme.palette.gray.colorSummary }}
          >
            Subtotal:
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, color: theme.palette.gray.colorSummary }}
          >
            ${Math?.round(subtotal * 100) / 100}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: theme.palette.gray.colorSummary, margin: "6px 0" }}
          >
            Delivery Fee:
          </Typography>
          <Typography variant="body1">
            {" "}
            {calculateTotalShippingPrice(cartProducts) === 0
              ? "Free"
              : `$${calculateTotalShippingPrice(cartProducts)}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Total Price:
          </Typography>
          <Typography variant="h6">${totalPrice?.toFixed(2)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Cart;
