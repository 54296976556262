
export const buttonStyle = (theme) => ({
  borderRadius: "100px",
  padding: "6px 16px",
  border: `1px solid ${theme.palette.primary.blueProgress}`,
  backgroundColor: theme.palette.primary.lightIceBlue,
  color: theme.palette.primary.main,
  fontSize: "10px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: theme.palette.primary.lightIceBlue,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down("xl")]: {
    padding: "4px 12px",
    fontSize: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "4px 12px",
    fontSize: "8px",
  },
 
});

export const tabStyles = (theme) => ({
  Tabs: (bgColor) => ({
    backgroundColor: bgColor,
    "& .MuiTabs-indicator": {
      display: "none",
    },
    borderRadius: "72px",
    padding: "5px 15px",
  }),
  Tab: (isActive) => ({
    textTransform: "none",
    fontSize: "14px",
    fontFamily: theme.typography.headerFont,
    color: isActive ? theme.palette.primary.main : theme.palette.primary.arrow,
    backgroundColor: isActive ? theme.palette.primary.light : "transparent",
    border: "1px solid",
    borderColor: isActive ? theme.palette.primary.borderIcon : "transparent",
    borderRadius: "16px",
    paddingX: 2,
    minHeight: "34px",
    minWidth: "90px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      minWidth: "70px",
    },
  }),
});

export const chatContainerStyle = (theme, imageFile) => ({
  height: "80vh",
  paddingX: "30px",
  display: "flex",
  flexDirection: "row", 
  [theme.breakpoints.down("xl")]: {
    height: "auto",
    flexDirection:  "row",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", 
    paddingX: "10px",
  },
});

export const leftSectionStyle = (theme) => ({
  width: "40%",
  backgroundColor: theme.palette.primary.light,
  borderRadius: "8px",
  padding: "20px",
 
  [theme.breakpoints.down("xl")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
});

export const rightSectionStyle = (theme) => ({
  backgroundColor: theme.palette.primary.light,
  width: "60%",
  borderRadius: "8px",
 
  [theme.breakpoints.down("xl")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
});

export const chatHeaderStyle = (theme) => ({
  borderBottom: `1px solid ${theme.palette.primary.borderBtnGrey}`,
  paddingBottom: "12px",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "8px",
  },
});

export const chatInputBoxStyle = (theme) => ({
  backgroundColor: theme.palette.primary.greyLightOpacity,
  height: "60px",
  borderRadius: "8px",
  width: "100%",
  "& fieldset": {
    borderColor: theme.palette.gray.border,
  },
  "&:hover fieldset": {
    borderColor: theme.palette.gray.border,
  },
  "&.Mui-focused fieldset": {
    borderColor: theme.palette.gray.border,
  },
  "& .MuiInputBase-input": {
    padding: "10px 14px",
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "50px",
    "& .MuiInputBase-input": {
      padding: "8px 12px",
      fontSize: "12px",
    },
  },
});

export const imagePreviewStyle = (theme) => ({
  width: "40px",
  height: "40px",
  objectFit: "cover",
  borderRadius: "5px",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
    height: "30px",
  },
});

export const centeredMessageContainerStyle = (theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  textAlign: "center",
  flexDirection: "column",
  padding: "20px",
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
  },
});

export const chatListScrollContainerStyle = (theme) => ({
  padding: 2,
  overflowX: "hidden",
  overflowY: "auto",
  maxHeight: "calc(100vh - 180px)",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "calc(100vh - 140px)",
  },
});

export const chatItemThumbnailStyle = (theme) => ({
  width: "81px",
  height: "81px",
  objectFit: "cover",
  borderRadius: "8px",
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    height: "60px",
  },
});
