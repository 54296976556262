import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";

const Sidebar = ({ title, items, selectedIndex, onSelect, isService }) => {
  const theme = useTheme();
  const isFullscreen = useSelector((state) => state.appState.isFullScreen);

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        borderRadius: 3,
        padding: 2,
      }}
    >
      {isFullscreen === true && (
        <Box
          sx={{
            background: theme.palette.neutral.boxGray,
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 800,
              lineHeight: "1.3rem",
              color: theme.palette.primary.darkMain,
              textTransform: "uppercase",
              padding: "8px",
            }}
          >
            {title}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          // height: "685px",
          height: "140rem",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <List>
          {items?.map((item, index) => (
            <ListItem
              key={item?.id}
              onClick={() => onSelect(index)}
              sx={{
                cursor: "pointer",
                padding: "8px 0px",
                borderRadius: "12px",
                width: "auto",
                background:
                  selectedIndex === index
                    ? theme.palette.primary.main
                    : theme.palette.neutral.light,
                color:
                  selectedIndex === index
                    ? theme.palette.primary.light
                    : theme.palette.primary.darkColor,
                marginTop: "10px",
                "&:hover": {
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  justifyContent: "center",
                }}
              >
                {/* Display Tooltip only if fullscreen is true */}
                {isFullscreen === false ? (
                  <Tooltip title={item?.name} arrow placement="right">
                    <Box
                      component="img"
                      src={item?.image}
                      alt={item?.name}
                      sx={{
                        width: "40px",
                        height: "35px",
                        objectFit: isService ? "contain" : "cover",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Box
                    component="img"
                    src={item?.image}
                    alt={item?.name}
                    sx={{
                      width: "40px",
                      // height: "35px",
                      // objectFit: isService ? "contain" : "cover",
                    }}
                  />
                )}
                {isFullscreen === false ? null : (
                  <ListItemText
                    primary={item?.name}
                    sx={{
                      "& .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary":
                        {
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: isService ? "1.3rem" : "1.4rem",
                          display: "block",
                        },
                    }}
                  />
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;
