import { CircularProgress, Box, Typography,useTheme  } from '@mui/material';
const CircularStepProgress = ({ steps=3, currentStep }) => {
  const theme=useTheme()
  const progress = (currentStep / steps) * 100;
  return (
    <Box position="relative" display="inline-flex">
    {/* CircularProgress  (uncompleted part) */}
    <CircularProgress
      variant="determinate"
      value={100} // Full circle
      size={50}
      thickness={3.5}
      style={{ color: theme.palette.primary.sky, position: 'absolute' }} 
    />
    {/* CircularProgress for the  (completed part) */}
    <CircularProgress
      variant="determinate"
      value={progress}
      size={50}
      thickness={3.5}
      style={{ color: theme.palette.primary.blueProgress }} 
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography  component="div"  sx={{fontWeight:"800",color: theme.palette.primary.blueProgress,fontSize:"17px",lineHeight:"24.1px"}}>
        {`${currentStep}/${steps}`}
      </Typography>
    </Box>
  </Box>
  );
};
export default CircularStepProgress
