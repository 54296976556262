import { TableCell, TableHead, TableRow, Typography } from "@mui/material";

const TableHeader = ({ headerTextStyles }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: "none" }}>
          <Typography variant="body2" fontWeight="bold" sx={headerTextStyles}>
            product
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          <Typography variant="body2" fontWeight="bold" sx={headerTextStyles}>
            price
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          <Typography variant="body2" fontWeight="bold" sx={headerTextStyles}>
            qty
          </Typography>
        </TableCell>

        <TableCell sx={{ borderBottom: "none" }}>
          <Typography variant="body2" fontWeight="bold" sx={headerTextStyles}>
            subtotal
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          <Typography variant="body2" fontWeight="bold" sx={headerTextStyles}>
            shipping fee
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          <Typography variant="body2" fontWeight="bold" sx={headerTextStyles}>
            status
          </Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          <Typography variant="body2" fontWeight="bold" sx={headerTextStyles}>
            actions
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
