import React from "react";

// import Requests from "./components/Requests.jsx";
import Category from "./components/Category.jsx";
import WhyTruck from "./components/WhyTruck.jsx";
import Products from "./components//Products.jsx";
import Services from "./components/Services.jsx";
import { useTheme } from "@mui/material";
import RequestsHome from "./components/RequestHome.jsx";

const Home = () => {
  const theme = useTheme();

  return (
    <>
      <div
        style={{
          background: theme.palette.primary.background,
        }}
      >
        <Category />
        <RequestsHome />
        <Products />
        <Services />
        <WhyTruck />
      </div>
    </>
  );
};

export default Home;
