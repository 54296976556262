export const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f4f4f4", // Light grayish background
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off", // Hide default icons for a cleaner look
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#5c5c5c", // Dark gray for text to contrast with background
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff", // White stroke to make text more readable
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#a0a0a0", // Soft gray for administrative text
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#d4d4d4", // Lighter gray for points of interest
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#525252", // Dark gray for POI labels
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#b0e6a0", // Soft green for parks
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#418c5a", // Green for park labels
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff", // White roads to stand out
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c0c0c0", // Light gray borders around roads
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#525252", // Dark gray for arterial road labels
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#e0e0e0", // Light gray for highways
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#333333", // Darker gray for highway labels
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#525252", // Dark gray for local roads
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#d4d4d4", // Light gray for transit lines
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#d4d4d4", // Light gray for transit stations
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#a0c4ff", // Dark blue for water areas
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#1a4c8c", // Medium blue for water labels
      },
    ],
  },
];
