import { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  useTheme,
  Button,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { message } from "antd";
import { db } from "../../../../configs/firebase";
import { useDispatch, useSelector } from "react-redux";
import { setUserAddressData } from "../../../../redux/slices/appStateSlice";
import DeleteBox from "../../../../components/common/DeleteBox";

const AddressCard = ({ address }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.appState.userData);
  const userAddressData = useSelector(
    (state) => state.appState.userAddressData
  );
  const [openDialog, setOpenDialog] = useState(false);
  const handleEdit = () => {
    navigate(`/profile?page=addresses&layout=editAddress&id=${address.id}`);
  };

  const handleDelete = () => {
    if (address?.isDefault) {
      message.error("Sorry, this is the default address and can't be deleted.");
      return;
    }
    setOpenDialog(true);
  };
  const handleConfirmDelete = async () => {
    try {
      const ref = db
        .collection("users")
        .doc(currentUser?.uid)
        .collection("addresses")
        .doc(address?.id);
      await ref.delete();
      const updatedAddresses = userAddressData.filter(
        (item) => item.id !== address.id
      );
      dispatch(setUserAddressData(updatedAddresses));
      message.success("Address deleted successfully");
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting address: ", error);
      message.error("Failed to delete the address");
      setOpenDialog(false);
    }
  };
  return (
    <Stack
      sx={{
        borderRadius: "10px",
        border: `solid 1px ${theme.palette.action.selected}`,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.primary.whiteSmoke,
          padding: "10px 24px",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "500",
            color: theme.palette.primary.lightDoveGrey,
            textTransform: "capitalize",
          }}
        >
          shipping address
        </Typography>
        {address?.isDefault && <Chip label="Default" color="primary" />}
        <Box
          style={{
            display: "flex",
          }}
        >
          <IconButton onClick={handleEdit}>
            <BiEditAlt
              style={{
                fontSize: "20px",
                color: theme.palette.primary.main,
                fontWeight: "500",
              }}
            />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <MdDelete
              style={{
                fontSize: "20px",
                color: theme.palette.primary.red,
                fontWeight: "500",
              }}
            />
          </IconButton>
        </Box>
      </Stack>
      <Stack
        gap={2}
        sx={{
          paddingY: "20px",
          paddingX: "20px",
          color: theme.palette.neutral.MoustacheGrey,
          textTransform: "capitalize",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "500" }}>
          Name:{" "}
          <Box component="span" sx={{ color: theme.palette.neutral.greyBlue }}>
            {address?.name}
          </Box>
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: "500" }}>
          address:{" "}
          <Box component="span" sx={{ color: theme.palette.neutral.greyBlue }}>
            {address?.addressLineOne} , {address?.city} , {address?.state} ,{" "}
            {address?.country}
          </Box>
        </Typography>

        <Typography variant="body2" sx={{ fontWeight: "500" }}>
          zip code:{" "}
          <Box component="span" sx={{ color: theme.palette.neutral.greyBlue }}>
            {address?.zipCode}
          </Box>
        </Typography>
        {address?.addressLineTwo && (
          <Typography variant="body2" sx={{ fontWeight: "500" }}>
            apt./suite :{" "}
            <Box
              component="span"
              sx={{ color: theme.palette.neutral.greyBlue }}
            >
              {address?.addressLineTwo}
            </Box>
          </Typography>
        )}
      </Stack>
      {/* delete address dialog */}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        PaperProps={{
          style: {
            borderRadius: "25px",
          },
        }}
      >
        <DeleteBox
          itemToDelete={`${address?.name} address`}
          onCancel={() => setOpenDialog(false)}
          onDelete={handleConfirmDelete}
        />
      </Dialog>
    </Stack>
  );
};

export default AddressCard;
