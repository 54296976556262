import { Box } from "@mui/material"

const BlackLayer = ({children})=> {
  return (
    <Box
    sx={{
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "#fff",
      borderRadius: "4px",
    }}
  >
    {/* <Typography variant="h6">+{number}</Typography> */}
    {children}
  </Box>
  )
}

export default BlackLayer
