import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { LuDot } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";

const InfoList = ({ currentProduct, pathName }) => {
  const theme = useTheme();
  const listItems = [
    {
      id: 1,
      title: "Condition",
      description: currentProduct?.condition ? currentProduct?.condition : "-",
    },
    {
      id: 2,
      title: "Dimentions",
      description: currentProduct?.dimentions
        ? currentProduct?.dimentions
        : "-",
    },
    {
      id: 3,
      title: "Weight",
      description: currentProduct?.weight ? currentProduct?.weight : "-",
    },
    {
      id: 4,
      title: "Warranty",
      description: currentProduct?.warranty ? currentProduct?.warranty : "-",
    },
  ];
  const companiesItems = [
    {
      id: 1,
      title: "Condition",
      description: currentProduct?.condition ? currentProduct?.condition : "-",
    },
    {
      id: 2,
      title: "Dimentions",
      description: currentProduct?.dimentions
        ? currentProduct?.dimentions
        : "-",
    },
    {
      id: 3,
      title: "Weight",
      description: currentProduct?.weight ? currentProduct?.weight : "-",
    },
    {
      id: 4,
      title: "Warranty",
      description: currentProduct?.warranty ? currentProduct?.warranty : "-",
    },
  ];
  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          ...(pathName !== "service-details" && {
            lg: "18rem",
            sm: "50%",
          }),
        },
        fontSize: "14px",
        fontWeight: "500",
      }}
    >
      {currentProduct?.companies && (
        <>
          <Typography
            variant="body1"
            style={{
              fontWeight: 700,
              color: theme.palette.primary.doveGrey,
            }}
          >
            Details
          </Typography>
          <List>
            {listItems?.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  height: "32px",
                   whiteSpace: "nowrap",
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: "500",

                  },
                }}
              >
                <Box
                  component="span"
                  display="flex"
                  alignItems="center"
                  sx={{
                    width: "12.125rem",
                    color: theme.palette.primary.darkMain,
                  }}
                >
                  <LuDot size="34px" />
                  <ListItemText>{item?.title}</ListItemText>
                </Box>
                <ListItemText
                  sx={{
                    color: theme.palette.primary.text,
                    textAlign: item?.description === "-" ? "center" : "start",
                  }}
                >
                  {item?.description}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {pathName !== "service-details" && (
        <>
          <Typography
            variant="body1"
            style={{
              fontWeight: 700,
              color: theme.palette.primary.doveGrey,
              marginTop: "15px",
            }}
          >
            Shipping
          </Typography>
          <List>
            {currentProduct?.companies &&
              currentProduct?.companies?.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    height: "32px",
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      fontWeight: "500",
                    },
                  }}
                >
                  <Box
                    component="span"
                    display="flex"
                    alignItems="center"
                    sx={{
                      width: "12.125rem",
                      color: theme.palette.primary.darkMain,
                    }}
                  >
                    <LuDot size="34px" />
                    <ListItemText>{item?.name}</ListItemText>
                  </Box>
                  <ListItemText
                    sx={{
                      color: theme.palette.primary.text,
                    }}
                  >
                     ${item?.price?.toFixed(2)}
                  </ListItemText>
                </ListItem>
              ))}
            {currentProduct?.isLocalPickup && (
              <ListItem
                sx={{
                  height: "32px",
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                }}
              >
                <Box
                  component="span"
                  display="flex"
                  alignItems="center"
                  sx={{
                    width: "12.125rem",
                    color: theme.palette.primary.darkMain,
                  }}
                >
                  <LuDot size="34px" />
                  <ListItemText>Local Pickup</ListItemText>
                </Box>
                <ListItemText
                  sx={{
                    color: theme.palette.primary.text,
                  }}
                >
                  Free
                </ListItemText>
              </ListItem>
            )}
          </List>
        </>
      )}
      {pathName === "service-details" && (
        <>
          <Typography
            variant="body1"
            style={{
              fontWeight: 700,
              color: theme.palette.primary.doveGrey,
              textTransform: "capitalize",
              marginBottom: "15px",
            }}
          >
            service location
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <FaLocationDot />
            <Typography variant="caption">
              {currentProduct?.businessAddress?.addressLineOne}
            </Typography>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default InfoList;
