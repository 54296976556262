import React from 'react';
import { Pagination, PaginationItem, Stack, useTheme } from '@mui/material';

const CustomPagination = ({ totalPages, currentPage, handlePageChange }) => {
  const theme = useTheme();

  return (
    <Stack
      spacing={2}
      sx={{
        mt: 4,
        paddingBottom: "35px",
      }}
    >
    {totalPages > 0 && (
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        sx={{
          "& ul": {
            justifyContent: "center",
            marginTop: "25px",
          },
        }}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              margin: "auto",
              justifyContent: "center",
              "&.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.light,
                fontWeight: "bold",
              },
              "&:hover": {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: theme.palette.primary.light,
              },
              borderRadius: "50%",
              marginRight: "5px",
              minWidth: "40px",
            }}
          />
        )}
      />
    )}
      
    </Stack>
  );
};

export default CustomPagination;
