import React, { useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
  MarkerClustererF, // Import MarkerClustererF
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../components/layout/constants.js";
import { mapStyles } from "../../../utils/methods.js";
import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import productIconImagePath from "../../../assets/Icons/weui_location-filled.png";
import serviceIconImagePath from "../../../assets/Icons/fa6-solid_screwdriver-wrench.png";
import { IoIosCloseCircleOutline } from "react-icons/io";
import theme from "../../../configs/theme.js";
// Default center and zoom settings
const defaultCenter = { lat: 37.0902, lng: -95.7129 }; // Center of USA
const defaultZoom = 4; // Zoom level to show the entire USA
const libraries = ["places"];

const mapOptions = {
  zoomControl: true,
  scaleControl: true,
  streetViewControl: true,
  rotateControl: true,
  fullscreenControl: true,
  styles: mapStyles,
};

const productIconImage = productIconImagePath;
const serviceIconImage = serviceIconImagePath;

// Custom cluster styles to change the font color
const clusterStyles = [
  {
    url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
    height: 53,
    width: 53,
    textColor: theme.palette.primary.light,
    textSize: 14,
  },
  {
    url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
    height: 56,
    width: 56,
    textColor: theme.palette.primary.light,
    textSize: 14,
  },
  {
    url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
    height: 66,
    width: 66,
    textColor: theme.palette.primary.light,
    textSize: 14,
  },
];

const MapView = ({ products, type, goToProductPage }) => {
  const theme = useTheme();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleMarkerClick = (product) => {
    setActiveMarker(product.id);
  };

  const handleCloseClick = (event) => {
    event.stopPropagation();
    setActiveMarker(null);
  };

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }
  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }
  return (
    <>
      {/* Apply custom CSS to hide the default close button */}
      <style>
        {`
          /* Target the default close button in the InfoWindow and hide it */
          .gm-style-iw button[aria-label="Close"] {
            display: none !important;
          }
        `}
      </style>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "500px" }}
        center={
          products?.length > 0
            ? { lat: products[0]._geoloc?.lat, lng: products[0]._geoloc?.lng }
            : defaultCenter
        }
        zoom={defaultZoom}
        options={mapOptions}
      >
        {/* MarkerClustererF with custom styles */}
        <MarkerClustererF
          options={{
            imagePath:
              "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            styles: clusterStyles, // Custom styles for the cluster text color
            gridSize: 50, // Cluster markers within this pixel range
            maxZoom: 15, // Don't cluster beyond this zoom level
          }}
        >
          {(clusterer) =>
            products?.map((product) => (
              <MarkerF
                key={product?.id}
                position={{
                  lat: product?._geoloc?.lat,
                  lng: product?._geoloc?.lng,
                }}
                title={product?.title}
                icon={type === "services" ? serviceIconImage : productIconImage}
                clusterer={clusterer}
                onClick={() => handleMarkerClick(product)}
              >
                {activeMarker === product?.id && (
                  <InfoWindowF
                    position={{
                      lat: product?._geoloc?.lat,
                      lng: product?._geoloc?.lng,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        padding: "8px",
                        overflowY: "auto",
                        maxHeight: "150px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        goToProductPage(product?.id, product, product?.title)
                      }
                    >
                      {/* Product Image */}
                      <img
                        src={product?.images[0]}
                        alt={product?.title}
                        loading="lazy"
                        style={{
                          width: "80px",
                          height: "78px",
                          borderRadius: "8px",
                          objectFit: "cover",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      />

                      {/* Content Section */}
                      <Box sx={{ flex: 1 }}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ mb: 1 }}
                        >
                          {/* Price Chip */}
                          <Chip
                            label={
                              product?.price
                                ? `$${product?.price}`
                                : "Contact for price"
                            }
                            sx={{
                              backgroundColor: theme.palette.primary.darkColor,
                              color: theme.palette.primary.light,
                              fontSize: "12px",
                              height: "17px",
                              marginBottom: "4px",
                            }}
                          />

                          {/* Custom Close Button */}
                          <Box
                            component="span"
                            onClick={handleCloseClick}
                            sx={{
                              cursor: "pointer",
                              fontWeight: "bold",
                              marginLeft: "auto",
                            }}
                          >
                            <IoIosCloseCircleOutline size="24px" />
                          </Box>
                        </Box>
                        {/* Title */}
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "180px",
                          }}
                        >
                          {product?.title?.length > 10
                            ? `${product?.title.substring(0, 10)}...`
                            : product?.title}
                        </Typography>

                        {/* Additional Info */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "8px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: theme.palette.primary.darkMain }}
                          >
                            {product?.description?.length > 25
                              ? `${product?.description?.substring(0, 25)}...`
                              : product?.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </InfoWindowF>
                )}
              </MarkerF>
            ))
          }
        </MarkerClustererF>
      </GoogleMap>
    </>
  );
};

export default MapView;
