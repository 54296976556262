import React from "react";
import { Typography, useTheme, Box, Grid, Container } from "@mui/material";

import about from "../../assets/Images/about.png";
import carAbout from "../../assets/Images/car-about.png";
import img1 from "../../assets/Icons/about/Background.png";
import img2 from "../../assets/Icons/about/Background1.png";
import img3 from "../../assets/Icons/about/Background2.png";
import img4 from "../../assets/Icons/about/Background3.png";
import img5 from "../../assets/Icons/about/Background4.png";
import img6 from "../../assets/Icons/about/Background5.png";
import WhyTruck from "./components/WhyTruck";

const AboutUs = () => {
  const theme = useTheme();

  const carouselData = [
    {
      image: img1,
      text: "Buy & Sell Parts with Ease",
      desc: "Quickly locate or list truck parts with just a few clicks. Reach a wider audience and find exactly what you need.",
    },
    {
      image: img2,
      text: "HOTLINE REQUEST Feature",
      desc: "Post your request on the HOTLINE and let multiple suppliers compete to offer you the best deals!",
    },
    {
      image: img3,
      text: "Connect with Service Providers",
      desc: "Find repair shops, towing services, and maintenance experts in your area.",
    },
    {
      image: img4,
      text: "Seamless Communication",
      desc: "Connect with buyers and sellers via in-app chat, email, text, or phone, making transactions simple and transparent.",
    },
    {
      image: img5,
      text: "Companion Website",
      desc: "Any listing made on the app automatically appears on the website, ensuring maximum visibility and ease of access.",
    },
    {
      image: img6,
      text: "Flexible Payment Options",
      desc: "Secure transactions through Stripe or negotiate direct payment methods that work best for you.",
    },
  ];

  return (
    <>
      <section style={{ margin: "0 30px" }}>
        <Box
          sx={{
            position: "relative",
            background: `url(${about}) center center / cover no-repeat`,
            minHeight: "300px",
            borderRadius: "25px",
            color: theme.palette.primary.light,
            display: "flex",
            alignItems: "center",
            p: 3,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: theme.typography.headerFont,
                fontSize: "2rem",
                fontWeight: 400,
                lineHeight: "61.6px",

                color: theme.palette.primary.light,
              }}
            >
              About Us
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 400,
                mb: 2,

                color: theme.palette.primary.light,
              }}
            >
              Welcome to TRUCKPARTSMART!
            </Typography>

            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 400,
                width: { sm: "90%", md: "55%" },
                lineHeight: "30px",
                color: theme.palette.primary.descHome,
              }}
            >
              At TRUCKPARTSMART, we are dedicated to revolutionizing the
              trucking industry by making the process of buying and selling
              truck parts easier, faster, and more efficient.
            </Typography>
          </Box>
        </Box>
        </section>
<section style={{background: theme.palette.primary.light}}>
        <Grid container justifyContent={"space-evenly"} sx={{ pt: 10, px: 3, pb: 3 }}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              sx={{
                fontSize: "32px",
                fontFamily: theme.typography.headerFont,
                color: theme.palette.primary.lightDoveGrey,
                mb: 3,
              }}
            >
              Our Story
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                lineHeight: "30px",
                color: theme.palette.primary.darkBlue,
                mb: 3,
              }}
            >
              I am Andy Young, one of the founders of TRUCKPARTSMART. With over
              30 years of experience in the trucking industry and the used truck
              parts business, I have firsthand knowledge of the challenges
              involved in sourcing quality truck parts. Before entering this
              industry, I spent over 15 years working in automotive repair, body
              shops, and welding businesses.
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                lineHeight: "30px",
                color: theme.palette.primary.darkBlue,
                mb: 3,
              }}
            >
              Finding the right truck parts has always been a struggle—long
              hours searching for parts, dealing with unreliable suppliers, and
              encountering defective parts with no return policies. To address
              these challenges, I built a business that prioritized excellent
              customer service, ensuring buyers could exchange incorrect or
              defective parts hassle-free. Now, I am taking this vision further
              with the TRUCKPARTSMART app and website
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={carAbout}
              alt="AboutUs"
              style={{
                width: "100%",
                borderRadius: "10px",
              }}
            />
          </Grid>
        </Grid>
</section>
     
      <Box sx={{ background: theme.palette.primary.gray }}>
        <Box sx={{ textAlign: "center", pt: 10, mb: 6 }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              color: theme.palette.primary.main,
            }}
          >
            Our Features
          </Typography>
          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: 400,
              color: theme.palette.primary.lightDoveGrey,
              fontFamily: theme.typography.headerFont,
            }}
          >
            Key Features of TRUCKPARTSMART
          </Typography>
        </Box>

        <Container sx={{ pb: 10 }}>
          <Grid container spacing={3} justifyContent={"center"}>
            {carouselData?.map((item, index) => (
              <Grid item xs={12} sm={5} md={4} display={"flex"}>
                <Box
                  sx={{
                    background: theme.palette.primary.light,
                    border: `1px solid ${theme.palette.primary.borderIcon} `,
                    textAlign: "center",
                    p: 3,
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={item?.image}
                    alt={`carousel-${index}`}
                    style={{
                      width: "64px",
                      height: "64px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontWeight: 800,
                      color: theme.palette.text.primary,
                      fontSize: "15px",
                      mt: 2,
                    }}
                  >
                    {item?.text}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: ".9rem",
                      fontWeight: 400,
                      lineHeight: "1.6rem",
                      color: theme.palette.primary.darkMain,
                      marginTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {item?.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box>
      <WhyTruck background={'about'}/>
      </Box>
    </>
  );
};

export default AboutUs;
