import { Stack, Typography, useTheme, Button, Box } from "@mui/material";
import AddressCard from "./AddressCard";
import { db } from "../../../../configs/firebase";
import { collection, getDocs } from "firebase/firestore";

import { useEffect, useState } from "react";
import { message } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { setUserAddressData } from "../../../../redux/slices/appStateSlice";
import box from "../../../../assets/Images/nothing_here.png";
import Loader from "../../../../components/common/Loader";

const AddressesCollection = ({ handleAddNewAddressBtn }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userAddressData = useSelector(
    (state) => state.appState.userAddressData
  );
  const userData = useSelector((state) => state.appState.userData);
  const [addresses, setAddresses] = useState(userAddressData || []);
  const [addressesLoading, setAddressesLoading] = useState(true);

  useEffect(() => {
    const fetchAddresses = async () => {
      if (userData?.uid) {
        try {
          const addressRef = collection(
            db,
            "users",
            userData?.uid,
            "addresses"
          );
          const querySnapshot = await getDocs(addressRef);
          const fetchedAddresses = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAddresses(fetchedAddresses);
          dispatch(setUserAddressData(fetchedAddresses));
        } catch (error) {
          console.error("Error fetching addresses: ", error);
          message.error("Failed to fetch addresses. Please try again.");
        } finally {
          setAddressesLoading(false);
        }
      }
    };

    fetchAddresses();
  }, [userData?.uid]);
  
  
  useEffect(() => {
    setAddresses(userAddressData);
  }, [userAddressData]);

  if (addressesLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Loader />
      </Stack>
    );
  }

  return (
    <>
      {addresses.length === 0 ? (
        <>
          <Stack alignItems="center" justifyContent="center" height="100%">
            <img src={box} alt="empty box" loading="lazy" />
            <Typography variant="body1">No addresses found.</Typography>
            <Button
              onClick={handleAddNewAddressBtn}
              type="button"
              variant="contained"
              color="primary"
              sx={{
                width: "15.625rem",
                fontWeight: "500",
                textTransform: "capitalize",
                paddingY: "16px",
                paddingX: "32px",
                borderRadius: "8px",
                marginTop: "25px",
              }}
            >
              add new address
            </Button>
          </Stack>
        </>
      ) : (
        <Stack gap={3}>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontWeight: "600",
              color: theme.palette.primary.lightDoveGrey,
            }}
            variant="h6"
          >
            addresses information
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "repeat(2, 1fr)",
              },
              gap: 3,
            }}
          >
            {addresses.length > 0 &&
              addresses.map((address) => (
                <AddressCard key={address.id} address={address} />
              ))}
          </Box>

          <Button
            onClick={handleAddNewAddressBtn}
            type="button"
            variant="contained"
            color="primary"
            sx={{
              width: "15.625rem",
              fontWeight: "500",
              textTransform: "capitalize",
              paddingY: "16px",
              paddingX: "32px",
              borderRadius: "8px",
              marginTop: "25px",
            }}
          >
            add new address
          </Button>
        </Stack>
      )}
    </>
  );
};

export default AddressesCollection;
