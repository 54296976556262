import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Grid,
  Button,
  Box,
  useTheme,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  Chip,
  Alert,
} from "@mui/material";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Formik, Form } from "formik";
import { IoCloseCircleOutline } from "react-icons/io5";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setFormTouched } from "../../../../../../redux/slices/appStateSlice";
import PhoneNumberInput from "../../../../../../components/common/PhoneNumberInput";
import parsePhoneNumberFromString from "libphonenumber-js";
import { collection, getDocs, Timestamp } from "firebase/firestore";
import { db, storageSdk } from "../../../../../../configs/firebase";
import { getDownloadURL, uploadBytes } from "firebase/storage";

import { useSelector } from "react-redux";
import ShippingAddressSelector from "../../../../../cart/components/ShippingAddressSelector";
import { MdDelete, MdOutlineAddAPhoto } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Loader from "../../../../../../components/common/Loader";
import ImageCropUploader from "../../../../../../components/common/ImageCropUploader";
import { message } from "antd";
import moment from "moment/moment";
import WorkingHoursForm from "../../../../../../components/common/Header/component/WokingHoursBussiness";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// Validation schema for the form
const validationSchema = Yup.object().shape({
  businessName: Yup.string()
    .trim()
    .required("Business name is required")
    .min(3, "Business name must be at least 3 characters"),
  email: Yup.string()
    .trim()
    .email("Invalid email")
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
      "Please enter a valid email address"
    ),
  // phone: Yup.string()
  //   .required("Phone number is required")
  //   .test("is-valid-phone", "Please enter a valid phone number", (value) => {
  //     if (!value) return false;
  //     const phoneNumber = parsePhoneNumberFromString(value);
  //     return phoneNumber?.isValid() || false;
  //   }),
  addressLineOne: Yup.string().trim().required("Address is required"),
  addressLineTwo: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9\s\-/#.,]*$/,
      "Invalid characters in apartment/suite field"
    ),
  city: Yup.string()
    .trim()
    .min(3, "City must be at least 3 characters long")
    .required("City is required"),
  state: Yup.string()
    .trim()
    .min(2, "State must be least 2 characters long")
    .max(20, "State must be at most 20 characters long")
    .required("State is required"),
  zipCode: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9\s\-]+$/, "Zip code must be valid")
    .required("Zip code is required"),
  description: Yup.string()
    .trim()
    .min(30, "Description must be at least 30 characters"),
    websiteLink: Yup.string()
    .trim()
    .matches(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/,
      "Please enter a valid website URL"
    )
    .optional(),
    businessContent: Yup.string()
        .trim()
        .min(15, "Business content must be at least 15 characters"),
});

const EditBusinessForm = ({
  initialValues,
  onSubmit,
  setCountry,
  setPlaceId,
  selectedAddresId,
  setLat,
  setLng,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const cropperRef = useRef(null);
  const [addresses, setAddresses] = useState([]);
  const userData = useSelector((state) => state.appState.userData);
  const [addressess, setAddressess] = useState([]);
  const [chooseAddressEnabled, setChooseAddressEnabled] = useState(false);
  // const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [addressSelectorMode, setAddressSelectorMode] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(
    initialValues?.businessImage || null
  );
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [daysEmpty, setDaysEmpty] = React.useState(false);
  const [isHoursModalOpen, setIsHoursModalOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(
    userData.businessInfo?.openDays ?? []
  );

  // States for handling address and selection
  const [address, setAddress] = useState(
    `${initialValues.addressLineOne ?? ""}, ${initialValues.city ?? ""}, ${
      initialValues.state ?? ""
    } , ${initialValues.country ?? ""}` || ""
  );

  const [selectedAddressId, setSelectedAddressId] = useState(
    userData.businessInfo?.address ? userData.businessInfo?.address?.id : null
  );

  useEffect(() => {
    if (selectedAddress) {
      setFormValues((prevValues) => ({
        ...prevValues,
        addressLineOne:
          selectedAddress.addressLineOne || prevValues.addressLineOne,
        addressLineTwo:
          selectedAddress.addressLineTwo || prevValues.addressLineTwo,
        city: selectedAddress.city || prevValues.city,
        state: selectedAddress.state || prevValues.state,
        zipCode: selectedAddress.zipCode || prevValues.zipCode,
      }));

      setAddress(
        `${selectedAddress.addressLineOne ?? ""}, ${
          selectedAddress.city ?? ""
        }, ${selectedAddress.state ?? ""}, ${selectedAddress.country ?? ""}`
      );
      setIsAddressSelected(true);
      setPlaceId(selectedAddress?.id);
      setSelectedAddressId(selectedAddress?.id);
    }
  }, [selectedAddress, setPlaceId]);

  useEffect(() => {
    if (initialValues) {
      setAddress(
        `${initialValues.addressLineOne ?? ""}, ${initialValues.city ?? ""}, ${
          initialValues.state ?? ""
        } `
      );
      setIsAddressSelected(true);
    }
  }, [initialValues]);

  // Handle the selection and setting of address fields
  const handleSelect = async (value, setFieldValue) => {
    try {
      const results = await geocodeByAddress(value);
      if (!results || results.length === 0) {
        throw new Error("No results found for the provided address.");
      }

      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;

      const addressDetails = {
        addressLineOne: "",
        city: "",
        state: "",
        zipCode: "",
      };

      // Extract city, state, and zip code from address components
      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number") || types.includes("route")) {
          addressDetails.addressLineOne += component.long_name + " ";
        } else if (types.includes("locality")) {
          addressDetails.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          addressDetails.state = component.short_name;
        } else if (types.includes("postal_code")) {
          addressDetails.zipCode = component.long_name;
        } else if (types.includes("country")) {
          setCountry(component.short_name);
        }
      });

      // Update the address value
      setAddress(value);

      setFieldValue("addressLineOne", addressDetails?.addressLineOne?.trim());

      // Set initial values for city, state, and zip if not manually overridden
      setFieldValue("city", addressDetails.city);
      setFieldValue("state", addressDetails.state);
      setFieldValue("zipCode", addressDetails.zipCode);
      setFieldValue("isAddressSelected", true);
      setIsAddressSelected(true);
      setPlaceId(results[0].place_id);
      setLat(latLng.lat);
      setLng(latLng.lng);
    } catch (error) {
      console.error("Error selecting address:", error.message);
      if (
        error.message === "ZERO_RESULTS" ||
        error.message.includes("No results found")
      ) {
        alert(
          "No results found for the provided address. Please try again with a more specific address."
        );
      }
    }
  };

  // Clear the address
  const handleClearAddress = (setFieldValue) => {
    setAddress("");
    setIsAddressSelected(false);
    setFieldValue("city", "");
    setFieldValue("state", "");
    setFieldValue("zipCode", "");
    setFieldValue("isAddressSelected", false);
    setFieldValue("addressLineOne", "");
  };

  const handleInputChange = (event) => {
    dispatch(setFormTouched());
  };

  const fetchAddresses = async () => {
    try {
      const addressesRef = collection(db, "users", userData?.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);
      const fetchedAddresses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedAddresses = fetchedAddresses.sort(
        (a, b) => b.isDefault - a.isDefault
      );

      setAddresses(sortedAddresses);

      if (sortedAddresses.length === 1 && !userData?.businessInfo?.address) {
        setSelectedAddressId(sortedAddresses[0].id); // Automatically select first address
      }

     
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    if (userData?.uid) {
      fetchAddresses();
    }
  }, [userData]);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCurrentFile(file);
      setIsCropDialogOpen(true);
    }
  };

  const handleCropSave = async (setFieldValue) => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      if (!croppedCanvas) {
        message.error("Failed to crop the image. Please try again.");
        return;
      }

      croppedCanvas.toBlob(async (blob) => {
        if (!blob) {
          message.error("Failed to generate cropped image. Please try again.");
          return;
        }

        setIsCropDialogOpen(false);
        const currentDateTime = new Date().toISOString();
        const imageName = `image-${currentDateTime}.png`;
        const imageRef = storageSdk.ref(
          `businessImage/${userData.uid}/${imageName}`
        );

        try {
          setIsLoadingImage(true);
          await uploadBytes(imageRef, blob);
          const downloadURL = await getDownloadURL(imageRef);

          // Update single selected image
          setSelectedImage(downloadURL);
          setFieldValue("businessImage", downloadURL); // Update Formik value

          message.success("Image uploaded successfully.");
        } catch (error) {
          console.error("Error uploading image:", error);
          message.error("Failed to upload image. Please try again.");
        } finally {
          setIsLoadingImage(false);
        }
      });
    }
  };

  const handleRemoveImage = (setFieldValue) => {
    setSelectedImage(null);
    setFieldValue("businessImage", null);

    // Add a slight delay before allowing another selection
    setTimeout(() => {
      document.getElementById("photo-upload").value = "";
    }, 100);
  };

  const handleCropCancel = () => {
    setIsCropDialogOpen(false);
    setCurrentFile(null);
  };

  // Convert Firebase timestamps to Date objects and format them using moment
  const formatBusinessHours = (selectedDays) => {
    return selectedDays.map((d) => ({
      ...d,
      from: d?.from ? new Date(d.from.seconds * 1000) : null,
      to: d?.to ? new Date(d.to.seconds * 1000) : null,
    }));
  };

  const updateDays = (days) => {
    setSelectedDays(days);
  };

  useEffect(() => {
    if (userData.businessInfo?.openDays) {
      setSelectedDays(userData?.businessInfo?.openDays);
    }
  }, [userData.businessInfo?.openDays]);

  // Function to format business hours with Firebase Timestamps
  const formatBusinessHoursToFierbase = (selectedDays) => {
    return selectedDays.map((d) => ({
      ...d,
      from: d?.from
        ? Timestamp.fromDate(new Date(d.from.seconds * 1000))
        : null,
      to: d?.to ? Timestamp.fromDate(new Date(d.to.seconds * 1000)) : null,
    }));
  };

  const formattedDays = formatBusinessHours(selectedDays);
  const formattedDaysToFierbase = formatBusinessHoursToFierbase(selectedDays);

  return (
    <Stack
      sx={{
        background: theme.palette.primary.light,
        borderRadius: "10px",
      }}
      gap={4}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: "600",
          fontSize: "24px",
          color: theme.palette.primary.lightDoveGrey,
          textTransform: "capitalize",
        }}
      >
        business info
      </Typography>

      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        // onSubmit={onSubmit}
        onSubmit={(values) => {
          onSubmit(values, formattedDaysToFierbase);
        }}
        enableReinitialize // Reinitialize the form if initialValues change
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isValid,
          isSubmitting,
        }) => (
          <Form>
            <Grid container spacing={2}>
              {/* Business Name */}
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Business Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="businessName"
                  variant="standard"
                  value={values.businessName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.businessName && Boolean(errors?.businessName)}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                  InputProps={{ disableUnderline: true }}
                />
                {touched.businessName && errors.businessName && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "15px" }}
                  >
                    {errors?.businessName}
                  </Typography>
                )}
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Email
                </Typography>
                <TextField
                  fullWidth
                  name="email"
                  variant="standard"
                  placeholder="Enter Email Address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                  InputProps={{ disableUnderline: true }}
                />
                {touched.email && errors?.email && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "15px" }}
                  >
                    {errors?.email}
                  </Typography>
                )}
              </Grid>

              {/* Phone */}
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Phone Number
                </Typography>
                <PhoneNumberInput
                  fullWidth
                  name="phone"
                  placeholder="Enter your Phone Number"
                  value={values.phone}
                  onBlur={handleBlur("phone")}
                  onChange={(phone) => {
                    setFieldValue("phone", phone);
                    handleInputChange();
                  }}
                  error={touched.phone && Boolean(errors.phone)}
                />
                {/* {touched.phone && errors?.phone && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.phone}
                  </Typography>
                )} */}
              </Grid>

              {/* Address */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: chooseAddressEnabled ? "block" : "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "600", marginBottom: "10px" }}
                  >
                    Address <span style={{ color: "red" }}>*</span>
                  </Typography>

                  {addresses?.length > 0 && (
                    <>
                      <Button
                        onClick={() =>
                          setChooseAddressEnabled(!chooseAddressEnabled)
                        }
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {chooseAddressEnabled ? "Cancel" : "Choose Address"}
                      </Button>

                      {chooseAddressEnabled && (
                        <ShippingAddressSelector
                          currentUser={userData}
                          onSelectAddress={(address) =>
                            setSelectedAddressId(address?.id)
                          }
                          isModalOpen={isNewAddressModalOpen}
                          setIsModalOpen={setIsNewAddressModalOpen}
                          selectValue={addressSelectorMode}
                          selectedAddress={selectedAddress}
                          setSelectedAddress={setSelectedAddress}
                          selectedAddressId={selectedAddressId}
                        />
                      )}
                    </>
                  )}
                </Box>
                <PlacesAutocomplete
                  value={address}
                  disabled={isAddressSelected} // Keep address disabled when selected
                  onChange={(value) => {
                    setAddress(value);
                    handleInputChange(); // Mark form as touched on input change
                    setIsAddressSelected(false);
                    // setFieldValue("addressLineOne", value);
                    setFieldValue("isAddressSelected", false);
                  }}
                  onSelect={(value) => handleSelect(value, setFieldValue)}
                  searchOptions={{
                    types: ["address"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div style={{ position: "relative", width: "100%" }}>
                      <TextField
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                        })}
                        id="addressLineOne"
                        name="addressLineOne"
                        fullWidth
                        disabled={isAddressSelected}
                        variant="outlined"
                        sx={{ ...theme.sharedStyles }}
                        error={
                          touched.addressLineOne &&
                          Boolean(errors.addressLineOne)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {address && (
                                <IconButton
                                  onClick={() =>
                                    handleClearAddress(setFieldValue)
                                  }
                                  aria-label="clear address"
                                >
                                  <IoCloseCircleOutline />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Paper
                        sx={{
                          marginTop: "-15px !important",
                          position: "absolute",
                          zIndex: 2100,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "4px",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const { key, ...suggestionProps } =
                            getSuggestionItemProps(suggestion);
                          const isActive = suggestion.active;

                          return (
                            <Box
                              key={suggestion.description}
                              {...suggestionProps}
                              sx={{
                                padding: "10px 16px",
                                backgroundColor: isActive
                                  ? "rgba(0, 0, 0, 0.1)"
                                  : "white",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                                },
                                borderBottom: "1px solid #eee",
                              }}
                            >
                              <Typography variant="body2">
                                {suggestion.description}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Paper>
                    </div>
                  )}
                </PlacesAutocomplete>
                {touched.addressLineOne && errors.addressLineOne && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.addressLineOne}
                  </Typography>
                )}
              </Grid>

              {/* addressLineTwo */}
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Apt./suite (optional)
                </Typography>
                <TextField
                  id="addressLineTwo"
                  name="addressLineTwo"
                  fullWidth
                  variant="outlined"
                  value={values.addressLineTwo}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e, "addressLineTwo", setFieldValue);
                  }}
                  onBlur={handleBlur("addressLineTwo")}
                  placeholder="Enter Apt./Suite"
                />
              </Grid>
              {/* City */}
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  City <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="city"
                  variant="standard"
                  value={values.city}
                  placeholder="Enter your city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                />
                {touched.city && errors.city && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.city}
                  </Typography>
                )}
              </Grid>

              {/* State */}
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="state"
                  variant="standard"
                  value={values.state}
                  placeholder="Enter your state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                />
                {touched.state && errors.state && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.state}
                  </Typography>
                )}
              </Grid>

              {/* Zip Code */}
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Zip code <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="zipCode"
                  variant="standard"
                  value={values.zipCode}
                  placeholder="Enter your zip code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.zipCode && Boolean(errors.zipCode)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                />
                {touched.zipCode && errors.zipCode && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.zipCode}
                  </Typography>
                )}
              </Grid>

              {/* Website */}
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Website link (optional)
                </Typography>
                <TextField
                  fullWidth
                  name="websiteLink"
                  variant="standard"
                  value={values.websiteLink}
                  placeholder="Enter your website"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.websiteLink && Boolean(errors.websiteLink)}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{
                    pattern: "https?://.*", // Restrict input to URLs starting with http:// or https://
                    title:
                      "Please enter a valid website URL (e.g., https://www.example.com)", // Tooltip for invalid input
                  }}
                />
                {touched.websiteLink && errors.websiteLink && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "15px" }}
                  >
                    {errors.websiteLink}
                  </Typography>
                )}
              </Grid>

             

              {/* Description */}
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Description (optional)
                </Typography>
                <TextField
                  fullWidth
                  name="description"
                  variant="standard"
                  placeholder="Enter a brief description of your business"
                  multiline
                  rows={4}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.description) || touched.description}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                  InputProps={{ disableUnderline: true }}
                />
                {(errors.description || touched.description) && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.description}
                  </Typography>
                )}
              </Grid>
              
               {/* Business Content */}
               <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Business content (optional)
                </Typography>
                <TextField
                  fullWidth
                  name="businessContent"
                  variant="standard"
                  value={values.businessContent}
                  placeholder="Enter your business Content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.businessContent) || touched.businessContent}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                  InputProps={{ disableUnderline: true }}
                />
                 {(errors.businessContent || touched.businessContent) && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.businessContent}
                  </Typography>
                )}
              </Grid>

              {/** Business-Hours */}

              <Grid
                item
                xs={12}     
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Business hours (optional)
                </Typography>
                <TextField
                  fullWidth
                  name="openDays"
                  variant="standard"
                  placeholder="Enter your business hours "
                  onClick={() => setIsHoursModalOpen(true)}
                  // value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                    "& .MuiInputBase-input": {
                      cursor: "pointer", // Apply cursor: pointer to the input element
                    },
                  }}
                  InputProps={{ disableUnderline: true, readOnly: true }}
                />

                <Grid item xs={6} container justify={"space-evenly"}>
                  {formattedDays?.length > 0
                    ? formattedDays?.map((d) => (
                        <Grid item key={d?.days}>
                          <Chip
                            variant="outlined"
                            size="medium"
                            label={`${d?.days} ${moment(d?.from).format(
                              "hh:mma"
                            )}-${moment(d?.to).format("hh:mma")}`}
                          />
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              <Alert
                severity="error"
                style={{ display: `${daysEmpty === false ? "none" : ""}` }}
              >
                Please add business hours or select appointment only!
              </Alert>
              {/** Photo */}
              <Grid item xs={12} md={4}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Photo
                </Typography>

                <Box
                  sx={{
                    border: `1px dashed ${theme.palette.primary.main}`,
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                    padding: "20px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "245px",
                    cursor: selectedImage ? "not-allowed" : "pointer",
                  }}
                  onClick={() => {
                    if (!selectedImage && !isCropDialogOpen) {
                      document.getElementById("photo-upload").click();
                    }
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple={false} // Prevent multiple selection
                    onChange={handleImageSelect}
                    style={{ display: "none" }}
                    id="photo-upload"
                  />

                  <ImageCropUploader
                    isCropDialogOpen={isCropDialogOpen}
                    handleCropCancel={handleCropCancel}
                    currentFile={currentFile}
                    cropperRef={cropperRef}
                    handleCropSave={handleCropSave}
                    setFieldValue={setFieldValue}
                  />

                  {isLoadingImage ? (
                    <Loader color={theme.palette.primary.main} />
                  ) : selectedImage ? (
                    <Box sx={{ position: "relative", width: "100%" }}>
                      <img
                        src={
                          selectedImage ? selectedImage : values?.businessImage
                        }
                        alt="Selected Business"
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents opening file input when deleting
                          handleRemoveImage(setFieldValue);
                        }}
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          background: "white",
                          borderRadius: "50%",
                        }}
                      >
                        <MdDelete color="red" />
                      </IconButton>
                    </Box>
                  ) : (
                    <MdOutlineAddAPhoto
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "35px",
                      }}
                    />
                  )}
                </Box>

                {errors.image && touched.image && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "10px" }}
                  >
                    {errors.image}
                  </Typography>
                )}
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    sx={{
                      textTransform: "capitalize",
                      color: theme.palette.primary.light,
                      backgroundColor: theme.palette.primary.main,
                      fontSize: "14px",
                      fontWeight: "700",
                      padding: "0 32px",
                      borderRadius: "3px",
                      height: "56px",
                      width: "11.25rem",
                      marginY: "20px",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                      },
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <WorkingHoursForm
          open={isHoursModalOpen}
          onClose={() => setIsHoursModalOpen(false)}
          updateDays={updateDays}
          selectedDays={selectedDays}
          setDaysEmpty={setDaysEmpty}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default EditBusinessForm;
