import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";

// images
import loginPic from "../../../assets/Images/login_side_image.jpeg";
import logo from "../../../assets/Logo/logoBlack.png";

import BackButton from "../../../components/common/BackButton";
import { API_URL } from "../../../components/layout/constants";
import { message } from "antd";
const ForgetPasswordForm = ({ onLoginClick }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  function handleLoginClick() {
    onLoginClick(true);
  }

  const forgetPasswordValidation = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/forgotPasswordEmail`,
        {
          email: values.email.trim(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.isSuccess) {
    
        message.success("Please check your email to reset your password");
        handleLoginClick();
        setIsLoading(false);
      } else {
        message.error("you are not authorized");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error sending password reset email:", error);
      message.error("Re-enter the correct email or register a new account");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        gap: "40px",
        padding: "30px",
        borderRadius: "2.5rem",
        height: "100vh",
        maxWidth: "100%",
      }}
    >
      <Grid
        item
        md={6}
        sx={{
          height: "100%",
          display: { xs: "none", md: "flex" },
          width: { md: "58.33%", lg: "50%" },
        }}
      >
        <img
          src={loginPic}
          alt="truck on road"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "30px",
          }}
          loading="lazy"
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "30px",
            flexGrow: 1,
            width: "27.813rem",
          }}
        >
          {/* logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "280px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/home")}
          >
            <img src={logo} alt="logo" width="100%" />
          </Box>

          <BackButton handleLoginClick={handleLoginClick} />

          <Box>
            <Typography
              variant="h4"
              style={{
                fontFamily: theme.palette.text.authFont,
                textAlign: "left",
                fontSize: "1.875rem",
                fontWeight: "600",
                lineHeight: "2.5rem",
                marginBottom: "5px",
                textTransform: "capitalize",
              }}
            >
              forgot password
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: theme.palette.text.authFont,
                fontWeight: "300",
                color: theme.palette.primary.grey,
                marginBottom: "30px",
                lineHeight: "24px",
              }}
            >
              Enter your registered email address. we’ll send you an email to
              reset your password.
            </Typography>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={forgetPasswordValidation}
              onSubmit={handleSubmit}
            >
              {({ handleBlur, handleChange, values, errors, touched }) => (
                <Form>
                  <Box style={{ marginBottom: "20px" }}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email Address"
                      value={values.email}
                      onBlur={handleBlur("email")}
                      onChange={handleChange("email")}
                      placeholder="Enter Your Email"
                      required
                      error={touched.email && Boolean(errors.email)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                    {touched.email && errors?.email && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {errors?.email}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={isLoading}
                    sx={{
                      fontFamily: theme.palette.text.authFont,
                      textTransform: "capitalize",
                      marginBottom: "30px",
                      borderRadius: "10px",
                      padding: "10px 16px",
                      fontSize: "1rem",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                    }}
                  >
                    {isLoading ? "Sending..." : "Reset Password"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgetPasswordForm;
