import { Button, Stack, TableCell, TableRow } from "@mui/material";
import StatusDisplay from "../../../../components/common/StatusDisplay";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ReviewModal from "../../../../components/common/ReviewModal";
import { useSelector } from "react-redux";

const MyOrderTableRow = ({ order, theme, rowStyle, tabIndex }) => {
  const navigate = useNavigate();
  const ButtonStyle = {
    paddingX: "18px",
    paddingY: "5px",
    fontSize: "14px",
    borderRadius: "8px",
    whiteSpace: "nowrap",
    fontWeight: "400",
    letterSpacing: "0.3px",
    boxShadow: "none",
    "&.Mui-disabled": {
      backgroundColor: theme.palette.primary.whiteSmoke,
      color: theme.palette.primary.lightWhiteGrey,
    },
  };
  const cellStyle = {
    color: theme.palette.neutral.MoustacheGrey,
    fontSize: "14px",
  };

  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [localOrder, setLocalOrder] = useState(order);
  const currentUser = useSelector((state) => state.appState.userData);

  const handleReviewModalCancel = () => setIsReviewModalVisible(false);

  // Update the local order state after the review is submitted
  const onReviewSubmit = () => {
    setLocalOrder((prevOrder) => ({
      ...prevOrder,
      isReviewed: true,
    }));
  };

  return (
    <TableRow sx={rowStyle} key={localOrder?.id}>
      <TableCell sx={{ width: "130px", ...cellStyle }}>
        #{localOrder?.order_no}
      </TableCell>
      <TableCell sx={{ width: "84px", ...cellStyle }}>
        {format(localOrder?.createdAt?.toDate(), "MM/dd/yyyy")}
      </TableCell>
      <TableCell sx={{ width: "130px", ...cellStyle }}>
        <StatusDisplay status={localOrder?.status} />
      </TableCell>
      <TableCell sx={{ width: "32px", ...cellStyle }}>
        {/* {localOrder?.order_items?.length} */}
        {localOrder?.totalQuantity
          ? localOrder?.totalQuantity
          : localOrder?.order_items?.length}
      </TableCell>
      <TableCell sx={{ width: "60px", ...cellStyle }}>
        {`$${localOrder?.total?.toFixed(2)}`}
      </TableCell>
      <TableCell sx={{ width: "200px", ...cellStyle }}>
        <Stack
          direction={{
            xs: "column",
            lg: "row",
          }}
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={() =>
              navigate(`/orderDetails/${localOrder?.id}`, {
                state: { scrollToRequests: true },
              })
            }
            sx={{
              ...ButtonStyle,
              backgroundColor: theme.palette.primary.smoke,
              textTransform: "capitalize",
              fontSize: {
                xs: "12px",
                lg: "14px",
              },
              marginRight: {
                xs: "0px",
                lg: "20px",
              },
              marginBottom: {
                xs: "10px",
                lg: "0",
              },
             

              color: theme.palette.primary.main,
              width: {
                xs: "117px",
                xl: "86px",
              },
              "&:hover": {
                backgroundColor: theme.palette.primary.smoke,
                color: theme.palette.primary.main,
                boxShadow: "none",
              },
            }}
          >
            details
          </Button>

          {localOrder?.status !== "cancelled" && (
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/trackOrder/${localOrder?.id}`, {
                  state: { scrollToRequests: true },
                })
              }
              sx={{
                ...ButtonStyle,
                fontSize: {
                  xs: "12px",

                  xl: "14px",
                },
                backgroundColor: theme.palette.primary.main,
                textTransform: "capitalize",
                color: theme.palette.primary.light,
                width: "117px",

                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                  boxShadow: "none",
                },
              }}
            >
              {tabIndex === 1 ? " order status" : "track Order"}
            </Button>
          )}

          {/* Display "Review seller" button only if the order is completed and not reviewed */}
          {localOrder?.status === "completed" &&
            !localOrder?.isReviewed &&
            tabIndex === 0 && (
              <>
                <Button
                  variant="contained"
                  sx={{
                    ...ButtonStyle,
                    fontSize: {
                      xs: "12px",

                      xl: "14px",
                    },
                    marginLeft: {
                      xs: "0px",
                      lg: "20px",
                    },
                    marginTop: {
                      xs: "10px",
                      lg: "0",
                    },
                    backgroundColor: theme.palette.primary.light,

                    textTransform: "capitalize",
                    color: theme.palette.primary.main,
                    border: "1px solid",
                    borderColor: theme.palette.neutral.blueCyan,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.main,
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => setIsReviewModalVisible(true)}
                >
                  review seller
                </Button>

                <ReviewModal
                  isVisible={isReviewModalVisible}
                  onClose={handleReviewModalCancel}
                  order={localOrder}
                  currentUser={currentUser}
                  onReviewSubmit={onReviewSubmit}
                />
              </>
            )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default MyOrderTableRow;
