import { useMemo } from 'react';

const useFormattedDate = (timestamp, format = 'full') => {
  const formattedDate = useMemo(() => {
    let date;

    // Check if timestamp is in Firestore format or JS timestamp
    if (typeof timestamp === 'object' && 'seconds' in timestamp && 'nanoseconds' in timestamp) {
      // Firestore timestamp format: {seconds, nanoseconds}
      date = new Date(timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000));
    } else if (typeof timestamp === 'number') {
      // JavaScript timestamp format (milliseconds since epoch)
      date = new Date(timestamp);
    } else {
      return 'Invalid timestamp';
    }

    // If the format is 'year', return just the year
    if (format === 'year') {
      return date.getFullYear();
    }

    // Default to returning the full formatted date
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(date);
  }, [timestamp, format]);

  return formattedDate;
};

export default useFormattedDate;
