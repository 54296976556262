import { useParams, useLocation } from "react-router-dom";
import AddItem from "../AddItem";
import AdditionalDetails from "../AdditionalDetails";
import Billing from "../Billing";
import ViewProducts from "../../UploadCsv/ViewProducts";
import ConfirmCSV from "../../UploadCsv/ConfirmCSV";
import UploadCSVPage from "../../UploadCsv/UploadCSVPage";

const DynamicPage = () => {
  const { pageName, productId } = useParams();
  const location = useLocation();
  const isEditMode =
    new URLSearchParams(location.search).get("mode") === "edit";

  switch (pageName) {
    case "add-item":
      return <AddItem isEditMode={isEditMode} productId={productId} />;
    case "additional-details":
      return (
        <AdditionalDetails isEditMode={isEditMode} productId={productId} />
      );
    case "billing-details":
      return <Billing isEditMode={isEditMode} productId={productId} />;
    case "view-products":
      return <ViewProducts />;
    case "confirmation":
      return <ConfirmCSV />;
    case "upload-csv":
      return <UploadCSVPage />;
    default:
      return <div>Page Not Found</div>;
  }
};

export default DynamicPage;
