import React from "react";
import { privacyPolicyData } from "./data";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import Section from "./component/Section";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const infoCollection =
    privacyPolicyData?.informationCollection?.personalInformation;

  const renderCaliforniaTable = () => (
    <TableContainer component={Paper} sx={{ mt: 4 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Examples</TableCell>
            <TableCell>Collected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {privacyPolicyData.californiaPrivacyRights.categoriesCollected.table.map(
            (row, index) => (
              <TableRow key={`category-${index}-${row.category}`}>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.examples}</TableCell>
                <TableCell>{row.collected}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderRetentionPolicy = () => (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" sx={{ fontWeight: "600", mt: 2 }}>
        {privacyPolicyData.californiaPrivacyRights.retentionPolicy.title}
      </Typography>
      {privacyPolicyData.californiaPrivacyRights.retentionPolicy.details.map(
        (detail, index) => (
          <Typography
            key={`retention-detail-${index}`}
            variant="body1"
            sx={{ mt: 2 }}
          >
            {detail}
          </Typography>
        )
      )}
      <Typography variant="h6" sx={{ fontWeight: "600", mt: 3 }}>
        {privacyPolicyData.californiaPrivacyRights.retentionPolicy.subaTitle}
      </Typography>
      {privacyPolicyData.californiaPrivacyRights.retentionPolicy.subdetails.map(
        (subDetail, index) => (
          <Typography
            key={`retention-subdetail-${index}`}
            variant="body1"
            sx={{ mt: 2 }}
          >
            {subDetail}
          </Typography>
        )
      )}
    </Box>
  );

  return (
    <section
      style={{ width: "70%", margin: "0 auto 4rem auto ", height: "90vh" }}
    >
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          padding: 2,
          borderRadius: 1,
          color: theme.palette.primary.text,
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.secondary.background,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        <Box>
          <Typography
            variant="h1"
            sx={{ mb: 2, fontWeight: "600", fontSize: "2rem" }}
          >
            PRIVACY POLICY
          </Typography>
          <Typography sx={{ fontWeight: "600" }}>
            Last updated: {privacyPolicyData.last_updated}
          </Typography>
          <Typography variant="body1" sx={{ margin: "20px 0" }}>
            {privacyPolicyData.description}
          </Typography>
          <Box>
            <ul>
              {privacyPolicyData.services.map((service, index) => (
                <li key={`service-${index}`} style={{ marginBottom: "15px" }}>
                  {service.includes("https://")
                    ? service.split("https://").map((part, idx) => {
                        if (idx > 0) {
                          return (
                            <React.Fragment
                              key={`service-part-${index}-${idx}`}
                            >
                              <Link
                                to={`https://${part}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {`https://${part.split(",")[0]}`}
                              </Link>
                              {part.split(",")[1] && (
                                <span>{part.split(",")[1]}</span>
                              )}
                            </React.Fragment>
                          );
                        }
                        return part;
                      })
                    : service}
                </li>
              ))}
            </ul>
          </Box>
          <Typography>{privacyPolicyData.questions}</Typography>
        </Box>
        {/* Summary Section */}
        <Box>
          <Typography variant="h6" sx={{ mb: 2, mt: 2, fontWeight: "600" }}>
            {privacyPolicyData.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "20px", fontWeight: "400" }}
          >
            {privacyPolicyData.subaTitle}
          </Typography>
          <Box>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {privacyPolicyData.summary.map((item, index) => (
                <li
                  key={`summary-${index}-${item.title || "no-title"}`}
                  style={{ marginBottom: "15px" }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "600" }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body1">
                    {item.content.split("Learn more")[0]}{" "}
                    <span style={{ color: theme.palette.primary.main }}>
                      {item.content.split("about")[1]}
                    </span>
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
        {/* TABLE OF CONTENTS Section */}
        <Box>
          <Typography variant="h6" sx={{ mb: 2, mt: 3, fontWeight: "600" }}>
            TABLE OF CONTENTS
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {privacyPolicyData.tableOfContents.map((item, index) => (
              <li
                key={`toc-${index}-${item.title}`}
                style={{
                  marginBottom: "10px",
                  color: theme.palette.primary.main,
                }}
              >
                {`${index + 1}. ${item.title}`}
              </li>
            ))}
          </ul>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, mt: 3, fontWeight: "600" }}>
              {privacyPolicyData?.informationCollection?.title}
            </Typography>

            {/* Personal Information */}
            <Section
              title={infoCollection?.title}
              summary={infoCollection?.summary}
              details={infoCollection?.details}
            />
            <Section
              title="Sensitive Information"
              summary="We do not process sensitive information."
            />
            {/* Payment Data */}
            <Section
              title={infoCollection?.paymentData?.title}
              summary={infoCollection?.paymentData?.details}
            />

            {/* Social Media Login Data */}
            <Section
              title={infoCollection?.socialMediaLoginData?.title}
              summary={infoCollection?.socialMediaLoginData?.details}
            />
            {/* Personal Information Provided by You */}
            <Section
              title={infoCollection?.personalInfoProvidedByYou?.title}
              summary={infoCollection?.personalInfoProvidedByYou?.summary}
              details={infoCollection?.personalInfoProvidedByYou?.details}
            />

            {/* Application Data */}
            <Section
              title={infoCollection?.applicationData?.title}
              summary={infoCollection?.applicationData?.content}
              details={infoCollection?.applicationData?.details}
            />

            {/* Automatic Information */}
            <Section
              title={infoCollection?.automaticInformation?.title}
              summary={infoCollection?.automaticInformation?.summary}
              details={[
                infoCollection?.automaticInformation?.desc,
                ...(infoCollection?.automaticInformation?.details || []),
              ]}
            />
          </Box>
        </Box>
        {/* Processing Information Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, mt: 3, fontWeight: "600" }}>
            {privacyPolicyData?.processingInformation?.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {privacyPolicyData?.processingInformation?.summary}
          </Typography>

          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
            {privacyPolicyData.processingInformation.reasons.map(
              (reason, index) => (
                <li key={`reason-${index}`} style={{ marginBottom: "15px" }}>
                  <Section title={reason.title} summary={reason.description} />
                </li>
              )
            )}
          </ul>
        </Box>
        {/* Legal Bases Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" sx={{ mb: 2, mt: 3, fontWeight: "600" }}>
            {privacyPolicyData.legalBases.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {privacyPolicyData.legalBases.summary}
          </Typography>
          {privacyPolicyData.legalBases.applicableRegions.map(
            (region, index) => (
              <Box
                key={`region-${index}-${region.title || "no-title"}`}
                sx={{ mb: 4 }}
              >
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  {region.title}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {region.summary}
                </Typography>
                {region.bases &&
                  region.bases.map((base, idx) => (
                    <Section
                      key={`base-${idx}`}
                      title={base.title}
                      summary={base.description}
                      details={base.examples}
                    />
                  ))}
                {region.exceptionalCases && (
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      Exceptional Cases
                    </Typography>
                    <ul>
                      {region.exceptionalCases.map((exception, index) => (
                        <li
                          key={`exception-${index}`}
                          style={{ marginBottom: "15px" }}
                        >
                          {exception}
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.sharingInformation.title}
            summary={privacyPolicyData.sharingInformation.summary}
            details={privacyPolicyData.sharingInformation.details.map(
              (item) => `${item.title}: ${item.description}`
            )}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.socialLogins.title}
            summary={privacyPolicyData.socialLogins.summary}
            details={privacyPolicyData.socialLogins.details}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.dataRetention.title}
            summary={privacyPolicyData.dataRetention.summary}
            details={privacyPolicyData.dataRetention.details}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.safetyInfo.title}
            summary={privacyPolicyData.safetyInfo.summary}
            details={privacyPolicyData.safetyInfo.details}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.minorsInfo.title}
            summary={privacyPolicyData.minorsInfo.summary}
            details={privacyPolicyData.minorsInfo.details}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.privacyRights.title}
            summary={privacyPolicyData.privacyRights.summary}
            details={privacyPolicyData.privacyRights.details}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {privacyPolicyData.privacyRights.accountInformation.title}
          </Typography>
          {privacyPolicyData.privacyRights.accountInformation.content.map(
            (text, index) => (
              <Typography
                key={` privacy-rights-${index} `}
                variant="body1"
                sx={{ mb: 2 }}
              >
                {text}
              </Typography>
            )
          )}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.doNotTrack.title}
            summary={privacyPolicyData.doNotTrack.summary}
            details={privacyPolicyData.doNotTrack.details}
          />
        </Box>
        {/* California Privacy Rights Main Title */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: "600", mt: 4 }}>
            {privacyPolicyData.californiaPrivacyRights.title}
          </Typography>

          {/* Intro */}
          {privacyPolicyData.californiaPrivacyRights.intro.map(
            (paragraph, index) => (
              <Typography key={`intro-${index}`} variant="body1" sx={{ mt: 2 }}>
                {paragraph}
              </Typography>
            )
          )}

          {/* Shine The Light Section */}
          <Section
            title="Shine The Light"
            summary={privacyPolicyData.californiaPrivacyRights.shineTheLight[0]}
            details={privacyPolicyData.californiaPrivacyRights.shineTheLight.slice(
              1
            )}
          />

          {/* CCPA Notice Section */}
          <Section
            title={privacyPolicyData.californiaPrivacyRights.ccpaNotice.title}
            summary={
              privacyPolicyData.californiaPrivacyRights.ccpaNotice.content[0]
            }
          />

          {/* Categories Collected Section and Table */}
          <Section
            title={
              privacyPolicyData.californiaPrivacyRights.categoriesCollected
                .title
            }
            summary={
              privacyPolicyData.californiaPrivacyRights.categoriesCollected
                .content[0]
            }
          />
          {renderCaliforniaTable()}

          {/* Retention Policy */}
          {renderRetentionPolicy()}

          {/* Usage and Sharing */}
          <Section
            title={
              privacyPolicyData.californiaPrivacyRights.usageAndSharing.title
            }
            details={
              privacyPolicyData.californiaPrivacyRights.usageAndSharing.content
            }
          />

          {/* Information Sharing */}
          <Section
            title={
              privacyPolicyData.californiaPrivacyRights.informationSharing.title
            }
            details={
              privacyPolicyData.californiaPrivacyRights.informationSharing
                .content
            }
          />

          {/* Rights */}
          <Section
            title={privacyPolicyData.californiaPrivacyRights.rights.title}
            details={privacyPolicyData.californiaPrivacyRights.rights.content}
          />

          {/* Verification Process */}
          <Section
            title={
              privacyPolicyData.californiaPrivacyRights.verificationProcess
                .title
            }
            details={
              privacyPolicyData.californiaPrivacyRights.verificationProcess
                .content
            }
          />

          {/* Other Rights */}
          <Section
            title={privacyPolicyData.californiaPrivacyRights.otherRights.title}
            details={
              privacyPolicyData.californiaPrivacyRights.otherRights.content
            }
          />
        </Box>

        {/* Render Virginia Privacy Rights Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: "600", mt: 4 }}>
            {privacyPolicyData.virginiaPrivacyRights.title}
          </Typography>
          {privacyPolicyData.virginiaPrivacyRights.intro.map(
            (paragraph, index) => (
              <Typography
                key={`${index}-intro-paragraph`}
                variant="body1"
                sx={{ mt: 2 }}
              >
                {paragraph}
              </Typography>
            )
          )}
          <Section
            title={privacyPolicyData.virginiaPrivacyRights.cdpaNotice.title}
            summary={privacyPolicyData.virginiaPrivacyRights.cdpaNotice.content.join(
              " "
            )}
            details={privacyPolicyData.virginiaPrivacyRights.cdpaNotice.links}
          />
          <Section
            title="Your Rights"
            details={privacyPolicyData.virginiaPrivacyRights.rights}
          />
          <Section
            title="Statement"
            details={privacyPolicyData.virginiaPrivacyRights.statement}
          />
          <Section
            title={privacyPolicyData.virginiaPrivacyRights.exerciseRights.title}
            details={
              privacyPolicyData.virginiaPrivacyRights.exerciseRights.content
            }
          />
          <Section
            title="Verification Process"
            details={
              privacyPolicyData.virginiaPrivacyRights.verificationProcess
            }
          />
          <Section
            title="Right to Appeal"
            details={privacyPolicyData.virginiaPrivacyRights.rightToAppeal}
          />
        </Box>

        {/* Render Updates to Notice Section */}
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.updatesToNotice.title}
            details={privacyPolicyData.updatesToNotice.content}
          />
        </Box>

        {/* Render Contact Information Section */}
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.contactInformation.title}
            details={privacyPolicyData.contactInformation.content}
          />
        </Box>

        {/* Render Review, Update, or Delete Section */}
        <Box sx={{ mt: 4 }}>
          <Section
            title={privacyPolicyData.reviewUpdateDelete.title}
            details={privacyPolicyData.reviewUpdateDelete.content}
          />
        </Box>
      </Box>
    </section>
  );
};

export default PrivacyPolicy;
