import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import { clearFormAddItemData } from "../../redux/slices/appStateSlice";
import { Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import saveDraftProduct from "../../utils/saveDraftProduct";
import saveDraftService from "../../utils/saveDraftService";

function CustomPrompt({
  when,
  toNavigate,
  userData,
  formAddItemData,
  service,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isBlocked, setIsBlocked] = useState(false);

  // Function to show custom confirmation modal
  const showCustomConfirmModal = useCallback(() => {
    Modal.confirm({
      title: "Unsaved Changes",
      content:
        "You have unsaved changes. Are you sure you want to leave this page?",
      okText: "Yes, leave page",
      cancelText: "Stay on page",

      onOk: async () => {
        dispatch(clearFormAddItemData());
        setIsBlocked(false);
        navigate(toNavigate);
        if (!service) {
          await saveDraftProduct({
            userData,
            formAddItemData,
            values: false,
          });
          localStorage.setItem("onOkProduct", "true");
        } else {
          await saveDraftService({
            userData,
            formAddItemData,
            values: false,
          });
          localStorage.setItem("onOkService", "true");
        }
      },

      onCancel: async () => {
        setIsBlocked(false);

        if (!service) {
          await saveDraftProduct({
            userData,
            formAddItemData,
            values: true,
          });
          localStorage.setItem("onOkProduct", "true");
        } else {
          await saveDraftService({
            userData,
            formAddItemData,
            values: true,
          });
          localStorage.setItem("onOkService", "true");
        }
      },
    });
  }, [dispatch, formAddItemData, navigate, service, toNavigate, userData]);

  useBlocker(() => {
    if (when && !isBlocked) {
      setIsBlocked(true);
      showCustomConfirmModal();
      return true;
    }
    return false;
  }, [when, isBlocked, showCustomConfirmModal]);

  // Function to block navigation if conditions are met
  const blockNavigation = useCallback(() => {
    if (when && !isBlocked) {
      setIsBlocked(true);
      showCustomConfirmModal();
      return true;
    }
    return false;
  }, [when, isBlocked, showCustomConfirmModal]);

  useBlocker(blockNavigation, [blockNavigation]);

  useEffect(() => {
    setIsBlocked(false);
  }, [location.pathname]);

  return null;
}

export default CustomPrompt;
