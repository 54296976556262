export const formatAddress = (address) => {
  const { addressLineOne = '', state="",city = '',country = ''  } = address || {};
  const isCityInAddressLine = addressLineOne.includes(city);
  if (isCityInAddressLine) {
    // return `${addressLineOne}, ${zipCode}, ${addressLineTwo}`;
    return `${addressLineOne} ${state ? `, ${state}` : ''} ${country ? `, ${country}` : ''} `;
  }
  // return `${addressLineOne}${addressLineOne && city ? ', ' : ''}${city}${zipCode ? ', ' : ''}${zipCode} ${addressLineTwo}`;
  return `${addressLineOne}${addressLineOne && city ? ', ' : ''}${city} ${state ? ', ' : ''}${state}${country ? ', ' : ''}${country}`;

};