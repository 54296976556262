import React from "react";
import { Dialog, Typography, Button, useTheme, Stack } from "@mui/material";
import illustrationSuccess from "../../../../../assets/Images/Illustration Success.png";
const PayoutSuccessDialog = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
    
        "& .MuiPaper-root": {
          borderRadius: "16px",
          boxShadow: "none",
          padding: "24px",
          textAlign: "center",
        },
      }}
    >
      <Stack gap={3}>
        <img
          src={illustrationSuccess}
          alt="Illustration Success"
          loading="lazy"
          style={{
            width: "250px",
            height: "200px",
            objectFit: "cover",
            margin: "0 auto",
          }}
        />

        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: theme.palette.text.primary,
            textTransform: "capitalize",
            marginBottom: "12px",
          }}
        >
          payouts initiated successfully!
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
            marginBottom: "16px",
          }}
        >
          Your payout request has been successfully processed. The funds will be
          transferred to your bank account within 1-2 business days. Please
          check your account for confirmation.
        </Typography>

        <Button
          variant="contained"
          onClick={onClose}
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            padding: "8px 24px",
            borderRadius: "20px",
            boxShadow: "none",
            margin: "0 auto",
            width: "50%",

            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.light,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: "none",
            },
          }}
        >
          ok
        </Button>
      </Stack>
    </Dialog>
  );
};

export default PayoutSuccessDialog;
