import {
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { db } from "../../configs/firebase";
import backgroundImage from "../../assets/Images/cover-seller.png";
import Loader from "../../components/common/Loader";
import {
  MdContentPasteGo,
  MdEmail,
  MdOutlineChat,
  MdOutlineDescription,
  MdPhone,
} from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

import {
  setCurrentProduct,
  setFollowingStatus,
} from "../../redux/slices/appStateSlice";
import { useDispatch, useSelector } from "react-redux";
import noItems from "../../assets/Images/no-Items.png";
import CustomPagination from "../../components/common/CustomPagention";
import RatingsComponent from "./component/RatingsComponent";
import Avatar from "./component/Avatar";
import ItemsProducts from "../../components/common/ItemsProducts";
import { serverTimestamp } from "firebase/firestore";
import { message } from "antd";
import ReviewCard from "./component/ReviewCard";
import LocationMapModal from "../../components/common/LocationMapModal";
import { HiGlobeAlt } from "react-icons/hi";
import { FaClock } from "react-icons/fa";
import { format } from "date-fns";

const SellerDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const productsPerPage = 6;
  const [reviews, setReviews] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [currentReviewPage, setCurrentReviewPage] = useState(1);
  const [lastVisibleReview, setLastVisibleReview] = useState(null);
  const [reviewsTotalCount, setReviewsTotalCount] = useState(0);
  const reviewsPerPage = 5;
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUser, setFilteredUser] = useState(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageLoaded, setImageLoaded] = useState(false);
  const [displayedProducts, setdisplayedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedTab, setSelectedTab] = useState("business");
  const [selectedType, setSelectedType] = useState("");
  const [itemsCount, setItemsCount] = useState(0);
  const [servicesCount, setServicesCount] = useState(0);
  const [isMapOpen, setMapOpen] = useState(false);
  const userData = useSelector((state) => state.appState.userData);
  const isFollowing = useSelector((state) => state.appState.isFollowing);
  const businessSectionRef = React.useRef(null);

  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const servicesCategories = useSelector(
    (state) => state.appState.servicesCategories
  );

  const toggleDetailsExpanded = () => {
    setIsExpanded(!isExpanded);

    if (isExpanded && businessSectionRef.current) {
      setTimeout(() => {
        businessSectionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start", // Ensures it scrolls to the top of the section
        });
      }, 100);
    }
  };

  const inputStyles = {
    btn: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22.4px",
      textAlign: "center",
      padding: "13px 40px",
      borderRadius: "10px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "170px",
      height: "50px",
    },
  };

  const tabStyles = (tabValue) => ({
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontSize: selectedTab === tabValue ? "16px" : "14px",
    fontWeight: selectedTab === tabValue ? 800 : 700,
    lineHeight: "24px",
    letterSpacing: "0.2px",
    overflow: { sm: "auto", md: "hidden" },
    color:
      selectedTab === tabValue
        ? theme.palette.primary.main
        : theme.palette.primary.darkMain,
    borderBottom:
      selectedTab === tabValue
        ? `2px solid ${theme.palette.primary.main}`
        : "none",
    paddingBottom: "10px",
    minWidth: "120px",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  });

  const fetchTotalReviewCount = async (uid) => {
    try {
      const totalReviewsSnapshot = await db
        .collection("users")
        .doc(uid)
        .collection("reviews")
        .get();
      setReviewsTotalCount(totalReviewsSnapshot.size);
    } catch (error) {
      console.error("Error fetching total review count:", error);
    }
  };

  const handleReviewPageChange = (event, value) => {
    setCurrentReviewPage(value);
    fetchReviews(id, value);
  };

  const fetchReviews = async (uid, page = 1) => {
    setReviewsLoading(true);
    try {
      let reviewsRef = db
        .collection("users")
        .doc(uid)
        .collection("reviews")
        .orderBy("updatedAt", "desc")
        .limit(reviewsPerPage);
      if (page > 1 && lastVisibleReview) {
        reviewsRef = reviewsRef.startAfter(lastVisibleReview);
      }

      const reviewsSnapshot = await reviewsRef.get();
      const newReviews = [];

      reviewsSnapshot.forEach((doc) => {
        newReviews.push({ id: doc.id, ...doc.data() });
      });

      setLastVisibleReview(
        reviewsSnapshot.docs[reviewsSnapshot.docs.length - 1]
      );
      setReviews(newReviews);
    } catch (error) {
      console.error("Error fetching reviews:", error);
      message.error("Error fetching reviews");
    } finally {
      setReviewsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTab === "reviews" && id) {
      fetchReviews(id, currentReviewPage);
    }
  }, [selectedTab, id, currentReviewPage]);

  useEffect(() => {
    if (location.state && location.state.selectedTab) {
      setSelectedType(location.state.selectedTab);
    }
  }, [location.state]);

  const mapUserTypeToTab = (userType) => {
    switch (userType) {
      case "serviceProvider":
        return "Services Providers";
      case "seller":
        return "Sellers";
      case "all":
        return "All";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    const loggedIn = userData?.uid;
    setIsLoggedIn(!!loggedIn);
  }, [userData]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const fetchUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const usersSnapshot = await db
        .collection("users")
        .where("uid", "==", id)
        .where("isDeleted", "==", false)
        .get();

      if (!usersSnapshot.empty) {
        const userDoc = usersSnapshot.docs[0].data();
        const userType = userDoc.userType;
        const mappedTab = mapUserTypeToTab(userType);
        setSelectedType(mappedTab);
        console.log(userDoc, "userDoc");
        setFilteredUser(userDoc);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) fetchUserData();
  }, [fetchUserData, id]);

  useEffect(() => {
    if (id) {
      getProductsCount(id);
      getServicesCount(id);
      fetchTotalReviewCount(id);
    }
  }, [id]);

  const getProductsCount = async (uid) => {
    try {
      const docs = await db
        .collection("products")
        .where("isActive", "==", true)
        .where("isSold", "==", false)
        .where("isDeleted", "==", false)
        .where("uid", "==", uid)
        .get();
      setItemsCount(docs.size);
    } catch (error) {
      console.error("Error fetching product count:", error);
    }
  };

  const getServicesCount = async (uid) => {
    try {
      const docs = await db
        .collection("services")
        .where("isActive", "==", true)
        .where("isDeleted", "==", false)
        .where("uid", "==", uid)
        .where("isDeleted", "==", false)
        .get();
      setServicesCount(docs.size);
    } catch (error) {
      console.error("Error fetching services count:", error);
    }
  };

  useEffect(() => {
    if (id) {
      selectedTab === "items" ? fetchProducts(id) : fetchServices(id);
    }
  }, [id, selectedTab]);

  const fetchProducts = async (uid) => {
    setIsLoading(true);
    try {
      const productsRef = db
        .collection("products")
        .where("isActive", "==", true)

        .where("isDeleted", "==", false)

        .where("uid", "==", uid)
        .orderBy("createdAt", "desc");

      // Fetching the data once using getDocs
      const querySnapshot = await productsRef.get();

      let products = [];
      querySnapshot.forEach((doc) => {
        products.push(doc.data());
      });

      setdisplayedProducts(products);
      setItemsCount(products.length);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchServices = async (uid) => {
    setIsLoading(true);
    try {
      const servicesRef = db
        .collection("services")
        .where("isActive", "==", true)
        .where("isDeleted", "==", false)
        .where("uid", "==", uid)
        .where("isDeleted", "==", false)
        .orderBy("createdAt", "desc");

      // Fetching the data once using getDocs
      const querySnapshot = await servicesRef.get();

      let services = [];
      querySnapshot.forEach((doc) => {
        services.push(doc.data());
      });

      setdisplayedProducts(services);
      setServicesCount(services.length);
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const goToProductPage = (id, product, nameToDisplay) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setCurrentProduct(product));
    if (selectedTab === "services") {
      navigate(`/service-details/${id}`, { state: { nameToDisplay } });
    } else {
      navigate(`/product/${id}`, { state: { nameToDisplay } });
    }
  };

  useEffect(() => {
    if (selectedType === "Services Providers") {
      setSelectedTab("services");
    } else if (selectedType === "Sellers") {
      setSelectedTab("items");
    }
  }, [selectedType]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = displayedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const handlePageChange = (event, value) => {
    window.scrollTo({ top: 500, behavior: "smooth" });
    setCurrentPage(value);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(1);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("tab", newValue);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  useEffect(() => {
    if (id && userData?.uid) {
      checkFollowingStatus();
    }
  }, [id, userData]);

  const checkFollowingStatus = async () => {
    const followerRef = db
      .collection("users")
      .doc(id)
      .collection("followers")
      .doc(userData?.uid);

    try {
      const docSnapshot = await followerRef.get();
      dispatch(setFollowingStatus(docSnapshot.exists));
    } catch (error) {
      console.error("Error checking following status:", error);
    }
  };

  const handleFollow = async () => {
    if (!isLoggedIn) {
      message.error("Please log in to follow users.");
      return;
    }

    const followerRef = db
      .collection("users")
      .doc(id)
      .collection("followers")
      .doc(userData?.uid);

    try {
      // Check if the user is already following
      const docSnapshot = await followerRef.get();

      if (docSnapshot.exists) {
        message.info("You are already following this user.");
        dispatch(setFollowingStatus(true));
      } else {
        const followerData = {
          uid: userData?.uid,
          // firstName: userData?.firstName,
          // lastName: userData?.lastName,
          // name: userData?.firstName + " " + userData?.lastName,
          name:
            userData?.businessInfo?.name ||
            userData?.firstName + " " + userData?.lastName,
          profilePicture: userData?.profilePicture
            ? userData.profilePicture
            : null,
          createdAt: serverTimestamp(),
        };
        await followerRef.set(followerData);
        dispatch(setFollowingStatus(true));
        message.success("You have successfully followed this user!");
      }
    } catch (error) {
      console.error("Error updating followers:", error);
    }
  };

  const handleUnfollow = async () => {
    const followerRef = db
      .collection("users")
      .doc(id)
      .collection("followers")
      .doc(userData?.uid);

    try {
      await followerRef.delete();
      dispatch(setFollowingStatus(false));
      message.success("You have successfully unfollowed this user!");
    } catch (error) {
      console.error("Error removing follower:", error);
    }
  };

  const getCategoryName = (categoryId) => {
    const category = itemsCategories.find((cat) => cat?.id === categoryId);

    return category ? category.name : "Unknown Category";
  };

  const getServiceName = (categoryId) => {
    if (!servicesCategories.length) return "Loading...";

    const category = servicesCategories.find(
      (service) => service.id === categoryId
    );
    return category ? category.name : "Unknown service";
  };

  //navigate to chat page and send the current user  object with it

  const handleChatButtonClick = (request) => {
    if (isLoggedIn) {
      navigate("/chat?type=users", { state: { user: filteredUser } });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/login");
    }
  };

  const formattedAddress = [
    filteredUser?.businessInfo?.address?.city,
    filteredUser?.businessInfo?.address?.state,
    filteredUser?.businessInfo?.address?.zipCode,
  ]
    .filter(Boolean)
    .join(", ");

  const displayName = () => {
    if (filteredUser?.userType === "buyer") {
      return (
        `${filteredUser?.firstName || ""} ${filteredUser?.lastName || ""}` ||
        filteredUser?.name
      );
    }
    return filteredUser?.businessInfo?.name || "Business name";
  };

  const displayEmail = () => {
    const emailAddress =
      filteredUser?.userType === "buyer"
        ? filteredUser?.email
        : filteredUser?.businessInfo?.email;

    return emailAddress ? (
      <a
        href={`mailto:${emailAddress}`}
        style={{ textDecoration: "none", color: theme.palette.dark.dustyBlue }}
      >
        {emailAddress}
      </a>
    ) : (
      "No Email"
    );
  };

  const displayPhoneNumber = () => {
    const phoneNumber =
      filteredUser?.userType === "buyer"
        ? filteredUser?.phoneNumber
        : filteredUser?.businessInfo?.phoneNumber;

    return phoneNumber ? `${phoneNumber}` : "No Phone Number";
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    if (tab) {
      setSelectedTab(tab);
    }
  }, [location.search]);
  const handleOpenMap = () => setMapOpen(true);
  const handleCloseMap = () => setMapOpen(false);

  // Function to convert Firebase Timestamp to JS Date
  const convertFirebaseTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return null;
    return new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  };

  return (
    <>
      <Box sx={{ margin: { xs: "0 0", sm: "0 0", md: "0 35px" } }}>
        <Box
          sx={{
            position: "relative",
            background: `url(${backgroundImage}) center center / cover no-repeat`,
            // background: filteredUser?.businessInfo?.businessImage ? `url(${filteredUser.businessInfo.businessImage}) center center / cover no-repeat` : `url(${backgroundImage}) center center / cover no-repeat`,

            height: "218px",
            borderRadius: isSmallScreen ? "0px" : "10px",
            color: theme.palette.primary.light,
          }}
        >
          <Box
            sx={{
              width: "179px",
              height: "179px",
              position: "absolute",
              top: isSmallScreen ? "65px" : "130px",
              left: isSmallScreen ? "0px" : "40px",
              borderRadius: "50%",
              boxSizing: "border-box",
            }}
          >
            {filteredUser?.profilePicture ? (
              <>
                {!imageLoaded && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: theme.palette.grey[200],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loader />
                  </Box>
                )}

                <img
                  src={filteredUser?.profilePicture}
                  alt={filteredUser?.businessInfo?.address?.name}
                  loading="lazy"
                  onLoad={handleImageLoad}
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
              </>
            ) : (
              <Avatar
                name={
                  filteredUser?.businessInfo?.name ||
                  filteredUser?.firstName ||
                  "User"
                }
                borderRadius="50%"
                fontSize="10rem"
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            background: theme.palette.primary.light,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: isSmallScreen ? "25px 0" : "30px",
            flexDirection: isSmallScreen ? "column " : "row",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              position: "relative",
              left: isSmallScreen ? "0" : "220px",
              color: theme.palette.primary.label,
              marginBottom: isSmallScreen ? "10px" : "0",
            }}
          >
            {displayName()}
          </Typography>
          {userData?.uid !== filteredUser?.uid && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <IconButton
                onClick={() => handleChatButtonClick(filteredUser)}
                sx={{
                  background: theme.palette.primary.main,
                  borderRadius: "50%",
                  width: "46px",
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: theme.palette.primary.light,
                  "&:hover": {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                  },
                }}
              >
                <MdOutlineChat
                  style={{
                    color: theme.palette.primary.light,
                    fontSize: "1.5rem",
                  }}
                />
              </IconButton>
              <Button
                onClick={isFollowing ? handleUnfollow : handleFollow}
                style={{
                  ...inputStyles.btn,
                  textTransform: "capitalize",
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
                disabled={isLoading}
              >
                {isFollowing ? "Unfollow" : "Follow"}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <section style={{ margin: "0 25px" }}>
        <Grid
          container
          spaceing={2}
          gap={
            filteredUser?.userType !== "buyer" &&
            filteredUser?.userType !== "serviceProvider"
              ? 0
              : 2
          }
          sx={{
            mt: 4,
            justifyContent:
              filteredUser?.userType !== "buyer" &&
              filteredUser?.userType !== "serviceProvider"
                ? "space-between"
                : "flex-start",
          }}
        >
          <Grid item xs={12} md={2.9} sx={{ mb: 3 }}>
            <Box
              sx={{
                background: theme.palette.primary.light,
                padding: "15px",
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  color: theme.palette.dark.charcoalBlack,
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                About
              </Typography>
              <Box>
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "1.4rem",
                      color: theme.palette.dark.dustyBlue,
                      marginBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleOpenMap}
                  >
                    <FaLocationDot
                      style={{
                        color: theme.palette.primary.main,
                        marginRight: "10px",
                        fontSize: "20px",
                      }}
                    />{" "}
                    {formattedAddress || "No Address Available"}
                  </Typography>
                  <LocationMapModal
                    open={isMapOpen}
                    onClose={handleCloseMap}
                    lat={filteredUser?.businessInfo?.address?.lat}
                    lng={filteredUser?.businessInfo?.address?.lng}
                    address={
                      filteredUser?.businessInfo?.address
                        ? filteredUser?.businessInfo?.address
                        : filteredUser?.address
                    }
                  />
                </>

                <Typography
                  variant="body2"
                  sx={{
                    lineHeight: "1.4rem",
                    color: theme.palette.dark.dustyBlue,
                    marginBottom: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MdEmail
                    style={{
                      color: theme.palette.primary.main,
                      marginRight: "10px",
                      fontSize: "20px",
                    }}
                  />{" "}
                  {displayEmail()}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    lineHeight: "1.4rem",
                    color: theme.palette.dark.dustyBlue,
                    marginBottom: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MdPhone
                    style={{
                      color: theme.palette.primary.main,
                      marginRight: "10px",
                      fontSize: "23px",
                    }}
                  />{" "}
                  <a
                    href={`tel:${displayPhoneNumber()}`}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    {displayPhoneNumber()}
                  </a>
                </Typography>

                {filteredUser?.businessInfo?.websiteLink && (
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "1.4rem",
                      color: theme.palette.dark.dustyBlue,
                      marginBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                      wordBreak: "break-word",
                    }}
                  >
                    <HiGlobeAlt
                      style={{
                        color: theme.palette.primary.main,
                        marginRight: "10px",
                        fontSize: "23px",
                      }}
                    />{" "}
                    <a
                      href={filteredUser?.businessInfo?.websiteLink}
                      target="_blank"
                      rel="noopener noreferrer" // for security reasons
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        cursor: "pointer",
                        wordBreak: "break-word",
                      }}
                    >
                      {filteredUser?.businessInfo?.websiteLink}
                    </a>
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={filteredUser?.userType === "buyer" ? 8 : 6}
            sx={{
              background: theme.palette.primary.light,
              padding: 2.2,
              borderRadius: "15px",
            }}
          >
            <Box
              sx={{
                mb: 5,
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  mb: 2,

                  "& .MuiTabs-flexContainer": {
                    overflow: "auto",
                  },
                }}
              >
                {/* Business Tab */}
                {filteredUser?.userType !== "buyer" &&
                  (filteredUser?.businessInfo?.description ||
                    filteredUser?.businessInfo?.businessImage ||
                    filteredUser?.businessInfo?.businessContent) && (
                    <Tab
                      label="Business info"
                      value="business"
                      sx={tabStyles("business")}
                    />
                  )}
                {selectedType === "All"
                  ? // Show both Items and Services
                    [
                      { label: `Items (${itemsCount})`, value: "items" },
                      {
                        label: `Services (${servicesCount})`,
                        value: "services",
                      },
                    ].map((tab, index) => (
                      <Tab
                        key={index}
                        label={tab.label}
                        value={tab.value}
                        sx={{
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                          fontSize: selectedTab === tab.value ? "16px" : "14px",
                          fontWeight: selectedTab === tab.value ? 800 : 700,
                          lineHeight: "24px",
                          letterSpacing: "0.2px",
                          color:
                            selectedTab === tab.value
                              ? theme.palette.primary.main
                              : theme.palette.primary.darkMain,
                          borderBottom:
                            selectedTab === tab.value
                              ? `2px solid ${theme.palette.primary.main}`
                              : "none",
                          paddingBottom: "10px",
                          minWidth: "120px",
                          overflow: { sm: "auto", md: "hidden" },
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                    ))
                  : selectedType === "Sellers" ||
                    filteredUser?.userType === "buyer"
                  ? // Show only Items
                    [{ label: `Items (${itemsCount})`, value: "items" }].map(
                      (tab, index) => (
                        <Tab
                          key={index}
                          label={tab.label}
                          value={tab.value}
                          sx={{
                            textTransform: "capitalize",
                            fontFamily: "Poppins",
                            fontSize:
                              selectedTab === tab.value ? "16px" : "14px",
                            fontWeight: selectedTab === tab.value ? 800 : 700,
                            lineHeight: "24px",
                            letterSpacing: "0.2px",
                            overflow: { sm: "auto", md: "hidden" },
                            color:
                              selectedTab === tab.value
                                ? theme.palette.primary.main
                                : theme.palette.primary.darkMain,
                            borderBottom:
                              selectedTab === tab.value
                                ? `2px solid ${theme.palette.primary.main}`
                                : "none",
                            paddingBottom: "10px",
                            minWidth: "120px",
                            "&:hover": {
                              color: theme.palette.primary.main,
                            },
                          }}
                        />
                      )
                    )
                  : selectedType === "Services Providers"
                  ? // Show only Services
                    [
                      {
                        label: `Services (${servicesCount})`,
                        value: "services",
                      },
                    ].map((tab, index) => (
                      <Tab
                        key={index}
                        label={tab.label}
                        value={tab.value}
                        sx={{
                          textTransform: "capitalize",
                          fontFamily: "Poppins",
                          fontSize: selectedTab === tab.value ? "16px" : "14px",
                          fontWeight: selectedTab === tab.value ? 800 : 700,
                          lineHeight: "24px",
                          letterSpacing: "0.2px",
                          overflow: { sm: "auto", md: "hidden" },
                          color:
                            selectedTab === tab.value
                              ? theme.palette.primary.main
                              : theme.palette.primary.darkMain,
                          borderBottom:
                            selectedTab === tab.value
                              ? `2px solid ${theme.palette.primary.main}`
                              : "none",
                          paddingBottom: "10px",
                          minWidth: "120px",
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                    ))
                  : null}
                {/* Reviews Tab */}
                {filteredUser?.userType !== "buyer" &&
                  filteredUser?.userType !== "serviceProvider" && (
                    <Tab
                      label={`Reviews (${reviewsTotalCount})`}
                      value="reviews"
                      sx={tabStyles("reviews")}
                    />
                  )}
              </Tabs>
            </Box>

            {isLoading ? (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "100vh",
                }}
              >
                <Loader />
              </Grid>
            ) : selectedTab === "reviews" ? (
              reviewsLoading ? (
                <Loader />
              ) : reviews?.length > 0 ? (
                <Grid
                  container
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  {reviews?.map((review) => (
                    <Grid
                      item
                      xs={12}
                      key={review?.id}
                      sx={{ marginBottom: 3 }}
                    >
                      <ReviewCard review={review} />
                    </Grid>
                  ))}
                  {selectedTab === "reviews" && (
                    <CustomPagination
                      totalPages={Math.ceil(reviewsTotalCount / reviewsPerPage)}
                      currentPage={currentReviewPage}
                      handlePageChange={handleReviewPageChange}
                    />
                  )}
                </Grid>
              ) : (
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "100vh",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={noItems}
                    alt="No reviews available"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: theme.typography.headerFont,
                      color: theme.palette.dark.charcoalBlack,
                    }}
                  >
                    No Reviews Found
                  </Typography>
                </Grid>
              )
            ) : selectedTab === "business" ? (
              <Grid
                container
                sx={{ flexDirection: "column", alignItems: "center" }}
              >
                {filteredUser?.businessInfo?.businessImage && (
                  <img
                    src={filteredUser?.businessInfo?.businessImage}
                    alt="Business"
                    style={{
                      width: "95%",
                      height: "230px",
                      borderRadius: "10px",
                    }}
                  />
                )}

                {filteredUser?.businessInfo?.businessContent && (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: "1.4rem",
                        fontWeight: 500,
                        marginTop: "40px",
                        marginBottom: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <MdContentPasteGo
                        style={{
                          color: theme.palette.primary.main,
                          marginRight: "6px",
                          fontSize: "20px",
                        }}
                      />
                      Business Content
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.dark.dustyBlue,
                        marginRight: "10px",
                      }}
                    >
                      {filteredUser?.businessInfo?.businessContent}
                    </Typography>
                  </Box>
                )}
                {filteredUser?.businessInfo?.openDays && (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: "1.4rem",
                        fontWeight: 500,
                        marginTop: "40px",
                        marginBottom: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaClock
                        style={{
                          color: theme.palette.primary.main,
                          marginRight: "6px",
                          fontSize: "20px",
                        }}
                      />
                      Business Hours
                    </Typography>

                    {filteredUser?.businessInfo?.openDays?.map((day, index) => {
                      // Convert Firebase Timestamp to JS Date
                      const fromDate = convertFirebaseTimestamp(day.from);
                      const toDate = convertFirebaseTimestamp(day.to);

                      // Format the dates
                      const fromTime = fromDate
                        ? format(fromDate, "hh:mm a")
                        : "N/A";
                      const toTime = toDate ? format(toDate, "hh:mm a") : "N/A";

                      return (
                        <Typography
                          key={index}
                          variant="body2"
                          sx={{
                            color: theme.palette.dark.dustyBlue,
                            marginBottom: "5px",
                          }}
                        >
                          {day?.days
                            ? `${day?.days}: Open from ${fromTime} to ${toTime}`
                            : "Business hours not available"}
                        </Typography>
                      );
                    })}
                  </Box>
                )}
                {filteredUser?.businessInfo?.description && (
                  <Box ref={businessSectionRef} sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: "1.4rem",
                        fontWeight: 500,
                        marginTop: "40px",
                        marginBottom: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <MdOutlineDescription
                        style={{
                          color: theme.palette.primary.main,
                          marginRight: "6px",
                          fontSize: "20px",
                        }}
                      />
                      Description
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.dark.dustyBlue,
                        marginRight: "10px",
                      }}
                    >
                      {isExpanded
                        ? filteredUser?.businessInfo?.description
                        : filteredUser?.businessInfo?.description?.slice(
                            0,
                            300
                          )}
                      {!isExpanded &&
                      filteredUser?.businessInfo?.description > 300
                        ? "..."
                        : ""}
                    </Typography>

                    {filteredUser?.businessInfo?.description &&
                      filteredUser?.businessInfo?.description.length > 300 && (
                        <Typography
                          variant="body2"
                          onClick={toggleDetailsExpanded}
                          sx={{
                            color: theme.palette.primary.main,

                            marginBottom: "20px",
                            cursor: "pointer",
                          }}
                        >
                          {isExpanded ? "Show Less" : "Show More"}
                        </Typography>
                      )}
                  </Box>
                )}
              </Grid>
            ) : currentProducts?.length > 0 ? (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {currentProducts.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={5}
                    xxl={3}
                    key={product?.id}
                    sx={{
                      marginBottom: 3,
                    }}
                  >
                    <ItemsProducts
                      item={product}
                      index={product?.id}
                      isLoggedIn={isLoggedIn}
                      selectedTabSeller={selectedTab}
                      goToPage={goToProductPage}
                      categorySellerName={getCategoryName(product.categoryId)}
                      serviseSellerName={getServiceName(product.categoryId)}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "100vh",
                  flexDirection: "column",
                }}
              >
                <img
                  src={noItems}
                  alt="No data available"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: theme.typography.headerFont,
                    color: theme.palette.dark.charcoalBlack,
                  }}
                >
                  No{" "}
                  {selectedTab === "items" ? "Active Items" : "Active Services"}{" "}
                  Found
                </Typography>
              </Grid>
            )}

            {/* Pagination Component */}
            {(selectedTab === "items" || selectedTab === "services") &&
              Math.ceil(displayedProducts.length / productsPerPage) > 1 && (
                <CustomPagination
                  totalPages={Math.ceil(
                    displayedProducts.length / productsPerPage
                  )}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              )}
          </Grid>

          {filteredUser?.userType !== "buyer" &&
            filteredUser?.userType !== "serviceProvider" && (
              <Grid item xs={12} md={2.9} sx={{ mt: { xs: 3, md: "0" } }}>
                <RatingsComponent filteredUser={filteredUser} />
              </Grid>
            )}
        </Grid>
      </section>
    </>
  );
};

export default SellerDetails;
