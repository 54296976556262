import { auth } from "../../../../configs/firebase";
import {
  BASE_URL,
  STRIPE_COLLECT_PAYMENT_ENDPOINT,
} from "../../../../components/layout/constants";
import { message } from "antd";

export const createLinkAccount = async (uid, urlLink) => {

  try {
    const currentUser = auth?.currentUser;
    if (!currentUser) throw new Error("User is not authenticated");

    const userToken = await currentUser.getIdToken();
    const url = `${BASE_URL}${STRIPE_COLLECT_PAYMENT_ENDPOINT}`;
    const headers = {
      "Content-Type": "application/json",
      authorization: userToken,
    };

    const body = JSON.stringify({ uid, urlLink });

    const response = await fetch(url, {
      method: "POST",
      headers,
      body,
    });

    const responseData = await response.json();
    
    if (!responseData?.isSuccess) {
      message.error(responseData?.message?.msg);
    }
    if (responseData?.isSuccess && responseData?.data?.accountLink?.url) {
      window.location.href = responseData?.data?.accountLink?.url;
    } else {
      console.error(" Error on redirect to create account page");

    }
  } catch (error) {
    console.error("Error creating account link:", error);
    message.error("Failed to create account link. Please try again later.");
  }
};
