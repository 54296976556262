import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  Button,
  Grid,
  PaginationItem,
  Pagination,
  Stack,
  Dialog,
} from "@mui/material";
import { FiPlus } from "react-icons/fi";
import ItemManagementTabs from "./components/ItemManagementTabs";
import ItemsControl from "./../../../components/common/ItemsControl";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFormAddItemData,
  clearFormAddServiceData,
  setCurrentProduct,
  setGoBackAddService,
  setGoBackEditService,
} from "../../../redux/slices/appStateSlice";
import { db } from "../../../configs/firebase";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { message } from "antd";
import {
  productsAscIndex,
  productsDescIndex,
  serviceAscIndex,
  serviceDescIndex,
  // serviceIndex,
} from "../../../configs/algoliaIndexes";
import algoliasearch from "algoliasearch";

import ItemsProducts from "../../../components/common/ItemsProducts";
import noitems from "../../../assets/Images/no-Items.png";
import {
  Algolia_App_Id,
  Algolia_Search_Api_Key,
} from "../../../components/layout/constants";
import DeleteBox from "../../../components/common/DeleteBox";

const ListItems = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sortOrderControle, setSortOrderControle] = useState("default");

  const userData = useSelector((state) => state.appState.userData);
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const servicesCategories = useSelector(
    (state) => state.appState.servicesCategories
  );

  const newItemServiceAdded = useSelector(
    (state) => state.appState.newItemAdded
  );

  const ChangeStutesServiceAdded = useSelector(
    (state) => state.appState.selectedProductStatus
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");

  const [listState, setListState] = useState("activeItems");
  const [currentPage, setCurrentPage] = useState(1);
  const [isTitle, setIsTitle] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(0);
  const [selectedType, setSelectedType] = useState(
    searchParams.get("type") || "items"
  );

  const [categoryIdProducts, setCategoryIdProducts] = useState([]);
  // States for each count

  const [deletedProductIds, setDeletedProductIds] = useState([]);
  const [deletedServicesIds, setDeletedServicesIds] = useState([]);

  // Local state to store counts
  const [activeItemsCount, setActiveItemsCount] = useState(0);
  const [soldItemsCount, setSoldItemsCount] = useState(0);
  const [draftItemsCount, setDraftItemsCount] = useState(0);
  const [activeItemsArray, setActiveItemsArray] = useState([]);

  // Local state to store counts for services
  const [activeServicesCount, setActiveServicesCount] = useState(0);
  const [draftServicesCount, setDraftServicesCount] = useState(0);
  const [activeServicesArray, setActiveServicesArray] = useState([]);
  const [isDelayPassed, setIsDelayPassed] = useState(false);

  useEffect(() => {
    const loggedIn =
      userData &&
      typeof userData === "object" &&
      userData !== null &&
      userData?.uid;

    setIsLoggedIn(!!loggedIn);
  }, [userData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelayPassed(true);
    }, 10000);

    // Clean up timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (selectedType === "items") {
      setListState("activeItems");
    } else if (selectedType === "services") {
      setListState("activeService");
    }
  }, [selectedType]);

  useEffect(() => {
    const typeFromParams = searchParams?.get("type");
    if (typeFromParams && typeFromParams !== selectedType) {
      setSelectedType(typeFromParams);
    }
  }, [searchParams, selectedType]);

  useEffect(() => {
    const savedDeletedProductIds =
      JSON.parse(localStorage.getItem("deletedProductIds")) || [];
    const savedDeletedServicesIds =
      JSON.parse(localStorage.getItem("deletedServicesIds")) || [];

    setDeletedProductIds(savedDeletedProductIds);
    setDeletedServicesIds(savedDeletedServicesIds);
  }, []);

  const client = algoliasearch(Algolia_App_Id, Algolia_Search_Api_Key);
  const productsIndex = client.initIndex("products");
  const servicesIndex = client.initIndex("services");

  const getIndex = useMemo(() => {
    if (selectedType === "items") {
      if (sortOrderControle === "price_asc") {
        return productsAscIndex;
      } else if (sortOrderControle === "price_desc") {
        return productsDescIndex;
      }
      return productsIndex;
    } else if (selectedType === "services") {
      if (sortOrderControle === "price_asc") {
        return serviceAscIndex;
      } else if (sortOrderControle === "price_desc") {
        return serviceDescIndex;
      }
      return servicesIndex;
    }

    return productsIndex;
  }, [selectedType, sortOrderControle]);

  const filter = useMemo(() => {
    const filters = [];

    // Always filter by current user's UID
    if (userData?.uid) {
      filters.push(`uid:${userData?.uid}`);
    }

    if (selectedType === "services") {
      if (listState === "activeService") {
        filters.push("isActive:true");
        filters.push("isDraft:false");
        filters.push("isDeleted:false");
      } else if (listState === "draftService") {
        filters.push("isActive:false");
        filters.push("isDraft:true");
        filters.push("isDeleted:false");
      }
    } else if (selectedType === "items") {
      if (listState === "activeItems") {
        filters.push("isActive:true");
        filters.push("isDeleted:false");
        filters.push("isDraft:false");
        filters.push("isSold:false");
      } else if (listState === "sold") {
        filters.push("isActive:false");
        filters.push("isDraft:false");
        filters.push("isSold:true");
        filters.push("isDeleted:false");
      } else if (listState === "draft") {
        filters.push("isActive:false");
        filters.push("isDraft:true");
        filters.push("isDeleted:false");
        filters.push("isSold:false");
      }
    }

    if (selectedType === "items" && selectedCategoryId) {
      filters.push(`categoryId:${selectedCategoryId}`);
    } else if (selectedType === "services" && selectedServiceId) {
      filters.push(`categoryId:${selectedServiceId}`);
    }

    return filters.join(" AND ");
  }, [
    userData?.uid,
    selectedType,
    selectedCategoryId,
    selectedServiceId,
    listState,
  ]);

  const fetchCounts = useCallback(async () => {
    try {
      const baseFilter = `uid:${userData?.uid}`;

      const activeItemsFilter = `${baseFilter} AND isActive:true AND isDeleted:false${
        selectedType === "items" && selectedCategoryId
          ? ` AND categoryId:${selectedCategoryId}`
          : ""
      }`;
      const soldItemsFilter = `${baseFilter} AND isSold:true AND isDraft:false AND isActive:false AND isDeleted:false${
        selectedType === "items" && selectedCategoryId
          ? ` AND categoryId:${selectedCategoryId}`
          : ""
      }`;
      const draftItemsFilter = `${baseFilter} AND isDraft:true AND isDeleted:false AND isActive:false AND isSold:false${
        selectedType === "items" && selectedCategoryId
          ? ` AND categoryId:${selectedCategoryId}`
          : ""
      }`;
      const activeServicesFilter = `${baseFilter} AND isActive:true AND isDraft:false AND isDeleted:false${
        selectedType === "services" && selectedServiceId
          ? ` AND categoryId:${selectedServiceId}`
          : ""
      }`;
      const draftServicesFilter = `${baseFilter} AND isDraft:true AND isActive:false AND isDeleted:false${
        selectedType === "services" && selectedServiceId
          ? ` AND categoryId:${selectedServiceId}`
          : ""
      }`;

      const [
        activeItems,
        soldItems,
        draftItems,
        activeServices,
        draftServices,
      ] = await Promise.all([
        getIndex.search("", { filters: activeItemsFilter }),
        getIndex.search("", { filters: soldItemsFilter }),
        getIndex.search("", { filters: draftItemsFilter }),
        getIndex.search("", { filters: activeServicesFilter }),
        getIndex.search("", { filters: draftServicesFilter }),
      ]);

      setActiveItemsCount(activeItems.nbHits);
      setSoldItemsCount(soldItems.nbHits);
      setDraftItemsCount(draftItems.nbHits);
      setActiveServicesCount(activeServices.nbHits);
      setDraftServicesCount(draftServices.nbHits);
    } catch (error) {
      console.error("Error fetching counts:", error);
      message.error("An error occurred while updating counts.");
    }
  }, [
    userData?.uid,
    selectedType,
    selectedCategoryId,
    selectedServiceId,
    getIndex,
  ]);

  const fetchProducts = useCallback(async () => {
    if (!getIndex || !filter) {
      console.warn("Missing index or filter for Algolia query.");
      return;
    }
    setCategoryIdProducts([]);
    setIsLoading(true);

    try {
      const algoliaQuery = {
        filters: filter,
        hitsPerPage: 12,
        page: currentPage - 1,
      };

      const { hits, nbPages } = await getIndex.search("", algoliaQuery);

      let updatedHits = hits.map((product) => ({
        ...product,
        price: product?.price !== (undefined || null) ? product?.price : null,
      }));

      let sortedHits = updatedHits;

      if (sortOrderControle === "price_asc") {
        sortedHits = updatedHits.sort((a, b) => {
          if (a.price === null || a.price === undefined) return 1;
          if (b.price === null || b.price === undefined) return -1;
          return a.price - b.price;
        });
      } else if (sortOrderControle === "price_desc") {
        sortedHits = updatedHits.sort((a, b) => {
          if (a.price === null || a.price === undefined) return 1;
          if (b.price === null || b.price === undefined) return -1;
          return b.price - a.price;
        });
      } else {
        sortedHits = updatedHits.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
      }

      setCategoryIdProducts(sortedHits);
      setTotalPages(nbPages);

      fetchCounts();
    } catch (error) {
      console.error("Error fetching products:", error);
      message.error("An error occurred while fetching data.");
    } finally {
      setIsLoading(false);
    }
  }, [
    getIndex,
    filter,
    currentPage,
    ChangeStutesServiceAdded?.id,
    newItemServiceAdded,
    listState,
    selectedType,
    deletedProductIds,
    deletedServicesIds,
    sortOrderControle,
    userData?.uid,
  ]);

  useEffect(() => {
    fetchProducts(currentPage);
  }, [
    currentPage,
    selectedCategoryId,
    selectedServiceId,
    sortOrderControle,
    selectedType,
    fetchProducts,
  ]);

  useEffect(() => {
    if (userData?.uid) {
      const timer = setTimeout(() => {
        fetchCounts();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [
    fetchCounts,
    userData?.uid,
    selectedType,
    selectedCategoryId,
    sortOrderControle,
  ]);

  useEffect(() => {
    if (categoryIdProducts.length < 1) {
      if (selectedType === "items") {
        setCategoryIdProducts(activeItemsArray);
      } else if (selectedType === "services") {
        setCategoryIdProducts(activeServicesArray);
      }
      setCategoryIdProducts(categoryIdProducts);
    }
  }, [activeItemsArray, activeServicesArray, categoryIdProducts, selectedType]);

  const handleTabChange = (tap) => {
    setListState(tap);
    setCurrentPage(1);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setCurrentPage(1);
    setSelectedCategoryId("");
    setSelectedServiceId("");
    setSortOrderControle("default");
    setSearchParams({ type: type });
  };

  const goToProductPage = (id, product, nameToDisplay) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setCurrentProduct(product));
    if (selectedType === "services") {
      navigate(`/service-details/${id}`, { state: { nameToDisplay } });
    } else {
      navigate(`/product/${id}`, { state: { nameToDisplay } });
    }
  };

  const getCategoryName = (categoryId) => {
    const category = itemsCategories?.find((cat) => cat?.id === categoryId);
    return category ? category?.name : "Unknown Category";
  };

  const getServiceName = (categoryId) => {
    const category = servicesCategories?.find(
      (service) => service?.id === categoryId
    );
    return category ? category?.name : "Unknown service";
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchProducts(value);
    window.scrollTo({ top: 500, behavior: "smooth" });
  };

  const handleSortOrderChange = (value) => {
    setSortOrderControle(value);
    setCurrentPage(1);
    fetchProducts(1);
  };
  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  const handleServiceChange = (serviceId) => {
    setSelectedServiceId(serviceId);
  };

  const handleEdit = (ProductId) => {
    if (selectedType === "services") {
      dispatch(setGoBackEditService(false));
      navigate(`/selling/edit-service/${ProductId}`);
    } else {
      navigate(`/selling/edit-item/${ProductId}`);
    }
  };
  const handleDelete = (ProductId) => {
    setProductToDelete(ProductId);
    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const collectionName =
        selectedType === "services" ? "services" : "products";
      const docRef = doc(db, collectionName, productToDelete);

      setIsDeleteModalVisible(false);

      await updateDoc(docRef, {
        isDeleted: true,
        deletedAt: serverTimestamp(),
      });

      message.success("Item deleted successfully");

      setCategoryIdProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productToDelete)
      );

      setActiveItemsCount((prevCount) =>
        listState === "activeItems" ? prevCount - 1 : prevCount
      );
      setSoldItemsCount((prevCount) =>
        listState === "sold" ? prevCount - 1 : prevCount
      );
      setDraftItemsCount((prevCount) =>
        listState === "draft" ? prevCount - 1 : prevCount
      );
      setActiveServicesCount((prevCount) =>
        listState === "activeService" ? prevCount - 1 : prevCount
      );
      setDraftServicesCount((prevCount) =>
        listState === "draftService" ? prevCount - 1 : prevCount
      );
    } catch (error) {
      message.error("Error deleting item");
      console.error("Error deleting item:", error);
    } finally {
      setIsLoading(false);
      setProductToDelete(null);
    }
  };

  // Cancel deletion
  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
    setProductToDelete(null);
  };

  const handleNavigation = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (selectedType === "services") {
      handleServiceNavigation();
      dispatch(clearFormAddServiceData());
      localStorage.setItem("hasModalShownService", "false");
    } else if (selectedType === "items") {
      handleItemNavigation();
      dispatch(clearFormAddItemData());
      localStorage.setItem("hasModalShown", "false");
    }
  };

  const handleNavigationCsv = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (selectedType === "services") {
      if (userData?.userType === "buyer") {
        navigate("/selling/service-business-form");
      } else {
        navigate("/selling/upload-csv");
      }
    } else if (selectedType === "items") {
      if (userData?.userType === "buyer") {
        navigate("/selling/add-business-info");
      } else {
        navigate("/selling/upload-csv");
      }
    }
  };

  const handleServiceNavigation = () => {
    if (userData?.userType === "buyer") {
      navigate("/selling/service-business-form");
    } else {
      dispatch(setGoBackAddService(false));
      dispatch(clearFormAddServiceData());
      navigate("/selling/add-service");
    }
    dispatch(setGoBackAddService(false));
  };

  // Handle navigation for items
  const handleItemNavigation = () => {
    if (userData?.userType === "buyer") {
      navigate("/selling/add-business-info");
      dispatch(clearFormAddItemData());
    } else {
      navigate("/selling/add-item");
      dispatch(clearFormAddItemData());
    }
  };

  return (
    <Box
      sx={{
        margin: { xs: "0px 20px 0 40px", sm: "0px 20px 0 40px", lg: "0 40px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",

          flexDirection: { xs: "column", sm: "row", md: "row" },
        }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              fontFamily: theme.typography.headerFont,
              mb: 1,
            }}
          >
            Manage your listings
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.gray.numPrice }}
          >
            Here you can view, add and edit your{" "}
            {selectedType === "services" ? "services" : "parts"}
          </Typography>
        </Box>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            sx={{
              me: 2,
              background: theme.palette.primary.main,
              textTransform: "capitalize",
              padding: "15px 25px 15px 25px",
              width: { xs: "100%", sm: "auto", md: "inherit" },
              mt: { xs: "20px", md: "0" },
              "&:hover": {
                background: theme.palette.primary.main,
              },
            }}
            onClick={handleNavigation}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.primary.light,
                display: "flex",
                fontWeight: 700,
                alignItems: "center",
                gap: "10px",
                textTransform: "initial",
              }}
            >
              <FiPlus style={{ fontSize: "20px" }} />{" "}
              {selectedType === "services" ? "Add Service" : "List a part"}
            </Typography>
          </Button>

          {selectedType !== "services" && (
            <Button
              sx={{
                me: 2,
                border: `1px solid ${theme.palette.primary.main}`,
                textTransform: "capitalize",
                padding: "15px 25px 15px 25px",
                width: { xs: "100%", sm: "auto", md: "inherit" },
                mt: { xs: "20px", md: "0" },
              }}
              onClick={handleNavigationCsv}
            >
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                  display: "flex",
                  fontWeight: 700,
                  alignItems: "center",
                  gap: "10px",
                  textTransform: "initial",
                }}
              >
                <FiPlus style={{ fontSize: "20px" }} /> Upload CSV
              </Typography>
            </Button>
          )}
        </Box>
      </Box>

      <Grid container spacing={3} sx={{ marginTop: "30px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          //   md={12}
          lg={3.4}
          sx={{
            paddingTop: "0 !important",
            marginBottom: { xs: "20px", lg: "0" },
            paddingLeft: {
              xs: "0 !important",
              sm: "0 !important",
              lg: "24px !important",
            },
            paddingRight: { xs: "0", sm: "0", lg: "28px !important" },
          }}
        >
          <ItemManagementTabs
            onTabChange={handleTabChange}
            activeItemsCount={activeItemsCount}
            soldItemsCount={soldItemsCount}
            draftItemsCount={draftItemsCount}
            activeServicesCount={activeServicesCount}
            draftServicesCount={draftServicesCount}
            selectedType={selectedType}
            onTypeChange={handleTypeChange}
            selectedCategoryId={selectedCategoryId}
            selectedServiceId={selectedServiceId}
            sortOrderControle={sortOrderControle}
            searchParams={searchParams}
            listState={listState}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={8.6}
          sx={{
            background: theme.palette.primary.light,
            padding: 2.2,
            borderRadius: "15px",
          }}
        >
          <ItemsControl
            sortOrderControle={sortOrderControle}
            handleSortOrderChange={handleSortOrderChange}
            handleCategoryChange={handleCategoryChange}
            handleServiceChange={handleServiceChange}
            categories={itemsCategories}
            services={servicesCategories}
            selectedCategoryId={selectedCategoryId}
            selectedServiceId={selectedServiceId}
            selectedTab={listState}
          />

          {isLoading ? (
            <Grid
              container
              sx={{
                justifyContent: "center",

                display: "flex",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <Loader />
            </Grid>
          ) : categoryIdProducts?.length > 0 ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {categoryIdProducts?.map((product) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xxl={3}
                  key={product?.id}
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <ItemsProducts
                    item={product}
                    index={product?.id}
                    isLoggedIn={isLoggedIn}
                    selectedTab={selectedType}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    goToPage={goToProductPage}
                    categoryName={
                      selectedType === "items"
                        ? getCategoryName(product?.categoryId)
                        : ""
                    }
                    serviseName={
                      selectedType === "services"
                        ? getServiceName(product?.categoryId)
                        : ""
                    }
                    isTitle={isTitle}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            categoryIdProducts?.length === 0 && (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "100vh",
                  flexDirection: "column",
                }}
              >
                <img
                  src={noitems}
                  alt="No data available"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: theme.typography.headerFont,
                    color: theme.palette.dark.charcoalBlack,
                  }}
                >
                  No{" "}
                  {listState === "activeItems"
                    ? "Active"
                    : listState === "sold"
                    ? "Sold"
                    : listState === "draft"
                    ? "Draft"
                    : listState === "activeService"
                    ? "Active Services"
                    : "Draft Services"}{" "}
                  Selling
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ mb: 3 }}
                >
                  {listState === "activeItems"
                    ? "You don't have active items."
                    : listState === "sold"
                    ? "You don't have sold items."
                    : listState === "draft"
                    ? "You don't have draft items."
                    : listState === "activeService"
                    ? "You don't have active services."
                    : "You don't have draft services."}
                </Typography>
                <Box>
                  <Button
                    sx={{
                      me: 2,
                      background: theme.palette.primary.main,
                      textTransform: "capitalize",
                      padding: "15px 90px 15px 90px",
                      "&:hover": {
                        background: theme.palette.primary.main,
                      },
                    }}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      if (selectedType === "services") {
                        handleServiceNavigation();
                      } else {
                        handleItemNavigation();
                      }
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.primary.light,
                        display: "flex",
                        fontWeight: 700,
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {selectedType === "services" ? "Add Service" : "Add Part"}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            )
          )}

          <Stack
            spacing={2}
            sx={{
              mt: 4,
              paddingBottom: "35px",
            }}
          >
            {totalPages > 0 && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& ul": {
                    justifyContent: "center",
                    marginTop: "25px",
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      margin: "auto",
                      justifyContent: "center",
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                        fontWeight: "bold",
                      },
                      "&:hover": {
                        backgroundColor: `${theme.palette.primary.main} !important`,
                        color: theme.palette.primary.light,
                      },
                      borderRadius: "50%",
                      marginRight: "5px",
                      minWidth: "40px",
                    }}
                  />
                )}
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      <Dialog
        open={isDeleteModalVisible}
        onClose={handleCancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DeleteBox
          itemToDelete={selectedType === "services" ? "service" : "product"}
          onCancel={handleCancelDelete}
          onDelete={handleConfirmDelete}
        />
      </Dialog>
    </Box>
  );
};

export default ListItems;
