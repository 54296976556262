import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const ItemSection = ({
  item,
  itemKey,
  index,
  goToPage,
  height,
  left,
  paddingCategories,
  widthCategorie,
}) => {
  const theme = useTheme();
  const xSscreen = useMediaQuery("(max-width:480px)");

  return (
    <Box
      key={itemKey}
      sx={{
        position: "relative",
        top: "-55px",
      }}
    >
      <Box
        style={{
          borderRadius: "50%",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "83px",
          height: "84px",
          cursor: "pointer",
          transition: "transform 0.5s ease-in-out",
          position: "relative",
          zIndex: 5,

          marginLeft: "20px",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = "scale(1.1)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "scale(1)";
        }}
      >
        <img
          loading="lazy"
          src={item?.image}
          alt="category"
          style={{
            borderRadius: "0%",
            width: widthCategorie ? "64px" : "83px",
            height: height ? "80px" : "84px",
            position: "absolute",
            top: "65px",
            left: left ? left : "20px",
          }}
          onClick={() => goToPage(item?.id, item?.name)}
        />
      </Box>
      <Box
        sx={{
          clipPath:
            index % 2 === 0
              ? "polygon(0 18px, 100% 50%, 100% 100%, 0% 100%)"
              : "polygon(0 50%, 100% 11%, 100% 100%, 0% 100%)",

          backgroundColor: theme.palette.primary.light,
          width: widthCategorie ? { xs: "140px", lg: "180px" } : "149px",
          height: "138px",
          borderRadius: "20px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontFamily: theme.typography.headerFont,
              minHeight: "25px",
              lineHeight: "19.9px",
              color: theme.palette.text.primary,
              textAlign: "center",
              wordWrap: "break-word",
              padding: xSscreen
                ? "0 10px"
                : paddingCategories
                ? item?.name.includes("&")
                  ? { sm: "0 34px", lg: "0 20px" }
                  : "0 20px"
                : "0",
              marginBottom: "5px",
              position: "absolute",
              bottom: "3px",
              width: "153px"
            }}
          >
            {/* {item?.name} */}
            {item?.name.length > 15
              ? item?.name.slice(0, 20) + "..."
              : item?.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemSection;
