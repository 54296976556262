// Purpose: To store constants that are used throughout the application.
let API_URL = "";
let Algolia_App_Id = "";
let Algolia_Search_Api_Key = "";
let GOOGLE_API_KEY = "";
let STRIPE_CHECKOUT_ENDPOINT = "";
let BASE_URL = "";
let STRIPE_COLLECT_PAYMENT_ENDPOINT = "";
let STRIPE_PAYOUT_ENDPOINT = "";
let STRIPE_CANCEL_ENDPOINT = "";
if (process.env.REACT_APP_ENV === "production") {
  API_URL = "https://us-central1-truckparts-prod.cloudfunctions.net/userApi";
  Algolia_App_Id = "JG4K4308NO";
  Algolia_Search_Api_Key = "a2389193466ae09baa6691e5bd0803e0";
  GOOGLE_API_KEY = "AIzaSyA45Ug7mkKJVuuGzIItp7V11Fb7SV8vzJc";
  BASE_URL = "https://us-central1-truckparts-prod.cloudfunctions.net/";
  STRIPE_CHECKOUT_ENDPOINT = "stripeApi/createPayLinkForCheckout";
  STRIPE_COLLECT_PAYMENT_ENDPOINT = "stripeApi/createAccountLink";
  STRIPE_PAYOUT_ENDPOINT = "stripeApi/createStripePayout";
  STRIPE_CANCEL_ENDPOINT = "stripeApi/cancelOrder";
} else {
  API_URL = "https://us-central1-truckparts-dev.cloudfunctions.net/userApi";
  Algolia_App_Id = "L1URYAE8IT";
  Algolia_Search_Api_Key = "42752db9c826be61a6d9a75ba471fc6e";
  GOOGLE_API_KEY = "AIzaSyA45Ug7mkKJVuuGzIItp7V11Fb7SV8vzJc";
  BASE_URL = "https://us-central1-truckparts-dev.cloudfunctions.net/";
  STRIPE_CHECKOUT_ENDPOINT = "stripeApi/createPayLinkForCheckout";
  STRIPE_COLLECT_PAYMENT_ENDPOINT = "stripeApi/createAccountLink";
  STRIPE_PAYOUT_ENDPOINT = "stripeApi/createStripePayout";
  STRIPE_CANCEL_ENDPOINT = "stripeApi/cancelOrder";
}

export {
  API_URL,
  Algolia_App_Id,
  Algolia_Search_Api_Key,
  GOOGLE_API_KEY,
  STRIPE_CHECKOUT_ENDPOINT,
  BASE_URL,
  STRIPE_COLLECT_PAYMENT_ENDPOINT,
  STRIPE_PAYOUT_ENDPOINT,
  STRIPE_CANCEL_ENDPOINT,
};
