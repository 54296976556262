import { Button, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";

function DeleteBox({ itemToDelete = "", onCancel = () => {}, onDelete = () => {}, isDeleteLoading = false }) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.primary.light,
        padding: { xs: "16px", sm: "24px", md: "32px" },
        borderRadius: "8px",
        width: { xs: "90%", sm: "400px" },
      }}
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
     
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          backgroundColor: theme.palette.status.lightRed,
          color: theme.palette.status.cancel,
        }}
      >
        <RiErrorWarningLine size={30} />
      </Stack>

    
      <Typography
        variant="h5"
        sx={{
          textTransform: "capitalize",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Delete {itemToDelete}
      </Typography>

      
      <Typography
        variant="body2"
        sx={{
          textTransform: "capitalize",
          textAlign: "center",
          color: theme.palette.neutral.textSecondary,
        }}
      >
        are you sure you want to delete this {itemToDelete}? This action cannot
        be undone.
      </Typography>

     
      <Stack
        direction="row"
        gap={2}
        sx={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        
        <Button
          variant="outlined"
          onClick={onCancel}
          sx={{
            flex: 1,
            padding: { xs: "8px", sm: "10px" },
            borderRadius: "4px",
            border: `1px solid ${theme.palette.gray.btnContact}`,
            textTransform: "capitalize",
            fontWeight: "500",
            color: theme.palette.gray.lightBorderGray,
            backgroundColor: theme.palette.gray.btnContact,
            "&:hover": {
              color: theme.palette.gray.lightBorderGray,
              backgroundColor: theme.palette.gray.btnContact,
              border: `1px solid ${theme.palette.gray.btnContact}`,
              boxShadow: "none",
            },
          }}
        >
          cancel
        </Button>

        
        <Button
          variant="contained"
          onClick={onDelete}
          disabled={isDeleteLoading}
          sx={{
            flex: 1,
            padding: { xs: "8px", sm: "10px" },
            borderRadius: "4px",
            fontWeight: "500",
            textTransform: "capitalize",
            boxShadow: "none",
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.status.cancel,
            "&:hover": {
              backgroundColor: theme.palette.status.cancel,
              color: theme.palette.primary.light,
              boxShadow: "none",
            },
          }}
        >
          {isDeleteLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "delete"
          )}
        </Button>
      </Stack>
    </Stack>
  );
}

export default DeleteBox;
