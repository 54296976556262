import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userData: [],
  userAddressData: [],
  products: [],
  requests: [],
  servicesCategories: [],
  itemsCategories: [],
  currentProduct: {},
  formAddItemData: [],
  formAddServiceData: {},
  csvProducts: {},
  isFormTouched: false,
  serviceActiveStep: 1,
  serviceTotalPages: 2,
  goBackAddService: false,
  goBackEditService: false,
  requestTab: true,
  cartProducts: [],
  isFollowing: false,
  isFullScreen: true,
  selectedProductStatus: { id: null, status: null },
  newItemAdded: false,
  addServiceVideo: null,
  editServiceVideo: null,
  addItemVideo: null,
  checkout: {
    data: null, 
    loading: false, 
    error: null, 
  },
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    restUserData: (state, action) => {
      state.userData = initialState.userData;
    },
    setUserAddressData: (state, action) => {
      state.userAddressData = action.payload;
    },
    setCsvProducts: (state, action) => {
      state.csvProducts = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    setServicesCategories: (state, action) => {
      state.servicesCategories = action.payload;
    },
    setItemsCategories: (state, action) => {
      state.itemsCategories = action.payload;
    },
    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
    },
    setFormTouched: (state) => {
      state.isFormTouched = true;
    },
    resetFormTouched: (state) => {
      state.isFormTouched = false;
    },
    setFormAddItemData: (state, action) => {
      state.formAddItemData = action.payload;
    },
    clearFormAddItemData: (state) => {
      state.formAddItemData = [];
    
    },
    setFormAddServiceData: (state, action) => {
      state.formAddServiceData = {
        ...state.formAddServiceData, // Keep existing fields
        ...action.payload, // Merge new data
      };
    },
    clearFormAddServiceData: (state) => {
      state.formAddServiceData = {};
    },
    setServiceActiveStep: (state, action) => {
      state.serviceActiveStep = action.payload;
    },
    resetServiceActiveStep: (state, action) => {
      state.serviceActiveStep = 1;
    },
    setGoBackAddService: (state, action) => {
      state.goBackAddService = action.payload;
    },
    setGoBackEditService: (state, action) => {
      state.goBackEditService = action.payload;
    },
    setRequestTab: (state, action) => {
      state.requestTab = action.payload;
    },
    setFollowingStatus: (state, action) => {
      state.isFollowing = action.payload;
    },
    setIsFullscreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
    setCartProducts: (state, action) => {
      state.cartProducts = action.payload;
    },
    setSelectedProductStatus: (state, action) => {
      state.selectedProductStatus = action.payload;
    },
    setNewItemAdded: (state, action) => {
      state.newItemAdded = action.payload;
    },
    setAddServiceVideo: (state, action) => {
      state.addServiceVideo = action.payload;
    },
    clearAddServiceVideo: (state) => {
      state.addServiceVideo = null;
    },
    setEditServiceVideo: (state, action) => {
      state.editServiceVideo = action.payload;
    },
    clearEditServiceVideo: (state) => {
      state.editServiceVideo = null;
    },
    setAddItemVideo: (state, action) => {
      state.addItemVideo = action.payload;
    },
    clearAddItemVideo: (state) => {
      state.addItemVideo = null;
    },
    setCheckoutData: (state, action) => {
      state.checkout.data = action.payload;
    },
    setCheckoutLoading: (state, action) => {
      state.checkout.loading = action.payload;
    },
    setCheckoutError: (state, action) => {
      state.checkout.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserData,
  restUserData,
  setProducts,
  setServicesCategories,
  setItemsCategories,
  setCurrentProduct,
  setFormTouched,
  resetFormTouched,
  setRequests,
  setUserAddressData,
  setFormAddItemData,
  clearFormAddItemData,
  setFormAddServiceData,
  clearFormAddServiceData,
  setServiceActiveStep,
  setNewItemAdded,
  resetServiceActiveStep,
  setGoBackAddService,
  setGoBackEditService,
  setRequestTab,
  setFollowingStatus,
  setIsFullscreen,
  setCartProducts,
  setSelectedProductStatus,
  setAddServiceVideo,
  clearAddServiceVideo,
  setEditServiceVideo,
  clearEditServiceVideo,
  setAddItemVideo,
  clearAddItemVideo,
  setCheckoutData,
  setCheckoutLoading,
  setCheckoutError,
  setCsvProducts
} = appStateSlice.actions;

export default appStateSlice.reducer;
