import React, { useState } from "react";
import { Box, Avatar, Typography, Stack, useTheme } from "@mui/material";
import { FaStar } from "react-icons/fa";
import { formatDistanceToNow } from "date-fns";
import ReviewModal from "../../../components/common/ReviewModal";
import { useNavigate } from "react-router-dom";

const ReviewCard = ({
  review,
  backgroundColor = "white",
  borderRadius = 0,
  padding = 0,
  boxShadow = "none",
}) => {
  const theme = useTheme();
const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  
  const formatTimeAgo = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const truncatedComment =
    review?.comment?.length > 35
      ? review.comment.slice(0, 35) + "..."
      : review.comment;

  return (
    <>
      <Stack
        gap={1}
        sx={{
          padding: padding,
          borderRadius: borderRadius,
          backgroundColor: backgroundColor,
          boxShadow: boxShadow,
          transition: "height 0.3s ease-in-out",
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar
            alt={review?.userInfo?.name}
            src={review?.userInfo?.profilePicture || ""}
            sx={{
              width: "52px",
              height: "52px",
              bgcolor: theme.palette.status.lightPink,
              fontSize: "20px",
              textTransform: "capitalize",
              color: theme.palette.dark.darkBlack,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/sellers/seller-details/${review?.userInfo?.uid}`);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            {!review?.userInfo?.profilePicture && review?.userInfo?.name[0]}
          </Avatar>
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  color: theme.palette.dark.darkBlack,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/sellers/seller-details/${review?.userInfo?.uid}`);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                {review?.userInfo?.name || "Anonymous"}
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: theme.palette.neutral.MoustacheGrey }}
              >
                {formatTimeAgo(review?.createdAt)}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                  key={star}
                  style={{
                    color:
                      star <= review?.rating
                        ? theme.palette.status.star
                        : theme.palette.neutral.paleGray,
                    fontSize: "13px",
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            transition: "height 0.3s ease-in-out",
          }}
        >
         <Typography
  variant="body2"
  sx={{
    color: theme.palette.neutral.MoustacheGrey,
    lineHeight: "28px",
    textAlign: "start",
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }}
>
  {truncatedComment}
  {review?.comment?.length > 35 && (
    <Box
      component="span"
      onClick={handleOpenModal}
      sx={{
        cursor: "pointer",
        color: theme.palette.primary.main,
        textDecoration: "underline",
        marginLeft: "8px",
        fontSize: "12px",
        display: "inline-block",
      }}
    >
      See more
    </Box>
  )}
</Typography>

        </Box>
      </Stack>
      {/* Review Modal for Display */}
      <ReviewModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        review={review}
        mode="display"
      />
    </>
  );
};

export default ReviewCard;
