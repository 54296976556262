// src/common/components/Header/HeaderInfoBar.js
import React from "react";
import { Box, Typography, Divider, useTheme } from "@mui/material";
import { TbTruckDelivery } from "react-icons/tb";
import { BiSolidOffer } from "react-icons/bi";

const HeaderInfoBar = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      bgcolor={theme.palette.primary.main}
      color={theme.palette.primary.light}
      p={2}
      lineHeight="1.5"
      sx={{
        display: {
          xs: "none",
          sm: "flex",
          
        },
      }}
    >
      {/* <Typography variant="subtitle2">Delivery in 10 minutes</Typography> */}
      <Box display="flex" gap={2} alignItems="center">
        <Box display="flex" alignItems="center" >
          <TbTruckDelivery />
          <Typography variant="subtitle2" sx={{ marginLeft: "4px" }}>
            Track your order
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ bgcolor: theme.palette.primary.light }}
        />
        <Box display="flex" alignItems="center">
          <BiSolidOffer />
          <Typography variant="subtitle2" sx={{ marginLeft: "4px" }}>
            All Offers
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderInfoBar;
