import React, { useState, useEffect } from "react";
import { Typography, useTheme, Box, useMediaQuery } from "@mui/material";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { db } from "../../../configs/firebase";
import Loader from "../../../components/common/Loader";
import ItemSection from "../../../components/common/ItemSection";
import image1 from "../../../assets/Images/service_image1.png";
import image2 from "../../../assets/Images/service_image2.png";
import image3 from "../../../assets/Images/service_image3.png";
import StoreButtons from "../../../components/common/StoreButton";

const Services = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [displayedServices, setDisplayedServices] = useState([]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);

  const navigateToServices = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/services");
  };

  const goToServisePage = (id, name) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    navigate(`/filtered-services?service-Id=${id}&service-name=${name}`);
  };

  useEffect(() => {
    if (displayedServices.length < 0) {
      setIsLoading(false);
    }
  }, [displayedServices]);

  useEffect(() => {
    getServiceCategory();
  }, []);

  const getServiceCategory = async () => {
    try {
      const docs = db
        .collection("servicesCategories")
        .orderBy("index", "asc")
        .limit(8);
      let serviceCategory = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          serviceCategory.push(doc.data());
        });
        setDisplayedServices(serviceCategory);
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  };

  const carouselData = [
    {
      image: image1,
      text: "Keep Your Trucks Running, Hassle-Free!",
    },
    {
      image: image2,
      text: "Got trucks? Get the app!",
    },
    {
      image: image3,
      text: "If you have trucks, you need this app!",
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section style={{ padding: "40px 25px 0px 25px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px 20px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.typography.headerFont,

              color: theme.palette.text.primary,
            }}
          >
            Services
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              color: theme.palette.primary.main,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={navigateToServices}
          >
            See all
            <FaArrowRightLong
              style={{
                width: "13.33px",
                height: "13.33px",
                color: theme.palette.primary.main,
                marginLeft: "8px",
              }}
            />
          </Typography>
        </Box>
        {displayedServices.length ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              overflowX: "scroll",
              paddingBottom: "10px",
              gap: "15px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              [theme.breakpoints.up("sm")]: {
                overflowX: "auto",
              },
            }}
          >
            {displayedServices?.map((service, index) => {
              return (
                <ItemSection
                  key={service?.id}
                  item={service}
                  index={index}
                  goToPage={goToServisePage}
                  widthCategorie
                  height="80px"
                />
              );
            })}
          </Box>
        ) : (
          <Typography variant="body1" style={{ textAlign: "center" }}>
            No services available.
          </Typography>
        )}
      </section>
      <section style={{ padding: "10px 0 0 0" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            overflowX: "scroll",
            margin: isSmallScreen ? "10px" : "0px 30px",
            paddingBottom: "10px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            [theme.breakpoints.up("sm")]: {
              overflowX: "auto",
            },
          }}
        >
          {carouselData?.map((item, index) => (
            <Box
              key={index}
              sx={{
                flex: "0 0 auto",
                padding: "10px",
                boxSizing: "border-box",
                textAlign: "center",
                width: "429px",
                height: "200px",
                position: "relative",
              }}
            >
              <img
                src={item?.image}
                alt={`carousel-${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
              />

              <Box
                sx={{
                  position: "absolute",
                  top: "30px",
                  left: "0",
                  right: "0",
                }}
              >
                <Typography
                  sx={{
                    padding: "10px",
                    fontSize: "1.5rem",
                    color: theme.palette.primary.light,
                    fontFamily: theme.typography.headerFont,
                    fontWeight: 400,
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  {item?.text}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <StoreButtons />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </section>
    </>
  );
};

export default Services;
