import React from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { GOOGLE_API_KEY } from "../layout/constants.js";
import { mapStyles } from "../../utils/methods.js";
import { IoIosCloseCircleOutline } from "react-icons/io";

const mapOptions = {
  zoomControl: true,
  scaleControl: true,
  streetViewControl: true,
  rotateControl: true,
  fullscreenControl: true,
  styles: mapStyles,
};

const LocationMapModal = ({ open, onClose, lat, lng, address }) => {
  const theme = useTheme();
  const { addressLineOne, addressLineTwo, city, state, country } =
    address || {};
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading Map...</div>;
  if (loadError) return <div>Error loading Google Maps API</div>;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Location</Typography>
          <IoIosCloseCircleOutline
            onClick={onClose}
            style={{
              cursor: "pointer",
              fontSize: "24px",
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {address ? (
          <>
            <Typography
              variant="subtitle1"
              sx={{ textTransform: "capitalize", fontWeight: 600, mb: 1 }}
            >
              address:{" "}
              <span style={{ fontWeight: 400, fontSize: "14px" }}>
                {addressLineOne} , {city}, {state} , {country}
              </span>
            </Typography>
            {addressLineTwo && (
              <Typography
                variant="subtitle1"
                sx={{ mb: 2, textTransform: "capitalize", fontWeight: 600 }}
              >
                apt/suite:{" "}
                <span style={{ fontWeight: 400, fontSize: "14px" }}>
                  {addressLineTwo}
                </span>
              </Typography>
            )}
          </>
        ) : (
          <Typography>no address yet</Typography>
        )}
        {/* Map Container */}
        <Box sx={{ height: "400px", width: "100%" }}>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={{ lat, lng }}
            zoom={15}
            options={mapOptions}
          >
            <MarkerF position={{ lat, lng }} />
          </GoogleMap>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LocationMapModal;
