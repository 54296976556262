import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  ListItemText,
  Paper,
  Radio,
  TextField,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../configs/firebase";
import { Timestamp } from "firebase/firestore";
import Loader from "../../components/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { message, Modal } from "antd";
import {
  resetFormTouched,
  setFormTouched,
  setItemsCategories,
  setRequests,
  setRequestTab,
} from "../../redux/slices/appStateSlice";
import StreetAddress from "../../components/common/StreetAddress";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import ShippingAddressSelector from "../cart/components/ShippingAddressSelector";
const RequestSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Request Name is required")
    .min(3, "Request name must be at least 3 characters")
    .max(25, "Request name must be at max 25 characters"),
  details: Yup.string()
    .trim()
    .required("Request Details are required")
    .min(20, "Request Details must be at least 20 characters"),
  categoryId: Yup.string().required("Please select category"),
  address: Yup.object().required("Address is required"),
});

const AddRequests = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let { requestId, addressId } = location.state || {};

  const displayedCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const [categories, setCategories] = useState(
    displayedCategories.length > 0 ? displayedCategories : []
  );
  const displayedRequests = useSelector((state) => state.appState.requests);

  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [chooseAddressEnabled, setChooseAddressEnabled] = useState(false);
  // const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [addressSelectorMode, setAddressSelectorMode] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addresses, setAddresses] = useState([]);

  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressToShow, setStreetAddressToShow] = useState("");
  const [addressError, setAddressError] = useState("");
  const userData = useSelector((state) => state.appState.userData);
  const [isLoading, setisLoading] = useState(false);

  const { uid, profilePicture } = userData;
  const displayedRequest = displayedRequests.find(
    (request) => request.id === requestId
  );
  const isFormTouched = useSelector((state) => state.appState.isFormTouched);
  const confirmedLeave = useRef(false);
  const popupShown = useRef(false);

  const [initialValues, setInitialValues] = useState({
    name: displayedRequest?.name || "",
    tags: displayedRequest?.tags || "",
    categoryId: displayedRequest?.categoryId || "",
    details: displayedRequest?.details || "",
    isUrgent: displayedRequest?.isUrgent || false,
    address: streetAddress || displayedRequest?.address || {},
  });

  useEffect(() => {
    if (selectedAddress) {
      setStreetAddressToShow(
        `${selectedAddress?.addressLineOne ?? ""}, ${
          selectedAddress?.city ?? ""
        }, ${selectedAddress?.state ?? ""}, ${selectedAddress?.country ?? ""}`
      );
      setIsAddressSelected(true);
      setStreetAddress(selectedAddress);
      localStorage.setItem("RequestAddressId", selectedAddress?.id);
    }
  }, [selectedAddress]);

  const [selectedAddressId, setSelectedAddressId] = useState(
    userData?.address ? userData?.address?.id : null
  );

  const [locationAddressId, setLocationAddressId] = useState("");
  const [matchAddressData, setMatchAddressData] = useState("");

  const fetchAddresses = async () => {
    try {
      const addressesRef = collection(db, "users", userData?.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);
      const fetchedAddresses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedAddresses = fetchedAddresses.sort(
        (a, b) => b.isDefault - a.isDefault
      );

      setAddresses(sortedAddresses);

      const defaultAddress = sortedAddresses?.find(
        (address) => address?.isDefault
      );

      if (!addressId) {
        setSelectedAddressId(defaultAddress?.id);
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    if (userData?.uid) {
      fetchAddresses();
    }
  }, [userData]);

  useEffect(() => {
    if (displayedCategories.length === 0) {
      getCategories();
    } else {
      setCategories(displayedCategories);
    }
  }, [displayedCategories]);

  const getCategories = async () => {
    try {
      const docs = db.collection("itemCategories").orderBy("index", "asc");

      let fetchedCategories = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedCategories.push(doc.data());
        });

        if (fetchedCategories.length > 0) {
          setCategories(fetchedCategories);
          dispatch(setItemsCategories(fetchedCategories));
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    const newInitialValues = { ...initialValues };

    if (!displayedRequest) {
      // If no displayedRequest, use user data's address
      const { createdAt, updatedAt, ...filteredAddress } =
        userData?.address || {};
      if (filteredAddress && Object.keys(filteredAddress).length > 0) {
        newInitialValues.address = filteredAddress;

        setStreetAddress(filteredAddress);
        setStreetAddressToShow(
          `${filteredAddress.addressLineOne ?? ""}, ${
            filteredAddress.city ?? ""
          }, ${filteredAddress.state ?? ""} , ${filteredAddress.country ?? ""}`
        );
      }
    } else {
      const requestAddress = displayedRequest?.address || {};
      newInitialValues.address = requestAddress;

      setStreetAddress(requestAddress);
      setStreetAddressToShow(
        `${requestAddress.addressLineOne ?? ""}, ${
          requestAddress.city ?? ""
        }, ${requestAddress.state ?? ""} , ${requestAddress.country ?? ""}`
      );
    }

    setInitialValues(newInitialValues);
  }, [displayedRequest, userData]);

  const handleSelectStreet = async (address, placeId, setFieldValue) => {
    setAddressError("");
    const results = await geocodeByAddress(address);

    if (!address) {
      setAddressError("Address is required");
      setStreetAddress("");
      setStreetAddressToShow("");
      return;
    }

    try {
      let results;
      if (placeId) {
        results = await geocodeByPlaceId(placeId);
      } else {
        results = await geocodeByAddress(address);
      }

      if (!results || results?.length === 0) {
        setAddressError("No results found for the provided address.");
        throw new Error("ZERO_RESULTS");
      }

      const latLng = await getLatLng(results[0]);

      const selectedPlaceData = {
        city:
          results[0]?.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          results[0]?.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        country:
          results[0]?.address_components.find((component) =>
            component.types.includes("country")
          )?.short_name || "",
        zipCode:
          results[0]?.address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "",
        lat: latLng.lat,
        lng: latLng.lng,
        route:
          results[0]?.address_components.find((component) =>
            component.types.includes("route")
          )?.long_name || "",
        street_number:
          results[0]?.address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name || "",
        primary_line: results[0]?.formatted_address || "",
        placeId: placeId || "",
        id: placeId || Date.now(),
        addressLineOne:
          results[0]?.address_components.find((component) =>
            component.types.includes("route")
          )?.long_name || "",
        addressLineTwo: "",
        isDefault: false,
        name: "Home",
      };

      setFieldValue("city", selectedPlaceData?.city);
      setFieldValue("state", selectedPlaceData?.state);
      setFieldValue("zipCode", selectedPlaceData?.zipCode);
      setFieldValue("address", selectedPlaceData);

      setStreetAddress(selectedPlaceData);
      setStreetAddressToShow(
        `${selectedPlaceData.addressLineOne ?? ""}, ${
          selectedPlaceData.city ?? ""
        }, ${selectedPlaceData.state ?? ""} , ${
          selectedPlaceData.country ?? ""
        }`
      );
    } catch (error) {
      console.error("Error selecting address", error);
      if (error.message === "ZERO_RESULTS") {
        message.error("No matching address found. Please try again.");
      } else {
        message.error("An error occurred while fetching the address.");
      }
    }
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const addressesRef = collection(
          db,
          "users",
          userData?.uid,
          "addresses"
        );
        const querySnapshot = await getDocs(addressesRef);
        const fetchedAddresses = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Save the fetched addresses in state
        setAddresses(fetchedAddresses);

        // Get the addressLineOne from the request
        const addressLineOneFromRequest =
          displayedRequest?.address?.addressLineOne;

        // Check if the user's address matches the request's addressLineOne
        const matchedAddress = fetchedAddresses?.find(
          (address) => address?.addressLineOne === addressLineOneFromRequest
        );

        if (matchedAddress) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          setLocationAddressId(matchedAddress?.id);
          setMatchAddressData(matchedAddress);
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    if (userData?.uid && displayedRequest) {
      fetchAddresses();
    }
  }, [userData?.uid, displayedRequest, addressId]);

  const showConfirmModal = useCallback((onOk, onCancel) => {
    Modal.confirm({
      title: "Unsaved Changes",
      content:
        "You have unsaved changes. Are you sure you want to leave this page?",
      okText: "Yes, leave page",
      cancelText: "Stay on page",
      onOk,
      onCancel,
      centered: true,
    });
  }, []);

  const handleInputChange = (event) => {
    dispatch(setFormTouched());
  };

  useEffect(() => {
    confirmedLeave.current = false;
  }, [isFormTouched]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (isFormTouched && !confirmedLeave.current && !popupShown.current) {
        event.preventDefault();
        popupShown.current = true;
        showConfirmModal(
          () => {
            confirmedLeave.current = true;
            dispatch(resetFormTouched());
            navigate(-1);
          },
          () => {
            // Do nothing
            confirmedLeave.current = false;
            popupShown.current = false; // Reset the flag if the user cancels
          }
        );
      } else if (!isFormTouched || confirmedLeave.current) {
        navigate(-1);
      }
    };

    window.history.pushState(null, "", location.pathname + location.search);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isFormTouched, dispatch, navigate]);

  // in case of click reload with unsaved changes
  useEffect(() => {
    const handleOnBeforeUnload = (event) => {
      if (isFormTouched && !confirmedLeave.current) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    const handleOnUnload = () => {
      if (isFormTouched && !confirmedLeave.current) {
        dispatch(resetFormTouched());
      }
    };
    window.addEventListener("beforeunload", handleOnBeforeUnload, {
      capture: true,
    });
    window.addEventListener("unload", handleOnUnload, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", handleOnBeforeUnload, {
        capture: true,
      });
      window.removeEventListener("unload", handleOnUnload, { capture: true });
    };
  }, [isFormTouched]);

  useEffect(() => {
    dispatch(resetFormTouched());
  }, []);

  const handleTagInputChange = (e, setFieldValue) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "");

    inputValue = inputValue.replace(/\s+/g, " ");

    setFieldValue("tags", inputValue);
    handleInputChange();
  };

  const updateUserDefaultAddress = async (defaultAddress) => {
    const { customName, updatedAt, createdAt, ...address } = defaultAddress;
    try {
      await updateDoc(doc(db, "users", userData?.uid), {
        address: { ...address },
      });
      console.log("Default address updated in user profile:", address);
    } catch (error) {
      console.error("Error updating default address:", error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    setAddressError("");
    setisLoading(true);
    const currentDate = new Date();
    const firestoreTimestamp = Timestamp.fromDate(currentDate);

    try {
      const { id, ...streetAddressWithoutId } = streetAddress;

      const requestData = {
        _geoloc: {
          lat: streetAddress?.lat || 0,
          lng: streetAddress?.lng || 0,
        },
        name: values?.name,
        address: {
          ...streetAddressWithoutId,

          addressLineOne:
            values?.addressLineOne || streetAddress?.addressLineOne,
        },
        tags: values?.tags,
        categoryId: values?.categoryId,
        details: values?.details,
        isUrgent: values?.isUrgent,
        profilePicture: profilePicture ? profilePicture : null,
        userName:
          userData?.businessInfo?.name ||
          `${userData?.firstName} ${userData?.lastName}`,
        uid,
        updatedAt: firestoreTimestamp,
        createdAt: firestoreTimestamp,
        isDeleted: false,
      };

      if (displayedRequest) {
        // Editing existing request
        dispatch(setRequestTab(false));
        requestData.id = displayedRequest.id;
        requestData.address.id = locationAddressId;
        requestData.updatedAt = firestoreTimestamp;
        await setDoc(doc(db, "requests", displayedRequest?.id), requestData);

        const updatedRequestsData = [
          requestData,
          ...displayedRequests.filter(
            (request) => request?.id !== displayedRequest?.id
          ),
        ];
        dispatch(setRequests(updatedRequestsData));

        message.success({
          content: "Your request has been successfully updated.",
          duration: 5,
        });
      } else {
        // Adding new request
        dispatch(setRequestTab(false));
        const docRef = doc(collection(db, "requests"));
        requestData.id = docRef.id;
        requestData.address.id = docRef.id;
        requestData.createdAt = firestoreTimestamp;
        await setDoc(docRef, requestData);
        dispatch(setRequests([requestData, ...displayedRequests]));
        message.success({
          content:
            "Your request has been successfully submitted. Thank you for reaching out!",
          duration: 5,
        });
      }

      if (
        !selectedAddress &&
        streetAddress?.id !== locationAddressId &&
        streetAddress?.addressLineOne !== matchAddressData?.addressLineOne &&
        streetAddress?.id !== userData?.address?.id
      ) {
        // Handle Addresses Collection
        const addressesRef = collection(db, "users", userData.uid, "addresses");
        const querySnapshot = await getDocs(addressesRef);

        const batch = writeBatch(db);

        const { id, ...streetAddressWithoutId } = streetAddress;
        const isFirstAddress = querySnapshot.empty;

        const newAddressRef = doc(addressesRef);
        const newAddressData = {
          id: newAddressRef.id,
          ...streetAddressWithoutId,
          addressLineOne:
            values?.addressLineOne || streetAddress?.addressLineOne,
          isDefault: isFirstAddress, // First address is default
          name: "Business",
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        };

        batch.set(newAddressRef, newAddressData);
        let newSelectedAddressId = newAddressRef?.id;

        if (isFirstAddress) {
          await updateUserDefaultAddress(newAddressData);
        }

        await batch.commit();

        resetForm();
        localStorage.setItem("RequestAddressId", newSelectedAddressId);

        navigate("/requests", { state: { scrollToRequests: true } });
      }

      resetForm();
      navigate("/requests", { state: { scrollToRequests: true } });
    } catch (e) {
      console.error("Error adding/updating document: ", e);
      message.error({
        content: "An error occurred. Please try again.",
        duration: 5,
      });
    } finally {
      setTimeout(() => {
        setisLoading(false);
      }, 50000);
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        margin: "40px 25px",
        background: theme.palette.primary.light,
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <h2
          style={{
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "27px",
            textAlign: "left",
            color: theme.palette.primary.Charcoal,
          }}
        >
          {displayedRequest ? "Edit urgent Request" : "Add urgent Request"}
        </h2>

        <p
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "21px",
            letterSpacing: "-0.02em",
            textAlign: "left",
            color: theme.palette.primary.colorInput,
          }}
        >
          {displayedRequest
            ? "Please edit your urgent Request info"
            : "Please enter your urgent Request info"}
        </p>
      </Box>

      {addresses?.length > 0 && (
        <Box sx={{ marginTop: "20px", mb: "20px" }}>
          <Box sx={{ textAlign: "right" }}>
            <Button
              onClick={() => setChooseAddressEnabled(!chooseAddressEnabled)}
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                color: theme.palette.primary.main,
              }}
            >
              {chooseAddressEnabled ? "Cancel" : "Choose Address"}
            </Button>
          </Box>

          {chooseAddressEnabled && (
            <ShippingAddressSelector
              currentUser={userData}
              onSelectAddress={(address) => setSelectedAddressId(address?.id)}
              isModalOpen={isNewAddressModalOpen}
              setIsModalOpen={setIsNewAddressModalOpen}
              selectValue={addressSelectorMode}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              selectedAddressId={selectedAddressId}
              locationAddressId={locationAddressId}
            />
          )}
        </Box>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={RequestSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.Charcoal,
                  }}
                >
                  Request Name *
                </label>
                <Field
                  name="name"
                  placeholder="Request Name"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, " ");
                    setFieldValue("name", inputValue);
                    handleInputChange();
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.primary.light}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = "none";
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = "none";
                  }}
                />
                {errors.name && touched.name ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.name}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} md={6}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.charcoal,
                  }}
                >
                  Address *
                </label>
                <StreetAddress
                  handleSelect={(address, placeId) =>
                    handleSelectStreet(address, placeId, setFieldValue)
                  }
                  setAddressToShow={setStreetAddressToShow}
                  setAddress={setStreetAddress}
                  address={streetAddressToShow}
                  placeholder="Please Enter Address"
                  // handleInputChange={handleInputChange}
                  borderColor="none"
                  backgroundColor={theme.palette.primary.background}
                  padding="13px"
                  marginTop="10px"
                />
                {(errors.address && touched.address) ||
                (addressError && addressError?.length > 0) ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors?.address && touched?.address
                      ? errors?.address
                      : addressError}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} md={6}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.Charcoal,
                  }}
                >
                  Tags
                </label>
                <Field
                  name="tags"
                  placeholder="Add Tags"
                  onChange={(e) => handleTagInputChange(e, setFieldValue)}
                  style={{
                    width: "100%",
                    padding: "13px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.primary.light}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = "none";
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = "none";
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.charcoal,
                  }}
                >
                  Category *
                </label>
                <Autocomplete
                  id="categoryId"
                  value={categories?.find(
                    (category) => category?.id === displayedRequest?.categoryId
                  )}
                  style={{ marginTop: "10px" }}
                  // options={categories}
                  options={[...categories]?.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={(option) => option.name}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{
                        background: theme.palette.primary.light,
                        maxHeight: "200px",
                        overflowY: "hidden",
                        borderRadius: "10px",
                      }}
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          scrollbarWidth: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        },
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => {
                    setFieldValue("categoryId", value?.id || "");
                    handleInputChange();
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      key={option.id}
                      {...props}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: theme.palette.primary.background,
                        padding: "8px 16px",
                        borderRadius: "8px",
                        margin: "5px 10px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={option.image}
                          alt={option.name}
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "8px",
                          }}
                        />
                        <ListItemText
                          primary={option.name}
                          primaryTypographyProps={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            color: theme.palette.primary.black,
                          }}
                        />
                      </div>
                      <Radio
                        checked={selected}
                        value={option.id}
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: "47px",
                          background: theme.palette.primary.background,
                          color: `${theme.palette.primary.darkColor} !important`,
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "21px",
                          borderRadius: "10px",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                      }}
                    />
                  )}
                />

                {errors.categoryId && touched.categoryId ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.categoryId}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.Charcoal,
                  }}
                >
                  Request Details *
                </label>
                <Field
                  as="textarea"
                  name="details"
                  placeholder="Enter your Request Details"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/ +/g, " ");
                    const formattedValue = inputValue
                      .replace(/(?:^|\.\s+)(\w)/g, (match) =>
                        match.toUpperCase()
                      )
                      .replace(/^\w/, (match) => match.toUpperCase());
                    setFieldValue("details", formattedValue);
                    handleInputChange();
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    height: "130px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.primary.light}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = "none";
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = "none";
                  }}
                />
                {errors.details && touched.details ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.details}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <button
                  type="submit"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                    textAlign: "center",
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.main,
                    padding: "13px 40px",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "10px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "170px",
                    height: "50px",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader color={theme.palette.primary.light} />
                  ) : displayedRequest ? (
                    "Update Request"
                  ) : (
                    "Add Request"
                  )}
                </button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddRequests;
