import React from "react";

import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/styles";
import CircleProgress from "../../../components/common/CircularProgress";

const ViewProducts = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const initialSetup = sessionStorage.getItem("initialSetupCSV");
  const csvProducts = useSelector((state) => state.appState.csvProducts);

  // Styled components for the table
  const StyledTableCell = styled("th")(() => ({
    color: theme.palette.primary.textBlueBlack,
    padding: "20px 0px",
    lineHeight: "22px",
    fontWeight: 400,
    textAlign: "center",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    backgroundColor: theme.palette.primary.light,
  }));

  const goToConfirmation = () => {
    sessionStorage.setItem("initialSetupCSV", "true");
    navigate("/selling/confirmation");
  };

  return (
    <Box>
      <Box
        sx={{
          padding: "20px",
          margin: "0px 25px",
          background: theme.palette.primary.light,
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h2"
            sx={{
              color: theme.palette.dark.colorCart,
              fontSize: "24px",
              fontFamily: theme.typography.headerFont,
            }}
          >
            Import CSV file
          </Typography>
          <CircleProgress step={initialSetup === "true" ? 2 : 1} />
        </Stack>

        <TableContainer
          component={Paper}
          sx={{
            padding: "30px",
            margin: "40px 0",
            boxShadow: "none",
            [theme.breakpoints.down("xs")]: {
              padding: "12px",
            },
          }}
        >
          <Typography
            component="h1"
            style={{
              marginBottom: "1rem",
              color: theme.palette.primary.darkMain,
              fontWeight: 700,
            }}
          >
            Total Items: {csvProducts?.length}
          </Typography>
          <Typography
            component="h1"
            style={{
              marginBottom: "2rem",
              color: theme.palette.primary.darkMain,
              fontWeight: 700,
            }}
          >
            Please review the imported items below and ensure all data is
            correct.
          </Typography>
          {
            <>
              <Table
                sx={{
                  minWidth: { md: 1300, lg: 700 },
                  borderRadius: "70px",
                  boxShadow: `0px 5px 19px ${theme.palette.primary.shadowTable}`,
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: theme.palette.primary.lightAliceBlue,
                    }}
                  >
                    <StyledTableCell align="center">Ext ID</StyledTableCell>
                    <StyledTableCell align="center">Title</StyledTableCell>
                    <StyledTableCell align="center">Category</StyledTableCell>
                    <StyledTableCell align="center">Condition</StyledTableCell>
                    <StyledTableCell align="center">Photos</StyledTableCell>
                    <StyledTableCell align="center">Price</StyledTableCell>
                    <StyledTableCell align="center">Quantity</StyledTableCell>
                    <StyledTableCell align="center">
                      Description
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Online Payment
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Local Pickup
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Company Name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Company Price
                    </StyledTableCell>
                    {/** Conditionally render columns if warranty and dimensions are present */}
                    {csvProducts?.some(
                      (row) => row?.warranty || row?.dimensions
                    ) && (
                      <>
                        <StyledTableCell align="center">
                          Warranty
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Dimensions
                        </StyledTableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {csvProducts?.map((row) => (
                    <StyledTableRow key={row?.id}>
                      <StyledTableCell align="center">
                        #{row?.ext_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.title}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.category}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.condition}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <img
                          sx={{
                            width: "50px !important",
                            height: "50px !important",
                            objectFit: "cover",
                          }}
                          src={row?.photos}
                          alt="product"
                          loading="lazy"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <NumericFormat
                          displayType="text"
                          value={row?.price}
                          decimalScale={2}
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          prefix="$"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.quantity}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* {row?.description} */}
                        {row?.description.length > 50
                          ? `${row?.description?.slice(0, 20)}...`
                          : row?.description}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row?.online_payment}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row?.local_pickup}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.shipping_company_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <NumericFormat
                          displayType="text"
                          value={row?.shipping_company_price}
                          decimalScale={2}
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          prefix="$"
                        />
                      </StyledTableCell>
                      {/** Conditionally render Warranty and Dimensions columns if they are available */}
                      {row?.warranty || row?.dimensions ? (
                        <>
                          <StyledTableCell align="center">
                            {row?.warranty || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.dimensions || "N/A"}
                          </StyledTableCell>
                        </>
                      ) : null}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          }
        </TableContainer>

        <Box>
          <Button
            sx={{ mt: 4, textTransform: "capitalize" }}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            onClick={goToConfirmation}
          >
            Next:Confirmation
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewProducts;
