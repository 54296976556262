import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  ListItemText,
  Paper,
  Radio,
  TextField,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db, storageSdk } from "../../../configs/firebase";

import {
  clearFormAddItemData,
  setFormAddItemData,
  setItemsCategories,
} from "../../../redux/slices/appStateSlice";
import Loader from "../../../components/common/Loader";
import { MdDelete, MdOutlineAddAPhoto } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { message, Modal } from "antd";
import CustomPrompt from "../../../components/common/CustomPrompt";
import CircleProgress from "../../../components/common/CircularProgress";
import validationSchemas from "./validationSchemas";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import "cropperjs/dist/cropper.css";
import ImageCropUploader from "../../../components/common/ImageCropUploader";
import saveDraftProduct from "../../../utils/saveDraftProduct";

const AddItem = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = useParams();
  const initialSetup = sessionStorage.getItem("initialSetup");

  const displayedCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const [categories, setCategories] = useState(
    displayedCategories.length > 0 ? displayedCategories : []
  );
  const formAddItemData = useSelector(
    (state) => state.appState.formAddItemData
  );

  const [selectedImages, setSelectedImages] = useState([]);
  const [productData, setProductData] = useState(null);
  const [isDirty, setIsDirty] = useState(true);
  const [draftProduct, setDraftProduct] = useState(null);

  const [videoPreview, setVideoPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [removedImages, setRemovedImages] = useState([]);
  const userData = useSelector((state) => state.appState.userData);
  const hasModalShown = useRef(false); // Use a ref to track modal display state

  const [isVideoRemoved, setIsVideoRemoved] = useState(false);
  const currentSchema = validationSchemas[`step${"One"}`];
  const videoInputRef = useRef(null);

  const inputStyles = {
    common: {
      width: "100%",
      padding: "13px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      borderRadius: "10px",
      marginTop: "8px",
    },
    label: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "21px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: theme.palette.primary.Charcoal,
    },
    subTitle: {
      fontSize: ".7rem",
      color: theme.palette.dark.dustyBlue,
      margin: "5px 20px 20px 20px",
      fontWeight: 500,
      lineHeight: "1rem",
      fontStyle: "italic",
      fontFamily: "Poppins",
    },
    btn: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22.4px",
      textAlign: "center",
      padding: "13px 40px",
      borderRadius: "5px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "170px",
      height: "50px",
    },
    focusBlur: {
      outline: "none",
      border: `1px solid ${theme.palette.gray.borderFaild}`,
    },
    inputColor: {
      color: theme.palette.primary.darkColor,
      background: theme.palette.primary.light,
    },
  };

  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const cropperRef = useRef(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [initialFormData, setInitialFormData] = useState({
    title: formAddItemData?.title ?? "",
    categoryId: formAddItemData.categoryId ?? "",
    description: formAddItemData?.description ?? "",
    video: formAddItemData?.video ?? null,
    condition: formAddItemData?.condition ?? "",
    images: formAddItemData?.images ?? [],
  });

  /**Fuction Update ****/
  const fetchProductData = useCallback(async () => {
    try {
      const productRef = db.collection("products").doc(productId);
      const doc = await productRef.get();
      if (doc.exists) {
        if (doc?.data()?.uid !== userData?.uid) {
          setIsDirty(false);
          message.error("You are not authorized to edit this product.");
          navigate("/");
          setIsLoading(false);
          return;
        }

        const data = doc.data();
        setProductData(data);
      } else {
        message.error("Product not found");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      message.error("Failed to fetch product data.");
    }
  }, [navigate, productId, userData?.uid]);

  useEffect(() => {
    setIsDirty(false);

    // Parse query parameters
    const params = new URLSearchParams(location.search);
    const retainRedux = params.get("retainRedux");

    if (productId) {
      // Only fetch data if retainRedux is NOT true
      if (!retainRedux) {
        fetchProductData();
      }

      // Clear form data if retainRedux is either missing or explicitly false
      if (!retainRedux || retainRedux !== "true") {
        dispatch(clearFormAddItemData());
      }
    }
  }, [productId, fetchProductData, dispatch, location.search]);

  const handleNext = async (validateForm, values, setErrors) => {
    setIsDirty(false);
    const errors = await validateForm(values);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentStep((prevStep) =>
          Math.min(prevStep + 1, initialSetup === "true" ? 4 : 3)
        );
        handleSubmit(values);
        if (localStorage.getItem("hasModalShown") !== "true") {
          saveDraftProduct({
            userData,
            formAddItemData,
            values: values,
          });
        }
        setIsLoading(false);
      }, 1000);
    } else {
      setErrors(errors);
    }
  };

  const navigateToNextPage = () => {
    setIsDirty(false);

    if (productId) {
      navigate(`/selling/additional-details/${productId}`);
    } else {
      navigate("/selling/additional-details");
    }
  };

  const getCategories = useCallback(async () => {
    try {
      const docs = db.collection("itemCategories").orderBy("index", "asc");

      let fetchedCategories = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedCategories.push(doc.data());
        });
        if (fetchedCategories.length > 0) {
          setCategories(fetchedCategories);
          dispatch(setItemsCategories(fetchedCategories));
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (displayedCategories.length === 0) {
      getCategories();
    } else {
      setCategories(displayedCategories);
    }
  }, [displayedCategories, getCategories]);

  useEffect(() => {
    const imagesFromForm = (formAddItemData?.images || [])
      .map((fileOrUrl) => {
        if (fileOrUrl instanceof File) {
          return { file: fileOrUrl, url: URL?.createObjectURL(fileOrUrl) };
        } else if (
          typeof fileOrUrl === "string" &&
          fileOrUrl.startsWith("https")
        ) {
          return { file: null, url: fileOrUrl };
        } else {
          console.error("Invalid file detected:", fileOrUrl);
          return null;
        }
      })
      .filter(Boolean);

    const imagesFromProduct = (productData?.images || []).map((url) => ({
      file: null,
      url,
    }));

    const imagesDraftProduct = (draftProduct?.images || []).map((url) => ({
      file: null,
      url,
    }));

    const mergedImages = [
      ...imagesFromProduct,
      ...imagesFromForm,
      ...imagesDraftProduct,
    ].reduce((acc, current) => {
      // Exclude images that are in the removedImages list
      if (
        !acc.find((item) => item.url === current.url) &&
        !removedImages.includes(current.url)
      ) {
        acc.push(current);
      }
      return acc;
    }, []);

    setSelectedImages(mergedImages);
    if (!isVideoRemoved && productData?.video && !videoPreview) {
      setVideoPreview(productData?.video);
    } else if (formAddItemData?.video && !videoPreview) {
      setVideoPreview(formAddItemData?.video);
    } else if (draftProduct?.video && !videoPreview) {
      setVideoPreview(draftProduct?.video);
    }
  }, [
    formAddItemData,
    productData,
    removedImages,
    videoPreview,
    isVideoRemoved,
    draftProduct,
  ]);

  const handleOnDragEnd = (result, setFieldValue) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(selectedImages);
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);

    setSelectedImages(reorderedImages);
    setFieldValue(
      "images",
      reorderedImages.map((img) => img.file || img.url)
    );
  };

  const handleVideoUpload = async (e, setFieldValue) => {
    const video = e?.target?.files[0];

    if (video) {
      const fileSizeMB = video.size / (1024 * 1024);

      // Validate file type
      if (!["video/mp4", "video/mov"].includes(video.type)) {
        message.error("Only MP4 or MOV formats are allowed.");
        return;
      }

      // Validate file size (100MB max)
      if (fileSizeMB > 100) {
        message.error("File size exceeds the 100MB limit.");
        return;
      }

      // Check video duration before uploading
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.src = URL.createObjectURL(video);

      videoElement.onloadedmetadata = async () => {
        URL.revokeObjectURL(videoElement.src);

        // Validate duration (max 180 seconds = 3 minutes)
        if (videoElement.duration > 180) {
          message.error("Video duration exceeds 3 minutes.");
          return;
        }

        // Start the loading state
        setIsLoadingVideo(true);

        // Generate a unique name for the video file
        const currentDateTime = formatDate(new Date());
        const videoName = `video-${currentDateTime}.mp4`;
        const videoRef = storageSdk.ref(
          `products/${userData?.uid}/${videoName}`
        );

        try {
          // Upload the video to Firebase Storage
          await uploadBytes(videoRef, video);
          const downloadURL = await getDownloadURL(videoRef);

          // Set the video preview and form data
          setVideoPreview(downloadURL);
          setFieldValue("video", downloadURL);

          dispatch(
            setFormAddItemData({
              ...formAddItemData,
              video: downloadURL,
            })
          );

          message.success("Video uploaded successfully.");
        } catch (error) {
          console.error("Error uploading video:", error);
          message.error("Failed to upload video. Please try again.");
        } finally {
          // Stop the loading state
          setIsLoadingVideo(false);
        }
      };
    }
    // Clear input field
    e.target.value = null;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const handleRemoveImage = (index, setFieldValue) => {
    const updatedImages = [...selectedImages];
    const removedImage = updatedImages.splice(index, 1)[0];

    // Release the object URL for cleanup
    if (removedImage?.file) {
      URL.revokeObjectURL(removedImage.url);
    }

    if (!removedImages.includes(removedImage.url)) {
      setRemovedImages([...removedImages, removedImage.url]);
    }

    setSelectedImages(updatedImages);
    setFieldValue(
      "images",
      updatedImages.map((img) => img.file || img.url)
    );

    dispatch(
      setFormAddItemData({
        ...formAddItemData,
        images: updatedImages.map((img) => img.file || img.url),
      })
    );
  };

  const handleRemoveVideo = (setFieldValue) => {
    if (videoPreview) {
      URL.revokeObjectURL(videoPreview);
    }
    setFieldValue("video", null);
    setVideoPreview(null);
    setIsVideoRemoved(true);

    if (videoInputRef.current) {
      videoInputRef.current.value = "";
    }

    if (formAddItemData.video ?? productData?.video) {
      dispatch(
        setFormAddItemData({
          ...formAddItemData,
          video: null,
        })
      );
    }
  };

  useEffect(() => {
    if (isVideoRemoved) {
      setVideoPreview(null);
      setIsVideoRemoved(true);
    }
  }, [isVideoRemoved]);

  const handleSubmit = async (values) => {
    setTimeout(() => {
      dispatch(setFormAddItemData(values));
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigateToNextPage();
    }, 0);
  };

  useEffect(() => {
    // Handle video preview
    if (formAddItemData?.video) {
      setVideoPreview(formAddItemData?.video);
    } else if (productData?.video) {
      setVideoPreview(productData?.video);
    }

    // Handle images preview
  }, [formAddItemData?.video, productData?.video]);

  const showCustomConfirmModal = () => {
    if (
      !draftProduct ||
      hasModalShown.current ||
      localStorage.getItem("hasModalShown") === "true"
    )
      return;

    hasModalShown.current = true;

    Modal.confirm({
      title: "Continue Where You Left Off?",
      content:
        "You already started listing an item. Do you want to continue with the same data?",
      okText: "Yes",
      cancelText: "No",

      onOk: async () => {
        // Update the initialFormData when the user clicks OK
        setInitialFormData({
          title: draftProduct?.title || "",
          categoryId: draftProduct?.categoryId || "",
          description: draftProduct?.description,
          video: draftProduct?.video ?? null,
          condition: draftProduct?.condition ?? "",
          images: draftProduct?.images ?? [],
        });

        localStorage.setItem("hasModalShown", "true");
      },

      onCancel: async () => {
        // Clear the draft from Firebase
        await saveDraftProduct({
          userData,
          formAddItemData,
          values: false, // Clear draft
        });

        localStorage.setItem("hasModalShown", "true");
      },
    });
  };

  // Fetch the draft product from Firebase
  useEffect(() => {
    const fetchDraftProduct = async () => {
      if (userData?.uid) {
        const userDoc = await db.collection("users").doc(userData?.uid).get();
        if (userDoc?.exists) {
          setDraftProduct(userDoc.data()?.draftProduct || null);
        }
   
      }
    };

    fetchDraftProduct();
  }, [userData]);


  useEffect(() => {
    if (
      draftProduct &&
      !hasModalShown.current &&
      localStorage.getItem("hasModalShown") !== "true" &&
      localStorage.getItem("onOkProduct") !== "true"
    ) {
      showCustomConfirmModal();
    } else if (!draftProduct && productData) {
      // If no draft product, initialize form with productData
      setInitialFormData({
        title: productData?.title ?? "",
        categoryId: productData?.categoryId ?? "",
        description: productData?.description ?? "",
        video: productData?.video ?? null,
        condition: productData?.condition ?? "",
        images: productData?.images ?? [],
      });
    }
  }, [draftProduct, productData]);

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files).filter((file) =>
      file.type.startsWith("image/")
    );

    if (files.length + selectedImages.length > 10) {
      message.error({
        content: "You can upload a maximum of 10 images.",
        duration: 5,
      });
      return;
    }

    if (files.length > 0) {
      setCurrentFile(files[0]);
      setIsCropDialogOpen(true);
    }

    e.target.value = null;
  };

  const handleCropSave = async (setFieldValue) => {
    const cropper = cropperRef.current?.cropper;

    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();

      if (!croppedCanvas) {
        message.error("Failed to crop the image. Please try again.");
        return;
      }

      croppedCanvas.toBlob(async (blob) => {
        if (!blob) {
          message.error("Failed to generate cropped image. Please try again.");
          return;
        }

        setIsCropDialogOpen(false);

        const currentDateTime = formatDate(new Date());
        const imageName = `image-${currentDateTime}.png`;
        const imageRef = storageSdk.ref(
          `products/${userData.uid}/${imageName}`
        );

        try {
          setIsLoadingImage(true);

          await uploadBytes(imageRef, blob);
          const downloadURL = await getDownloadURL(imageRef);

          const uploadedImage = { file: null, url: downloadURL };
          const updatedImages = [...selectedImages, uploadedImage];

          setSelectedImages(updatedImages);
          setFieldValue(
            "images",
            updatedImages?.map((img) => img?.url)
          );

          dispatch(
            setFormAddItemData({
              ...formAddItemData,
              images: updatedImages?.map((img) => img?.url),
            })
          );

          message.success("Image uploaded successfully.");
        } catch (error) {
          console.error("Error uploading image:", error);
          message.error("Failed to upload image. Please try again.");
        } finally {
          setIsLoadingImage(false);
        }
      });
    }
  };

  const handleCropCancel = () => {
    setIsCropDialogOpen(false);
    setCurrentFile(null);
  };

  return (
    <div
      style={{
        padding: "20px",
        margin: "0px 25px",
        background: theme.palette.primary.light,
        borderRadius: "10px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          marginBottom: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2
          style={{
            fontFamily: theme.typography.headerFont,
            fontSize: "1.5rem",
            fontWeight: 500,
            lineHeight: "27px",
            textAlign: "left",
            color: theme.palette.primary.Charcoal,
          }}
        >
          {productId ? "Edit Product" : "Add Product"}
        </h2>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <CircleProgress step={initialSetup === "true" ? 2 : 1} />{" "}
          </Box>{" "}
        </Box>
      </Box>

      <Formik
        // enableReinitialize={!!productData}
        enableReinitialize={true}
        initialValues={initialFormData}
        validationSchema={currentSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          validateForm,
          setErrors,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <label style={inputStyles.label}>
                  Category <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  id="categoryId"
                  value={
                    displayedCategories?.find(
                      (category) => category.id === values.categoryId
                    ) || null
                  }
                  style={{ marginTop: "10px" }}
                  // options={displayedCategories}
                  options={[...displayedCategories]?.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{
                        background: theme.palette.primary.light,
                        maxHeight: "400px",
                        overflowY: "hidden",
                        borderRadius: "10px",
                      }}
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          scrollbarWidth: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        },
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  onChange={(event, value) => {
                    setFieldValue("categoryId", value?.id || "");
                    setFieldValue("condition", "");
                    setIsDirty(true);
                  }}
                  onBlur={(event) => {
                    event.preventDefault();
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      key={option.id}
                      {...props}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: theme.palette.primary.background,
                        padding: "8px 16px",
                        borderRadius: "8px",
                        margin: "5px 10px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={option.image}
                          alt={option.name}
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "8px",
                          }}
                        />
                        <ListItemText
                          primary={option.name}
                          primaryTypographyProps={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            color: theme.palette.primary.black,
                          }}
                        />
                      </div>
                      <Radio
                        checked={selected}
                        value={option.id}
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: "47px",
                          border: inputStyles.focusBlur.border,
                          ...inputStyles.inputColor,
                          borderRadius: "10px",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                      }}
                    />
                  )}
                />

                {errors.categoryId && touched.categoryId ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.categoryId}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} md={4}>
                <label style={inputStyles.label}>
                  Title <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  name="title"
                  placeholder="Enter part name"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, " ");
                    setIsDirty(true);
                    const formattedValue = inputValue
                      .replace(/(?:^|\.\s+)(\w)/g, (match) =>
                        match.toUpperCase()
                      )
                      .replace(/^\w/, (match) => match.toUpperCase());

                    setFieldValue("title", formattedValue);
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
                {errors.title && touched.title ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.title}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} md={4}>
                <label
                  style={{
                    ...inputStyles.label,
                    marginBottom: "10px",
                    display: "block",
                  }}
                >
                  Condition <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  id="condition"
                  options={
                    categories?.find(
                      (category) => category?.id === values?.categoryId
                    )?.conditions || []
                  }
                  noOptionsText={
                    values.categoryId
                      ? "No conditions available"
                      : "Choose category first"
                  }
                  isOptionEqualToValue={(option, value) => option === value}
                  value={values.condition || null}
                  onChange={(event, value) => {
                    setFieldValue("condition", value || "");
                    setIsDirty(true);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: theme.palette.primary.background,
                        padding: "8px 16px",
                        borderRadius: "8px",
                        margin: "5px 10px",
                      }}
                    >
                      <div>{option}</div>
                      <Radio
                        checked={values.condition === option}
                        value={option}
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Condition"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: "47px",
                          border: `1px solid ${theme.palette.gray.borderFaild}`,
                          background: theme.palette.primary.light,
                          color: `${theme.palette.primary.darkColor} !important`,
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "21px",
                          borderRadius: "10px",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                      }}
                    />
                  )}
                />
                {errors.condition && touched.condition ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.condition}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <label style={inputStyles.label}>
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  as="textarea"
                  name="description"
                  placeholder="Please provide a description of your item"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/ +/g, " ");

                    const formattedValue = inputValue
                      .replace(/(?:^|\.\s+)(\w)/g, (match) =>
                        match.toUpperCase()
                      )
                      .replace(/^\w/, (match) => match.toUpperCase());

                    setFieldValue("description", formattedValue);
                    setIsDirty(true);
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    height: "130px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
                {errors.description && touched.description ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.description}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} md={4}>
                <label style={inputStyles.label}>
                  Photos <span style={{ color: "red" }}>*</span>
                </label>
                <div style={inputStyles.subTitle}>
                  <ul>
                    <li>The First Image will be the default one.</li>
                    <li>You can drag between the images to rearrange.</li>
                  </ul>
                </div>

                <Box
                  sx={{
                    border: `1px dashed ${theme.palette.primary.main}`,
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                    padding: "20px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    height: selectedImages.length === 0 ? "195px" : "auto",
                  }}
                  onClick={(e) => {
                    if (!isCropDialogOpen) {
                      // Only trigger input click when the crop dialog is NOT open
                      document.getElementById("photo-upload").click();
                    }
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => {
                      handleImageSelect(e);
                      setIsDirty(true);
                    }}
                    style={{ display: "none" }}
                    id="photo-upload"
                  />
                  <ImageCropUploader
                    isCropDialogOpen={isCropDialogOpen}
                    handleCropCancel={handleCropCancel}
                    currentFile={currentFile}
                    cropperRef={cropperRef}
                    handleCropSave={handleCropSave}
                    setFieldValue={setFieldValue}
                  />
                  {isLoadingImage ? (
                    <Loader color={theme.palette.primary.main} />
                  ) : (
                    <MdOutlineAddAPhoto
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "35px",
                      }}
                    />
                  )}
                </Box>
                {errors.images && touched.images ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.images}
                  </div>
                ) : null}
                {selectedImages.length > 0 && (
                  <Box
                    sx={{
                      borderRadius: "10px",
                      background: theme.palette.primary.light,
                      padding: "10px",
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                      height: "235px",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <DragDropContext
                      onDragEnd={(result) =>
                        handleOnDragEnd(result, setFieldValue)
                      }
                    >
                      <Droppable droppableId="images">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              gap: "10px",
                              flexWrap: "wrap",
                              overflow: "hidden",
                            }}
                          >
                            {selectedImages?.map((imageObj, index) =>
                              imageObj && imageObj.url ? (
                                <Draggable
                                  key={index}
                                  draggableId={`image-${index}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Box
                                      sx={{ height: "200px", mb: 2 }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <img
                                        src={imageObj.url}
                                        alt={`preview-${index}`}
                                        style={{
                                          width: "300px",
                                          height: "200px",
                                          objectFit: "cover",
                                          borderRadius: "5px",
                                          position: "relative",
                                        }}
                                      />
                                      <IconButton
                                        onClick={() =>
                                          handleRemoveImage(
                                            index,
                                            setFieldValue
                                          )
                                        }
                                        sx={{
                                          position: "relative",
                                          top: "-210px",
                                          right: "-150px",
                                          borderRadius: "50%",
                                        }}
                                      >
                                        <MdDelete color="red" />
                                      </IconButton>
                                    </Box>
                                  )}
                                </Draggable>
                              ) : null
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <label style={inputStyles.label}>Video (optional)</label>
                <div style={inputStyles.subTitle}>
                  <ul>
                    <li>you can upload one video.</li>
                    <li>Maximum video upload limit: 3 minutes</li>
                  </ul>
                </div>
                <Box
                  sx={{
                    border: `1px dashed ${theme.palette.primary.main}`,
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                    padding: "20px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: videoPreview ? "auto" : "195px",
                    cursor:
                      !videoPreview && !isLoadingVideo
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={() =>
                    !isLoadingVideo &&
                    !videoPreview &&
                    document.getElementById("video-upload").click()
                  }
                >
                  <input
                    type="file"
                    accept="video/mp4,video/mov"
                    ref={videoInputRef}
                    onChange={(e) => {
                      setIsDirty(true);
                      if (e.target.files[0]) {
                        const file = e.target.files[0];
                        const fileSizeMB = file.size / (1024 * 1024);

                        if (!["video/mp4", "video/mov"].includes(file.type)) {
                          message.error("Only MP4 or MOV formats are allowed.");
                          return;
                        }

                        if (fileSizeMB > 100) {
                          message.error("File size exceeds the 100MB limit.");
                          return;
                        }

                        handleVideoUpload(e, setFieldValue);
                      }
                    }}
                    style={{
                      display: "none",
                    }}
                    id="video-upload"
                    disabled={!!videoPreview}
                  />

                  {isLoadingVideo ? (
                    <Loader color={theme.palette.primary.main} />
                  ) : !videoPreview ? (
                    <MdOutlineAddAPhoto
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "35px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {" "}
                      <video
                        key={videoPreview}
                        id="video-preview"
                        width="100%"
                        height="100%"
                        controls
                        playsInline
                        muted
                        preload="metadata"
                        onLoadedMetadata={(e) => {
                          const video = e.target;
                          video.controls = true;
                          video.muted = false; // Unmute after initial load
                        }}
                        style={{ display: "block", borderRadius: "15px" }}
                      >
                        <source src={videoPreview} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveVideo(setFieldValue);
                        }}
                        sx={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          borderRadius: "50%",
                        }}
                      >
                        <MdDelete color="red" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <button
                  type="submit"
                  onClick={() => handleNext(validateForm, values, setErrors)}
                  style={{
                    ...inputStyles.btn,
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader color={theme.palette.primary.light} />
                  ) : (
                    "Next"
                  )}
                </button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <CustomPrompt
        when={isDirty || (productId && isDirty)}
        toNavigate={"/selling"}
        userData={userData}
        formAddItemData={formAddItemData}
      />
    </div>
  );
};

export default AddItem;
