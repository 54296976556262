import {
  CiSearch,
  CiSquareQuestion,
  CiCreditCard2,
  CiLogout,
} from "react-icons/ci";
import {
  MdOutlineContactPhone,
  MdOutlineShoppingCart,
  MdOutlineHome,
  MdOutlineCategory,
  MdOutlinePassword,
} from "react-icons/md";
import { GrServices, GrTransaction } from "react-icons/gr";
import { PiBankBold } from "react-icons/pi";
import { TbLogs } from "react-icons/tb";
import { IoIosLogIn, IoIosContacts, IoMdHeartEmpty } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import { LuClipboardList } from "react-icons/lu";
import { RiListIndefinite } from "react-icons/ri";

import { FaRegCommentDots } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";
import { BsChatSquareDots, BsTags, BsPatchQuestion } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";

export const menuLinks = [
  {
    id: "home",
    name: "Home",
    link: "/",
    icon: <MdOutlineHome />,
    isLargeScreen: true,
  },
  {
    id: "requests",
    name: "Requests",
    link: "/requests",
    icon: <BsPatchQuestion />,
    isLargeScreen: true,
  },
  {
    id: "categories",
    name: "Categories",
    link: "/categories",
    icon: <MdOutlineCategory />,
    isLargeScreen: true,
  },
  {
    id: "services",
    name: "Services",
    link: "/services",
    icon: <GrServices />,
    isLargeScreen: true,
  },
  {
    id: "sellers",
    name: "Vendors",
    link: "/sellers",
    icon: <HiOutlineUserGroup />,
    isLargeScreen: true,
  },
  {
    id: "selling",
    name: "My listings",
    link: "/selling",
    icon: <BsTags />,
    isLargeScreen: true,
    logIn: true,
  },

  {
    id: "contactUs",
    name: "Help center",
    link: "/contact-us",
    icon: <MdOutlineContactPhone />,
    isLargeScreen: true,
    logIn: true,
  },
  {
    id: "blogs",
    name: "Blogs",
    link: "/blogs",
    icon: <TbLogs />,
    isLargeScreen: true,
  },
  {
    id: "search",
    name: "Search",
    link: "/explore",
    icon: <CiSearch />,
  },
  {
    id: "chat",
    name: "Chat",
    link: "/chats",
    icon: <BsChatSquareDots />,
    logIn: true,
  },
  {
    id: "cart",
    name: "Cart",
    link: "/cart",
    icon: <MdOutlineShoppingCart />,
    logIn: true,
  },
  {
    id: "transactionsHistory",
    name: "Transactions History",
    link: "/profile?page=transactions-history&tab=seller",
    icon: <GrTransaction />,
    logIn: true,
  },

  {
    id: "signUp",
    name: "Sign Up",
    link: "/signUp",
    icon: <IoIosLogIn />,
    logIn: false,
  },
  {
    id: "logIn",
    name: "Login",
    link: "/logIn",
    icon: <IoIosLogIn />,
    logIn: false,
  },
];

export const profileLinks = [
  {
    id: "accountInfo",
    name: "Account Info ",
    link: "/profile?page=info&form=personal",
    icon: <IoSettingsSharp />,
    isLargeScreen: true,
  },
  {
    id: "orders",
    name: "Orders",
    link: "/profile?page=orders",
    icon: <LuClipboardList />,
    isLargeScreen: true,
  },
  {
    id: "listings",
    name: "My listings",
    link: "/selling",
    icon: <RiListIndefinite />,
    isLargeScreen: true,
  },
  // {
  //   id: "cards",
  //   name: "Bank Accounts & Cards",
  //   link: "/profile?page=cards",
  //   icon: <CiCreditCard2 />,
  //   isLargeScreen:true
  // },
  {
    id: "linkBankAccount",
    name: "Link bank account",
    icon: <PiBankBold />,
    isLargeScreen: true,
  },
  {
    id: "change password",
    name: "Change password",
    link: "/profile?page=info&form=changePassword",
    icon: <MdOutlinePassword />,
    isLargeScreen: true,
  },

  {
    id: "contactUs",
    name: "Help Center",
    link: "/contact-us",
    icon: <IoIosContacts />,
    isLargeScreen: true,
  },
  {
    id: "following",
    name: "Following",
    link: "/following",
    icon: <FaUsers />,
    isLargeScreen: true,
  },
  {
    id: "transactionsHistory",
    name: "Transactions History",
    link: "/profile?page=transactions-history&tab=seller",
    icon: <GrTransaction />,
    isLargeScreen: true,
  },
  {
    id: "favorites",
    name: "Favorites",
    link: "/profile?page=favorites",

    icon: <IoMdHeartEmpty />,
    logIn: true,
  },
  {
    id: "reviews",
    name: "Reviews",
    link: "/reviews",
    icon: <FaRegCommentDots />,
    isLargeScreen: true,
  },

  {
    id: "logout",
    name: "Logout",
    icon: <CiLogout />,
    isLargeScreen: true,
  },
];
