import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPrompt from "../../../components/common/CustomPrompt";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
  Modal as MuiModal,
  Stack,
  Link,
} from "@mui/material";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import {
  setFormAddItemData,
  setNewItemAdded,
} from "../../../redux/slices/appStateSlice";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";
import { BsBank } from "react-icons/bs";
import { IoIosArrowForward, IoIosWarning } from "react-icons/io";

import { RiDeleteBin6Line } from "react-icons/ri";
import map from "../../../assets/Images/map.png";
import { message, Modal as AntModal } from "antd";
import CircleProgress from "../../../components/common/CircularProgress";
import { db, storageSdk } from "../../../configs/firebase";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { formatAddress } from "../../../utils/reusableFunctions";
import ShippingAddressSelector from "../../cart/components/ShippingAddressSelector";
import { GoDotFill } from "react-icons/go";
import { FaCheckCircle, FaPen } from "react-icons/fa";
import { createLinkAccount } from "../../profile/components/transactionsHistory/createLinkAccount";
import saveDraftProduct from "../../../utils/saveDraftProduct";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  display: "flex",
  "&:active .MuiSwitch-thumb": {
    width: 15,
  },
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette.primary.light,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 24,
    height: 24,
    borderRadius: 12,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    opacity: 1,
    backgroundColor: "#e4e4e4",
    boxSizing: "border-box",
  },
}));

const Billing = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [productDataStep3, setProductDataStep3] = useState(null);
  const userData = useSelector((state) => state.appState.userData);
  const [editAddress, setEditAddress] = useState(false);
  const isFormTouched = useSelector((state) => state.appState.isFormTouched);
  const { businessInfo } = userData;
  const initialSetup = sessionStorage.getItem("initialSetup");
  const formAddItemData = useSelector(
    (state) => state.appState.formAddItemData
  );

  const isContactForPrice =
    formAddItemData?.contactForPrice ?? productDataStep3?.contactForPrice;
  const [companyData, setCompanyData] = useState({
    name: "",
    price: "",
  });
  const [allowOnlinePayment, setAllowOnlinePayment] = useState(
    formAddItemData?.isOnline ?? productDataStep3?.isOnline ?? false
  );
  const [isLocalPickup, setIsLocalPickup] = useState(
    formAddItemData?.isLocalPickup ?? true
  );
  const [companies, setCompanies] = useState(formAddItemData?.companies ?? []);

  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isCustomCarrierSelected, setIsCustomCarrierSelected] = useState(false);
  const [chooseAddressEnabled, setChooseAddressEnabled] = useState(false);
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [addressSelectorMode, setAddressSelectorMode] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [selectedAddressId, setSelectedAddressId] = useState(
    userData?.businessInfo?.address ? userData?.businessInfo?.address?.id : null
  );

  const inputStyles = {
    btn: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22.4px",
      textAlign: "center",
      padding: "13px 40px",
      borderRadius: "5px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "170px",
      height: "50px",
    },
  };

  const [accountBankLoading, setAccountBankLoading] = useState(false);
  const [isAccountLinked, setIsAccountLinked] = useState(false);

  useEffect(() => {
    // Check if userData is available and the account is linked based on paymentRestricted
    if (
      userData?.uid &&
      (formAddItemData?.isOnline ?? productDataStep3?.isOnline)
    ) {
      const isAccountLinked =
        !userData?.paymentRestricted && userData?.hasLinkedAccount;
      setIsAccountLinked(isAccountLinked);
    }
  }, [
    userData?.uid,
    userData?.paymentRestricted,
    userData?.hasLinkedAccount,
    formAddItemData?.isOnline,
    productDataStep3?.isOnline,
  ]);

  const validateShippingMethod = () => {
    if (!isLocalPickup && companies.length === 0) {
      message.error(
        "Please select either Local Pickup or add a Shipping Company."
      );
      return false;
    }
    return true;
  };

  const formattedAddress = chooseAddressEnabled
    ? selectedAddress?.addressLineOne ||
      productDataStep3?.shippingAddress?.addressLineOne ||
      userData?.businessInfo?.address?.addressLineOne
      ? formatAddress(
          selectedAddress ||
            productDataStep3?.shippingAddress ||
            userData?.businessInfo?.address
        )
      : "No Address Available"
    : formAddItemData?.shippingAddress?.addressLineOne
    ? formatAddress(formAddItemData?.shippingAddress)
    : productDataStep3?.shippingAddress?.addressLineOne
    ? formatAddress(productDataStep3?.shippingAddress)
    : userData?.businessInfo?.address
    ? formatAddress(userData?.businessInfo?.address)
    : "No Address Available";


  /**Fuction Update ****/
  const fetchProductData = useCallback(async () => {
    try {
      const productRef = db.collection("products").doc(productId);
      const doc = await productRef.get();
      if (doc.exists) {
        const data = doc.data();
        setProductDataStep3(data);
      } else {
        message.error("Product not found");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      message.error("Failed to fetch product data.");
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      fetchProductData();
    }
  }, [productId, fetchProductData]);

  useEffect(() => {
    if (productDataStep3) {
      setCompanies(
        formAddItemData?.companies ?? productDataStep3?.companies ?? []
      );

      setAllowOnlinePayment(
        formAddItemData?.isOnline ?? productDataStep3?.isOnline ?? false
      );

      setIsLocalPickup(
        formAddItemData?.isLocalPickup ??
          productDataStep3?.isLocalPickup ??
          false
      );
      if (!formAddItemData?.shippingAddress) {
        dispatch(
          setFormAddItemData({
            ...formAddItemData,
            shippingAddress: userData?.businessInfo?.address,
            _geoloc: {
              lat: userData?.businessInfo?.address?.lat || 0,
              lng: userData?.businessInfo?.address?.lng || 0,
            },
          })
        );
      }

      if (
        productId &&
        productDataStep3?.shippingAddress &&
        !formAddItemData?.shippingAddress
      ) {
        dispatch(
          setFormAddItemData({
            ...formAddItemData,
            shippingAddress: productDataStep3.shippingAddress,
            _geoloc: {
              lat: productDataStep3?.shippingAddress?.lat || 0,
              lng: productDataStep3?.shippingAddress?.lng || 0,
            },
          })
        );
      }
    }
  }, [productDataStep3, formAddItemData, dispatch]);

  useEffect(() => {
    if (productDataStep3) {
      setCompanies(
        formAddItemData?.companies ?? productDataStep3.companies ?? []
      );

      setAllowOnlinePayment(
        formAddItemData?.isOnline ?? productDataStep3?.isOnline ?? false
      );

      setIsLocalPickup(
        formAddItemData?.isLocalPickup ??
          productDataStep3?.isLocalPickup ??
          false
      );
      if (!formAddItemData?.shippingAddress) {
        dispatch(
          setFormAddItemData({
            ...formAddItemData,
            shippingAddress: userData?.businessInfo?.address,
            _geoloc: {
              lat: userData?.businessInfo?.address?.lat || 0,
              lng: userData?.businessInfo?.address?.lng || 0,
            },
          })
        );
      }

      if (
        productId &&
        productDataStep3?.shippingAddress &&
        !formAddItemData?.shippingAddress
      ) {
        dispatch(
          setFormAddItemData({
            ...formAddItemData,
            shippingAddress: productDataStep3.shippingAddress,
            _geoloc: {
              lat: productDataStep3.shippingAddress.lat || 0,
              lng: productDataStep3.shippingAddress.lng || 0,
            },
          })
        );
      }
    }
  }, [productDataStep3, formAddItemData, dispatch, userData, productId]);
  
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const uploadImagesToFirebase = async (images) => {
    const uploadedUrls = [];
    const existingUrls = [];

    for (let image of images) {
      if (typeof image === "string" && image.startsWith("https://")) {
        existingUrls.push(image);
      } else {
        const currentDateTime = formatDate(new Date());
        const imageName = `image-${currentDateTime}.png`;
        const imageRef = storageSdk.ref(
          `products/${userData?.uid}/${imageName}`
        );

        await uploadBytes(imageRef, image)
          .then(async () => {
            const downloadURL = await getDownloadURL(imageRef);
            uploadedUrls.push(downloadURL);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            message.error("Failed to upload image. Please try again.");
          });
      }
    }

    return [...existingUrls, ...uploadedUrls];
  };

  const handleAdd = async () => {
    if (!validateShippingMethod()) {
      return;
    }

    const isAddressValid =
      formAddItemData?.shippingAddress ||
      (userData?.businessInfo?.address &&
        Object.keys(userData?.businessInfo?.address).length > 0);

    if (!isAddressValid) {
      message.error("Please add an address before adding the item.");
      return;
    }

    setIsLoadingAdd(true);
    setIsLoadingDraft(false);
    setIsActive(true);
    setIsDraft(false);
    await handleSubmit(true, false, companies);
    setIsLoadingAdd(false);
  };

  const handleSaveAsDraft = async () => {
    if (!validateShippingMethod()) {
      return;
    }

    if (!formAddItemData?.shippingAddress && !userData?.businessInfo?.address) {
      message.error("Please add an address before adding the item.");
      return;
    }
    setIsLoadingDraft(true);
    setIsLoadingAdd(false);
    setIsActive(false);
    setIsDraft(true);
    await handleSubmit(false, true, companies);
    setIsLoadingDraft(false);
  };

  const handleAddCompany = () => {
    if (companyData.name && companyData.price) {
      const updatedCompanies = [
        ...companies,
        { name: companyData.name, price: companyData.price },
      ];
      setCompanies(updatedCompanies);
      setCompanyData({ name: "", price: "" });

      dispatch(
        setFormAddItemData({
          ...formAddItemData,
          companies: updatedCompanies,
        })
      );
    }
  };

  // Handle deleting a company
  const handleDeleteCompany = (index) => {
    const updatedCompanies = companies.filter((_, i) => i !== index);
    setCompanies(updatedCompanies);

    dispatch(
      setFormAddItemData({
        ...formAddItemData,
        companies: updatedCompanies,
      })
    );
  };

  const fetchBlobAsFile = async (blobUrl, fileName = "video.mp4") => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  };

  const uploadVideoToFirebase = async (blobUrl) => {
    if (!blobUrl) {
      console.error("Video blob URL is missing.");
      message.error("No video file found for upload. Please try again.");
      return null;
    }
    try {
      // Convert the blob URL to a File object
      const videoFile = await fetchBlobAsFile(blobUrl, "video.mp4");

      const currentDateTime = formatDate(new Date());
      const videoName = `video-${currentDateTime}.mp4`;
      const videoRef = storageSdk.ref(`products/${userData?.uid}/${videoName}`);

      // Upload the file to Firebase
      await uploadBytes(videoRef, videoFile);
      const videoURL = await getDownloadURL(videoRef);

      return videoURL;
    } catch (error) {
      console.error("Error uploading video:", error);
      message.error("Failed to upload video. Please try again.");
      return null;
    }
  };

  const handleSubmit = async (isActive, isDraft, companies) => {
    if (!validateShippingMethod()) {
      return;
    }
    try {
      setIsLoading(true);

      await saveDraftProduct({
        userData,
        formAddItemData,
        values: false, // Clear draft
      });

      const updatedFormAddItemData = { ...formAddItemData };
      const imageUrls = await uploadImagesToFirebase(
        updatedFormAddItemData?.images
      );
      updatedFormAddItemData.images = imageUrls || [];

      // Upload video if present and set the video URL
      if (
        updatedFormAddItemData?.video &&
        updatedFormAddItemData?.video?.startsWith("blob:")
      ) {
        const videoURL = await uploadVideoToFirebase(
          updatedFormAddItemData?.video
        );
        updatedFormAddItemData.video = videoURL;
      } else if (!updatedFormAddItemData?.video?.startsWith("https")) {
        updatedFormAddItemData.video = null;
      }

      // Use selectedAddress if chooseAddressEnabled is true
      if (chooseAddressEnabled && selectedAddress) {
        const { updatedAt, createdAt, ...address } = selectedAddress;
        updatedFormAddItemData.shippingAddress =
          address ||
          productDataStep3?.shippingAddress ||
          userData?.businessInfo?.address;
        updatedFormAddItemData._geoloc = {
          lat:
            selectedAddress?.lat ||
            productDataStep3?.shippingAddress?.lat ||
            userData?.businessInfo?.address?.lat ||
            0,
          lng:
            selectedAddress?.lng ||
            productDataStep3?.shippingAddress?.lng ||
            userData?.businessInfo?.address?.lng ||
            0,
        };
      } else if (
        !updatedFormAddItemData.shippingAddress &&
        userData?.businessInfo?.address
      ) {
        updatedFormAddItemData.shippingAddress =
          userData?.businessInfo?.address;
        updatedFormAddItemData._geoloc = {
          lat: userData?.businessInfo?.address?.lat || 0,
          lng: userData?.businessInfo?.address?.lng || 0,
        };
      }

      updatedFormAddItemData.companies = companies.length > 0 ? companies : [];
      const finalData = createFinalData(
        updatedFormAddItemData,
        isActive,
        isDraft
      );

      // Call the appropriate function based on whether productId exists
      productId
        ? await handleEditProduct(finalData)
        : await handleAddProduct(finalData);
      message.success("Product saved successfully!");
      dispatch(setFormAddItemData({}));
      dispatch(setNewItemAdded(true));
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/selling?type=items");
    } catch (error) {
      console.error("Error while submitting the form:", error);
      message.error("Failed to save the product. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to create the final data object
  const createFinalData = (data, isActive, isDraft) => ({
    ...data,
    isOnline: allowOnlinePayment,
    isLocalPickup,
    isActive,
    isDraft,
    isSold: false,
    isDeleted: false,
    uid: userData?.uid,
    profilePicture: userData?.profilePicture || null,
    registeredSince: userData?.registeredSince,
    name: businessInfo?.name || "",
    ...(!productId && { createdAt: serverTimestamp() }),
    updatedAt: serverTimestamp(),
  });

  // Function to handle adding a new product
  const handleAddProduct = async (finalData) => {
    if (
      userData?.userType === "serviceProvider" &&
      userData?.isDeleted === false
    ) {
      const userDocRef = db.collection("users").doc(userData?.uid);
      await userDocRef.update({
        userType: "all",
      });
    }
    const docRef = doc(collection(db, "products"));
    finalData.id = docRef?.id;
    await setDoc(docRef, finalData);
  };
  

  // Function to handle editing an existing product
  const handleEditProduct = async (finalData) => {
    if (
      userData?.userType === "serviceProvider" &&
      userData?.isDeleted === false
    ) {
      const userDocRef = db.collection("users").doc(userData.uid);
      await userDocRef.update({
        userType: "all",
      });
    }

    finalData.id = productDataStep3?.id;
    finalData.createdAt = productDataStep3?.createdAt;
    await setDoc(doc(db, "products", productId), finalData);
  };

  const handleOnlinePaymentToggle = () => {
    const newAllowOnlinePayment = !allowOnlinePayment;
    setAllowOnlinePayment(newAllowOnlinePayment);
    dispatch(
      setFormAddItemData({
        ...formAddItemData,
        isOnline: newAllowOnlinePayment,
      })
    );
  };

  const handleLocalPickupToggle = () => {
    setIsLocalPickup(!isLocalPickup);
    dispatch(
      setFormAddItemData({ ...formAddItemData, isLocalPickup: !isLocalPickup })
    );
  };

  const handlePrevious = () => {
    setIsDirty(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (productId) {
      navigate(`/selling/additional-details/${productId}`);
    } else {
      navigate("/selling/additional-details");
    }
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };
  const handleNewAddressModalOpen = () => {
    setIsNewAddressModalOpen(true);
  };

  const handleAddBankAccount = async () => {
    setAccountBankLoading(true);

    // Check if we are editing or creating a new item
    const linkUrl = productId
      ? `/selling/billing-details/${productId}?mode=edit`
      : "/selling/billing-details";

    try {
      await createLinkAccount(userData?.uid, linkUrl);
    } catch (error) {
      message.error("Error in linking your bank account.");
    } finally {
      setAccountBankLoading(false);
    }
  };

  const getAddressLineTwo = () => {
    if (chooseAddressEnabled) {
      return (
        selectedAddress?.addressLineTwo ||
        productDataStep3?.shippingAddress?.addressLineTwo ||
        userData?.businessInfo?.address?.addressLineTwo
      );
    } else {
      return (
        productDataStep3?.shippingAddress?.addressLineTwo ||
        formAddItemData?.shippingAddress?.addressLineTwo ||
        userData?.businessInfo?.address?.addressLineTwo
      );
    }
  };

  const addressLineTwo = getAddressLineTwo();

  return (
    <div
      style={{
        padding: "20px",
        margin: "0px 25px",
        background: theme.palette.primary.light,
        borderRadius: "10px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2
          style={{
            fontFamily: theme.typography.headerFont,
            fontSize: "1.5rem",
            fontWeight: 500,
            lineHeight: "27px",
            textAlign: "left",
            color: theme.palette.primary.Charcoal,
          }}
        >
          Add Products
        </h2>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <CircleProgress step={initialSetup === "true" ? 4 : 3} />{" "}
          </Box>{" "}
        </Box>
      </Box>

      <Grid container spacing={2}>
        {!isContactForPrice && (
          <>
            <Grid item xs={12}>
              <Box
                sx={{
                  paddingY: "20px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: theme.palette.primary.background,
                    padding: "10px 15px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    Allow online payment
                  </Typography>

                  <CustomSwitch
                    checked={allowOnlinePayment}
                    onChange={handleOnlinePaymentToggle}
                    color="primary"
                  />
                </Box>

                {/* Add New Bank Account Section */}

                {allowOnlinePayment && (
                  <>
                    {isAccountLinked ? (
                      <Box
                        sx={{
                          border: "1px dashed #d1d5db",
                          borderRadius: "8px",
                          padding: "20px 20px 10px 20px",
                          width: { sx: "90%", md: "30%" },
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                              marginBottom: "5px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 700,
                                textTransform: "capitalize",
                              }}
                            >
                              View bank account
                            </Typography>

                            <Box>
                              {accountBankLoading ? (
                                <Loader />
                              ) : (
                                <FaPen
                                  onClick={handleAddBankAccount}
                                  disabled={accountBankLoading}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginTop: "15px",
                            }}
                          >
                            <Box
                              sx={{
                                background: theme.palette.primary.background,
                                borderRadius: "10px",
                                display: "flex",
                              }}
                            >
                              <FaCheckCircle color="green" />
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{
                                color: theme.palette.status.success,
                              }}
                            >
                              Bank info has been added.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : userData?.hasLinkedAccount &&
                      userData?.paymentRestricted ? (
                      <Box
                        sx={{
                          border: "1px dashed #d1d5db",
                          borderRadius: "8px",
                          padding: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",

                          width: { sx: "90%", md: "30%" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Box
                              sx={{
                                background: theme.palette.primary.background,
                                paddingX: "15px",
                                paddingY: "10px",
                                borderRadius: "10px",
                              }}
                            >
                              <IoIosWarning
                                size={24}
                                color={theme.palette.status.warning}
                              />
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 700,
                                textTransform: "capitalize",
                              }}
                            >
                              bank account has messing details
                            </Typography>
                            {accountBankLoading ? (
                              <Loader />
                            ) : (
                              <FaPen
                                onClick={handleAddBankAccount}
                                disabled={accountBankLoading}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          border: "1px dashed #d1d5db",
                          borderRadius: "8px",
                          padding: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          width: { sx: "90%", md: "30%" },
                          "&:hover": {
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                        onClick={handleAddBankAccount}
                        disabled={accountBankLoading}
                      >
                        {accountBankLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                              width: "100%",
                            }}
                          >
                            <Loader />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <Box
                                sx={{
                                  background: theme.palette.primary.background,

                                  borderRadius: "10px",
                                }}
                              >
                                <BsBank
                                  style={{ fontSize: "35px", color: "blue" }}
                                />
                              </Box>
                              <Box
                                style={{
                                  fontStyle: theme.typography.headerFont,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 700 }}
                                >
                                  Add New Bank Account
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ opacity: "0.8", marginTop: "5px" }}
                                >
                                  Configure your bank account to start getting
                                  paid for your work.
                                </Typography>
                              </Box>
                              <IoIosArrowForward
                                style={{
                                  color: theme.palette.primary.main,
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Divider sx={{ width: "96%", margin: "auto" }} />
          </>
        )}
        <Grid item xs={12} sx={{ paddingTop: "0" }}>
          <Box
            sx={{
              paddingY: "20px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: theme.palette.primary.background,
                padding: "10px 15px",
                borderRadius: "8px",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Local Pickup
              </Typography>

              <CustomSwitch
                checked={isLocalPickup}
                onChange={handleLocalPickupToggle}
                color="primary"
              />
            </Box>
            <Stack direction="row" gap={1}>
              <GoDotFill size="17px" color={theme.palette.dark.dustyBlue} />
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.dark.dustyBlue,
                  fontStyle: "italic",
                  fontWeight: "500",
                }}
              >
                By selecting the local pickup option Users need to know business
                or house address to pickup parts
              </Typography>
            </Stack>
          </Box>
        </Grid>
        {!isContactForPrice && (
          <>
            <Divider sx={{ width: "96%", margin: "auto" }} />

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>Company</InputLabel>
                <Select
                  name="company"
                  value={
                    isCustomCarrierSelected
                      ? "Custom Carrier"
                      : companyData.name
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "Custom Carrier") {
                      setIsCustomCarrierSelected(true);
                      setCompanyData({ ...companyData, name: "" });
                    } else {
                      setIsCustomCarrierSelected(false);
                      setCompanyData({ ...companyData, name: value });
                    }
                  }}
                  label="Company"
                  sx={{
                    border: "0px solid transparent",
                    background: theme.palette.primary.background,
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0px solid transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "0px solid transparent",
                    },
                  }}
                >
                  <MenuItem value="FedEx">FedEx</MenuItem>
                  <MenuItem value="USPS">USPS</MenuItem>
                  <MenuItem value="UPS">UPS</MenuItem>
                  <MenuItem value="Custom Carrier">Custom Carrier</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {isCustomCarrierSelected && (
              <Grid item xs={12} md={3}>
                <TextField
                  label="Enter Custom Carrier Name"
                  value={companyData.name}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, name: e.target.value })
                  }
                  fullWidth
                  sx={{
                    border: "0px solid transparent",
                    background: theme.palette.primary.background,
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0px solid transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "0px solid transparent",
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item xs={6} md={2}>
              <TextField
                label="Price"
                type="number"
                value={companyData.price}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value.startsWith(".")) {
                    value = "0.";
                  }
                  const decimalIndex = value.indexOf(".");
                  if (decimalIndex !== -1) {
                    value = value.slice(0, decimalIndex + 3);
                  }

                  if (Number(value) < 0) {
                    return;
                  }
                  setCompanyData({
                    ...companyData,
                    price: Number(value),
                  });
                }}
                fullWidth
                sx={{
                  border: "0px solid transparent",
                  background: theme.palette.primary.background,
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0px solid transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "0px solid transparent",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                onClick={handleAddCompany}
                sx={{
                  height: "56px",
                  background: theme.palette.primary.main,
                  textTransform: "capitalize",
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  mt: 2,
                  maxHeight: "200px",
                  overflowY: "auto",
                  maxWidth: "400px",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {companies.map((company, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      background: theme.palette.primary.light,
                      padding: "10px 15px",
                      borderRadius: "8px",
                      boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "40%",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {company.name}{" "}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 600,
                          marginRight: "8px",
                          color: theme.palette.gray.overlayBlack,
                        }}
                      >
                        ${company.price}
                      </Typography>
                    </Box>

                    <Box>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteCompany(index)}
                      >
                        <RiDeleteBin6Line />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </>
        )}

        <Divider sx={{ width: "96%", margin: "auto" }} />
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: "20px" }}
          >
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.dark.charcoalBlack,
                fontWeight: 600,
                marginBottom: "16px",
                textTransform: "capitalize",
              }}
            >
              shipping address
            </Typography>
            <Button
              onClick={() => setChooseAddressEnabled(!chooseAddressEnabled)}
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                color: theme.palette.primary.main,
              }}
            >
              {chooseAddressEnabled ? "Cancel" : "Choose Address"}
            </Button>
          </Stack>
          {chooseAddressEnabled && (
            <>
              <ShippingAddressSelector
                currentUser={userData}
                onSelectAddress={(address) => setSelectedAddressId(address?.id)}
                isModalOpen={isNewAddressModalOpen}
                setIsModalOpen={setIsNewAddressModalOpen}
                selectValue={addressSelectorMode}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                selectedAddressId={selectedAddressId}
              />
              <Button
                variant="outlined"
                onClick={handleNewAddressModalOpen}
                sx={{
                  width: "fit-content",
                  margin: "10px 0",
                  textTransform: "capitalize",
                }}
              >
                add new address
              </Button>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: theme.palette.primary.light,
              boxShadow: "0 0 5px rgba(0,0,0,0.1)",
              padding: "10px 10px 5px 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box>
                <img
                  loading="lazy"
                  src={map}
                  alt="map"
                  style={{ objectFit: "cover" }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: theme.palette.dark.charcoalBlack,
                    fontWeight: 600,
                    marginBottom: 1,
                  }}
                >
                  {chooseAddressEnabled
                    ? selectedAddress?.name ||
                      productDataStep3?.shippingAddress?.name ||
                      userData?.businessInfo?.address?.name
                    : productDataStep3?.shippingAddress
                    ? productDataStep3.shippingAddress.name
                    : formAddItemData?.shippingAddress
                    ? formAddItemData.shippingAddress.name
                    : userData?.businessInfo?.address?.name || ""}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.gray.colorSummary,
                    fontWeight: 500,
                    marginBottom: 1.5,
                    fontSize: ".9rem",
                  }}
                >
                  {formattedAddress}
                </Typography>

                {addressLineTwo && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.palette.gray.colorSummary,
                      fontWeight: "500",
                    }}
                  >
                    Apt/Suite: {addressLineTwo}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Stack
          direction="row"
          sx={{ width: "80%", marginTop: "15px", marginLeft: "10px" }}
          gap={1}
        >
          <GoDotFill size="17px" color={theme.palette.dark.dustyBlue} />
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.dark.dustyBlue,
              fontStyle: "italic",
              fontWeight: "500",
              lineHeight: "18px",
            }}
          >
            By selecting "Add Item", you agree to accept the
            Truckpartsmart/Truckpartsmart.com{" "}
            <Link
              component="button"
              onClick={() => {
                navigate("/terms-conditions");
              }}
              sx={{
                color: theme.palette.primary.main,
                textDecoration: "underline",
                marginLeft: "4px",
                marginRight: "4px",
              }}
            >
              Terms of Services
            </Link>{" "}
            and Marketing Terms. A (5%) fee will be charged to transactions that
            use the Stripe payment system to consummate online sales.
            Transactions that do not utilize the Stripe payment system will not
            be subject to the (5%) fee.
          </Typography>
        </Stack>
        <Grid
          container
          sx={{
            display: "flex",
            gap: "16px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              gap: "16px",
              display: "flex",
              flexWrap: "wrap",
              marginLeft: "15px",
            }}
          >
            <Grid item xs={12} sm={12} md="auto">
              <button
                type="submit"
                onClick={() => handlePrevious()}
                style={{
                  ...inputStyles.btn,
                  width: "100%",
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
              >
                Previous
              </button>
            </Grid>

            <Grid item xs={12} sm={12} md="auto">
              <Button
                onClick={handleSaveAsDraft}
                style={{
                  ...inputStyles.btn,
                  width: "100%",
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
                disabled={isLoadingDraft}
              >
                {isLoadingDraft ? (
                  <Loader color={theme.palette.primary.light} />
                ) : (
                  "Save As Draft"
                )}
              </Button>
            </Grid>
          </Box>
          <Grid item xs={12} sm={12} md="auto">
            <button
              // onClick={handleAddCheck}
              onClick={handleAdd}
              style={{
                ...inputStyles.btn,
                width: "100%",
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
              }}
              disabled={isLoadingAdd}
            >
              {isLoadingAdd ? (
                <Loader color={theme.palette.primary.light} />
              ) : productId ? (
                "Update Item"
              ) : (
                "Add Item"
              )}
            </button>
          </Grid>
        </Grid>
      </Grid>

      <CustomPrompt when={isDirty} toNavigate={"/selling"} />
      {/* // <BankAccountModal
      //   open={open}
      //   setOpen={setOpen}
      //   onContinue={handleContinueFromModal}
      // /> */}
    </div>
  );
};

export default Billing;
