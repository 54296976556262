import React, { useState, useEffect } from "react";
import { Typography, useTheme, Box } from "@mui/material";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { db } from "../../../configs/firebase";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";
import ItemSection from "../../../components/common/ItemSection";

const Category = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const displayedProducts = useSelector((state) => state.appState.products);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (displayedProducts.length > 0) {
      setIsLoading(false);
    }
  }, [displayedProducts]);

  useEffect(() => {
    getCategories();
  }, []);

  const goToCategoryPage = (id, name) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // navigate(`/categories/${id}`, { state: { name } });
    navigate(`/filtered-categories?category-Id=${id}&category-name=${name}`);
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const docs = db.collection("itemCategories").orderBy("index", "asc");
      // .limit(8);
      let categories = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          categories.push(doc.data());
        });
        setDisplayedCategories(categories);
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  };

  const navigateToCategories = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/categories");
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section style={{ margin: "0px 25px 0px 25px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0 20px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.typography.headerFont,
              color: theme.palette.text.primary,
            }}
          >
            Category
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 500,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={navigateToCategories}
          >
            See all
            <FaArrowRightLong
              style={{
                width: "13.33px",
                height: "13.33px",
                color: theme.palette.primary.main,
                marginLeft: "8px",
              }}
            />
          </Typography>
        </Box>
        {displayedCategories?.length ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              overflowX: "scroll",
              gap: "12px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              [theme.breakpoints.up("sm")]: {
                overflowX: "auto",
              },
            }}
          >
            {displayedCategories?.map((item, index) => {
              return (
                <ItemSection
                  key={item?.id || index}
                  item={item}
                  index={item?.id}
                  left="10px"
                  goToPage={goToCategoryPage}
                />
              );
            })}
          </Box>
        ) : (
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            No categories available.
          </Typography>
        )}
      </section>
    </>
  );
};

export default Category;
