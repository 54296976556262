import * as Yup from 'yup';

const validationSchemas = {
  stepOne: Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .min(3, "Title must be at least 3 characters"),
    description: Yup.string()
      .required("Description are required")
      .min(15, "Description must be at least 15 characters"),
    categoryId: Yup.string().required("Please select category"),
    condition: Yup.string().required("Please select the item condition"),
    images: Yup.array()
      .min(1, "Please upload at least one image")
      .required("Photos are required"),
  }),
  
  stepTwo:  Yup.object().shape({
    contactForPrice: Yup.boolean().required("Contact for price is required"),
    price: Yup.number()
      .min(1, "Price cannot be negative at least 1")
      .when("contactForPrice", {
        is: false,
        then: (schema) => schema.required("Please enter price"),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
    quantity: Yup.number()
      .moreThan(0, "Quantity should be more than 0")
      .required("You must provide Quantity for item"),
  }),
  
  
};

export default validationSchemas;

