import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { IoShieldCheckmark } from "react-icons/io5";
import profilePlacholder from "../../../assets/Images/profilePlaceholder.png";
import useFormattedDate from "../../../utils/useFormattedDate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


const Profile = ({ currentProduct,pathName }) => {
  const navigate = useNavigate();
  const { firstName, lastName, profilePicture, registeredSince, name, uid: sellerId, } =
    currentProduct;
  // const year = new Date(
  //   registeredSince.seconds * 1000 + registeredSince.nanoseconds / 1000000
  // ).getFullYear();
  const year =useFormattedDate(registeredSince,"year")
  const user = useSelector((state) => state.appState.userData);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const theme = useTheme();
  
  useEffect(() => {
    if (
      user &&
      !(Array.isArray(user) && user?.length === 0) &&
      typeof user === "object" &&
      user?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);
  
  const handleGoToChat =()=>{
    if (isLoggedIn) {
    if(pathName==="service-details"){
      const service={
        id: currentProduct?.id,
        image: currentProduct?.images[0],
        title: currentProduct?.title,
        description:currentProduct?.description,
        ...(currentProduct?.price != null && { price: currentProduct.price })
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/chat?type=services", { state: { service, userIdFromService:sellerId } })
    }else{
      const item={
        id: currentProduct?.id,
        image: currentProduct?.images[0],
        price: currentProduct?.price,
        title: currentProduct?.title,
        description:currentProduct?.description
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/chat?type=items", { state: { item, userIdFromItem:sellerId } })
    }
  }
  else {
    navigate("/login");
  }
  }
  const handleGoToProfile=()=>{
    if (isLoggedIn) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/sellers/seller-details/${sellerId}`)
  }
  else {
    navigate("/login");
  }
  }
  
  
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid display="flex" alignItems="center" gap={2} item>
        <Box
          sx={{
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            overflow: "hidden",
          }}
        >
          <img
            src={
              currentProduct?.profilePicture &&
              currentProduct?.profilePicture.length > 0
                ? profilePicture
                : profilePlacholder
            }
            alt={`${firstName} ${lastName}`}
            loading="lazy"
            style={{ objectFit: "cover", height: "100%", width: "100%" , cursor:"pointer "}}
            onClick={handleGoToProfile}
          />
        </Box>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ marginBottom: "4px" }}
          >
            <Typography
              variant="h3"
              sx={{
                textTransform: "capitalize",
                color: theme.palette.primary.doveGrey,
                fontWeight: "600",
                fontSize: "14px",
                cursor:"pointer ",
              }}
              onClick={handleGoToProfile}
            >
              {firstName && lastName ? `${firstName} ${lastName}` : name}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "20px",
                height: "20px",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: "50%",
              }}
            >
              <IoShieldCheckmark color={`${theme.palette.primary.main}`} />
            </Box>
          </Box>
          <Typography
            variant="body2"
            sx={{ fontSize: "12px", color: theme.palette.primary.blueBlack }}
          >
            {year ? `Member since ${year}`:"member"}
          </Typography>
        </Box>
      </Grid>
      {user?.uid !== currentProduct?.uid && (
      
      <Grid item>
        <Button
          variant="outlined"
          // onClick={handleGoToChat}
          onClick={handleGoToProfile}
          sx={{
            // width: "6.375rem",
            padding: "8px 16px",
            borderRadius: "40px",
            border: `1px solid ${theme.palette.neutral.blueCyan}`,
            color: theme.palette.primary.lightMain,
            fontWeight: "700",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {" "}
          {/* contact */}
          Visit Profile
        </Button>
      </Grid>
      )}
    </Grid>
  );
};

export default Profile;
