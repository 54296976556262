import { Button, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import notFound from "../../assets/Images/notFound.png";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Stack
      justifyContent="center"
      sx={{
        padding: "20px",
        height: "100vh",
        backgroundColor: theme.palette.primary.background,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={3}
        sx={{
          borderRadius: "16px",
          backgroundColor: theme.palette.primary.light,
          paddingY: "90px",
          paddingX: {
            xs: "20px",
            sm: "40px",
            md: "60px",
          },
        }}
      >
        <img
          src={notFound}
          loading="lazy"
          alt="not found screen"
          style={{
            width: "100%",
            maxWidth: "280px",
            objectFit: "contain",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.primary.label,
            fontWeight: 700,
            lineHeight: {
              xs: "24px",
              sm: "26px",
              md: "36px",
            },
            textAlign: "center",
            maxWidth: "400px",
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "24px",
            },
          }}
        >
          Sorry , we didnt find the page you are looking for.
        </Typography>
        <Button
          onClick={() => navigate("/")}
          sx={{
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.main,
            textTransform: "capitalize",
            padding: "16px 44px",
            borderRadius: "8px",
            fontSize: {
              xs: "12px",
              sm: "14px",
            },
            fontWeight: 700,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.light,
            },
          }}
        >
          go back to home page
        </Button>
      </Stack>
    </Stack>
  );
};

export default NotFound;
