import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Section from "./component/Section";
import { termsOfService } from "./data";

const TermsConditions = () => {
  const theme = useTheme();

  return (
    <section
      style={{ width: "70%", margin: "0 auto 4rem auto ", height: "90vh" }}
    >
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          padding: 2,
          borderRadius: 1,
          color: theme.palette.primary.text,
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.secondary.background,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        <Box>
          <Typography
            variant="h1"
            sx={{ mb: 2, fontWeight: "600", fontSize: "2rem" }}
          >
            Terms and Conditions
          </Typography>
          <Typography sx={{ fontWeight: "600" }}>
            Last updated: {termsOfService.last_updated}
          </Typography>
          <Typography variant="body1" sx={{ margin: "20px 0" }}>
            {termsOfService.description}
          </Typography>
          <Box>
            <ul>
              {termsOfService.services.map((service, index) => (
                <li key={`service-${index}`} style={{ marginBottom: "15px" }}>
                  {service.includes("https://")
                    ? service.split("https://").map((part, idx) => {
                        return idx > 0 ? (
                          <React.Fragment key={`service-part-${index}-${idx}`}>
                            <Link
                              to={`https://${part}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: theme.palette.primary.main,
                              }}
                            >
                              {`https://${part.split(",")[0]}`}
                            </Link>
                            {part.split(",")[1] && (
                              <span>{part.split(",")[1]}</span>
                            )}
                          </React.Fragment>
                        ) : (
                          part
                        );
                      })
                    : service}
                </li>
              ))}
            </ul>
          </Box>
        </Box>
        {/* Summary Section */}

        {/* TABLE OF CONTENTS Section */}
        <Box>
          <Typography variant="h6" sx={{ mb: 2, mt: 3, fontWeight: "600" }}>
            TABLE OF CONTENTS
          </Typography>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {termsOfService.tableOfContents.map((item, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "10px",
                  color: theme.palette.primary.main,
                }}
              >
                {`${index + 1}. ${item.title}`}
              </li>
            ))}
          </ul>
        </Box>

        {Object.keys(termsOfService).map((key, index) => {
          const section = termsOfService[key];
          if (section.title || section.content) {
            return (
              <Section
                key={index}
                title={section.title}
                details={section.content}
              />
            );
          }
          return null;
        })}
      </Box>
    </section>
  );
};

export default TermsConditions;
