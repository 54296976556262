import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
  TextField,
  IconButton,
  Button,
  CircularProgress,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { IoIosSend } from "react-icons/io";
import { BiSolidImageAdd } from "react-icons/bi";
import { IoChatbubbleOutline } from "react-icons/io5";
import { RiMore2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
  collection,
  doc,
  setDoc,
  addDoc,
  serverTimestamp,
  updateDoc,
  query,
  where,
  getDocs,
  orderBy,
  onSnapshot,
  getDoc,
  increment,
  limit,
  startAfter,
} from "firebase/firestore";
import UserChatCard from "./components/UserChatCard";
import { db, storageSdk as storage } from "../../configs/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { message as antMessage } from "antd";
import ChatMessages from "./components/ChatMessages";
import { setCurrentProduct } from "../../redux/slices/appStateSlice";
import { useDispatch } from "react-redux";
import { CiSearch } from "react-icons/ci";
import profilePlaceholder from "../../assets/Images/profilePlaceholder.png";

import {
  buttonStyle,
  chatContainerStyle,
  leftSectionStyle,
  rightSectionStyle,
  chatHeaderStyle,
  chatInputBoxStyle,
  chatListScrollContainerStyle,
} from "./chatStyles";

const Chat = () => {
  const theme = useTheme();
  const tabStyles = {
    Tabs: (bgColor) => ({
      backgroundColor: bgColor,
      "& .MuiTabs-indicator": {
        display: "none",
      },
      borderRadius: "72px",
      padding: "5px 15px",
    }),
    Tab: (isActive, theme) => ({
      textTransform: "none",
      fontSize: "14px",
      fontFamily: theme.typography.headerFont,
      color: isActive
        ? theme.palette.primary.main
        : theme.palette.primary.arrow,
      backgroundColor: isActive ? theme.palette.primary.light : "transparent",
      border: "1px solid",
      borderColor: isActive ? theme.palette.primary.borderIcon : "transparent",
      borderRadius: "16px",
      paddingX: 2,
      minHeight: "34px",
      minWidth: "90px",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    }),
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const typeParts = type?.split("/");
  const chatId = typeParts?.[1];

  const [activeTab, setActiveTab] = useState(() => {
    if (type === "items") return "items";
    if (type === "services") return "services";
    return "users"; // Default to 'users' if type is not specified
  });
  const [userToChatWith, setUserToChatWith] = useState(
    location.state?.user?.uid ||
      location.state?.userIdFromItem ||
      location.state?.userIdFromService ||
      null
  );
  const [message, setMessage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const currentUser = useSelector((state) => state.appState.userData);
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [ItemToChatAbout, setItemToChatAbout] = useState(
    location.state?.item || null
  );
  const [serviceToChatAbout, setServiceToChatAbout] = useState(
    location.state?.service || null
  );
  const contentToChatAbout =
    activeTab === "items" ? ItemToChatAbout : serviceToChatAbout;
  const [lastVisibleChat, setLastVisibleChat] = useState(null);
  const [hasMoreChats, setHasMoreChats] = useState(true);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [lastVisibleMessage, setLastVisibleMessage] = useState(null);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastMessageID, setLastMessageID] = useState(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [unreadCounts, setUnreadCounts] = useState({
    users: 0,
    items: 0,
    services: 0,
  });

  const [uploadProgress, setUploadProgress] = useState(0);
  const [activeChatId, setActiveChatId] = useState(null);
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const servicesCategories = useSelector(
    (state) => state.appState.servicesCategories
  );
  let isLoading =
    !(
      !currentChat &&
      !userDetails &&
      !ItemToChatAbout &&
      !serviceToChatAbout
    ) &&
    (!userDetails ||
      (activeTab === "items" && !ItemToChatAbout) ||
      (activeTab === "services" && !serviceToChatAbout));

  const unreadCountsRef = useRef(unreadCounts);

  useEffect(() => {
    if (!currentUser) return;

    // Firestore query to get all chats for the current user
    const chatsQuery = query(
      collection(db, "chats"),
      where("users", "array-contains", currentUser.uid)
    );

    // Listen for real-time updates
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      let usersUnread = 0;
      let itemsUnread = 0;
      let servicesUnread = 0;

      snapshot.forEach((doc) => {
        const chat = doc.data();
        const userField =
          currentUser.uid === chat.unRead.user1.uid
            ? chat.unRead.user1
            : chat.unRead.user2;

        if (userField.numOfMessages > 0) {
          // Categorize unread messages based on chat type
          if (chat.chatType === "userToUser") {
            usersUnread += userField.numOfMessages;
          } else if (chat.chatType === "item") {
            itemsUnread += userField.numOfMessages;
          } else if (chat.chatType === "service") {
            servicesUnread += userField.numOfMessages;
          }
        }
      });

      // Update unreadCounts state dynamically
      setUnreadCounts({
        users: usersUnread,
        items: itemsUnread,
        services: servicesUnread,
      });
    });

    return () => unsubscribe(); // Cleanup listener when component unmounts
  }, [currentUser]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fullType = queryParams.get("type");

    const [type] = fullType ? fullType.split("/") : ["users"];

    if (type === "items" || type === "services" || type === "users") {
      setActiveTab(type);
    } else {
      setActiveTab("users");
    }
  }, [location.search]);

  useEffect(() => {
    const urlPath = location.search;
    const typePath = new URLSearchParams(urlPath).get("type");
    if (!typePath || typePath === activeTab) return;

    if (typePath) {
      const splitPath = typePath.split("/");
      const extractedChatId = splitPath[1];
      setActiveChatId(extractedChatId);
    }
  }, [location.search]);

  const previousUserToChatWithRef = useRef(userToChatWith);
  useEffect(() => {
    const newUserToChatWith =
      location.state?.user?.uid ||
      location.state?.userIdFromItem ||
      location.state?.userIdFromService ||
      null;

    if (previousUserToChatWithRef.current !== newUserToChatWith) {
      setUserToChatWith(newUserToChatWith);
      previousUserToChatWithRef.current = newUserToChatWith;
    }
  }, [location.state]);

  const handleChatNavigation = (chat, chatType, messageID) => {
    const otherUserId = chat.users.find((uid) => uid !== currentUser.uid);

    navigate(
      `/chat?type=${chatType}/${chat.id}/message/${messageID || "fallbackID"}`,
      {
        state: {
          userId: otherUserId,

          item: ItemToChatAbout,
          service: serviceToChatAbout,
        },
      }
    );
  };
  useEffect(() => {
    const item = location.state?.item;
    const service = location.state?.service;

    if (item) {
      setItemToChatAbout(item);
    } else if (service) {
      setServiceToChatAbout(service);
    }
  }, [location.state?.item, location.state?.service]);

  const scrollContainerRef = useRef(null);
  const chatListRef = useRef(null);

  const handleImageUpload = async () => {
    const chatId = currentChat ? currentChat.id : (await createNewChat()).id;
    const timestamp = new Date().toISOString();
    const storageRef = ref(storage, `chats/${chatId}/${timestamp}`);

    const uploadResult = await uploadBytes(storageRef, imageFile);
    const downloadURL = await getDownloadURL(uploadResult.ref);

    return { chatId, downloadURL };
  };

  const handleChatClick = useCallback(
    (chat, chatType) => {
      setHasMoreMessages(true);
      setIsLoadingMessages(false);
      setMessages([]);
      setLastVisibleMessage(null);
      setCurrentChat(chat);
      setUserDetails(chat?.userData);
      // Find the other user in the chat to set as userToChatWith
      const otherUserId = chat?.users?.find((uid) => uid !== currentUser?.uid);
      setUserToChatWith(otherUserId);
      resetUnreadMessages(chat?.id);
      setActiveChatId(chat?.id);
      markMessagesAsSeen(chat?.id);
      setLastMessageID((prevID) => {
        navigate(
          `/chat?type=${chatType}/${chat?.id}/message/${prevID || "fallbackID"}`
        );
        return prevID;
      });

      if (chatType === "items") {
        setItemToChatAbout(chat.itemData);
      } else if (chatType === "services") {
        setServiceToChatAbout(chat.serviceData);
      }
    },
    [currentChat, userToChatWith, currentUser.uid, activeTab, activeChatId]
  );

  const renderChatCards = (chatType) => {
    return chats.map((chat) => (
      <UserChatCard
        activeChatId={activeChatId || currentChat?.id}
        key={chat.id}
        theme={theme}
        chat={chat}
        currentUser={currentUser}
        activeTab={activeTab}
        onClick={() => handleChatClick(chat, chatType)}
      />
    ));
  };

  const renderTabContent = () => {
    if (chats.length === 0 && !isLoadingChats) {
      switch (activeTab) {
        case "users":
          return (
            <Typography variant="body1" style={{ textAlign: "center" }}>
              No user chats available.
            </Typography>
          );
        case "items":
          return (
            <Typography variant="body1" style={{ textAlign: "center" }}>
              No part-related chats available.
            </Typography>
          );
        case "services":
          return (
            <Typography variant="body1" style={{ textAlign: "center" }}>
              No service-related chats available.
            </Typography>
          );
        default:
          return null;
      }
    } else {
      switch (activeTab) {
        case "users":
          return renderChatCards("users");
        case "items":
          return renderChatCards("items");
        case "services":
          return renderChatCards("services");
        default:
          return null;
      }
    }
  };
  // Handle tab change and update the URL accordingly
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentChat(null);
    setServiceToChatAbout(null);
    setItemToChatAbout(null);
    setUserToChatWith(null);
    setUserDetails(null);
    setLastVisibleChat(null);
    setHasMoreChats(true);
    setIsLoadingChats(false);
    setHasReachedEnd(false);

    const route =
      newValue === "items"
        ? "items"
        : newValue === "services"
        ? "services"
        : "users";
    navigate(`/chat?type=${route}`, { replace: true });
  };
  // Mark messages as seen when the current chat changes
  const markMessagesAsSeen = async () => {
    if (!currentChat) return;
    try {
      const messagesRef = collection(db, "chats", currentChat.id, "messages");
      const unseenMessagesQuery = query(
        messagesRef,
        where("seen", "==", false),
        where("senderId", "!=", currentUser.uid)
      );

      const unseenMessagesSnapshot = await getDocs(unseenMessagesQuery);
      // Batch update to mark all messages as seen
      const batch = db.batch();
      unseenMessagesSnapshot.forEach((doc) => {
        batch.update(doc.ref, { seen: true });
      });
      await batch.commit();

      // Reset unread count for the current user in the chat   document
      const userField =
        currentUser.uid === currentChat?.unRead?.user1?.uid
          ? "unRead.user1.numOfMessages"
          : "unRead.user2.numOfMessages";

      await updateDoc(doc(db, "chats", currentChat.id), {
        [userField]: 0,
      });
    } catch (error) {
      console.error("Error marking messages as seen:", error);
      antMessage.error("Failed to mark messages as seen");
    }
  };
  const handleSendMessage = async () => {
    if (!message.trim() && !imageFile) {
      antMessage.warning("Please enter a message or attach an image");
      return;
    } // Prevent sending empty messages
    if (currentUser?.uid === userToChatWith) {
      antMessage.warning("You can't send a message to yourself");
      return;
    }

    if (
      imageFile &&
      !["image/jpeg", "image/png", "image/jpg"].includes(imageFile.type)
    ) {
      antMessage.warning(
        "Please upload a valid image file (.jpg, .jpeg, .png)"
      );
      return;
    }
    if (imageFile) {
      setIsUploading(true);
    }

    try {
      let chatId = currentChat ? currentChat.id : null;
      const messageRef = doc(collection(db, "messages"));
      const messageId = messageRef.id;

      // Create a new chat if it doesn't exist
      if (!currentChat && userToChatWith) {
        chatId = (await createNewChat()).id;
      }

      // Set a temporary client timestamp to display until the server timestamp is synced
      let localCreatedAt = new Date();
      let messageData = {
        id: messageId,
        senderId: currentUser?.uid,
        createdAt: localCreatedAt, // Temporarily use the client's timestamp
        seen: false,
      };

      // Handle image upload if there is an image file
      if (imageFile) {
        setIsUploading(true);
        const { downloadURL } = await handleImageUpload(); // Upload image and get URL
        messageData = {
          ...messageData,
          messageType: "image",
          image: downloadURL,
          text: null,
        };
      } else {
        messageData = {
          ...messageData,
          messageType: "text",
          text: message,
          image: null,
        };
      }

      // Adding the message to the `messages` subcollection, using serverTimestamp for final 'createdAt'
      await addDoc(collection(db, "chats", chatId, "messages"), {
        ...messageData,
        createdAt: serverTimestamp(),
      });

      // Update chat's last message (either text or image)
      await updateDoc(doc(db, "chats", chatId), {
        lastMessage: {
          message:
            messageData?.messageType === "image" ? "Image" : messageData?.text,
          uid: messageData?.senderId,
        },
        updatedAt: serverTimestamp(),
      });

      // Increment unread count for the recipient
      let recipientField = "";
      if (currentChat) {
        recipientField =
          currentUser.uid === currentChat?.unRead?.user1?.uid
            ? "unRead.user2.numOfMessages"
            : "unRead.user1.numOfMessages";
      } else {
        // For new chats, assume userToChatWith is user2
        recipientField =
          currentUser.uid === userToChatWith
            ? "unRead.user1.numOfMessages"
            : "unRead.user2.numOfMessages";
      }

      await updateDoc(doc(db, "chats", chatId), {
        [recipientField]: increment(1),
      });

      setMessage("");
      setImageFile(null);

      setHasMoreMessages(true);
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsUploading(false);
    }
  };
  const loadOlderMessages = async () => {
    if (!lastVisibleMessage) {
      console.log("No last visible message to start from.");
      setHasMoreMessages(false);
      return;
    }

    if (isLoadingMessages) {
      console.log("Already loading messages.");
      return;
    }

    if (!lastVisibleMessage || isLoadingMessages) {
      console.log("No more messages to load");
      return;
    }
    // Check if lastVisibleMessage has a valid createdAt timestamp
    const lastCreatedAt = lastVisibleMessage.data().createdAt;

    if (lastCreatedAt === null || lastCreatedAt === undefined) {
      return;
    }
    setIsLoadingMessages(true);

    const olderMessagesQuery = query(
      collection(db, "chats", currentChat.id, "messages"),
      orderBy("createdAt", "desc"),
      startAfter(lastVisibleMessage),
      limit(10)
    );

    const snapshot = await getDocs(olderMessagesQuery);

    if (snapshot.empty) {
      setHasMoreMessages(false);
      setIsLoadingMessages(false);

      return;
    }

    const olderMessages = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setLastVisibleMessage(snapshot.docs[snapshot.docs.length - 1]);

    // Add new messages at the beginning of the existing messages array
    setMessages((prevMessages) => [
      ...olderMessages.reverse(),
      ...prevMessages,
    ]);
    setIsLoadingMessages(false);
  };
  const renderRightSection = () => {
    // When no message is selected
    if (
      !currentChat &&
      !userDetails &&
      !ItemToChatAbout &&
      !serviceToChatAbout &&
      !userToChatWith
    ) {
      return (
        <Stack alignItems="center" justifyContent="center" gap={1}>
          <IoChatbubbleOutline size="24px" />
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            You don’t have a message selected
          </Typography>
          <Typography variant="caption">
            All messages are private conversations between you & the seller
          </Typography>
        </Stack>
      );
    }
    if (currentChat) {
      return (
        <Box>
          <ChatMessages
            loadOlderMessages={loadOlderMessages}
            key={lastVisibleMessage || "defaultKey"}
            lastVisibleMessage={lastVisibleMessage}
            hasMoreMessages={hasMoreMessages}
            isLoadingMessages={isLoadingMessages}
            messages={messages}
            theme={theme}
            activeTab={activeTab}
            userDetails={userDetails}
            scrollToBottom={scrollToBottom}
            scrollContainerRef={scrollContainerRef}
          />
        </Box>
      );
    } else if (userToChatWith && !currentChat) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ height: "40vh" }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Start new chat by sending a message
          </Typography>
        </Stack>
      );
    }
    return null;
  };
  const createNewChat = async () => {
    const newChatRef = doc(collection(db, "chats"));
    const chatId = newChatRef.id;
    const chatData = {
      id: chatId,
      chatType:
        activeTab === "users"
          ? "userToUser"
          : activeTab === "items"
          ? "item"
          : "service",
      users: [currentUser?.uid, userToChatWith],
      adminId: null,
      itemId: ItemToChatAbout ? ItemToChatAbout?.id : null,
      serviceId: serviceToChatAbout ? serviceToChatAbout?.id : null,
      lastMessage: {
        message: message || "Photo",
        uid: currentUser?.uid,
      },
      lastRead: {
        user1: { uid: currentUser?.uid, dateTime: null },
        user2: { uid: userToChatWith, dateTime: null },
      },
      typing: null,
      unRead: {
        user1: { uid: currentUser?.uid, numOfMessages: 0 },
        user2: { uid: userToChatWith, numOfMessages: 0 },
      },
      itemData: ItemToChatAbout
        ? {
            id: ItemToChatAbout.id,
            title: ItemToChatAbout.title,
            description: ItemToChatAbout.description,
            image: ItemToChatAbout.image,
            price: ItemToChatAbout.price,
          }
        : null,
      serviceData: serviceToChatAbout
        ? {
            id: serviceToChatAbout.id,
            title: serviceToChatAbout.title,
            description: serviceToChatAbout.description,
            image: serviceToChatAbout.image,
          }
        : null,
      // createdAt: serverTimestamp(),
      // updatedAt: serverTimestamp()
      createdAt: serverTimestamp() || { seconds: Date.now() / 100 },
      updatedAt: serverTimestamp() || { seconds: Date.now() / 100 },
    };

    await setDoc(newChatRef, chatData);
    // setCurrentChat(chatData);
    setTimeout(async () => {
      const updatedDoc = await getDoc(newChatRef);
      setCurrentChat(updatedDoc.data());
    }, 10);
    return chatData;
  };
  const resetUnreadMessages = async (chatId) => {
    if (!chatId || !currentUser) return;

    try {
      const chatRef = doc(db, "chats", chatId);
      const chatSnapshot = await getDoc(chatRef);

      if (chatSnapshot.exists()) {
        const chatData = chatSnapshot.data();

        // Determine if current user is `user1` or `user2`
        let updateField;
        if (chatData.unRead.user1.uid === currentUser.uid) {
          updateField = "unRead.user1.numOfMessages";
        } else if (chatData.unRead.user2.uid === currentUser.uid) {
          updateField = "unRead.user2.numOfMessages";
        }

        if (updateField) {
          // Set the numOfMessages to 0 for the current user
          await updateDoc(chatRef, {
            [updateField]: 0,
          });
        }
      }
    } catch (error) {
      console.error("Error resetting unread messages:", error);
    }
  };
  const getCategoryName = (categoryId) => {
    const category = itemsCategories.find((cat) => cat.id === categoryId);
    return category ? category.name : "Unknown Category";
  };

  const getServiceName = (categoryId) => {
    if (!servicesCategories.length) return "Loading...";
    const category = servicesCategories.find(
      (service) => service.id === categoryId
    );
    return category ? category.name : "Unknown service";
  };
  const handleMoreDetails = async () => {
    try {
      let productData = null;
      let nameToDisplay = "";
      if (activeTab === "items") {
        const itemRef = doc(db, "products", contentToChatAbout.id);
        const itemDoc = await getDoc(itemRef);
        if (itemDoc.exists()) {
          productData = { id: itemDoc.id, ...itemDoc.data() };
          nameToDisplay = getCategoryName(productData.categoryId);
        } else {
          console.log("No such item exists!");
          return;
        }
        dispatch(setCurrentProduct(productData));
        navigate(`/product/${contentToChatAbout.id}`, {
          state: { nameToDisplay, chatId: currentChat?.id, activeTab },
        });
      } else if (activeTab === "services") {
        const serviceRef = doc(db, "services", contentToChatAbout.id);
        const serviceDoc = await getDoc(serviceRef);
        if (serviceDoc.exists()) {
          productData = { id: serviceDoc.id, ...serviceDoc.data() };
          nameToDisplay = getServiceName(productData.categoryId);
        } else {
          console.log("No such service exists!");
          return;
        }
        dispatch(setCurrentProduct(productData));
        navigate(`/service-details/${contentToChatAbout.id}`, {
          state: { nameToDisplay },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const loadMoreChats = () => {
    if (!hasMoreChats || isLoadingChats) {
      return;
    }
    fetchChatsWithUserData(true);
    setHasReachedEnd(false);
  };
  const handleChatsScroll = () => {
    if (chatListRef.current && !isLoadingChats && !hasReachedEnd) {
      const { scrollTop, scrollHeight, clientHeight } = chatListRef.current;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setHasReachedEnd(true);
        loadMoreChats();
      }
    }
  };

  const fetchChatsWithUserData = (isLoadMore = false) => {
    if (!currentUser || !hasMoreChats) {
      return;
    }

    let chatsQuery = null;
    const chatsRef = collection(db, "chats");

    if (activeTab === "users") {
      chatsQuery = query(
        chatsRef,
        where("users", "array-contains", currentUser.uid),
        where("chatType", "==", "userToUser"),
        orderBy("updatedAt", "desc"),
        limit(5)
      );
    } else if (activeTab === "items") {
      chatsQuery = query(
        chatsRef,
        where("users", "array-contains", currentUser.uid),
        where("chatType", "==", "item"),
        orderBy("updatedAt", "desc"),
        limit(4)
      );
    } else if (activeTab === "services") {
      chatsQuery = query(
        chatsRef,
        where("users", "array-contains", currentUser.uid),
        where("chatType", "==", "service"),
        orderBy("updatedAt", "desc"),
        limit(4)
      );
    }

    if (isLoadMore && lastVisibleChat) {
      chatsQuery = query(chatsQuery, startAfter(lastVisibleChat));
    }

    setIsLoadingChats(true);

    const unsubscribe = onSnapshot(chatsQuery, async (snapshot) => {
      if (snapshot.empty) {
        setHasMoreChats(false);

        setIsLoadingChats(false);
        return;
      }

      const newChats = [];
      const userIds = new Set();

      snapshot.forEach((doc) => {
        const chatData = doc.data();
        const otherUserId = chatData.users.find(
          (uid) => uid !== currentUser.uid
        );
        if (otherUserId) userIds.add(otherUserId);

        newChats.push({ id: doc.id, ...chatData });
      });

      const lastChat = snapshot.docs[snapshot.docs.length - 1];
      setLastVisibleChat(lastChat);

      // Fetch user data in batch using userIds
      const usersQuery = query(
        collection(db, "users"),
        where("uid", "in", Array.from(userIds))
      );

      const userDocsSnapshot = await getDocs(usersQuery);
      const usersData = userDocsSnapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));

      // Attach user data to each chat and filter out duplicates
      const chatsWithUserData = newChats.map((chat) => {
        const otherUserId = chat.users.find((uid) => uid !== currentUser.uid);
        const userData = usersData.find((user) => user.uid === otherUserId);
        return { ...chat, userData };
      });

      setChats((prevChats) => {
        const combinedChats = isLoadMore
          ? [...prevChats, ...chatsWithUserData]
          : chatsWithUserData;
        return combinedChats.filter(
          (chat, index, self) =>
            index === self.findIndex((t) => t.id === chat.id)
        );
      });
      setIsLoadingChats(false);
    });

    return unsubscribe;
  };

  const fetchUserDetails = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        console.error("No such user!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  };
  const scrollToBottom = (behavior = "smooth") => {
    scrollContainerRef.current?.scrollTo({
      top: scrollContainerRef.current.scrollHeight,
      behavior,
    });
  };
  useEffect(() => {
    const getUserDetails = async () => {
      if (userToChatWith) {
        const details = await fetchUserDetails(userToChatWith);
        setUserDetails(details);
      }
    };

    getUserDetails();
  }, [userToChatWith]);

  useEffect(() => {
    if (currentChat) {
      markMessagesAsSeen();
    }
  }, [currentChat]);
  useEffect(() => {
    console.log("Setting up onSnapshot listener for fetch.");
    if (!currentUser) return;

    const unsubscribe = fetchChatsWithUserData();
    return () => {
      console.log("Cleaning up onSnapshot listener for fetch.");
      if (unsubscribe) unsubscribe();
      setChats([]);
      setMessages([]);
      setHasReachedEnd(false);
    };
  }, [currentUser, activeTab]);
  // Check for existing chat with userToChatWith when component mounts or userToChatWith changes
  useEffect(() => {
    if (!userToChatWith) return;
    const checkExistingChat = async () => {
      const chatsRef = collection(db, "chats");
      let chatQuery;
      if (activeTab === "items" && ItemToChatAbout) {
        chatQuery = query(
          chatsRef,
          where("users", "array-contains", currentUser.uid),
          where("itemId", "==", ItemToChatAbout.id)
        );
      } else if (activeTab === "users" && !ItemToChatAbout) {
        chatQuery = query(
          chatsRef,
          where("users", "array-contains", currentUser.uid),
          where("chatType", "==", "userToUser")
        );
      } else if (activeTab === "services" && serviceToChatAbout) {
        chatQuery = query(
          chatsRef,
          where("users", "array-contains", currentUser.uid),
          where("serviceId", "==", serviceToChatAbout.id)
        );
      } else {
        return;
      }

      const chatSnapshot = await getDocs(chatQuery);
      let chatFound = null;

      chatSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.users.includes(userToChatWith)) {
          chatFound = { id: doc.id, ...data };
        }
      });

      if (chatFound) {
        setCurrentChat(chatFound);
      } else {
        setCurrentChat(null);
      }
    };
    checkExistingChat();
  }, [
    userToChatWith,
    currentUser.uid,
    ItemToChatAbout,
    activeTab,
    serviceToChatAbout,
  ]);

  useEffect(() => {
    if (!currentChat) return;

    const messagesRef = collection(db, "chats", currentChat.id, "messages");
    const initialMessagesQuery = query(
      messagesRef,
      orderBy("createdAt", "desc"),
      limit(10)
    );

    const unsubscribeMessages = onSnapshot(
      initialMessagesQuery,
      async (snapshot) => {
        const validMessages = snapshot.docs.filter(
          (doc) => doc.data().createdAt
        );
        if (validMessages.length > 0) {
          setLastVisibleMessage(validMessages[validMessages.length - 1]);
        } else {
          setLastVisibleMessage(null);
        }

        const fetchedMessages = validMessages.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(fetchedMessages.reverse()); // Reverse for chronological order

        setHasMoreMessages(true);
        if (fetchedMessages.length > 0) {
          const latestMessageID =
            fetchedMessages[fetchedMessages.length - 1].id;
          setLastMessageID(() => {
            handleChatNavigation(currentChat, activeTab, latestMessageID);
            return latestMessageID;
          });
        } else {
          setLastMessageID(null);
        }
        // Batch update for unseen messages not sent by current user
        const batch = db.batch();
        let hasUnseenMessages = false;
        snapshot.docs.forEach((doc) => {
          const messageData = doc.data();
          if (!messageData.seen && messageData.senderId !== currentUser.uid) {
            batch.update(doc.ref, { seen: true });
            hasUnseenMessages = true;
          }
        });
        await batch.commit();

        // Reset unread count in chat document
        if (hasUnseenMessages) {
          const userField =
            currentUser.uid === currentChat.unRead.user1.uid
              ? "unRead.user1.numOfMessages"
              : "unRead.user2.numOfMessages";
          await updateDoc(doc(db, "chats", currentChat.id), {
            [userField]: 0,
          });
        }
      }
    );

    return () => {
      unsubscribeMessages();
    };
  }, [activeTab, currentChat, currentUser?.uid]);

  useEffect(() => {
    setIsScrolled(false);
  }, [chats, activeTab, currentChat]);
  // Simulate upload progress for demonstration
  useEffect(() => {
    if (isUploading) {
      setUploadProgress(0);
      const interval = setInterval(() => {
        setUploadProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 10;
        });
      }, 500); // Update every 500ms
    }
  }, [isUploading]);
  // if you uncomment this when you load more messages it will scroll to the buttom every time
  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);
  const mapUserTypeToTab = (userType) => {
    switch (userType) {
      case "serviceProvider":
        return "Services Providers";
      case "seller":
        return "Sellers";
      case "all":
        return "All";
      default:
        return "Unknown";
    }
  };

  const goToSellerPage = (id, userType) => {
    const selectedTab = mapUserTypeToTab(userType);
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/sellers/seller-details/${id}`, { state: { selectedTab } });
  };
  const getDisplayName = () => {
    const name =
      userDetails?.businessInfo?.name ||
      `${userDetails?.firstName} ${userDetails?.lastName}`;
    return name.length > 20 ? `${name.substring(0, 20)}...` : name;
  };
  const conditionalHeight = imageFile
    ? {
        height: {
          xs: "auto",
          sm: "70vh",
          xl: "94vh",
        },
      }
    : {};
  useEffect(() => {
    const fetchChatDetails = async () => {
      if (!chatId) return; // Exit if no chatId is provided

      try {
        const chatRef = doc(db, "chats", chatId);
        const chatSnapshot = await getDoc(chatRef);

        if (chatSnapshot.exists()) {
          const chatData = chatSnapshot.data();
          // Check if current user is part of the chat
          if (!chatData.users.includes(currentUser?.uid)) {
            antMessage.error("You are not authorized to view this chat.");

            return;
          }
          setCurrentChat({ id: chatSnapshot.id, ...chatData });
        } else {
          console.log("Chat does not exist!");
        }
      } catch (error) {
        console.error("Error fetching chat details:", error);
      }
    };

    fetchChatDetails();
  }, [chatId]);

  useEffect(() => {
    const fetchUserDetailsFromState = async () => {
      const userIdFromState = location.state?.userId;

      if (!userIdFromState) return; // Exit if no userId is found in state

      try {
        const userDetails = await fetchUserDetails(userIdFromState);
        if (userDetails) {
          setUserDetails(userDetails);
        } else {
          console.log("User details could not be fetched.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetailsFromState();
  }, [location.state]);

  return (
    <Stack
      direction="row"
      gap={3}
      sx={{ ...chatContainerStyle(theme, imageFile), ...conditionalHeight }}
    >
      {/* Left Section with Tabs */}
      <Stack gap={2} sx={leftSectionStyle}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: theme.typography.headerFont,
            textTransform: "capitalize",
            color: theme.palette.primary.trueBlack,
          }}
        >
          messages
        </Typography>

        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant={isSmallScreen ? "contained" : "fullWidth"}
          sx={tabStyles.Tabs(theme.palette.primary.greyLightOpacity)}
        >
          <Tab
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>Users</Typography>
                {unreadCounts.users > 0 && (
                  <span
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    width: "8px",
                    height: "8px", 
                    display: "inline-block",
                    textAlign: "center",
                    lineHeight: "20px", 
                  }}
                >
                  
                </span>
                )}
              </Stack>
            }
            value="users"
            sx={tabStyles.Tab(activeTab === "users", theme)}
          />
          <Tab
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>Parts</Typography>
                {unreadCounts.items > 0 && (
                  <span
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    width: "8px",
                    height: "8px", 
                    display: "inline-block",
                    textAlign: "center",
                    lineHeight: "20px", 
                  }}
                >
                  
                </span>
                )}
              </Stack>
            }
            value="items"
            sx={tabStyles.Tab(activeTab === "items", theme)}
          />
          <Tab
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>Services</Typography>
                {unreadCounts.services > 0 && (
                  <span
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    width: "8px",
                    height: "8px", 
                    display: "inline-block",
                    textAlign: "center",
                    lineHeight: "20px", 
                  }}
                >
                  
                </span>
                )}
              </Stack>
            }
            value="services"
            sx={tabStyles.Tab(activeTab === "services", theme)}
          />
        </Tabs>
        {/* Content under tabs based on current type */}
        <Stack
          gap={1}
          sx={chatListScrollContainerStyle}
          ref={chatListRef}
          onScroll={handleChatsScroll}
        >
          {renderTabContent()}

          {isLoadingChats && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100px" }}
            >
              <CircularProgress size={24} />
            </Stack>
          )}
        </Stack>
      </Stack>

      {/* Right Section (Chat Details or other content) */}
      {isLoading ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={rightSectionStyle}
        >
          <CircularProgress size={40} />
          <Typography
            sx={{ marginTop: "10px", color: theme.palette.text.secondary }}
          >
            Loading...
          </Typography>
        </Stack>
      ) : (
        <Stack
          justifyContent={!currentChat && !userToChatWith ? "center" : ""}
          alignItems={!currentChat && !userToChatWith ? "center" : ""}
          sx={rightSectionStyle}
        >
          <Stack gap={1} sx={{ padding: "30px" }}>
            {/* chat header */}
            {(currentChat ||
              contentToChatAbout ||
              ItemToChatAbout ||
              userToChatWith) && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={chatHeaderStyle}
              >
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    goToSellerPage(userDetails?.uid, userDetails?.userType)
                  }
                >
                  <img
                    src={userDetails?.profilePicture || profilePlaceholder}
                    style={{
                      width: "48px",
                      height: "48px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    loading="lazy"
                    alt={userDetails?.firstName}
                  />
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{
                        textTransform: "capitalize",
                        color: theme.palette.primary.purpleBlack,
                        fontFamily: theme.typography.headerFont,
                      }}
                    >
                      {getDisplayName()}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
            {/*  in case there item or service */}
            {contentToChatAbout && activeTab !== "users" && (
              <Stack
                direction="row"
                justifyContent="center"
                gap={2}
                bgcolor={theme.palette.primary.background}
                sx={{ padding: "10px 5px", borderRadius: "8px" }}
              >
                <Stack justifyContent="center" alignItems="center">
                  <img
                    src={contentToChatAbout.image}
                    alt={contentToChatAbout.title}
                    style={{
                      width: "81px",
                      height: "81px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                    loading="lazy"
                  />
                </Stack>
                <Stack justifyContent="center" gap={0.8}>
                  <Typography>
                    {contentToChatAbout.title.length > 20
                      ? `${contentToChatAbout.title.substring(0, 20)}...`
                      : contentToChatAbout.title}
                  </Typography>
                  <Typography>
                    {contentToChatAbout.price
                      ? `$ ${contentToChatAbout?.price}`
                      : "Contact for price"}
                  </Typography>
                  <Stack direction="row" gap={1}>
                    <Button sx={buttonStyle} onClick={handleMoreDetails}>
                      more details
                    </Button>
                    <Button
                      sx={buttonStyle}
                      onClick={() =>
                        goToSellerPage(userDetails?.uid, userDetails?.userType)
                      }
                    >
                      {userDetails?.userType === "buyer"
                        ? "View Buyer"
                        : userDetails?.userType === "seller"
                        ? "View Seller"
                        : "View Seller"}
                      {/* {activeTab === "items"
                      ? "view seller"
                      : "view service provider"} */}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )}
            {renderRightSection()}
            {/*  Chat Input Section */}
            {(currentChat ||
              contentToChatAbout ||
              ItemToChatAbout ||
              userToChatWith) && (
              <Box
                sx={{
                  width: "100%",
                  alignSelf: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {/* Image Preview Box */}
                {imageFile && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      border: `1px solid ${theme.palette.gray.border}`,
                      borderRadius: "5px",
                      padding: "5px",
                      backgroundColor: theme.palette.primary.greyLightOpacity,
                    }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <img
                        src={URL.createObjectURL(imageFile)}
                        alt="Preview"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          top: "0px",
                          right: "-3px",
                          color: "#B0B0B0",
                        }}
                        onClick={() => setImageFile(null)}
                      >
                        <MdDeleteForever />
                      </IconButton>
                      {isUploading && (
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "5px",
                            color: "white",
                          }}
                        >
                          <CircularProgress
                            size={20}
                            color="inherit"
                            variant="determinate"
                            value={uploadProgress}
                          />
                        </Stack>
                      )}
                    </Box>
                  </Box>
                )}

                {/* Input Field */}
                <TextField
                  variant="outlined"
                  placeholder="Type a message..."
                  fullWidth
                  value={message}
                  disabled={isUploading || imageFile}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault(); // Prevent adding a new line
                      handleSendMessage();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <IconButton
                          component="label"
                          sx={{ color: "#B0B0B0", mr: 1 }}
                        >
                          <BiSolidImageAdd />
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (
                                file &&
                                [
                                  "image/jpeg",
                                  "image/png",
                                  "image/jpg",
                                ].includes(file.type)
                              ) {
                                setImageFile(file);
                              } else {
                                antMessage.warning(
                                  "Please upload a valid image file (.jpg, .jpeg, .png)"
                                );
                              }
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSendMessage}
                          disabled={isUploading}
                        >
                          <IoIosSend color={theme.palette.primary.main} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: chatInputBoxStyle,
                  }}
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Chat;
