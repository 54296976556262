import React from "react";
import {
  TextField,
  InputAdornment,
  useTheme,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { FiSearch } from "react-icons/fi";

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  placeholder,
  sortOrderControle,
  handleSortOrderChange,
  handleSortDropdownToggle,
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={2}
      alignItems="center" 
      justifyContent="space-between"
    >
      <Grid item xs={12} sm={8} md={10}>
        <TextField
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            background: theme.palette.primary.light,
            width: "100%",
            height: "48px",
            fontSize: "16px",
            borderRadius: "10px",
            outline: "none",
            position: "relative",
            top: { xs: "-84px", sm: "-84px", md: "-100px" },
            transform: {
              xs: "translate(0%, 47%)",
              sm: "translate(0%, 47%)",
              md: "translate(17%, 47%)",
              lg: "translate(50%, 50%)",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              "&:hover fieldset": {
                borderColor: theme.palette.primary.main,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
              },
            },
            "& .MuiInputBase-input": {
              padding: "0px",
              height: "48px",
              borderRadius: "10px",
              border: `1px solid ${theme.palette.primary.light}`,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FiSearch
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "24px",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <FormControl
          variant="outlined"
          size="small"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexDirection: "column",
            position: "relative",
            top: { xs: "-84px", sm: "-84px", md: "-54px", lg: '-75px' },
            transform: {
              xs: "translate(0%, 47%)",
              sm: "translate(0%, 70%)",
              md: "translate(86%, 80%)",
              lg: "translate(420%, 90%)",
              xl: "translate(490%, 90%)",
            },
          }}
        >
          {/* <Typography>Sort by importance</Typography> */}
          <Select
            value={sortOrderControle}
            onChange={(event) => handleSortOrderChange(event.target.value)}
            onOpen={() => handleSortDropdownToggle(true)}
            onClose={() => handleSortDropdownToggle(false)}
          >
            <MenuItem value="default">Default sorting</MenuItem>
            <MenuItem value="alphabetically">Sort alphabetically</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
