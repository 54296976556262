import { Box } from "@mui/material";
import AddressesCollection from "./AddressesCollection";
import { useLocation, useNavigate } from "react-router-dom";
import AddressForm from "./AddressForm";
import { useEffect } from "react";

const SavedAddresses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Get the 'form' query parameter
  const params = new URLSearchParams(location.search);
  const layout = params.get("layout") || "address"; // Default to 'personal'
  const validLayouts = ["address", "newAddress", "editAddress"];
  // If 'form' is invalid, redirect to default (personal) form
  useEffect(() => {
    if (!validLayouts.includes(layout)) {
      navigate("/profile?page=addresses&layout=address", { replace: true });
    }
  }, [layout, navigate]);
  
  const handleAddNewAddressBtn = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/profile?page=addresses&layout=newAddress");
  };

  return (
    <Box sx={{height:"100%"}}>
      {layout === "newAddress" || layout === "editAddress" ? (
        <AddressForm />
      ) : (
        <AddressesCollection handleAddNewAddressBtn={handleAddNewAddressBtn} />
      )}
    </Box>
  );
};

export default SavedAddresses;
