import React, { useEffect, useState } from "react";
import LoginForm from "./components/LoginForm";
import ForgetPasswordForm from "./components/ForgetPassword";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const LogIn = () => {
  const [isLoginMode, setIsLoginMode] = useState(true);
  const userData = useSelector((state) => state.appState.userData);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userData &&
      !(Array.isArray(userData) && userData?.length === 0) &&
      typeof userData === "object" &&
      userData?.uid
    ) {
      // Check if user is logged in
      const redirectPath = sessionStorage.getItem("redirectAfterLogin");
      if (redirectPath) {
        navigate(redirectPath);
      } else {
        navigate("/home"); // Default to home if no saved path
      }
    }
  }, [userData, navigate]);

  return (
    <>
      {isLoginMode ? (
        <LoginForm onForgetPasswordClick={setIsLoginMode} />
      ) : (
        <ForgetPasswordForm onLoginClick={setIsLoginMode} />
      )}
    </>
  );
};

export default LogIn;
