import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Pagination,
  Divider,
} from "@mui/material";
import noItems from "../../assets/Images/no-Items.png";
import { useSelector } from "react-redux";
import { db } from "../../configs/firebase";
import ReviewCard from "../sellers/component/ReviewCard";
import { styled } from '@mui/system';
import Ratings from "./components/Ratings";

 
const DashedDivider = styled(Divider)(({ theme }) => ({
  border: `2px dashed ${theme.palette.gray.lightGray}`,
  margin: '20px 0',
}));
const Reviews = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const currentUser = useSelector((state) => state.appState.userData);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState(null);
  const [totalReviews, setTotalReviews] = useState(0);
  const reviewsPerPage = 6;

  // Fetch total number of reviews to calculate pages
  const fetchTotalReviewsCount = async () => {
    const snapshot = await db
      .collection("users")
      .doc(currentUser?.uid)
      .collection("reviews")
      .get();
    setTotalReviews(snapshot.size);
  };

  const fetchReviews = async (pageNumber = 1) => {
    setLoading(true);
    try {
      let reviewsRef = db
        .collection("users")
        .doc(currentUser?.uid)
        .collection("reviews")
        .orderBy("createdAt", "desc")
        .limit(reviewsPerPage);

      if (pageNumber > 1 && lastVisible) {
        // For pages greater than 1, fetch starting after the last visible document
        reviewsRef = reviewsRef.startAfter(lastVisible);
      }

      const snapshot = await reviewsRef.get();
      const fetchedReviews = [];
      snapshot.forEach((doc) => {
        fetchedReviews.push({ id: doc.id, ...doc.data() });
      });

      const lastDoc = snapshot.docs[snapshot.docs.length - 1];
      setLastVisible(lastDoc);

      setReviews(fetchedReviews);
    } catch (err) {
      console.error("Error fetching reviews: ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser?.uid) {
      fetchTotalReviewsCount();
      fetchReviews(1);
    }
  }, [currentUser?.uid]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setLastVisible(null);
    fetchReviews(value);
  };

  return (
    <Box
      sx={{
        padding: isSmallScreen ? "10px" : "20px",
        textAlign: "center",
      }}
    >
      <Typography
        variant={isSmallScreen ? "h6" : "h4"}
        sx={{
          fontWeight: "bold",
          color: theme.palette.primary.main,
          marginBottom: "20px",
          textTransform: "capitalize",
        }}
      >
        my reviews
      </Typography>
      <DashedDivider />
      <Ratings currentUser={currentUser}/>
      <DashedDivider />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : reviews?.length === 0 && !loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: isSmallScreen ? "10px" : "20px",
          }}
        >
          <img
            src={noItems}
            alt="No reviews available"
            loading="lazy"
            style={{
              maxWidth: "100%",
              height: "auto",
              marginBottom: "20px",
            }}
          />
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.secondary,
              fontSize: isSmallScreen ? "14px" : "16px",
            }}
          >
            You don't have any reviews.
          </Typography>
        </Box>
      ) : (
        <>
          {/* Display reviews */}
          <Grid container spacing={2}>
            {reviews.map((review) => (
              <Grid item xs={12} sm={6} md={4} key={review?.id} >
                <ReviewCard review={review} boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)" borderRadius="8px" padding="10px" backgroundColor="white" />
              </Grid>
            ))}
          </Grid>

          {/* Pagination Controls */}
          {totalReviews > reviewsPerPage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Pagination
                count={Math.ceil(totalReviews / reviewsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Reviews;
