import { Button, Stack, Typography, useTheme } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PasswordInput from "./PasswordInput";
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useDispatch } from "react-redux";
import {
  resetFormTouched,
  restUserData,
} from "../../../../redux/slices/appStateSlice";
import { useState } from "react";
import Loader from "../../../../components/common/Loader";
// Validation schema using Yup
const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().trim().required("Current password is required"),
  newPassword: Yup.string()
    .trim()
    .required("new password is required")
    .min(8, "new password must be at least 8 characters")
    .matches(/[A-Z]/, "new password must contain at least one uppercase letter")
    .matches(/[a-z]/, "new password must contain at least one lowercase letter")
    .matches(/[0-9]/, "new password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "new Password must contain at least one special character"
    ),
  reTypeNewPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Re-type new password is required"),
});

const SetPasswordForm = ({ inputStyle, sharedStyles }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const handlePasswordChange = async (values, actions) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const { currentPassword, newPassword } = values;
    if (user) {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      setLoading(true);
      try {
        // Re-authenticate the user with current password
        await reauthenticateWithCredential(user, credential);
        // Update the password
        await updatePassword(user, newPassword);
        actions.setStatus({ success: true });
        actions.setSubmitting(false);
        dispatch(resetFormTouched());
        message.success("Password updated successfully");
        // Log the user out after password change
        await signOut(auth);
        dispatch(restUserData());
        navigate("/login");
      } catch (error) {
        actions.setStatus({ success: false });
        actions.setSubmitting(false);
        actions.setErrors({ currentPassword: "you entered wrong password" });
        console.error("Error updating password:", error);
      } finally {
        setLoading(false);
      }
    } else {
      message.error("you must login first");
      navigate("/login");
    }
  };

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        reTypeNewPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handlePasswordChange}
    >
      {({ isSubmitting, errors, touched, isValid }) => (
        <Form>
          <Stack spacing={3}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "600",
                fontSize: "24px",
                color: theme.palette.primary.lightDoveGrey,
                textTransform: "capitalize",
              }}
            >
              Change Password
            </Typography>
            <Stack spacing={3}>
              <Field
                name="currentPassword"
                label="Current Password"
                id="currentPassword"
                component={PasswordInput}
                inputStyle={inputStyle}
                sharedStyles={sharedStyles}
                error={
                  touched.currentPassword && Boolean(errors.currentPassword)
                }
              />

              <Field
                name="newPassword"
                label="New Password"
                id="newPassword"
                component={PasswordInput}
                inputStyle={inputStyle}
                sharedStyles={sharedStyles}
                error={touched.newPassword && Boolean(errors.newPassword)}
              />

              <Field
                name="reTypeNewPassword"
                label="Re-type New Password"
                id="reTypeNewPassword"
                component={PasswordInput}
                inputStyle={inputStyle}
                sharedStyles={sharedStyles}
                error={
                  touched.reTypeNewPassword && Boolean(errors.reTypeNewPassword)
                }
              />

              <Button
                type="submit"
                variant="contained"
                disabled={!isValid || isSubmitting}
                color="primary"
                sx={{
                  width: "15.625rem",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  paddingY: "16px",
                  paddingX: "32px",
                  borderRadius: "8px",
                }}
              >
                {loading ? <Loader /> : "save changes"}
              </Button>
              {errors.general && (
                <Typography variant="body2" color="error.main">
                  {errors.general}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default SetPasswordForm;
