import React, { useEffect, useState } from "react";
import { Box, useTheme, Tabs, Tab } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AccountInfoForm from "./AccountInfoForm";
import SetPasswordForm from "./SetPasswordForm";
import EditEmailForm from "./EditEmailForm";
import EditBusinessInfo from "./editBusinessInfo/EditBusinessInfo";
import AddBusinessInfo from "./AddBusinessInfo";

const AccountInfo = () => {
  const theme = useTheme();
  const currentUser = useSelector((state) => state.appState.userData);
  const [user, setUser] = useState(currentUser || null);

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // Get the 'form' query parameter
  const params = new URLSearchParams(location.search);
  const form = params.get("form") || "personal"; // Default to 'personal'

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  // Define valid form types
  const validForms = [
    "personal",
    "changePassword",
    "changeEmail",
    "editBusinessInfo",
    "addBusinessInfo",
  ];

  // If 'form' is invalid, redirect to default (personal) form
  useEffect(() => {
    if (!validForms.includes(form)) {
      navigate("/profile?page=info&form=personal", { replace: true });
    }
  }, [form, navigate]);

  const sharedStyles = {
    borderRadius: "8px",
    color: theme.palette.neutral.greyBlue,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: `${theme.palette.neutral.metallicGray} !important`,
        borderRadius: "8px",
      },
    },
  };

  const inputStyle = {
    color: theme.palette.primary.darkColor,
    marginBottom: 1,
    fontWeight: "600",
    textTransform: "capitalize",
  };

  const handleTabChange = (event, newValue) => {
    navigate(`/profile?page=info&form=${newValue}`);
  };

  const handleChangePasswordClick = () => {
    navigate("/profile?page=info&form=changePassword");
  };
  const handleChangeEmailClick = () => {
    navigate("/profile?page=info&form=changeEmail");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to view your account information.</div>;
  }

  // Check if the userType is not 'buyer', and show the tabs if it's not a buyer
  const showTabs = currentUser?.userType !== "buyer";

  return (
    <Box>
      {["personal", "editBusinessInfo", "addBusinessInfo"].includes(form) && (
        <Tabs
          value={form}
          onChange={handleTabChange}
          sx={{ marginBottom: "20px" }}
          aria-label="Account Info Tabs"
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
        >
          <Tab label="Personal Info" value="personal" />
          {currentUser?.userType !== "buyer" && (
            <Tab label="Business Info" value="editBusinessInfo" />
          )}
          {currentUser?.userType === "buyer" && (
            <Tab label="Business Info" value="addBusinessInfo" />
          )}
        </Tabs>
      )}

      {/* Render the form based on the current tab */}
      {form === "personal" && (
        <AccountInfoForm
          user={user}
          currentUser={currentUser}
          inputStyle={inputStyle}
          sharedStyles={sharedStyles}
          handleChangePasswordClick={handleChangePasswordClick}
          handleChangeEmailClick={handleChangeEmailClick}
        />
      )}
      {form === "changePassword" && (
        <SetPasswordForm inputStyle={inputStyle} sharedStyles={sharedStyles} />
      )}
      {form === "changeEmail" && (
        <EditEmailForm
          currentUser={currentUser}
          inputStyle={inputStyle}
          sharedStyles={sharedStyles}
        />
      )}
      {form === "editBusinessInfo" && <EditBusinessInfo />}
      {form === "addBusinessInfo" && <AddBusinessInfo />}
    </Box>
  );
};

export default AccountInfo;
