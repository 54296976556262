import { MdArrowBackIosNew } from "react-icons/md";
import { Box, Button, useTheme } from "@mui/material";

const BackButton = ({handleLoginClick}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={() => handleLoginClick()}
      startIcon={
        <Box
          sx={{
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MdArrowBackIosNew />
        </Box>
      }
      sx={{
        width: "4.188rem",
        height: "24px",
        color: theme.palette.primary.black,
        "&:hover": {
          backgroundColor: "transparent", // Remove the background on hover
          boxShadow: "none", // Remove any shadow on hover
        },
        boxShadow: "none", // Remove default button shadow
      }}
    >
      back
    </Button>
  );
};

export default BackButton;
