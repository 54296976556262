import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import box from "../../../../assets/Images/nothing_here.png";
import { useEffect, useState } from "react";
import { IoWalletOutline } from "react-icons/io5";

import { useSelector } from "react-redux";
import { createLinkAccount } from "./createLinkAccount";
import { message } from "antd";
import { collection, orderBy, getDocs, query } from "firebase/firestore";
import { db } from "../../../../configs/firebase";
import TransactionsTableRow from "./components/TransactionsTableRow";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../../components/common/Loader";
import { createStripePayout } from "./components/createStripePayout";

const TransactionsHistory = () => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = searchParams.get("tab") || "buyer" ;
  const [activeTab, setActiveTab] = useState(defaultTab);
  const currentUser = useSelector((state) => state.appState.userData);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

const fetchOrders = async () => {
  if (!currentUser?.uid) return; // Ensure user data is available

  setOrdersLoading(true);
  try {
    // Select the correct subcollection name
    const subcollectionName =
      activeTab === "seller" ? "userPayouts" : "userTransactions";

    // Directly reference the subcollection (skipping the parent document check)
    const subcollectionRef = collection(
      db,
      `stripeUsers/${currentUser.uid}/${subcollectionName}`
    );

    // Query the subcollection
    const subcollectionQuery = query(
      subcollectionRef,
      orderBy("createdAt", "desc")
    );
    const subcollectionSnapshot = await getDocs(subcollectionQuery);

    const fetchedOrders = subcollectionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setOrders(fetchedOrders);

     // If no tab is selected, determine it based on user role
     if (!activeTab) {
      const isBuyer = fetchedOrders.some((order) => order.buyer_id === currentUser.uid);
      const isSeller = fetchedOrders.some((order) => order.seller_id === currentUser.uid);

      if (isBuyer) {
        setActiveTab("buyer");
      } else if (isSeller) {
        setActiveTab("seller");
      } 
    }

  } catch (error) {
    console.error("Error fetching orders:", error);
    setOrders([]);
  } finally {
    setOrdersLoading(false);
  }
};

  
  

  const handleCollectMoney = async (payoutId, uid) => {
    setIsLoading(true);
    try {
      const response = await createStripePayout(payoutId, uid);
      if (response?.isSuccess) {
        handleOpenSuccessDialog();
        await fetchOrders();
      }
    } catch (error) {
      message.error(
        "You have insufficient funds in your Stripe account for this transfer."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [activeTab, currentUser?.uid]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
   
    setSearchParams({ page: "transactions-history", tab });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleOpenSuccessDialog = () => {
    setIsSuccessDialogOpen(true);
  };

  // if (!ordersLoading && !orders.length) {
  //   return (
  //     <Stack
  //       alignItems="center"
  //       justifyContent="center"
  //       height="100%"
  //       sx={{
  //         py: 6,
  //         backgroundColor: theme.palette.primary.light,
  //         borderRadius: "16px",
  //       }}
  //     >
  //       <img src={box} alt="empty box" />
  //       <Typography variant="body1">No orders found.</Typography>
  //     </Stack>
  //   );
  // }

  return (
    
    <Stack gap={2}>
      {/* Toggle between seller and buyer */}
      
      <Box
        sx={{
          width: "223px",
          height: "52px",
          fontSize: "1.5rem",
          color: theme.palette.dark.charcoalBlack,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: theme.palette.primary.light,
          borderRadius: "40px",
          gap: "7px",
        }}
      >
       <Button
          onClick={() => handleTabChange("buyer")}
          sx={{
            backgroundColor:
              activeTab === "buyer"
                ? theme.palette.primary.main
                : "transparent",
            color:
              activeTab === "buyer"
                ? theme.palette.primary.light
                : theme.palette.primary.ToggleBlack,
            fontWeight: 500,
            padding: "6px 25px",
            textTransform: "capitalize",
            borderRadius: "40px",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.light,
              borderRadius: "40px",
            },
          }}
        >
          buyer
        </Button>
        <Button
          onClick={() => handleTabChange("seller")}
          sx={{
            backgroundColor:
              activeTab === "seller"
                ? theme.palette.primary.main
                : "transparent",
            color:
              activeTab === "seller"
                ? theme.palette.primary.light
                : theme.palette.primary.ToggleBlack,
            fontWeight: 500,
            padding: "6px 25px",
            textTransform: "capitalize",
            borderRadius: "40px",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.light,
              borderRadius: "40px",
            },
          }}
        >
          seller
        </Button>
       
      </Box>
      {ordersLoading ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          minHeight={"30vh"}
          sx={{
            backgroundColor: theme.palette.primary.light,
            borderRadius: "16px",
          }}
        >
          <Loader />
        </Stack>
      ) : (
        <>
         
          {!ordersLoading && orders?.length === 0 ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                py: 6,
                backgroundColor: theme.palette.primary.light,
                borderRadius: "16px",
              }}
            >
              <img
                src={box}
                alt="No orders"
                width={{
                  xs: "100px",
                  sm: "150px",
                }}
                height={{ xs: "100px", sm: "150px" }}
              />
              <Typography
                variant="h6"
                sx={{
                  mt: 2,
                  textAlign: "center",
                  fontSize: {
                    xs: "0.8rem",
                    sm: "1.2rem",
                  },
                  padding: { xs: "0 20px", sm: "0 20px" },
                }}
              >
                {activeTab === "buyer"
                  ? "You have no transactions as a buyer."
                  : "You have no transactions as a seller."}
              </Typography>
            </Stack>
          ) : (
            <Box
              sx={{
                boxShadow: `0px 0px 76px 0px ${theme.palette.customShadows.darkLight}`,
              }}
            >
              <TableContainer
                sx={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <Table
                  sx={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <TableHead
                    sx={{
                      backgroundColor: theme.palette.neutral.background,
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: theme.palette.primary.textBlueBlack }}
                      >
                        Order ID
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.palette.primary.textBlueBlack }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.palette.primary.textBlueBlack }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{ color: theme.palette.primary.textBlueBlack }}
                      >
                        {activeTab === "buyer" ? "Seller Name" : "Buyer Name"}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: theme.palette.primary.textBlueBlack,
                          textAlign:
                            activeTab === "seller" ? "center" : "start",
                        }}
                      >
                        Total
                      </TableCell>
                      {/* <TableCell
                        sx={{ color: theme.palette.primary.textBlueBlack }}
                      >
                        Actions
                      </TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody
                    sx={{ backgroundColor: theme.palette.primary.light }}
                  >

 {/* money box */}
          {/* {!ordersLoading && activeTab === "seller" && (
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "space-between" }}
              sx={{
                backgroundColor: theme.palette.primary.lightPurple,
                boxShadow: `0px 0px 76px 0px ${theme.palette.customShadows.darkLight}`,
                borderRadius: "16px",
                border: `1px dashed ${theme.palette.primary.purple}`,
                padding: { xs: "15px 20px", sm: "20px 30px" },
                gap: { xs: 2, sm: 0 },
              }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                justifyContent="center"
                gap={{ xs: 1, sm: 2 }}
              >
                <Typography
                  variant="body1"
                  textAlign={{ xs: "center", sm: "left" }}
                >
                  Entitled Funds
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: "18px", sm: "20px" },
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  $200
                </Typography>
              </Stack>
              <Button
                onClick={handleCollectMoney}
                disabled={isLoading}
                startIcon={
                  isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <IoWalletOutline />
                  )
                }
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.main,
                  fontWeight: 500,
                  padding: { xs: "6px 15px", sm: "6px 25px" },
                  textTransform: "capitalize",
                  borderRadius: "40px",
                  transition: "all 0.3s ease-in-out",
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                    borderRadius: "40px",
                  },
                }}
              >
                {isLoading ? "processing..." : "collect money"}
              </Button>
            </Stack>
          )} */}

                    {/* {buyerTransactions.map((transaction, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      color: theme.palette.neutral.MoustacheGrey,
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {transaction.orderId}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.neutral.MoustacheGrey,
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {transaction.date}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.neutral.MoustacheGrey,
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    <StatusDisplay status={transaction?.status} />
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.neutral.MoustacheGrey,
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {transaction.buyerName}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.neutral.MoustacheGrey,
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {transaction.total}
                  </TableCell>
                  <TableCell>
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <AiFillBank />
                      <i
                        style={{
                          fontSize: "16px",
                          color: "#000",
                        }}
                        className="fas fa-university"
                      />
                      •••• {transaction.paymentMethod}
                    </span>
                  </TableCell>
                </TableRow>

              ))} */}
                    {orders
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order) => (
                        <TransactionsTableRow
                          handleCollectMoney={handleCollectMoney}
                          order={order}
                          key={order?.id}
                          theme={theme}
                          activeTab={activeTab}
                          ordersLoading={ordersLoading}
                          isSuccessDialogOpen={isSuccessDialogOpen}
                          setIsSuccessDialogOpen={setIsSuccessDialogOpen}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={orders?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: "0px 0px 10px 10px",
                }}
              />
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default TransactionsHistory;
