import { Box, Stack, Typography, IconButton } from "@mui/material";
import { IoRemove, IoAdd } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setCurrentProduct,
} from "../../../redux/slices/appStateSlice";
import { useEffect, useState } from "react";
import { db } from "../../../configs/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { message } from "antd";
const ProductItem = ({
  product,
  theme,
  handleQuantityIncrement,
  handleQuantityDecrement,
  handleRemoveFromCart,
  allProducts,
  isLastItem,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Find the product from allProducts to check its available quantity
  const productInAllProducts = allProducts?.find((p) => p?.id === product?.id);
  const isMaxQuantity =
    productInAllProducts && product.quantity === productInAllProducts?.quantity;
    
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const [productDetails, setProductDetails] = useState(null);
  const [categoryName, setCategoryName] = useState(null);

  const getCategoryName = (categoryId) => {
    const category = itemsCategories?.find((cat) => cat?.id === categoryId);
    return category ? category?.name : "Unknown Category";
  };

  useEffect(() => {
    if (productDetails) {
      const name = getCategoryName(productDetails.categoryId);
      setCategoryName(name);
    }
  }, [productDetails]);

  const goToProductPage = (id) => {
    if (productDetails?.isDeleted || productDetails?.isSold || productDetails?.isDraft) {
      message.warning("This product is not available to view.");
      return;
    }
    if (categoryName) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch(setCurrentProduct(productDetails));
      navigate(`/product/${id}`, { state: { nameToDisplay: categoryName } });
    }
  };
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productDocRef = doc(db, "products", product?.id);
        const productDoc = await getDoc(productDocRef);
        if (productDoc.exists()) {
          setProductDetails(productDoc.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product details: ", error);
      }
    };

    fetchProductDetails();
  }, [product?.id]);

  const isOutOfStock =
  productDetails?.isDraft ||
  productDetails?.isSold ||
  productDetails?.isDeleted ||
  (productDetails?.contactForPrice && productDetails?.price === null) ||
  !productDetails?.isActive;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      key={product?.id}
      sx={{
        borderBottom: !isLastItem
          ? `1px solid ${theme.palette.neutral.grayBorder}`
          : "none",
        paddingBottom: "10px",
        marginBottom: "10px",
        width: "100%",
      }}
    >
      <Stack direction="row" alignItems="center" gap={2} sx={{ width: "100%" }}>
        <img
          src={product?.image}
          alt={product?.title}
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "8px",
            objectFit: "cover",
            maxWidth: "100%",
            cursor: "pointer",
          }}
          onClick={() => goToProductPage(product?.id)}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "74%",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.primary.darkBlack,
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={() => goToProductPage(product?.id)}
            >
              {product?.title}
            </Typography>
            <IoIosCloseCircleOutline
              onClick={() => handleRemoveFromCart(product?.id)}
              size="24px"
              style={{ cursor: "pointer" }}
            />
          </Stack>

        
          {productDetails?.contactForPrice && productDetails?.price === null ? (
            <Typography
              variant="caption"
              sx={{ color: "orange", fontWeight: "500" }}
            >
              Please contact the seller
            </Typography>
          ) : isOutOfStock ? (
            <Typography
              variant="caption"
              sx={{ color: "red", fontWeight: "500" }}
            >
              Out of Stock
            </Typography>
          ) : null}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{ color: theme.palette.primary.main, fontWeight: "700" }}
              variant="body1"
            >
              ${product?.price?.toFixed(2)}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                borderRadius: "10.625rem",
                padding: "8px",
                border: `1px solid ${theme.palette.neutral.lightGrayBorder}`,
                width: "6.5rem",
                height: "40px",
              }}
            >
              <IconButton
                onClick={() => handleQuantityDecrement(product?.id)}
                disabled={product?.quantity === 1}
                sx={{
                  backgroundColor:
                    product?.quantity !== 1
                      ? theme.palette.primary.main
                      : theme.palette.primary.light,
                  width: "24px",
                  height: "24px",
                  border:
                    product?.quantity !== 1
                      ? `1px solid ${theme.palette.primary.main}`
                      : `1px solid ${theme.palette.primary.lightGray}`,
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor:
                      product?.quantity !== 1
                        ? theme.palette.primary.main
                        : theme.palette.primary.lightGray,
                    color:
                      product?.quantity !== 1
                        ? theme.palette.primary.light
                        : theme.palette.primary.darkBlack,
                  },
                }}
                size="small"
              >
                <IoRemove />
              </IconButton>
              <Typography variant="body2">{product?.quantity}</Typography>
              <IconButton
                onClick={() => handleQuantityIncrement(product?.id)}
                disabled={
                  productInAllProducts &&
                  product?.quantity === productInAllProducts.quantity
                } // Disable when quantity equals available stock
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  width: "24px",
                  height: "24px",
                  border:
                    productInAllProducts &&
                    product?.quantity === productInAllProducts.quantity
                      ? `1px solid ${theme.palette.primary.lightGray}`
                      : `1px solid ${theme.palette.primary.main}`,
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                  },
                }}
                size="small"
              >
                <IoAdd />
              </IconButton>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProductItem;
