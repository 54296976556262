import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Summary = ({
  sectionStyles,
  rowStyle,
  theme,
  headerTextStyles,
  order,
}) => {
  const textStyles = {
    label: {
      color: theme.palette.neutral.SlateGray,
      textTransform: "capitalize",
    },
    value: {
      color: theme.palette.neutral.greyBlue,
      fontSize: "14px",
    },
    totalLabel: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textTransform: "capitalize",
    },
    totalValue: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: "16px",
    },
    subtotal: {
      fontWeight: 600,
      fontSize: "16px",
    },
  };
  const navigate = useNavigate();

  return (
    <Stack gap={3} sx={{ ...sectionStyles }}>
      <Typography
        variant="body1"
        sx={{ ...headerTextStyles, fontWeight: "600", fontSize: "18px" }}
      >
        summary
      </Typography>
      {order && (
        <>
          <Stack gap={1}>
            {/* Subtotal */}
            {order?.subtotal !== undefined && (
              <Stack direction="row" sx={rowStyle}>
                <Typography variant="body1" sx={textStyles.label}>
                  subtotal:
                </Typography>
                <Box
                  component="span"
                  sx={{ ...textStyles.subtotal, color: textStyles.label.color }}
                >
                  $
                  {Number.isInteger(order?.subtotal)
                    ? `${order.subtotal}.00`
                    : order?.subtotal?.toFixed(2) || "0.00"}
                </Box>
              </Stack>
            )}

            {/* Shipping Cost */}
            {order?.total_shipping_cost !== undefined && (
              <Stack direction="row" sx={rowStyle}>
                <Typography variant="body2" sx={textStyles.label}>
                  shipping cost:
                </Typography>
                <Box component="span" sx={textStyles.value}>
                  {order?.total_shipping_cost > 0 && "$"}
                  {order?.total_shipping_cost === 0
                    ? "Free"
                    : order?.total_shipping_cost?.toFixed(2)}
                </Box>
              </Stack>
            )}

            {/* Total Price */}
            {order?.total !== undefined && (
              <Stack direction="row" sx={rowStyle}>
                <Typography variant="body1" sx={textStyles.totalLabel}>
                  total price:
                </Typography>
                <Box component="span" sx={textStyles.totalValue}>
                  ${order?.total?.toFixed(2)}
                </Box>
              </Stack>
            )}
          </Stack>

          {/* Track Order Button */}
          {order?.status !== "cancelled" && (
            <Button
              onClick={() =>
                navigate(`/trackOrder/${order?.id}`, {
                  state: { scrollToRequests: true },
                })
              }
              variant="contained"
              sx={{
                padding: "16px 32px",
                borderRadius: "8px",
                height: "48px",
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              track order
            </Button>
          )}
        </>
      )}
      {!order && (
        <Typography variant="body1">there is no summary data</Typography>
      )}
    </Stack>
  );
};

export default Summary;
