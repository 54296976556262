import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import Summary from "./components/Summary";
import Address from "./components/Address";
import TableHeader from "./components/TableHeader";
import TableRowItem from "./components/TableRowItem";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../configs/firebase";
import Loader from "../../components/common/Loader";
import { message, Modal } from "antd";
import { useSelector } from "react-redux";

const OrderDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [orderLoading, setOrderLoading] = useState(false);
  const user = useSelector((state) => state.appState.userData);

  const handleCancelOrder = async () => {
    try {
      const docRef = doc(db, "orders", orderId);
      // Update the status field to 'cancelled'
      await updateDoc(docRef, {
        status: "cancelled",
      });
      // Update local state to reflect the changes
      setOrder((prevOrder) => ({
        ...prevOrder,
        status: "cancelled",
      }));
      message.success("Order cancelled successfully");
      navigate(-1);
    } catch (error) {
      message.error(`Error cancelling order: ${error.message}`);
    }
  };


  useEffect(() => {
    const fetchOrder = async () => {
      if (orderId) {
        setOrderLoading(true);
        try {
          const orderDoc = doc(db, "orders", orderId);
          const orderSnapshot = await getDoc(orderDoc);
          if (orderSnapshot.exists()) {
            
         
            setOrder({ id: orderSnapshot.id, ...orderSnapshot.data() });
          } else {
            console.error("No such document!");
          }
        } catch (error) {
          console.error("Error fetching order: ", error);
        } finally {
          setOrderLoading(false);
        }
      }
    };

    fetchOrder();
  }, [navigate, orderId, user?.uid]);

  const headerTextStyles = {
    color: theme.palette.primary.lightDoveGrey,
    textTransform: "capitalize",
  };
  const bodyTextStyles = {
    color: theme.palette.neutral.SlateGray,
    fontWeight: "600",
    textTransform: "capitalize",
  };
  const sectionStyles = {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "8px",
    padding: "32px 16px",
    boxShadow: `0px 0px 76px 0px ${theme.palette.customShadows.darkLight}`,
  };
  const rowStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 1,
  };

  if (orderLoading) {
    return <Loader height="100vh" />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        padding: {
          xs: "16px",
          sm: "20px 60px",
        },
        backgroundColor: theme.palette.primary.background,
        minHeight: "100vh",
      }}
    >
      {/* Table Section */}
      <Box>
        <Box
          sx={{
            marginBottom: "10px",
            borderTopRightRadius: "8px",
            borderTopLeftRadius: "8px",
            backgroundColor: theme.palette.neutral.lightPaleGray,
            padding: "10px 24px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                variant="body1"
                sx={{ ...headerTextStyles, fontWeight: "700" }}
              >
                order
              </Typography>
              <Typography variant="body1" sx={headerTextStyles}>
                #{order?.order_no}
              </Typography>
            </Stack>

            {order?.status === "cancelled" && (
              <Typography
                variant="body2"
                sx={{
                  ...headerTextStyles,
                  color: theme.palette.status.darkRed,
                }}
              >
                order cancelled
              </Typography>
            )}

            {order?.status === "completed" && (
              <Typography
                variant="body2"
                sx={{ ...headerTextStyles, color: theme.palette.status.green }}
              >
                order delivered
              </Typography>
            )}
            {order?.status === "shipped" && (
              <Typography
                variant="body2"
                sx={{ ...headerTextStyles, color: theme.palette.status.green }}
              >
                order is shipped
              </Typography>
            )}
          </Stack>
        </Box>

        <TableContainer
          component="div"
          sx={{
            borderRadius: "4px",
            border: `1px solid ${theme.palette.primary.lightGray}`,
            boxShadow: `0px 0px 76px 0px ${theme.palette.customShadows.darkLight}`,
            padding: {
              xs: "0",
              sm: "30px 60px",
            },
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <Table aria-label="order table">
            {/* Table Header */}
            <TableHeader headerTextStyles={headerTextStyles} />
            <TableBody>
              {!order && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography component="span" variant="body1">
                      there is no order data
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {order &&
                order?.order_items?.map((orderItem, index) => (
                  <TableRowItem
                    bodyTextStyles={bodyTextStyles}
                    key={orderItem?.id}
                    order={orderItem}
                    orders={order}
                    setOrder={setOrder}
                    currentUser={user}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Address and Summary Section */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "1fr 1fr",
          },
          gap: "12px",
        }}
      >
        {/* Address Section */}
        <Address
          theme={theme}
          sectionStyles={sectionStyles}
          headerTextStyles={headerTextStyles}
          order={order}
          setOrder={setOrder}
          
        />
        {/* Summary Section */}
        <Summary
          theme={theme}
          rowStyle={rowStyle}
          sectionStyles={sectionStyles}
          order={order}
          headerTextStyles={headerTextStyles}
        />
      </Box>
    </Box>
  );
};

export default OrderDetails;
