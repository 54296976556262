import React, { useEffect, useState } from "react";
import {
  SwipeableDrawer,
  Button,
  Typography,
  Box,
  Stack,
  useTheme,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { db } from "../../configs/firebase";
import ProductItem from "../DrawerCart/components/ProductItem";
import { message } from "antd";
import { setCartProducts } from "../../redux/slices/appStateSlice";
import nothingHere from "../../assets/Images/nothing_here.png";
import {
  handleQuantityIncrement,
  handleQuantityDecrement,
  handleRemoveFromCart,
} from "../../utils/cartUtils";
import Loader from "../../components/common/Loader";
const SwipeableCartDrawer = ({ open, toggleDrawer }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.appState.userData);

  const [allProductsData, setAllProductsData] = useState([]);
  const [isLoadingAllProducts, setIsLoadingAllProducts] = useState(false);
  const cartProducts = useSelector((state) => state.appState.cartProducts);
  const { data, loading, error } = useSelector(
    (state) => state.appState.checkout
  );

  const fetchProductsData = async (cartItems) => {
    setIsLoadingAllProducts(true);
    try {
      const productIds = cartItems?.map((item) => item?.id);
      const productsRef = db
        .collection("products")
        .where("isDeleted", "==", false);
      // Fetch data once using getDocs
      const querySnapshot = await productsRef
        .where("id", "in", productIds)
        .get();

      let fetchedProducts = [];
      querySnapshot.forEach((doc) => {
        fetchedProducts.push(doc.data());
      });

      setAllProductsData(fetchedProducts);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoadingAllProducts(false);
    }
  };
  useEffect(() => {
    if (cartProducts?.length > 0) {
      fetchProductsData(cartProducts);
    }
  }, [cartProducts]);

  useEffect(() => {
    if (currentUser) {
      dispatch(setCartProducts(currentUser?.cart?.items));
    }
  }, [currentUser]);



  const validateCartProducts = async (cartItems) => {
    try {
      const productIds = cartItems.map((item) => item.id);
      const productsRef = db.collection("products");
      const querySnapshot = await productsRef.where("id", "in", productIds).get();
  
      let invalidProducts = false;
  
      querySnapshot.forEach((doc) => {
        const productData = doc.data();
  
        if (
          !productData?.isActive ||
          productData?.isDraft || 
          productData?.isSold || 
          productData?.isDeleted ||
          (productData.contactForPrice) 
        ) {
          invalidProducts = true; 
        }
      });
  
      if (invalidProducts) {
        message.error(
          "Some products in your cart are no longer valid. Please review your cart and remove invalid items."
        );
      }
  
      return invalidProducts;
    } catch (error) {
      console.error("Error validating cart products:", error);
      message.error("Failed to validate products. Please try again later.");
      return true;
    }
  };
  

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{
          "& .MuiDrawer-paper": {
            width: {
              xs: "100%",
              sm: "29.688rem",
            },
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: 16 }}
        >
          <Typography
            variant="h6"
            sx={{
              textTransform: "capitalize",
              color: theme.palette.dark.heading,
            }}
          >
            Shopping Cart{" "}
            {cartProducts?.length >= 1 && <span>({cartProducts?.length})</span>}
          </Typography>
          <IoMdClose
            size="24px"
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer(false)}
          />
        </Stack>
        {cartProducts?.length > 0 ? (
          <Stack
            style={{ padding: 16 }}
            role="presentation"
            justifyContent="space-between"
            height="100%"
          >
            <Box>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                {cartProducts?.map((product, index) => (
                  <ProductItem
                    key={product?.id}
                    allProducts={allProductsData}
                    product={product}
                    theme={theme}
                    handleQuantityIncrement={() =>
                      handleQuantityIncrement(
                        product?.id,
                        allProductsData,
                        cartProducts,
                        currentUser,
                        dispatch
                      )
                    }
                    handleQuantityDecrement={() =>
                      handleQuantityDecrement(
                        product?.id,
                        cartProducts,
                        currentUser,
                        dispatch
                      )
                    }
                    handleRemoveFromCart={() =>
                      handleRemoveFromCart(
                        product?.id,
                        cartProducts,
                        currentUser,
                        dispatch
                      )
                    }
                    isLastItem={index === cartProducts?.length - 1}
                  />
                ))}
              </Box>
            </Box>
            <Stack
              gap={2}
              sx={{
                width: {
                  xs: "100%",
                  sm: "23.5rem",
                },
                marginX: "auto",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.dark.heading }}
                >
                  {cartProducts?.length} products
                </Typography>
                {loading ? (
                  <Loader />
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.dark.heading,
                      fontWeight: "600",
                    }}
                  >
                    ${data?.isSuccess && data?.data?.total?.toFixed(2)}
                  </Typography>
                )}
              </Stack>

             
              <Button
                onClick={async () => {
                  if (
                    !currentUser?.cart?.items?.length >= 1 &&
                    cartProducts?.length >= 1
                  ) {
                    message.warning("you have to put items on the card");
                    return;
                  }
                  const hasInvalidProducts = await validateCartProducts(cartProducts);
                  if (hasInvalidProducts) {
                    return;
                  }
                  navigate("/cart");
                  toggleDrawer(false)();
                }}
                sx={{
                  borderRadius: "43px",
                  padding: {
                    xs: "12px 20px",
                    sm: "16px 40px",
                  },
                  backgroundColor: theme.palette.primary.lightSky,
                  color: theme.palette.primary.main,
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.lightSky,
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {/* go to cart */}
                proceed to checkout
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack
            style={{ width: "29.688rem", padding: 16 }}
            role="presentation"
            justifyContent="center"
            alignItems="center"
            height="100%"
            gap={2}
          >
            <img alt="nothing here" src={nothingHere} />
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: 700,
                color: theme.palette.primary.darkColor,
              }}
            >
              Your cart is empty
            </Typography>

            <Button
              sx={{
                paddingX: "32px",
                color: theme.palette.primary.light,
                paddingY: "10px",
                textTransform: "capitalize",
                fontWeight: "700",
                fontSize: "14px",
                letterSpacing: "calc(1.2% * 16px)",
                borderRadius: "32px",
                backgroundColor: theme.palette.primary.main,

                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                },
              }}
              variant="outlined"
              onClick={() => navigate("/categories")}
            >
              continue Shopping
            </Button>
          </Stack>
        )}
      </SwipeableDrawer>
    </div>
  );
};

export default SwipeableCartDrawer;
