// src/utils/saveDraftProduct.js
import { message } from "antd";
import { db } from "../configs/firebase";

const saveDraftProduct = async ({ userData, formAddItemData, values }) => {
  try {
    if (!userData?.uid) {
      console.error("User not logged in.");
      return;
    }

    if (values === false) {
      // Clear the draftProduct if values are false
      await db
        .collection("users")
        .doc(userData?.uid)
        .set({ draftProduct: null }, { merge: true });
      
      return;
    } else {
      // Build the draftProduct from the provided values or fallback to formAddItemData
      const draftProduct = {
        categoryId: values?.categoryId || formAddItemData?.categoryId || null,
        carsList: values?.carsList || formAddItemData?.carsList || [],
        title: values?.title || formAddItemData?.title || "",
        description: values?.description || formAddItemData?.description || "",
        condition: values?.condition || formAddItemData?.condition || null,
        images: values?.images || formAddItemData?.images || null,
        video: values?.video || formAddItemData?.video || null,
        price: values?.price || formAddItemData?.price || null,
        contactForPrice:
          values?.contactForPrice || formAddItemData?.contactForPrice || false,
        quantity: values?.quantity || formAddItemData?.quantity || 1,
        warranty: values?.warranty || formAddItemData?.warranty || "",
        weight: values?.weight || formAddItemData?.weight || "",
        dimentions: values?.dimentions || formAddItemData?.dimentions || "",
        isLocalPickup:
          values?.isLocalPickup || formAddItemData?.isLocalPickup || false,
        isOnline: values?.isOnline || formAddItemData?.isOnline || false,
        companies: values?.companies || formAddItemData?.companies || [],
        shippingAddress: userData?.businessInfo?.address,
      };

      await db
        .collection("users")
        .doc(userData?.uid)
        .set({ draftProduct }, { merge: true });
    
    }
  } catch (error) {
    console.error("Error saving draft product:", error);
    message.error("Failed to save draft. Please try again.");
  }
};

export default saveDraftProduct;
