import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  ListItem,
  useTheme,
  useMediaQuery,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import RequestComponent from "../../components/common/Request/RequestComponent";
import Sidebar from "../../components/common/Request/Sidebar";
import {
  setItemsCategories,
  setRequests,
  setRequestTab,
  setServicesCategories,
} from "../../redux/slices/appStateSlice";
import { db } from "../../configs/firebase";
import Loader from "../../components/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import {
  Algolia_App_Id,
  Algolia_Search_Api_Key,
} from "../../components/layout/constants";
import algoliasearch from "algoliasearch/lite";
import { FiSearch } from "react-icons/fi";

const RequestsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const displayedCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const requestTab = useSelector((state) => state.appState.requestTab);
  const [categories, setCategories] = useState(
    displayedCategories.length > 0 ? displayedCategories : []
  );

  const displayedServices = useSelector(
    (state) => state.appState.servicesCategories
  );
  const [services, setServices] = useState(
    displayedServices.length > 0 ? displayedServices : []
  );

  const isFullscreen = useSelector((state) => state.appState.isFullScreen);

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedId, setSelected] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrderControle, setSortOrderControle] = useState("default");
  const [userLocation, setUserLocation] = useState({ lat: null, lng: null });

  useEffect(() => {
    if (displayedCategories?.length === 0) {
      getCategories();
    } else {
      setCategories(displayedCategories);
    }
  }, [displayedCategories]);

  const getCategories = async () => {
    try {
      const docs = db.collection("itemCategories").orderBy("index", "asc");

      let fetchedCategories = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedCategories.push(doc.data());
        });

        if (fetchedCategories.length > 0) {
          setCategories(fetchedCategories);
          dispatch(setItemsCategories(fetchedCategories));
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (displayedServices.length === 0) {
      getServiceCategory();
    } else {
      setServices(displayedServices);
    }
  }, [displayedServices]);

  const getServiceCategory = async () => {
    setIsLoading(true);
    try {
      const docs = db.collection("servicesCategories").orderBy("index", "asc");

      let serviceCategory = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          serviceCategory.push(doc.data());
        });

        if (serviceCategory.length > 0) {
          setServices(serviceCategory);
          dispatch(setServicesCategories(serviceCategory));
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching services:", error);
      setIsLoading(false);
    }
  };

  const handleCategorySelect = (index) => {
    const selectedCategory = categories[index];
    setSelectedCategoryIndex(index);

    goToCategoryOrServicePage(
      selectedCategory.id,
      "category",
      selectedCategory.name
    );
  };

  const handleServiceSelect = (index) => {
    const selectedService = services[index];
    setSelectedServiceIndex(index);
    goToCategoryOrServicePage(
      selectedService.id,
      "service",
      selectedService.name
    );
  };

  const goToCategoryOrServicePage = (id, type, name) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (type === "category") {
      navigate(`/filtered-categories?category-Id=${id}&category-name=${name}`);
    } else if (type === "service") {
      navigate(`/filtered-services?service-Id=${id}&service-name=${name}`);
    }
  };
  const [limitRequests, setLimitRequests] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (requestTab) {
      getRequests();
    } else {
      dispatch(setRequestTab(true));
    }
  }, [currentPage, limitRequests, selectedId]);

  const getRequests = async () => {
    setIsLoading(true);

    try {
      const client = algoliasearch(Algolia_App_Id, Algolia_Search_Api_Key);
      const algoliaQuery = {
        hitsPerPage: limitRequests,
        page: currentPage - 1,
        filters:
          selectedId === 0
            ? `isDeleted=0`
            : `categoryId:${selectedId}  AND isDeleted=0`,
      };

      const { hits, nbPages } = await client
        .initIndex("requests")
        .search("", algoliaQuery);
      setTotalPages(nbPages);
      dispatch(setRequests([...hits]));

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching requests from Algolia:", error);
      message.error("Error fetching requests from Algolia please try again.");
      setIsLoading(false);
    }
  };
  const handleMainCategorySelect = (index, categoryId, categoryName) => {
    setSelectedIndex(index);
    setSelected(categoryId);
    dispatch(setRequestTab(true));

    navigate(`/requests?category=${categoryName}`);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.trim();
    if (searchTerm === "") {
      setSearchTerm("");
    } else if (event.key === "Enter" && searchTerm) {
      setSearchTerm(searchTerm);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    const categoryFromUrl = queryParams?.get("category") || "all";

    if (categoryFromUrl === "all") {
      setSelectedIndex(0);
      setSelected(0);
    } else {
      const categoryIndex = categories?.findIndex(
        (category) =>
          category?.name?.toLowerCase() === categoryFromUrl?.toLowerCase()
      );
      if (categoryIndex !== -1) {
        setSelectedIndex(categoryIndex + 1);
        setSelected(categories[categoryIndex]?.id);
      }
    }
  }, [location?.search, categories, setSelectedIndex, setSelected]);

  const handleSortOrderChange = (value) => {
    setSortOrderControle(value);
  };

  const handleOpenModal = () => {
    if (navigator?.permissions?.query) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result?.state === "granted") {
            handleRequestLocation();
          } else if (result?.state === "prompt" || result?.state === "denied") {
            message.error(
              "Access to location denied. Please enable location access in your browser settings and try again."
            );
          }
        })
        .catch((error) => {
          console.error("Permission query error:", error);
        });
    } else {
      console.warn(
        "Permissions API is not supported, falling back to geolocation request."
      );
      handleRequestLocation();
    }
  };

  const handleRequestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          if (localStorage.getItem("locationPermission") !== "granted") {
            localStorage.setItem("locationPermission", "granted");
          }
        },
        (error) => {
          if (error.code === 1) {
            message.error(
              "Access to location denied. Please enable location access in your browser settings."
            );
          } else {
            console.error("Error fetching location:", error);
            message.error("Failed to retrieve location. Please try again.");
          }
        }
      );
    } else {
      message.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const locationPermission = localStorage.getItem("locationPermission");
    if (locationPermission === "granted") {
      handleRequestLocation();
    } else {
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          if (result?.state === "granted") {
            handleRequestLocation();
          }
        });
      } else {
        // Fallback logic if permissions API is not available
        message.error("Geolocation is not supported in this browser.");
      }
    }
  }, []);

  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <Box sx={{ px: 2 }}>
      <Box
        sx={{
          textAlign: "center",
          padding: 2,
          background: theme.palette.primary.light,
          marginBottom: "7rem",
          borderRadius: "15px",
          overflow: "hidden",
          position: "relative",

          height: { xs: "355px", sm: "275px", md: "275px" },
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              lineHeight: { sm: "3.5rem" },
              textAlign: "center",
              marginTop: "8px",
              color: theme.palette.primary.black2,

              fontFamily: theme.typography.Columbia,
              textTransform: "uppercase",
              fontStyle: "italic",
              letterSpacing: "1px",
            }}
          >
            TRUCKPARTSMART HOTLINE
          </Typography>
        </Box>
        <Grid
          container
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={7}>
            <TextField
              placeholder="Search and press enter"
              onChange={handleSearchChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearchChange(event);
                }
              }}
              sx={{
                background: theme.palette.primary.light,
                width: "100%",
                height: "48px",
                fontSize: "16px",
                borderRadius: "10px",
                marginBottom: "25px",
                outline: "none",
                marginRight: "10px",
                my: "10px",

                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  marginRight: "20px",
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                },
                "& .MuiInputBase-input": {
                  padding: "0px",
                  height: "48px",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.primary.light}`,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FiSearch
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "24px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} sx={{ maxWidth: { sm: "fit-content" } }}>
            <FormControl variant="outlined" size="small">
              <Select
                value={sortOrderControle}
                onChange={(event) => handleSortOrderChange(event.target.value)}
                style={{
                  height: "48px",
                  borderRadius: "10px",
                }}
              >
                <MenuItem value="default">Recently listed</MenuItem>
                <MenuItem value="request_desc" onClick={handleOpenModal}>
                  Requests near me
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              lineHeight: "1.4rem",
              fontWeight: 500,
              marginTop: { xs: "15px", sm: "10px" },
              color: theme.palette.text.primary,
              fontFamily: theme.typography.headerFont,
            }}
          >
            Welcome to Truckpartsmart! What are you looking for today?
          </Typography>

          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              gap: 2,
              position: "absolute",
              right: "20px",
              left: "20px",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <ListItem
              onClick={() => handleMainCategorySelect(0, 0, "all")}
              sx={{
                padding: "14px 20px",
                borderRadius: "12px",
                width: "auto !important",
                cursor: "pointer",
                background:
                  selectedIndex === 0
                    ? theme.palette.primary.main
                    : theme.palette.neutral.boxGray,
                color:
                  selectedIndex === 0
                    ? theme.palette.primary.light
                    : theme.palette.primary.darkColor,
                "&:hover": {
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                },
                flexShrink: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "21px",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                All Requests
              </Typography>
            </ListItem>
            {categories?.map(({ name, id }, index) => (
              <ListItem
                key={index + 1}
                onClick={() => handleMainCategorySelect(index + 1, id, name)}
                sx={{
                  padding: "14px 20px",
                  borderRadius: "12px",
                  width: "auto !important",
                  cursor: "pointer",
                  background:
                    selectedIndex === index + 1
                      ? theme.palette.primary.main
                      : theme.palette.neutral.boxGray,
                  color:
                    selectedIndex === index + 1
                      ? theme.palette.primary.light
                      : theme.palette.primary.darkColor,
                  "&:hover": {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                  },
                  flexShrink: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {name}
                </Typography>
              </ListItem>
            ))}
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {/* Categories Sidebar */}
        {!mdDown && (
          <Grid
            item
            xs={12}
            md={isFullscreen === true ? 2 : 0.7}
            sx={{ paddingTop: "0 !important",  height: "140rem", }}
          >
            <Sidebar
              title={isFullscreen === true ? "Parts Category" : null}
              items={categories}
              selectedIndex={selectedCategoryIndex}
              onSelect={handleCategorySelect}
            />
          </Grid>
        )}

        {/* Main Content */}
        <Grid
          item
          xs={12}
          md={mdDown ? 12 : isFullscreen === true ? 8 : 10.6}
          sx={{ paddingTop: "0 !important" }}
        >
          <Box
            sx={{
              borderRadius: "15px",
              padding: "25px 25px 30px",
              background: theme.palette.primary.black2,
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
            }}
          >
            <RequestComponent
              limitRequests={limitRequests}
              searchTerm={searchTerm}
              sortOrderControle={sortOrderControle}
              margin="0px"
              wordLimit="60"
              categoryId={selectedId}
              userLocation={userLocation}
              pagenation={true}
              height={true}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              isLoading={isLoading}
              displayLocation="requestsPage"
            />
          </Box>
        </Grid>

        {/* Services Sidebar */}
        {!mdDown && (
          <Grid
            item
            xs={12}
            md={isFullscreen === true ? 2 : 0.7}
            sx={{ paddingTop: "0 !important" }}
          >
            <Sidebar
              title={isFullscreen === true ? "Services" : ""}
              items={services}
              selectedIndex={selectedServiceIndex}
              onSelect={handleServiceSelect}
              isService={true}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RequestsPage;
