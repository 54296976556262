import React from "react";
import { useTheme, styled, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const StyledPhoneInput = styled("div")(({ theme }) => ({
  ".react-tel-input": {
    width: "100%",
    border: `1px solid ${theme.palette.neutral.metallicGray}`,
    borderRadius: "8px",
   
    "&.focused": {
      borderColor: theme.palette.primary.main,
    },
    input: {
      height: "50px",
      border: "none",
      fontSize: "14px",
      "&::placeholder": {
        color: theme.palette.text.secondary,
      },
     
    },
    ".special-label": {
      display: "none", 
    },
    ".flag-dropdown": {
      borderRight: `1px solid ${theme.palette.gray.slightGray}`,
    
    },
    ".selected-flag": {
      "&:hover": {
        backgroundColor: `${theme.palette.neutral.paleGray} !important`, 
       
      },
      "&:focus": {
        backgroundColor: `${theme.palette.neutral.paleGray} !important`, 
    
      },
    }
  },
}));

const PhoneNumberInput = ({
  value,
  onChange,
  onBlur,
 
}) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <StyledPhoneInput>
        <PhoneInput
          country={"us"}
          value={value}
          onBlur={onBlur}
          countryCodeEditable={false}
          onChange={(phone) => {
            const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;
            onChange(formattedPhone);
          }}
          inputStyle={{
            width: "100%",
            border: "none",
            borderRadius: "8px",
            fontSize: "14px",
          }}
          buttonStyle={{
            background: `${theme.palette.neutral.paleGray} !important`,
            border: "none",
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
          
              
          }}
          dropdownStyle={{
            zIndex: 1000,
          }}
          enableSearch
        />
      </StyledPhoneInput>
    </React.Fragment>
  );
};

export default PhoneNumberInput;
