import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  InputAdornment,
  Paper,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { message, Modal } from "antd";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFormTouched,
  setFormTouched,
} from "../../redux/slices/appStateSlice";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../src/configs/firebase";

const validationSchema = Yup.object().shape({
  customName: Yup.string()
    .trim()
    .min(3, "Address name must be at least 3 characters long")
    .max(10, "Address name must be at most 10 characters long")
    .when("name", {
      is: (val) => val === "Other",
      then: (schema) => schema.required("Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  addressLineOne: Yup.string().trim().required("Address is required"),
  addressLineTwo: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9\s\-/#.,]*$/,
      "Invalid characters in apartment/suite field"
    ),
  city: Yup.string()
    .trim()
    .min(3, "City must be at least 3 characters long")
    .required("City is required"),
  state: Yup.string()
    .trim()
    .min(2, "State must be at least 2 characters long")
    .max(20, "State must be at most 20 characters long")
    .required("State is required"),
  zipCode: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9\s\-]+$/, "Zip code must be valid")
    .required("Zip code is required"),
});

const ReusableAddressModal = ({
  initialData,
  handleEditClose,
  handleAddressSubmit,
  firebaseEnabled = false,
  docId = null,
  setOrder = null,
  setEditAddress,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [address, setAddress] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [country, setCountry] = useState(null);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  const [initialValues, setInitialValues] = useState({
    customName: "",
    name: "Home",
    addressLineTwo: "",
    city: "",
    state: "",
    zipCode: "",
    addressLineOne: "",
    isAddressSelected: false,
  });

  const confirmedLeave = useRef(false);
  const isFormTouched = useSelector((state) => state.appState.isFormTouched);

  useEffect(() => {
    if (initialData) {
      const deliveryAddress = initialData?.delivery_address || initialData;
      setInitialValues({
        name:
          deliveryAddress?.name === "Home" || deliveryAddress?.name === "Work"
            ? deliveryAddress?.name
            : "Other",
        customName:
          deliveryAddress?.name === "Home" || deliveryAddress?.name === "Work"
            ? ""
            : deliveryAddress?.name,
        addressLineTwo: deliveryAddress?.addressLineTwo || "",
        city: deliveryAddress?.city || "",
        state: deliveryAddress?.state || "",
        zipCode: deliveryAddress?.zipCode || "",
        addressLineOne: deliveryAddress?.addressLineOne || "",
        isAddressSelected: false,
      });
      setLat(deliveryAddress?.lat);
      setLng(deliveryAddress?.lng);
      setPlaceId(deliveryAddress?.placeId);
      setCountry(deliveryAddress?.country);
      setAddress(deliveryAddress?.addressLineOne);
    }
  }, [initialData]);

  useEffect(() => {
    dispatch(resetFormTouched());
  }, []);

  useEffect(() => {
    if (typeof window.google !== "undefined" && window.google.maps) {
      setApiLoaded(true);
    }
  }, []);

  const handleSelect = async (value, setFieldValue) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const addressComponents = results[0].address_components;

    const addressDetails = {
      addressLineOne: "",
      city: "",
      state: "",
      zipCode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number") || types.includes("route")) {
        addressDetails.addressLineOne += `${component.long_name} `;
      } else if (types.includes("locality")) {
        addressDetails.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        addressDetails.state = component.short_name;
      } else if (types.includes("postal_code")) {
        addressDetails.zipCode = component.long_name;
      } else if (types.includes("country")) {
        setCountry(component.short_name);
      }
    });

    setAddress(value);
    setFieldValue("addressLineOne", addressDetails.addressLineOne.trim());
    setFieldValue("city", addressDetails.city);
    setFieldValue("state", addressDetails.state);
    setFieldValue("zipCode", addressDetails.zipCode);
    setFieldValue("isAddressSelected", true);
    setIsAddressSelected(true);
    setPlaceId(results[0].place_id);
    setLat(latLng.lat);
    setLng(latLng.lng);
  };

  const handleSubmit = async (values) => {
    try {
      delete values.isAddressSelected;
      const {
        customName,
        isAddressSelected,
        addressLineOne,
        city,
        state,
        zipCode,
        ...filteredValues
      } = values;

      const updatedAddress = {
        ...filteredValues,
        addressLineOne,
        city,
        state,
        zipCode,
        placeId,
        lat,
        lng,
        country,
        ...(firebaseEnabled && { updatedAt: Timestamp.now() }),
        name: values.name === "Other" ? customName : values.name,
      };

      if (firebaseEnabled) {
        if (!docId) {
          message.error("Document ID is missing");
          return;
        }
        const docRef = doc(db, "orders", docId);
        await updateDoc(docRef, {
          delivery_address: updatedAddress,
        });
        setOrder((prevOrder) => ({
          ...prevOrder,
          delivery_address: updatedAddress,
        }));
      } else {
        handleAddressSubmit(updatedAddress);
      }

      dispatch(resetFormTouched());
      message.success("Address updated successfully");
      setEditAddress(false);
    } catch (error) {
      message.error(`Error updating address: ${error.message}`);
    }
  };

  const handleClearAddress = (setFieldValue) => {
    setAddress("");
    setIsAddressSelected(false);
    setFieldValue("addressLineOne", "");
    setFieldValue("isAddressSelected", false);
  };

  const handleInputChange = () => {
    dispatch(setFormTouched());
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnMount={true}
    >
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form sx={{ position: "relative" }}>
          <Stack spacing={3}>
            <Box
              sx={{
                borderRadius: "8px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: {
                  xs: "10px",
                  sm: "20px",
                  lg: "30px",
                },
                gridTemplateAreas: `
                  "name name name"
                  "addressLineOne addressLineOne addressLineTwo"
                  "city state zipCode"
                `,
                "@media (max-width: 600px)": {
                  gridTemplateColumns: "1fr",
                  gridTemplateAreas: `
                    "name"
                     "addressLineOne"
                     "addressLineTwo"
                    "city"
                    "state"
                    "zipCode"
                  `,
                },
              }}
            >
              {/* Name */}
              <Box sx={{ gridArea: "name" }}>
                <InputLabel
                  sx={{ marginBottom: "8px", textTransform: "capitalize" }}
                  htmlFor="name"
                >
                  address name
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="name"
                    name="name"
                    value={values?.name}
                    onChange={(e) => {
                      handleInputChange(e);
                      handleChange(e);

                      if (e.target.value !== "Other") {
                        setFieldValue("name", e.target.value);
                        setFieldValue("customName", "");
                      } else {
                        setFieldValue("name", "Other");
                      }
                    }}
                  >
                    <FormControlLabel
                      value="Home"
                      control={<Radio />}
                      label="Home"
                    />
                    <FormControlLabel
                      value="Work"
                      control={<Radio />}
                      label="Work"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
                {values?.name !== "Home" && values?.name !== "Work" && (
                  <TextField
                    id="customName"
                    name="customName"
                    fullWidth
                    variant="outlined"
                    value={values?.customName}
                    onBlur={handleBlur("customName")}
                    onChange={(e) => {
                      handleChange(e);
                      handleInputChange(e);
                    }}
                    error={touched.customName && Boolean(errors.customName)}
                    placeholder="Enter address name"
                  />
                )}

                {touched.customName && errors?.customName && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.customName}
                  </Typography>
                )}
              </Box>
              {/* Address Line One */}
              <Box sx={{ gridArea: "addressLineOne" }}>
                <InputLabel
                  htmlFor="addressLineOne"
                  sx={{ marginBottom: "8px", textTransform: "capitalize" }}
                >
                  address{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <PlacesAutocomplete
                  value={address}
                  disabled={isAddressSelected}
                  onChange={(value) => {
                    setAddress(value);
                    handleInputChange();
                    setIsAddressSelected(false);
                    setFieldValue("isAddressSelected", false);
                  }}
                  onSelect={(value) => handleSelect(value, setFieldValue)}
                  searchOptions={{
                    // componentRestrictions: { country: "us" },
                    types: ["address"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div style={{ position: "relative", width: "100%" }}>
                      <TextField
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                        })}
                        id="addressLineOne"
                        name="addressLineOne"
                        fullWidth
                        disabled={isAddressSelected}
                        variant="outlined"
                        error={
                          touched.addressLineOne &&
                          Boolean(errors.addressLineOne)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {address && (
                                <IconButton
                                  onClick={() =>
                                    handleClearAddress(setFieldValue)
                                  }
                                  aria-label="clear address"
                                >
                                  <IoCloseCircleOutline />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Paper
                        sx={{
                          marginTop: "-15px !important",
                          position: "absolute",
                          zIndex: 2100,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "4px",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const { key, ...restProps } =
                            getSuggestionItemProps(suggestion);
                          return (
                            <Box
                              key={suggestion.placeId || index} // Provide unique key here
                              {...restProps}
                              sx={{
                                padding: "10px 16px",
                                backgroundColor: suggestion.active
                                  ? "rgba(0, 0, 0, 0.1)"
                                  : "white",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                                },
                                borderBottom: "1px solid #eee",
                              }}
                            >
                              <Typography variant="body2">
                                {suggestion.description}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Paper>
                    </div>
                  )}
                </PlacesAutocomplete>

                {touched.addressLineOne && errors.addressLineOne && (
                  <Typography variant="caption" color="error">
                    {errors.addressLineOne}
                  </Typography>
                )}
              </Box>

              {/* Address Line Two */}
              <Box sx={{ gridArea: "addressLineTwo" }}>
                <InputLabel
                  sx={{ marginBottom: "8px", textTransform: "capitalize" }}
                  htmlFor="addressLineTwo"
                >
                  apartment/suite field
                </InputLabel>
                <TextField
                  id="addressLineTwo"
                  name="addressLineTwo"
                  fullWidth
                  variant="outlined"
                  value={values?.addressLineTwo}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  placeholder="Enter apartment/suite field"
                />
                {touched.addressLineTwo && errors?.addressLineTwo && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.addressLineTwo}
                  </Typography>
                )}
              </Box>

              {/* City */}
              <Box sx={{ gridArea: "city" }}>
                <InputLabel
                  sx={{ marginBottom: "8px", textTransform: "capitalize" }}
                  htmlFor="city"
                >
                  city{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="city"
                  name="city"
                  fullWidth
                  variant="outlined"
                  value={values?.city}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  placeholder="Enter city"
                />
                {touched.city && errors?.city && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.city}
                  </Typography>
                )}
              </Box>

              {/* State */}
              <Box sx={{ gridArea: "state" }}>
                <InputLabel
                  sx={{ marginBottom: "8px", textTransform: "capitalize" }}
                  htmlFor="state"
                >
                  state{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="state"
                  name="state"
                  fullWidth
                  variant="outlined"
                  value={values?.state}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  placeholder="Enter state"
                />
                {touched.state && errors?.state && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.state}
                  </Typography>
                )}
              </Box>

              {/* Zip Code */}
              <Box sx={{ gridArea: "zipCode" }}>
                <InputLabel
                  sx={{ marginBottom: "8px", textTransform: "capitalize" }}
                  htmlFor="zipCode"
                >
                  zip code{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="zipCode"
                  name="zipCode"
                  fullWidth
                  variant="outlined"
                  value={values?.zipCode}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur}
                  error={touched.zipCode && Boolean(errors.zipCode)}
                  placeholder="Enter zip code"
                />
                {touched.zipCode && errors?.zipCode && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.zipCode}
                  </Typography>
                )}
              </Box>

              {/* Save Changes Button */}
              <Box sx={{ gridColumn: "1 / span 3" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    paddingY: "16px",
                    paddingX: "32px",
                    borderRadius: "8px",
                  }}
                >
                  Edit Address
                </Button>
              </Box>
            </Box>
          </Stack>
          <Box
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              cursor: "pointer",
            }}
          >
            <IoIosCloseCircleOutline
              onClick={handleEditClose}
              size="24px"
              color="red"
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ReusableAddressModal;
