import { Outlet, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Footer from "../common/Footer/Footer";
import Subscribe from "../common/Footer/Subscribe";
import Header from "../common/Header/Header";
import { useTheme } from "@mui/material";

const Layout = () => {
  const theme = useTheme();
  const location = useLocation();

  const showSubscribePaths = [
    "/home",
    "/",
    "/explore",
    "/services",
    "/categories",
    "/sellers",
    "/requests",
    "/contact-us",
    "/following",
    "/blogs",
    "/privacy-policy",
    "/terms-conditions",
  ];

  const shouldShowSection = showSubscribePaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const aboutUs = location.pathname.startsWith("/about-us");

  useEffect(() => {
    if (location.state && location.state.scrollToRequests) {
      const requestsSection = document.getElementById("products-section");
      if (requestsSection) {
        requestsSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div id="products-section">
      <Header />
      <div
        style={{
          background: aboutUs
            ? theme.palette.primary.light
            : theme.palette.primary.background,
          padding: "0 0 20px 0",
        }}
      >
        <Outlet />
        {shouldShowSection && <Subscribe />}
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
