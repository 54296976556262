import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddBusinessInfoForm from "./AddBusinessInfoForm";
import { db } from "../../../../configs/firebase";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

const AddBusinessInfo = () => {
  const navigate = useNavigate();
  const [placeId, setPlaceId] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [country, setCountry] = useState(null);
  const [addresses, setAddresses] = useState([]);

  const userData = useSelector((state) => state.appState.userData);

  const initialValues = {
    businessName: "",
    email: userData?.email || userData?.businessInfo?.email || "",
    phone: userData?.phoneNumber || userData?.businessInfo?.phoneNumber || "",
    addressLineOne:
      userData?.businessInfo?.address?.addressLineOne ||
      addresses?.addressLineOne ||
      "",
    addressLineTwo:
      userData?.businessInfo?.address?.addressLineTwo ||
      addresses?.addressLineTwo ||
      "",
    description: "",
    city: userData?.businessInfo?.address?.city || addresses?.city || "",
    state: userData?.businessInfo?.address?.state || addresses?.state || "",
    zipCode:
      userData?.businessInfo?.address?.zipCode || addresses?.zipCode || "",
    websiteLink: userData?.websiteLink || "",
    businessContent: userData?.businessContent || "",
    businessImage: userData?.businessImage || "",
  };

  const fetchAddresses = async () => {
    try {
      const addressesRef = collection(db, "users", userData?.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);
      const fetchedAddresses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedAddresses = fetchedAddresses.sort(
        (a, b) => b.isDefault - a.isDefault
      );

      // setAddresses(sortedAddresses);

      const defaultAddress = sortedAddresses?.find(
        (address) => address?.isDefault
      );
      setAddresses(defaultAddress);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    if (userData?.uid) {
      fetchAddresses();
    }
  }, [userData]);

 // Update Default Address
  const updateUserDefaultAddress = async (defaultAddress) => {
    const { customName, updatedAt, createdAt, ...address } = defaultAddress;
    try {
      await updateDoc(doc(db, "users", userData?.uid), {
        address: { ...address },
      });
    } catch (error) {
      console.error("Error updating default address:", error);
    }
  };


  const handleSubmit = async (values, formattedDaysToFierbase) => {
    try {
      if (!userData?.uid) {
        return message.error("User ID is not available");
      }

      if (!values.city || !values.state || !values.zipCode) {
        return message.error("Please select a valid address");
      }

      // Handle Addresses Collection
      const addressesRef = collection(db, "users", userData.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);

      let existingAddress = null;
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (
          data?.placeId === placeId ||
          (data?.lat === lat && data?.lng === lng) ||
          data?.addressLineOne === values?.addressLineOne
        ) {
          existingAddress = { id: doc.id, ...data };
        }
      });
      const batch = writeBatch(db);
      let newSelectedAddressId = null;

      if (existingAddress) {
        // Update Existing Address

        const addressRef = doc(addressesRef, existingAddress?.id);
        batch.update(addressRef, {
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          updatedAt: Timestamp.now(),
        });

        newSelectedAddressId = existingAddress?.id;
        // Update default address only if it's currently set as default
        if (existingAddress.isDefault) {
          await updateUserDefaultAddress({
            ...existingAddress,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
          });
        }
      } else {
        // Create New Address
        const newAddressRef = doc(addressesRef);
        const newAddressData = {
          id: newAddressRef.id,
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          placeId,
          lat,
          lng,
          country,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          isDefault: querySnapshot.empty, // First address is default
          name: "Business",
        };

        batch.set(newAddressRef, newAddressData);
        newSelectedAddressId = newAddressRef?.id;

        if (querySnapshot.empty) {
          await updateUserDefaultAddress(newAddressData);
        }
      }

      // Commit all batch updates
      await batch.commit();

      // Construct Business Info Object
      const businessInfo = {
        address: {
          id: newSelectedAddressId,
          addressLineOne: values?.addressLineOne,
          addressLineTwo: values?.addressLineTwo,
          city: values?.city,
          state: values?.state,
          zipCode: values?.zipCode,
          name: "Business",

          isDefault: false,
          placeId,
          lat,
          lng,
          country,
        },
        email: values?.email,
        phoneNumber: values?.phone,
        description: values?.description,
        name: values?.businessName,
        websiteLink: values?.websiteLink,
        businessContent: values?.businessContent,
        businessImage: values?.businessImage,
        openDays: formattedDaysToFierbase,
      };

      // Update Business Info in Firestore
      const userDocRef = doc(db, "users", userData.uid);
      // await updateDoc(userDocRef, { businessInfo });
      // message.success("Business info updated successfully!");

      await updateDoc(userDocRef, { businessInfo });
      await db.collection("users").doc(userData?.uid).update({
        businessInfo,
        userType: values?.userType,
      });
      message.success(
        `you are now a ${
          values?.userType === "seller" ? "seller" : "service provider"
        }`
      );

      // Navigate to profile
      navigate("/profile?page=info&form=editBusinessInfo");
    } catch (error) {
      message.error(`Error saving address: ${error.message}`);
      console.error("Error saving address:", error);
    }
  };

  return (
    <AddBusinessInfoForm
      key={JSON.stringify(addresses)}
      initialValues={initialValues}
      Fetchaddresses={addresses}
      onSubmit={handleSubmit}
      setCountry={setCountry}
      setPlaceId={setPlaceId}
      setLat={setLat}
      setLng={setLng}
    />
  );
};

export default AddBusinessInfo;
