import React, { useEffect, useState } from "react";
import box from "../../../../assets/Images/nothing_here.png";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  TablePagination,
  Box,
  TableBody,
  Typography,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import { collection, orderBy, getDocs, query } from "firebase/firestore";
import { db } from "../../../../configs/firebase";
import MyOrderTableRow from "./MyOrderTableRow";
import Loader from "../../../../components/common/Loader";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const MyOrders = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orders, setOrders] = useState([]);
 
  
  const [filteredOrders, setFilteredOrders] = useState([]);
  const userData = useSelector((state) => state.appState.userData);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // 0: Buyer, 1: Seller
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const headerCellStyle = {
    color: theme.palette.primary.textBlueBlack,
    textTransform: "capitalize",
  };
  const rowStyle = {
    borderBottom: `3px solid ${theme.palette.primary.whiteSmoke} `,
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setOrdersLoading(true);
      try {
        const ordersCollection = collection(db, "orders");
        const ordersQuery = query(
          ordersCollection,
          orderBy("createdAt", "desc")
        );
        const ordersSnapshot = await getDocs(ordersQuery);
        const allOrders = ordersSnapshot.docs.map((doc) => ({
          id: doc?.id,
          ...doc?.data(),
        }));
        setOrders(allOrders);
      } catch (error) {
        console.error("Error fetching orders: ", error);
      } finally {
        setOrdersLoading(false);
      }
    };
    fetchOrders();
  }, []);

  useEffect(() => {
    const type = searchParams.get("type") || "buyer"; // Default to buyer
    setTabIndex(type === "buyer" ? 0 : 1);
    if (type === "buyer") {
      const buyerOrders = orders.filter(
        (order) => order?.buyer_id === userData?.uid
      );
      setFilteredOrders(buyerOrders);
    } else {
      const sellerOrders = orders.filter(
        (order) => order?.seller_id === userData?.uid
      );
      setFilteredOrders(sellerOrders);
    }
  }, [orders, searchParams, userData?.uid]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTabChange = (event, newValue) => {
    const type = newValue === 0 ? "buyer" : "seller";
    setTabIndex(newValue);
    navigate(`/profile?page=orders&type=${type}`);
  };

  if (ordersLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Loader />
      </Stack>
    );
  }

  if (!ordersLoading && !orders.length) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <img src={box} alt="empty box" />
        <Typography variant="body1">No orders found.</Typography>
      </Stack>
    );
  }

  return (
    <Box sx={{ borderRadius: "16px" }}>
      {/* Tabs for filtering orders */}
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="my orders Tabs"
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
      >
        <Tab label="As Buyer" />
        <Tab label="As Seller" />
      </Tabs>

      {/* Display Custom Message and Image when there are no orders */}
      {filteredOrders?.length === 0 ? (
        <Stack alignItems="center" justifyContent="center" sx={{ py: 6 }}>
          <img src={box} alt="No orders" width="150px" height="150px" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            {tabIndex === 0
              ? "You have no orders as a buyer."
              : "You have no orders as a seller."}
          </Typography>
        </Stack>
      ) : (
        <>
          <TableContainer
            sx={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: theme.palette.primary.lightAliceBlue }}
              >
                <TableRow sx={rowStyle}>
                  <TableCell sx={{ ...headerCellStyle, width: "130px" }}>
                    order ID
                  </TableCell>
                  <TableCell sx={headerCellStyle}>date</TableCell>
                  <TableCell sx={headerCellStyle}>status</TableCell>
                  <TableCell sx={headerCellStyle}>QTY</TableCell>
                  <TableCell sx={headerCellStyle}>total</TableCell>
                  <TableCell sx={{ ...headerCellStyle, textAlign: "center" }}>
                    actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order) => (
                    <MyOrderTableRow
                      order={order}
                      key={order?.id}
                      theme={theme}
                      rowStyle={rowStyle}
                      tabIndex={tabIndex}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredOrders?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
          />
        </>
      )}
    </Box>
  );
};

export default MyOrders;
