import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
  useTheme,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { GrLocation } from "react-icons/gr";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useSelector } from "react-redux";

const calculateTimeAgo = (timestamp) => {
  if (!timestamp) return "Just now";
  let createdAtDate;

  if (typeof timestamp === "number") {
    createdAtDate = new Date(timestamp);
  } else if (timestamp.seconds) {
    createdAtDate = new Date(timestamp.seconds * 1000);
  } else {
    createdAtDate = new Date(timestamp);
  }
  const now = Date.now();

  const diffInSeconds = Math.floor((now - createdAtDate) / 1000);
  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minutes ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hours ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} days ago`;
  }
};

const RequestItem = ({
  request,
  handleClick,
  handleOpenDeleteDialog,
  handleChatButtonClick,
  toggleDetailsExpanded,
  goToSellerPage,
  expandedRequestId,
  isMediumScreen,
  handleUpdate,
  anchorEl,
  updateRqequest,
  handleClose,
}) => {
  const theme = useTheme();
  const userData = useSelector((state) => state.appState.userData);

  // Media queries to check screen size
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  let maxDetailLength;

  if (updateRqequest) {
    maxDetailLength = 250;
  } else if (xsDown) {
    maxDetailLength = 40;
  } else if (smUp && !mdUp) {
    maxDetailLength = 130;
  } else if (mdUp && !lgUp) {
    maxDetailLength = 180;
  } else if (lgUp && !xlUp) {
    maxDetailLength = 230;
  } else if (xlUp) {
    maxDetailLength = 280;
  } else {
    maxDetailLength = 320;
  }

  return (
    <Box key={request?.id}>
      {request?.uid === userData?.uid && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            position: "relative",
            top: isMediumScreen ? "23px" : "0",
            zIndex: 3,
          }}
        >
          <Box />
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => handleClick(event, request?.id)}
            sx={{
              background: "transparent",
              borderRadius: "50%",
              padding: "0",
              minWidth: "auto",
            }}
          >
            <BiDotsVerticalRounded
              style={{
                fontSize: "30px",
                color: theme.palette.primary.orange,
              }}
            />
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                boxShadow: `3px 3px 5px -2px ${theme.palette.customShadows.light}`,
              },
            }}
          >
            <MenuItem
              onClick={() =>
                updateRqequest === "new"
                  ? handleUpdate(request?.address?.id)
                  : handleUpdate()
              }
            >
              Update
            </MenuItem>
            <MenuItem onClick={handleOpenDeleteDialog}>Delete</MenuItem>
          </Menu>
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          top:
            request?.uid === userData?.uid && !isMediumScreen ? "-25px" : "0px",
        }}
      >
        <Box
          sx={{
            height:
              expandedRequestId === request?.id
                ? "auto"
                : {
                    xs: updateRqequest ? "0" : "225px",
                    sm: updateRqequest ? "0" : "155px",
                  },
            overflowY: expandedRequestId === request?.id ? "scroll" : "hidden",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
            transition: "max-height 0.5s ease",
            maxHeight:
              expandedRequestId === request?.id
                ? "225px"
                : updateRqequest
                ? "auto"
                : {
                    xs: updateRqequest
                      ? "0"
                      : request?.details?.length > 200
                      ? "160px"
                      : "155px",
                    sm: updateRqequest ? "0" : "155px",
                  },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
              color: theme.palette.primary.orange,

              fontFamily: theme.typography.matrixFont,

              marginBottom: "10px",
              fontSize: "18px",
              lineHeight: "27px",
              textTransform: "none",
            }}
          >
            {request?.name?.toUpperCase()}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.primary.orange,

              fontFamily: theme.typography.matrixFont,

              lineHeight: "34px",
              maxHeight: expandedRequestId === request?.id ? "none" : "100px",
              overflow:
                expandedRequestId === request?.id ? "visible" : "hidden",
              transition: "max-height 0.5s ease",
            }}
          >
            {expandedRequestId === request?.id
              ? request?.details
              : request?.details?.slice(0, maxDetailLength)}
            {!expandedRequestId && request?.details?.length > maxDetailLength
              ? "..."
              : ""}
            {request?.details && request?.details?.length > maxDetailLength && (
              <Typography
                variant="body2"
                onClick={() => toggleDetailsExpanded(request?.id)}
                sx={{
                  color: theme.palette.primary.orangeOpacity,
                  marginLeft: "20px",
                  display: "inline",
                  fontFamily: theme.typography.matrixFont,
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              >
                {expandedRequestId === request?.id ? "Show Less" : "Show More"}
              </Typography>
            )}
          </Typography>

          {request?.tags && request?.tags && request?.tags.trim() !== "" && (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                padding: "8px",
                borderRadius: "8px",
              }}
            >
              {request?.tags
                .split(" ")
                .filter((tag) => tag.trim() !== "")
                .slice(0, 5)
                .map((tag, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{
                      display: "inline-block",
                      backgroundColor: theme.palette.primary.orange,
                      color: theme.palette.primary.light,
                      padding: "8px",
                      borderRadius: "8px",
                      fontFamily: theme.typography.matrixFont,
                      fontWeight: 600,
                      marginBottom: "8px",
                    }}
                  >
                    {tag?.trim()}
                  </Typography>
                ))}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start", sm: "center" },

            justifyContent: {
              xs: "flex-start",
              sm: "space-between",
            },
            background: theme.palette.primary.lightBlack,

            padding: "10px 15px",
            flexDirection: { xs: "column", sm: "row" },
            marginTop: "20px",
            marginBottom: updateRqequest
              ? "15px"
              : expandedRequestId
              ? "15px"
              : "0px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: {
                xs: "15px",
                sm: "0",
              },
            }}
            onClick={() => goToSellerPage(request?.uid)}
          >
            <Avatar
              alt={request?.firstName}
              src={request?.profilePicture}
              style={{
                width: "48px",
                height: "48px",
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.light,

                    fontWeight: 700,
                    marginLeft: "20px",
                  }}
                >
                  {request?.userName ||
                    `${request?.firstName} ${request?.lastName}`}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.neutral.subText,
                    fontSize: ".7rem",
                    marginLeft: "5px",
                  }}
                >
                  {calculateTimeAgo(request?.createdAt)}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.neutral.textLight,
                    fontSize: ".7rem",
                    fontWeight: 500,
                    marginTop: "5px",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "9px",
                  }}
                >
                  <GrLocation
                    style={{
                      color: theme.palette.neutral.icon,
                      width: "15px",
                      height: "18px",
                      marginRight: "5px",
                    }}
                  />
                  {request?.address?.city && request?.address?.state
                    ? `${request?.address?.city || ""}, ${
                        request?.address?.state || ""
                      }`
                    : request?.address?.addressLineOne}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            {userData?.uid !== request?.uid && (
              <Button
                variant="button"
                onClick={() => handleChatButtonClick(request)}
                sx={{
                  textTransform: "capitalize",
                  background: theme.palette.primary.orange,

                  color: theme.palette.primary.light,
                  borderRadius: "8px",
                  fontWeight: 700,
                  padding: "8px",
                  width: "148px",
                  height: "40px",
                  "&:hover": {
                    background: theme.palette.primary.orange,
                  },
                }}
              >
                <IoChatbubbleEllipsesOutline
                  style={{
                    marginRight: "8px",
                    fontSize: "20px",
                  }}
                />{" "}
                contact
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {updateRqequest && (
        <Divider
          sx={{
            borderBottomWidth: 0.5,
            borderColor: theme.palette.primary.opacityOrange,
            marginBottom: updateRqequest ? "15px" : "0px",
          }}
        />
      )}
    </Box>
  );
};

export default RequestItem;
