import React, { useEffect, useRef, useState } from "react";
import { Modal, message } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FaStar } from "react-icons/fa";
import * as Yup from "yup";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import anonymousPic from "../../assets/Images/profilePlaceholder.png";
import { db } from "../../configs/firebase";
import VerifiedBox from "./VerifiedBox";

// Validation schema using Yup
const reviewSchema = Yup.object().shape({
  rating: Yup.number()
    .min(1, "Please provide a rating")
    .required("Rating is required"),
  comment: Yup.string()
    .min(3, "Feedback should be at lease 3 characters long")
    .required("Feedback message is required"),
});

const ReviewModal = ({
  isVisible,
  onClose,
  order,
  currentUser,
  onReviewSubmit = () => {},
  mode = "send",
  review,
}) => {
  const theme = useTheme();
  const resetFormRef = useRef(null);
  const [hoverRating, setHoverRating] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleReviewSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const sellerId = order?.seller_id;
      const sellerDocRef = doc(db, "users", sellerId);
      const orderDocRef = doc(db, "orders", order.id);

      const reviewData = {
        reviewType: "seller",
        itemId: null,
        serviceId: null,
        orderId: order.id,
        rating: values.rating,
        userInfo: {
          uid: currentUser?.uid,
          name:
            currentUser?.businessInfo?.name ||
            currentUser?.firstName + " " + currentUser?.lastName ||
            "Anonymous",
          profilePicture:
            currentUser?.profilePicture || null
        },
        comment: values.comment,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(collection(sellerDocRef, "reviews"), reviewData);

      await updateDoc(sellerDocRef, {
        updatedAt: serverTimestamp(),
      });

      await updateDoc(orderDocRef, {
        isReviewed: true,
      });

      message.success("Review submitted successfully!");
      onReviewSubmit();
      onClose();
      resetForm();
    } catch (error) {
      console.error("Error submitting review: ", error);
      message.error("Failed to submit review, please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isVisible && mode === "send" && resetFormRef.current) {
      resetFormRef.current();
    }
  }, [isVisible, mode]);

  return (
    <Modal
      title={mode === "send" ? "Review Seller" : "Review Details"}
      open={isVisible}
      onCancel={onClose}
      footer={null}
    >
      {mode === "send" ? (
        <Formik
          initialValues={{ rating: 0, comment: "" }}
          validationSchema={reviewSchema}
          onSubmit={handleReviewSubmit}
          validateOnBlur
          validateOnChange
        >
          {({ values, setFieldValue, handleSubmit, resetForm }) => {
            resetFormRef.current = resetForm;

            return (
              <Form>
                <Stack gap={1}>
                  <Stack alignItems="center" gap={1}>
                    <img
                      src={order?.seller_info?.profile_picture || anonymousPic}
                      alt={order?.seller_info?.name}
                      loading="lazy"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          textTransform: "capitalize",
                          color: theme.palette.dark.darkBlack,
                        }}
                      >
                        {order?.seller_info?.name}
                      </Typography>
                      <VerifiedBox />
                    </Stack>
                  </Stack>

                  {/* Star Rating Input */}
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <FaStar
                        key={star}
                        style={{
                          cursor: "pointer",
                          color:
                            star <= (hoverRating || values.rating)
                              ? theme.palette.status.star
                              : theme.palette.neutral.paleGray,
                          transition: "color 0.3s ease-in-out",
                        }}
                        onClick={() => setFieldValue("rating", star)}
                        onMouseEnter={() => setHoverRating(star)}
                        onMouseLeave={() => setHoverRating(0)}
                        size="32px"
                      />
                    ))}
                  </Stack>
                  <ErrorMessage
                    name="rating"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <Box sx={{ height: "140px" }}>
                    <Field
                      as="textarea"
                      name="comment"
                      placeholder="Leave your feedback"
                      onChange={(e) => {
                        const commentValue = e.target.value;

                        const capitalizedComment =
                          commentValue.charAt(0).toUpperCase() +
                          commentValue.slice(1);
                        setFieldValue("comment", capitalizedComment);
                      }}
                      value={values.comment}
                      style={{
                        padding: "14px 16px",
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        borderColor: theme.palette.gray.lightBorderGray,
                        resize: "none",
                      }}
                    />
                    <ErrorMessage
                      name="comment"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Box>

                  {/* Submit Button with Spinner */}
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    sx={{
                      alignSelf: "end",
                      marginTop: "20px",
                      padding: "12px 44px",
                      backgroundColor: loading
                        ? theme.palette.action.disabledBackground
                        : theme.palette.primary.main,
                      borderRadius: "8px",
                      color: loading
                        ? theme.palette.action.disabled
                        : theme.palette.primary.light,
                      textTransform: "capitalize",
                      width: "129px",
                      "&:hover": {
                        backgroundColor: loading
                          ? theme.palette.action.disabledBackground
                          : theme.palette.primary.main,
                        color: theme.palette.primary.light,
                      },
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Send"
                    )}
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      ) : (
        // Display mode with scrollable content
        <Stack gap={1}>
          <Stack alignItems="center" gap={1}>
            <img
              src={review?.userInfo?.profilePicture || anonymousPic}
              alt={review?.userInfo?.name}
              loading="lazy"
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "18px",
                  textTransform: "capitalize",
                  color: theme.palette.dark.darkBlack,
                }}
              >
                {review?.userInfo?.name || "Anonymous"}
              </Typography>
              <VerifiedBox />
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="center" spacing={1}>
            {[1, 2, 3, 4, 5].map((star) => (
              <FaStar
                key={star}
                style={{
                  color:
                    star <= review?.rating
                      ? theme.palette.status.star
                      : theme.palette.neutral.paleGray,
                  fontSize: "32px",
                }}
              />
            ))}
          </Stack>

          <Typography
            variant="body2"
            sx={{
              color: theme.palette.neutral.MoustacheGrey,
              lineHeight: "28px",
              maxHeight: "140px",
              overflowY: "auto",
              textAlign: "center",
              padding: "0 5px",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                borderRadius: "4px",
                backgroundColor: theme.palette.primary.slightGray,
              },
            }}
          >
            {review?.comment}
          </Typography>
        </Stack>
      )}
    </Modal>
  );
};

export default ReviewModal;
