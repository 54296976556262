import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import express from "../../../assets/Icons/express.png";
import paypal from "../../../assets/Icons/paypal.png";
import mastercard from "../../../assets/Icons/mastercard.png";
import visa from "../../../assets/Icons/visa.png";
import { useSelector } from "react-redux";
import { createCheckoutLink } from "./createCheckoutLink";
import Loader from "../../../components/common/Loader";
import { useState } from "react";

const SummaryBox = ({
  theme,
  currentUser,
  selectedAddressId,
  allProductsData,
}) => {
  const navigate = useNavigate();

  const { data, loading, error } = useSelector(
    (state) => state.appState.checkout
  );
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

  const handleCheckout = async () => {
    const invalidItems = allProductsData.filter(
      (item) =>
        item.contactForPrice ||
        !item.isOnline ||
        item.isSold ||
        item.isDeleted ||
        item.isDraft
    );

    if (invalidItems.length > 0) {
      message.warning(
        "Some items in your cart are unavailable for checkout. Please remove or contact the seller."
      );
      return;
    }

    if (!currentUser && !currentUser?.uid) {
      navigate("/login");
      return;
    }

    if (!data) {
      message.warning("you have to put items on the card");
      return;
    }

    if (!selectedAddressId) {
      message.warning("Please select a shipping address.");
      return;
    }

    try {
      setIsCheckoutLoading(true);
      await createCheckoutLink(currentUser?.uid, selectedAddressId);
    } catch (error) {
      console.error("Error during checkout:", error);
    } finally {
      setIsCheckoutLoading(false);
    }
  };
  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: theme.palette.primary.light,
        borderRadius: "16px",
        boxShadow: `4px 7px 60px 0px ${theme.palette.customShadows.darkShadow}`,
        textAlign: "center",
        width: { xl: "20.563rem", xs: "100%" },
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              color: theme.palette.primary.lightBlueBlack,
              marginBottom: "8px",
            }}
          >
            <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
              subtotal:
            </Typography>
            <Typography variant="body2">
              ${data?.isSuccess && data?.data?.subtotal?.toFixed(2)}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              color: theme.palette.primary.lightBlueBlack,
              marginBottom: "8px",
            }}
          >
            <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
              shipping cost:
            </Typography>
            <Typography variant="body1">
              {data?.isSuccess &&
                (data?.data?.totalShippingCost === 0
                  ? "Free"
                  : `$${data?.data?.total_shipping_cost?.toFixed(2)}`)}
            </Typography>
          </Stack>
          <Divider variant="middle" sx={{ marginY: "20px" }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              color: theme.palette.primary.darkBlack,
              marginBottom: "20px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "600" }}>
              Total:
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "600" }}>
              ${data?.isSuccess && data?.data?.total?.toFixed(2)}
            </Typography>
          </Stack>

          <Button
            onClick={handleCheckout}
            variant="contained"
            disabled={isCheckoutLoading}
            sx={{
              width: "100%",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: theme.palette.primary.main,
              textTransform: "capitalize",
              fontWeight: "500",
              marginBottom: "15px",
            }}
          >
            {isCheckoutLoading ? "Processing..." : "Purchase Now"}
          </Button>

          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={express}
              alt="american express logo"
              loading="lazy"
              style={{ objectFit: "cover" }}
            />
            <img
              src={mastercard}
              alt="mastercard  logo"
              loading="lazy"
              style={{ objectFit: "cover" }}
            />
            {/* <img
              src={paypal}
              alt="paypal logo"
              loading="lazy"
              style={{ objectFit: "cover" }}
            /> */}
            <img
              src={visa}
              alt="visa logo"
              loading="lazy"
              style={{ objectFit: "cover" }}
            />
            {/* <img
              src={applepay}
              alt="applePay logo"
              loading="lazy"
              style={{ objectFit: "cover" }}
            /> */}
          </Stack>
        </>
      )}
    </Box>
  );
};

export default SummaryBox;
