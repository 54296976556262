import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Box, Typography, Tabs, Tab, useTheme, Button } from "@mui/material";
import Loader from "../../components/common/Loader";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { db } from "../../configs/firebase";
import CustomPagination from "../../components/common/CustomPagention";
import Avatar from "../sellers/component/Avatar";
import { useNavigate } from "react-router-dom";
import box from "../../assets/Images/nothing_here.png";
const Following = () => {
  const theme = useTheme();
  const productsPerPage = 6;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(() => {
    return Number(localStorage.getItem("activeTab")) || 0;
  });
  const [followings, setFollowings] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const userData = useSelector((state) => state.appState.userData);
  
  const mapUserTypeToTab = (userType) => {
    switch (userType) {
      case "serviceProvider":
        return "Services Providers";
      case "seller":
        return "Sellers";
      case "all":
        return "All";
      default:
        return "Unknown";
    }
  };
  
  useEffect(() => {
    if (
      userData &&
      !(Array.isArray(userData) && userData?.length === 0) &&
      typeof userData === "object" &&
      userData?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    localStorage.setItem("activeTab", newValue);
    setIsLoading(true);
    setCurrentPage(1);
    setFollowings([]);
    setFollowers([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (activeTab === 0) {
        await fetchFollowings();
      } else {
        await fetchFollowers();
      }

      setIsLoading(false);
    };

    fetchData();
  }, [activeTab]);

  const fetchFollowings = async () => {
    setIsLoading(true);
    const followingsArray = [];

    try {
      const currentUserId = userData?.uid;
      const usersRef = collection(db, "users");

      const usersSnapshot = await getDocs(query(usersRef, orderBy("createdAt", "desc")));

      if (usersSnapshot.docs && usersSnapshot.docs.length > 0) {
        const followingsPromises = usersSnapshot.docs.map(async (userDoc) => {
          const followerRef = collection(db, `users/${userDoc.id}/followers`);
          const followersSnapshot = await getDocs(
            query(followerRef, where("uid", "==", currentUserId))
          );

          if (!followersSnapshot.empty) {
            followingsArray.push({
              uid: userDoc.id,
              ...userDoc.data(),
            });
          }
        });
        await Promise.all(followingsPromises);
      }
      if (followingsArray?.length > 0) {
        setFollowings(followingsArray);
      }
    } catch (error) {
      console.error("Error fetching followings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFollowers = async () => {
    setIsLoading(true);

    try {
      const currentUserId = userData?.uid;
      const followersRef = collection(db, `users/${currentUserId}/followers`);
      const followersSnapshot = await getDocs(followersRef);

      const userPromises = followersSnapshot.docs.map(async (doc) => {
        const followerData = { uid: doc.id, ...doc.data() };

        const userRef = collection(db, "users");
        const userDoc = await getDocs(
          query(userRef, where("uid", "==", followerData?.uid))
        );

        if (!userDoc.empty) {
          const userData = userDoc.docs[0].data();
          followerData.userType = userData?.userType;
        } else {
          followerData.userType = "Unknown";
        }

        return followerData;
      });

      const followersWithUserType = await Promise.all(userPromises);
      setFollowers(followersWithUserType);
    } catch (error) {
      console.error("Error fetching followers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const indexOfLastItem = currentPage * productsPerPage;
  const indexOfFirstItem = indexOfLastItem - productsPerPage;
  const displayedData =
    activeTab === 0
      ? followings.slice(indexOfFirstItem, indexOfLastItem)
      : followers.slice(indexOfFirstItem, indexOfLastItem);


  const handlePageChange = (event, value) => {
    window.scrollTo({ top: 500, behavior: "smooth" });
    setCurrentPage(value);
  };

  const goToSellerPage = (id, userType) => {
    const selectedTab = mapUserTypeToTab(userType);
    if (isLoggedIn) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(`/sellers/seller-details/${id}`, { state: { selectedTab } });
    } else {
      navigate("/login");
    }
    
  };

 //navigate to chat page and send the current user  object with it
 const handleChatButtonClick = (filteredUser) => {
  if (isLoggedIn) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/chat?type=users", { state: { user: filteredUser } });
  } else {
    navigate("/login");
  }
};

console.log(displayedData, 'displayedData')
  return (
    <section style={{ margin: "0px 35px" }}>
      <Box
        sx={{
          background: theme.palette.primary.light,
          padding: 3,
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{ fontFamily: theme.typography.headerFont }}
          >
            Your Followed Truck Parts
          </Typography>
          <Typography
            variant="h5"
            sx={{ mb: 5, fontFamily: theme.typography.headerFont }}
          >
            Stay Updated on Truck Parts
          </Typography>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            centered
          >
            <Tab
              label="Followings"
              sx={{
                textTransform: "capitalize",
                fontSize: "1.2rem",
                marginRight: "30px",
              }}
            />
            <Tab
              label="Followers"
              sx={{
                textTransform: "capitalize",
                fontSize: "1.2rem",
                marginRight: "20px",
              }}
            />
          </Tabs>
        </Box>

        <Box>
          {isLoading && !imageLoaded ? (
            <Loader />
          ) : displayedData?.length > 0 ? (
            displayedData?.map((item, index) => (
              <Box
                key={item?.uid || index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderBottom: "1px solid #ddd",
                  flexDirection: { xs: "column", sm: "row" },
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                <Box sx={{ width: "80px", height: "80px" }}>
                  {item?.profilePicture ? (
                    <img
                      src={item?.profilePicture}
                      alt={item?.businessInfo?.name || "User"}
                      loading="lazy"
                      onLoad={handleImageLoad}
                      onClick={() => goToSellerPage(item?.uid, item?.userType)}
                      style={{
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <Avatar
                      name={item?.businessInfo?.name || item?.firstName || "User"}
                      borderRadius="50%"
                      fontSize="3rem"
                      uid={item?.uid}
                      userType={item?.userType}
                      goToPage={goToSellerPage}
                    />
                  )}
                </Box>
                <Box sx={{ flex: 1, ml: { sm: 2 }, mt: { xs: 2, sm: 0 } }}>
                  <Typography variant="h6" component="div"
                  onClick={() => goToSellerPage(item?.uid, item?.userType)}
                  sx={{
                    cursor: item?.uid && item?.userType ? "pointer" : "default", // Conditionally set the cursor style
                  }}
                  >
                    {item?.businessInfo?.name || item?.name ||
                      `${item?.firstName} ${item?.lastName}`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                  {activeTab === 0 ? "Following" : "Follower"} since{" "}
                    {item.createdAt
                      ? format(item.createdAt.toDate(), "MMM yyyy")
                      : "Unknown"}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    onClick={() => handleChatButtonClick(item)}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                      fontSize: "14px",
                      padding: "8px 16px",
                      textTransform: "capitalize",
                      borderRadius: "8px",
                      marginTop: { xs: "15px", md: "0" },
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                  >
                    Contact
                  </Button>
                </Box>
              </Box>
            ))
          ) : (
            <>
              <img src={box} alt="empty box" />
              <Typography>
                No {activeTab === 0 ? "followings" : "followers"} found.
              </Typography>
            </>
          )}
        </Box>

        {/* Pagination Component */}
        <CustomPagination
          totalPages={Math.ceil(
            (activeTab === 0 ? followings.length : followers.length) /
              productsPerPage
          )}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </section>
  );
};

export default Following;
