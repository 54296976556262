import React, { useEffect, useState } from "react";
import { CircularProgress, Box, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const CircleProgress = ({ step }) => {
  const theme = useTheme();
  const location = useLocation();
  const [totalSteps, setTotalSteps] = useState(3);
  const userData = useSelector((state) => state.appState.userData);

  useEffect(() => {
    const initialSetup = sessionStorage.getItem("initialSetup");

    if (userData?.userType !== "buyer") {
      sessionStorage.setItem("initialSetup", "false");
      setTotalSteps(3);
    } else if (
      location.pathname === "/selling/add-business-info" ||
      initialSetup === "true"
    ) {
      setTotalSteps(4);
      sessionStorage.setItem("initialSetup", "true");
    }
  }, [location.pathname, userData]);

  const progressValue = (step / totalSteps) * 100;

  return (
    <Box position="relative" display="inline-flex">
      {/* CircularProgress for uncompleted part */}
      <CircularProgress
        variant="determinate"
        value={100} 
        size={50}
        thickness={3.5}
        style={{
          color: theme.palette.primary.sky,
          position: "absolute",
        }}
      />
      {/* CircularProgress for completed part */}
      <CircularProgress
        variant="determinate"
        value={progressValue}
        size={50}
        thickness={3.5}
        style={{
          color: theme.palette.primary.blueProgress,
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          component="div"
          sx={{
            fontWeight: "800",
            color: theme.palette.primary.blueProgress,
            fontSize: "17px",
            lineHeight: "24.1px",
          }}
        >
          {`${step}/${totalSteps}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircleProgress;
