import React from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { BiMenuAltRight } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import { IoMdHeartEmpty,IoMdCart } from "react-icons/io";
import { BsChatSquareDots } from "react-icons/bs";


const ToolbarComponent = ({
  handleProfileMenuOpen,
  isUserIconVisible,
  isLoggedIn,
  logo,
  styles,
  borderColor,
  color,
  renderMenu,
  renderMobileMenu,
  mobileMenuId,
  menuLinksLargeScreen,
  handleMobileMenuOpen,
  toggleDrawer,
  cartCount,
  favoriteCount,
  messagesCount
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isLargeScreen = useMediaQuery("(max-width: 1200px)");

  // Filter menu items based on login status
  const filteredMenuLinks = menuLinksLargeScreen?.filter(
    (menu) => !menu.logIn || isLoggedIn
  );
  
  return (
    <>
      {isLargeScreen && (
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => navigate("/")}
                style={{ width: "240px" }}
              >
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </IconButton>
            </Box>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              {isUserIconVisible && isLoggedIn && (
                <div onClick={handleProfileMenuOpen}>
                  <IconButton
                    aria-controls={mobileMenuId}
                    color="inherit"
                    style={{
                      border: "1px solid",
                      borderColor: borderColor,
                      borderRadius: "50%",
                      padding: "8px",
                      color: color,
                    }}
                  >
                    <FaUser />
                  </IconButton>
                </div>
              )}

              {renderMenu}
              <Box>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  style={{ background: theme.palette.primary.light }}
                >
                  <BiMenuAltRight
                    style={{ color: theme.palette.secondary.menuHeaderIcone }}
                  />
                </IconButton>
              </Box>
            </div>
          </Box>
        </Toolbar>
      )}
      {!isLargeScreen && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "6px 30px",
            background:
              location.pathname !== "/" && location.pathname !== "/home"
                ? theme.palette.primary.light
                : "none",
          }}
        >
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => navigate("/")}
              style={{ width: "220px" }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </IconButton>
          </Box>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {filteredMenuLinks?.map((menu) => (
              <Typography
                key={menu?.id}
                variant="body2"
                sx={{
                  ...styles.listBtn,
                  ...(location.pathname === menu?.link && styles.activeListBtn),
                }}
                onClick={() => navigate(menu?.link)}
              >
                {menu?.name}
              </Typography>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {isLoggedIn ? (
              <>
               <div
                  onClick={() => {
                    navigate("/explore");
                  }}
                >
                  <IconButton
                    color="inherit"
                    style={{
                      border: "1px solid",
                      borderColor: borderColor,
                      borderRadius: "50%",
                      padding: "8px",
                      marginRight: "20px",
                      color: color,
                    }}
                  >
                    <CiSearch />
                  </IconButton>
                </div>
                <div
                  onClick={() => {
                    navigate("/chats");
                  }}
                >
                  <IconButton
                    color="inherit"
                    style={{
                      border: "1px solid",
                      borderColor: borderColor,
                      borderRadius: "50%",
                      padding: "10px",
                      marginRight: "20px",
                      color: color,
                    }}
                  >
                   
                    <Badge badgeContent={messagesCount} color="primary">
                    <BsChatSquareDots style={{fontSize:"1.2rem"}}/>
                    </Badge>
                  </IconButton>
                </div>
                <div
                  onClick={() => {
                    navigate("/profile?page=favorites");
                  }}
                >
                  <IconButton
                    color="inherit"
                    style={{
                      border: "1px solid",
                      borderColor: borderColor,
                      borderRadius: "50%",
                      padding: "8px",
                      marginRight: "20px",
                      color: color,
                    }}
                  >
                    
                    <Badge badgeContent={favoriteCount} color="primary">
                    <IoMdHeartEmpty />
                    </Badge>
                    {/* <IoMdHeartEmpty /> */}
                  </IconButton>
                </div>
                 <div onClick={toggleDrawer(true)}>
                 <IconButton
                    color="inherit"
                    style={{
                      border: "1px solid",
                      borderColor: borderColor,
                      borderRadius: "50%",
                      padding: "8px",
                      marginRight: "20px",
                      color: color,
                    }}
                  >
                     <Badge badgeContent={cartCount} color="primary">
                      <IoMdCart />
                    </Badge>
                  </IconButton>
                 </div>
                <div onClick={handleProfileMenuOpen}>
                  <IconButton
                    aria-controls={mobileMenuId}
                    color="inherit"
                    style={{
                      border: "1px solid",
                      borderColor: borderColor,
                      borderRadius: "50%",
                      padding: "8px",
                      color: color,
                    }}
                  >
                    <FaUser />
                  </IconButton>
                </div>
                {renderMenu}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    navigate("/explore");
                  }}
                >
                  <IconButton
                    color="inherit"
                    style={{
                      border: "1px solid",
                      borderColor: borderColor,
                      borderRadius: "50%",
                      padding: "8px",
                      marginRight: "6px",
                      color: color,
                    }}
                  >
                    <CiSearch />
                  </IconButton>
                </div>
                <Button
                  onClick={() => navigate("/logIn")}
                  sx={{
                    background: theme.palette.primary.main,
                    padding: "10px 30px",
                    borderRadius: "16px",
                    border: `1px solid ${theme.palette.primary.main}`,
                    textTransform: "capitalize !important",
                    fontWeight: 600,
                    "&:hover": {
                      background: theme.palette.primary.main,
                    },
                    color: theme.palette.primary.light,
                  }}
                >
                  Login
                </Button>
                <Button
                  variant="contain"
                  onClick={() => navigate("/signUp")}
                  sx={{
                    background: "transparent",
                    padding: "10px 30px",
                    borderRadius: "16px",
                    border:
                      location.pathname !== "/" && location.pathname !== "/home"
                        ? `1px solid ${theme.palette.primary.grey}`
                        : `1px solid ${theme.palette.primary.light}`,
                    textTransform: "capitalize",
                    color:
                      location.pathname !== "/" && location.pathname !== "/home"
                        ? theme.palette.primary.darkColor
                        : theme.palette.primary.light,
                    fontWeight: 600,
                  }}
                >
                  Sign Up
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {renderMobileMenu}
    </>
  );
};

export default ToolbarComponent;
