// socialLinks.js
export const SOCIAL_LINKS = {
  facebook: "https://www.facebook.com/share/1C23LKSKDq/?mibextid=LQQJ4d",
  instagram:
    "https://www.instagram.com/truckpartsmart?igsh=anJwbHRoZ3prYTFk&utm_source=qr",
};

// links.js
export const APP_LINKS = {
  appleStore:
    "https://apps.apple.com/us/app/truckpartsmart/id6499494008?platform=iphone",
  googlePlay:
    "https://play.google.com/store/apps/details?id=com.zetaton.truckparts",
};
