import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import { Field, Formik, Form } from "formik";
import Cart from "./component/Cart";
import StreetAddress from "../../components/common/StreetAddress";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { db } from "../../configs/firebase";
import { message } from "antd";
import Loader from "../../components/common/Loader";
import { useNavigate } from "react-router-dom";
import visa from "../../assets/Images/Visa.png";
import cash from "../../assets/Images/cash.png";
import PhoneNumberInput from "../../components/common/PhoneNumberInput";

const CheckOut = () => {
  const theme = useTheme();

  const [filteredItemsBySeller, setFilteredItemsBySeller] = useState({});
  const [totalBySeller, setTotalBySeller] = useState({});
  const [sellerData, setSellerData] = useState([]);
  const [sellerIds, setSellerIds] = useState([]);
  const userData = useSelector((state) => state.appState.userData);
  const [addressError, setAddressError] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressToShow, setStreetAddressToShow] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [productData, setProductData] = useState([]);
  const [selectedDeliveryOptions, setSelectedDeliveryOptions] = useState({});
  const navigate = useNavigate();


  const name = userData?.businessInfo?.businessName
  ?? userData?.businessInfo?.name
  ?? (userData?.firstName && userData?.lastName
    ? `${userData.firstName} ${userData.lastName}`
    : userData?.firstName ?? userData?.lastName ?? userData?.name ?? null);


  const handleCartData = (
    filteredItems,
    totals,
    sellers,
    ids,
    productData,
    selectedDeliveryOptions
  ) => {
    setFilteredItemsBySeller(filteredItems);
    setTotalBySeller(totals);
    setSellerData(sellers);
    setSellerIds(ids);
    setProductData(productData);
    setSelectedDeliveryOptions(selectedDeliveryOptions);
  };

  const checkOutSchema = Yup.object().shape({
    phone_number: Yup.string()
      .required("Mobile number is required")
      .matches(
        /^(\+1\s?)?(\(?[2-9]\d{2}\)?[\s.-]?)?[2-9]\d{2}[\s.-]?\d{4}$/,
        "Please enter a valid phone number"
      )
      .min(10, "Mobile number must be at least 10 digits")
      .max(15, "Mobile number must not exceed 15 digits"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    city: Yup.string()
      .required("City is required")
      .min(2, "City must be at least 2 characters")
      .max(30, "City must not exceed 30 characters"),
    state: Yup.string()
      .required("State is required")
      .min(2, "State must be at least 2 characters")
      .max(30, "State must not exceed 30 characters"),
    zipCode: Yup.string()
      .matches(/^[0-9]{5}$/, "zipCode must be 5 digits")
      .required("zipCode is required"),
    address: Yup.object().required("Address is required"),
  });

  const isEmptyObject = (obj) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

  const isUserDataValid =
    userData && !Array.isArray(userData) && !isEmptyObject(userData);

  useEffect(() => {
    if (userData?.address?.addressLineOne) {
      setStreetAddressToShow(userData?.address.addressLineOne);
      setStreetAddress(userData?.address);
    }
  }, [userData]);

  const handleSubmit = async (values, { resetForm }) => {
    const generateOrderNo = () => {
      const uuid = uuidv4();
      const randomNumbers = uuid.replace(/\D/g, "").slice(0, 5);
      return `#${randomNumbers}`;
    };
    const unavailableItems = productData?.filter(
      (product) =>
        product?.isSold || product?.isDraft || product?.contactForPrice
    );
    if (unavailableItems?.length > 0) {
      message.warning(
        "Some items in your cart are unavailable. Please remove them before confirming your order."
      );
      console.log(unavailableItems, "unavailableItems");
      return;
    }

    if (!streetAddress && !userData?.address?.addressLineOne) {
      setAddressError("Address is required");
      return;
    }

    const orderItems = Object.keys(filteredItemsBySeller).map((sellerId) => {
      const sellerInfo = sellerData.find((seller) => seller.uid === sellerId);
      const totals = totalBySeller[sellerId] || {
        subtotal: 0,
        shipping_price: 0,
        total: 0,
      };

      return {
        seller_id: sellerId,
        order_items: filteredItemsBySeller[sellerId],
        seller_info: {
          email: sellerInfo?.businessInfo?.email || sellerInfo?.email || null,
          id: sellerInfo?.uid || "",
          name: sellerInfo?.businessInfo?.name || null,
          phone_number:
            sellerInfo?.businessInfo?.phone_number ||
            sellerInfo.phoneNumber ||
            null,
          profile_picture: sellerInfo?.profilePicture || null,
        },
        subtotal: totals?.subtotal,
        shipping_price: totals?.shipping_price,
        total: totals?.total,
      };
    });

    setisLoading(true);

    try {
      for (const item of orderItems) {
        const order_no = generateOrderNo();

        const orderData = {
          buyer_id: isUserDataValid && userData?.uid,
          buyer_info: {
            email:
              values.email ??
              userData?.businessInfo?.email ??
              userData?.email ??
              null,
            name: name,
              // userData?.businessInfo?.name ??
              // (userData?.firstName && userData?.lastName
              //   ? `${userData.firstName} ${userData.lastName}`
              //   : userData?.firstName ?? userData?.lastName ?? null),
            id: userData?.uid ?? null,
            phone_number:
              values?.phone_number ??
              userData?.businessInfo?.phoneNumber ??
              userData?.phoneNumber ??
              null,
            profile_picture:
              userData?.businessInfo?.profilePicture ??
              userData?.profilePicture ??
              null,
          },
          delivery_address: {
            ...streetAddress,
            city: values.city
              ? values.city
              : streetAddress?.city
              ? streetAddress.city
              : (isUserDataValid && userData?.address?.city) || "",
            state: values.state
              ? values.state
              : streetAddress?.state
              ? streetAddress.state
              : (isUserDataValid && userData?.address?.state) || "",
            zipCode: values.zipCode
              ? values.zipCode
              : streetAddress?.zipCode
              ? streetAddress.zipCode
              : (isUserDataValid && userData?.address?.zipCode) || "",
          },
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          payment_method: values?.payment_method || "",
          order_no,
          last4: "",
          order_items: item?.order_items,
          seller_id: item?.seller_id,
          seller_info: item?.seller_info,
          subtotal: item?.subtotal,
          total_shipping_cost: item?.shipping_price,
          total: item?.total,
         
          status: "pending",
          total_tax_amount: 0,
          isReviewed: false,
        };

        const docRef = doc(collection(db, "orders"));
        orderData.id = docRef.id;
        await setDoc(docRef, orderData);
      }

      // Clear the user's cart in the database after order placement
      const userDocRef = doc(db, "users", userData.uid);
      await updateDoc(userDocRef, {
        cart: {
          items: [],
        },
      });

      setIsCartEmpty(true);

      message.success({
        content:
          "Your order has been successfully placed! We'll keep you updated on its progress.",
        duration: 5,
      });
      resetForm();
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/");
    } catch (e) {
      console.error("Error adding/updating document: ", e);
      message.error({
        content: "An error occurred. Please try again.",
        duration: 5,
      });
    } finally {
      setTimeout(() => {
        setisLoading(false);
      }, 50000);
    }
  };

  const handleSelectStreet = async (address, placeId, setFieldValue) => {
    setAddressError("");
    if (!address) {
      setAddressError("Address is required");
      setStreetAddress("");
      setStreetAddressToShow("");
      return;
    }
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const selectedPlaceData = {
        city:
          results[0]?.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          results[0]?.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        zipCode:
          results[0]?.address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "",
        lat: latLng.lat,
        lng: latLng.lng,
        route:
          results[0]?.address_components.find((component) =>
            component.types.includes("route")
          )?.long_name || "",
        street_number:
          results[0]?.address_components.find((component) =>
            component.types.includes("street_number")
          )?.long_name || "",
        primary_line: results[0]?.formatted_address || "",
        placeId: placeId || "",
        id: placeId || Date.now(),
        addressLineOne: address || "",
        addressLineTwo: "",
        isDefault: false,
        name: "Home",
      };
      setFieldValue("address", selectedPlaceData);
      setFieldValue("city", selectedPlaceData?.city);
      setFieldValue("state", selectedPlaceData?.state);
      setFieldValue("zipCode", selectedPlaceData?.zipCode);

      setStreetAddress(selectedPlaceData);
      setStreetAddressToShow(selectedPlaceData?.addressLineOne);
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };

  return (
    <section style={{ margin: "0 15px" }}>
      <Formik
        initialValues={{
          // name: isUserDataValid
          //   ? `${userData?.firstName || ""} ${userData?.lastName || ""}`
          //   : "",
          phone_number: isUserDataValid ? userData?.phoneNumber : "",
          email: isUserDataValid ? userData?.email : "",
          address: userData?.address
            ? userData?.address
            : streetAddressToShow || "",
          city: userData?.address ? userData?.address?.city : "",
          zipCode:
            isUserDataValid && userData?.address
              ? userData?.address?.zipCode || ""
              : "",
          state:
            isUserDataValid && userData?.address
              ? userData?.address?.state || ""
              : "",
          payment_method: "cash",
        }}
        validationSchema={checkOutSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <Grid
              container
              spacing={3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: { xs: "18px", md: "0px" },
              }}
            >
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: `calc(100% + 16px)`,
                    padding: "10px",
                    borderRadius: "8px 8px 0 0",
                    marginBottom: "16px",
                    background: theme.palette.neutral.paleGray,
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 700,
                      color: theme.palette.primary.lightDoveGrey,
                    }}
                  >
                    Delivery Information
                  </Typography>
                </Box>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    background: theme.palette.primary.light,
                    marginLeft: "0px !important",
                    padding: "10px 20px 20px 5px",
                    borderRadius: "0px 0 8px 8px",
                    // width: `calc(95% + 16px)`,
                  }}
                >
                  {/* <Grid item xs={12} md={6} mb={2}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: ".75rem",
                        lineHeight: "1.313rem",
                        color: theme.palette.gray.colorfeild,
                      }}
                    >
                      Full Name *
                    </Typography>
                    <Field
                      name="name"
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        inputValue = inputValue.replace(/\s+/g, " ");
                        setFieldValue("name", inputValue);
                      }}
                      style={{
                        width: "100%",
                        padding: "13px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        color: theme.palette.primary.darkColor,
                        border: `1px solid ${theme.palette.gray.borderFaild}`,
                        marginTop: "8px",
                        borderRadius: "10px",
                        background: theme.palette.primary.light,
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                      onBlur={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                    />
                    {errors.name && touched.name ? (
                      <div
                        style={{
                          color: theme.palette.status.error,
                          fontSize: "12px",
                          padding: "4px 10px",
                        }}
                      >
                        {errors.name}
                      </div>
                    ) : null}
                  </Grid> */}
                  <Grid item xs={12} md={6} mb={2}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: ".75rem",
                        lineHeight: "1.313rem",
                        color: theme.palette.gray.colorfeild,
                      }}
                    >
                      Mobile Number *
                    </Typography>
                    {/* <Field
                      name="phone_number"
                      onChange={(e) => {
                        let inputValue = e.target.value;
                       
                        setFieldValue("phone_number", inputValue);
                      }}
                      style={{
                        width: "100%",
                        padding: "13px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        color: theme.palette.primary.darkColor,
                        border: `1px solid ${theme.palette.gray.borderFaild}`,
                        marginTop: "8px",
                        borderRadius: "10px",
                        background: theme.palette.primary.light,
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                      onBlur={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                    /> */}
                    <PhoneNumberInput
                      fullWidth
                      inputStyles={{ marginTop: "4px" }}
                      fieldsetStyles={{
                        marginTop: "8px",
                        height: "55px",
                        borderColor: theme.palette.gray.borderFaild,
                      }}
                      name="phone_number"
                      placeholder="Enter your Phone Number"
                      value={values.phone_number}
                      onBlur={(e) => {
                        e.target.style.outline = "none";
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = "none";
                      }}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        setFieldValue("phone_number", inputValue);
                      }}
                      error={
                        touched.phone_number && Boolean(errors.phone_number)
                      }
                    />
                    {errors.phone_number && touched.phone_number ? (
                      <div
                        style={{
                          color: theme.palette.status.error,
                          fontSize: "12px",
                          padding: "4px 10px",
                        }}
                      >
                        {errors.phone_number}
                      </div>
                    ) : null}
                    {values.phoneError && (
                      <div
                        style={{
                          color: theme.palette.status.error,
                          fontSize: "12px",
                          padding: "4px 10px",
                        }}
                      >
                        {values.phoneError}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} mb={2}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: ".75rem",
                        lineHeight: "1.313rem",
                        color: theme.palette.gray.colorfeild,
                      }}
                    >
                      Email *
                    </Typography>
                    <Field
                      name="email"
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        inputValue = inputValue.replace(/\s+/g, " ");
                        setFieldValue("email", inputValue);
                      }}
                      style={{
                        width: "100%",
                        padding: "13px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        color: theme.palette.primary.darkColor,
                        border: `1px solid ${theme.palette.gray.borderFaild}`,
                        marginTop: "8px",
                        borderRadius: "10px",
                        background: theme.palette.primary.light,
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                      }}
                      onBlur={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                    />
                    {errors.email && touched.email ? (
                      <div
                        style={{
                          color: theme.palette.status.error,
                          fontSize: "12px",
                          padding: "4px 10px",
                        }}
                      >
                        {errors.email}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6} mb={2} mt={2}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: ".75rem",
                        lineHeight: "1.313rem",
                        color: theme.palette.gray.colorfeild,
                        position: "relative",
                        top: "-15px",
                      }}
                    >
                      Address *
                    </Typography>
                    <StreetAddress
                      handleSelect={(address, placeId) => {
                        handleSelectStreet(address, placeId, setFieldValue);
                      }}
                      setAddressToShow={setStreetAddressToShow}
                      setAddress={setStreetAddress}
                      address={streetAddressToShow}
                      borderColor={`1px solid ${theme.palette.gray.borderFaild}`}
                      padding="13px"
                      marginTop="-8px"
                      backgroundColor={theme.palette.primary.light}
                      onFocus={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                      }}
                      onBlur={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                    />
                    {(errors.address && touched.address) ||
                    (addressError && addressError?.length > 0) ? (
                      <div
                        style={{
                          color: theme.palette.status.error,
                          fontSize: "12px",
                          padding: "4px 10px",
                        }}
                      >
                        {errors.address && touched.address
                          ? errors.address
                          : addressError}
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={6} mb={2}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: ".75rem",
                        lineHeight: "1.313rem",
                        color: theme.palette.gray.colorfeild,
                      }}
                    >
                      City *
                    </Typography>
                    <Field
                      name="city"
                      value={values.city}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        inputValue = inputValue.replace(/\s+/g, " ");
                        setFieldValue("city", inputValue);
                      }}
                      style={{
                        width: "100%",
                        padding: "13px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        color: theme.palette.primary.darkColor,
                        border: `1px solid ${theme.palette.gray.borderFaild}`,
                        marginTop: "8px",
                        borderRadius: "10px",
                        background: theme.palette.primary.light,
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                      }}
                      onBlur={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                    />
                    {errors.city && touched.city ? (
                      <div
                        style={{
                          color: theme.palette.status.error,
                          fontSize: "12px",
                          padding: "4px 10px",
                        }}
                      >
                        {errors.city}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6} mb={2}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: ".75rem",
                        lineHeight: "1.313rem",
                        color: theme.palette.gray.colorfeild,
                      }}
                    >
                      State *
                    </Typography>

                    <Field
                      name="state"
                      value={values.state}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        inputValue = inputValue.replace(/\s+/g, " ");
                        setFieldValue("state", inputValue);
                      }}
                      style={{
                        width: "100%",
                        padding: "13px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        color: theme.palette.primary.darkColor,
                        border: `1px solid ${theme.palette.gray.borderFaild}`,
                        marginTop: "8px",
                        borderRadius: "10px",
                        background: theme.palette.primary.light,
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                      }}
                      onBlur={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                    />
                    {errors.state && touched.state ? (
                      <div
                        style={{
                          color: theme.palette.status.error,
                          fontSize: "12px",
                          padding: "4px 10px",
                        }}
                      >
                        {errors.state}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6} mb={2}>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: ".75rem",
                        lineHeight: "1.313rem",
                        color: theme.palette.gray.colorfeild,
                      }}
                    >
                      Zip Code *
                    </Typography>
                    <Field
                      name="zipCode"
                      value={values.zipCode}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        inputValue = inputValue.replace(/\D|\s+/g, "");
                        setFieldValue("zipCode", inputValue);
                      }}
                      style={{
                        width: "100%",
                        padding: "13px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "21px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        color: theme.palette.primary.darkColor,
                        border: `1px solid ${theme.palette.gray.borderFaild}`,
                        marginTop: "8px",
                        borderRadius: "10px",
                        background: theme.palette.primary.light,
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                      }}
                      onBlur={(e) => {
                        e.target.style.outline = "none";
                        e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                      }}
                    />
                    {errors.zipCode && touched.zipCode ? (
                      <div
                        style={{
                          color: theme.palette.status.error,
                          fontSize: "12px",
                          padding: "4px 10px",
                        }}
                      >
                        {errors.zipCode}
                      </div>
                    ) : null}
                  </Grid>

                  {/* Payment Method */}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    background: theme.palette.primary.light,
                    marginTop: "20px",
                    marginLeft: "0 !important",
                    borderRadius: "10px",
                    // width: `calc(96% + 14px)`,
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        padding: "10px",
                        borderRadius: "8px 8px 0 0",
                        background: theme.palette.neutral.paleGray,
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 700,
                          color: theme.palette.primary.lightDoveGrey,
                        }}
                      >
                        Payment Method
                      </Typography>
                    </Box>
                    <Box sx={{ padding: "26px" }}>
                      <RadioGroup style={{ marginBottom: "10px" }}>
                        <Box
                          sx={{
                            borderRadius: "15px",
                            border: `1px solid ${theme.palette.gray.borderColor}`,
                            background: theme.palette.gray.grayBackground,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px 20px",
                            }}
                          >
                            <FormControlLabel
                              value="creditCard"
                              control={<Radio disabled />}
                              label={
                                <div>
                                  <span>Credit Card</span>
                                </div>
                              }
                            />
                            <div>
                              <img
                                src={visa}
                                alt="Visa"
                                style={{ height: "24px", marginLeft: "10px" }}
                              />
                            </div>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            sx={{ padding: "20px", display: "none" }}
                          >
                            <Grid item xs={12} md={6} mb={2}>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: ".75rem",
                                  lineHeight: "1.313rem",
                                  color: theme.palette.gray.colorfeild,
                                }}
                              >
                                Card Number *
                              </Typography>

                              <Field
                                name="cardnumber"
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  inputValue = inputValue.replace(/\s+/g, " ");
                                  setFieldValue("cardnumber", inputValue);
                                }}
                                style={{
                                  width: "100%",
                                  padding: "13px",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  lineHeight: "21px",
                                  letterSpacing: "-0.02em",
                                  textAlign: "left",
                                  color: theme.palette.primary.darkColor,
                                  border: `1px solid ${theme.palette.gray.borderFaild}`,
                                  marginTop: "8px",
                                  borderRadius: "10px",
                                  background: theme.palette.primary.light,
                                }}
                                onFocus={(e) => {
                                  e.target.style.outline = "none";
                                  e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                                }}
                                onBlur={(e) => {
                                  e.target.style.outline = "none";
                                  e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                                }}
                              />
                              {/* {errors.email && touched.email ? (
                    <div
                      style={{
                        color: theme.palette.status.error,
                        fontSize: "12px",
                        padding: "4px 10px",
                      }}
                    >
                      {errors.email}
                    </div>
                  ) : null} */}
                            </Grid>
                            <Grid item xs={12} md={6} mb={2}>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: ".75rem",
                                  lineHeight: "1.313rem",
                                  color: theme.palette.gray.colorfeild,
                                }}
                              >
                                Expire Date *
                              </Typography>

                              <Field
                                name="expiredate"
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  inputValue = inputValue.replace(/\s+/g, " ");
                                  setFieldValue("expiredate", inputValue);
                                }}
                                style={{
                                  width: "100%",
                                  padding: "13px",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  lineHeight: "21px",
                                  letterSpacing: "-0.02em",
                                  textAlign: "left",
                                  color: theme.palette.primary.darkColor,
                                  border: `1px solid ${theme.palette.gray.borderFaild}`,
                                  marginTop: "8px",
                                  borderRadius: "10px",
                                  background: theme.palette.primary.light,
                                }}
                                onFocus={(e) => {
                                  e.target.style.outline = "none";
                                  e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                                }}
                                onBlur={(e) => {
                                  e.target.style.outline = "none";
                                  e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                                }}
                              />
                              {/* {errors.email && touched.email ? (
                    <div
                      style={{
                        color: theme.palette.status.error,
                        fontSize: "12px",
                        padding: "4px 10px",
                      }}
                    >
                      {errors.email}
                    </div>
                  ) : null} */}
                            </Grid>

                            <Grid item xs={12} md={6} mb={2}>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: ".75rem",
                                  lineHeight: "1.313rem",
                                  color: theme.palette.gray.colorfeild,
                                }}
                              >
                                Card Holder *
                              </Typography>
                              <Field
                                name="cardholder"
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  inputValue = inputValue.replace(/\s+/g, " ");
                                  setFieldValue("cardholder", inputValue);
                                }}
                                style={{
                                  width: "100%",
                                  padding: "13px",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  lineHeight: "21px",
                                  letterSpacing: "-0.02em",
                                  textAlign: "left",
                                  color: theme.palette.primary.darkColor,
                                  border: `1px solid ${theme.palette.gray.borderFaild}`,
                                  marginTop: "8px",
                                  borderRadius: "10px",
                                  background: theme.palette.primary.light,
                                }}
                                onFocus={(e) => {
                                  e.target.style.outline = "none";
                                  e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                                }}
                                onBlur={(e) => {
                                  e.target.style.outline = "none";
                                  e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                                }}
                              />
                              {/* {errors.email && touched.email ? (
                    <div
                      style={{
                        color: theme.palette.status.error,
                        fontSize: "12px",
                        padding: "4px 10px",
                      }}
                    >
                      {errors.email}
                    </div>
                  ) : null} */}
                            </Grid>
                            <Grid item xs={12} md={6} mb={2}>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: ".75rem",
                                  lineHeight: "1.313rem",
                                  color: theme.palette.gray.colorfeild,
                                }}
                              >
                                CVC *
                              </Typography>
                              <Field
                                name="cvc"
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  inputValue = inputValue.replace(/\s+/g, " ");
                                  setFieldValue("cvc", inputValue);
                                }}
                                style={{
                                  width: "100%",
                                  padding: "13px",
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  lineHeight: "21px",
                                  letterSpacing: "-0.02em",
                                  textAlign: "left",
                                  color: theme.palette.primary.darkColor,
                                  border: `1px solid ${theme.palette.gray.borderFaild}`,
                                  marginTop: "8px",
                                  borderRadius: "10px",
                                  background: theme.palette.primary.light,
                                }}
                                onFocus={(e) => {
                                  e.target.style.outline = "none";
                                  e.target.style.border = `1px solid ${theme.palette.primary.main}`;
                                }}
                                onBlur={(e) => {
                                  e.target.style.outline = "none";
                                  e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                                }}
                              />
                              {/* {errors.email && touched.email ? (
                    <div
                      style={{
                        color: theme.palette.status.error,
                        fontSize: "12px",
                        padding: "4px 10px",
                      }}
                    >
                      {errors.email}
                    </div>
                  ) : null} */}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px",
                              marginTop: "30px",
                              borderRadius: "10px",
                              width: "100%",
                              background: theme.palette.primary.transparent,
                            }}
                          >
                            <FormControlLabel
                              value="cash"
                              control={<Radio checked />}
                              label="Cash"
                              style={{
                                padding: "0px 15px 0",
                              }}
                              onClick={() =>
                                setFieldValue("payment_method", "cash")
                              }
                            />
                            <div>
                              <img
                                src={cash}
                                alt="Cash"
                                style={{ height: "24px", marginLeft: "10px" }}
                              />
                            </div>
                          </div>
                        </Box>
                      </RadioGroup>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={5.5}
                sx={{ paddingLeft: { md: "0 !important" } }}
              >
                <Box
                  sx={{
                    background: theme.palette.primary.light,
                    width: { xs: "calc(100% + 16px)", md: "calc(96% + 16px)" },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        marginLeft: "0px",
                        padding: "10px",
                        borderRadius: "8px 8px 0 0",
                        marginBottom: "16px",
                        background: theme.palette.neutral.paleGray,
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 700,
                          color: theme.palette.primary.lightDoveGrey,
                        }}
                      >
                        Order Summary
                      </Typography>
                    </Box>
                  </Box>
                  <Cart
                    onCartDataChange={handleCartData}
                    isCartEmpty={isCartEmpty}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{ margin: "20px", paddingBottom: "30px" }}
                  >
                    <button
                      type="submit"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "22.4px",
                        textAlign: "center",
                        color: theme.palette.primary.light,
                        backgroundColor: theme.palette.primary.main,
                        padding: "13px 40px",
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "50px",
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Loader color={theme.palette.primary.light} />
                      ) : (
                        "Confirm Order"
                      )}
                    </button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default CheckOut;
