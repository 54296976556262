
import { message } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { setCartProducts, setUserData } from "../redux/slices/appStateSlice";
import { db } from "../configs/firebase";
import { calculateCheckout } from "../components/common/calculateCheckout";


export const updateCartInFirebase = async (currentUser, updatedCartProducts) => {
  if (!currentUser || !currentUser.uid) return;

  try {
    const userDocRef = doc(db, "users", currentUser.uid);
    await updateDoc(userDocRef, {
      "cart.items": updatedCartProducts,
    });
  } catch (error) {
    console.error("Error updating cart in Firebase", error);
    throw error; 
  }
};

export const handleQuantityIncrement = async (
  productId,
  allProducts,
  cartProducts,
  currentUser,
  dispatch
) => {
  const productInAllProducts = allProducts?.find((item) => item.id === productId);
  if (productInAllProducts) {
    const currentProductInCart = cartProducts?.find((item) => item.id === productId);

    if (currentProductInCart.quantity < productInAllProducts.quantity) {
      const updatedCartProducts = cartProducts?.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
      );

      dispatch(setCartProducts(updatedCartProducts));

      try {
        await updateCartInFirebase(currentUser, updatedCartProducts); 
        await calculateCheckout(currentUser?.uid, dispatch); 
      } catch (error) {
        console.error("Error during quantity increment:", error);
        message.error("Failed to update cart. Please try again.");
      }
    } else {
      message.warning("Cannot add more than available stock");
    }
  }
};
export const handleQuantityDecrement = async (
  productId,
  cartProducts,
  currentUser,
  dispatch
) => {
console.log(productId, 'productId')
  // Check if the product exists in the cart
  const updatedCartProducts = cartProducts.map((item) =>
    item.id === productId && item.quantity > 1
      ? { ...item, quantity: item.quantity - 1 }
      : item
  );

  // Filter out any items that are effectively removed (i.e., quantity is 0)
  const finalCartProducts = updatedCartProducts.filter(item => item.quantity > 0);

  dispatch(setCartProducts(finalCartProducts));

  // Check if the cart is empty before calling calculateCheckout
  if (finalCartProducts.length === 0) {
    return; 
  }

  try {
    await updateCartInFirebase(currentUser, finalCartProducts);
    await calculateCheckout(currentUser?.uid, dispatch); 
  } catch (error) {
    console.error("Error during quantity decrement:", error);
    message.error("Failed to update cart. Please try again.");
  }
};


export const handleRemoveFromCart = async (
  productId,
  cartProducts,
  currentUser,
  dispatch
) => {
  const updatedCartProducts = cartProducts.filter((item) => item.id !== productId);

  dispatch(setCartProducts(updatedCartProducts));
  dispatch(
    setUserData({ ...currentUser, cart: { items: updatedCartProducts } })
  );

  // Check if the cart is empty before calling calculateCheckout
  // if (updatedCartProducts.length === 0) {
  //   return;
  // }

  try {
    await updateCartInFirebase(currentUser, updatedCartProducts);
    await calculateCheckout(currentUser?.uid, dispatch); 
  } catch (error) {
    console.error("Error during cart removal:", error);
    message.error("Failed to update cart. Please try again.");
  }
};

