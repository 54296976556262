import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { restUserData } from "../../../redux/slices/appStateSlice";
import { menuLinks, profileLinks } from "./component/HeaderLinks";
import {
  AppBar,
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import logo from "../../../assets/Logo/logo.png";
import logoBlack from "../../../assets/Logo/logoBlack.png";
import { getAuth, signOut } from "firebase/auth";
import background from "../../../assets/Images/background_header_home.png";
import { message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ToolbarComponent from "../Header/component/ToolbarComponent";
import HeaderContent from "./component/HeaderContent";
import HeaderInfoBar from "./component/HeaderInfoBar";
import SwipeableCartDrawer from "../../../pages/DrawerCart/SwipeableCartDrawer";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../configs/firebase";
import { createLinkAccount } from "../../../pages/profile/components/transactionsHistory/createLinkAccount";

const { confirm } = Modal;

const mobileMenuId = "primary-search-account-menu-mobile";
const profileMenue = "profile-menu";

const Header = () => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.appState.userData);
  const isSmallScreen = useMediaQuery("(max-width: 580px)");
  const [filteredMenuLinks, setFilteredMenuLinks] = useState(menuLinks);
  const [menuLinksLargeScreen, setMenuLinksLargeScreen] = useState(menuLinks);
  const [isUserIconVisible, setIsUserIconVisible] = useState(true);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMenuOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const cartCount = userData?.cart?.items?.length || 0;
  const [favoriteCount, setFavoriteCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const counts = {
    cart: cartCount,
    favorite: favoriteCount,
    messages: messagesCount,
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(isOpen);
  };

  const getActiveStyles = (link) => {
    if (!link) return {};
    const baseLink = link.split("?")[0]; // Extract base path before query string
    const queryString = link.split("?")[1]; // Extract query string if exists
    const pathMatches = location.pathname === baseLink;
    const queryMatches = queryString
      ? location.search.includes(queryString)
      : true;
    const isActive = pathMatches && queryMatches;

    return {
      color: isActive ? theme.palette.primary.main : "inherit",
      fontWeight: isActive ? 800 : "inherit",

      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: "4px",
        backgroundColor: isActive
          ? `${theme.palette.primary.main}`
          : "transparent",
        borderTopRightRadius: isActive ? "10px" : "0px",
        borderBottomRightRadius: isActive ? "10px" : "0px",
      },
    };
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      // Sign out the user from Firebase
      await signOut(auth);
      dispatch(restUserData());
      setIsUserIconVisible(false);
      localStorage.removeItem("userData"); // Clear user data from Local Storage
      sessionStorage.removeItem("sessionStartTime"); // Clear session start time from Session Storage
      setIsLoggedIn(false);
      // Optionally show a success message
      message.success("You have been logged out successfully.");
    } catch (error) {
      console.error("Error logging out:", error);
      message.error("Failed to log out. Please try again.");
    }
  };

  const handleLinkBankAccount = async () => {
    setIsLoading(true);
    try {
      await createLinkAccount(userData?.uid);
    } catch (error) {
      message.error("Error in linking bank account. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      userData &&
      !(Array.isArray(userData) && userData?.length === 0) &&
      typeof userData === "object" &&
      userData?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  useEffect(() => {
    setFilteredMenuLinks(
      menuLinks?.filter((menu) => {
        if (menu?.logIn === undefined) return true;
        return menu?.logIn === isLoggedIn;
      })
    );
    setMenuLinksLargeScreen(
      menuLinks.filter((menu) => {
        if (menu?.isLargeScreen) return true;
      })
    );
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchFavoriteCount = async () => {
      if (userData?.uid) {
        try {
          const favoriteRef = collection(
            db,
            "users",
            userData.uid,
            "favoriteProducts"
          );
          const favoriteSnapshot = await getDocs(favoriteRef);
          setFavoriteCount(favoriteSnapshot.size);
        } catch (error) {
          console.error("Error fetching favorite products:", error);
        }
      }
    };

    fetchFavoriteCount();
  }, [userData?.uid]);

  useEffect(() => {
    if (userData?.unreadMessages) {
      setMessagesCount(userData?.unreadMessages);
    } else {
      setMessagesCount(0);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.favoriteProducts) {
      setFavoriteCount(userData?.favoriteProducts);
    } else {
      setFavoriteCount(0);
    }
  }, [userData]);

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{
        WebkitOverflowScrolling: "touch",
        "& .MuiPaper-root": {
          padding: "20px 0",
          "&::-webkit-scrollbar": {
            width: "8px",
            borderRadius: "20px",
            backgroundColor: theme.palette.primary.light,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "8px",
          },
        },
      }}
    >
      {filteredMenuLinks?.map((menu) => {
        const isActive = location.pathname === menu?.link;
        return (
          <MenuItem
            key={menu?.id}
            onClick={() => {
              if (menu?.id === "cart") {
                // Open the cart drawer instead of navigating
                toggleDrawer(true)();
              } else {
                navigate(menu?.link);
              }
              setMobileMoreAnchorEl(null);
            }}
            sx={{
              ...getActiveStyles(menu?.link),
              "&:hover": {
                color: theme.palette.primary.main,
                fontWeight: 800,
              },
            }}
          >
            <IconButton color="inherit">
              <Badge badgeContent={counts[menu?.id] || null} color="primary">
                {menu?.icon}
              </Badge>
            </IconButton>
            <Typography
              sx={{
                fontWeight: isActive ? 700 : 400,
                borderBottom: isActive
                  ? `2px solid ${theme.palette.primary.main}`
                  : "none",
              }}
            >
              {menu?.name}
            </Typography>
          </MenuItem>
        );
      })}
    </Menu>
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={profileMenue}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        WebkitOverflowScrolling: "touch",

        "& .MuiPaper-root": {
          padding: "20px 0",
          "&::-webkit-scrollbar": {
            width: "8px",
            borderRadius: "20px",
            backgroundColor: theme.palette.primary.light,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "8px",
          },
        },
      }}
    >
      {profileLinks?.map((menu) => {
        const isActive = location.pathname === menu?.link;

        return (
          <MenuItem
            key={menu?.id}
            onClick={() => {
              if (menu?.id === "logout") {
                showDeleteConfirm();
                handleMenuClose();
              } else if (menu?.id === "linkBankAccount") {
                handleLinkBankAccount();
              } else {
                navigate(menu?.link);
                handleMenuClose();
              }
              setMobileMoreAnchorEl(null);
            }}
            disabled={isLoading && menu?.id === "linkBankAccount"}
            sx={{
              ...getActiveStyles(menu?.link),
              padding: "10px 20px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              "&:hover": {
                backgroundColor: theme.palette.primary.whiteSmoke,
                fontWeight: 600,
              },
              "& .MuiTypography-root": {
                marginLeft: "10px",
              },
            }}
          >
            <IconButton color="inherit">
              <Badge badgeContent={counts[menu.id] || null} color="primary">
                {isLoading && menu?.id === "linkBankAccount" ? (
                  <CircularProgress size={20} />
                ) : (
                  menu?.icon
                )}
              </Badge>
            </IconButton>
            <Typography
              sx={{
                fontWeight: isActive ? 700 : 400,
                borderBottom: isActive
                  ? `2px solid ${theme.palette.primary.main}`
                  : "none",
              }}
            >
              {isLoading && menu?.id === "linkBankAccount"
                ? "Loading..."
                : menu?.name}
            </Typography>
          </MenuItem>
        );
      })}
    </Menu>
  );

  const styles = {
    listBtn: {
      lineHeight: "1.2rem",
      textAlign: "center",
      color:
        location.pathname !== "/" && location.pathname !== "/home"
          ? theme.palette.text.primary
          : theme.palette.primary.light,
      cursor: "pointer",
      margin: "6px 16px",
    },
    headerTitle: {
      fontFamily: theme.typography.headerFont,
      textAlign: "left",
      marginBottom: "20px",
    },
    descriptionText: {
      color: theme.palette.primary.descHome,

      fontWeight: 400,
      lineHeight: "28px",
      textAlign: "left",
      marginBottom: "40px",
    },
    startSelling: {
      padding: "18px 50px 18px 50px",
      borderRadius: "16px",
      background: theme.palette.primary.main,
      color: theme.palette.primary.light,
      border: "none",
      cursor: "pointer",
      margin: "0 10px",
      opacity: 1,
      marginBottom: isSmallScreen ? "15px" : "0px",
      textTransform: "capitalize",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    orderNow: {
      padding: "18px 50px 18px 50px",
      borderRadius: "16px",
      color: theme.palette.primary.light,
      border: "2px solid white",
      margin: "0 10px",
      cursor: "pointer",
      marginBottom: isSmallScreen ? "15px" : "0px",
      opacity: 1,
      textTransform: "capitalize",
    },
    activeListBtn: {
      fontWeight: 600, // Ensures font weight is bold
    },
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure you want to logout?",
      style: {
        top: 20,
        boxShadow: "none !important",
        borderRadius: "10px",
        padding: "20px",
      },
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "default",
      maskClosable: true,
      cancelText: "No",
      onOk() {
        handleLogout();
      },
      onCancel() {},
    });
  };

  return (
    <>
      <HeaderInfoBar />
      <Box
        sx={{
          background: theme.palette.primary.background,
          marginBottom: "0px",
          paddingBottom: location.pathname === "/requests" ? "0" : "25px",
        }}
      >
        <Box
          sx={{
            ...((location.pathname === "/" ||
              location.pathname === "/home") && {
              position: "relative",
              background: `url(${background}) center center / cover no-repeat`,
              height: { xs: "118vh", sm: "100vh" },
              borderRadius: isSmallScreen ? "0px" : "25px",
              margin: { xs: "0", sm: "25px", md: "25px", lg: "25px" },
              color: theme.palette.primary.light,
            }),
          }}
        >
          <AppBar
            position="relative"
            sx={{
              bgcolor: "transparent",
              boxShadow: "none",
              zIndex: 1,
              borderRadius: "30px",
            }}
          >
            <ToolbarComponent
              cartCount={cartCount}
              favoriteCount={favoriteCount}
              messagesCount={messagesCount}
              handleProfileMenuOpen={handleProfileMenuOpen}
              open={open}
              setOpen={setOpen}
              toggleDrawer={toggleDrawer}
              isUserIconVisible={isUserIconVisible}
              isLoggedIn={isLoggedIn}
              logo={
                location.pathname !== "/" && location.pathname !== "/home"
                  ? logoBlack
                  : logo
              }
              borderColor={
                location.pathname !== "/" && location.pathname !== "/home"
                  ? theme.palette.primary.grey
                  : theme.palette.primary.light
              }
              color={
                location.pathname !== "/" && location.pathname !== "/home"
                  ? theme.palette.primary.darkColor
                  : theme.palette.primary.light
              }
              styles={styles}
              renderMenu={renderMenu}
              renderMobileMenu={renderMobileMenu}
              menuLinksLargeScreen={menuLinksLargeScreen}
              handleMobileMenuOpen={handleMobileMenuOpen}
              mobileMenuId={mobileMenuId}
            />
          </AppBar>
          <HeaderContent isSmallScreen={isSmallScreen} styles={styles} />
        </Box>
      </Box>

      <SwipeableCartDrawer open={open} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Header;
