import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Use the storage you prefer (local storage, session storage, etc.)
import appStateReducer from "./slices/appStateSlice.js";

const persistConfig = {
  key: "root", // The key to use in local storage
  storage, // Use the storage you prefer (local storage, session storage, etc.)
};

const persistedReducer = persistReducer(persistConfig, appStateReducer);

export const store = configureStore({
  reducer: {
    appState: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
