import React, { useEffect, useState } from "react";
import { Grid, Box, Button, useTheme, Stack, Typography } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import ProductItem from "./components/ProductItem";
import SummaryBox from "./components/SummaryBox";
import SavedForLater from "./components/SavedForLater";
import { doc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../configs/firebase";
import { setCartProducts, setUserData } from "../../redux/slices/appStateSlice";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import nothingHere from "../../assets/Images/nothing_here.png";
import {
  handleQuantityIncrement,
  handleQuantityDecrement,
  handleRemoveFromCart,
} from "../../utils/cartUtils";
import ShippingAddressSelector from "./components/ShippingAddressSelector";

const Cart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.appState.userData);
  const [allProductsData, setAllProductsData] = useState([]);

  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isLoadingAllProducts, setIsLoadingAllProducts] = useState(false);
  const cartProducts = useSelector((state) => state.appState.cartProducts);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const fetchProductsData = async (cartItems) => {
    setIsLoadingAllProducts(true);
    try {
      const productIds = cartItems.map((item) => item.id);
      const productsRef = db
        .collection("products")
        .where("isDeleted", "==", false);

      const querySnapshot = await productsRef
        .where("id", "in", productIds)
        .get();

      let fetchedProducts = [];
      querySnapshot.forEach((doc) => {
        fetchedProducts.push(doc.data());
      });

      setAllProductsData(fetchedProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoadingAllProducts(false);
    }
  };

  useEffect(() => {
    if (cartProducts?.length > 0) {
      fetchProductsData(cartProducts);
    }
  }, [cartProducts]);


  const handleClearCart = async () => {
    dispatch(setUserData({ ...currentUser, cart: { items: [] } }));
    try {
      const userDocRef = doc(db, "users", currentUser?.uid);
      await updateDoc(userDocRef, {
        "cart.items": [],
      });
      dispatch(setCartProducts([]));
      message.success("Your order has been placed successfully!");
    } catch (error) {
      message.error("Error clearing cart ");
    }
  };
  
  useEffect(() => {
    if (currentUser) {
      dispatch(setCartProducts(currentUser?.cart?.items));
    }
  }, [currentUser]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  if (!currentUser || !currentUser.uid) {
    return (
      <Box>
        <Typography variant="h6" align="center">
          Please log in to view your cart.
        </Typography>
      </Box>
    );
  }
 
  return (
    <>
      {cartProducts?.length === 0 ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "16px",
            marginBottom: "20px",
          }}
        >
          <img alt="nothing here" src={nothingHere} />
          <Typography
            variant="h6"
            align="center"
            style={{
              fontWeight: 700,
              color: theme.palette.primary.darkColor,
            }}
          >
            Your cart is empty
          </Typography>

          <Button
            sx={{
              paddingX: "32px",
              color: theme.palette.primary.light,
              paddingY: "10px",
              textTransform: "capitalize",
              fontWeight: "700",
              fontSize: "14px",
              letterSpacing: "calc(1.2% * 16px)",
              borderRadius: "32px",
              backgroundColor: theme.palette.primary.main,

              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.light,
              },
            }}
            variant="outlined"
            onClick={() => navigate("/categories")}
          >
            continue Shopping
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <Grid
            component="section"
            container
            spacing={4}
            sx={{
              marginBottom: "30px",
              display: "grid",
              gridTemplateColumns: "2fr 1fr",
              gap: "10px",
              "@media (max-width: 900px)": { gridTemplateColumns: "1fr" },
            }}
          >
            <Grid item>
              <Stack gap={1}>
                <Stack
                  gap={1}
                  sx={{
                    padding: "20px",
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "16px",
                    boxShadow: `4px 7px 60px 0px ${theme.palette.customShadows.darkShadow}`,
                    width: { xl: "67.5rem", xs: "100%" },
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
               
                  >
                    <Typography variant="h6">Shipping Address</Typography>
                    <Button
                      sx={{
                        color: theme.palette.primary.light,
                        width: "40px",
                        height: "40px", 
                        borderRadius: "50%", 
                        backgroundColor: theme.palette.primary.main,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 0, 
                        minWidth: 0, 
                        minHeight: 0, 
                        fontSize: "18px", 
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                        }
                      }}
                      onClick={handleModalOpen}
                    >
                      +
                    </Button>
                  </Stack>

                  <ShippingAddressSelector
                    currentUser={currentUser}
                    onSelectAddress={(address) => setSelectedAddressId(address?.id)}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    
                  />
                </Stack>
                <Box
                  sx={{
                    padding: "20px",
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "16px",
                    boxShadow: `4px 7px 60px 0px ${theme.palette.customShadows.darkShadow}`,
                    width: { xl: "67.5rem", xs: "100%" },
                  }}
                >
                  {cartProducts?.map((product) => (
                    <ProductItem
                      key={product?.id}
                      allProducts={allProductsData}
                      product={product}
                      theme={theme}
                      handleQuantityIncrement={() =>
                        handleQuantityIncrement(
                          product.id,
                          allProductsData,
                          cartProducts,
                          currentUser,
                          dispatch
                        )
                      }
                      handleQuantityDecrement={() =>
                        handleQuantityDecrement(
                          product.id,
                          cartProducts,
                          currentUser,
                          dispatch
                        )
                      }
                      handleRemoveFromCart={() =>
                        handleRemoveFromCart(
                          product.id,
                          cartProducts,
                          currentUser,
                          dispatch
                        )
                      }
                    />
                  ))}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      onClick={() => navigate("/explore?page=items")}
                      variant="contained"
                      startIcon={<FaArrowLeft size="15px" />}
                      sx={{
                        width: "10.938rem",
                        borderRadius: "6px",
                        height: "40px",
                        textTransform: "capitalize",
                        backgroundColor: theme.palette.primary.main,
                        fontSize: "14px",
                      }}
                    >
                      Back to shop
                    </Button>
                    {cartProducts?.length > 1 && (
                      <Button
                        onClick={handleClearCart}
                        sx={{
                          paddingX: "16px",
                          width: "7.625rem",
                          height: "40px",
                          borderRadius: "6px",
                          border: `1px solid ${theme.palette.neutral.grayBorder}`,
                          backgroundColor: theme.palette.primary.light,
                          color: theme.palette.primary.lightDoveGrey,
                          boxShadow: "none",
                          fontWeight: "500",
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        remove all
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid item>
              <SummaryBox
                theme={theme}
                currentUser={currentUser}
                selectedAddressId={selectedAddressId || selectedAddress}
                allProductsData={allProductsData}
              />
            </Grid>
          </Grid>
          <SavedForLater />
        </Box>
      )}
    </>
  );
};

export default Cart;
