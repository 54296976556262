import { Box, Stack, Typography, useTheme } from "@mui/material";
import { CiShop } from "react-icons/ci";
import { GoDotFill } from "react-icons/go";

function Stock({ currentProduct, pathName }) {
  const theme = useTheme();

  const { quantity, warranty, isActive } = currentProduct;


  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ width: "100%" }}
      gap={{ md: 3, xs: 1 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          backgroundColor: isActive
            ? theme.palette.primary.lightGreen
            : theme.palette.status.lightRed,
          padding: "4px 8px",
          borderRadius: "8px",
          color: isActive
            ? theme.palette.primary.darkGreen
            : theme.palette.primary.red,
          marginTop: "10px",
        }}
      >
        <GoDotFill />
        <Typography
          variant="body2"
          sx={{
            fontWeight: "700",
            textTransform: "capitalize",
          }}
        >
          {isActive ? "available" : "Not Available"}
        </Typography>
      </Stack>

      {currentProduct?.isSold && pathName !== "service-details" && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{
            backgroundColor: theme.palette.status.lightRed,
            padding: "4px 8px",
            borderRadius: "8px",
            color: theme.palette.status.cancel,
            marginTop: "10px",
            width: "fit-content",
          }}
        >
          <GoDotFill />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "700",
              textTransform: "capitalize",
            }}
          >
            sold
          </Typography>
        </Stack>
      )}
      {pathName !== "service-details" && (
        <Box
          xs={6}
          md={4}
          
          display="flex"
          alignItems="center"
          gap={1}
          style={{ marginTop: "10px" }}
        >
          <CiShop color={theme.palette.primary.main} />
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.dark.dustyBlue,
              textTransform: "capitalize",
            }}
          >
            {quantity === 0
              ? "Out of stock"
              : quantity === 1
              ? "1 item"
              : `${quantity} available`}
          </Typography>
        </Box>
      )}
      {/* {warranty && (
        <Box xs={6} item md={4} display="flex" alignItems="center" gap={1}>
          <MdOutlineVerified color={theme.palette.primary.main} />
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.dark.dustyBlue,
              textTransform: "capitalize",
            }}
          >
            {warranty} warranty
          </Typography>
        </Box>
      )} */}
      {/* <Box item  xs={6} md={4} display="flex" alignItems="center" gap={1}>
      <BsTruck color={theme.palette.primary.main} />
      <Typography variant="body2" sx={{color:theme.palette.dark.dustyBlue,textTransform:"capitalize"}}>free delivery</Typography>
    </Box> */}
    </Stack>
  );
}

export default Stock;
