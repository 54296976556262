import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { IoCameraOutline } from "react-icons/io5";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getAuth, updateProfile } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { message } from "antd";
import { db } from "../../../../configs/firebase";
import { useDispatch } from "react-redux";


import {
  resetFormTouched,
  setFormTouched,
  setUserData,
} from "../../../../redux/slices/appStateSlice";

import Loader from "../../../../components/common/Loader";
import PhoneNumberInput from "../../../../components/common/PhoneNumberInput";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import parsePhoneNumberFromString from "libphonenumber-js";

// Validation schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("First name is required")
    .min(3, "First name must be at least 3 characters long")
    .max(10, "First name must be at most 10 characters long"),
  lastName: Yup.string()
    .trim()
    .required("Last name is required")
    .min(3, "Last name must be at least 3 characters long")
    .max(10, "Last name must be at most 10 characters long"),
  // phone: Yup.string()
  //   .required("Phone number is required")
  //   .test("is-valid-phone", "Please enter a valid phone number", (value) => {
  //     if (!value) return false;
  //     const phoneNumber = parsePhoneNumberFromString(value);
  //     return phoneNumber?.isValid() || false;
  //   }),
});

const AccountInfoForm = ({
  inputStyle,
  sharedStyles,
  handleChangePasswordClick,
  handleChangeEmailClick,
  currentUser,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  // const [preview, setPreview] = useState(user?.photoURL || null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.appState.userData);
  const [preview, setPreview] = useState(userData?.profilePicture || null);

  const handleSubmit = async (values, actions) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      message.error("No user is logged in.");
      return;
    }
    setLoading(true);
    try {
      let photoURL = user?.photoURL;

      // Proceed only if a new file has been selected
      if (selectedFile) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `profilePictures/${user.uid}/${selectedFile.name}`
        );

        // Attempt to upload the file
        try {
          await uploadBytes(storageRef, selectedFile);
          photoURL = await getDownloadURL(storageRef);
          await updateProfile(user, { photoURL });
        } catch (uploadError) {
          console.error("Error uploading file:", uploadError);
          message.error("Failed to upload profile image. Please try again.");
          setLoading(false);
          return;
        }
      }

      // Update Firestore document with profile data
      const userDoc = doc(db, "users", user.uid);
      await updateDoc(userDoc, {
        firstName: values?.firstName,
        lastName: values?.lastName,
        phoneNumber: values?.phone,
        profilePicture: photoURL || null,
      });

      dispatch(
        setUserData({
          ...userData,
          firstName: values?.firstName,
          lastName: values?.lastName,
          phoneNumber: values?.phone,
          profilePicture: photoURL || null,
        })
      );
      dispatch(resetFormTouched());
      message.success("Profile updated successfully");
      actions.setSubmitting(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile. Please try again.");
      actions.setSubmitting(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.profilePicture) {
      setPreview(userData.profilePicture);
    }
  }, [userData]);

  const handleInputChange = () => {
    dispatch(setFormTouched());
  };

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    // Check if a file is selected
    if (!file) {
      message.error("No file selected.");
      return;
    }

    // Validate file type
    if (
      file &&
      !["image/jpeg", "image/png", "image/jpg"].includes(file?.type)
    ) {
      message.error("Please upload a valid image file (.jpg, .jpeg, .png)");
      return;
    }
    if (preview) {
      URL?.revokeObjectURL(preview);
    }
    // Create a preview URL and set it in the state
    const previewURL = URL?.createObjectURL(file);
    setPreview(previewURL);
    setSelectedFile(file);
  };

  const handleBeSellerClick = () => {
    navigate("/profile?page=info&form=addBusinessInfo");
  };

  return (
    <Formik
      initialValues={{
        firstName: currentUser?.firstName || "",
        lastName: currentUser?.lastName || "",
        email: currentUser?.email || "",
        phone: currentUser?.phoneNumber || "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => (
        <Form>
          <Stack spacing={3}>
            <Stack
              direction={{
                xs: "column",
                sm: "row",
              }}
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Paper
                elevation={3}
                sx={{
                  position: "relative",
                  display: "inline-block",
                  borderRadius: "8px",
                  width: "100px",
                  height: "100px",
                }}
              >
                <Avatar
                  src={preview}
                  alt="Profile Picture"
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "inherit",
                  }}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    bottom: -10,
                    right: -10,
                    backgroundColor: theme.palette.primary.main,
                    width: "32px",
                    height: "32px",
                    border: "1px solid",
                    borderColor: theme.palette.primary.light,
                    borderRadius: "50%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                  component="label"
                >
                  <IoCameraOutline
                    color={theme.palette.primary.light}
                    size="80px"
                  />
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    hidden
                    onChange={handleFileChange}
                  />
                </IconButton>
              </Paper>
              {currentUser?.userType === "buyer" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBeSellerClick}
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    paddingY: {
                      xs: "12px",
                      sm: "16px",
                    },
                    paddingX: {
                      xs: "16px",
                      sm: "24px",
                    },
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                    },
                    borderRadius: "8px",
                  }}
                >
                  became a seller / service provider
                </Button>
              )}
            </Stack>

            <Box
              sx={{
                borderRadius: "8px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: {
                  xs: "10px",
                  sm: "20px",
                  lg: "30px",
                },
                gridTemplateAreas: `
                  "firstName lastName"
                  "email phone"
                  "password password"
                `,
                "@media (max-width: 600px)": {
                  gridTemplateColumns: "1fr",
                  gridTemplateAreas: `
                    "firstName"
                    "lastName"
                    "email"
                    "phone"
                    "password"
                    "."
                  `,
                },
              }}
            >
              {/* First Name */}
              <Box sx={{ gridArea: "firstName" }}>
                <InputLabel sx={inputStyle} htmlFor="firstName">
                  first name
                </InputLabel>
                <TextField
                  id="firstName"
                  name="firstName"
                  fullWidth
                  variant="outlined"
                  value={values.firstName}
                  onBlur={handleBlur("firstName")}
                  placeholder="Enter your First Name"
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e);
                  }}
                  sx={sharedStyles}
                  error={touched.firstName && Boolean(errors.firstName)}
                />

                {touched.firstName && errors?.firstName && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.firstName}
                  </Typography>
                )}
              </Box>

              {/* Last Name */}
              <Box sx={{ gridArea: "lastName" }}>
                <InputLabel sx={inputStyle} htmlFor="lastName">
                  last name
                </InputLabel>
                <TextField
                  id="lastName"
                  name="lastName"
                  fullWidth
                  variant="outlined"
                  value={values.lastName}
                  placeholder="Enter your Last Name"
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e);
                  }}
                  onBlur={handleBlur("lastName")}
                  sx={sharedStyles}
                  error={touched.lastName && Boolean(errors.lastName)}
                />
                {touched.lastName && errors?.lastName && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.lastName}
                  </Typography>
                )}
              </Box>

              {/* Email */}
              <Box sx={{ gridArea: "email" }}>
                <InputLabel sx={inputStyle} htmlFor="email">
                  email
                </InputLabel>
                <OutlinedInput
                  id="email"
                  name="email"
                  fullWidth
                  sx={sharedStyles}
                  disabled
                  value={values.email}
                  placeholder="Enter your Email"
                
                />
              </Box>

              {/* Phone - Using PhoneNumberInput */}
              <Box sx={{ gridArea: "phone" }}>
                <InputLabel sx={inputStyle} htmlFor="phone">
                  Phone Number
                </InputLabel>
                <PhoneNumberInput
                  value={values.phone}
                  onChange={(phone) => {
                    setFieldValue("phone", phone); 
                    handleInputChange(); 
                  }}
                  onBlur={() => setFieldTouched("phone", true)} 
                 
                />
                {/* {touched.phone && errors.phone && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors.phone}
                  </Typography>
                )} */}
              </Box>

              {/* Password */}
              <Box sx={{ gridArea: "password" }}>
                <InputLabel sx={inputStyle} htmlFor="password">
                  password
                </InputLabel>
                <OutlinedInput
                  id="password"
                  type="password"
                  fullWidth
                  sx={sharedStyles}
                  disabled
                  value="********"
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={handleChangePasswordClick}
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "600",
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        change
                      </Button>
                    </InputAdornment>
                  }
                />
              </Box>

              {/* Save Changes Button */}
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                gap={{
                  xs: 2,
                  sm: 0,
                }}
                alignItems="center"
                justifyContent="space-between"
                sx={{ gridColumn: "1 / span 2", marginTop: "20px" }}
              >
                {/* {currentUser?.userType === "buyer" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBeSellerClick}
                    sx={{
                      fontWeight: "500",
                      textTransform: "capitalize",
                      paddingY: "16px",
                      paddingX: "32px",
                      borderRadius: "8px",
                    }}
                  >
                    be a seller / service provider
                  </Button>
                )} */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "15.625rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    paddingY: "16px",
                    paddingX: "32px",
                    borderRadius: "8px",
                  }}
                  disabled={!isValid || isSubmitting}
                >
                  {loading ? <Loader /> : "save changes"}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AccountInfoForm;
