import { auth } from "../../configs/firebase";
import { setCheckoutData, setCheckoutError, setCheckoutLoading } from "../../redux/slices/appStateSlice";

 import { BASE_URL, STRIPE_CHECKOUT_ENDPOINT } from "../layout/constants";

export const calculateCheckout = async (uid, dispatch) => {
  try {
    dispatch(setCheckoutLoading(true)); 
    const currentUser = auth.currentUser;
    if (!currentUser) throw new Error("User is not authenticated");

    const userToken = await currentUser.getIdToken();
    const url = `${BASE_URL}${STRIPE_CHECKOUT_ENDPOINT}`;
    const headers = {
      "Content-Type": "application/json",
      authorization: userToken,
    };
    const body = JSON.stringify({ uid });
    const response = await fetch(url, {
      method: "POST",
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error("Failed to calculate checkout");
    }

    const responseData = await response.json();
    
    dispatch(setCheckoutData(responseData)); 
    dispatch(setCheckoutError(null)); 
    
  } catch (error) {
    dispatch(setCheckoutError("Error calculating checkout"));
    console.error("Error calculating checkout:", error);
  } finally {
    dispatch(setCheckoutLoading(false)); 
  }
};
