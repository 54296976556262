import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { MdAdd, MdRemove } from "react-icons/md";
import { RiShoppingCartLine } from "react-icons/ri";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { message } from "antd";
import { useDispatch } from "react-redux";
import {
  setCurrentProduct,
  setSelectedProductStatus,
  setUserData,
} from "../../../redux/slices/appStateSlice";
import { db, firestoreFieldValue } from "../../../configs/firebase";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { calculateCheckout } from "../../../components/common/calculateCheckout";
import { checkStripeRequirements } from "../../../utils/checkStripeRequirements";
import BankAccountModal from "../../../components/common/BankAccountModal";

const ButtonsGroup = ({ currentProduct, pathName, user }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [buttonText, setButtonText] = useState("Add to Cart");
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    currentProduct?.quantity === 0
  );
  const [status, setStatus] = useState({
    isDraft: currentProduct?.isDraft,
    isActive: currentProduct?.isActive,
    isSold: pathName === "service-details" ? false : currentProduct?.isSold,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [newQuantity, setNewQuantity] = useState(0);
  const [hasUserStartedTyping, setHasUserStartedTyping] = useState(false);

  const {
    contactForPrice,
    isOnline,
    quantity: stockQuantity,
    uid: sellerId,
    images,
    price,
    id: productId,
    title,
  } = currentProduct;

  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasAcknowledgedStripeModal, setHasAcknowledgedStripeModal] =
    useState(false);

  // Determine shipping price and company
  let shippingPrice = 0;
  let shippingCompanyName = null;
  let isLocalPickup = false;

  if (
    currentProduct?.isLocalPickup === true &&
    currentProduct?.companies?.length === 0
  ) {
    shippingPrice = 0;
    isLocalPickup = false;
    shippingCompanyName = null;
  } else if (
    currentProduct?.companies?.length > 0 &&
    currentProduct?.isLocalPickup !== true
  ) {
    const minShippingCompany = currentProduct?.companies?.reduce((prev, curr) =>
      prev?.price < curr?.price ? prev : curr
    );
    shippingPrice = minShippingCompany?.price || 0;
    shippingCompanyName = minShippingCompany?.name || null;
    isLocalPickup = false;
  } else {
    shippingPrice = 0;
    isLocalPickup = true;
    shippingCompanyName = null;
  }

  useEffect(() => {
    if (
      user &&
      !(Array.isArray(user) && user?.length === 0) &&
      typeof userData === "object" &&
      user?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  const handleStatusToggle = async (newStatus) => {
    if (newStatus === "sold") {
      const updatedProduct = { ...currentProduct, quantity: 0 };

      dispatch(setCurrentProduct(updatedProduct));

      const productRef = doc(db, "products", currentProduct?.id);
      try {
        await updateDoc(productRef, {
          quantity: 0,
        });
      } catch (error) {
        console.error("Error updating product in Firestore: ", error);
      }
    }

    const collectionName =
      pathName === "service-details" ? "services" : "products";
    const productDocRef = doc(db, collectionName, productId);
    try {
      const updatedStatus =
        pathName === "service-details"
          ? {
              isDraft: newStatus === "draft",
              isActive: newStatus === "active",
              updatedAt: serverTimestamp(),
            }
          : {
              isDraft: newStatus === "draft",
              isActive: newStatus === "active",
              isSold: newStatus === "sold",
              updatedAt: serverTimestamp(),
            };

      await updateDoc(productDocRef, updatedStatus);
      setStatus(updatedStatus);
      if (pathName === "service-details") {
        message.success(`service marked as ${newStatus}`);
      } else {
        message.success(`Product marked as ${newStatus}`);
      }
    } catch (error) {
      if (pathName === "service-details") {
        message.error("Failed to update service status");
        console.error("Error updating service status: ", error);
      } else {
        message.error("Failed to update product status");
        console.error("Error updating product status: ", error);
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewQuantity(0);
    setHasUserStartedTyping(false);
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    setHasUserStartedTyping(true);

    if (value.includes(".")) {
      return;
    }
    if (value === "" || !isNaN(value)) {
      setNewQuantity(parseFloat(value));
    }
  };

  const handleUpdateQuantity = async () => {
    if (newQuantity <= 0) {
      message.error("The quantity must be greater than 0");
      return;
    }

    try {
      const productDocRef = doc(db, "products", currentProduct?.id);
      await updateDoc(productDocRef, {
        quantity: newQuantity,
        isSold: false,
        isActive: true,
      });
      message.success("Quantity updated successfully");
      const updatedProduct = { ...currentProduct, quantity: newQuantity };
      dispatch(setCurrentProduct(updatedProduct));
      setOpenDialog(false);
      setTimeout(async () => {
        setHasAcknowledgedStripeModal(true);
        await handleStatusToggleAndNavigate("active");
      }, 1000);
    } catch (error) {
      console.error("Error updating quantity: ", error);
      message.error("An error occurred while updating the quantity");
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      const collectionName =
        pathName === "service-details" ? "services" : "products";
      const productDocRef = doc(db, collectionName, productId);

      try {
        // Fetch the document once using getDoc
        const docSnapshot = await getDoc(productDocRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();

          if (pathName === "service-details") {
            setStatus({
              isDraft: data?.isDraft,
              isActive: data?.isActive,
              updatedAt: data?.updatedAt,
            });
          } else {
            setStatus({
              isDraft: data?.isDraft,
              isActive: data?.isActive,
              isSold: data?.isSold,
              updatedAt: data?.updatedAt,
            });
          }
        } else {
          console.log("Document does not exist!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchProductData(); // Call the function to fetch data once
  }, [productId, pathName]);

  const handleIncrement = () => {
    if (quantity < stockQuantity) {
      setQuantity((prev) => prev + 1);
    } else {
      message.warning("Quantity exceeds available stock");
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const handleAddToCart = async () => {
    if (user?.length === 0) {
      navigate("/login");
      return;
    }
    if (user?.uid === currentProduct?.uid) {
      message.error("You cannot add your own product to the cart.");
      return;
    }

    // Fetch the latest product data to validate critical fields before adding to cart
    try {
      setLoading(true);
      const productRef = doc(db, "products", productId);
      const productSnapshot = await getDoc(productRef);

      if (!productSnapshot.exists()) {
        message.error("Product not found or has been removed.");
        return;
      }

      const latestProductData = productSnapshot.data();

      if (latestProductData?.contactForPrice) {
        message.warning(
          "This product is changed to contact for price and cannot be added to the cart."
        );
        return;
      }

      if (
        latestProductData?.isSold ||
        latestProductData?.isDraft ||
        !latestProductData?.isActive ||
        latestProductData?.isDeleted
      ) {
        message.warning("This product is not available for purchase.");
        return;
      }

      if (quantity > latestProductData?.stockQuantity) {
        message.warning("Quantity exceeds available stock");
        return;
      }

      // Proceed to add to cart after validation
      const cartItem = {
        id: productId,
        image: images[0],
        price: quantity * price,
        quantity: quantity,
        seller_id: sellerId,
        shipping_price: shippingPrice,
        isLocalPickup: isLocalPickup,
        shipping_company: shippingCompanyName,
        tax_amount: null,
        title: title,
      };

      const userDocRef = doc(db, "users", user?.uid);
      const existingCartItem = user?.cart?.items?.find(
        (item) => item?.id === productId
      );

      if (existingCartItem) {
        const newQuantity = existingCartItem.quantity + quantity;
        if (newQuantity > latestProductData.quantity) {
          message.warning("Quantity exceeds available stock");
          return;
        }

        await updateDoc(userDocRef, {
          "cart.items": firestoreFieldValue.arrayRemove(existingCartItem),
        });
        await updateDoc(userDocRef, {
          "cart.items": firestoreFieldValue.arrayUnion({
            ...existingCartItem,
            quantity: newQuantity,
            price: newQuantity * price,
          }),
        });

        const updatedCart = user?.cart.items.map((item) =>
          item.id === productId
            ? { ...item, quantity: newQuantity, price: newQuantity * price }
            : item
        );

        dispatch(
          setUserData({ ...user, cart: { ...user?.cart, items: updatedCart } })
        );
        message.success("Item quantity updated in cart successfully");

        if (newQuantity >= latestProductData.quantity) {
          setButtonText("Max Quantity Reached");
          setIsButtonDisabled(true);
        }
      } else {
        await updateDoc(userDocRef, {
          "cart.items": firestoreFieldValue.arrayUnion(cartItem),
        });
        const updatedCart = user?.cart
          ? [...user?.cart?.items, cartItem]
          : [cartItem];
        dispatch(
          setUserData({ ...user, cart: { ...user?.cart, items: updatedCart } })
        );
        message.success("Item added to cart successfully");
      }

      await calculateCheckout(user?.uid, dispatch);
    } catch (error) {
      message.error("Failed to add item to cart");
      console.error("Error adding item to cart: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoToChat = () => {
    if (pathName === "service-details") {
      const service = {
        id: currentProduct?.id,
        image: currentProduct?.images[0],
        title: currentProduct?.title,
        description: currentProduct?.description,
        ...(currentProduct?.price != null && { price: currentProduct.price }),
      };
      navigate("/chat?type=services", {
        state: { service, userIdFromService: sellerId },
      });
    } else {
      const item = {
        id: currentProduct?.id,
        image: currentProduct?.images[0],
        price: currentProduct?.price,
        title: currentProduct?.title,
        description: currentProduct?.description,
      };
      navigate("/chat?type=items", {
        state: { item, userIdFromItem: sellerId },
      });
    }
  };

  const handleStatusToggleAndNavigate = async (newStatus) => {
    if (newStatus === "active" && isOnline && pathName !== "service-details") {
      // const isStripeReady = !user?.paymentRestricted;

      // if (!isStripeReady && !hasAcknowledgedStripeModal) {
      //   setIsModalOpen(true);
      //   return;
      // }

      if (
        currentProduct?.quantity === 0 &&
        newStatus === "active" &&
        newQuantity === 0
      ) {
        setOpenDialog(true);
        return;
      }
      await handleStatusToggle(newStatus);

      dispatch(
        setSelectedProductStatus({
          id: currentProduct?.id,
          status: newStatus,
        })
      );
      setHasAcknowledgedStripeModal(false);
      navigate({
        pathname: "/selling",
        search: `?type=${
          pathName === "service-details" ? "services" : "items"
        }`,
        state: { currentId: currentProduct?.id, status: newStatus },
      });

      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      // If not online or not active status change, proceed with normal flow
      await handleStatusToggle(newStatus);
      navigate({
        pathname: "/selling",
        search: `?type=${
          pathName === "service-details" ? "services" : "items"
        }`,
        state: { currentId: currentProduct?.id, status: newStatus },
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (currentProduct?.quantity === 0) {
      setButtonText("Out of Stock");
      setIsButtonDisabled(true);
      return;
    }

    if (user?.cart?.items) {
      const existingCartItem = user?.cart?.items?.find(
        (item) => item.id === productId
      );
      if (existingCartItem) {
        setButtonText("Increase Quantity");
        if (existingCartItem.quantity >= stockQuantity) {
          setButtonText("Max Quantity Reached");
          setIsButtonDisabled(true);
        } else {
          setIsButtonDisabled(false);
        }
      } else {
        setButtonText("Add to Cart");
        setIsButtonDisabled(false);
      }
    }
  }, [user, productId, stockQuantity, currentProduct?.quantity]);

  const handleModalContinue = async () => {
    setIsModalOpen(false);
    setHasAcknowledgedStripeModal(true);
  };
  useEffect(() => {
    if (hasAcknowledgedStripeModal) {
      const changeStatus = async () => {
        await handleStatusToggleAndNavigate("active");
      };
      changeStatus();
    }
  }, [hasAcknowledgedStripeModal]);

  return (
    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" gap={2}>
      {user?.uid === sellerId ? (
        // Display buttons for the seller to mark as draft, active, or sold
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={async () => {
              await handleStatusToggleAndNavigate(
                status?.isDraft ? "active" : "draft"
              );
              // w.scnavigate("/selling");
              // windorollTo({ top: 0, behavior: "smooth" });
            }}
            sx={{
              paddingX: "32px",
              width: {
                xs: "100%",
                sm: "19.375rem",
              },
              height: "56px",
              textTransform: "capitalize",
              fontWeight: "600",
              fontSize: "1rem",
              marginBottom: { xs: "10px", md: "0" },
              backgroundColor: theme.palette.primary.main,
            }}
          >
            {status?.isDraft ? "Make as Active" : "Make as Draft"}
          </Button>
          {pathName !== "service-details" && (
            <Button
              variant="contained"
              onClick={async () => {
                // await handleStatusToggle(status?.isSold ? "active" : "sold");
                // navigate({
                //   pathname: "/selling",
                //   search: `?type=${
                //     pathName === "service-details" ? "services" : "items"
                //   }`,
                //   state: {
                //     id: currentProduct?.id,
                //     status: status?.isSold ? "active" : "sold",
                //   },
                // });

                await handleStatusToggleAndNavigate(
                  status?.isSold ? "active" : "sold"
                );
              }}
              sx={{
                paddingX: "32px",
                width: {
                  xs: "100%",
                  sm: "19.375rem",
                },
                height: "56px",
                textTransform: "capitalize",
                fontWeight: "600",
                fontSize: "1rem",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              {status?.isSold && pathName !== "service-details"
                ? "Make as Active"
                : "Make as Sold"}
            </Button>
          )}
          {/* <BankAccountModal
            open={isModalOpen}
            setOpen={setIsModalOpen}
            onContinue={handleModalContinue}
          /> */}
          <Dialog
            maxWidth={"sm"}
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: "12px",
              },
            }}
            fullWidth
          >
            <DialogTitle>
              <Box display="flex" gap="8px" flexDirection={"column"}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "700", lineHeight: "2.25rem" }}
                >
                  Activate Item
                </Typography>
                <Typography
                  variant={"body1"}
                  style={{ color: theme.palette.neutral.textLight }}
                >
                  To activate this item, you must first set the quantity. Please
                  update the quantity to proceed with activation.
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant={"body1"} style={{ marginBottom: "5px" }}>
                    Quantity *
                  </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    inputMode="numeric"
                    placeholder="Provide a quantity for your item"
                    name="quantity"
                    variant="outlined"
                    value={newQuantity}
                    onChange={handleQuantityChange}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: "8px",
                      },
                    }}
                    error={
                      hasUserStartedTyping &&
                      (newQuantity <= 0 || isNaN(newQuantity))
                    }
                    helperText={
                      hasUserStartedTyping &&
                      (newQuantity <= 0 || isNaN(newQuantity))
                        ? "Quantity must be a valid number greater than 0"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              <Box
                display={"flex"}
                justifyContent={{ xs: "center", sm: "flex-end" }}
                alignItems={{ xs: "center", sm: "flex-end" }}
                gap={"10px"}
                flexWrap={"wrap"}
              >
                <Button
                  onClick={handleDialogClose}
                  style={{
                    textTransform: "capitalize",
                    border: `1px solid ${theme.palette.neutral.blueCyan}`,
                    width: "100px",
                    borderRadius: "8px",
                    fontWeight: 600,
                    padding: "8px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleUpdateQuantity}
                  style={{
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                    textTransform: "capitalize",
                    borderRadius: "8px",
                    fontWeight: 600,
                    padding: "8px 12px",
                  }}
                  disabled={newQuantity <= 0 || isNaN(newQuantity)}
                >
                  Set Quantity and Activate
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Stack>
      ) : contactForPrice ||
        currentProduct?.isOnline === false ||
        pathName === "service-details" ? (
        <Button
          variant="contained"
          onClick={handleGoToChat}
          endIcon={
            <IoChatbubbleEllipsesOutline color={theme.palette.primary.light} />
          }
          sx={{
            paddingX: "32px",
            width: {
              xs: "100%",
              sm: "19.375rem",
            },
            height: "56px",
            textTransform: "capitalize",
            fontWeight: "700",
            fontSize: "14px",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          {/* Contact Seller */}
          Start a chat
        </Button>
      ) : (
        !status?.isSold &&
        !status?.isDraft &&
        !contactForPrice && (
          <>
            <Box
              sx={{
                width: "10.25rem",
                paddingX: "20px",
                paddingY: "16px",
                borderRadius: "3px",
              }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton onClick={handleDecrement}>
                <MdRemove />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.primary.darkMain,
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {String(quantity)?.padStart(2, "0")}
              </Typography>
              <IconButton onClick={handleIncrement}>
                <MdAdd />
              </IconButton>
            </Box>

            <Button
              variant="contained"
              endIcon={
                <RiShoppingCartLine
                  color={
                    isButtonDisabled
                      ? theme.palette.secondary.main
                      : theme.palette.primary.light
                  }
                />
              }
              onClick={handleAddToCart}
              disabled={loading || isButtonDisabled}
              sx={{
                paddingX: "32px",
                width: {
                  xs: "100%",
                  sm: "19.375rem",
                },
                height: "56px",
                textTransform: "uppercase",
                fontWeight: "700",
                fontSize: "14px",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              {loading ? "adding to cart..." : buttonText}
            </Button>
          </>
        )
      )}
    </Stack>
  );
};

export default ButtonsGroup;
