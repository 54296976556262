import React from "react";
import {
  Dialog,
  Typography,
  Button,
  useTheme,
  Stack,
  Box,
} from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";

const DisclaimerDialog = ({
  openDisclaimerDialog,
  handleCloseDisclaimerDialog,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={openDisclaimerDialog}
      onClose={handleCloseDisclaimerDialog}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          padding: "24px",
          textAlign: "center",
        },
      }}
    >

      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "70px",
          height: "70px",
          borderRadius: "50%",
          backgroundColor: theme.palette.status.lightRed,
          color: theme.palette.status.cancel,
          margin: "0 auto",
          marginBottom: "16px",
        }}
      >
        <RiErrorWarningLine size={36} />
      </Stack>

      
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: theme.palette.text.primary,
          textTransform: "capitalize",
          marginBottom: "12px",
        }}
      >
        Disclaimer
      </Typography>

     
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.text.secondary,
          marginBottom: "16px",
          
        }}
      >
        The order is on hold due to one of the following reasons:
      </Typography>
      <Box
        component="ul"
        sx={{
          textAlign: "left",
          margin: "0 auto",
          maxWidth: "80%",
          paddingLeft: "16px",
          marginBottom: "24px",
        }}
      >
        <li>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            Your bank account information is incomplete.
          </Typography>
        </li>
        <li>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            Not all products in this order have been shipped yet.
          </Typography>
        </li>
      </Box>

      
      <Button
        variant="contained"
        onClick={handleCloseDisclaimerDialog}
        sx={{
          textTransform: "capitalize",
          fontWeight: "bold",
          padding: "8px 24px",
          borderRadius: "20px",
          boxShadow: "none",
          margin: "0 auto",
          width: "50%",

          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.light,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: "none",
          },
        }}
      >
        ok
      </Button>
    </Dialog>
  );
};

export default DisclaimerDialog;
