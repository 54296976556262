import React, { useEffect, useState } from "react";
import { db } from "../../../../../configs/firebase";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EditBusinessForm from "./components/EditBusinessForm";

const EditBusinessInfo = () => {
  const navigate = useNavigate();
  const [placeId, setPlaceId] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const userData = useSelector((state) => state.appState.userData);
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formattedDays, setFormattedDays] = useState([]);
  

  useEffect(() => {
    const fetchBusinessInfo = async () => {
      try {
        const docRef = doc(db, "users", userData?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const businessData = docSnap.data().businessInfo;
          // Set the initialValues based on the fetched businessInfo
          setInitialValues({
            businessName: businessData?.name || "",
            email: businessData?.email || "",
            phone: businessData?.phoneNumber || "",
            addressLineOne: businessData?.address?.addressLineOne || "",
            addressLineTwo: businessData?.address?.addressLineTwo || "",
            description: businessData?.description || "",
            city: businessData?.address?.city || "",
            state: businessData?.address?.state || "",
            zipCode: businessData?.address?.zipCode || "",
            websiteLink: businessData?.websiteLink || "",
            businessContent: businessData?.businessContent || "",
            businessImage: businessData?.businessImage || "",
          });

          // Also set other related state fields
          setPlaceId(businessData?.address?.placeId || null);
          setLat(businessData?.address?.lat || null);
          setLng(businessData?.address?.lng || null);
          setCountry(businessData?.address?.country || null);
          setCity(businessData?.address?.city || null);
          setState(businessData?.address?.state || null);
          setZipCode(businessData?.address?.zipCode || null);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    if (userData?.uid) {
      fetchBusinessInfo();
    }
  }, [userData?.uid]);

  const fetchAddresses = async () => {
    // setIsLoading(true);
    try {
      const addressesRef = collection(db, "users", userData?.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);
      const fetchedAddresses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedAddresses = fetchedAddresses.sort(
        (a, b) => b.isDefault - a.isDefault
      );

      // setAddresses(sortedAddresses);

      const defaultAddress = sortedAddresses?.find(
        (address) => address?.isDefault
      );
      setAddresses(defaultAddress);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.uid) {
      fetchAddresses();
    }
  }, [userData]);

  // Update Default Address

  const updateUserDefaultAddress = async (defaultAddress) => {
    const { customName, updatedAt, createdAt, ...address } = defaultAddress;
    try {
      await updateDoc(doc(db, "users", userData?.uid), {
        address: { ...address },
      });
      console.log("Default address updated in user profile:", address);
    } catch (error) {
      console.error("Error updating default address:", error);
    }
  };

  const handleSubmit = async (values, formattedDaysToFierbase) => {
    console.log(values, "Submitted values");

    try {
      if (!userData?.uid) {
        return message.error("User ID is not available");
      }

      if (!values.city || !values.state || !values.zipCode) {
        return message.error("Please select a valid address");
      }

      // Handle Addresses Collection
      const addressesRef = collection(db, "users", userData.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);

      let existingAddress = null;
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (
          data?.placeId === placeId ||
          (data?.lat === lat && data?.lng === lng) ||
          data?.addressLineOne === values?.addressLineOne
        ) {
          existingAddress = { id: doc.id, ...data };
        }
      });
      const batch = writeBatch(db);
      let newSelectedAddressId = null;

      if (existingAddress) {
        // Update Existing Address

        const addressRef = doc(addressesRef, existingAddress?.id);
        batch.update(addressRef, {
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          updatedAt: Timestamp.now(),
        });

        newSelectedAddressId = existingAddress?.id;
        // Update default address only if it's currently set as default
        if (existingAddress.isDefault) {
          await updateUserDefaultAddress({
            ...existingAddress,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
          });
        }
      } else {
        // Create New Address
        const newAddressRef = doc(addressesRef);
        const newAddressData = {
          id: newAddressRef.id,
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          placeId,
          lat,
          lng,
          country,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          isDefault: querySnapshot.empty, // First address is default
          name: "Business",
        };

        batch.set(newAddressRef, newAddressData);
        newSelectedAddressId = newAddressRef?.id;

        if (querySnapshot.empty) {
          await updateUserDefaultAddress(newAddressData);
        }
      }

      // Commit all batch updates
      await batch.commit();

      // Construct Business Info Object
      const businessInfo = {
        address: {
          id: newSelectedAddressId,
          addressLineOne: values?.addressLineOne,
          addressLineTwo: values?.addressLineTwo,
          city: values?.city || city,
          state: values?.state || state,
          zipCode: values?.zipCode || zipCode,
          name: values?.businessName,

          isDefault: false,
          placeId,
          lat,
          lng,
          country,
        },
        email: values?.email,
        phoneNumber: values?.phone,
        description: values?.description,
        name: values?.businessName,
        websiteLink: values?.websiteLink,
        businessContent: values?.businessContent,
        businessImage: values?.businessImage,
        openDays: formattedDaysToFierbase,
      };

      // Update Business Info in Firestore
      const userDocRef = doc(db, "users", userData.uid);
      await updateDoc(userDocRef, { businessInfo });
      message.success("Business info updated successfully!");

      localStorage.setItem("selectedAddressId", newSelectedAddressId);
      // Navigate to profile
      navigate("/profile?page=info&form=personal");
    } catch (error) {
      message.error(`Error saving address: ${error.message}`);
      console.error("Error saving address:", error);
    }
  };

  if (!initialValues) {
    return <p>Loading...</p>;
  }

  return (
    <EditBusinessForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      // selectedAddresId={selectedAddressId}
      setCountry={setCountry}
      setPlaceId={setPlaceId}
      
      setLat={setLat}
      setLng={setLng}
    />
  );
};

export default EditBusinessInfo;
