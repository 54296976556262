
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  useTheme,
  useMediaQuery,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import Slider from "react-slick";
import BlackLayer from "./BlackLayer";
import { FaChevronLeft, FaChevronRight, FaRegCirclePlay } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";



const ImageGallery = ({ currentProduct }) => {
  const theme = useTheme();
  const { images, video } = currentProduct;
 
  const [galleryItems, setGalleryItems] = useState(video ? [...images, video] : images);
  const [selectedItem, setSelectedItem] = useState(galleryItems[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false); 
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const mainVideoRef = useRef(null); 
  const dialogVideoRef = useRef(null); 
  const [slidesToShow, setSlidesToShow] = useState(6);
// Function to go to the previous item
const handlePrevious = () => {
  const newIndex = currentIndex === 0 ? galleryItems.length - 1 : currentIndex - 1;
  setCurrentIndex(newIndex);
  setSelectedItem(galleryItems[newIndex]);
};

// Function to go to the next item
const handleNext = () => {
  const newIndex = currentIndex === galleryItems.length - 1 ? 0 : currentIndex + 1;
  setCurrentIndex(newIndex);
  setSelectedItem(galleryItems[newIndex]);
};

  useEffect(() => {
    if (isMobile) {
      setSlidesToShow(4);
    } else if (isTablet) {
      setSlidesToShow(6);
    } else if (isDesktop) {
      setSlidesToShow(6);
    } else {
      setSlidesToShow(5);
    }
  }, [isMobile, isTablet, isDesktop]);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    arrows: true,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  const hiddenImagesAfter = galleryItems?.length - currentIndex - slidesToShow;
  const hiddenImagesBefore = currentIndex;

  // Function to open the dialog
  const handleOpenDialog = () => {
    if (selectedItem === video && mainVideoRef.current) {
      mainVideoRef.current.pause(); 
    }
    setDialogOpen(true); 
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
    if (dialogVideoRef.current) {
      dialogVideoRef.current.pause(); // Pause the dialog video when closing dialog
      dialogVideoRef.current.currentTime = 0; // Reset dialog video to start
    }
  };

  // Update galleryItems and selectedItem when currentProduct changes
  useEffect(() => {
    const updatedGalleryItems = video ? [...images, video] : images;
    setGalleryItems(updatedGalleryItems);
    setSelectedItem(updatedGalleryItems[0]); 
    setCurrentIndex(0);
  }, [currentProduct,images,video]);
  return (
    <Box
      sx={{
        textAlign: "center",
        width: {
          xs: "100%",
          md: "70%",
          lg: "26rem",
          xl: "34.563rem",
        },
        margin: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
        }}
        onClick={handleOpenDialog} 
      >
        {/* Check if the selected item is a video or image */}
        {selectedItem === video ? (
          <video
            ref={mainVideoRef} 
            controls
            style={{
              width: "100%",
              height: isMobile ? "300px" : "41.938rem", 
              objectFit: "cover",
              borderRadius: "8px",
            }}
            onPlay={() => {
              if (dialogOpen) mainVideoRef.current.pause(); // Pause main video if dialog is open
            }}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            src={selectedItem}
            alt="Selected"
            style={{
              width: "100%",
              height: isMobile ? "300px" : "41.938rem", 
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        )}
      </Box>

      {/* Dialog for displaying the image/video in a larger size */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width: {
              xs: "90%", 
              sm: "80%", 
              md: "70%", 
              lg: "60%", 
            },
            height: "auto",
            maxHeight: "90vh",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            padding: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.primary.light,
          }}
        >
          {/* Custom Close button */}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
              zIndex: 1,
            }}
          >
            <IoMdCloseCircle color={theme.palette.status.cancel} />
          </IconButton>

{/* Left Arrow */}
<IconButton
            onClick={handlePrevious}
            sx={{
              position: "absolute",
              left: 16,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: theme.palette.text.primary,
              "&:hover": { backgroundColor: theme.palette.text.border},
            }}
          >
            <FaChevronLeft style={{ color: theme.palette.primary.light }} />
          </IconButton>
          
          {/* Displaying the selected item in larger size */}
          {selectedItem === video ? (
            <video
              ref={dialogVideoRef}
              controls
              autoPlay // Start playing when dialog opens
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={selectedItem}
              alt="Selected in Dialog"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          )}
          {/* Right Arrow */}
          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: 16,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: theme.palette.text.primary,
              "&:hover": { backgroundColor: theme.palette.text.border},
            }}
          >
            <FaChevronRight style={{ color: theme.palette.primary.light }} />
          </IconButton>
        </Box>
      </Dialog>

      <Slider {...settings} style={{ marginTop: "1rem" }}>
        {galleryItems?.map((item, index) => (
          <div key={index} onClick={() => setSelectedItem(item)}>
            <Box
              sx={{
                width: "80px",
                height: "71px",
                borderRadius: "4px",
                position: "relative",
                border:
                  index === galleryItems?.indexOf(selectedItem)
                    ? `2px solid ${theme.palette.primary.main}`
                    : "none",
                margin: "0 auto",
              }}
            >
              {index === currentIndex + slidesToShow - 1 &&
                hiddenImagesAfter > 0 && (
                  <BlackLayer>
                    <Typography variant="h6">+{hiddenImagesAfter}</Typography>
                  </BlackLayer>
                )}
              {index === currentIndex && hiddenImagesBefore > 0 && (
                <BlackLayer>
                  <Typography variant="h6">+{hiddenImagesBefore}</Typography>
                </BlackLayer>
              )}
              {/* Display a play button icon or thumbnail for video */}
              {item === video ? (
                <Box
                  sx={{ position: "relative", width: "100%", height: "100%" }}
                >
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                  {/* Overlay the play button */}
                  <BlackLayer>
                    <FaRegCirclePlay style={{ fontSize: "28px" }} />
                  </BlackLayer>
                </Box>
              ) : (
                <img
                  src={item}
                  alt={`Thumbnail ${galleryItems?.indexOf(selectedItem)}`}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                />
              )}
            </Box>
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageGallery;
