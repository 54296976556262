import React from 'react';

import { Box,  InputLabel, OutlinedInput, Typography } from "@mui/material";
import { setFormTouched } from '../../../../redux/slices/appStateSlice';
import { useDispatch } from 'react-redux';

const EmailInput = ({ label, id, inputStyle, sharedStyles, field, form }) => {
  const dispatch=useDispatch()
  const handleInputChange = (event) => {
    field.onChange(event); 
    dispatch(setFormTouched())
  };

  return (
    <Box>
      <InputLabel htmlFor={id} sx={inputStyle}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        sx={{ ...sharedStyles, width: {
          xs:"100%",
          xl:"90%"
        } }}
        onInput={handleInputChange}
        type="email"
        {...field}
        error={form.touched[field.name] && Boolean(form.errors[field.name])}
      />
      {form.touched[field.name] && form.errors[field.name] && (
        <Typography variant="caption" sx={{display:"block", marginTop:"10px"}}  style={{ color: "red" }}>
          {form.errors[field.name]}
        </Typography>
      )}
    </Box>
  );
};

export default EmailInput;
