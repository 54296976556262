import React from "react";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { message } from "antd";
import { setUserData } from "../../redux/slices/appStateSlice";
import { Button, useTheme } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { auth, db } from "../../configs/firebase";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
const SocialAuthButton = ({ provider, action }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleAuth = async () => {
    try {
      let providerInstance;

      if (provider === "google") {
        providerInstance = new GoogleAuthProvider();
      } else if (provider === "apple") {
        providerInstance = new OAuthProvider("apple.com");
    
        providerInstance.addScope("email");
        providerInstance.addScope("name");
      }

      const result = await signInWithPopup(auth, providerInstance);
      const { uid, email, displayName } = result.user;
      const [firstName, lastName] = displayName?.split(" ") || ["", ""];

      const currentDate = new Date();

      // Convert the Date object to a Firestore Timestamp for storage
      const firestoreTimestamp = Timestamp.fromDate(currentDate);

      const userData = {
        uid,
        email,
        firstName,
        lastName,
        middleName: "",
        profilePicture: "",
        phoneNumber: "",
        websiteLink: "",
        registeredSince: firestoreTimestamp,
        followersCount: 0,
        completionRate: 0.0,
        activeProducts: 0,
        lastLoginInfo: {
          buildNumber: 0,
          lastLogin: firestoreTimestamp,
          osType: "web",
          version: "",
        },
        isDisabled: false,
        userType: "buyer",
        taxRate: 0,
        createdAt: firestoreTimestamp,
        updatedAt: firestoreTimestamp,
        address: null,
        cart: null,
      };

      // Check if the user already exists in the database
      const userRef = doc(db, "users", uid);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        // If user doesn't exist, create a new document
        await setDoc(userRef, userData);
      
      } else {
        // If the user exists, update specific fields in the document
        await updateDoc(userRef, {
          // use userRef to update, not userDoc
          lastLoginInfo: {
            buildNumber: 0,
            lastLogin: firestoreTimestamp,
            osType: "web",
            version: "",
          },
          updatedAt: firestoreTimestamp,
        });
        
      }

      dispatch(setUserData(userData));

      message.success("Authentication successful.");

      navigate("/home");
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        message.warning(
          "The popup was closed by the user before completing the sign-in."
        );
      } else {
        console.error("Error during sign-in:", error);
        message.error("Authentication failed. Please try again.");
      }
    }
  };

  return (
    <Button
      item
      xs={12}
      md={6}
      onClick={handleAuth}
      variant="outlined"
      startIcon={provider === "google" ? <FcGoogle /> : <FaApple />}
      sx={{
        textTransform: "capitalize",
        width: "13.125rem",
        fontSize: "14px",
        height: "2.75rem",
        borderRadius: "10px",
        border: "1px solid",
        borderColor: theme.palette.neutral.borderColor,
        color: theme.palette.primary.blackIcon,
        fontWeight: "500",
        "&:hover": {
          backgroundColor: "transparent", // Remove the background on hover
          boxShadow: "none", // Remove any shadow on hover
        },
        boxShadow: "none",
      }}
    >
      {action} {provider === "google" ? "Google" : "Apple"}
    </Button>
  );
};

export default SocialAuthButton;
