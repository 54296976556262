import { auth } from "../../../configs/firebase";
import {
  BASE_URL,
  STRIPE_CHECKOUT_ENDPOINT,
} from "../../../components/layout/constants";
export const createCheckoutLink = async (uid, addressId) => {
  try {
    const currentUser = auth?.currentUser;
    if (!currentUser) throw new Error("User is not authenticated");

    const userToken = await currentUser.getIdToken();
    const url = `${BASE_URL}${STRIPE_CHECKOUT_ENDPOINT}`;
    const headers = {
      "Content-Type": "application/json",
      authorization: userToken,
    };
    const body = JSON.stringify({ uid, addressId });
    const response = await fetch(url, {
      method: "POST",
      headers,
      body,
    });
    if (!response.ok) {
      throw new Error("Failed to create checkout link");
    }
    const responseData = await response.json();

    if (
      responseData?.isSuccess &&
      responseData?.data &&
      responseData?.data?.paymentLink
    ) {
      window.location.href = responseData?.data?.paymentLink;
    } else {
      console.error("Checkout URL not found or request was not successful");
    }
  } catch (error) {
    console.error("Error creating checkout link:", error);
  }
};
