import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiShoppingCart } from "react-icons/fi";
import { MdDelete, MdLocationOn } from "react-icons/md";
import { RiEditFill } from "react-icons/ri";
import { db, firestoreFieldValue } from "../../configs/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  getDoc,
} from "firebase/firestore";
import { message } from "antd";
import { setCartProducts, setUserData } from "../../redux/slices/appStateSlice";
import { CiHeart } from "react-icons/ci";
import { IoIosHeart } from "react-icons/io";
import {
  handleQuantityDecrement,
  handleQuantityIncrement,
} from "../../utils/cartUtils";
import { calculateCheckout } from "./calculateCheckout";
import { IoAdd, IoRemove } from "react-icons/io5";

const ItemsProducts = React.memo(
  ({
    item,
    index,
    isMediumScreen,
    isSmallScreen,
    categoryName,
    serviseName,
    selectedName,
    categoryOrServiceName,
    selectedNameCatOrServ,
    categoryHomeName,
    selectedTab,
    isLoggedIn,
    type,
    handleEdit,
    goToPage,
    selectedTabSeller,
    handleRemoveFromUI,
    serviseSellerName,
    categorySellerName,
    categoryFavouriteName,
    categoryMapName,
    serviseMapName,
    categorySavedForLaterName,
    serviseSavedForLaterName,
    isTitle = false,
    handleDelete,
  }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isSellerListPage = pathname === "/selling";
    const [nameToDisplay, setNameToDisplay] = useState("");

    useEffect(() => {
      const computedNameToDisplay =
        selectedNameCatOrServ ||
        selectedName ||
        serviseMapName ||
        categoryMapName ||
        categoryOrServiceName ||
        categoryHomeName ||
        categorySavedForLaterName ||
        serviseSavedForLaterName ||
        categoryFavouriteName ||
        (selectedTabSeller === "services"
          ? serviseSellerName
          : categorySellerName) ||
        (selectedTab === "services" ? serviseName : categoryName);

      setNameToDisplay(computedNameToDisplay);
    }, [
      serviseSavedForLaterName,
      selectedNameCatOrServ,
      selectedName,
      categoryOrServiceName,
      categoryHomeName,
      categoryFavouriteName,
      selectedTabSeller,
      serviseSellerName,
      categorySellerName,
      selectedTab,
      serviseName,
      categoryName,
      categorySavedForLaterName,
      serviseMapName,
      categoryMapName,
    ]);

    const locationToDisplay =
      selectedTab === "activeService" || selectedTab === "draftService"
        ? `${item?.businessAddress?.city || ""}${
            item?.businessAddress?.city && item?.businessAddress?.state
              ? ", "
              : ""
          }${item?.businessAddress?.state || ""}`
        : `${item?.shippingAddress?.city || ""}${
            item?.shippingAddress?.city && item?.shippingAddress?.state
              ? ", "
              : ""
          }${item?.shippingAddress?.state || ""}`;

    const dispatch = useDispatch();
    const user = useSelector((state) => state.appState.userData);
    const [loading, setLoading] = useState(false);
    const [isInCart, setIsInCart] = useState(false);
    const [favoriteClicked, setFavoriteClicked] = useState(false);
    const [allProductsData, setAllProductsData] = useState([]);
    const [isLoadingAllProducts, setIsLoadingAllProducts] = useState(false);
    const cartProducts = useSelector((state) => state.appState.cartProducts);
    const [totals, setTotals] = useState({
      subtotal: 0,
      tax: 0,
      totalWithTax: 0,
    });

    const productInAllProducts = allProductsData.find((p) => p.id === item?.id);
    const isMaxQuantity =
      productInAllProducts &&
      isInCart &&
      user?.cart?.items?.find((p) => p.id === item?.id)?.quantity ===
        productInAllProducts?.quantity;

    useEffect(() => {
      const itemInCart = user?.cart?.items?.some(
        (cartItem) => cartItem?.id === item?.id
      );
      setIsInCart(itemInCart);
    }, [user, cartProducts, item?.id]);

    useEffect(() => {
      const fetchFavoriteStatus = async () => {
        if (!user || !user.uid) return;
        try {
          const userFavoritesRef = collection(
            db,
            "users",
            user.uid,
            "favoriteProducts"
          );
          const q = query(
            userFavoritesRef,
            where("productModel.id", "==", item.id)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            setFavoriteClicked(true);
          }
        } catch (error) {
          console.error("Error fetching favorite status: ", error);
        }
      };

      fetchFavoriteStatus();
    }, [user, item?.id]);

    const handleAddToFavorite = async () => {
      if (!isLoggedIn) {
        message.error("You need to be logged in to add items to favorites.");
        navigate("/login");
        return;
      }

      try {
        const userFavoritesRef = collection(
          db,
          "users",
          user.uid,
          "favoriteProducts"
        );

        const q = query(
          userFavoritesRef,
          where("productModel.id", "==", item.id)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          if (!favoriteClicked) {
            message.info("This item is already in your favorites.");
            setFavoriteClicked(true);
          } else {
            const docId = querySnapshot.docs[0].id;
            await deleteDoc(doc(userFavoritesRef, docId));

            setFavoriteClicked(false);
            if (typeof handleRemoveFromUI === "function") {
              handleRemoveFromUI(item.id);
            }

            message.success("Item removed from favorites!");
          }
          return;
        }

        if (querySnapshot.empty) {
          const {
            _highlightResult,
            objectID,
            path,
            ...itemDataWithoutHighlight
          } = item;
          const productModel = {
            ...itemDataWithoutHighlight,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            registeredSince: serverTimestamp(),
          };

          const docRef = await addDoc(userFavoritesRef, { productModel });

          const favoriteItem = {
            id: docRef.id,
            productModel: productModel,
            createdAt: serverTimestamp(),
          };

          await updateDoc(docRef, favoriteItem);
          setFavoriteClicked(true);
          message.success("Item added to favorites!");
        } else {
          const docId = querySnapshot.docs[0].id;
          await deleteDoc(doc(userFavoritesRef, docId));

          setFavoriteClicked(false);
          message.success("Item removed from favorites!");
        }
      } catch (error) {
        message.error("Failed to toggle favorite status");
        console.error("Error toggling favorite item: ", error);
      }
    };

    const handleAddToCart = async () => {
      if (!isLoggedIn) {
        message.error("You need to be logged in to add items to the cart.");
        navigate("/login");
        return;
      }

      if (user?.uid === item?.uid) {
        message.error("You cannot add your own product to the cart.");
        return;
      }

      try {
        setLoading(true);
        // Fetch the latest product data for validation
        const productRef = doc(db, "products", item?.id);
        const productSnapshot = await getDoc(productRef);

        if (!productSnapshot.exists()) {
          message.error("This product no longer exists.");
          return;
        }

        const latestProductData = productSnapshot.data();

        // Validate contactForPrice before proceeding
        if (latestProductData?.contactForPrice) {
          message.warning(
            "This product is changed to contact for price and cannot be added to the cart."
          );
          return;
        }

        if (item?.isSold || item?.isDraft || item?.isDeleted) {
          message.warning(
            "This product is out of stock and will be removed from favorites."
          );
          try {
            // Remove item from favorites
            const userFavoritesRef = collection(
              db,
              "users",
              user.uid,
              "favoriteProducts"
            );
            const q = query(
              userFavoritesRef,
              where("productModel.id", "==", item?.id)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
              const docId = querySnapshot.docs[0].id;
              await deleteDoc(doc(userFavoritesRef, docId));
              setFavoriteClicked(false);
              if (typeof handleRemoveFromUI === "function") {
                handleRemoveFromUI(item?.id);
              }
            }
          } catch (error) {
            message.error("Failed to remove item from favorites.");
            console.error("Error removing item from favorites: ", error);
          }
          return;
        }

        const productInAllProducts = allProductsData?.find(
          (p) => p?.id === item?.id
        );

        const isMaxQuantity =
          productInAllProducts &&
          isInCart &&
          user?.cart?.items?.find((p) => p?.id === item?.id)?.quantity ===
            productInAllProducts.quantity;

        if (isMaxQuantity) {
          message.warning("You've reached the maximum quantity for this item.");
          return;
        }

        // Determine shipping price and company
        let shippingPrice = 0;
        let shippingCompanyName = null;
        let isLocalPickup = false;

        if (item?.isLocalPickup === true && item?.companies?.length === 0) {
          shippingPrice = 0;
          isLocalPickup = true;
          shippingCompanyName = null;
        } else if (
          item?.companies?.length > 0 &&
          item?.isLocalPickup !== true
        ) {
          const minShippingCompany = item?.companies.reduce((prev, curr) =>
            prev.price < curr.price ? prev : curr
          );

          shippingPrice = minShippingCompany?.price || 0;
          shippingCompanyName = minShippingCompany?.name || null;
          isLocalPickup = false;
        } else {
          shippingPrice = 0;
          isLocalPickup = true;
          shippingCompanyName = null;
        }

        if (isInCart) {
          // Increase quantity if the item is already in the cart
          handleQuantityIncrement(
            item?.id,
            allProductsData,
            user?.cart?.items,
            user,
            dispatch,
            setTotals
          );
          message.success("Increased item quantity in the cart.");
        } else {
          // Add to cart if the item isn't in the cart yet
          const cartItem = {
            id: item?.id,
            image: item?.images[0],
            price: item?.price,
            quantity: 1,
            isLocalPickup: isLocalPickup,
            shipping_company: shippingCompanyName,
            seller_id: item?.uid,
            shipping_price: shippingPrice,
            tax_amount: null,
            title: item?.title,
          };

          const userDocRef = doc(db, "users", user?.uid);
          await updateDoc(userDocRef, {
            "cart.items": firestoreFieldValue.arrayUnion(cartItem),
          });

          const updatedCart = user.cart
            ? [...user?.cart?.items, cartItem]
            : [cartItem];
          dispatch(
            setUserData({
              ...user,
              cart: { ...user?.cart, items: updatedCart },
            })
          );
          setIsInCart(true);
          message.success("Item added to cart successfully.");
        }

        calculateCheckout(user?.uid, dispatch);
      } catch (error) {
        message.error("Failed to add item to cart");
        console.error("Error adding item to cart: ", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchProductsData = async (cartItems) => {
      setIsLoadingAllProducts(true);
      try {
        const productIds = cartItems?.map((item) => item?.id);
        const productsRef = db.collection("products");

        const querySnapshot = await productsRef
          .where("id", "in", productIds)
          .get();

        let fetchedProducts = [];
        querySnapshot.forEach((doc) => {
          fetchedProducts.push(doc.data());
        });

        setAllProductsData(fetchedProducts);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setIsLoadingAllProducts(false);
      }
    };

    useEffect(() => {
      if (cartProducts?.length > 0) {
        fetchProductsData(cartProducts);
      }
    }, [cartProducts]);

    useEffect(() => {
      if (user) {
        dispatch(setCartProducts(user?.cart?.items));
      }
    }, [dispatch, user]);

    const handleContactSeller = (currentProduct) => {
      if (item?.isSold || item?.isDraft) {
        message.warning(
          "This product is out of stock and will be removed from favorites."
        );
        return;
      }
      if (user?.uid === item?.uid) {
        message.error("You cannot contact yourself as the seller.");
        return;
      }

      if (isLoggedIn) {
        handleGoToChat(currentProduct);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        navigate("/login");
      }
    };

    const handleGoToChat = (currentProduct) => {
      if (!currentProduct?.shippingAddress) {
        const service = {
          id: currentProduct?.id,
          image: currentProduct?.images[0],
          title: currentProduct?.title,
          description: currentProduct?.description,
          ...(currentProduct?.price != null && {
            price: currentProduct?.price,
          }),
        };
        navigate("/chat?type=services", {
          state: { service, userIdFromService: currentProduct?.uid },
        });
      } else {
        const item = {
          id: currentProduct?.id,
          image: currentProduct?.images[0],
          price: currentProduct?.price,
          title: currentProduct?.title,
          description: currentProduct?.description,
        };

        navigate("/chat?type=items", {
          state: { item, userIdFromItem: currentProduct?.uid },
        });
      }
    };

    return (
      <Box
        key={index}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 10px",
          width: "100%",
          height: {
            xs: isInCart ? "auto" : "440px",
            sm: "400px",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: isSmallScreen ? "280px" : "220px",
            borderRadius: "24px",
            margin: "auto",
            boxSizing: "border-box",
            overflow: "hidden",
            position: "relative",
           
          }}
        
        >
          <img
            src={item?.images[0]}
            alt={item?.title}
            loading="lazy"
            style={{
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
            onClick={() => goToPage(item?.id, item, nameToDisplay)}
          />
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: theme.palette.primary.darkColor,
              color: theme.palette.primary.light,
              padding: "5px 10px",
              borderRadius: "12px",
              fontSize: ".9rem",
            }}
          >
            {item?.price ? `$${item?.price}` : "Contact for Price"}
          </Box>
          {/* // If the current page is a Seller List Page, display the edit and delete buttons */}
          {isSellerListPage && (
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <IconButton
                onClick={() => handleEdit(item?.id)}
                sx={{
                  backgroundColor: theme.palette.dark.backgroundIcons,
                  borderRadius: "50%",
                  width: "32px",
                  height: "32px",
                  padding: "6px !important",
                  color: theme.palette.primary.light,
                  boxShadow: "0px 2px 4px rgba(255, 255, 255, 0.5)",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              >
                <RiEditFill />
              </IconButton>
              <IconButton
                onClick={() => handleDelete(item?.id)}
                sx={{
                  backgroundColor: theme.palette.dark.backgroundIcons,
                  borderRadius: "50%",
                  width: "32px",
                  height: "32px",
                  padding: "6px !important",
                  color: theme.palette.primary.light,
                  "&:hover": {
                    backgroundColor: theme.palette.error.main,
                  },
                }}
              >
                <MdDelete />
              </IconButton>
            </Box>
          )}
          {!isSellerListPage && (
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {type !== "filtered-services" &&
                selectedTabSeller !== "filtered-services" && (
                  <IconButton
                    onClick={handleAddToFavorite}
                    sx={{
                      backgroundColor: theme.palette.dark.backgroundIcons,
                      borderRadius: "50%",
                      width: "32px",
                      height: "32px",
                      padding: "6px !important",
                      color: theme.palette.primary.light,
                      boxShadow: "0px 2px 4px rgba(255, 255, 255, 0.5)",
                      "&:hover": {
                        backgroundColor: theme.palette.error.main,
                      },
                    }}
                  >
                    {favoriteClicked ? <IoIosHeart color="red" /> : <CiHeart />}
                  </IconButton>
                )}
            </Box>
          )}
        </Box>
<Box sx={{ cursor: "pointer", textAlign: 'left', width: '100%'}} onClick={() => goToPage(item?.id, item, nameToDisplay)}>

        <Box
          sx={{
            marginTop: "20px",
            textAlign: "left",
            width: "100%",
            padding: "0px 10px 0 10px",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: 600,
              color: theme.palette.primary.light,
              fontSize: "12px",
              background: theme.palette.status.background,
              borderRadius: "50px",
              padding: "6px",
            }}
          >
            {nameToDisplay}
          </Typography>
        </Box>

        <Box
          sx={{
            textAlign: "left",
            color: theme.palette.primary.darkMain,
            padding: "15px 10px 0",
            minHeight: isSellerListPage ? "10px" : { sm: "45px", md: "65px" },
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: "10px" }}>
            {item?.title
              ? isMediumScreen
                ? item?.title.length > 10
                  ? `${item?.title.slice(0, 10)}...`
                  : item?.title
                : item?.title.length > 15
                ? `${item?.title.slice(0, 15)}...`
                : item?.title
              : "No title available"}
          </Typography>
          <Typography variant="body2">
            {item?.description
              ? isMediumScreen
                ? item?.description.length > 20
                  ? `${item?.description.slice(0, 20)}...`
                  : item?.description
                : item?.description.length > 25
                ? `${item?.description.slice(0, 25)}...`
                : item?.description
              : "No description available"}
          </Typography>
        </Box>
</Box>
        {isSellerListPage && !isSellerListPage ? (
          <Box
            sx={{
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              mt: 1,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.dark.charcoalBlack,
                fontSize: ".9rem",
                fontWeight: 700,
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
                marginLeft: "9px",
              }}
            >
              <MdLocationOn
                style={{
                  color: theme.palette.dark.charcoalBlack,
                  width: "15px",
                  height: "18px",
                  marginRight: "5px",
                }}
              />
              {locationToDisplay}
            </Typography>
          </Box>
        ) : item?.isOnline === true &&
          item?.price !== null &&
          item?.contactForPrice === false &&
          (type !== "filtered-services" ||
            selectedTabSeller !== "filtered-services") ? (
          <Grid
            container
            sx={{ alignItems: "center", justifyContent: "space-around" }}
          >
            {cartProducts?.map((product) => {
              if (product?.id === item?.id) {
                return (
                  <Grid item xs={12} md={2} sx={{ mt: 3 }} key={product.id}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        borderRadius: "10.625rem",
                        padding: "8px",
                        border: `1px solid ${theme.palette.neutral.lightGrayBorder}`,
                        width: "6.5rem",
                        height: "40px",
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          handleQuantityDecrement(
                            product.id,
                            cartProducts,
                            user,
                            dispatch
                          )
                        }
                        disabled={product?.quantity === 1}
                        sx={{
                          backgroundColor:
                            product?.quantity !== 1
                              ? theme.palette.primary.main
                              : theme.palette.primary.light,
                          width: "24px",
                          height: "24px",
                          border:
                            product?.quantity !== 1
                              ? `1px solid ${theme.palette.primary.main}`
                              : `1px solid ${theme.palette.primary.lightGray}`,
                          color: theme.palette.primary.light,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor:
                              product?.quantity !== 1
                                ? theme.palette.primary.main
                                : theme.palette.primary.lightGray,
                            color:
                              product?.quantity !== 1
                                ? theme.palette.primary.light
                                : theme.palette.primary.darkBlack,
                          },
                        }}
                        size="small"
                      >
                        <IoRemove />
                      </IconButton>

                      <Typography variant="body2">
                        {product?.quantity}
                      </Typography>

                      <IconButton
                        onClick={() => {
                          handleQuantityIncrement(
                            product?.id,
                            allProductsData,
                            cartProducts,
                            user,
                            dispatch
                          );
                        }}
                        disabled={
                          productInAllProducts &&
                          product?.quantity === productInAllProducts?.quantity
                        }
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          width: "24px",
                          height: "24px",
                          border:
                            productInAllProducts &&
                            product?.quantity === productInAllProducts.quantity
                              ? `1px solid ${theme.palette.primary.lightGray}`
                              : `1px solid ${theme.palette.primary.main}`,
                          color: theme.palette.primary.light,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.light,
                          },
                        }}
                        size="small"
                      >
                        <IoAdd />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              }
              return null;
            })}

            <Grid
              item
              xs={12}
              md={isInCart ? 8 : 12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  ml: 3,
                  width: "100%",
                  padding: "12px 24px",
                  fontWeight: "700",
                  backgroundColor: isMaxQuantity
                    ? theme.palette.primary.lightGray
                    : isInCart
                    ? theme.palette.primary.lightMain
                    : item?.quantity === 0
                    ? theme.palette.primary.lightGray
                    : theme.palette.primary.main,
                  color: theme.palette.primary.light,
                  borderRadius: "16px",
                  marginTop: "15px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: theme.palette.primary.main,
                  },
                }}
                onClick={handleAddToCart}
                disabled={isMaxQuantity || item?.quantity === 0}
              >
                <FiShoppingCart
                  style={{
                    marginRight: "15px",
                    fontSize: "x-large",
                    borderRadius: "10px",
                  }}
                />
                {loading
                  ? "Processing..."
                  : isMaxQuantity
                  ? "Max Quantity"
                  : isInCart
                  ? // ? "Increase Quantity"
                    "Add to Cart"
                  : item?.quantity === 0
                  ? "Out of Stock"
                  : "Add to Cart"}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            {item?.contactForPrice === true && type === "filtered-services" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 12px",
                  margin: "15px 0",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.primary.text,
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                  onClick={() => handleContactSeller(item)}
                >
                  Contact Seller
                </Typography>
                <IoIosArrowForward
                  style={{
                    color: theme.palette.primary.text,
                    width: "24px",
                    height: "24px",
                  }}
                />
              </Box>
            ) : item?.isOnline === true &&
              item.price !== null &&
              item?.contactForPrice === false ? (
              <Button
                sx={{
                  width: "100%",
                  padding: "12px 24px",
                  fontWeight: "700",
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                  borderRadius: "16px",
                  marginTop: "15px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: theme.palette.primary.main,
                  },
                }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  if (isLoggedIn) {
                    navigate("/cart");
                  } else {
                    // Redirect to login page
                    navigate("/login");
                  }
                }}
              >
                <FiShoppingCart
                  style={{
                    marginRight: "15px",
                    fontSize: "x-large",
                    borderRadius: "10px",
                  }}
                />{" "}
                Add to Cart
              </Button>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 12px",
                  margin: "15px 0",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.primary.text,
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                  onClick={() => handleContactSeller(item)}
                >
                  Contact Seller
                </Typography>
                <IoIosArrowForward
                  style={{
                    color: theme.palette.primary.text,
                    width: "24px",
                    height: "24px",
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }
);
export default ItemsProducts;
