import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  Button,
  ButtonGroup,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const ItemsControl = ({
  totalItems,
  type,
  selectedTab,
  categoryName,
  sortOrderControle,
  handleSortOrderChange,
  selectedNameCatOrServ,
  handleCategoryChange,
  handleServiceChange,
  categories,
  services,
  selectedCategoryId,
  selectedServiceId,
  setShowMap,
  showMap,
  setSelectedTypeMap,
  selectedTypeMap,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const displayName =
    selectedNameCatOrServ ||
    categoryName ||
    (type === "filtered-categories" ? "Category" : "Service");

  const isListItemPage = location.pathname === "/selling";
  const isListingMapPage = location.pathname === "/explore";

  const nameToDisplay =
    selectedTab === "activeService" || selectedTab === "draftService"
      ? "My Services"
      : "My Parts";

  // const handleMapBtnClick = () => {
  //   setShowMap(true);
  // };

  // const handleListBtnClick = () => {
  //   setShowMap(false);
  // };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: 4 }}
    >
      {!isListingMapPage && (
        <>
          {isListItemPage ? (
            <Grid item xs={12} sm={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: theme.typography.headerFont,
                  fontSize: "1.5rem",

                  color: theme.palette.dark.charcoalBlack,
                  marginBottom: isSmallScreen ? "20px" : "0",
                }}
              >
                {nameToDisplay}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              {!isSmallScreen && (
                <Typography variant="body1">
                  {totalItems} parts in {displayName}{" "}
                </Typography>
              )}
            </Grid>
          )}
        </>
      )}
      {isListingMapPage && (
        <Box
          sx={{
            backgroundColor: theme.palette.primary.light,
            padding: "6px",
            borderRadius: "30px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            marginBottom: isSmallScreen ? "20px" : "0",
          }}
        >
          {/* Toggle between Items and Services */}
          <Box
            sx={{
              fontFamily: theme.typography.headerFont,
              fontSize: "1.5rem",
              color: theme.palette.dark.charcoalBlack,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              backgroundColor: theme.palette.primary.light,
              borderRadius: "72px",
              gap: "7px",
            }}
          >
            <Button
           onClick={() => setShowMap("list")}
              sx={{
                backgroundColor:
                !showMap
                    ? theme.palette.primary.main
                    : "transparent",
                color:
                !showMap
                    ? theme.palette.primary.light
                    : theme.palette.dark.charcoalBlack,
                fontWeight: 700,
                padding: "6px 25px",
                textTransform: "capitalize",
                borderRadius: "40px",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                  borderRadius: "40px",
                },
              }}
            >
              List
            </Button>
            <Button
             onClick={() => setShowMap("map")}
              sx={{
                backgroundColor:
                showMap
                    ? theme.palette.primary.main
                    : "transparent",
                color:
                showMap
                    ? theme.palette.primary.light
                    : theme.palette.dark.charcoalBlack,
                fontWeight: 700,
                padding: "6px 25px",
                textTransform: "capitalize",
                borderRadius: "40px",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                  borderRadius: "40px",
                },
              }}
            >
              Map
            </Button>
          </Box>
        </Box>
      )}
      {isListItemPage ? (
        <>
          <Grid
            item
            xs={12}
            sm={9}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 3,
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              <Typography>
                {selectedTab === "activeService" ||
                selectedTab === "draftService"
                  ? "Filter by Services"
                  : "Filter by Categories"}
              </Typography>
              <Select
                value={
                  selectedTab === "activeService" ||
                  selectedTab === "draftService"
                    ? selectedServiceId || "" // Service ID when in service tab
                    : selectedCategoryId || "" // Category ID when in category tab
                }
                onChange={
                  (event) =>
                    selectedTab === "activeService" ||
                    selectedTab === "draftService"
                      ? handleServiceChange(event.target.value) // Change service ID when in service tab
                      : handleCategoryChange(event.target.value) // Change category ID when in category tab
                }
                displayEmpty
              >
                <MenuItem value="">
                  {selectedTab === "activeService" ||
                  selectedTab === "draftService"
                    ? "All Services"
                    : "All Categories"}
                </MenuItem>
                {selectedTab === "activeService" ||
                selectedTab === "draftService"
                  ? services.map((service) => (
                      <MenuItem key={service?.id} value={service?.id}>
                        {service?.name}
                      </MenuItem>
                    ))
                  : categories.map((category) => (
                      <MenuItem key={category?.id} value={category?.id}>
                        {category?.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              size="small"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              <Typography>Sort by price</Typography>
              <Select
                value={sortOrderControle}
                onChange={(event) => handleSortOrderChange(event.target.value)}
             
              >
                <MenuItem value="default">Random</MenuItem>
                <MenuItem value="price_asc">Price: Low to High</MenuItem>
                <MenuItem value="price_desc">Price: High to Low</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>
      ) : (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <FormControl variant="outlined" size="small">
            <Select
              value={sortOrderControle}
              onChange={(event) => handleSortOrderChange(event.target.value)}
             
            >
              <MenuItem value="default">Random</MenuItem>
              <MenuItem value="price_asc">Price: Low to High</MenuItem>
              <MenuItem value="price_desc">Price: High to Low</MenuItem>
            </Select>
          </FormControl>
          {!isListingMapPage && (
            <Box
              sx={{
                backgroundColor: theme.palette.primary.light,
                padding: "6px",
                borderRadius: "30px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                marginBottom: isSmallScreen ? "20px" : "0",
                marginRight: !isLargeScreen && "20px" ,
              }}
            >
              {/* Toggle between Items and Services */}
              <Box
                sx={{
                  fontFamily: theme.typography.headerFont,
                  fontSize: "1.5rem",
                  color: theme.palette.dark.charcoalBlack,
                  
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: "72px",
                  gap: "7px",
                }}
              >
                <Button
                  onClick={() => setShowMap("list")}
                  sx={{
                    backgroundColor: !showMap
                      ? theme.palette.primary.main
                      : "transparent",
                    color: !showMap
                      ? theme.palette.primary.light
                      : theme.palette.dark.charcoalBlack,
                    fontWeight: 700,
                    padding: "6px 25px",
                    textTransform: "capitalize",
                    borderRadius: "40px",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                      borderRadius: "40px",
                    },
                  }}
                >
                  List
                </Button>
                <Button
                  onClick={() => setShowMap("map")}
                  sx={{
                    backgroundColor: showMap
                      ? theme.palette.primary.main
                      : "transparent",
                    color: showMap
                      ? theme.palette.primary.light
                      : theme.palette.dark.charcoalBlack,
                    fontWeight: 700,
                    padding: "6px 25px",
                    textTransform: "capitalize",
                    borderRadius: "40px",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                      borderRadius: "40px",
                    },
                  }}
                >
                  Map
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ItemsControl;
