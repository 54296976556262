import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiRotateRight } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { FcShipped } from "react-icons/fc";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { FaHome } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { FiLoader ,FiRefreshCw} from "react-icons/fi";

const StatusDisplay = ({ status }) => {
  const theme = useTheme();

  // Icon and color selection logic
  const getStatusIconAndColor = (status) => {
    switch (status) {
      case "completed":
        return {
          icon: <IoCheckmarkCircleOutline size="24px" />,
          color: theme.palette.status.darkGreen,
        };
      case "paid":
        return {
          icon: <IoCheckmarkCircleOutline size="24px" />,
          color: theme.palette.status.darkGreen,
        };
      case "cancelled":
        return {
          icon: <ImCancelCircle size="15px" />,
          color: theme.palette.status.cancel,
        };
      case "pending":
        return {
          icon: <BiRotateRight size="24px" />,
          color: theme.palette.status.pending,
        };
      case "preparing":
        return {
          icon: <BiRotateRight size="24px" />,
          color: theme.palette.status.pending,
        };
      case "withheld":
        return {
          icon: <IoIosWarning size="24px" />,
          color: theme.palette.status.pending,
        };
      case "shipped":
        return {
          icon: <FcShipped size="24px" />,
          color: theme.palette.status.darkGreen,
        };
      case "delivered":
        return {
          icon: <FaHome size="24px" />,
          color: theme.palette.status.darkGreen,
        };
      case "precessing":
        return {
          icon: <BiRotateRight size="24px" />,
          color: theme.palette.status.pending,
        };
      case "transferred":
        return {
          icon: <BiRotateRight size="24px" />,
          color: theme.palette.status.darkGreen,
        };

      case "processing":
        return {
          icon: <FiLoader size="24px" />, 
          color: theme.palette.status.pending,       
        };

      case "refunded":
        return {
          icon: <FiRefreshCw size="24px" />,
          color: theme.palette.status.darkGreen,
        };

      default:
        return { icon: null, color: "inherit" };
    }
  };

  const { icon, color } = getStatusIconAndColor(status);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        color: color,
      }}
    >
      {icon}
      <Typography
        variant="body2"
        sx={{ fontWeight: "500", textTransform: "capitalize" }}
      >
        {status}
      </Typography>
    </Box>
  );
};

export default StatusDisplay;
