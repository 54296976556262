import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCropUploader = ({
  isCropDialogOpen,
  handleCropCancel,
  currentFile,
  cropperRef,
  handleCropSave,
  setFieldValue,
}) => {
  return (
    <Dialog
      open={isCropDialogOpen}
      onClose={handleCropCancel}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent>
        {currentFile && (
          <Cropper
            src={URL.createObjectURL(currentFile)}
            style={{ height: 400, width: "100%" }}
            initialAspectRatio={4 / 3}
            guides={true}
            viewMode={1}
            background={false}
            responsive={true}
            autoCropArea={1}
            cropBoxResizable
            cropBoxMovable
            dragMode="move"
            checkOrientation={false}
            ref={cropperRef}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCropCancel} color="primary" style={{textTransform:"capitalize"}}>
          Cancel
        </Button>
        <Button
          onClick={() => handleCropSave(setFieldValue)}
          color="primary"
          variant="contained"
          style={{textTransform:"capitalize"}}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropUploader;
