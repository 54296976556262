import React, { useState, useEffect } from "react";
import {
  useTheme,
  Box,
  Typography,
  Dialog,
  useMediaQuery,
  Button,
} from "@mui/material";

import algoliasearch from "algoliasearch";
import { db } from "../../../configs/firebase";
import { Timestamp } from "firebase/firestore";
import { Algolia_App_Id, Algolia_Search_Api_Key } from "../../layout/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Loader from "../Loader";

import {
  setIsFullscreen,
  setRequests,
} from "../../../redux/slices/appStateSlice";
import noRequestsImage from "../../../assets/Images/no-Items.png";
import DeleteBox from "../../../components/common/DeleteBox";
import RequestItem from "./RequestItem";
import { CiSquarePlus } from "react-icons/ci";
import imageCorner from "../../../assets/Icons/full-screen-corner.png";

const RequestComponent = ({
  margin,
  categoryId,
  height,
  searchTerm,
  userLocation,
  sortOrderControle,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down(1124));
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const displayedRequestUpdate = useSelector(
    (state) => state.appState.requests
  );

  const isFullscreen = useSelector((state) => state.appState.isFullScreen);

  const userData = useSelector((state) => state.appState.userData);
  const [anchorEl, setAnchorEl] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDetailsExpanded, setisDetailsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expandedRequestId, setExpandedRequestId] = useState(null);

  const requestsSectionRef = React.useRef(null);

  const handleFullScreenToggle = () => {
    dispatch(setIsFullscreen(!isFullscreen));
  };

  const toggleDetailsExpanded = (id) => {
    setisDetailsExpanded(!isDetailsExpanded);
    setExpandedRequestId(expandedRequestId === id ? null : id);

    if (isDetailsExpanded && requestsSectionRef.current) {
      setTimeout(() => {
        requestsSectionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start", // Ensures it scrolls to the top of the section
        });
      }, 100);
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpenDialog(true);
    setAnchorEl(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
    setAnchorEl(null);
    setCurrentId(null);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentId(null);
  };

  const handleDelete = async () => {
    if (!currentId) return;

    const selectedRequest = displayedRequestUpdate?.find(
      (request) => request.id === currentId
    );

    if (selectedRequest && selectedRequest?.uid === userData?.uid) {
      handleOpenDeleteDialog();
      const currentDate = new Date();
      const firestoreTimestamp = Timestamp.fromDate(currentDate);
      try {
        await db
          .collection("requests")
          .doc(currentId)
          .update({ isDeleted: true, deletedAt: firestoreTimestamp });
        // await db.collection("requests").doc(currentId).delete();
        const deleteRequests = displayedRequestUpdate.filter(
          (request) => request.id !== currentId
        );

        dispatch(setRequests(deleteRequests));

        message.success({
          content: "Your request has been successfully deleted.",
          duration: 5,
        });
        handleCloseDeleteDialog();
        handleClose();
      } catch (error) {
        console.error("Error deleting document: ", error);
        message.error(
          "There was an error deleting your request. Please try again."
        );
      }
    } else {
      message.error("You don't have permission to delete this request.");
    }
  };

  const handleUpdate = (addressId) => {
    navigate("/requests/add", { state: { requestId: currentId, addressId } });
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleClose();
  };

  useEffect(() => {
    if (location.state && location.state.scrollToRequests) {
      const requestsSection = document.getElementById("requests-section");
      if (requestsSection) {
        requestsSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    if (
      userData &&
      !(Array.isArray(userData) && userData?.length === 0) &&
      typeof userData === "object" &&
      userData?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  const handleChatButtonClick = (request) => {
    if (isLoggedIn) {
      navigate("/chat?type=users", { state: { user: request } });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/login");
    }
  };

  const goToSellerPage = (request) => {
    if (isLoggedIn) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(`/sellers/seller-details/${request}`);
    } else {
      navigate("/login");
    }
  };

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const client = algoliasearch(Algolia_App_Id, Algolia_Search_Api_Key);

      // Fix: Ensure indexName is a string
      const indexName =
        sortOrderControle === "request_desc" ? "requests nearest" : "requests";
      const index = client.initIndex(indexName);

      let filterQuery = `isDeleted:false`;
      if (categoryId) {
        filterQuery += ` AND categoryId:${categoryId}`;
      }

      const algoliaQuery = {
        filters: filterQuery,
        query: searchTerm || "",

        // Fix: Correct location-based search
        ...(sortOrderControle === "request_desc" &&
        userLocation?.lat != null &&
        userLocation?.lng != null
          ? {
              aroundLatLng: `${userLocation?.lat}, ${userLocation?.lng}`,
              aroundRadius: "all",
            }
          : {}),
      };

      const { hits } = await index.search(searchTerm, algoliaQuery);
      dispatch(setRequests(hits));
    } catch (error) {
      console.error("Error fetching requests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [searchTerm, sortOrderControle, userLocation]);

  useEffect(() => {
    if (!displayedRequestUpdate || displayedRequestUpdate.length === 0) {
      fetchRequests();
    }
  }, [displayedRequestUpdate]);

  return (
    <Box
      sx={{
        margin: margin,
        background: theme.palette.primary.black2,
        height: "140rem",
      }}
    >
      <Box
        sx={{
          mb: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 800,

            textAlign: "center",
            marginTop: "8px",
            color: theme.palette.primary.light,
            fontSize: "1.7rem",
            fontFamily: theme.typography.matrixFont,
            textTransform: "uppercase",
            fontStyle: "italic",
            letterSpacing: "1px",
          }}
        >
          TRUCKPARTSMART HOTLINE
        </Typography>

        <Box sx={{ gap: "10px", display: "flex" }}>
          <Button
            sx={{
              padding: "5px 20px",
              background: theme.palette.primary.lightBlack,
              color: theme.palette.primary.orange,
              fontWeight: 700,
              fontSize: "15px",
              fontFamily: theme.typography.matrixFont,
              borderRadius: "16px",
              textTransform: "uppercase",
              "&:hover": {
                background: theme.palette.primary.lightBlack,
              },
            }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              navigate(isLoggedIn ? "/requests/add" : "/login");
            }}
          >
            <CiSquarePlus
              style={{
                marginRight: "2px",
                fontSize: "x-large",
                borderRadius: "10px",
              }}
            />{" "}
            Add Request
          </Button>

          <Button
            sx={{
              padding: "5px 20px",
              background: theme.palette.primary.lightBlack,
              color: theme.palette.primary.orange,
              fontWeight: 700,
              fontSize: "15px",
              fontFamily: theme.typography.matrixFont,
              borderRadius: "16px",
              textTransform: "uppercase",
              display: { xs: "none", md: "flex" },

              "&:hover": {
                background: theme.palette.primary.lightBlack,
              },
            }}
            onClick={handleFullScreenToggle}
          >
            <img
              src={imageCorner}
              alt="full Screen corner"
              style={{
                marginRight: "2px",
                fontSize: "x-large",
                borderRadius: "10px",
              }}
            />{" "}
            {isFullscreen ? "Full Screen" : ""}
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: height ? { md: "400px" } : "500px" }}>
        {displayedRequestUpdate?.length > 0 ? (
          displayedRequestUpdate?.map((request, index) => (
            <RequestItem
              key={request?.id}
              request={request}
              handleClick={handleClick}
              handleUpdate={handleUpdate}
              updateRqequest={"new"}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              handleChatButtonClick={handleChatButtonClick}
              expandedRequestId={expandedRequestId}
              isMediumScreen={isMediumScreen}
              anchorEl={anchorEl}
              handleClose={handleClose}
              toggleDetailsExpanded={toggleDetailsExpanded}
              isDetailsExpanded={isDetailsExpanded}
              requestsSectionRef={requestsSectionRef}
              goToSellerPage={goToSellerPage}
            />
          ))
        ) : (
          // Show "No Requests found" when there are no requests
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "60vh",
              textAlign: "center",
            }}
          >
            <img
              src={noRequestsImage}
              alt="No Requests found"
              style={{
                width: "250px",
                marginBottom: "20px",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.primary.orange,
                fontFamily: theme.typography.matrixFont,
                textTransform: "capitalize",
                mb: 2,
              }}
            >
              no requests found
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.primary.orange,
                fontFamily: theme.typography.matrixFont,
              }}
            >
              We couldn't find any requests for the selected Requests.
            </Typography>
          </Box>
        )}
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DeleteBox
          onCancel={handleCloseDeleteDialog}
          onDelete={handleDelete}
          itemToDelete="request"
        />
      </Dialog>
    </Box>
  );
};

export default RequestComponent;
