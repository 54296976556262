import {
  Box,
  Button,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PasswordInput from "./PasswordInput";
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  sendSignInLinkToEmail,
  signOut,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useDispatch } from "react-redux";
import {
  resetFormTouched,
  restUserData,
} from "../../../../redux/slices/appStateSlice";
import EmailInput from "./EmailInput";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().trim().required("Current password is required"),
  currentEmail: Yup.string()
    .trim()
    .email("Enter a valid email")
    .required("Current email is required"),
  newEmail: Yup.string()
    .trim()
    .email("Enter a valid new email")
    .required("New email is required"),
});

const EditEmailForm = ({ inputStyle, sharedStyles, currentUser }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailChange = async (values, actions) => {
 
  };

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        currentEmail: currentUser?.email || "",
        newEmail: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleEmailChange}
    >
      {({ isSubmitting, errors, touched, isValid }) => (
        <Form>
          <Stack spacing={3}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "600",
                fontSize: "24px",
                color: theme.palette.primary.lightDoveGrey,
                textTransform: "capitalize",
              }}
            >
              Change Email
            </Typography>
            <Stack spacing={3}>
              <Field
                name="currentPassword"
                label="Current Password"
                id="currentPassword"
                component={PasswordInput}
                inputStyle={inputStyle}
                sharedStyles={sharedStyles}
                error={
                  touched.currentPassword && Boolean(errors.currentPassword)
                }
              />
              <Box
                sx={{
                  ...sharedStyles,
                  width: {
                    xs: "100%",
                    xl: "90%",
                  },
                }}
              >
                <InputLabel sx={inputStyle} htmlFor="currentEmail">
                  Current Email
                </InputLabel>
                <OutlinedInput
                  id="currentEmail"
                  type="email"
                  fullWidth
                  sx={sharedStyles}
                  disabled
                  value={currentUser?.email || ""}
                />
              </Box>
              <Field
                name="newEmail"
                label="Enter New Email"
                id="newEmail"
                component={EmailInput}
                inputStyle={inputStyle}
                sharedStyles={sharedStyles}
                error={touched.newEmail && Boolean(errors.newEmail)}
              />

              <Button
                type="submit"
                variant="contained"
                disabled={!isValid || isSubmitting}
                color="primary"
                sx={{
                  width: "15.625rem",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  paddingY: "16px",
                  paddingX: "32px",
                  borderRadius: "8px",
                }}
              >
                Save Changes
              </Button>
              {errors.general && (
                <Typography variant="body2" color="error.main">
                  {errors.general}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default EditEmailForm;
