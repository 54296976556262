import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
  useTheme,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import Loader from "../../../components/common/Loader";
import { db } from "../../../configs/firebase";
import NewAddressForm from "../../../components/common/NewAddressForm";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ShippingAddressSelector = ({
  currentUser,
  onSelectAddress,
  isModalOpen,
  setIsModalOpen,
  selectValue = "id",
  selectedAddress,
  setSelectedAddress,
  selectedAddressId,
  locationAddressId,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.appState.userData);

  const fetchAddresses = async () => {
    setIsLoading(true);
    try {
      const addressesRef = collection(
        db,
        "users",
        currentUser?.uid,
        "addresses"
      );
      const querySnapshot = await getDocs(addressesRef);
      const fetchedAddresses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedAddresses = fetchedAddresses.sort(
        (a, b) => b.isDefault - a.isDefault
      );

      setAddresses(sortedAddresses);

      // const defaultAddress = sortedAddresses?.find(
      //   (address) => address?.isDefault
      // );

      // if (defaultAddress) {
      //   setSelectedAddress(
      //     selectValue === "id" ? defaultAddress?.id : null
      //   );
      //   onSelectAddress(defaultAddress);
      // }

      let preSelectedAddress = null;

      // Logic for selecting the address based on conditions
      if (location.pathname === "/requests/add" && location.state === null) {
        preSelectedAddress = sortedAddresses.find(
          (address) => address.id === userData?.address?.id
        );
      } else if (locationAddressId) {
        preSelectedAddress = sortedAddresses.find(
          (address) => address.id === locationAddressId
        );
      } else if (
        location.pathname === "/profile" &&
        location.search.startsWith("?page=info&form=")
      ) {
        preSelectedAddress =
          sortedAddresses.find(
            (address) => address.id === userData?.businessInfo?.address?.id
          ) ||
          sortedAddresses.find(
            (address) => address.id === userData?.address?.id
          );
      } else if (
        location.pathname === "/selling/add-business-info" ||
        location.pathname === "/selling/service-business-form" ||
        location.pathname === "/selling/add-service-billing&ShippingDetails" ||
        location.pathname === "/selling/billing-details"
      ) {
        preSelectedAddress = sortedAddresses.find(
          (address) => address.id === userData?.businessInfo?.address?.id
        );
      }

      // Set selected address
      if (preSelectedAddress) {
        setSelectedAddress(
          selectValue === "id" ? preSelectedAddress.id : preSelectedAddress
        );
        onSelectAddress(
          selectValue === "id" ? preSelectedAddress.id : preSelectedAddress
        );
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddressChange = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue, "selectedValue");
    if (selectValue === "id") {
      setSelectedAddress(selectedValue);
      onSelectAddress(selectedValue);
    } else {
      const selected = addresses.find(
        (address) => JSON.stringify(address) === selectedValue
      );
      setSelectedAddress(selected || null);
      onSelectAddress(selected || null);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (currentUser?.uid) {
      fetchAddresses();
    }
  }, [currentUser]);

  if (isLoading) return <Loader />;

  const noAddressesMessage = (
    <Box
      sx={{
        padding: "10px",
        textAlign: "center",

        borderRadius: "8px",
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        You don't have any saved addresses. Please add an address to continue.
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        maxHeight: isSmallScreen ? "150px" : "170px",
        overflowY: "auto",
        padding: isSmallScreen ? "0 3px" : "0 5px",
        marginTop: "10px",
        width: "98.5%",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.primary.main,
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "4px",
          backgroundColor: theme.palette.primary.slightGray,
        },
      }}
    >
      {/* Show message if no addresses are available */}
      {addresses?.length === 0 ? (
        noAddressesMessage
      ) : (
        <RadioGroup
          value={
            selectValue === "id"
              ? selectedAddress || ""
              : selectedAddress
              ? JSON.stringify(selectedAddress)
              : ""
          }
          onChange={handleAddressChange}
        >
          {addresses?.map((address, index) => (
            <Box key={address?.id}>
              <FormControlLabel
                value={
                  selectValue === "id" ? address?.id : JSON.stringify(address)
                }
                control={
                  <Radio
                    sx={{
                      width: {
                        xs: "35px",
                        sm: "40px",
                      },
                      height: {
                        xs: "35px",
                        sm: "40px",
                      },
                    }}
                  />
                }
                label={
                  <Stack gap={1}>
                    <Stack
                      direction={"row"}
                      gap={isSmallScreen ? 1 : 2}
                      alignItems={isSmallScreen ? "flex-start" : "center"}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "600",
                          color: theme.palette.primary.lightDoveGrey,
                          textTransform: "capitalize",
                          fontSize: isSmallScreen ? "0.8rem" : "1rem",
                        }}
                      >
                        name:&nbsp;
                        <Typography
                          component="span"
                          sx={{
                            fontSize: isSmallScreen ? "0.8rem" : "1rem",
                            color: theme.palette.neutral.MoustacheGrey,
                          }}
                        >
                          {address?.name}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "600",
                          color: theme.palette.primary.lightDoveGrey,
                          textTransform: "capitalize",
                          fontSize: isSmallScreen ? "0.8rem" : "1rem",
                        }}
                      >
                        zip code:&nbsp;
                        <Typography
                          component="span"
                          sx={{
                            fontSize: isSmallScreen ? "0.8rem" : "1rem",
                            color: theme.palette.neutral.MoustacheGrey,
                          }}
                        >
                          {address?.zipCode}
                        </Typography>
                      </Typography>
                      {address?.addressLineTwo && (
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: "600",
                            color: theme.palette.primary.lightDoveGrey,
                            textTransform: "capitalize",
                            fontSize: isSmallScreen ? "0.8rem" : "1rem",
                          }}
                        >
                          apt./suite:&nbsp;
                          <Typography
                            component="span"
                            sx={{
                              fontSize: isSmallScreen ? "0.8rem" : "1rem",
                              color: theme.palette.neutral.MoustacheGrey,
                            }}
                          >
                            {address?.addressLineTwo}
                          </Typography>
                        </Typography>
                      )}
                    </Stack>
                    {/* Address Line Below */}
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "600",
                        color: theme.palette.primary.lightDoveGrey,
                        textTransform: "capitalize",
                        fontSize: isSmallScreen ? "0.8rem" : "1rem",
                      }}
                    >
                      address:&nbsp;
                      <Typography
                        component="span"
                        sx={{
                          fontSize: isSmallScreen ? "0.8rem" : "1rem",
                          color: theme.palette.neutral.MoustacheGrey,
                        }}
                      >
                        {address?.addressLineOne} , {address?.city} ,{" "}
                        {address?.state} , {address?.country}
                      </Typography>
                    </Typography>
                  </Stack>
                }
              />
              {index < addresses.length - 1 && (
                <Divider
                  sx={{
                    margin: isSmallScreen ? "10px 0" : "20px 0",
                  }}
                />
              )}
            </Box>
          ))}
        </RadioGroup>
      )}

      {/* Dialog for New Address Form */}
      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Add New Address
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoMdCloseCircleOutline />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <NewAddressForm
            userId={currentUser?.uid}
            closeModal={handleModalClose}
            addresses={addresses}
            fetchAddresses={fetchAddresses}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ShippingAddressSelector;
