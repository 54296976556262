import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  useTheme,
  Box,
  Typography,
  Button,
  useMediaQuery,
  Menu,
  MenuItem,
  Avatar,
  Grid,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import { Timestamp } from "firebase/firestore";
import noRequestsImage from "../../../assets/Images/no-Items.png";

import { db } from "../../../configs/firebase.js";
import DeleteBox from "../../../components/common/DeleteBox.jsx";
import { setRequests } from "../../../redux/slices/appStateSlice.js";
import { message } from "antd";
import { useDispatch } from "react-redux";
import Loader from "../../../components/common/Loader.jsx";
import RequestItem from "../../../components/common/Request/RequestItem.jsx";

const RequestsHome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.appState.userData);
  const [displayedRequest, setdisplayedRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down(1124));
  const [anchorEl, setAnchorEl] = useState(false);
  const [activeArrow, setActiveArrow] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isDetailsExpanded, setisDetailsExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expandedRequestId, setExpandedRequestId] = useState(null);
const allRequests = useSelector(
    (state) => state.appState.requests
  );
  
  const slider = React.useRef(null);
  const requestsSectionRef = React.useRef(null);

  const toggleDetailsExpanded = (id) => {
    setisDetailsExpanded(!isDetailsExpanded);
    // setExpandedRequestId(expandedRequestId === id ? null : id);
    setExpandedRequestId((prevId) => (prevId === id ? null : id));

    if (isDetailsExpanded && requestsSectionRef.current) {
      setTimeout(() => {
        requestsSectionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start", // Ensures it scrolls to the top of the section
        });
      }, 100);
    }
  };

  useEffect(() => {
    if (
      userData &&
      !(Array.isArray(userData) && userData?.length === 0) &&
      typeof userData === "object" &&
      userData?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);
  
  

  const getRequests = async () => {
    setIsLoading(true);
    try {
      const requestsRef = db
        .collection("requests")
        .where("isDeleted", "==", false)
        .orderBy("createdAt", "desc");

      const querySnapshot = await requestsRef.get();

      let requestes = [];
      querySnapshot.forEach((doc) => {
        requestes.push(doc.data());
      });
      setdisplayedRequest(requestes);
      setRequests(requestes)
    } catch (error) {
      console.error("Error fetching requestes:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
      if (!allRequests || allRequests?.length === 0) {
        getRequests();
      }
    }, [allRequests]);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentId(null);
  };

  const handleChatButtonClick = (request) => {
    if (isLoggedIn) {
      navigate("/chat?type=users", { state: { user: request } });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/login");
    }
  };

  const handlePrev = () => {
    setActiveArrow("left");
    slider?.current?.slickPrev();
  };

  const handleNext = () => {
    setActiveArrow("right");
    slider?.current?.slickNext();
  };

  const handleUpdate = () => {
    navigate("/requests/add", { state: { requestId: currentId } });
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleClose();
  };

  const handleOpenDeleteDialog = () => {
    setOpenDialog(true);
    setAnchorEl(false);
  };

  const handleCloseDeleteDialog = () => {
    setDialogOpen(false);
    setOpenDialog(false);
    setAnchorEl(null);
    setCurrentId(null);
  };

  const handleDelete = async () => {
    if (!currentId) return;

    const selectedRequest = displayedRequest?.find(
      (request) => request.id === currentId
    );

    if (selectedRequest && selectedRequest?.uid === userData?.uid) {
      handleOpenDeleteDialog();
      const currentDate = new Date();
      const firestoreTimestamp = Timestamp.fromDate(currentDate);
      try {
        await db
          .collection("requests")
          .doc(currentId)
          .update({ isDeleted: true, deletedAt: firestoreTimestamp });
        // await db.collection("requests").doc(currentId).delete();
        const deleteRequests = displayedRequest.filter(
          (request) => request.id !== currentId
        );

        setdisplayedRequest(deleteRequests);
        dispatch(setRequests(deleteRequests));

        message.success({
          content: "Your request has been successfully deleted.",
          duration: 5,
        });
        handleCloseDeleteDialog();
        handleClose();
      } catch (error) {
        console.error("Error deleting document: ", error);
        message.error(
          "There was an error deleting your request. Please try again."
        );
      }
    } else {
      message.error("You don't have permission to delete this request.");
    }
  };

  const goToSellerPage = (request) => {
    if (isLoggedIn) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(`/sellers/seller-details/${request}`);
    } else {
      navigate("/login");
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: false,
    beforeChange: (current, next) => {
      // Reset expandedRequestId when sliding to another item
      setExpandedRequestId(null); // Close "Show More" on slide change
    },
  };

  return (
    <section ref={requestsSectionRef} style={{ margin: " 0 20px 20px 20px" }}>
      <section style={{ position: "relative" }}>
        <Box
          sx={{
            padding: "0px 60px 30px",
            borderRadius: "32px",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 800,
                lineHeight: { sm: "3.5rem" },
                textAlign: "center",
                marginTop: "8px",
                color: theme.palette.primary.black2,

                fontFamily: theme.typography.Columbia,
                textTransform: "uppercase",
                fontStyle: "italic",
                letterSpacing: "1px",
              }}
            >
              TRUCKPARTSMART HOTLINE
            </Typography>
          </Box>
        </Box>

        <>
          {/* Show Loader while fetching data */}
          {isLoading && (
            <Box sx={{ textAlign: "center", padding: 4 }}>
              <Loader />
            </Box>
          )}

          {/* Show "No Requests Found" message if no data is available */}
          {!isLoading && allRequests?.length === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
                textAlign: "center",
              }}
            >
              <img
                src={noRequestsImage}
                alt="No Requests found"
                style={{
                  width: "250px",
                  marginBottom: "20px",
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.primary.orange,
                  fontFamily: theme.typography.matrixFont,
                  textTransform: "capitalize",
                  mb: 2,
                }}
              >
                No requests found
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.text.secondary,
                  fontFamily: theme.typography.fontFamily,
                }}
              >
                We couldn't find any requests for the selected category.
              </Typography>
            </Box>
          )}
        </>
        {/* Show Requests if data is available */}
        {!isLoading && allRequests?.length > 0 && (
          <Grid container>
            <Grid item xs={11} lg={10} sx={{ margin: "auto", px: "20px" }}>
              <Box
                sx={{
                  borderRadius: { xs: "4px", lg: "32px !important" },
                  overflowY: {
                    xs: "inherit !important",
                    lg: "auto !important",
                  },
                  backgroundColor: theme.palette.primary.black2,
                  margin: "auto",
                  padding: "0 20px",
                  boxShadow: "0px 2px 10px rgba(255, 255, 255, 0.2)",
                }}
              >
                <>
                  {isMediumScreen ? (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: theme.palette.primary.black2,

                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "30px",
                          height: "43px",
                          borderRadius: "70px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <button
                          style={{
                            background: theme.palette.primary.orange,
                            width: "47px",
                            height: "27px",
                            borderRadius: "40px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={handlePrev}
                        >
                          <HiOutlineArrowNarrowLeft
                            style={{
                              fontSize: "x-large",
                              height: "25px",

                              color: theme.palette.primary.light,
                            }}
                          />
                        </button>
                        <button
                          style={{
                            background: theme.palette.primary.orange,
                            width: "47px",
                            height: "27px",
                            borderRadius: "40px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={handleNext}
                        >
                          <HiOutlineArrowNarrowRight
                            style={{
                              fontSize: "x-large",
                              height: "25px",

                              color: theme.palette.primary.light,
                            }}
                          />
                        </button>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "115px",
                        height: "43px",
                        borderRadius: "70px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={{
                          background: theme.palette.primary.orange,

                          borderRadius: "40px",
                          border: "none",
                          cursor: "pointer",
                          position: "absolute",
                          left: "20px",
                          transform: "translate(0%, 230%)",
                        }}
                        onClick={handlePrev}
                      >
                        <IoIosArrowBack
                          style={{
                            fontSize: "55px",

                            color: theme.palette.primary.light,
                            "&:hover": {
                              color: theme.palette.primary.orange, // Change arrow color on hover
                            },
                          }}
                        />
                      </Button>

                      <Button
                        sx={{
                          background: theme.palette.primary.orange,
                          borderRadius: "40px",
                          border: "none",
                          cursor: "pointer",
                          position: "absolute",
                          right: "20px",
                          top: "50%",
                          transform: "translate(0%, 9%)",
                          fontSize: "2rem",
                        }}
                        onClick={handleNext}
                      >
                        <IoIosArrowForward
                          style={{
                            fontSize: "3rem",

                            color: theme.palette.primary.light,
                          }}
                        />
                      </Button>
                    </Box>
                  )}
                </>

                <Box>
                  <Slider {...settings} ref={slider}>
                    {allRequests?.map((request, index) => {
                      return (
                        <RequestItem
                          key={request?.id}
                          request={request}
                          handleClick={handleClick}
                          handleUpdate={handleUpdate}
                          handleOpenDeleteDialog={handleOpenDeleteDialog}
                          handleChatButtonClick={handleChatButtonClick}
                          isMediumScreen={isMediumScreen}
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                          toggleDetailsExpanded={toggleDetailsExpanded}
                          expandedRequestId={expandedRequestId}
                          isDetailsExpanded={isDetailsExpanded}
                          goToSellerPage={goToSellerPage}
                        />
                      );
                    })}
                  </Slider>
                </Box>
              </Box>
            </Grid>
            <Dialog
              open={openDialog}
              onClose={handleCloseDeleteDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DeleteBox
                onCancel={handleCloseDeleteDialog}
                onDelete={handleDelete}
                itemToDelete="request"
              />
            </Dialog>
          </Grid>
        )}
      </section>
    </section>
  );
};

export default RequestsHome;
