import React, { useState, useEffect, useCallback } from "react";

import { Link, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Popper,
  Radio,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Papa from "papaparse";
import { message } from "antd";
import CircleProgress from "../../../components/common/CircularProgress";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { useSelector } from "react-redux";
import { db } from "../../../configs/firebase";
import { setCsvProducts } from "../../../redux/slices/appStateSlice";
import { useDispatch } from "react-redux";

const UploadCSVPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialSetup = sessionStorage.getItem("initialSetupCSV");
  const displayedCategories = useSelector(
    (state) => state.appState.itemsCategories
  );

  const [isFile, setIsFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [categoriesNames, setCategoriesNames] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [invalidCategory, setInvalidCategory] = useState([]);
  const [invalidConditionArr, setInvalidConditionArr] = useState([]);
  const [lowerCaseCategory, setLowerCaseCategory] = useState([]);
  const [categoryCondition, setCategoryCondition] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});

  const getCategories = useCallback(async () => {
    try {
      const docs = db.collection("itemCategories").orderBy("index", "asc");

      let fetchedCategories = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedCategories?.push(doc.data());
        });

        if (fetchedCategories?.length > 0) {
          const categoryNames = fetchedCategories.map(
            (category) => category.name
          );
          setCategoriesNames(categoryNames); // Set category names
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, []);

  useEffect(() => {
    if (displayedCategories?.length === 0) {
      getCategories();
    } else {
      setCategoriesNames(
        displayedCategories?.map((category) => category?.name)
      ); // Extract names from displayedCategories
    }
  }, [displayedCategories, getCategories]);

  const handleRemoveFile = () => {
    setIsFile(null);
    setFileName("");
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
    
      if (selectedFile.type !== "text/csv" && !selectedFile.name.endsWith(".csv")) {
        message.error("Please select a valid CSV file.");
        return;
      }
      
      setIsFile(selectedFile);
      setFileName(selectedFile.name);
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const setOfCategories = new Set(
            results?.data?.map((obj) => obj?.category)
          );
          const invalidCategoryArr = [];
          const invalidConditionArr = [];

          if (setOfCategories?.size > 0) {
            const lowerCategories = categoriesNames?.map((cat) =>
              cat?.toLowerCase()
            );
            setLowerCaseCategory(lowerCategories);

            setOfCategories?.forEach((item) => {
              if (!lowerCategories?.includes(item?.toLowerCase())) {
                invalidCategoryArr?.push(item);
              }
            });
            setInvalidCategory(invalidCategoryArr);
          }

          results.data.forEach((item) => {
            if (item?.category && item?.condition) {
              const selectedCategory = categoriesNames?.find(
                (cat) => cat.toLowerCase() === item?.category.toLowerCase()
              );

              if (selectedCategory) {
                const validConditions = selectedCategory?.conditions || [];
                if (!validConditions.includes(item?.condition)) {
                  invalidConditionArr.push(item);
                }
              }
            }
            setInvalidConditionArr(invalidConditionArr);
          });

          if (invalidConditionArr.length > 0) {
            message.warning("Some conditions in the CSV file are invalid.");
          }

          setParsedData(results?.data);
          dispatch(setCsvProducts(results?.data));
        },
      });
    } else {
      message.error("Please select a file");
    }
  };

  const handleChange = (event, value) => {
    const categoryValue =
      value?.name && typeof value?.name === "string"
        ? value?.name.toLowerCase()
        : "";

    const selectedCategory = displayedCategories?.find(
      (category) => category?.name.toLowerCase() === categoryValue
    );

    if (selectedCategory) {
      const categoryConditions = selectedCategory?.conditions || [];
      setCategoryCondition(categoryConditions);
      setInvalidConditionArr([selectedCategory?.name]);

      if (!categoryConditions.includes(parsedData?.condition)) {
        message.error(
          `The selected condition does not match the selected category. Please choose a valid condition.`
        );

        setSelectedCondition({});
      } else {
        setCategoryCondition(categoryConditions);
      }
    }

    // Create a new array with updated categories
    const updatedParsedData = parsedData?.map((d) => {
      return { ...d, category: value?.name };
    });
    // Update the state with the new array of objects
    setParsedData(updatedParsedData);
    setInvalidConditionArr(updatedParsedData);
    dispatch(setCsvProducts(updatedParsedData));
  };

  const handleConditionChange = (event, value, item) => {
    const updatedData = parsedData?.map((data) => {
      if (data.ext_id === item.ext_id) {
        return { ...data, condition: value };
      }
      return data;
    });

    setParsedData(updatedData);
    setInvalidConditionArr(updatedData);
    dispatch(setCsvProducts(updatedData));
  };

  // Submit
  const handleViewProducts = () => {
    if (
      parsedData?.some(
        (pro) => pro?.title === "" || !/^(?!\s+$).*/.test(pro?.title)
      )
    ) {
      message.error("You must provide a title for all your items");
      return;
    } else if (parsedData?.some((pro) => pro?.title?.length < 3)) {
      message.error("Title must be at least 3 characters");
      return;
    } else if (parsedData?.some((pro) => pro?.title?.length > 20)) {
      message.error("Title must be less than 20 characters");
      return;
    } else if (
      parsedData?.some(
        (pro) => pro?.photos === "" || !/^(?!\s+$).*/.test(pro?.photos)
      )
    ) {
      message.error("You must provide a photo for all your items");
      return;
    } else if (
      parsedData?.some(
        (pro) => pro?.category === "" || !/^(?!\s+$).*/.test(pro?.category)
      )
    ) {
      message.error("You must provide a category for all your items");
      return;
    } else if (
      parsedData?.some(
        (pro) =>
          pro?.description === "" || !/^(?!\s+$).*/.test(pro?.description)
      )
    ) {
      message.error("You must provide a description for all your items");
      return;
    } else if (parsedData?.some((pro) => pro?.description?.length < 20)) {
      message.error(
        "You must provide a description for your item at least 20 characters"
      );
      return;
    } else if (
      parsedData?.some((pro) => !/^[0-9]\d*\.?[0-9]*$/.test(pro?.price))
    ) {
      message.error("One of your items has incorrect price format");
      return;
    } else if (
      parsedData?.some(
        (pro) => pro?.price === "" || !/^(?!\s+$).*/.test(pro?.price)
      )
    ) {
      message.error("You must provide a price for all your items");
      return;
    } else if (parsedData?.some((pro) => pro?.quantity < 1)) {
      message.error("Quantity should be more than 0");
      return;
    } else if (
      parsedData?.some(
        (pro) => pro?.quantity === "" || !/^(?!\s+$).*/.test(pro?.quantity)
      )
    ) {
      message.error("You must provide a quantity for all your items");
      return;
    } else if (
      parsedData?.some(
        (pro) => !lowerCaseCategory?.includes(pro?.category?.toLowerCase())
      )
    ) {
      message.error("You must provide one of our categories");
      return;
    } else if (
      parsedData?.some(
        (pro) =>
          pro?.online_payment?.toLowerCase() !== "no" &&
          pro?.online_payment?.toLowerCase() !== "yes"
      )
    ) {
      message.error("You must determine yes or no for online payment");
      return;
    } else if (
      parsedData?.some(
        (pro) =>
          pro?.local_pickup?.toLowerCase() !== "no" &&
          pro?.local_pickup?.toLowerCase() !== "yes"
      )
    ) {
      message.error("You must determine yes or no for local pickup");
      return;
    } else if (
      parsedData?.some(
        (pro) =>
          pro?.local_pickup?.toLowerCase() === "no" &&
          (pro?.shipping_company_name === "" ||
            !/^(?!\s+$).*/.test(pro?.shipping_company_name) ||
            !/^[0-9]\d*\.?[0-9]*$/.test(pro?.shipping_company_price) ||
            isNaN(pro?.shipping_company_price))
      )
    ) {
      message.error("You must provide a shipping method with valid price");
      return;
    } else if (
      parsedData?.some(
        (pro) =>
          pro?.local_pickup?.toLowerCase() === "yes" &&
          (pro?.shipping_company_name === "" ||
            !/^(?!\s+$).*/.test(pro?.shipping_company_name) ||
            !/^[0-9]\d*\.?[0-9]*$/.test(pro?.shipping_company_price) ||
            isNaN(pro?.shipping_company_price))
      )
    ) {
      message.error("You must provide a shipping method with valid price");
      return;
    } else if (parsedData?.some((pro) => pro?.ext_id === "")) {
      message.error("You must provide an id for your item");
      return;
    } else if (
      parsedData?.some(
        (pro) => pro?.condition === "" || !/^(?!\s+$).*/.test(pro?.condition)
      )
    ) {
      message.error("You must provide a condition for all your items");
      return;
    } else if (parsedData?.some((pro) => pro?.condition !== "")) {
      let conditionError = false;

      parsedData.forEach((item) => {
        const categoryValue = item?.category?.toLowerCase();
        const selectedCategory = displayedCategories?.find(
          (category) => category?.name.toLowerCase() === categoryValue
        );

        if (selectedCategory) {
          const categoryConditions = selectedCategory?.conditions || [];
          // If the condition of the item doesn't match the valid category conditions
          if (!categoryConditions.includes(item?.condition)) {
            message.error(
              `The selected condition "${item?.condition}" does not match the selected category "${selectedCategory?.name}". Please choose a valid condition.`
            );
            conditionError = true;
          }
        }
      });

      if (conditionError) {
        console.warn("Invalid condition detected, stopping execution.");
        return;
      }
    }

    setParsedData((prevData) =>
      prevData.map((pro) => {
        categoriesNames?.forEach((cat) => {
          if (cat?.toLowerCase() === pro?.category?.toLowerCase()) {
            return { ...pro, category: cat };
          }
        });
        return pro;
      })
    );

    sessionStorage.setItem("initialSetupCSV", "true");
    navigate("/selling/view-products");
  };

  return (
    <Box>
      <Box
        sx={{
          padding: "20px",
          margin: "0px 25px",
          background: theme.palette.primary.light,
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h2"
            sx={{
              color: theme.palette.dark.colorCart,
              fontSize: "24px",
              fontFamily: theme.typography.headerFont,
            }}
          >
            Import CSV file
          </Typography>
          <CircleProgress step={initialSetup === "true" ? 1 : 2} />
        </Stack>

        <Box sx={{ mb: 4 }}>
          <Typography
            sx={{
              color: theme.palette.primary.darkMain,
              mt: 4,
              mb: 2,
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            Add product / Upload CSV
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: theme.palette.primary.darkMain,
              fontSize: "14px",
            }}
          >
            Simplify the process of adding products by effortlessly uploading
            your CSV file for seamless integration.
          </Typography>
          <Typography
            style={{ color: theme.palette.primary.darkMain, fontSize: "14px" }}
          >
            <span
              style={{ fontWeight: 700, color: theme.palette.primary.darkMain }}
            >
              Need Help?{" "}
            </span>
            <Link
              to="/files/template.csv"
              target="_blank"
              download
              style={{
                color: theme.palette.primary.main,
                fontWeight: "700",
                textDecoration: "none",
              }}
            >
              Download our CSV template
            </Link>{" "}
            to make sure your CSV is formatted correctly.
          </Typography>
        </Box>

        <Box
          sx={{
            border: `1px dashed ${theme.palette.primary.main}`,
            borderRadius: "10px",
            background: theme.palette.primary.background,
            padding: "20px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height:
              invalidCategory?.length > 0 && invalidConditionArr?.length > 0
                ? "auto"
                : "270px", // Conditional height
          }}
        >
          {isFile ? (
            <>
              <Typography style={{ color: theme.palette.primary.darkMain }}>
                {fileName}
                <span
                  onClick={handleRemoveFile}
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  Remove
                </span>
              </Typography>

              <Typography
                style={{
                  color: theme.palette.primary.darkMain,
                  margin: "2rem 0",
                }}
              >
                {invalidCategory?.length > 0
                  ? `The categories below don't have matching categories in Truck Parts. Please select the corresponding Truck Parts categories before proceeding with the upload.`
                  : ""}
              </Typography>
              {/* Table */}
              <Box sx={{ width: "400px" }}>
                {invalidCategory?.length > 0 && (
                  <>
                    <Box>
                      <Typography sx={{ mb: 3, fontWeight: 500, mt: 4 }}>
                        TruckParts Category
                      </Typography>
                      <Box>
                        {invalidCategory?.map((cat, index) => (
                          <Box key={index}>
                            <Box style={{ width: "100%" }}>
                              <Autocomplete
                                id={`category-autocomplete-${index}`}
                                value={
                                  displayedCategories?.find(
                                    (category) =>
                                      category.name === selectedCategory[index]
                                  ) || null
                                }
                                options={displayedCategories}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value?.id
                                }
                                PopperComponent={(props) => (
                                  <Popper
                                    {...props}
                                    placement="bottom-start" // Ensure the dropdown opens at the bottom
                                  />
                                )}
                                PaperComponent={({ children }) => (
                                  <Paper
                                    style={{
                                      background: theme.palette.primary.light,
                                      maxHeight: "400px",
                                      overflowY: "hidden",
                                      borderRadius: "10px",
                                    }}
                                    sx={{
                                      "& .MuiAutocomplete-listbox": {
                                        scrollbarWidth: "none",
                                        "&::-webkit-scrollbar": {
                                          display: "none",
                                        },
                                      },
                                    }}
                                  >
                                    {children}
                                  </Paper>
                                )}
                                onChange={(event, value) => {
                                  setSelectedCategory((prev) => ({
                                    ...prev,
                                    [index]: value?.name || "",
                                  }));

                                  handleChange(event, value);
                                }}
                                renderOption={(props, option, { selected }) => (
                                  <li
                                    key={option.id}
                                    {...props}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      background:
                                        theme.palette.primary.background,
                                      padding: "8px 16px",
                                      borderRadius: "8px",
                                      margin: "5px 10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={option.image}
                                        alt={option.name}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          marginRight: "8px",
                                        }}
                                      />
                                      <Typography
                                        sx={{
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          fontWeight: 500,
                                          color: theme.palette.primary.black,
                                        }}
                                      >
                                        {option?.name}
                                      </Typography>
                                    </div>
                                    <Radio
                                      checked={selected}
                                      value={option?.id}
                                      style={{
                                        color: theme.palette.primary.main,
                                      }}
                                    />
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select Category"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        height: "47px",
                                        borderRadius: "10px",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>

              <Box sx={{ width: "400px" }}>
                {invalidConditionArr?.length > 0 && (
                  <>
                    <Box>
                      <Typography sx={{ mb: 3, fontWeight: 500, mt: 4 }}>
                        TruckParts Condition
                      </Typography>
                      <Box>
                        {invalidConditionArr?.map((item, index) => {
                          // Find the category for the current item
                          const selectedCategory = displayedCategories?.find(
                            (category) => category?.name === item?.category
                          );
                          const categoryConditions =
                            selectedCategory?.conditions || [];

                          return (
                            <Box key={index}>
                              <Box style={{ width: "100%" }}>
                                <Autocomplete
                                  id={`condition-autocomplete-${index}`}
                                  options={categoryConditions}
                                  getOptionLabel={(option) => option || ""}
                                  noOptionsText={
                                    categoryConditions?.length === 0
                                      ? "No conditions available"
                                      : "Choose condition"
                                  }
                                  value={item?.condition || ""}
                                  onChange={(event, value) => {
                                    handleConditionChange(event, value, item);
                                  }}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li
                                      {...props}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        background:
                                          theme.palette.primary.background,
                                        padding: "8px 16px",
                                        borderRadius: "8px",
                                        margin: "5px 10px",
                                      }}
                                    >
                                      <div>{option}</div>
                                      <Radio
                                        checked={selected}
                                        value={option}
                                        style={{
                                          color: theme.palette.primary.main,
                                        }}
                                      />
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Condition"
                                      variant="outlined"
                                      fullWidth
                                      InputProps={{
                                        ...params.InputProps,
                                        style: {
                                          height: "47px",
                                          border: `1px solid ${theme.palette.gray.borderFaild}`,

                                          color: `${theme.palette.primary.darkColor} !important`,
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          fontWeight: 500,
                                          lineHeight: "21px",
                                          borderRadius: "10px",
                                        },
                                      }}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            border: "none",
                                          },
                                          "&:hover fieldset": {
                                            border: "none",
                                          },
                                          "&.Mui-focused fieldset": {
                                            border: "none",
                                          },
                                        },
                                        "& .MuiInput-underline:before": {
                                          borderBottom: "none",
                                        },
                                        "& .MuiInput-underline:after": {
                                          borderBottom: "none",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          ) : (
            <Box>
              <input
                type="file"
                name="file"
                inputProps={{ accept: ".csv" }}
                style={{ display: "none" }}
                onChange={handleFileChange}
                id="csv-upload"
              />
              <MdOutlineAddAPhoto
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "45px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  document.getElementById("csv-upload").click();
                }}
              />
            </Box>
          )}
        </Box>

        <Box>
          <Button
            sx={{ mt: 4, textTransform: "capitalize" }}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleViewProducts}
            disabled={parsedData?.length < 1 ? true : false}
          >
            Next: View draft products
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadCSVPage;
