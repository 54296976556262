// import algoliasearch from 'algoliasearch';
import algoliasearch from "algoliasearch/lite";
import { Algolia_Search_Api_Key, Algolia_App_Id } from '../components/layout/constants';

const client = algoliasearch(Algolia_App_Id, Algolia_Search_Api_Key);

// Initialize indexes
export const productsIndex = client.initIndex('products');
export const productsAscIndex = client.initIndex('products price asc');
export const productsDescIndex = client.initIndex('products price desc');
export const serviceIndex = client.initIndex('services');
export const serviceAscIndex = client.initIndex('services price asc');
export const serviceDescIndex = client.initIndex('services price desc');
export const requestsIndex = client.initIndex('requests');
export const requestsDesIndex = client.initIndex('requests nearest');
export const productsNearestIndex = client.initIndex('products nearest');
export const servicesNearestIndex = client.initIndex('services nearest');