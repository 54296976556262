import React, { useEffect, useState } from "react";
import BusinessForm from "../components/BusinessForm";
import { db } from "../../../configs/firebase";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  clearFormAddServiceData,
  setGoBackAddService,
} from "../../../redux/slices/appStateSlice";
import {
  collection,
  doc,
  getDocs,
  serverTimestamp,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

import { useDispatch } from "react-redux";
// const initialValues = {
//   businessName: "",
//   email: "",
//   phone: "",
//   addressLineOne: "",
//   addressLineTwo: "",
//   description: "",
//   city: "",
//   state: "",
//   zipCode: "",
// };

const ServiceBusinessInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [placeId, setPlaceId] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [country, setCountry] = useState(null);
  const [addresses, setAddresses] = useState([]);

  const userData = useSelector((state) => state.appState.userData);

  const initialValues = {
    businessName: "",
    email: userData?.email || userData?.businessInfo?.email || "",
    phone: userData?.phoneNumber || userData?.businessInfo?.phoneNumber || "",
    addressLineOne:
      userData?.businessInfo?.address?.addressLineOne ||
      addresses?.addressLineOne ||
      "",
    addressLineTwo:
      userData?.businessInfo?.address?.addressLineTwo ||
      addresses?.addressLineTwo ||
      "",
    description: "",
    city: userData?.businessInfo?.address?.city || addresses?.city || "",
    state: userData?.businessInfo?.address?.state || addresses?.state || "",
    zipCode:
      userData?.businessInfo?.address?.zipCode || addresses?.zipCode || "",
    websiteLink: userData?.websiteLink || "",
    businessContent: userData?.businessContent || "",
    businessImage: userData?.businessImage || "",
  };

  const fetchAddresses = async () => {
    try {
      const addressesRef = collection(db, "users", userData?.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);
      const fetchedAddresses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedAddresses = fetchedAddresses.sort(
        (a, b) => b.isDefault - a.isDefault
      );

      const defaultAddress = sortedAddresses?.find(
        (address) => address?.isDefault
      );
      setAddresses(defaultAddress);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    if (userData?.uid) {
      fetchAddresses();
    }
  }, [userData]);

  const updateUserDefaultAddress = async (defaultAddress) => {
    const { customName, updatedAt, createdAt, ...address } = defaultAddress;
    try {
      await updateDoc(doc(db, "users", userData?.uid), {
        address: { ...address },
      });
      console.log("Default address updated in user profile:", address);
    } catch (error) {
      console.error("Error updating default address:", error);
    }
  };

  const handleSubmit = async (values, formattedDaysToFierbase) => {
    console.log(values, "Submitted values");

    try {
      if (!userData?.uid) {
        return message.error("User ID is not available");
      }

      if (!values.city || !values.state || !values.zipCode) {
        return message.error("Please select a valid address");
      }

      // Handle Addresses Collection
      const addressesRef = collection(db, "users", userData.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);

      let existingAddress = null;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (
          data?.placeId === placeId ||
          (data?.lat === lat && data?.lng === lng) ||
          data?.addressLineOne === values?.addressLineOne
        ) {
          existingAddress = { id: doc.id, ...data };
        }
      });

      const batch = writeBatch(db);
      let newSelectedAddressId = null;

      if (existingAddress) {
        // Update Existing Address

        const addressRef = doc(addressesRef, existingAddress.id);
        batch.update(addressRef, {
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          updatedAt: Timestamp.now(),
        });

        newSelectedAddressId = existingAddress?.id;
        // Update default address only if it's currently set as default
        if (existingAddress.isDefault) {
          await updateUserDefaultAddress({
            ...existingAddress,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
          });
        }
      } else {
        // Create New Address
        const newAddressRef = doc(addressesRef);
        const newAddressData = {
          id: newAddressRef.id,
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          placeId,
          lat,
          lng,
          country,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          isDefault: querySnapshot.empty, // First address is default
          name: "Business",
        };

        batch.set(newAddressRef, newAddressData);
        newSelectedAddressId = newAddressRef?.id;

        if (querySnapshot.empty) {
          await updateUserDefaultAddress(newAddressData);
        }
      }

      // Commit all batch updates
      await batch.commit();

      const businessInfo = {
        address: {
          id: newSelectedAddressId,
          addressLineOne: values?.addressLineOne,
          addressLineTwo: values?.addressLineTwo,
          city: values?.city,
          state: values?.state,
          zipCode: values?.zipCode,
          // name: "Business",
          name: values?.businessName,

          isDefault: false,
          placeId,
          lat,
          lng,
          country,
        },
        email: values?.email || null,
        phoneNumber: values?.phone || null,
        description: values?.description || null,
        name: values?.businessName || null,
        websiteLink: values?.websiteLink,
        businessContent: values?.businessContent,
        businessImage: values?.businessImage,
        openDays: formattedDaysToFierbase,
      };

      await db.collection("users").doc(userData?.uid).update({
        businessInfo,
        userType: "seller",
        updatedAt: serverTimestamp(),
      });
      message.success("Business info updated successfully!");
      localStorage.setItem("selectedAddressId", newSelectedAddressId);

      // Navigate to profile
      // Navigate to profile
      // navigate("/selling/add-service");
    } catch (error) {
      message.error(`Error saving address: ${error.message}`);
      console.error("Error saving address:", error);
    }
  };

  return (
    <BusinessForm
      key={JSON.stringify(addresses)}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      setCountry={setCountry}
      setPlaceId={setPlaceId}
      setLat={setLat}
      setLng={setLng}
    />
  );
};

export default ServiceBusinessInfo;
