
import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  useTheme,
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {  FaBoxOpen, FaTruck, FaHome } from "react-icons/fa";

// Custom StepConnector for the mobile stepper
const MobileStepConnector = styled(StepConnector)(({ theme, completed }) => ({
  "& .MuiStepConnector-line": {
    minHeight: 50,
    borderWidth: 2,
    borderColor: completed
      ? theme.palette.status.success
      : theme.palette.neutral.MoustacheGrey,
  },
}));

// Custom icon component for the step icons
const MobileStepIcon = ({ icon: Icon, completed }) => {
  const theme = useTheme();
  return (
    <Box
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {completed ? (
        <Icon color={theme.palette.status.success} />
      ) : (
        <Icon color={theme.palette.neutral.MoustacheGrey} />
      )}
    </Box>
  );
};

const MobileStepper = ({ currentStatus,userData,sellerId }) => {
  const theme = useTheme();

  
  const statusSteps = [
  
    { label: "pending", icon: FaBoxOpen },
    { label: "shipped", icon: FaTruck },
    { label: "completed", icon: FaHome },
  ];


  const getStepStatus = (stepLabel) => {
    const currentIndex = statusSteps?.findIndex(step => step?.label === currentStatus);
    const stepIndex = statusSteps?.findIndex(step => step?.label === stepLabel);
    return stepIndex <= currentIndex;
  };

  return (
    <Stepper
      orientation="vertical"
      activeStep={-1}
      connector={null} 
      sx={{
        display: {
          xs: "flex",
          sm: "none", 
        },
        alignItems: "center",
        paddingY: "10px",
        width: "100%",
      }}
    >
      {statusSteps.map((step, index) => {
        const completed = getStepStatus(step?.label);
        return (
          <React.Fragment key={index}>
            <Step completed={completed} >
              <StepLabel
                StepIconComponent={() => (
                  <MobileStepIcon icon={step?.icon} completed={completed} />
                )}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "500",
                    color: completed
                      ? theme.palette.status.success
                      : theme.palette.neutral.MoustacheGrey,
                    textTransform: "capitalize"
                  }}
                >
                  {step?.label === "pending" ? "Preparing" : step?.label}
                </Typography>
              </StepLabel>
            </Step>
            {/* Render the connector only between the steps */}
            {index < statusSteps?.length - 1 && (
              <MobileStepConnector completed={getStepStatus(statusSteps[index + 1]?.label)} />
            )}
          </React.Fragment>
        );
      })}
    </Stepper>
  );
};

export default MobileStepper;
