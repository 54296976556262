import {
  Button,
  CircularProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../configs/firebase";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCurrentProduct } from "../../../redux/slices/appStateSlice";
import StatusDisplay from "../../../components/common/StatusDisplay";
import { message, Modal } from "antd";
import { cancelOrder } from "./cancelOrder";

const TableRowItem = ({
  bodyTextStyles,
  order,
  orders,
  currentUser,
  setOrder,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [sellerData, setSellerData] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const isBuyer = currentUser?.uid === orders?.buyer_id;
  const isSeller = currentUser?.uid === orders?.seller_id;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [nextStatus, setNextStatus] = useState(null);
  const [isCancelingLoading, setIsCancelingLoading] = useState(false);

  const getCategoryName = (categoryId) => {
    const category = itemsCategories?.find((cat) => cat?.id === categoryId);
    return category ? category?.name : "Unknown Category";
  };

  const goToProductPage = () => {
    if (categoryName) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch(setCurrentProduct(productDetails));
      navigate(`/product/${order?.id}`, {
        state: { nameToDisplay: categoryName },
      });
    }
  };

  useEffect(() => {
    const fetchSellerData = async () => {
      if (order?.seller_id) {
        try {
          const sellerDocRef = doc(db, "users", order.seller_id);
          const sellerDoc = await getDoc(sellerDocRef);
          if (sellerDoc.exists()) {
            setSellerData(sellerDoc.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching seller data: ", error);
        }
      }
    };

    fetchSellerData();
  }, [order]);

  useEffect(() => {
    if (productDetails) {
      const name = getCategoryName(productDetails.categoryId);
      setCategoryName(name);
    }
  }, [productDetails]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productDocRef = doc(db, "products", order?.id);
        const productDoc = await getDoc(productDocRef);
        if (productDoc.exists()) {
          setProductDetails(productDoc.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product details: ", error);
      }
    };

    fetchProductDetails();
  }, [order?.id]);

  const handleCancelItem = async () => {
    try {
      setIsCancelingLoading(true);

      const response = await cancelOrder(orders?.id, order?.id);

      if (response?.isSuccess) {
        // Update the local state only if the cancellation is successful
        const updatedOrderItems = orders?.order_items?.map((item) =>
          item.id === order?.id ? { ...item, status: "cancelled" } : item
        );

        // Update the state to reflect the cancellation locally
        setOrder((prevOrders) => ({
          ...prevOrders,
          order_items: updatedOrderItems,
        }));
        message.success("Item canceled successfully.");
      } else {
        message.error("Failed to cancel the item. Please try again.");
      }
    } catch (error) {
      console.error("Error canceling item:", error.message);
      message.error(error.message || "An unexpected error occurred.");
    } finally {
      setIsCancelingLoading(false);
    }
  };

  const updateItemStatus = async (newStatus) => {
    try {
      const updatedOrderItems = orders?.order_items?.map((item) =>
        item.id === order?.id ? { ...item, status: newStatus } : item
      );
      // const newOrderStatus = computeOrderStatus(updatedOrderItems);
      await updateDoc(doc(db, "orders", orders?.id), {
        order_items: updatedOrderItems,
      });

      setOrder((prevOrders) => ({
        ...prevOrders,
        order_items: updatedOrderItems,
        // status: newOrderStatus,
      }));

      message.success(`Item status updated to ${newStatus}.`);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  // Determine the next status for the seller
  const getNextStatus = () => {
    switch (order?.status) {
      case "preparing":
        return "shipped";
      case "shipped":
        return "delivered";
      default:
        return null;
    }
  };

  const renderActionButton = () => {
    if (
      isBuyer &&
      (order?.status === "cancelled" ||
        order?.status === "delivered" ||
        order?.status === "shipped")
    ) {
      return (
        <Button disabled sx={{ textTransform: "capitalize" }}>
          {order?.status === "cancelled" && "Cancelled"}
          {order?.status !== "cancelled" && "no action available"}
        </Button>
      );
    }

    if (
      isBuyer &&
      order?.status !== "shipped" &&
      order?.status !== "delivered"
    ) {
      return (
        <Button
          variant="outlined"
          onClick={() => showModal("cancel")}
          disabled={isCancelingLoading}
          startIcon={isCancelingLoading ? <CircularProgress size={20} /> : null}
          sx={{
            color: theme.palette.status.cancel,
            textTransform: "capitalize",
            borderColor: theme.palette.status.cancel,
            "&:hover": {
              color: theme.palette.status.cancel,
              borderColor: theme.palette.status.cancel,
              backgroundColor: theme.palette.status.lightRed,
            },
          }}
        >
          {isCancelingLoading ? "Cancelling..." : "Cancel Item"}
        </Button>
      );
    }

    if (isSeller) {
      if (order?.status !== "cancelled") {
        const nextStatus = getNextStatus();
        return nextStatus ? (
          <Button
            onClick={() => showModal("status")}
            variant="outlined"
            sx={{
              color: theme.palette.status.darkGreen,
              textTransform: "capitalize",
              borderColor: theme.palette.status.darkGreen,
              "&:hover": {
                color: theme.palette.status.darkGreen,
                borderColor: theme.palette.status.darkGreen,
                backgroundColor: theme.palette.status.lightGreen,
              },
            }}
          >
            mark as {nextStatus}
          </Button>
        ) : (
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.primary.brownGrey,
              textTransform: "capitalize",
            }}
          >
            no action available
          </Typography>
        );
      } else {
        return (
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.status.cancel,
              textTransform: "capitalize",
            }}
          >
            Cancelled
          </Typography>
        );
      }
    }

    return null;
  };
  const showModal = (action) => {
    if (action === "cancel") {
      setModalAction("cancel");
    } else {
      setModalAction("status");
      setNextStatus(getNextStatus());
    }
    setModalVisible(true);
  };

  return (
    <>
      <TableRow
        key={order?.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            alignItems: "center",
          }}
        >
          <img
            src={order?.image}
            alt={order?.title}
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "8px",
              marginRight: "10px",
              objectFit: "cover",
              cursor: "pointer",
            }}
            onClick={goToProductPage}
          />
          <Stack gap={0.5}>
            <Typography
              variant="body1"
              sx={{
                ...bodyTextStyles,
                cursor: "pointer",
                marginTop: {
                  xs: "10px",
                  sm: "0",
                },
              }}
              onClick={goToProductPage}
            >
              {order?.title?.length > 20
                ? `${order.title.substring(0, 20)}...`
                : order.title}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                textTransform: "capitalize",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/sellers/seller-details/${sellerData?.uid}`);
              }}
            >
              seller:{" "}
              {sellerData?.businessInfo?.name
                ? sellerData.businessInfo.name.length > 15
                  ? `${sellerData.businessInfo.name.substring(0, 15)}...`
                  : sellerData.businessInfo.name
                : ""}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography component="span" variant="body1" sx={bodyTextStyles}>
            ${order?.price?.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography component="span" variant="body1" sx={bodyTextStyles}>
            {order?.quantity}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography component="span" variant="body1" sx={bodyTextStyles}>
            ${(order?.quantity * order?.price)?.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography component="span" variant="body1" sx={bodyTextStyles}>
            {order?.shipping_company
              ? `${order.shipping_company}: $${order.shipping_price?.toFixed(
                  2
                )}`
              : "Free - Local Pickup"}
          </Typography>
        </TableCell>
        <TableCell>
          {order?.status && <StatusDisplay status={order?.status} />}
        </TableCell>

        <TableCell>{renderActionButton()}</TableCell>
      </TableRow>
      <Modal
        title="Confirm Action"
        open={modalVisible}
        onOk={() => {
          if (modalAction === "cancel") {
            handleCancelItem();
          } else {
            updateItemStatus(nextStatus);
          }
          setModalVisible(false);
        }}
        onCancel={() => setModalVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Typography style={{ fontWeight: 600 }}>
          {modalAction === "cancel"
            ? "Are you sure you want to cancel this item?"
            : `Are you sure you want to update the status to "${nextStatus}"?`}
        </Typography>
        {modalAction === "cancel" && (
          <Typography sx={{ mt: 2, fontSize: "14px", color: "gray" }}>
            When you cancel order, you agree to accept the Truckpartsmart{" "}
            <Link
              href="/terms-conditions"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#007bff", textDecoration: "underline" }}
            >
              Terms of Services
            </Link>{" "}
            and marketing terms, including all applicable fees (5%).
          </Typography>
        )}
      </Modal>
    </>
  );
};

export default TableRowItem;
