import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  ListItemText,
  Paper,
  Radio,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { db, storageSdk } from "../../../configs/firebase";
import { useParams } from "react-router-dom";
import {
  resetServiceActiveStep,
  setFormAddServiceData,
  setGoBackEditService,
  setServiceActiveStep,
  setServicesCategories,
} from "../../../redux/slices/appStateSlice";
import Loader from "../../../components/common/Loader";
import { MdDelete, MdOutlineAddAPhoto } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { message } from "antd";
import CustomPrompt from "../../../components/common/CustomPrompt";
import CircularStepProgress from "../components/CircularStepProgress";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import "cropperjs/dist/cropper.css";
import ImageCropUploader from "../../../components/common/ImageCropUploader";
import saveDraftService from "../../../utils/saveDraftService";


const EditService = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: serviceId } = useParams();
  const [isDirty, setIsDirty] = useState(false);
  const displayedCategories = useSelector(
    (state) => state.appState.setItemsCategories
  );
  const [categories, setCategories] = useState([]);
  const userData = useSelector((state) => state.appState.userData);
  const formAddServiceData = useSelector(
    (state) => state.appState.formAddServiceData
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [videoPreview, setVideoPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const totalSteps = useSelector((state) => state.appState.serviceTotalPages);
  const currentStep = useSelector((state) => state.appState.serviceActiveStep);
  const goBackEditService = useSelector(
    (state) => state.appState.goBackEditService
  );
  const videoInputRef = useRef(null);

  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const cropperRef = useRef(null);
  const [currentFile, setCurrentFile] = useState(null);

  // Fetch categories
  const getCategories = useCallback(async () => {
    try {
      const docs = db.collection("servicesCategories").orderBy("index", "asc");

      let fetchedCategories = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedCategories.push(doc.data());
        });

        if (fetchedCategories.length > 0) {
          setCategories(fetchedCategories);
          dispatch(setServicesCategories(fetchedCategories));
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  }, [dispatch]);

  const fetchServiceData = useCallback(async () => {
    try {
      setIsLoading(true);
      const serviceDoc = await db.collection("services").doc(serviceId).get();

      if (serviceDoc.exists) {
        const data = serviceDoc.data();
        if (data?.uid !== userData?.uid) {
          message.error("You are not authorized to edit this service.");
          navigate("/");
          setIsLoading(false);
          return;
        }

        dispatch(setFormAddServiceData(data));
        setIsLoading(false);

        //  Prepare images for preview
        const imagesWithUrls = (data.images || []).map((image) => ({
          file: image, // assuming it's a URL string or file path
          url: image,
        }));
        setSelectedImages(imagesWithUrls);

        if (data.video) {
          setVideoPreview(data.video); // assuming the video is a URL string
        }
      } else {
        console.error("No such document!");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching service:", error);
      setIsLoading(false);
    }
  }, [serviceId]);

  //ignore the dependency array it will make error
  useEffect(() => {
    getCategories();
    if (!goBackEditService) {
      fetchServiceData();
    }
  }, [getCategories]);

  useEffect(() => {
    dispatch(resetServiceActiveStep());
  }, []);
  
  useEffect(() => {
    if (formAddServiceData?.categoryId && categories.length > 0) {
      const foundCategory = categories.find(
        (cat) => cat.id === formAddServiceData.categoryId
      );
      setSelectedCategory(foundCategory || null);
    }
  }, [formAddServiceData.categoryId, categories]);
  
  useEffect(() => {
    if (formAddServiceData?.images) {
      const imagesWithUrls = formAddServiceData?.images.map((image) => ({
        file: image,
        url: typeof image === "string" ? image : URL?.createObjectURL(image), // If it's a URL, use it directly
      }));
      setSelectedImages(imagesWithUrls);
    }
  }, [formAddServiceData.images]);

  // Form validation schema
  const RequestSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("Title is required")
      .min(3, "Title must be at least 3 characters"),
    description: Yup.string()
      .trim()
      .required("Description are required")
      .min(15, "Description must be at least 15 characters"),
    categoryId: Yup.string().required("Please select category"),
    images: Yup.array()
      .min(1, "Please upload at least one image")
      .required("Photos are required"),
  });

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const handleVideoUpload = async (e, setFieldValue) => {
    const video = e?.target?.files[0];

    if (video) {
    
      const fileSizeMB = video.size / (1024 * 1024);

      // Validate file type
      if (!["video/mp4", "video/mov"].includes(video.type)) {
        message.error("Only MP4 or MOV formats are allowed.");
        return;
      }

       // Validate file size (100MB max)
    if (fileSizeMB > 100) {
      message.error("File size exceeds the 100MB limit.");
      return;
    }

    // Check video duration before uploading
    const videoElement = document.createElement("video");
    videoElement.preload = "metadata";
    videoElement.src = URL.createObjectURL(video);
    videoElement.onloadedmetadata = async () => {
      URL.revokeObjectURL(videoElement.src);

      // Validate duration (max 180 seconds = 3 minutes)
      if (videoElement.duration > 180) {
        message.error("Video duration exceeds 3 minutes.");
        return;
      }
      
      // Start the loading state
      setIsLoadingVideo(true);

      // Generate a unique name for the video file
      const currentDateTime = formatDate(new Date());
      const videoName = `video-${currentDateTime}.mp4`;
      const videoRef = storageSdk.ref(`services/${userData?.uid}/${videoName}`);
      try {
        // Upload the video to Firebase Storage
        await uploadBytes(videoRef, video);
        const downloadURL = await getDownloadURL(videoRef);

        // Set the video preview and form data
        setVideoPreview(downloadURL);
        setFieldValue("video", downloadURL);

        dispatch(
          setFormAddServiceData({
            ...formAddServiceData,
            video: downloadURL,
          })
        );

        message.success("Video uploaded successfully.");
      } catch (error) {
        console.error("Error uploading video:", error);
        message.error("Failed to upload video. Please try again.");
      } finally {
        // Stop the loading state
        setIsLoadingVideo(false);
      }
    }
  };
    // Clear input field
    e.target.value = null;
  };

  const handleOnDragEnd = (result, setFieldValue) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(selectedImages);
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);

    setSelectedImages(reorderedImages);
    setFieldValue(
      "images",
      reorderedImages.map((img) => img.file)
    );
  };

  const handleRemoveImage = (index, setFieldValue) => {
    const updatedImages = [...selectedImages];
    const removedImage = updatedImages.splice(index, 1)[0];
    if (removedImage) {
      URL.revokeObjectURL(removedImage.url); // Clean up memory
    }
    setSelectedImages(updatedImages);
    setFieldValue(
      "images",
      updatedImages.map((img) => img.file)
    );
  };

  const handleRemoveVideo = (setFieldValue) => {
    if (videoPreview) {
      URL.revokeObjectURL(videoPreview);
    }
    setFieldValue("video", null);
    setVideoPreview(null);
    if (videoInputRef.current) {
      videoInputRef.current.value = "";
    }
  };

  // Handle form submission and update service in Firestore
  const handleSubmit = async (values) => {
    setIsDirty(false);
    setTimeout(() => {
      const hasModalShown = localStorage.getItem("hasModalShownService");
      dispatch(setFormAddServiceData(values));
      dispatch(setServiceActiveStep(2));
      dispatch(setGoBackEditService(false));
      if (hasModalShown !== true) {
        saveDraftService({
          userData,
          formAddServiceData,
          values: values,
        });
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(`/selling/edit-service-billing&ShippingDetails/${serviceId}`);
    }, 0);
  };

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files).filter((file) =>
      file.type.startsWith("image/")
    );

    if (files.length + selectedImages.length > 10) {
      message.error({
        content: "You can upload a maximum of 10 images.",
        duration: 5,
      });
      return;
    }

    if (files.length > 0) {
      setCurrentFile(files[0]);
      setIsCropDialogOpen(true);
    }

    e.target.value = null;
  };

  const handleCropSave = async (setFieldValue) => {
    const cropper = cropperRef.current?.cropper;

    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();

      if (!croppedCanvas) {
        message.error("Failed to crop the image. Please try again.");
        return;
      }

      croppedCanvas.toBlob(async (blob) => {
        if (!blob) {
          message.error("Failed to generate cropped image. Please try again.");
          return;
        }

        setIsCropDialogOpen(false);

        const currentDateTime = formatDate(new Date());
        const imageName = `image-${currentDateTime}.png`;
        const imageRef = storageSdk.ref(
          `services/${userData?.uid}/${imageName}`
        );

        try {
          setIsLoadingImage(true);

          await uploadBytes(imageRef, blob);
          const downloadURL = await getDownloadURL(imageRef);

          const uploadedImage = { file: null, url: downloadURL };
          const updatedImages = [...selectedImages, uploadedImage];

          setSelectedImages(updatedImages);
          setFieldValue(
            "images",
            updatedImages.map((img) => img?.url)
          );

          dispatch(
            setFormAddServiceData({
              ...formAddServiceData,
              images: updatedImages.map((img) => img.url),
            })
          );

          message.success("Image uploaded successfully.");
        } catch (error) {
          console.error("Error uploading image:", error);
          message.error("Failed to upload image. Please try again.");
        } finally {
          setIsLoadingImage(false);
        }
      });
    }
  };

  const handleCropCancel = () => {
    setIsCropDialogOpen(false);
    setCurrentFile(null);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        padding: "20px",
        margin: "0px 25px",
        background: theme.palette.primary.light,
        borderRadius: "10px",
        position: "relative",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h2"
          sx={{
            color: theme.palette.dark.colorCart,
            fontSize: "24px",
            fontFamily: theme.typography.headerFont,
          }}
        >
          Update Service
        </Typography>
        <CircularStepProgress steps={totalSteps} currentStep={currentStep} />
      </Stack>

      <Formik
        enableReinitialize
        initialValues={{
          title: formAddServiceData?.title || "",
          categoryId: formAddServiceData?.categoryId || "",
          description: formAddServiceData?.description || "",
          video: formAddServiceData?.video || null,
          images: formAddServiceData?.images || [],
        }}
        validationSchema={RequestSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
            
            <Grid item xs={12} md={6}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.charcoal,
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  Service category <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete
                  id="categoryId"
                  name="categoryId"
                  value={selectedCategory}
                  // options={categories}
                  options={[...categories]?.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => {
                    setSelectedCategory(value);
                    setFieldValue("categoryId", value ? value.id : "");
                  }}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{
                        background: theme.palette.primary.light,
                        maxHeight: "400px",
                        overflowY: "hidden",
                        borderRadius: "10px",
                      }}
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          scrollbarWidth: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        },
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  // isOptionEqualToValue={(option, value) =>
                  //   option?.id === value?.id
                  // }

                  renderOption={(props, option, { selected }) => (
                    <li
                      key={option.id}
                      {...props}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: theme.palette.primary.background,
                        padding: "8px 16px",
                        borderRadius: "8px",
                        margin: "5px 10px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={option.image}
                          alt={option.name}
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "8px",
                          }}
                        />
                        <ListItemText
                          primary={option.name}
                          primaryTypographyProps={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            color: theme.palette.primary.black,
                          }}
                        />
                      </div>
                      <Radio
                        checked={selected}
                        value={option.id}
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select service category"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: "47px",
                          border: `1px solid ${theme.palette.gray.borderFaild}`,
                          background: theme.palette.primary.light,
                          color: `${theme.palette.primary.darkColor} !important`,
                          fontFamily: "Poppins",

                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "21px",
                          borderRadius: "10px",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                      }}
                    />
                  )}
                />

                {errors.categoryId && touched.categoryId ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.categoryId}
                  </div>
                ) : null}
              </Grid>
              
              <Grid item xs={12} md={6}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.Charcoal,
                  }}
                >
                  Type of service <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  name="title"
                  placeholder="Enter service name"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, " ");
                    setIsDirty(true);
                    setFieldValue("title", inputValue);
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
                {errors.title && touched.title ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.title}
                  </div>
                ) : null}
              </Grid>

            

              <Grid item xs={12}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.Charcoal,
                  }}
                >
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <Field
                  as="textarea"
                  name="description"
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/ +/g, " ");

                    const formattedValue = inputValue
                      .replace(/(?:^|\.\s+)(\w)/g, (match) =>
                        match.toUpperCase()
                      )
                      .replace(/^\w/, (match) => match.toUpperCase());
                    setIsDirty(true);
                    setFieldValue("description", formattedValue);
                  }}
                  style={{
                    width: "100%",
                    padding: "13px",
                    height: "130px",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.darkColor,
                    border: `1px solid ${theme.palette.gray.borderFaild}`,
                    marginTop: "8px",
                    borderRadius: "10px",
                    background: theme.palette.primary.light,
                  }}
                  onFocus={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.outline = "none";
                    e.target.style.border = `1px solid ${theme.palette.gray.borderFaild}`;
                  }}
                />
                {errors.description && touched.description ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.description}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} md={4}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.Charcoal,
                  }}
                >
                  Photos <span style={{ color: "red" }}>*</span>
                </label>
                <div
                  style={{
                    fontSize: ".7rem",
                    color: theme.palette.dark.dustyBlue,
                    margin: "5px 20px 20px 20px",
                    fontWeight: 500,
                    lineHeight: "1rem",
                    fontStyle: "italic",
                    fontFamily: "Poppins",
                  }}
                >
                  <ul>
                    <li>The First Image will be the default one.</li>
                    <li>You can drag between the images to rearrange.</li>
                  </ul>
                </div>

                <Box
                  sx={{
                    border: `1px dashed ${theme.palette.primary.main}`,
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                    padding: "20px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    height: selectedImages.length === 0 ? "195px" : "auto",
                  }}
                  onClick={(e) => {
                    if (!isCropDialogOpen) {
                      // Only trigger input click when the crop dialog is NOT open
                      document.getElementById("photo-upload").click();
                    }
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => {
                      handleImageSelect(e);
                      setIsDirty(true);
                    }}
                    style={{ display: "none" }}
                    id="photo-upload"
                  />
                  <ImageCropUploader
                    isCropDialogOpen={isCropDialogOpen}
                    handleCropCancel={handleCropCancel}
                    currentFile={currentFile}
                    cropperRef={cropperRef}
                    handleCropSave={handleCropSave}
                    setFieldValue={setFieldValue}
                  />
                  {isLoadingImage ? (
                    <Loader color={theme.palette.primary.main} />
                  ) : (
                    <MdOutlineAddAPhoto
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "35px",
                      }}
                    />
                  )}
                </Box>
                {errors.images && touched.images ? (
                  <div
                    style={{
                      color: theme.palette.status.error,
                      fontSize: "12px",
                      padding: "4px 10px",
                    }}
                  >
                    {errors.images}
                  </div>
                ) : null}
                {selectedImages.length > 0 && (
                  <Box
                    sx={{
                      borderRadius: "10px",
                      background: theme.palette.primary.light,
                      padding: "10px",
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                      height: "300px",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <DragDropContext
                      onDragEnd={(result) =>
                        handleOnDragEnd(result, setFieldValue)
                      }
                    >
                      <Droppable droppableId="images">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              gap: "10px",
                              flexWrap: "wrap",
                              overflow: "hidden",
                            }}
                          >
                            {selectedImages.map((imageObj, index) =>
                              imageObj && imageObj.url ? (
                                <Draggable
                                  key={index}
                                  draggableId={`image-${index}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Box
                                      sx={{ height: "200px", mb: 2 }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <img
                                        src={imageObj.url}
                                        alt={`preview-${index}`}
                                        style={{
                                          width: "300px",
                                          height: "200px",
                                          objectFit: "cover",
                                          borderRadius: "5px",
                                          position: "relative",
                                        }}
                                      />
                                      <IconButton
                                        onClick={() =>
                                          handleRemoveImage(
                                            index,
                                            setFieldValue
                                          )
                                        }
                                        sx={{
                                          position: "relative",
                                          top: "-210px",
                                          right: "-150px",
                                          borderRadius: "50%",
                                        }}
                                      >
                                        <MdDelete color="red" />
                                      </IconButton>
                                    </Box>
                                  )}
                                </Draggable>
                              ) : null
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: theme.palette.primary.Charcoal,
                  }}
                >
                  Video (optional)
                </label>
                <div
                  style={{
                    fontSize: ".7rem",
                    color: theme.palette.dark.dustyBlue,
                    margin: "5px 20px 20px 20px",
                    fontWeight: 500,
                    lineHeight: "1rem",
                    fontStyle: "italic",
                    fontFamily: "Poppins",
                  }}
                >
                  <ul>
                    <li>you can upload one video.</li>
                    <li>Maximum video upload limit: 3 minutes</li>
                  </ul>
                </div>
                <Box
                  sx={{
                    border: `1px dashed ${theme.palette.primary.main}`,
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                    padding: "20px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: videoPreview ? "auto" : "195px",
                    cursor:
                      !videoPreview && !isLoadingVideo
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={() =>
                    !isLoadingVideo &&
                    !videoPreview &&
                    document.getElementById("video-upload").click()
                  }
                >
                  <input
                    type="file"
                    // accept="video/*"
                    // ref={videoInputRef}
                    // onChange={(e) => {
                    //   handleVideoUpload(e, setFieldValue);
                    // }}
                    accept="video/mp4,video/mov"
                    ref={videoInputRef}
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        const file = e.target.files[0];
                        const fileSizeMB = file.size / (1024 * 1024);

                        if (!["video/mp4", "video/mov"].includes(file.type)) {
                          message.error("Only MP4 or MOV formats are allowed.");
                          return;
                        }

                        if (fileSizeMB > 100) {
                          message.error("File size exceeds the 100MB limit.");
                          return;
                        }

                        handleVideoUpload(e, setFieldValue);
                      }
                    }}
                    style={{
                      display: "none",
                    }}
                    id="video-upload"
                    disabled={!!videoPreview}
                  />

                  {isLoadingVideo ? (
                    <Loader color={theme.palette.primary.main} />
                  ) : !videoPreview ? (
                    <MdOutlineAddAPhoto
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "35px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {" "}
                      <video
                        key={videoPreview}
                        id="video-preview"
                        width="100%"
                        height="100%"
                        controls
                        playsInline
                        muted
                        preload="metadata"
                        onLoadedMetadata={(e) => {
                          const video = e.target;
                          video.controls = true;
                          video.muted = false; // Unmute after initial load
                        }}
                        style={{ display: "block", borderRadius: "15px" }}
                      >
                        <source src={videoPreview} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveVideo(setFieldValue);
                        }}
                        sx={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          borderRadius: "50%",
                        }}
                      >
                        <MdDelete color="red" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <button
                  type="submit"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                    textAlign: "center",
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.main,
                    padding: "13px 40px",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "170px",
                    height: "50px",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader color={theme.palette.primary.light} />
                  ) : (
                    "Next"
                  )}
                </button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <CustomPrompt when={isDirty} toNavigate={"/selling"} />
    </div>
  );
};

export default EditService;
