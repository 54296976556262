import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  InputAdornment,
  Paper,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { message, Modal } from "antd";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFormTouched,
  setFormTouched,
} from "../../../redux/slices/appStateSlice";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, "address name must be at least 3 characters long")
    .max(10, "address name must be at most 10 characters long")
    .when("addressName", {
      is: (val) => val === "other",
      then: (schema) => schema.required("Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  addressLineOne: Yup.string().trim().required("address is required"),
  addressLineTwo: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9\s\-/#.,]*$/,
      "Invalid characters in apartment/suite field"
    ),
  city: Yup.string()
    .trim()
    .min(3, "city must be at least 3 characters long")
    .required("City is required"),
  state: Yup.string()
    .trim()
    .min(2, "State must be least 2 characters long")
    .max(20, "State must be at most 20 characters long")
    .required("State is required"),
  zipCode: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9\s\-]+$/, "Zip code must be valid")
    .required("Zip code is required"),
});

const EditAddressModal = ({
  ServiceAddress,
  handleEditClose,
  setEditAddress,
  handleAddressSubmit,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [address, setAddress] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [country, setCountry] = useState(null);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  
  const [initialValues, setInitialValues] = useState({
    addressName: "other",
    name: "",
    addressLineTwo: "",
    city: "",
    state: "",
    zipCode: "",
    addressLineOne: "",
    isAddressSelected: false,
  });

  const confirmedLeave = useRef(false);
  const isFormTouched = useSelector((state) => state.appState.isFormTouched);
  useEffect(() => {
    if (ServiceAddress) {
      setInitialValues({
        addressName:
          ServiceAddress.name === "home" || ServiceAddress.name === "work"
            ? ServiceAddress.name
            : "other",
        name: ServiceAddress.name || "",
        addressLineTwo: ServiceAddress.addressLineTwo || "",
        city: ServiceAddress.city || "",
        state: ServiceAddress.state || "",
        zipCode: ServiceAddress.zipCode || "",
        addressLineOne: ServiceAddress.addressLineOne || "",
        isAddressSelected: false,
      });
      setLat(ServiceAddress.lat);
      setLng(ServiceAddress.lng);
      setPlaceId(ServiceAddress.placeId);
      setCountry(ServiceAddress.country);
      setAddress(ServiceAddress.addressLineOne);
    }
  }, [ServiceAddress]);
  useEffect(() => {
    dispatch(resetFormTouched());
  }, []);

  useEffect(() => {
    if (typeof window.google !== "undefined" && window.google.maps) {
      setApiLoaded(true);
    }
  }, []);

  const handleSelect = async (value, setFieldValue) => {
    const results = await geocodeByAddress(value);
    if (results.length === 0) {
      throw new Error("No results found");
    }
    const latLng = await getLatLng(results[0]);
    const addressComponents = results[0].address_components;

    const addressDetails = {
      addressLineOne: value,
      city: "",
      state: "",
      zipCode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("locality")) {
        addressDetails.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        addressDetails.state = component.short_name;
      } else if (types.includes("postal_code")) {
        addressDetails.zipCode = component.long_name;
      } else if (types.includes("country")) {
        setCountry(component.short_name);
      }
    });

    setAddress(value);
    setFieldValue("addressLineOne", value);
    setFieldValue("city", addressDetails.city);
    setFieldValue("state", addressDetails.state);
    setFieldValue("zipCode", addressDetails.zipCode);
    setFieldValue("isAddressSelected", true);
    setIsAddressSelected(true);
    setPlaceId(results[0].place_id);
    setLat(latLng.lat);
    setLng(latLng.lng);
  };

  const handleSubmit = async (values) => {
    try {
      delete values.isAddressSelected;
      const { addressName, isAddressSelected, ...filteredValues } = values;
      const updatedAddress = {
        ...filteredValues,
        addressLineOne: address,
        placeId,
        lat,
        lng,
        country,
      };
      handleAddressSubmit(updatedAddress);
      dispatch(resetFormTouched());
      message.success("Address updated successfully");
      setEditAddress(false);
    } catch (error) {
      message.error(`Error updating address: ${error.message}`);
      console.error("Error updating address:", error);
    }
  };

  const handleClearAddress = (setFieldValue) => {
    setAddress("");
    setIsAddressSelected(false);
    setFieldValue("addressLineOne", "");
    setFieldValue("isAddressSelected", false);
  };

  const handleInputChange = (event) => {
    dispatch(setFormTouched());
  };

  const showConfirmModal = useCallback((onOk, onCancel) => {
    Modal.confirm({
      title: "Unsaved Changes",
      content:
        "You have unsaved changes. Are you sure you want to leave this page?",
      okText: "Yes, leave page",
      cancelText: "Stay on page",
      onOk,
      onCancel,
      centered: true,
    });
  }, []);

  useEffect(() => {
    confirmedLeave.current = false;
  }, [isFormTouched]);
  // in case of click back with unsaved changes
  useEffect(() => {
    const handlePopState = (event) => {
      if (isFormTouched && !confirmedLeave.current) {
        event.preventDefault();
        showConfirmModal(
          () => {
            confirmedLeave.current = true;
            dispatch(resetFormTouched());
            navigate(-1);
          },
          () => {
            // Do nothing
          }
        );
      } else {
        navigate(-1);
      }
    };

    window.history.pushState(null, "", location.pathname + location.search);

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isFormTouched]);
  // in case of click reload with unsaved changes
  useEffect(() => {
    const handleOnBeforeUnload = (event) => {
      if (isFormTouched && !confirmedLeave.current) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    const handleOnUnload = () => {
      if (isFormTouched && !confirmedLeave.current) {
        dispatch(resetFormTouched());
      }
    };

    window.addEventListener("beforeunload", handleOnBeforeUnload, {
      capture: true,
    });
    window.addEventListener("unload", handleOnUnload, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", handleOnBeforeUnload, {
        capture: true,
      });
      window.removeEventListener("unload", handleOnUnload, { capture: true });
    };
  }, [isFormTouched]);

  if (!apiLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnMount={true}
    >
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form sx={{ position: "relative" }}>
          <Stack spacing={3}>
            <Box
              sx={{
                borderRadius: "8px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: {
                  xs: "10px",
                  sm: "20px",
                  lg: "30px",
                },
                gridTemplateAreas: `
                  "name name name"
                  "addressLineOne addressLineOne addressLineTwo"
                  "city state zipCode"
                `,
                "@media (max-width: 600px)": {
                  gridTemplateColumns: "1fr",
                  gridTemplateAreas: `
                    "name"
                     "addressLineOne"
                     "addressLineTwo"
                    "city"
                    "state"
                    "zipCode"
                  `,
                },
              }}
            >
              {/* Name */}
              <Box sx={{ gridArea: "name" }}>
                <InputLabel sx={{ marginBottom: "8px" }} htmlFor="name">
                  address Name
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="addressName"
                    name="addressName"
                    value={values.addressName}
                    onChange={(e) => {
                      handleInputChange(e);
                      handleChange(e);
                      if (e.target.value !== "other") {
                        setFieldValue("name", e.target.value);
                      } else {
                        setFieldValue("name", "");
                      }
                    }}
                  >
                    <FormControlLabel
                      value="home"
                      control={<Radio />}
                      label="Home"
                    />
                    <FormControlLabel
                      value="work"
                      control={<Radio />}
                      label="Work"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
                {values.addressName === "other" && (
                  <TextField
                    id="name"
                    name="name"
                    fullWidth
                    variant="outlined"
                    value={values.name}
                    onBlur={handleBlur("name")}
                    onChange={(e) => {
                      handleChange(e);
                      handleInputChange(e);
                    }}
                    error={touched.name && Boolean(errors.name)}
                    placeholder="Enter address name"
                  />
                )}

                {touched.name && errors?.name && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.name}
                  </Typography>
                )}
              </Box>
              {/* Address Line One */}
              <Box sx={{ gridArea: "addressLineOne" }}>
                <InputLabel
                  htmlFor="addressLineOne"
                  sx={{ marginBottom: "8px" }}
                >
                  Address{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <PlacesAutocomplete
                  value={address}
                  disabled={isAddressSelected}
                  onChange={(value) => {
                    setAddress(value);
                    handleInputChange(); // Mark form as touched on input change
                    setIsAddressSelected(false);
                    setFieldValue("isAddressSelected", false);
                  }}
                  onSelect={(value) => handleSelect(value, setFieldValue)}
                  searchOptions={{
                    componentRestrictions: { country: "us" },
                    types: ["address"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div style={{ position: "relative", width: "100%" }}>
                      <TextField
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                        })}
                        id="addressLineOne"
                        name="addressLineOne"
                        fullWidth
                        disabled={isAddressSelected}
                        variant="outlined"
                        error={
                          touched.addressLineOne &&
                          Boolean(errors.addressLineOne)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {address && (
                                <IconButton
                                  onClick={() =>
                                    handleClearAddress(setFieldValue)
                                  }
                                  aria-label="clear address"
                                >
                                  <IoCloseCircleOutline />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Paper
                        sx={{
                          marginTop: "-15px !important",
                          position: "absolute",
                          zIndex: 2100,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "4px",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const { key, ...restProps } =
                            getSuggestionItemProps(suggestion);
                          return (
                            <Box
                              key={suggestion.placeId || index} // Provide unique key here
                              {...restProps}
                              sx={{
                                padding: "10px 16px",
                                backgroundColor: suggestion.active
                                  ? "rgba(0, 0, 0, 0.1)"
                                  : "white",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                                },
                                borderBottom: "1px solid #eee",
                              }}
                            >
                              <Typography variant="body2">
                                {suggestion.description}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Paper>
                    </div>
                  )}
                </PlacesAutocomplete>

                {touched.addressLineOne && errors.addressLineOne && (
                  <Typography variant="caption" color="error">
                    {errors.addressLineOne}
                  </Typography>
                )}
              </Box>

              {/* Address Line Two */}
              <Box sx={{ gridArea: "addressLineTwo" }}>
                <InputLabel
                  sx={{ marginBottom: "8px" }}
                  htmlFor="addressLineTwo"
                >
                  Apartment/Suite Field
                </InputLabel>
                <TextField
                  id="addressLineTwo"
                  name="addressLineTwo"
                  fullWidth
                  variant="outlined"
                  value={values.addressLineTwo}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e); //
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  placeholder="Enter apartment/suite field"
                />
                {touched.addressLineTwo && errors?.addressLineTwo && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.addressLineTwo}
                  </Typography>
                )}
              </Box>

              {/* City */}
              <Box sx={{ gridArea: "city" }}>
                <InputLabel sx={{ marginBottom: "8px" }} htmlFor="city">
                  City{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="city"
                  name="city"
                  fullWidth
                  variant="outlined"
                  value={values.city}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e); //
                  }}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  placeholder="Enter city"
                />
                {touched.city && errors?.city && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.city}
                  </Typography>
                )}
              </Box>

              {/* State */}
              <Box sx={{ gridArea: "state" }}>
                <InputLabel sx={{ marginBottom: "8px" }} htmlFor="state">
                  State{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="state"
                  name="state"
                  fullWidth
                  variant="outlined"
                  value={values.state}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e); //
                  }}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  placeholder="Enter state"
                />
                {touched.state && errors?.state && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.state}
                  </Typography>
                )}
              </Box>

              {/* Zip Code */}
              <Box sx={{ gridArea: "zipCode" }}>
                <InputLabel sx={{ marginBottom: "8px" }} htmlFor="zipCode">
                  Zip Code{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="zipCode"
                  name="zipCode"
                  fullWidth
                  variant="outlined"
                  value={values.zipCode}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e); //
                  }}
                  onBlur={handleBlur}
                  error={touched.zipCode && Boolean(errors.zipCode)}
                  placeholder="Enter zip code"
                />
                {touched.zipCode && errors?.zipCode && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.zipCode}
                  </Typography>
                )}
              </Box>

              {/* Save Changes Button */}
              <Box sx={{ gridColumn: "1 / span 3" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    paddingY: "16px",
                    paddingX: "32px",
                    borderRadius: "8px",
                  }}
                >
                  Edit Address
                </Button>
              </Box>
            </Box>
          </Stack>
          <Box
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              cursor: "pointer",
            }}
          >
            <IoIosCloseCircleOutline
              onClick={handleEditClose}
              size="24px"
              color="red"
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditAddressModal;
