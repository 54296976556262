import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
  Modal as MuiModal,
  CircularProgress,
} from "@mui/material";
import CircularStepProgress from "../components/CircularStepProgress";
import IOSSwitch from "../../../components/common/IOSSwitch";

import { BiEditAlt } from "react-icons/bi";
import map from "../../../assets/Images/map.png";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  clearFormAddServiceData,
  setFormAddServiceData,
  setGoBackEditService,
  setNewItemAdded,
} from "../../../redux/slices/appStateSlice";
import { db, storageSdk as storage } from "../../../configs/firebase";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { message, Modal as AntModal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { formatAddress } from "../../../utils/reusableFunctions";
import ShippingAddressSelector from "../../cart/components/ShippingAddressSelector";
import saveDraftService from "../../../utils/saveDraftService";


const serviceBillingDetailsSchema = Yup.object().shape({
  contactForPrice: Yup.boolean().required("Contact for price is required"),
  price: Yup.number()
    .min(1, "Price cannot be negative at least 1")
    .when("contactForPrice", {
      is: false,
      then: (schema) => schema.required("Please enter price"),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  isOnline: Yup.boolean().required("Allow payment option is required"),
  isLocalAddress: Yup.boolean().required("Local pickup option is required"),
});

const EditServiceBillingDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: serviceId } = useParams();
  const totalSteps = useSelector((state) => state.appState.serviceTotalPages);
  const currentStep = useSelector((state) => state.appState.serviceActiveStep);
  const userData = useSelector((state) => state.appState.userData);
  const formAddServiceData = useSelector(
    (state) => state.appState.formAddServiceData
  );
  const [buttonClicked, setButtonClicked] = useState(""); // Track which button is clicked
  const [editAddress, setEditAddress] = useState(false);
  const isFormTouched = useSelector((state) => state.appState.isFormTouched);
  const [isLoading, setIsLoading] = useState(false);
  const { businessInfo } = userData;
  // const [initialValues, setInitialValues] = useState(null);
  const [chooseAddressEnabled, setChooseAddressEnabled] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [addressSelectorMode, setAddressSelectorMode] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const formattedAddress = formatAddress(
    chooseAddressEnabled ? selectedAddress || formAddServiceData?.businessAddress : formAddServiceData?.businessAddress
  );
  const commonStyles = {
    boxPadding: { paddingX: "30px" },
    formBox: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "32px",
      padding: "10px 20px",
    },
    labelText: {
      color: theme.palette.primary.darkMain,
      fontWeight: "600",
      textTransform: "capitalize",
    },
    inputStyle: {
      backgroundColor: theme.palette.primary.background,
      width: "12.25rem",
      padding: "19px 16px",
      borderRadius: "8px",
      "& .MuiInputBase-input": {
        color: theme.palette.primary.lightDoveGrey,
      },
    },
    switchStack: {
      backgroundColor: theme.palette.primary.background,
      borderRadius: "8px",
      padding: "14px 16px",
    },
    infoText: {
      color: theme.palette.dark.colorCart,
      fontWeight: "600",
      textTransform: "capitalize",
    },
    buttonStyle: {
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.main,
      fontSize: "14px",
      fontWeight: "700",
      padding: "0 32px",
      borderRadius: "5px",
      height: "56px",
      width: {
        xs: "100%",
        sm: "11.25rem",
      },
      marginY: {
        sm: "20px",
        xs: "10px",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.light,
      },
    },
    dashedBox: {
      border: `dashed 1px ${theme.palette.primary.dashedGrey}`,
      padding: "16px",
      width: { xs: "100%", sm: "21.438rem" },
      borderRadius: "10px",
      marginY: "20px",
    },
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "1px solid white",
    p: 4,
  };

  // Helper function to upload file to Firebase Storage under the user's UID folder
  const uploadFileToStorage = async (file) => {
    const storageRef = storage.ref();
    const userUid = userData?.uid;
    const fileRef = storageRef.child(
      `services/${userUid}/${file.name}-${Date.now()}`
    ); // Create a unique file path for this user's service
    await fileRef.put(file);
    const downloadURL = await fileRef.getDownloadURL();
    return downloadURL;
  };
  
  const isFile = (media) => media instanceof File;
  
  const validateShippingMethod = (isLocalPickup) => {
    if (!isLocalPickup) {
      message.error("Please select local Pickup.");
      return false;
    }
    return true;
  };



  const convertToReadableDate = (timestamp) => {
    if (timestamp?.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString('en-US', { timeZone: 'UTC' }); 
    }
    return serverTimestamp();
  };


  const submitForm = async (values) => {
    if (!validateShippingMethod(values.isLocalAddress)) {
      return;
    }

    setIsLoading(true);

    dispatch(
      setFormAddServiceData({
        price: values.price,
        contactForPrice: values.contactForPrice,
        isOnline: values.contactForPrice ? false : values.isOnline,
        isLocalAddress: values.isLocalAddress,
      })
    );
    
    saveDraftService({
      userData,
      formAddServiceData,
      values: false, // Clear draft
    });

    let imageURLs = [];
    let videoURL = null;

    // Handle images (filter files vs URLs)
    const existingImages = formAddServiceData?.images?.filter(
      (image) => typeof image === "string"
    ); // URLs
    const newImages = formAddServiceData?.images?.filter(isFile); // Files

    // Upload only new images
    if (newImages?.length > 0) {
      const newImageUploadPromises = newImages.map((image) =>
        uploadFileToStorage(image)
      );
      const newImageURLs = await Promise.all(newImageUploadPromises);
      imageURLs = [...existingImages, ...newImageURLs];
    } else {
      imageURLs = existingImages; // No new images, use existing URLs
    }

    // Handle video (check if it's a new file or existing URL)

    if (formAddServiceData?.video && isFile(formAddServiceData?.video)) {
      videoURL = await uploadFileToStorage(formAddServiceData?.video);

    } else {
      // If the video is not changed, use the existing video URL
      videoURL = formAddServiceData?.video;
    }


    const { updatedAt, createdAt, isDefault, id, ...restAddress } =
      chooseAddressEnabled
        ? selectedAddress || formAddServiceData?.businessAddress
        : formAddServiceData?.businessAddress;

    const serviceModel = {
      ...formAddServiceData,
      businessAddress: restAddress || null,
      _geoloc: {
        lat:
          (chooseAddressEnabled
            ? selectedAddress?.lat || formAddServiceData?.businessAddress?.lat
            : formAddServiceData?.businessAddress.lat) || 0,
        lng:
          (chooseAddressEnabled
            ? selectedAddress.lng || formAddServiceData?.businessAddress.lng
            : formAddServiceData?.businessAddress.lng) || 0,
      },
      images: imageURLs,
      video: videoURL,
      isActive: buttonClicked === "listService",
      isDraft: buttonClicked === "addToDraft",
      contactForPrice: values?.contactForPrice,
      isOnline: values?.contactForPrice ? false : values?.isOnline,
      isLocalAddress: values?.isLocalAddress,
      price: values?.price ? Number(values?.price) : null,
      updatedAt: Timestamp.now(),
      createdAt: formAddServiceData?.createdAt?.seconds
        ? Timestamp?.fromMillis(formAddServiceData?.createdAt?.seconds * 1000)
        : serverTimestamp(),
      registeredSince: userData?.registeredSince,
    };


    try {
      const serviceRef = db.collection("services").doc(serviceId);
      await serviceRef.update(serviceModel); // Update the existing service
      message.success({
        content: "Service updated successfully",
        duration: 5,
      });
      dispatch(clearFormAddServiceData());
      dispatch(setGoBackEditService(false));
      dispatch(setNewItemAdded(true));
      
      // navigate("/selling?type=services");
    } catch (error) {
      message.error({
        content: "Failed to update service. Please try again",
        duration: 5,
      });
      console.error("Error updating service:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditOpen = () => setEditAddress(true);

  const showConfirm = () => {
    AntModal.confirm({
      title: "Unsaved Changes",
      content:
        "You have unsaved changes. Are you sure you want to close without saving?",
      okText: "Yes, close",
      cancelText: "Cancel",
      onOk() {
        setEditAddress(false);
      },
    });
  };

  const handleEditClose = () => {
    if (isFormTouched) {
      showConfirm();
    } else {
      setEditAddress(false);
    }
  };

  const handleAddressSubmit = (updatedAddress) => {
    dispatch(setFormAddServiceData({ businessAddress: updatedAddress }));
  };

  const handleNewAddressModalOpen = () => {
    setIsNewAddressModalOpen(true);
  };
  const inputRef = useRef(null);
  const handleFocus = () => {
    const inputElement = inputRef.current;

    const preventScroll = (e) => {
      e.preventDefault();
    };

    inputElement.addEventListener("wheel", preventScroll);

    // Clean up the event listener when the input loses focus
    inputElement.addEventListener("blur", () => {
      inputElement.removeEventListener("wheel", preventScroll);
    });
  };
  return (
    <>
      {formAddServiceData && (
        <Formik
          initialValues={{
            contactForPrice: formAddServiceData?.contactForPrice,
            price: formAddServiceData?.price,
            isOnline: formAddServiceData?.isOnline,
            isLocalAddress: formAddServiceData?.isLocalAddress,
            businessAddress: formAddServiceData?.businessAddress,
          }}
          validationSchema={serviceBillingDetailsSchema}
          onSubmit={submitForm}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form>
              <Box sx={commonStyles.boxPadding}>
                <Box sx={commonStyles.formBox}>
                  {/* Header */}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        color: theme.palette.dark.colorCart,
                        fontSize: "24px",
                        fontFamily: theme.typography.headerFont,
                      }}
                    >
                      Update Service
                    </Typography>
                    <CircularStepProgress
                      steps={totalSteps}
                      currentStep={currentStep}
                    />
                  </Stack>

                  {/* Form Body */}
                  <Stack sx={{ width: { xs: "100%", sm: "90%" } }}>
                    {/* Price Section */}
                    <Box sx={{ marginY: "10px" }}>
                      <Typography
                        variant="body2"
                        sx={{ ...commonStyles.labelText, marginBottom: "10px" }}
                      >
                        price <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        gap={3}
                      >
                        <TextField
                          variant="standard"
                          name="price"
                          type="number"
                          placeholder={
                            values.contactForPrice
                              ? "Contact for Price"
                              : "Enter Price"
                          }
                          onBlur={handleBlur}
                          sx={{
                            ...commonStyles.inputStyle,
                            backgroundColor: values.contactForPrice
                              ? theme.palette.primary.brownGrey
                              : theme.palette.primary.background,
                              padding: '13px',
                            "& .MuiInputBase-input": {
                              color: values.contactForPrice
                                ? theme.palette.primary.doveGrey
                                : theme.palette.primary.lightDoveGrey,
                            },
                          }}
                          value={values.contactForPrice ? "" : values.price}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value.startsWith(".")) {
                              value = "0.";
                            }
                            const decimalIndex = value.indexOf(".");
                            if (decimalIndex !== -1) {
                              value = value.slice(0, decimalIndex + 3);
                            }

                            setFieldValue("price", parseFloat(value));
                          }}
                          InputProps={{
                            readOnly: values.contactForPrice,
                            disableUnderline: true,
                          }}
                          onFocus={handleFocus}
                          inputRef={inputRef}

                          error={touched.price && Boolean(errors.price)}
                        />
                        <FormGroup  sx={{ ...commonStyles.inputStyle, padding: "9px 16px !important",  }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.contactForPrice}
                                name="contactForPrice"
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("contactForPrice", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("price", "");
                                  }
                                }}
                                onBlur={handleBlur}
                              />
                            }
                            label="Contact for price"
                            componentsProps={{
                              typography: {
                                sx: {
                                  fontSize: "12px",
                                  color: theme.palette.dark.colorCart,
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                },
                              },
                            }}
                          />
                        </FormGroup>
                      </Stack>
                      <ErrorMessage
                        name="price"
                        component="div"
                        style={{ color: "red", marginTop: "4px" }}
                      />
                    </Box>

                    <Divider />

                    {/* Local Pickup Section */}
                    {/* <Stack gap={1} sx={{ marginY: "10px", paddingY: "10px" }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={commonStyles.switchStack}
                      >
                        <Typography variant="body1" sx={commonStyles.infoText}>
                          Local pickup
                        </Typography>
                        <IOSSwitch
                          checked={values.isLocalAddress}
                          onChange={(event) =>
                            setFieldValue(
                              "isLocalAddress",
                              event.target.checked
                            )
                          }
                          onBlur={handleBlur}
                          name="isLocalAddress"
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        gap={1}
                        sx={{
                          width: {
                            xs: "100%",
                          },
                        }}
                      >
                        <GoDotFill
                          size="17px"
                          color={theme.palette.dark.dustyBlue}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            color: theme.palette.dark.dustyBlue,
                            fontStyle: "italic",
                            fontWeight: "500",
                          }}
                        >
                          By selecting the local pickup option Users need to
                          know business or house address to pickup items
                        </Typography>
                      </Stack>
                    </Stack>

                    <Divider /> */}

                    {/* Shipping Address Section */}
                    <Stack gap={1} sx={{ marginY: "10px" }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          sx={{ ...commonStyles.labelText, marginLeft: "7px" }}
                        >
                          shipping address
                        </Typography>
                        <Button
                          onClick={() =>
                            setChooseAddressEnabled(!chooseAddressEnabled)
                          }
                          variant="outlined"
                          sx={{
                            textTransform: "capitalize",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {chooseAddressEnabled ? "Cancel" : "Choose Address"}
                        </Button>
                      </Stack>
                      {chooseAddressEnabled && (
                        <>
                          <ShippingAddressSelector
                            currentUser={userData}
                            onSelectAddress={(address) =>
                              setSelectedAddressId(address?.id)
                            }
                            isModalOpen={isNewAddressModalOpen}
                            setIsModalOpen={setIsNewAddressModalOpen}
                            selectValue={addressSelectorMode}
                            selectedAddress={selectedAddress}
                            setSelectedAddress={setSelectedAddress}
                          />
                          <Button
                            variant="outlined"
                            onClick={handleNewAddressModalOpen}
                            sx={{
                              width: "fit-content",
                              margin: "10px 0",
                              textTransform: "capitalize",
                            }}
                          >
                            add new address
                          </Button>
                        </>
                      )}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          padding: "8px 16px",
                          borderRadius: "8px",
                          boxShadow: `0px 6px 30px 0px ${theme.palette.customShadows.darkBlack}`,
                          marginY: "10px",
                        }}
                      >
                        <Stack direction="row" alignItems="center" gap={1.5}>
                          <img
                            loading="lazy"
                            src={map}
                            alt="map"
                            style={{ objectFit: "contain" }}
                          />
                          <Stack justifyContent="center" gap={0.5}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: theme.palette.dark.colorCart,
                                fontWeight: "700",
                              }}
                            >
                              {chooseAddressEnabled
                                ? selectedAddress?.name ||
                                formAddServiceData?.businessAddress?.name
                                : formAddServiceData?.businessAddress?.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.gray.colorSummary,
                                fontWeight: "500",
                              }}
                            >
                              {/* {
                                formAddServiceData?.businessAddress
                                  ?.addressLineOne
                              } */}
                              {formattedAddress || "Address not available"}
                            </Typography>
                            {chooseAddressEnabled &&
                              (selectedAddress?.addressLineTwo || formAddServiceData?.businessAddress?.addressLineTwo) && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: theme.palette.gray.colorSummary,
                                    fontWeight: "500",
                                  }}
                                >
                                  Apt/Suite :{" "}
                                  {selectedAddress?.addressLineTwo || formAddServiceData?.businessAddress?.addressLineTwo}
                                </Typography>
                              )}
                            {!chooseAddressEnabled &&
                              formAddServiceData?.businessAddress
                                ?.addressLineTwo && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: theme.palette.gray.colorSummary,
                                    fontWeight: "500",
                                  }}
                                >
                                  Apt/Suite  :{" "}
                                  {
                                    formAddServiceData?.businessAddress
                                      ?.addressLineTwo
                                  }
                                </Typography>
                              )}
                          </Stack>
                        </Stack>

                        {/* <Box sx={{ cursor: "pointer" }}>
                          <BiEditAlt
                            size="24px"
                            color={theme.palette.neutral.lightGreyIcon}
                            onClick={handleEditOpen}
                          />
                          <MuiModal
                            open={editAddress}
                            onClose={handleEditClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={{ ...modalStyle, borderRadius: "8px" }}>
                              <EditAddressModal
                                setEditAddress={setEditAddress}
                                ServiceAddress={
                                  formAddServiceData.businessAddress
                                }
                                handleAddressSubmit={handleAddressSubmit}
                                handleEditClose={handleEditClose}
                              />
                            </Box>
                          </MuiModal>
                        </Box> */}
                      </Stack>
                    </Stack>

                    <Stack
                      direction={{
                        xs: "column",
                        sm: "row",
                      }}
                      justifyContent="space-between"
                    >
                      {/* edit Button */}

                      <Stack
                        direction={{
                          xs: "column",
                          sm: "row",
                        }}
                        gap={{
                          xs: 0,
                          sm: 3,
                        }}
                      >
                        <Button
                          onClick={() => {
                            dispatch(setGoBackEditService(true));
                            navigate(-1);
                            dispatch(
                              setFormAddServiceData({
                                price: values.price,
                                contactForPrice: values.contactForPrice,
                                isOnline: values.contactForPrice
                                  ? false
                                  : values.isOnline,
                                isLocalAddress: values.isLocalAddress,
                              })
                            );
                          }}
                          variant="outlined"
                          sx={{
                            ...commonStyles.buttonStyle,
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.main,
                          }}
                        >
                          Go Back
                        </Button>
                        <Button
                          disabled={isLoading}
                          onClick={() => setButtonClicked("addToDraft")}
                          type="submit"
                          variant="outlined"
                          sx={{
                            ...commonStyles.buttonStyle,
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.main,
                          }}
                        >
                          {isLoading && buttonClicked === "addToDraft" ? (
                            <CircularProgress size={24} />
                          ) : (
                            "edit as Draft"
                          )}
                        </Button>
                      </Stack>
                      <Button
                        disabled={isLoading}
                        onClick={() => setButtonClicked("listService")}
                        type="submit"
                        variant="outlined"
                        sx={commonStyles.buttonStyle}
                      >
                        {isLoading && buttonClicked === "listService" ? (
                          <CircularProgress size={24} color="secondary" />
                        ) : (
                          "edit as active"
                        )}
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default EditServiceBillingDetails;
