import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal as MuiModal,
  Stack,
  Typography,
} from "@mui/material";
import StatusDisplay from "../../../components/common/StatusDisplay";
import { useSelector } from "react-redux";
import { Modal as AntModal } from "antd";
import ReusableAddressModal from "../../../components/common/ReusableAddressModal";
import LocationMapModal from "../../../components/common/LocationMapModal";
import { useNavigate } from "react-router-dom";
import { db } from "../../../configs/firebase";
import { getDoc, doc } from "firebase/firestore";

const Address = ({
  theme,
  sectionStyles,
  headerTextStyles,
  order,
  setOrder,
}) => {
  const textStyles = {
    label: {
      color: theme.palette.neutral.SlateGray,
      fontWeight: 500,
      textTransform: "capitalize",
      lineHeight: "18.3px",
    },
    value: {
      color: theme.palette.neutral.greyBlue,
      fontSize: "14px",
    },
    header: {
      ...headerTextStyles,
      fontWeight: 600,
      textTransform: "capitalize",
    },
    paymentMethod: {
      color: theme.palette.neutral.MoustacheGrey,
      fontSize: "16px",
      textTransform: "capitalize",
    },
  };

  const navigate = useNavigate();
  const [editAddress, setEditAddress] = useState(false);
  const [buyerName, setBuyerName] = useState(null);
  const [sellerName, setSellerName] = useState(null);
  const isFormTouched = useSelector((state) => state.appState.isFormTouched);
  const userData = useSelector((state) => state.appState.userData);
  const [docId, setDocId] = useState(order?.id);
  const phoneNumberWithPrefix = `+1${order?.buyer_info?.phone_number}`;

  const handleEditOpen = () => setEditAddress(true);

  // Ant Design confirmation modal
  const showConfirm = () => {
    AntModal.confirm({
      title: "Unsaved Changes",
      content:
        "You have unsaved changes. Are you sure you want to close without saving?",
      okText: "Yes, close",
      cancelText: "Cancel",
      onOk() {
        setEditAddress(false);
      },
    });
  };
  // Function to handle modal close
  const handleEditClose = () => {
    if (isFormTouched) {
      showConfirm();
    } else {
      setEditAddress(false);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async (userId, isBuyer) => {
      try {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData?.userType === "buyer") {
            return `${userData?.firstName} ${userData?.lastName}`;
          } else if (
            userData?.userType === "seller" ||
            userData?.userType === "serviceProvider" ||
            userData?.userType === "all"
          ) {
            return userData?.businessInfo?.name;
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    const fetchNames = async () => {
      if (order) {
        if (order?.buyer_info?.id) {
          const name = await fetchUserInfo(order.buyer_info?.id, true);
          setBuyerName(name);
        }

        if (order?.seller_info?.id) {
          const name = await fetchUserInfo(order?.seller_info?.id, false);
          setSellerName(name);
        }
      }
    };

    fetchNames();
  }, [order]);
console.log(order, 'oooooooooo')
  const goToSellerPage = (uid) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/sellers/seller-details/${uid}`);
  };

  const [isMapOpen, setMapOpen] = useState(false);
  const handleOpenMap = () => setMapOpen(true);
  const handleCloseMap = () => setMapOpen(false);
  return (
    <Stack gap={2} sx={sectionStyles}>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "600", textTransform: "capitalize" }}
        >
          {order?.seller_id === userData?.uid ? "purchased by:" : "sold by:"}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.neutral.MoustacheGrey,
            fontWeight: "600",
            cursor: "pointer",
          }}
          onClick={() =>
            goToSellerPage(
              order?.seller_id === userData?.uid
                ? order?.buyer_info?.id
                : order?.seller_info?.id
            )
          }
        >
          {/* {order?.seller_id === userData?.uid
            ? buyerName?.length > 20
              ? `${buyerName?.substring(0, 20)}...`
              : buyerName
            : sellerName?.length > 20
            ? `${sellerName?.substring(0, 20)}...`
            : sellerName} */}
          {order?.seller_id === userData?.uid
            ? order?.buyer_info?.name?.length > 20
              ? `${order?.buyer_info?.name.substring(0, 20)}...`
              : order?.buyer_info?.name
            : order?.seller_info?.fetchNames?.length > 20
            ? `${(order?.seller_info?.businessName).substring(0, 20)}...`
            : order?.seller_info?.businessName}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" sx={textStyles.header}>
          Delivering Address
        </Typography>
        {/* {userData.userType === "buyer" && order?.status !== "cancelled" && (
          <Box>
            <Button
              onClick={handleEditOpen}
              sx={{
                color: theme.palette.primary.main,
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              Edit
            </Button>
            <MuiModal
              open={editAddress}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{ ...modalStyle, borderRadius: "8px" }}>
             
                <ReusableAddressModal
                  initialData={order}
                  firebaseEnabled={true} 
                  docId={docId} 
                  setOrder={setOrder} 
                  handleEditClose={handleEditClose}
                  setEditAddress={setEditAddress}
                />
              </Box>
            </MuiModal>
          </Box>
        )} */}
      </Stack>

      {order && (
        <>
          {/* Address and phone number details */}
          <Stack gap={1}>
            {order?.delivery_address?.addressLineOne && (
              <Stack direction="row" gap={1}>
                <Typography variant="body2" sx={textStyles.label}>
                  Address:
                </Typography>
                <Box
                  component="span"
                  sx={{ ...textStyles.value, cursor: "pointer" }}
                  onClick={handleOpenMap}
                >
                  {[
                    order?.delivery_address?.addressLineOne,
                    order?.delivery_address?.addressLineTwo,
                    order?.delivery_address?.city,
                    order?.delivery_address?.state,
                    order?.delivery_address?.country,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </Box>
                <LocationMapModal
                  open={isMapOpen}
                  onClose={handleCloseMap}
                  lat={order?.delivery_address?.lat}
                  lng={order?.delivery_address?.lng}
                  address={order?.delivery_address}
                />
              </Stack>
            )}
            {order?.buyer_info?.phone_number && (
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body2" sx={textStyles.label}>
                  Phone Number:
                </Typography>
                <Box component="span" sx={textStyles.value}>
                  <a
                    href={`tel:${phoneNumberWithPrefix}`}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    +1 {order?.buyer_info?.phone_number}
                  </a>
                </Box>
              </Stack>
            )}
          </Stack>

          {/* Payment Method */}
          {order?.payment_method && (
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body1" sx={textStyles.header}>
                Payment Method:
              </Typography>
              <Box component="span" sx={textStyles.paymentMethod}>
                {order?.payment_method}
              </Box>
            </Stack>
          )}

          {/* Status
          <StatusDisplay status={order?.status} /> */}
        </>
      )}
      {!order && (
        <Typography variant="body2">
          There is no address data to show
        </Typography>
      )}
    </Stack>
  );
};

export default Address;
