import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
let config;
if (process.env.REACT_APP_ENV === "production") {
  config = {
    apiKey: "AIzaSyACt7O8TPdOnqIEkD7-aARon_mo51_eSaY",
    authDomain: "truckparts-prod.firebaseapp.com",
    projectId: "truckparts-prod",
    storageBucket: "truckparts-prod.appspot.com",
    messagingSenderId: "592235954107",
    appId: "1:592235954107:web:2066ea30c6ccd14988ce9e",
    measurementId: "G-E9NJKW0FPE"
  };
} else {
  config = {
    apiKey: "AIzaSyDhIw5QMf2BdmUVE6Uqg4iXQmqCnyD_9qg",
    authDomain: "truckparts-dev.firebaseapp.com",
    projectId: "truckparts-dev",
    storageBucket: "truckparts-dev.appspot.com",
    messagingSenderId: "550727437760",
    appId: "1:550727437760:web:285f32b55d5cd545044c25",
    measurementId: "G-7HWNFWBZPV",
  };
}

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); 
}

// firestore
const db = firebase.firestore();
//auth
const auth = firebase.auth();
// storage
const storageSdk = firebase.storage();
const field =firebase.firestore.FieldPath.documentId();
const firestoreFieldValue = firebase.firestore.FieldValue;
const firestoreTimeStamp = firebase.firestore.Timestamp;

export { auth, db,storageSdk,firestoreFieldValue,firestoreTimeStamp, field };