import React from "react";
import { CircularProgress, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Loader = ({ color,height="auto" }) => {
  const theme = useTheme();
  const loaderColor = color || theme.palette.primary.main; 

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height,
      }}
    >
      <CircularProgress size={30} sx={{ color: loaderColor }} />
    </Box>
  );
};

export default Loader;
