import { BASE_URL, STRIPE_PAYOUT_ENDPOINT } from "../../../../../components/layout/constants";
import { auth } from "../../../../../configs/firebase";
import { message } from "antd";

export const createStripePayout = async (payoutId, uid) => {
  try {
    const currentUser = auth?.currentUser;
    if (!currentUser) throw new Error("User is not authenticated");

    const userToken = await currentUser.getIdToken();
    const url = `${BASE_URL}${STRIPE_PAYOUT_ENDPOINT}`;
    const headers = {
      "Content-Type": "application/json",
      authorization: userToken,
    };

    const body = JSON.stringify({ uid, payoutId });

    const response = await fetch(url, {
      method: "POST",
      headers,
      body,
    });

    const responseData = await response.json();
 
    if (!response.ok) {
      const errorMessage = responseData?.error?.details?.message || "Error creating Stripe payout";
      throw new Error(errorMessage); 
    }

    // console.log("Stripe Payout Response:", responseData);
    // message.success("Stripe payout created successfully!");
    return responseData;
  } catch (error) {
 console.log(error, 'error')
    message.error(error.details.message || "An unexpected error occurred. Please try again.");
  }
};
