import React from "react";
import {
  Box,
  useTheme,
  ListItemText,
  ListItem,
  List,
  Button,
} from "@mui/material";

const ItemManagementTabs = React.memo(
  ({
    activeItemsCount,
    soldItemsCount,
    draftItemsCount,
    activeServicesCount,
    draftServicesCount,
    selectedType,
    onTabChange,
    onTypeChange,

    listState,
  }) => {
    const theme = useTheme();

    const renderListItem = (type, label, count) => {
      
      return (
        <ListItem
          onClick={() => handleNavigation(type)}
          sx={{
            mb: 2,
            backgroundColor:
              listState === type ? theme.palette.primary.main : "none",
            border: `1px solid ${theme.palette.gray.border}`,
            borderRadius: "8px",
            cursor: "pointer",
            padding: "16px",
            color:
              listState === type
                ? theme.palette.primary.contrastText
                : theme.palette.primary.darkMain,
            fontWeight: 700,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.light,
            },
          }}
        >
          <ListItemText
            primary={`${label} (${count})`}
            primaryTypographyProps={{
              sx: { fontWeight: 700, textAlign: "center" },
            }}
          />
        </ListItem>
      );
    };

    const handleNavigation = (type) => {
      onTabChange(type)
    };

    const handleTypeChange = (type) => {
      onTypeChange(type);
    };

    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Toggle between Items and Services */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "72px",
            padding: "4px",
            gap: "7px",
          }}
        >
          <Button
            onClick={() => handleTypeChange("items")}
            sx={{
              backgroundColor:
                selectedType === "items"
                  ? theme.palette.primary.light
                  : "transparent",
              color:
                selectedType === "items"
                  ? theme.palette.primary.main
                  : theme.palette.primary.light,
              fontWeight: 700,
              padding: "6px 50px",
              textTransform: "capitalize",
              borderRadius: selectedType === "items" ? "40px" : "0px",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                borderRadius: "40px",
              },
            }}
          >
            Parts
          </Button>
          <Button
            onClick={() => handleTypeChange("services")}
            sx={{
              backgroundColor:
                selectedType === "services"
                  ? theme.palette.primary.light
                  : "transparent",
              color:
                selectedType === "services"
                  ? theme.palette.primary.main
                  : theme.palette.primary.light,
              fontWeight: 700,
              padding: "6px 50px",
              textTransform: "capitalize",
              borderRadius: selectedType === "services" ? "40px" : "0px",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                borderRadius: "40px",
              },
            }}
          >
            Services
          </Button>
        </Box>

        {/* Items Section */}
        {selectedType === "items" && (
          <Box sx={{ mt: 2 }}>
            <List sx={{ mt: 3 }}>
              {renderListItem("activeItems", "Active parts", activeItemsCount)}
              {renderListItem("sold", "Sold parts", soldItemsCount)}
              {renderListItem("draft", "Draft parts", draftItemsCount)}
            </List>
          </Box>
        )}

        {/* Services Section */}
        {selectedType === "services" && (
          <Box sx={{ mt: 2 }}>
            <List>
              {renderListItem(
                "activeService",
                "Active Services",
                activeServicesCount
              )}
              {renderListItem(
                "draftService",
                "Draft Services",
                draftServicesCount
              )}
            </List>
          </Box>
        )}
      </Box>
    );
  }
);
export default ItemManagementTabs;
