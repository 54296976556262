import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  Typography,
  Button,
  Chip,
  Slider,
  Checkbox,
} from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import CollapsibleSection from "./CollapsibleSection";
import { IoIosClose } from "react-icons/io";
import { db } from "../../../configs/firebase";
import { RiMenu3Fill } from "react-icons/ri";
import {
  setItemsCategories,
  setServicesCategories,
} from "../../../redux/slices/appStateSlice";
import StreetAddress from "../../../components/common/StreetAddress";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { IoClose, IoCloseCircle } from "react-icons/io5";

const FilterSidebar = React.memo(
  ({
    onSearchChange = "",
    selectedName,
    setSelectedName = () => {},
    type,
    setSortOrderPrice = null,
    setSelectedIdCatOrServ = null,
    setSelectedNameCatOrServ = null,
    setMinPriceAlgolia = 0,
    setMaxPriceAlgolia = 0,
    onLocationChange = null,
    onRadiusChange = null,
    activeViewType = "items",
    setActiveViewType = null,
    passClearFilters = () => {},
    passClearAll = () => {},
    setListingNearMe = () => {},
    listingNearMe = null,
    openLocationModal = () => {},
    selectedIdCatOrServ,
    selectedFilters,
    setSelectedFilters = () => {},
    updateSelectedFilters = () => {},
    setSortOrderControle = () => {},
    sortOrder,
    setSortOrder = () => {},
  }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const pageType = queryParams.get("page") || "items";
    const currentView = queryParams.get("view") || "list";
    const isListMapPage = location.pathname === "/explore";

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const displayAllCategories = useSelector(
      (state) => state.appState.itemsCategories
    );
    const [categories, setCategories] = useState(
      displayAllCategories.length > 0 ? displayAllCategories : []
    );
    const displayAllServices = useSelector(
      (state) => state.appState.servicesCategories
    );

    const [services, setServices] = useState(
      displayAllServices.length > 0 ? displayAllServices : []
    );
    const [highestPrice, setHighestPrice] = useState(0);
    const [selectedId, setSelectedId] = useState(null);

    const [sliderValues, setSliderValues] = useState([0, 0]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [address, setAddress] = useState("");
    const [addressToShow, setAddressToShow] = useState("");
    const [showClearFilters, setShowClearFilters] = useState(false);
    const [showRadius, setShowRadius] = useState(false);
    const [radius, setRadius] = useState(500);
    const [latLng, setLatLng] = useState({ lat: null, lng: null });
    const [isAddressFocused, setIsAddressFocused] = useState(false);

    const handleSelectStreet = async (address, placeId, suggestion) => {
      try {
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);

        const selectedPlaceData = {
          city:
            results[0]?.address_components.find((component) =>
              component.types.includes("locality")
            )?.long_name || "",
          state:
            results[0]?.address_components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.short_name || "",
          country:
            results[0]?.address_components.find((component) =>
              component.types.includes("country")
            )?.long_name || "",
          zipCode:
            results[0]?.address_components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "",
          lat: latLng.lat,
          lng: latLng.lng,
          placeId: placeId || "",
          addressLineOne: address || "",
        };

        setAddress(selectedPlaceData);
        onLocationChange({ lat: latLng.lat, lng: latLng.lng });
        setAddressToShow(selectedPlaceData?.addressLineOne);

        window.scrollTo({ top: 100, behavior: "smooth" });
        setShowClearFilters(true);
      } catch (error) {
        console.error("Error selecting address", error);
      }
    };

    const handleClearFilters = () => {
      setAddress("");
      setAddressToShow("");
      setShowClearFilters(false);
      setShowRadius(false);
      setIsAddressFocused(false);
      setRadius(500);
      setLatLng({ lat: null, lng: null });
      onLocationChange({ lat: null, lng: null });
      onRadiusChange(Math.round(500 * 1609.34));
      setSortOrderControle("default");
    };

    const handleClearAll = () => {
      if (isListMapPage) {
        setSelectedFilters([]);
        setSelectedId(null);
        setSelectedName("");
      } else {
        setSelectedFilters((prevFilters) => {
          return prevFilters.filter(
            (filter) =>
              categories.some((category) => category.id === filter) ||
              services.some((service) => service.id === filter)
          );
        });

        setSelectedId((prevId) => {
          const isCategoryOrService =
            categories.some((category) => category.id === prevId) ||
            services.some((service) => service.id === prevId);
          return isCategoryOrService ? prevId : null;
        });
      }

      setSortOrder(null);
      setListingNearMe(null);
      setMinPrice(0);
      setMaxPrice(highestPrice);
      setSliderValues([0, highestPrice]);
      setSelectedNameCatOrServ("");
      setSortOrderControle("default");
      if (isListMapPage) {
        const matchedCategory = categories.find(
          (category) => category.name === selectedName
        );
        const matchedService = services.find(
          (service) => service.name === selectedName
        );
        const matchedId = matchedCategory?.id || matchedService?.id || null;

        if (pageType === "items") {
          navigate(`/explore?page=items&view=${currentView}`);
        } else {
          navigate(`/explore?page=services&view=${currentView}`);
        }

        if (matchedId) {
          setSelectedId(matchedId);
        }
      }
    };

    useEffect(() => {
      if (selectedFilters?.length === 0) {
        setSelectedId(null);
      }
    }, [selectedFilters]);
    useEffect(() => {
      passClearFilters(handleClearFilters);
      passClearAll(handleClearAll);
    }, []);

    const handleAddressChange = (newAddress) => {
      setAddressToShow(newAddress);
      setIsAddressFocused(!!newAddress);
      if (!newAddress) {
        handleClearFilters();
      }
    };

    const handleRadiusChange = (event, newRadius) => {
      const newRadiusInMeters = Math.round(newRadius * 1609.34);
      setRadius(newRadius);
      onRadiusChange(newRadiusInMeters);
    };

    useEffect(() => {
      if (displayAllCategories.length === 0) {
        getCategories();
      } else {
        setCategories(displayAllCategories);
      }
    }, [displayAllCategories]);

    const getCategories = async () => {
      setIsLoading(true);
      try {
        const docs = db.collection("itemCategories").orderBy("index", "asc");

        let fetchedCategories = [];
        await docs.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            fetchedCategories.push(doc.data());
          });

          if (fetchedCategories.length > 0) {
            setCategories(fetchedCategories);
            dispatch(setItemsCategories(fetchedCategories));
          }
        });
      } catch (error) {
        console.error("Error fetching categories:", error);
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (displayAllServices.length === 0) {
        getServices();
      } else {
        setServices(displayAllServices);
      }
    }, [displayAllServices]);

    const getServices = async () => {
      setIsLoading(true);
      try {
        const docs = db
          .collection("servicesCategories")
          .orderBy("index", "asc");

        let fetchedServices = [];
        await docs.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            fetchedServices.push(doc.data());
          });

          if (fetchedServices.length > 0) {
            setCategories(fetchedServices);
            dispatch(setServicesCategories(fetchedServices));
          }
        });
      } catch (error) {
        console.error("Error fetching categories:", error);
        setIsLoading(false);
      }
    };

    const toggleDrawer = (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };

    useEffect(() => {
      if (maxPrice) {
        setMaxPriceAlgolia(maxPrice);
      } else {
        setMaxPriceAlgolia(null);
      }
    }, [maxPrice, setMaxPriceAlgolia]);

    useEffect(() => {
      if (minPrice) {
        setMinPriceAlgolia(minPrice);
      } else {
        setMinPriceAlgolia(null);
      }
    }, [minPrice, setMinPriceAlgolia]);

    useEffect(() => {
      if (sortOrder) {
        setSortOrderPrice(sortOrder);
      } else {
        setSortOrderPrice(null);
      }
    }, [sortOrder, setSortOrderPrice]);

    useEffect(() => {
      if (selectedId) {
        setSelectedIdCatOrServ(selectedId);
      } else {
        setSelectedIdCatOrServ(null);
      }
    }, [setSelectedIdCatOrServ, selectedId]);

    const handleSearchChange = (event) => {
      const searchTerm = event.target.value.trim();
      if (searchTerm === "") {
        onSearchChange("");
      } else if (event.key === "Enter" && searchTerm) {
        onSearchChange(searchTerm);
      }
    };

    const handleRemoveFilter = (id) => {
      // Only allow category or service removal if on `isListMapPage`
      if (!isListMapPage) {
        const isCategoryOrService =
          categories.some((category) => category.id === id) ||
          services.some((service) => service.id === id);
        if (isCategoryOrService) {
          return;
        }
      }
      setSelectedFilters((prev) => prev?.filter((filter) => filter !== id));

      if (id === "Listings near me" || id === "Services near me") {
        setListingNearMe(null);
      }

      if (selectedId === id) {
        setSelectedId(null);
        setSelectedIdCatOrServ(null);
        setSelectedNameCatOrServ("");
        if (isListMapPage) {
          // Fetch all items or services again
          setSelectedName("");
          setMinPriceAlgolia(null);
          setMaxPriceAlgolia(null);

          if (pageType === "items") {
            navigate("/explore?page=items");
          } else if (pageType === "services") {
            navigate("/explore?page=services");
          }
        }
      }
      if (id.startsWith("price_")) {
        setSortOrder(null);
        setSortOrderControle("default");
      }
    };

    useEffect(() => {
      if (selectedFilters?.length === 0 && isListMapPage) {
        setSelectedName("");
        setSelectedId(null);
        setSelectedIdCatOrServ(null);
        if (pageType === "items") {
          navigate("/explore?page=items");
        } else {
          navigate("/explore?page=services");
        }
      }
    }, [selectedFilters, isListMapPage]);
    useEffect(() => {
      if (selectedName) {
        const matchedCategory = categories.find(
          (category) => category.name === selectedName
        );
        const matchedService = services.find(
          (service) => service.name === selectedName
        );
        const matchedId = matchedCategory?.id || matchedService?.id || null;
        if (matchedId) {
          setSelectedId(matchedId);
          setSelectedIdCatOrServ(matchedId);
        }
      }
    }, [selectedName, categories, services, setSelectedIdCatOrServ]);
    const navigate = useNavigate();

    const handleSelectFilter = (id) => {
      setSelectedId(id);
      setSelectedIdCatOrServ(id);
      updateSelectedFilters(id);
      const selectedCategory = categories.find(
        (category) => category?.id === id
      );
      const selectedService = services.find((service) => service?.id === id);
      const newSelectedName =
        selectedCategory?.name || selectedService?.name || "";
      if (type === "filtered-categories") {
        navigate(
          `/filtered-categories?category-Id=${id}&category-name=${newSelectedName}&view=${currentView}`
        );
      } else if (type === "filtered-services") {
        navigate(
          `/filtered-services?service-Id=${id}&service-name=${newSelectedName}&view=${currentView}`
        );
      } else if (type === "services") {
        navigate(
          `/explore?page=services&service-Id=${id}&service-name=${newSelectedName}&view=${currentView}`
        );
      } else if (type === "items") {
        navigate(
          `/explore?page=items&item-Id=${id}&item-name=${newSelectedName}&view=${currentView}`
        );
      }

      setSelectedFilters((prev) => {
        // Filter out only the category or service filters, preserving price and listing/service filters
        const updatedFilters = prev?.filter(
          (filter) =>
            !categories?.some((category) => category?.id === filter) &&
            !services?.some((service) => service?.id === filter)
        );

        return [id, ...updatedFilters];
      });

      setSelectedId(id);
      setSelectedNameCatOrServ(newSelectedName); // Set the new selected name
    };

    const handleSortChange = (event) => {
      const value = event.target.value;
      setSortOrder(value);
      setSortOrderControle(value);
      setSelectedFilters((prev) => {
        const updatedFilters = (prev || []).filter(
          (filter) => !filter?.startsWith("price_")
        );
        return [...updatedFilters, value];
      });
    };

    const handleSearchTypeChange = async (event) => {
      const value = event.target.value;

      if (navigator.permissions && navigator.permissions.query) {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });

        if (permissionStatus.state === "denied") {
          message.error(
            "Access to location denied. Please enable location access in your browser settings and try again."
          );
          return;
        }
      } else {
        message.error("Geolocation is not supported in this browser..");
      }

      setListingNearMe(value);
      setSelectedFilters((prev = []) => {
        const existingFilters = Array.isArray(prev) ? prev : [];

        const updatedFilters = existingFilters?.filter(
          (filter) =>
            !filter?.startsWith("listings_") && !filter?.startsWith("services_")
        );

        const newFilterLabel =
          value === "listings_near_me"
            ? "Listings near me"
            : "Services near me";

        return [...updatedFilters, newFilterLabel];
      });

      openLocationModal();
    };

    const getProducts = async () => {
      const collectionName =
        type === "filtered-categories" || type === "items"
          ? "products"
          : "services";
      try {
        const querySnapshot = await db
          .collection(collectionName)
          .orderBy("price", "desc")
          .where("isDeleted", "==", false)
          .limit(1)
          .get();
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const item = doc.data();
          const maxPrice = item.price;

          setSliderValues([0, maxPrice]);
          setHighestPrice(maxPrice);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    useEffect(() => {
      getProducts();
    }, [type]);
    
    
    // const handleSliderChange = (event, newValue) => {
    //   setSliderValues(newValue);
    //   setMinPrice(newValue[0]);
    //   setMaxPrice(newValue[1]);

    //   // Update the filter state with the selected price range
    //   setSelectedFilters((prevFilters) => {
    //     // Remove any existing price filters
    //     const updatedFilters = (prevFilters || []).filter(
    //       (filter) => !filter.startsWith("price_")
    //     );
    //     // Add the new price range filter
    //     return [...updatedFilters, `Price frome $${newValue[0]} to $${newValue[1]}`];
    //   });

    //   // Update Redux or parent state for Algolia filtering
    //   setMinPriceAlgolia(newValue[0]);
    //   setMaxPriceAlgolia(newValue[1]);
    // };

    const handleSliderChange = (event, newValue) => {
      setSliderValues(newValue);
      setMinPrice(newValue[0]);
      setMaxPrice(newValue[1]);
    
      // Update the filter state with the selected price range
      setSelectedFilters((prevFilters) => {
        // Remove any existing price filters
        const updatedFilters = (prevFilters || []).filter(
          (filter) => !filter.startsWith("Price from")
        );
    
        // Add the new price range filter if it's not already included
        const priceRange = `Price from $${newValue[0]} to $${newValue[1]}`;
        if (!updatedFilters.includes(priceRange)) {
          updatedFilters.push(priceRange);
        }
    
        return updatedFilters;
      });
    
      // Update Redux or parent state for Algolia filtering
      setMinPriceAlgolia(newValue[0]);
      setMaxPriceAlgolia(newValue[1]);
    };
    
    // Array of sorting options
    const sortOptions = [
      { label: "Price: Low to High", value: "price_asc" },
      { label: "Price: High to Low", value: "price_desc" },
    ];

    const getSortOptions = (type) => {
      if (type === "filtered-categories" || type === "items") {
        return [{ label: "Listings near me", value: "listings_near_me" }];
      } else if (type === "services" || type === "filtered-services") {
        return [{ label: "Services near me", value: "services_near_me" }];
      }
      return [];
    };
    useEffect(() => {
      if (selectedIdCatOrServ) {
        setSelectedId(selectedIdCatOrServ);
      }
    }, [selectedIdCatOrServ]);

    return (
      <div style={{ height: "100%" }}>
        {/* {isSmallScreen && !isListMapPage && ( */}
        {isSmallScreen && (
          <Box>
            <TextField
              placeholder="Search"
              onChange={handleSearchChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearchChange(event);
                }
              }}
              sx={{
                background: theme.palette.primary.light,
                width: "100%",
                height: "48px",
                fontSize: "16px",
                borderRadius: "10px",
                outline: "none",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                },
                "& .MuiInputBase-input": {
                  padding: "0px",
                  height: "48px",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.primary.light}`,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CiSearch
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "30px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        {isListMapPage && isSmallScreen && (
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              padding: "6px",
              borderRadius: "30px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Toggle between Items and Services */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                backgroundColor: theme.palette.primary.main,
                borderRadius: "72px",

                gap: "7px",
              }}
            >
              <Button
                onClick={() => setActiveViewType("items")}
                sx={{
                  backgroundColor:
                    activeViewType === "items"
                      ? theme.palette.primary.light
                      : "transparent",
                  color:
                    activeViewType === "items"
                      ? theme.palette.primary.main
                      : theme.palette.primary.light,
                  fontWeight: 700,
                  padding: "6px 25px",
                  textTransform: "capitalize",
                  borderRadius: "40px",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                    borderRadius: "40px",
                  },
                }}
              >
                Items
              </Button>
              <Button
                onClick={() => setActiveViewType("services")}
                sx={{
                  backgroundColor:
                    activeViewType === "services"
                      ? theme.palette.primary.light
                      : "transparent",
                  color:
                    activeViewType === "services"
                      ? theme.palette.primary.main
                      : theme.palette.primary.light,
                  fontWeight: 700,
                  padding: "6px 25px",
                  textTransform: "capitalize",
                  borderRadius: "40px",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                    borderRadius: "40px",
                  },
                }}
              >
                Services
              </Button>
            </Box>
          </Box>
        )}
        {!isLargeScreen && (
          <Box
            sx={{
              textAlign: "end",
              position: "relative",
              top: "56px",
              left: isListMapPage ? (isSmallScreen ? "auto" : "-195px") : "0",
              color: theme.palette.primary.main,
            }}
          >
            <IconButton
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              edge="start"
            >
              <RiMenu3Fill />
            </IconButton>
          </Box>
        )}

        <Drawer
          anchor="left"
          open={isLargeScreen || isDrawerOpen}
          onClose={toggleDrawer(false)}
          variant={isLargeScreen ? "permanent" : "temporary"}
          PaperProps={{
            sx: isLargeScreen
              ? {
                  position: "relative",
                  overflow: "hidden",
                  zIndex: "900 !important",
                  height: isAddressFocused ? "300vh" : "auto",
                  padding: "30px",
                  marginRight: "15px",
                  borderRadius: "15px",
                  top: "-15px",
                }
              : { padding: "20px", overflowX: "hidden" },
          }}
        >
          <div role="presentation">
            {!isLargeScreen && (
              <Box display="flex" justifyContent="flex-end" width="100%">
                <IconButton
                  onClick={() => setIsDrawerOpen(false)}
                  sx={{ backgroundColor: theme.palette.gray.btnPrice }}
                >
                  <IoClose
                    style={{
                      fontSize: "24px",
                      color: theme.palette.primary.text,
                    }}
                  />
                </IconButton>
              </Box>
            )}
            <Box>
              <Box mb={2}>
                {selectedFilters?.map((filter) => (
                  <Chip
                    key={filter}
                    label={
                      filter === "price_asc"
                        ? "Price: Low to High"
                        : filter === "price_desc"
                        ? "Price: High to Low"
                        : filter === "listings_near_me"
                        ? "Listings near me"
                        : filter === "services_near_me"
                        ? "Services near me"
                        : categories.find((category) => category.id === filter)
                            ?.name ||
                          services.find((service) => service.id === filter)
                            ?.name ||
                          filter // Display filter name if it's not a sortOrder
                    }
                    onDelete={() => handleRemoveFilter(filter)}
                    deleteIcon={
                      <IoIosClose
                        style={{ color: theme.palette.primary.light }}
                      />
                    }
                    sx={{
                      margin: "4px",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                    }}
                  />
                ))}
                {selectedName && selectedFilters?.length === 0 && (
                  <Chip
                    label={selectedName}
                    sx={{
                      margin: "4px",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                    }}
                  />
                )}
              </Box>
              {selectedFilters?.length > 0 ? (
                <Button
                  onClick={handleClearAll}
                  sx={{
                    marginBottom: "15px",
                    color: theme.palette.primary.main,
                  }}
                >
                  Clear All
                </Button>
              ) : null}
            </Box>
            {/* {!isListMapPage && ( */}
              <Box>
                <TextField
                  placeholder="Search"
                  onChange={handleSearchChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSearchChange(event);
                    }
                  }}
                  sx={{
                    background: theme.palette.primary.light,
                    width: "100%",
                    height: "48px",
                    fontSize: "16px",
                    borderRadius: "10px",
                    outline: "none",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "0px",
                      height: "48px",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.primary.light}`,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CiSearch
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: "30px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            {/* )} */}
            {isListMapPage && (
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "6px",
                  borderRadius: "30px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  marginTop: '20px'
                }}
              >
                {/* Toggle between Items and Services */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "72px",

                    gap: "7px",
                  }}
                >
                  <Button
                    onClick={() => setActiveViewType("items")}
                    sx={{
                      backgroundColor:
                        activeViewType === "items"
                          ? theme.palette.primary.light
                          : "transparent",
                      color:
                        activeViewType === "items"
                          ? theme.palette.primary.main
                          : theme.palette.primary.light,
                      fontWeight: 700,
                      padding: "6px 25px",
                      textTransform: "capitalize",
                      borderRadius: "40px",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                        borderRadius: "40px",
                      },
                    }}
                  >
                    Parts
                  </Button>
                  <Button
                    onClick={() => setActiveViewType("services")}
                    sx={{
                      backgroundColor:
                        activeViewType === "services"
                          ? theme.palette.primary.light
                          : "transparent",
                      color:
                        activeViewType === "services"
                          ? theme.palette.primary.main
                          : theme.palette.primary.light,
                      fontWeight: 700,
                      padding: "6px 25px",
                      textTransform: "capitalize",
                      borderRadius: "40px",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                        borderRadius: "40px",
                      },
                    }}
                  >
                    Services
                  </Button>
                </Box>
              </Box>
            )}

            <CollapsibleSection label="Results Type">
              <FormControl component="fieldset" sx={{ marginTop: "20px" }}>
                <RadioGroup
                  aria-label="results-type"
                  name="results-type"
                  value={listingNearMe}
                  onChange={handleSearchTypeChange}
                >
                  {getSortOptions(type).map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <Radio
                          sx={{
                            color: theme.palette.primary.main,
                            "&.Mui-checked": {
                              color: theme.palette.primary.main,
                            },
                          }}
                        />
                      }
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </CollapsibleSection>

            <CollapsibleSection label="Order by" >
              <FormControl component="fieldset" sx={{ marginTop: "20px" }}>
                <RadioGroup
                  aria-label="sort-order"
                  name="sort-order"
                  value={sortOrder}
                  onChange={handleSortChange}
                >
                  {sortOptions?.map((option) => (
                    <FormControlLabel
                      key={option?.value}
                      value={option?.value}
                      control={
                        <Radio
                          sx={{
                            color: theme.palette.primary.main,
                            "&.Mui-checked": {
                              color: theme.palette.primary.main,
                            },
                          }}
                        />
                      }
                      label={option?.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </CollapsibleSection>

            <CollapsibleSection
              label={
                type === "filtered-categories" || type === "items"
                  ? "Categories"
                  : "Services"
              }
            >
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="categories-services"
                  name="categories-services"
                  value={selectedId}
                  onChange={(e) => handleSelectFilter(e.target.value)}
                >
                  {(type === "filtered-categories" || type === "items"
                    ? categories
                    : services
                  ).map((item) => (
                    <FormControlLabel
                      key={item?.id}
                      value={item?.id}
                      control={
                        <Radio
                          sx={{
                            color: theme.palette.primary.main,
                            "&.Mui-checked": {
                              color: theme.palette.primary.main,
                            },
                          }}
                        />
                      }
                      label={item?.name}
                      sx={{
                        padding: "8px 16px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                        backgroundColor:
                          selectedId === item.id
                            ? theme.palette.neutral.background
                            : "transparent",
                        transition: "background-color 0.3s ease-in-out",
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </CollapsibleSection>

            <Box
              sx={{
                margin: "40px 0",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  marginBottom: "20px",
                  fontWeight: 600,
                }}
              >
                Filter by price
              </Typography>
              <Slider
                value={sliderValues}
                min={0}
                max={highestPrice}
                valueLabelDisplay="auto"
                onChange={handleSliderChange}
                valueLabelFormat={(value) => `$${value}`}
              />
              <Box
                sx={{
                  display: "flex",
                  alignIyems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    background: theme.palette.gray.btnPrice,
                    width: "125px",
                    height: "50px",
                    borderRadius: "5px",
                    border: `1px solid ${theme.palette.gray.btnPrice}`,
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: " 10px",
                    color: theme.palette.gray.numPrice,
                  }}
                >
                  ${sliderValues[0]}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    background: theme.palette.gray.btnPrice,
                    width: "125px",
                    height: "50px",
                    borderRadius: "5px",
                    border: `1px solid ${theme.palette.gray.btnPrice}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    paddingRight: " 15px",
                    color: theme.palette.gray.numPrice,
                  }}
                >
                  ${sliderValues[1]}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: "20px",
                    fontWeight: 600,
                  }}
                >
                  Location
                </Typography>
                <Box>
                  {showClearFilters && (
                    <Button
                      onClick={handleClearFilters}
                      variant="outlined"
                      sx={{
                        textAlign: "right",
                        mb: 2,
                        textTransform: "capitalize",
                        borderRadius: "20px",
                      }}
                    >
                      Clear
                    </Button>
                  )}
                </Box>
              </Box>

              <StreetAddress
                handleSelect={(address, placeId) => {
                  handleSelectStreet(address, placeId);
                  setIsAddressFocused(true);
                }}
                setAddress={setAddress}
                address={addressToShow}
                setAddressToShow={setAddressToShow}
                handleAddressChange={handleAddressChange}
                type="(regions)"
                placeholder="Search for zip code or city"
                disabledIcon={true}
                borderColor={`1px solid ${theme.palette.gray.borderFaild}`}
                padding="13px"
                marginTop="-8px"
                backgroundColor={theme.palette.primary.light}
              />
            </Box>
            <Box>
              {showClearFilters && (
                <Box sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showRadius}
                        onChange={(e) => setShowRadius(e.target.checked)}
                      />
                    }
                    label="Custom Radius"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 600,
                      },
                    }}
                  />
                  {showRadius && (
                    <Slider
                      value={radius}
                      min={100}
                      max={1000}
                      step={100}
                      onChange={handleRadiusChange}
                      valueLabelDisplay="auto"
                    />
                  )}
                </Box>
              )}
            </Box>
          </div>
        </Drawer>
      </div>
    );
  }
);

export default FilterSidebar;
