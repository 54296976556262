

import { message } from "antd";
import { auth } from "../../../configs/firebase";
import { BASE_URL, STRIPE_CANCEL_ENDPOINT } from "../../../components/layout/constants";

export const cancelOrder = async (orderId,productId) => {
  try {
    const currentUser = auth?.currentUser;
    if (!currentUser) throw new Error("User is not authenticated");

    const userToken = await currentUser.getIdToken();
    const url = `${BASE_URL}${STRIPE_CANCEL_ENDPOINT}`;
    const headers = {
      "Content-Type": "application/json",
      authorization: userToken,
    };

    const body = JSON.stringify({ orderId,productId });
    const response = await fetch(url, {
      method: "POST",
      headers,
      body,
    });
    const responseData = await response.json();
    if (!response.ok) {
      const errorMessage = responseData?.error?.message || "An unexpected error occurred. Please try again.";
      throw new Error(errorMessage); 
    }

    return responseData;
  } catch (error) {
 
    message.error(error.message || "An unexpected error occurred. Please try again.");
  }
};
