import React from "react";
import { useTheme } from "@mui/material";

const getGradients = (name, theme) => {
  const code = name
    ? name.toUpperCase().charCodeAt(0) +
      (name.length > 1 ? name.toUpperCase().charCodeAt(1) : 0)
    : 0;

  const colors = theme.avatarGradients;

  return colors[code % colors.length];
};

const Avatar = ({
  name = "User",
  goToPage,
  uid,
  userType = null,
  borderRadius = "0",
  fontSize = "12rem",
}) => {
  const theme = useTheme();
  const [startColor, endColor] = getGradients(name, theme);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: `linear-gradient(135deg, ${startColor}, ${endColor})`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: fontSize,
        fontWeight: "bold",
        color: theme.palette.primary.light,
        textTransform: "uppercase",
        cursor: "pointer",
        borderRadius: borderRadius,
      }}
      onClick={() => goToPage(uid, userType)}
    >
      {name ? name.charAt(0) : "U"}
    </div>
  );
};

export default Avatar;
