import { useEffect, useState } from "react";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../../configs/firebase";
import { useSelector } from "react-redux";
import { setCurrentProduct } from "../../../redux/slices/appStateSlice";
import notFound from "../../../assets/Images/nothing_here.png";
import Loader from "../../../components/common/Loader";
import ItemsProducts from "../../../components/common/ItemsProducts";
import CustomPagination from "../../../components/common/CustomPagention";
import { message } from "antd";

const Favorites = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productsPerPage = 6;
  const userData = useSelector((state) => state.appState.userData);

  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const servicesCategories = useSelector(
    (state) => state.appState.servicesCategories
  );

  const getCategoryName = (categoryId) => {
    const category = itemsCategories?.find((cat) => cat?.id === categoryId);
    if (category) return category.name;

    const service = servicesCategories?.find((serv) => serv?.id === categoryId);
    return service ? service.name : "Unknown Category";
  };

  useEffect(() => {
    const loggedIn =
      userData &&
      typeof userData === "object" &&
      userData !== null &&
      userData.uid;

    setIsLoggedIn(!!loggedIn);
  }, [userData]);


  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        setIsLoading(true); // Show loader while fetching
        const userFavoritesRef = collection(
          db,
          "users",
          userData.uid,
          "favoriteProducts"
        );
        const snapshot = await getDocs(userFavoritesRef);
        const favoriteIds = snapshot?.docs?.map(
          (doc) => doc?.data().productModel?.id
        );
        // Fetch updated products from Firestore
        const promises = favoriteIds?.map(async (id) => {
          const productDoc = await getDoc(doc(db, "products", id));
          return productDoc?.exists()
            ? { id: productDoc?.id, ...productDoc?.data() }
            : null;
        });

        const updatedFavorites = (await Promise.all(promises)).filter(
          (product) => product !== null
        );

        setFavoriteProducts(updatedFavorites); // Updated products
      } catch (error) {
        console.error("Error fetching favorite products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFavorites();
  }, [userData]);

  const handleRemoveFromUI = (productId) => {
    setFavoriteProducts((prevFavorites) =>
      prevFavorites.filter((product) => product.id !== productId)
    );
  };

  const handlePageChange = (event, value) => {
    window.scrollTo({ top: 350, behavior: "smooth" });
    setCurrentPage(value);
  };

  const goToProductPage = (id, product, nameToDisplay) => {
    if (product?.isDeleted || product?.isSold || product?.isDraft) {
      message.warning("This product is not available to view.");
      return;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setCurrentProduct(product));
    navigate(`/product/${id}`, { state: { nameToDisplay } });
  };

  const handleEdit = (ProductId) => {
    navigate(`/selling/edit-item/${ProductId}`);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = favoriteProducts?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  return (
    <Grid container spacing={3} sx={{ marginTop: "30px" }}>
      {isLoading ? (
        <Grid
          container
          sx={{
            justifyContent: "center",

            display: "flex",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Loader />
        </Grid>
      ) : currentProducts?.length > 0 ? (
        <Grid
          container
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          {currentProducts?.map((product) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xxl={3}
              key={product?.id}
              sx={{
                marginBottom: 3,
              }}
            >
              <ItemsProducts
                item={product}
                index={product?.id}
                isLoggedIn={isLoggedIn}
                handleEdit={handleEdit}
                goToPage={goToProductPage}
                handleRemoveFromUI={handleRemoveFromUI}
                categoryFavouriteName={getCategoryName(product?.categoryId)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            minHeight: "50vh",
            flexDirection: "column",
          }}
        >
          <img
            src={notFound}
            alt="No data available"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          <Typography variant="body1"> No favorites found</Typography>
        </Grid>
      )}
      {favoriteProducts?.length > productsPerPage && (
        <Grid
          container
          sx={{ margin: "auto", display: "flex", justifyContent: "center" }}
        >
          <CustomPagination
            totalPages={Math.ceil(favoriteProducts.length / productsPerPage)}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Favorites;
