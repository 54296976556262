import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import not_found from "../../assets/Images/not_found.png";
import ItemsControl from "../../components/common/ItemsControl";
import { useLocation, useNavigate } from "react-router-dom";
import FilterSidebar from "../airSystem/components/FilterSidebar";
import { setCurrentProduct } from "../../redux/slices/appStateSlice";
import { message, Modal } from "antd";
import { useDispatch } from "react-redux";
import {
  productsAscIndex,
  productsDescIndex,
  productsIndex,
  productsNearestIndex,
  serviceAscIndex,
  serviceDescIndex,
  serviceIndex,
  servicesNearestIndex,
} from "../../configs/algoliaIndexes";
import ItemsProducts from "../../components/common/ItemsProducts";
import { useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import MapView from "../airSystem/components/MapView";
const ListMap = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const typeFromUrl = queryParams.get("page") || "list";

  const viewFromUrl = queryParams.get("view") || "list";

  const [selectedName, setSelectedName] = useState(
    typeFromUrl === "items"
      ? queryParams.get("item-name")
      : queryParams.get("service-name")
  );
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [radius, setRadius] = useState(Math.round(500 * 1609.34));
  const userData = useSelector((state) => state.appState.userData);
  const [selectedTypeMap, setSelectedTypeMap] = useState(viewFromUrl);
  const [activeViewType, setActiveViewType] = useState("items");
  const [sortOrderControle, setSortOrderControle] = useState("default");
  const [sortOrderPrice, setSortOrderPrice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPriceAlgolia, setMinPriceAlgolia] = useState(null);
  const [maxPriceAlgolia, setMaxPriceAlgolia] = useState(null);
  const [selectedNameCatOrServ, setSelectedNameCatOrServ] = useState("");
  const [selectedIdCatOrServ, setSelectedIdCatOrServ] = useState(null);
  const [categoryIdProducts, setCategoryIdProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [clearFiltersFunction, setClearFiltersFunction] = useState(null);
  const [listingNearMe, setListingNearMe] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
  
  const [clearAllFunction, setClearAllFunction] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userLocation, setUserLocation] = useState({ lat: null, lng: null });
  const [showMap, setShowMap] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const servicesCategories = useSelector(
    (state) => state.appState.servicesCategories
  );
  const [sortOrder, setSortOrder] = useState(null);
  const handleLocationChange = (newLatLng) => setLatLng(newLatLng);
  const handleRadiusChange = (newRadius) => setRadius(newRadius);

  // const updateUrl = (viewType) => {
  //   const currentPage = queryParams.get("page") || "items";
  //   navigate(`/explore?page=${currentPage}&view=${viewType}`);
  // };
  // const handleViewChange = (view) => {
  //   setSelectedTypeMap(view);
  //   updateUrl(view);
  // };
  const updateUrlWithView = (viewType) => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set("view", viewType);
    navigate(`${location.pathname}?${currentParams.toString()}`);
  };
  const handleViewChange = (view) => {
    setSelectedTypeMap(view);
    updateUrlWithView(view);
  };
  useEffect(() => {
    if (!selectedTypeMap) {
      const viewFromUrl = queryParams.get("view") || "list";
      setSelectedTypeMap(viewFromUrl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const pageFromUrl = queryParams.get("Pnumber") || 1;
    setCurrentPage(Number(pageFromUrl));
  }, [location.search]);

  useEffect(() => {
    const loggedIn =
      userData &&
      typeof userData === "object" &&
      userData !== null &&
      userData?.uid;

    setIsLoggedIn(!!loggedIn);
  }, [userData]);

  useEffect(() => {
    if (navigator?.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result?.state === "granted") {
            localStorage.setItem("locationPermission", "granted");
          } else if (result?.state === "denied") {
            localStorage.setItem("locationPermission", "denied");
          }
        })
        .catch((error) => {
          console.error("Error querying permissions:", error);
        });
    } else {
      console.warn("Permissions API is not supported. Falling back.");
      // Handle geolocation directly without permissions API
      handleRequestLocation();
    }
  }, []);

  const handleOpenModal = () => {
    if (navigator?.permissions?.query) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result?.state === "granted") {
            handleRequestLocation();
          } else if (result?.state === "prompt" || result?.state === "denied") {
            setListingNearMe(null);
            message.error(
              "Access to location denied. Please enable location access in your browser settings and try again."
            );
          }
        })
        .catch((error) => {
          console.error("Permission query error:", error);
        });
    } else {
      console.warn(
        "Permissions API is not supported, falling back to geolocation request."
      );
      handleRequestLocation();
    }
  };

  const handleRequestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          if (localStorage.getItem("locationPermission") !== "granted") {
            localStorage.setItem("locationPermission", "granted");
          }
        },
        (error) => {
          if (error.code === 1) {
            // User denied Geolocation
            setListingNearMe(null);
            message.error(
              "Access to location denied. Please enable location access in your browser settings."
            );
          } else {
            console.error("Error fetching location:", error);
            message.error("Failed to retrieve location. Please try again.");
          }
          setIsModalVisible(false);
        }
      );
    } else {
      message.error("Geolocation is not supported by this browser.");
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    const locationPermission = localStorage.getItem("locationPermission");
    if (locationPermission === "granted") {
      handleRequestLocation();
    } else {
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          if (result?.state === "granted") {
            handleRequestLocation();
          }
        });
      } else {
        // Fallback logic if permissions API is not available
        message.error("Geolocation is not supported in this browser.");
      }
    }
  }, []);
  
  
  const handleSetClearFilters = (clearFilters) => {
    setClearFiltersFunction(() => clearFilters);
  };

  const handleSetClearAll = (clearAll) => {
    setClearAllFunction(() => clearAll);
  };

  const handleTypeChange = (type) => {
    setSelectedTypeMap(type);
    // navigate(`?type=${type}`);
  };

  const handleActiveViewType = (type) => {
    setActiveViewType(type);
    setSortOrderControle("default");
    setCurrentPage(1);
    setSortOrderPrice(null);
    setSelectedIdCatOrServ(null);

    setMinPriceAlgolia(null);
    setMaxPriceAlgolia(null);
    setListingNearMe(null);
    setSelectedFilters([]);

    if (clearFiltersFunction) {
      clearFiltersFunction();
    }
    if (clearAllFunction) {
      clearAllFunction();
    }
    navigate(`/explore?page=${type === "items" ? "items" : "services"}`);
  };

  const handleSortOrderChange = (value) => {
    if (value !== "default") {
      setSelectedFilters((prev) => {
        const updatedFilters = (prev || []).filter(
          (filter) => !filter?.startsWith("price_")
        );
        return [...updatedFilters, value];
      });
    }
    if (value === "default") {
      setSelectedFilters((prev) => {
        return (prev || []).filter((filter) => !filter?.startsWith("price_"));
      });
    }
    setSortOrder(value);

    setSortOrderControle(value);
    setCurrentPage(1);
    setSortOrderPrice(null);
  };

  const getIndex = useMemo(() => {
    if (listingNearMe && sortOrderControle === 'default') {
      return activeViewType === "items"
        ? productsNearestIndex
        : servicesNearestIndex;
    } else {
      if (sortOrderControle === "price_asc") {
        return activeViewType === "items" ? productsAscIndex : serviceAscIndex;
      }
      if (sortOrderControle === "price_desc") {
        return activeViewType === "items"
          ? productsDescIndex
          : serviceDescIndex;
      }
      // Default to createdAt descending
      return activeViewType === "items" ? productsIndex : serviceIndex;
    }
  }, [activeViewType, listingNearMe, sortOrderControle]);

  const filter = useMemo(() => {
    let baseFilter = "";

    if (selectedIdCatOrServ) {
      baseFilter = `categoryId:${selectedIdCatOrServ}`;
    }

    const notDeleted = "isActive:true AND isDeleted:false";
    baseFilter = baseFilter ? `${baseFilter} AND ${notDeleted}` : notDeleted;

    if (minPriceAlgolia && maxPriceAlgolia) {
      return `${baseFilter} AND (price:${Number(minPriceAlgolia)} TO ${Number(
        maxPriceAlgolia
      )})`;
    }

    return baseFilter;
  }, [selectedIdCatOrServ, minPriceAlgolia, maxPriceAlgolia]);

 

  const fetchProducts = useCallback(async () => {
    if (!getIndex) {
      console.warn("Missing index or coordinates for Algolia query.");
      return;
    }
    setIsLoading(true);

    try {
      const algoliaQuery = {
        filters: filter,
        hitsPerPage: selectedTypeMap === "map" ? 1000 : 9,
        page: selectedTypeMap === "map" ? 0 : currentPage - 1,
        query: searchTerm || "",
        ...(listingNearMe &&
        !latLng?.lat &&
        !latLng?.lng &&
        userLocation?.lat != null &&
        userLocation?.lng != null
          ? {
              aroundLatLng: `${userLocation?.lat}, ${userLocation?.lng}`,
              aroundRadius: "all",
            }
          : latLng?.lat != null && latLng?.lng != null
          ? {
              aroundLatLng: `${latLng.lat}, ${latLng.lng}`,
              aroundRadius: radius,
            }
          : {}),
      };

    ;

      const { hits, nbPages } = await getIndex.search("", algoliaQuery);
     
     

      setTotalPages(selectedTypeMap === "map" ? 1 : nbPages);
      setCategoryIdProducts(hits);
    } catch (error) {
      console.error(
        "Error fetching data from Algolia:",
        error.message || error
      );
      message.error(
        "An error occurred while fetching data. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    getIndex,
    filter,
    currentPage,
    listingNearMe,
    latLng?.lat,
    latLng?.lng,
    userLocation?.lat,
    userLocation?.lng,
    radius,
    sortOrderPrice,
    sortOrderControle,
    searchTerm,
    selectedTypeMap,
  ]);

  useEffect(() => {
    fetchProducts(currentPage);
  }, [fetchProducts, currentPage]);

  const handlePageChange = (event, value) => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set("Pnumber", value);
    currentParams.set("view", selectedTypeMap);
    navigate(`${location.pathname}?${currentParams.toString()}`);
    setCurrentPage(value);
    fetchProducts(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const goToProductPage = (id, product, nameToDisplay) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setCurrentProduct(product));
    if (activeViewType === "services") {
      navigate(`/service-details/${id}`, { state: { nameToDisplay } });
    } else {
      navigate(`/product/${id}`, { state: { nameToDisplay } });
    }
  };

  const getCategoryName = (categoryId) => {
    const category = itemsCategories?.find((cat) => cat?.id === categoryId);
    return category ? category?.name : "Unknown Category";
  };

  const getServiceName = (categoryId) => {
    const category = servicesCategories?.find(
      (service) => service?.id === categoryId
    );

    return category ? category.name : "Unknown service";
  };
  useEffect(() => {
    const pageFromUrl = queryParams.get("page");
    if (!pageFromUrl) {
      navigate("/explore?page=items", { replace: true });
    } else {
      setActiveViewType(pageFromUrl);
    }
  }, [location.search, navigate]);

  const updateSelectedFilters = (filter) => {
    if (!selectedFilters?.includes(filter)) {
      setSelectedFilters((prev) => [...(prev || []), filter]);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const itemId = queryParams.get("item-Id");
    const serviceId = queryParams.get("service-Id");
    const initialFilter = itemId || serviceId || null;

    setSelectedIdCatOrServ(initialFilter);

    if (initialFilter && !(selectedFilters || []).includes(initialFilter)) {
      setSelectedFilters((prev) => [...(prev || []), initialFilter]);
    }
  }, [location.search]);


  return (
    <section style={{ margin: "0px 25px 0px 25px" }}>
      <Grid container spacing={2}>
        {/* Sidebar */}
        <Grid item xs={12} md={3.4}>
          <FilterSidebar
          onSearchChange={setSearchTerm}
            selectedName={selectedName}
            setSelectedName={setSelectedName}
            type={activeViewType}
            activeViewType={activeViewType}
            sortOrderControle={sortOrderControle}
            setSortOrderControle={setSortOrderControle}
            setSortOrderPrice={setSortOrderPrice}
            setActiveViewType={handleActiveViewType}
            setMinPriceAlgolia={setMinPriceAlgolia}
            setMaxPriceAlgolia={setMaxPriceAlgolia}
            onLocationChange={handleLocationChange}
            onRadiusChange={handleRadiusChange}
            setSelectedNameCatOrServ={setSelectedNameCatOrServ}
            setSelectedIdCatOrServ={setSelectedIdCatOrServ}
            passClearFilters={handleSetClearFilters}
            passClearAll={handleSetClearAll}
            listingNearMe={listingNearMe}
            setListingNearMe={setListingNearMe}
            openLocationModal={handleOpenModal}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            updateSelectedFilters={updateSelectedFilters}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
        </Grid>

        {/* Main Content */}
        <Grid
          item
          xs={12}
          md={8.5}
          sx={{
            background: theme.palette.primary.light,
            padding: 2.2,
            borderRadius: "15px",
          }}
        >
          <ItemsControl
            setSelectedTypeMap={handleTypeChange}
            handleSortOrderChange={handleSortOrderChange}
            selectedTypeMap={selectedTypeMap}
            sortOrderControle={sortOrderControle}
            setShowMap={handleViewChange}
            showMap={selectedTypeMap === "map"}
          />

          {selectedTypeMap === "map" && categoryIdProducts?.length > 0 ? (
            <Grid container>
              <MapView
                products={categoryIdProducts}
                type={activeViewType}
                goToProductPage={goToProductPage}
              />
            </Grid>
          ) : isLoading && !categoryIdProducts?.length ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <Loader />
            </Grid>
          ) : categoryIdProducts?.length > 0 ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {categoryIdProducts?.map((product) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xxl={3}
                  key={product?.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 3,
                  }}
                >
                  <ItemsProducts
                    item={product}
                    index={product?.id}
                    isLoggedIn={isLoggedIn}
                    goToPage={goToProductPage}
                    selectedNameCatOrServ={selectedNameCatOrServ}
                    type={activeViewType}
                    categoryMapName={
                      activeViewType === "items"
                        ? getCategoryName(product?.categoryId)
                        : ""
                    }
                    serviseMapName={
                      activeViewType === "services"
                        ? getServiceName(product?.categoryId)
                        : ""
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <img
                src={not_found}
                alt="No data available"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
          )}
          <Stack
            spacing={2}
            sx={{
              mt: 4,
              paddingBottom: "35px",
            }}
          >
            {totalPages > 1 && selectedTypeMap !== "map" && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& ul": {
                    justifyContent: "center",
                    marginTop: "25px",
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      margin: "auto",
                      justifyContent: "center",
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                        fontWeight: "bold",
                      },
                      "&:hover": {
                        backgroundColor: `${theme.palette.primary.main} !important`,
                        color: theme.palette.primary.light,
                      },
                      borderRadius: "50%",
                      marginRight: "5px",
                      minWidth: "40px",
                    }}
                  />
                )}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </section>
  );
};

export default ListMap;
