import React from "react";
import {
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import PlacesAutocomplete from "react-places-autocomplete";
import { IoCloseCircleOutline } from "react-icons/io5";

const StreetAddress = ({
  handleSelect,
  setAddress,
  address,
  handleAddressChange,
  setAddressToShow,
  backgroundColor,
  borderColor,
  placeholder,
  disabledIcon,
  padding,
  type,
  marginTop,
  handleInputChange
}) => {
  const theme = useTheme();
  const handleClear = () => {
    setAddress("");
    setAddressToShow("");
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={(newAddress) => {
        setAddressToShow(newAddress);
        if (handleAddressChange) {
          handleAddressChange(newAddress);
        }
      }}
      onSelect={(address, placeId, suggestion) => {
        setAddressToShow(address);
        handleSelect(address, placeId, suggestion);
      }}
      
     
      
      searchOptions={{
        // ...(type ? { types: [type] } : {}),
        
        // Include both cities and postal codes
        types: type ? [type] : ["address"],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative", width: "100%" }}>
          <TextField
            {...getInputProps({
              placeholder: placeholder || "Please Enter Address",
              className: "location-search-input",
              autoComplete: "off",
              name: "address",
            })}
            fullWidth
            name="address"
            id="address"
            variant="outlined"
            sx={{
              borderRadius: "10px",
              background: backgroundColor,
              border: borderColor,
              marginTop: marginTop,
              "& .MuiInputBase-input": {
                padding: padding,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                color: theme.palette.primary.darkColor,
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 500,
                border: "none",
              },
              "& .MuiInputBase-input::placeholder": {
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.primary.darkColor,
              },
            }}
            // error={touched.addressLineOne && Boolean(errors.addressLineOne)}
            InputProps={{
              endAdornment:(
                <InputAdornment position="end">
                  {!disabledIcon && address && (
                    <IconButton
                      onClick={handleClear}
                      aria-label="clear address"
                    >
                      <IoCloseCircleOutline />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Paper
            sx={{
              marginTop: "-15px !important",
              position: "absolute",
              zIndex: 2100,
              width: "100%",
              maxHeight: "300px",
              overflowY: "auto",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            }}
          >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const { key, ...suggestionProps } =
                getSuggestionItemProps(suggestion);
              const isActive = suggestion.active;

              return (
                <Box
                  key={suggestion.description}
                  {...suggestionProps}
                  sx={{
                    padding: "10px 16px",
                    backgroundColor: isActive ? "rgba(0, 0, 0, 0.1)" : "white",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    },
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <Typography variant="body2">
                    {suggestion.description}
                  </Typography>
                </Box>
              );
            })}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default StreetAddress;
