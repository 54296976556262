import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Box,
  useTheme,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  Alert,
  Chip,
} from "@mui/material";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Formik, Form } from "formik";
import { IoCloseCircleOutline } from "react-icons/io5";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setFormTouched } from "../../../redux/slices/appStateSlice";
import PhoneNumberInput from "../../../components/common/PhoneNumberInput";
import parsePhoneNumberFromString from "libphonenumber-js";
import ShippingAddressSelector from "../../cart/components/ShippingAddressSelector";
import { useSelector } from "react-redux";
import { collection, getDocs, Timestamp } from "firebase/firestore";
import { db, storageSdk } from "../../../configs/firebase";
import { getDownloadURL, uploadBytes } from "firebase/storage";

import { message } from "antd";
import { MdDelete, MdOutlineAddAPhoto } from "react-icons/md";
import Loader from "../../../components/common/Loader";
import ImageCropUploader from "../../../components/common/ImageCropUploader";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import WorkingHoursForm from "../../../components/common/Header/component/WokingHoursBussiness";

// Validation schema
const validationSchema = Yup.object().shape({
  businessName: Yup.string()
    .trim()
    .required("Business name is required")
    .min(3, "Business Name must be at least 3 characters"),
  email: Yup.string().trim().email("Invalid email"),
  // phone: Yup.string()
  //   .required("Phone number is required")
  //   .test("is-valid-phone", "Please enter a valid phone number", (value) => {
  //     if (!value) return false;
  //     const phoneNumber = parsePhoneNumberFromString(value);
  //     return phoneNumber?.isValid() || false;
  //   }),
  addressLineOne: Yup.string().trim().required("Address is required"),
  description: Yup.string()
    .trim()
    .min(15, "Description must be at least 15 characters"),
  addressLineTwo: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9\s\-/#.,]*$/,
      "Invalid characters in apartment/suite field"
    ),
  city: Yup.string()
    .trim()
    .min(3, "City must be at least 3 characters long")
    .required("City is required"),
  state: Yup.string()
    .trim()
    .min(2, "State must be least 2 characters long")
    .max(20, "State must be at most 20 characters long")
    .required("State is required"),
  zipCode: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9\s\-]+$/, "Zip code must be valid")
    .required("Zip code is required"),
  websiteLink: Yup.string()
    .trim()
    .matches(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/,
      "Please enter a valid website URL"
    )
    .optional(),
  businessContent: Yup.string()
    .trim()
    .min(15, "Business content must be at least 15 characters"),
});

const BusinessForm = ({
  initialValues,
  onSubmit,
  setCountry,
  setPlaceId,
  setLat,
  setLng,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const cropperRef = useRef(null);

  const userData = useSelector((state) => state.appState.userData);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [chooseAddressEnabled, setChooseAddressEnabled] = useState(false);
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [addressSelectorMode, setAddressSelectorMode] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);
  const [addresses, setAddresses] = useState([]);
  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(
    initialValues?.businessImage || null
  );
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [daysEmpty, setDaysEmpty] = React.useState(false);
  const [isHoursModalOpen, setIsHoursModalOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(
    userData.businessInfo?.openDays ?? []
  );

  const [address, setAddress] = useState(
    `${initialValues.addressLineOne ?? ""}, ${initialValues.city ?? ""}, ${
      initialValues.state ?? ""
    } , ${initialValues.country ?? ""}` || ""
  );

  const [selectedAddressId, setSelectedAddressId] = useState(
    userData?.businessInfo?.address
      ? userData?.businessInfo?.address?.id || addresses?.id
      : null
  );

  const labelTextStyle = {
    color: theme.palette.primary.darkMain,
    fontWeight: "600",
    textTransform: "capitalize",
    marginBottom: "15px",
    marginLeft: "7px",
  };

  const inputStyle = {
    border: `1px solid ${theme.palette.gray.slightGray}`,
    padding: "13px 20px",
    borderRadius: "10px",
  };

  useEffect(() => {
    if (selectedAddress) {
      setFormValues((prevValues) => ({
        ...prevValues,
        addressLineOne:
          selectedAddress.addressLineOne || prevValues.addressLineOne,
        addressLineTwo:
          selectedAddress.addressLineTwo || prevValues.addressLineTwo,
        city: selectedAddress.city || prevValues.city,
        state: selectedAddress.state || prevValues.state,
        zipCode: selectedAddress.zipCode || prevValues.zipCode,
      }));

      setAddress(
        `${selectedAddress.addressLineOne ?? ""}, ${
          selectedAddress.city ?? ""
        }, ${selectedAddress.state ?? ""}, ${selectedAddress.country ?? ""}`
      );
      setIsAddressSelected(true);
      setPlaceId(selectedAddress?.id);
    }
  }, [selectedAddress, setPlaceId]);

  const handleClearAddress = (setFieldValue) => {
    setAddress("");
    setIsAddressSelected(false);
    setFieldValue("addressLineOne", "");
    setFieldValue("city", "");
    setFieldValue("state", "");
    setFieldValue("zipCode", "");
    setFieldValue("isAddressSelected", false);
  };

  const handleAddressChange = () => {
    dispatch(setFormTouched());
  };

  const handleInputChange = (e, fieldName, setFieldValue) => {
    let value = e?.target ? e.target.value : e;
    if (value.startsWith(" ")) {
      value = value.trimStart();
    }
    // if (fieldName === "phone") {
    //   value = formatPhoneNumber(value);
    // }
    if (fieldName === "businessName" || fieldName === "description") {
      if (value.length === 1) {
        value = value.toUpperCase();
      } else {
        value = value.replace(
          /(\.\s*)([a-z])/g,
          (match, p1, p2) => p1 + p2.toUpperCase()
        );
      }
    }
    setFieldValue(fieldName, value);
  };

  const handleSelect = async (value, setFieldValue) => {
    const results = await geocodeByAddress(value);
    if (!results || results.length === 0) {
      throw new Error("No address found");
    }
    const latLng = await getLatLng(results[0]);
    const addressComponents = results[0].address_components;

    const addressDetails = {
      addressLineOne: "",
      city: "",
      state: "",
      zipCode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number") || types.includes("route")) {
        addressDetails.addressLineOne += component.long_name + " ";
      } else if (types.includes("locality")) {
        addressDetails.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        addressDetails.state = component.short_name;
      } else if (types.includes("postal_code")) {
        addressDetails.zipCode = component.long_name;
      } else if (types.includes("country")) {
        setCountry(component.short_name);
      }
    });

    setAddress(value);
    setFieldValue("addressLineOne", addressDetails.addressLineOne.trim());
    setFieldValue("city", addressDetails.city);
    setFieldValue("state", addressDetails.state);
    setFieldValue("zipCode", addressDetails.zipCode);
    setFieldValue("isAddressSelected", true);
    setIsAddressSelected(true);
    setPlaceId(results[0].place_id);
    setLat(latLng.lat);
    setLng(latLng.lng);
  };

  const fetchAddresses = async () => {
    try {
      const addressesRef = collection(db, "users", userData?.uid, "addresses");
      const querySnapshot = await getDocs(addressesRef);
      const fetchedAddresses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedAddresses = fetchedAddresses.sort(
        (a, b) => b.isDefault - a.isDefault
      );

      setAddresses(sortedAddresses);

      const defaultAddress = sortedAddresses?.find(
        (address) => address?.isDefault
      );
      // setAddresses(defaultAddress);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    if (userData?.uid) {
      fetchAddresses();
    }
  }, [userData]);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCurrentFile(file);
      setIsCropDialogOpen(true);
    }
  };

  const handleCropSave = async (setFieldValue) => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      if (!croppedCanvas) {
        message.error("Failed to crop the image. Please try again.");
        return;
      }

      croppedCanvas.toBlob(async (blob) => {
        if (!blob) {
          message.error("Failed to generate cropped image. Please try again.");
          return;
        }

        setIsCropDialogOpen(false);
        const currentDateTime = new Date().toISOString();
        const imageName = `image-${currentDateTime}.png`;
        const imageRef = storageSdk.ref(
          `businessImage/${userData.uid}/${imageName}`
        );

        try {
          setIsLoadingImage(true);
          await uploadBytes(imageRef, blob);
          const downloadURL = await getDownloadURL(imageRef);

          // Update single selected image
          setSelectedImage(downloadURL);
          setFieldValue("businessImage", downloadURL); // Update Formik value

          message.success("Image uploaded successfully.");
        } catch (error) {
          console.error("Error uploading image:", error);
          message.error("Failed to upload image. Please try again.");
        } finally {
          setIsLoadingImage(false);
        }
      });
    }
  };

  const handleRemoveImage = (setFieldValue) => {
    setSelectedImage(null);
    setFieldValue("businessImage", null);

    // Add a slight delay before allowing another selection
    setTimeout(() => {
      document.getElementById("photo-upload").value = "";
    }, 100);
  };

  const handleCropCancel = () => {
    setIsCropDialogOpen(false);
    setCurrentFile(null);
  };

  // Convert Firebase timestamps to Date objects and format them using moment
  const formatBusinessHours = (selectedDays) => {
    return selectedDays.map((d) => ({
      ...d,
      from: d?.from ? new Date(d.from.seconds * 1000) : null,
      to: d?.to ? new Date(d.to.seconds * 1000) : null,
    }));
  };

  const updateDays = (days) => {
    setSelectedDays(days);
  };

  useEffect(() => {
    if (userData.businessInfo?.openDays) {
      setSelectedDays(userData?.businessInfo?.openDays);
    }
  }, [userData.businessInfo?.openDays]);

  // Function to format business hours with Firebase Timestamps
  const formatBusinessHoursToFierbase = (selectedDays) => {
    return selectedDays.map((d) => ({
      ...d,
      from: d?.from
        ? Timestamp.fromDate(new Date(d.from.seconds * 1000))
        : null,
      to: d?.to ? Timestamp.fromDate(new Date(d.to.seconds * 1000)) : null,
    }));
  };

  const formattedDays = formatBusinessHours(selectedDays);
  const formattedDaysToFierbase = formatBusinessHoursToFierbase(selectedDays);

  return (
    <Stack
      sx={{
        padding: "20px",
        margin: "0px 25px",
        background: theme.palette.primary.light,
        borderRadius: "10px",
      }}
      gap={4}
    >
      <Typography
        variant="h2"
        sx={{
          color: theme.palette.dark.colorCart,
          fontSize: "24px",
          fontFamily: theme.typography.headerFont,
          textTransform: "capitalize",
        }}
      >
        Business info
      </Typography>

      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        // onSubmit={onSubmit}
        onSubmit={(values) => {
          onSubmit(values, formattedDaysToFierbase);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={2}>
              {/* Business Name */}
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={labelTextStyle}>
                  business Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="businessName"
                  variant="standard"
                  value={values.businessName}
                  placeholder="Enter your business name"
                  // onChange={handleChange}
                  onChange={(e) =>
                    handleInputChange(e, "businessName", setFieldValue)
                  }
                  onBlur={handleBlur}
                  error={touched.businessName && Boolean(errors.businessName)}
                  sx={inputStyle}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                {touched.businessName && errors.businessName && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.businessName}
                  </Typography>
                )}
              </Grid>
              {/* Email */}
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={labelTextStyle}>
                  email
                </Typography>
                <TextField
                  fullWidth
                  name="email"
                  placeholder="Enter Email Address"
                  variant="standard"
                  value={values.email}
                  // onChange={handleChange}
                  onChange={(e) => handleInputChange(e, "email", setFieldValue)}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  sx={inputStyle}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                {touched.email && errors.email && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.email}
                  </Typography>
                )}
              </Grid>

              {/* Phone */}
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={labelTextStyle}>
                  Phone Number
                </Typography>

                <PhoneNumberInput
                  fullWidth
                  name="phone"
                  placeholder="Enter your Phone Number"
                  value={values.phone}
                  onBlur={handleBlur("phone")}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e, "phone", setFieldValue);
                  }}
                  error={touched.phone && Boolean(errors.phone)}
                />
                {/* {touched.phone && errors?.phone && (
                  <Typography variant="caption" color="error">
                    {errors?.phone}
                  </Typography>
                )} */}
              </Grid>

              {/* Address */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: chooseAddressEnabled ? "block" : "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "600", marginBottom: "10px" }}
                  >
                    Address <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {addresses.length > 0 && (
                    <>
                      <Button
                        onClick={() =>
                          setChooseAddressEnabled(!chooseAddressEnabled)
                        }
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {chooseAddressEnabled ? "Cancel" : "Choose Address"}
                      </Button>

                      {chooseAddressEnabled && (
                        <ShippingAddressSelector
                          currentUser={userData}
                          onSelectAddress={(address) =>
                            setSelectedAddressId(address?.id)
                          }
                          isModalOpen={isNewAddressModalOpen}
                          setIsModalOpen={setIsNewAddressModalOpen}
                          selectValue={addressSelectorMode}
                          selectedAddress={selectedAddress}
                          setSelectedAddress={setSelectedAddress}
                          selectedAddressId={selectedAddressId}
                        />
                      )}
                    </>
                  )}
                </Box>
                <PlacesAutocomplete
                  // value={address}
                  value={
                    address && address.trim() !== ", ,  ," ? address : "" // Prevents setting an empty address
                  }
                  disabled={isAddressSelected}
                  onChange={(value) => {
                    setAddress(value);
                    handleInputChange(value, "addressLineOne", setFieldValue);
                    handleAddressChange();
                    setIsAddressSelected(false);
                    setFieldValue("isAddressSelected", false);
                  }}
                  onSelect={(value) => {
                    handleSelect(value, setFieldValue);
                  }}
                  searchOptions={{
                    types: ["address"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div style={{ position: "relative", width: "100%" }}>
                      <TextField
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                        })}
                        id="addressLineOne"
                        name="addressLineOne"
                        fullWidth
                        disabled={isAddressSelected}
                        variant="outlined"
                        sx={{ ...theme.sharedStyles }}
                        error={
                          touched.addressLineOne &&
                          Boolean(errors.addressLineOne)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {address && (
                                <IconButton
                                  onClick={() =>
                                    handleClearAddress(setFieldValue)
                                  }
                                  aria-label="clear address"
                                >
                                  <IoCloseCircleOutline />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Paper
                        sx={{
                          marginTop: "-15px !important",
                          position: "absolute",
                          zIndex: 2100,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "4px",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const { key, ...suggestionProps } =
                            getSuggestionItemProps(suggestion);
                          const isActive = suggestion.active;

                          return (
                            <Box
                              key={suggestion.description}
                              {...suggestionProps}
                              sx={{
                                padding: "10px 16px",
                                backgroundColor: isActive
                                  ? "rgba(0, 0, 0, 0.1)"
                                  : "white",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                                },
                                borderBottom: "1px solid #eee",
                              }}
                            >
                              <Typography variant="body2">
                                {suggestion.description}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Paper>
                    </div>
                  )}
                </PlacesAutocomplete>
                {touched.addressLineOne && errors.addressLineOne && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.addressLineOne}
                  </Typography>
                )}
              </Grid>
              {/* addressLineTwo */}
              <Grid item xs={12}>
                <Typography variant="body2" sx={labelTextStyle}>
                  Apt./suite (optional)
                </Typography>
                <TextField
                  id="addressLineTwo"
                  name="addressLineTwo"
                  fullWidth
                  variant="outlined"
                  value={values.addressLineTwo}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e, "addressLineTwo", setFieldValue);
                  }}
                  onBlur={handleBlur("addressLineTwo")}
                  placeholder="Enter Apt./Suite"
                />
              </Grid>
              {/* City */}
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={labelTextStyle}>
                  City <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="city"
                  variant="standard"
                  value={values.city}
                  placeholder="Enter your city"
                  onChange={(e) => handleInputChange(e, "city", setFieldValue)}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  sx={inputStyle}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                {touched.city && errors.city && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.city}
                  </Typography>
                )}
              </Grid>

              {/* State */}
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={labelTextStyle}>
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="state"
                  variant="standard"
                  value={values.state}
                  placeholder="Enter your state"
                  onChange={(e) => handleInputChange(e, "state", setFieldValue)}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  sx={inputStyle}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                {touched.state && errors.state && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.state}
                  </Typography>
                )}
              </Grid>

              {/* Zip Code */}
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" sx={labelTextStyle}>
                  Zip code <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="zipCode"
                  variant="standard"
                  value={values.zipCode}
                  placeholder="Enter your zip code"
                  onChange={(e) =>
                    handleInputChange(e, "zipCode", setFieldValue)
                  }
                  onBlur={handleBlur}
                  error={touched.zipCode && Boolean(errors.zipCode)}
                  sx={inputStyle}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                {touched.zipCode && errors.zipCode && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.zipCode}
                  </Typography>
                )}
              </Grid>

              {/* Website */}
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Website link (optional)
                </Typography>
                <TextField
                  fullWidth
                  name="websiteLink"
                  variant="standard"
                  value={values.websiteLink}
                  placeholder="Enter your website"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.websiteLink && Boolean(errors.websiteLink)}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{
                    pattern: "https?://.*", // Restrict input to URLs starting with http:// or https://
                    title:
                      "Please enter a valid website URL (e.g., https://www.example.com)", // Tooltip for invalid input
                  }}
                />
                {touched.websiteLink && errors.websiteLink && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "15px" }}
                  >
                    {errors.websiteLink}
                  </Typography>
                )}
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <Typography variant="body2" sx={labelTextStyle}>
                  description
                </Typography>
                <TextField
                  fullWidth
                  name="description"
                  variant="standard"
                  placeholder="Enter Description"
                  multiline
                  rows={4}
                  value={values.description}
                  // onChange={handleChange}
                  onChange={(e) =>
                    handleInputChange(e, "description", setFieldValue)
                  }
                  onBlur={handleBlur}
                  error={touched.description && Boolean(errors.description)}
                  sx={inputStyle}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                {touched.description && errors.description && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.description}
                  </Typography>
                )}
              </Grid>

              {/* Business Content */}
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Business content (optional)
                </Typography>
                <TextField
                  fullWidth
                  name="businessContent"
                  variant="standard"
                  value={values.businessContent}
                  placeholder="Enter your business Content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    Boolean(errors.businessContent) || touched.businessContent
                  }
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                  }}
                  InputProps={{ disableUnderline: true }}
                />
                {(errors.businessContent || touched.businessContent) && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: "7px", marginTop: "15px" }}
                  >
                    {errors.businessContent}
                  </Typography>
                )}
              </Grid>

              {/** Business-Hours */}

              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Business hours (optional)
                </Typography>
                <TextField
                  fullWidth
                  name="openDays"
                  variant="standard"
                  placeholder="Enter your business hours "
                  onClick={() => setIsHoursModalOpen(true)}
                  // value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{
                    border: `1px solid ${theme.palette.gray.slightGray}`,
                    padding: "13px 20px",
                    borderRadius: "10px",
                    "& .MuiInputBase-input": {
                      cursor: "pointer", // Apply cursor: pointer to the input element
                    },
                  }}
                  InputProps={{ disableUnderline: true, readOnly: true }}
                />

                <Grid item xs={6} container justify={"space-evenly"}>
                  {formattedDays?.length > 0
                    ? formattedDays?.map((d) => (
                        <Grid item key={d?.days}>
                          <Chip
                            variant="outlined"
                            size="medium"
                            label={`${d?.days} ${moment(d?.from).format(
                              "hh:mma"
                            )}-${moment(d?.to).format("hh:mma")}`}
                          />
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              <Alert
                severity="error"
                style={{ display: `${daysEmpty === false ? "none" : ""}` }}
              >
                Please add business hours or select appointment only!
              </Alert>
              {/** Photo */}
              <Grid item xs={12} md={4}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Photo
                </Typography>

                <Box
                  sx={{
                    border: `1px dashed ${theme.palette.primary.main}`,
                    borderRadius: "10px",
                    background: theme.palette.primary.background,
                    padding: "20px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "245px",
                    cursor: selectedImage ? "not-allowed" : "pointer",
                  }}
                  onClick={() => {
                    if (!selectedImage && !isCropDialogOpen) {
                      document.getElementById("photo-upload").click();
                    }
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple={false} // Prevent multiple selection
                    onChange={handleImageSelect}
                    style={{ display: "none" }}
                    id="photo-upload"
                  />

                  <ImageCropUploader
                    isCropDialogOpen={isCropDialogOpen}
                    handleCropCancel={handleCropCancel}
                    currentFile={currentFile}
                    cropperRef={cropperRef}
                    handleCropSave={handleCropSave}
                    setFieldValue={setFieldValue}
                  />

                  {isLoadingImage ? (
                    <Loader color={theme.palette.primary.main} />
                  ) : selectedImage ? (
                    <Box sx={{ position: "relative", width: "100%" }}>
                      <img
                        src={
                          selectedImage ? selectedImage : values?.businessImage
                        }
                        alt="Selected Business"
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents opening file input when deleting
                          handleRemoveImage(setFieldValue);
                        }}
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          background: "white",
                          borderRadius: "50%",
                        }}
                      >
                        <MdDelete color="red" />
                      </IconButton>
                    </Box>
                  ) : (
                    <MdOutlineAddAPhoto
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "35px",
                      }}
                    />
                  )}
                </Box>

                {errors.image && touched.image && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "10px" }}
                  >
                    {errors.image}
                  </Typography>
                )}
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      textTransform: "capitalize",
                      color: theme.palette.primary.light,
                      backgroundColor: theme.palette.primary.main,
                      fontSize: "14px",
                      fontWeight: "700",
                      padding: "0 32px",
                      borderRadius: "3px",
                      height: "56px",
                      width: "11.25rem",
                      marginY: "20px",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.light,
                      },
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <WorkingHoursForm
          open={isHoursModalOpen}
          onClose={() => setIsHoursModalOpen(false)}
          updateDays={updateDays}
          selectedDays={selectedDays}
          setDaysEmpty={setDaysEmpty}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default BusinessForm;
