// src/utils/saveDraftProduct.js
import { message } from "antd";
import { db } from "../configs/firebase";

const saveDraftService = async ({ userData, formAddServiceData, values }) => {
  console.log(formAddServiceData, "formAddServiceData");
  try {
    if (!userData?.uid) {
      console.error("User not logged in.");
      return;
    }

    if (values === false) {
      // Clear the draftProduct if values are false
      await db
        .collection("users")
        .doc(userData?.uid)
        .set({ draftService: null }, { merge: true });

      return;
    } else {
      // Build the draftProduct from the provided values or fallback to formAddServiceData
      const draftService = {
        categoryId:
          values?.categoryId || formAddServiceData?.categoryId || null,
        title: values?.title || formAddServiceData?.title || "",
        description:
          values?.description || formAddServiceData?.description || "",
        images: values?.images || formAddServiceData?.images || null,
        video: values?.video || formAddServiceData?.video || null,
        price: values?.price || formAddServiceData?.price || null,
        contactForPrice:
          values?.contactForPrice ||
          formAddServiceData?.contactForPrice ||
          false,
        quantity: values?.quantity || formAddServiceData?.quantity || 1,
        isLocalPickup:
          values?.isLocalPickup || formAddServiceData?.isLocalPickup || false,
        isLocalAddress: values?.isLocalAddress || true,
        isOnline: values?.isOnline || formAddServiceData?.isOnline || false,
        businessAddress: userData?.businessInfo?.address,
      };

      await db
        .collection("users")
        .doc(userData?.uid)
        .set({ draftService }, { merge: true });
      console.log("Draft Service saved successfully.");
    }
  } catch (error) {
    console.error("Error saving draft product:", error);
    message.error("Failed to save draft. Please try again.");
  }
};

export default saveDraftService;
