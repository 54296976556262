import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  Button,
  Typography,
  IconButton,
  Collapse,
  Box,
  useTheme,
  TextField,
} from "@mui/material";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs"; // Import dayjs for validity check
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // Import the correct Adapter
import { Timestamp } from "firebase/firestore";

const CustomTimePicker = (rest) => {
  return (
    <TimePicker
      autoOk
      label="Uncontrolled picker"
      TextFieldComponent={TextField}
      inputVariant={"outlined"}
      {...rest}
      popupClassName="custom-time-picker-popup"
    />
  );
};

// Convert Firebase timestamps to dayjs objects
const formatHours = (daysArray) => {
  return daysArray.map((h) => ({
    ...h,
    from: h.from ? dayjs(new Date(h.from.seconds * 1000)) : null,
    to: h.to ? dayjs(new Date(h.to.seconds * 1000)) : null,
  }));
};

const WorkingHoursForm = ({
  open,
  onClose,
  updateDays,
  selectedDays,
  setDaysEmpty,
}) => {
  const theme = useTheme();
  const [error, setShowError] = useState(false);

  // Initialize state with selectedDays or default hours
  const [hours, setHours] = useState(
    selectedDays?.length > 0
      ? formatHours(selectedDays)
      : [{ days: "Mon-Fri", from: null, to: null }]
  );

  const singleDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const isDisabled = (arr) => hours.some((d) => arr.includes(d.days));

  const handleAddNewRow = () => {
    setShowError(false);
    setHours([...hours, { days: "", from: null, to: null }]);
  };

  const handleRemoveRow = (index) => {
    setShowError(false);
    const newArr = [...hours];
    newArr.splice(index, 1);
    setHours(newArr);
  };

  const handleSubmit = () => {
    // Convert moment/dayjs objects to Firebase Timestamps
    const updatedHours = hours.map((h) => ({
      ...h,
      from: h.from ? Timestamp.fromDate(h.from.toDate()) : null, // Convert to Firebase Timestamp
      to: h.to ? Timestamp.fromDate(h.to.toDate()) : null, // Convert to Firebase Timestamp
    }));

    // Check for any invalid values
    if (
      updatedHours.some(
        (d) => d.to === null || d.from === null || d.days === ""
      )
    ) {
      setShowError(true);
    } else {
      updateDays(updatedHours); // Pass the updated hours array
      setDaysEmpty(false);
      onClose();
    }
  };

  const handleTimeChange = (index, field, date) => {
    if (date && date.isValid()) {
      setShowError(false);
      const newArr = [...hours];
      newArr[index][field] = date;
      setHours(newArr);
    } else {
      setShowError(true);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {" "}
      {/* Wrap with LocalizationProvider */}
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="business-hours-title"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: { xs: "4px", lg: "32px !important" },
          },

          "& .MuiDialogContent-root": {
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for webkit browsers (Chrome, Safari, Edge)
            },
          },
        }}
      >
        <DialogTitle sx={{ padding: "24px 30px", fontSize: "1.2rem" }}>
          Business Hours
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
              color: theme.palette.grey[500],
            }}
          >
            <IoCloseCircleOutline />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={2}>
            {hours?.map((h, index) => (
              <Grid
                container
                item
                xs={12}
                spacing={2}
                alignItems="center"
                key={index}
              >
                {/* Day Selection */}
                <Grid item xs={10} md={5}>
                  <Select
                    fullWidth
                    variant="outlined"
                    value={h.days}
                    onChange={(e) => {
                      setShowError(false);
                      const newArr = [...hours];
                      newArr[index].days = e.target.value;
                      setHours(newArr);
                    }}
                    sx={{
                      borderRadius: "10px", // Soft rounded corners
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.grey[300], // Light border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main, // Focus effect
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main, // Focus effect
                      },
                    }}
                  >
                    <MenuItem
                      value="Mon-Fri"
                      disabled={isDisabled([
                        "Mon-Fri",
                        "Mon-Sun",
                        ...singleDays,
                      ])}
                    >
                      Mon-Fri
                    </MenuItem>
                    <MenuItem
                      value="Sat-Sun"
                      disabled={isDisabled([
                        "Sat-Sun",
                        "Mon-Sun",
                        ...singleDays,
                      ])}
                    >
                      Sat-Sun
                    </MenuItem>
                    <MenuItem
                      value="Mon-Sun"
                      disabled={isDisabled([
                        "Mon-Sun",
                        "Mon-Fri",
                        "Sat-Sun",
                        ...singleDays,
                      ])}
                    >
                      Mon-Sun
                    </MenuItem>
                    {singleDays?.map((day) => (
                      <MenuItem
                        key={day}
                        value={day}
                        disabled={isDisabled([day, "Mon-Fri", "Mon-Sun"])}
                      >
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* Time Pickers */}

                <Grid item xs={5} md={3}>
                  <CustomTimePicker
                    fullWidth
                    label="From"
                    value={h.from}
                    onChange={(date) => handleTimeChange(index, "from", date)} // Use helper function for validation
                  />
                </Grid>

                <Grid item xs={5} md={3}>
                  <CustomTimePicker
                    fullWidth
                    label="To"
                    value={h.to}
                    onChange={(date) => handleTimeChange(index, "to", date)} // Use helper function for validation
                  />
                </Grid>

                {/* Remove Button */}
                <Grid item xs={2} md={1}>
                  {hours.length > 1 && (
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <IoCloseCircleOutline />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}

            {/* Add New Business Hours */}
            <Grid item xs={12} sx={{ textAlign: "left" }}>
              <Button
                variant="outlined"
                startIcon={<IoAddCircleOutline />}
                onClick={handleAddNewRow}
                sx={{
                  textTransform: "none",
                  fontSize: "0.9rem",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                  },
                }}
              >
                Add Business Hours
              </Button>
            </Grid>

            {/* Error Message */}
            <Grid item xs={12}>
              <Collapse in={error}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.error.light,
                    color: theme.palette.error.dark,
                    padding: "8px 14px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2">
                    Please fill all fields correctly.
                  </Typography>
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </DialogContent>

        {/* Actions */}
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button onClick={onClose} sx={{ textTransform: "none" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              textTransform: "none",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default WorkingHoursForm;
