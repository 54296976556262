import { Stack, Button } from "@mui/material";

const ProductActionButtons = ({ theme,handleRemoveFromCart,product }) => {

  const baseButtonStyle = {
    height: "30px",
    paddingX: "10px",
    paddingY: "0",
    textTransform: "capitalize",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.neutral.grayBorder}`,
    boxShadow: `0px 1px 2px 0px ${theme.palette.customShadows.dark}`,
    fontSize: "12px",
    fontWeight: "500",
  };

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Button
        onClick={()=>handleRemoveFromCart(product.id)}
        sx={{
          ...baseButtonStyle, 
          color: theme.palette.status.discount, 
        }}
      >
        remove
      </Button>
    
    </Stack>
  );
};

export default ProductActionButtons;
