import { Stack, Typography, Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { IoCheckmarkOutline, IoCheckmarkDoneOutline } from "react-icons/io5";
import profilePlaceholder from "../../../assets/Images/profilePlaceholder.png";
import { useNavigate } from "react-router-dom";

const ChatMessages = ({
  messages,
  theme,
  userDetails,
  loadOlderMessages,
  hasMoreMessages,
  isLoadingMessages,
  lastVisibleMessage,
  activeTab,
  scrollContainerRef,
  scrollToBottom,
}) => {
  const currentUser = useSelector((state) => state.appState.userData);
  const navigate = useNavigate();
  const [lastVisibleMessageChat, setLastVisibleMessageChat] =
    useState(lastVisibleMessage);
    
useEffect(() => {
  console.log(hasMoreMessages)
}, [hasMoreMessages]);

  useEffect(() => {
    if (lastVisibleMessageChat) {
      setLastVisibleMessageChat(lastVisibleMessage);
    }
  }, [lastVisibleMessage]);

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom("auto");
  }, []);
  // Handle scroll: load older messages when scrolled to top
  const handleScroll = () => {
    if (scrollContainerRef.current.scrollTop === 0) {
      loadOlderMessages();
    }
  };
  // Attach scroll listener and handle clean-up
  useEffect(() => {
    const container = scrollContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [hasMoreMessages, isLoadingMessages, scrollContainerRef]);

  const mapUserTypeToTab = (userType) => {
    switch (userType) {
      case "serviceProvider":
        return "Services Providers";
      case "seller":
        return "Sellers";
      case "all":
        return "All";
      default:
        return "Unknown";
    }
  };

  const goToSellerPage = (id, userType) => {
    const selectedTab = mapUserTypeToTab(userType);
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/sellers/seller-details/${id}`, { state: { selectedTab } });
  };

  return (
    <Stack
      ref={scrollContainerRef}
      gap={1}
      sx={{
        height: activeTab === "users" ? "400px" : "300px",
        overflowY: "auto",
        paddingX: "10px",
        [theme.breakpoints.down("sm")]: {
          paddingX: "5px",
          height: "250px",
        },
      }}
    >
      {isLoadingMessages && (
        <CircularProgress
          size={20}
          color="primary"
          style={{ margin: "auto" }}
        />
      )}
      {!isLoadingMessages && !hasMoreMessages && (
        <Typography variant="body2" color="textSecondary" textAlign="center">
          No more messages to load
        </Typography>
      )}
      {messages.map((message, index) => {
        const isCurrentUser = message?.senderId === currentUser.uid;
        const isLastFromUser =
          index === messages.length - 1 ||
          messages[index + 1].senderId !== message?.senderId;
        const isFirstFromUser =
          index === 0 || messages[index - 1].senderId !== message?.senderId;

        return (
          <React.Fragment key={message?.id}>
            <Stack
              direction="row"
              gap={1}
              justifyContent={isCurrentUser ? "flex-end" : "flex-start"}
              alignItems="flex-start"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  gap: 0.5,
                },
              }}
            >
              {/* Avatar for the other user */}
              {!isCurrentUser && isFirstFromUser && (
                <img
                  src={
                    userDetails?.profilePicture
                      ? userDetails?.profilePicture
                      : profilePlaceholder
                  }
                  alt={userDetails?.firstName}
                  style={{
                    width: "48px",
                    height: "48px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    goToSellerPage(userDetails?.uid, userDetails?.userType)
                  }
                />
              )}

              {/* Message Bubble */}
              <Stack
                direction="column"
                alignItems={isCurrentUser ? "flex-end" : "flex-start"}
                gap={0.5}
              >
                {isFirstFromUser && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.darkHeaderGrey,
                      fontWeight: "500",
                      textTransform: "capitalize",

                      [theme.breakpoints.down("sm")]: {
                        fontSize: "0.8rem",
                      },
                    }}
                    onClick={
                      !isCurrentUser
                        ? () =>
                            goToSellerPage(
                              userDetails?.uid,
                              userDetails?.userType
                            )
                        : undefined
                    }
                    style={{
                      cursor: !isCurrentUser ? "pointer" : "default",
                    }}
                  >
                    {isCurrentUser
                      ? currentUser?.businessInfo?.name
                        ? currentUser?.businessInfo?.name
                        : `${currentUser?.firstName} ${currentUser?.lastName}`
                      : userDetails?.businessInfo?.name
                      ? userDetails?.businessInfo?.name
                      : `${userDetails?.firstName} ${userDetails?.lastName}`}
                  </Typography>
                )}
                <Box
                  sx={{
                    backgroundColor: isCurrentUser
                      ? theme.palette.primary.lightBlue
                      : theme.palette.primary.light,
                    color: isCurrentUser
                      ? theme.palette.primary.light
                      : theme.palette.primary.darkHeaderGrey,
                    padding: "10px",
                    borderRadius: isCurrentUser
                      ? "14px 0px 14px 14px"
                      : "0px 14px 14px 14px",
                    // maxWidth: isCurrentUser ? "60%" : "75%",
                    alignSelf: isCurrentUser ? "flex-end" : "flex-start",
                    border: `1px solid`,
                    borderColor: isCurrentUser
                      ? theme.palette.primary.lightBlue
                      : theme.palette.primary.slightGray,
                    boxShadow: isCurrentUser
                      ? "none"
                      : "0px 1px 3px rgba(0, 0, 0, 0.1)",
                    marginRight: isFirstFromUser ? "0" : "60px",
                    marginLeft: isFirstFromUser ? "0" : "60px",
                    [theme.breakpoints.down("sm")]: {
                      padding: "8px",
                      borderRadius: "10px",
                      // maxWidth: isCurrentUser ? "70%" : "85%",
                    },
                  }}
                >
                  {message.messageType === "image" ? (
                    <img
                      src={message.image}
                      alt="Sent"
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        wordBreak:
                          message?.text?.length > 15 ? "break-word" : "normal",
                        overflowWrap:
                          message?.text?.length > 15 ? "break-word" : "normal",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "0.9rem",
                        },
                      }}
                    >
                      {message?.text}
                    </Typography>
                  )}
                </Box>
              </Stack>

              {/* Avatar for the current user */}
              {isCurrentUser && isFirstFromUser && (
                <img
                  src={
                    currentUser?.profilePicture
                      ? currentUser?.profilePicture
                      : profilePlaceholder
                  }
                  alt="Avatar"
                  style={{
                    width: "48px",
                    height: "48px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Stack>

            {/* Timestamp */}
            {isLastFromUser && (
              <Typography
                variant="caption"
                sx={{
                  textAlign: isCurrentUser ? "right" : "left",
                  color: "grey",
                  marginLeft: isCurrentUser ? "10px" : 0,
                  marginRight: !isCurrentUser ? "10px" : 0,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.7rem",
                    marginLeft: isCurrentUser ? "5px" : 0,
                    marginRight: !isCurrentUser ? "5px" : 0,
                  },
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={isCurrentUser ? "end" : "start"}
                  gap={1}
                >
                  <Typography variant="caption">
                    {message.createdAt
                      ? new Date(
                          message.createdAt.seconds * 1000
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                      : "Sending..."}
                  </Typography>
                  {isCurrentUser && (
                    <>
                      {message.seen ? (
                        <IoCheckmarkDoneOutline
                          color={theme.palette.primary.main}
                        />
                      ) : (
                        <IoCheckmarkOutline
                          color={theme.palette.primary.main}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </Typography>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default ChatMessages;
