import React, { useState } from "react";
import { Box, Collapse, Typography, IconButton, useTheme } from "@mui/material";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";

const ToggleIconButton = React.memo(({ isOpen, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={onClick}
      sx={{
        padding: "0 !important",
        backgroundColor: isOpen
          ? theme.palette.neutral.background
          : theme.palette.primary.main,
        color: isOpen
          ? theme.palette.dark.charcoalBlack
          : theme.palette.primary.light,
        "&:hover": {
          backgroundColor: isOpen
            ? theme.palette.neutral.background
            : theme.palette.primary.main,
        },
      }}
    >
      {isOpen ? <FiMinus fontSize="20px" /> : <GoPlus fontSize="20px" />}
    </IconButton>
  );
});

const CollapsibleSection = React.memo(({ label, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const theme = useTheme();

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  // Determine if the section should have scrollable content
  const isScrollable = label === "Categories" || label === "Services";

  return (
    <Box
      sx={{
        background: theme.palette.primary.light,
        marginTop: "30px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: theme.palette.dark.charcoalBlack, fontWeight: 600 }}
        >
          {label}
        </Typography>
        <ToggleIconButton isOpen={isOpen} onClick={toggleOpen} />
      </Box>

      <Collapse in={isOpen}>
        <Box
          sx={{
            marginTop: "10px",
            ...(isScrollable && {
              maxHeight: "600px",
              overflowY: "auto",
              overflowX: 'hidden',
              padding: "0 5px",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                borderRadius: "4px",
                backgroundColor: theme.palette.primary.slightGray,
              },
            }),
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
});
export default CollapsibleSection;
