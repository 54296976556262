import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { IoLocation } from "react-icons/io5";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  Timestamp,
  collection,
  getDocs,
  doc,
  writeBatch,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../configs/firebase";
import { message } from "antd";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFormTouched,
  setFormTouched,
} from "../../../../redux/slices/appStateSlice";
import { IoCloseCircleOutline } from "react-icons/io5";

const validationSchema = Yup.object().shape({
  customName: Yup.string()
    .trim()
    .min(3, "Address name must be at least 3 characters long")
    .max(10, "Address name must be at most 10 characters long")
    .when("name", {
      is: (val) => val === "Other",
      then: (schema) => schema.required("Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  addressLineOne: Yup.string().trim().required("Address is required"),
  addressLineTwo: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9\s\-/#.,]*$/,
      "Invalid characters in apartment/suite field"
    ),
  city: Yup.string()
    .trim()
    .min(3, "City must be at least 3 characters long")
    .required("City is required"),
  state: Yup.string()
    .trim()
    .min(2, "State must be least 2 characters long")
    .max(20, "State must be at most 20 characters long")
    .required("State is required"),
  zipCode: Yup.string()
    .trim()
    .matches(/^[A-Za-z0-9\s\-]+$/, "Zip code must be valid")
    .required("Zip code is required"),
  isDefault: Yup.boolean(),
});

const AddressForm = () => {
  const theme = useTheme();
  const sharedStyles = {
    borderRadius: "8px",
    color: theme.palette.neutral.greyBlue,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: `${theme.palette.neutral.metallicGray} !important`,
        borderRadius: "8px",
      },
    },
  };
  const userAddressData = useSelector(
    (state) => state.appState.userAddressData
  );
  const inputStyle = {
    color: theme.palette.neutral.greyBlue,
    marginBottom: 1,
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.appState.userData);
  const [address, setAddress] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [country, setCountry] = useState(null);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [docId, setDocId] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isDefeultSelectedData, setIsDefeultSelectedData] = useState(false);
  const [initialValues, setInitialValues] = useState({
    customName: "",
    name: "Home",
    addressLineTwo: "",
    city: "",
    state: "",
    zipCode: "",
    addressLineOne: "",
    isDefault: false,
    isAddressSelected: false,
  });
  useEffect(() => {
    if (typeof window.google !== "undefined" && window.google.maps) {
      setApiLoaded(true);
    }
  }, []);

  useEffect(() => {
    // If there are no addresses or only one address, make the default checkbox true
    if (userAddressData.length === 0) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        isDefault: true,
      }));
    }
  }, [userAddressData]);

  const fetchAddress = async (id) => {
    try {
      if (!userData?.uid) {
        message.error("User ID is not available");
        return;
      }
      if (!id) {
        message.error("Address ID is not provided");
        return;
      }
      const docRef = doc(db, "users", userData?.uid, "addresses", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (!data) {
          message.error("No data found for this document");
          return;
        }
        setIsDefeultSelectedData(data.isDefault || false);
        setInitialValues({
          name:
            data.name === "Home" || data.name === "Work" ? data.name : "Other",
          customName:
            data.name === "Home" || data.name === "Work" ? "" : data.name,
          addressLineTwo: data.addressLineTwo || "",
          city: data.city || "",
          state: data.state || "",
          zipCode: data.zipCode || "",
          addressLineOne: data.addressLineOne || "",
          isDefault: data.isDefault || false,
          isAddressSelected: false,
        });
        setLat(data.lat);
        setLng(data.lng);
        setPlaceId(data.placeId);
        setCountry(data.country);
        setAddress(
          `${data.addressLineOne ?? ""}, ${data.city ?? ""}, ${
            data.state ?? ""
          } , ${data.country ?? ""}`
        );
        setIsEditMode(true);
        setDocId(data.id);
      } else {
        message.error("No such document found!");
      }
    } catch (error) {
      message.error(`Error fetching address: ${error.message}`);
      console.error(error);
    }
  };
  useEffect(() => {
    if (userData?.uid) {
      const params = new URLSearchParams(location.search);
      const id = params.get("id");
      if (id) {
        fetchAddress(id);
      }
    }
  }, [userData?.uid]);

  const handleSelect = async (value, setFieldValue) => {
    const results = await geocodeByAddress(value);
    if (!results || results.length === 0) {
      throw new Error("No address found");
    }

    const latLng = await getLatLng(results[0]);
    const addressComponents = results[0].address_components;

    const addressDetails = {
      addressLineOne: "",
      city: "",
      state: "",
      zipCode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number") || types.includes("route")) {
        addressDetails.addressLineOne += component.long_name + " ";
      } else if (types.includes("locality")) {
        addressDetails.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        addressDetails.state = component.short_name;
      } else if (types.includes("postal_code")) {
        addressDetails.zipCode = component.long_name;
      } else if (types.includes("country")) {
        setCountry(component.short_name);
      }
    });

    setAddress(value);
    setFieldValue("addressLineOne", addressDetails.addressLineOne.trim());
    setFieldValue("city", addressDetails.city);
    setFieldValue("state", addressDetails.state);
    setFieldValue("zipCode", addressDetails.zipCode);
    setFieldValue("isAddressSelected", true);
    setIsAddressSelected(true);
    setPlaceId(results[0].place_id);
    setLat(latLng.lat);
    setLng(latLng.lng);
  };

  const handleInputChange = (event) => {
    dispatch(setFormTouched());
  };

  const updateUserDefaultAddress = async (defaultAddress) => {
    const { customName, updatedAt, createdAt, ...address } = defaultAddress;
    await db
      .collection("users")
      .doc(userData?.uid)
      .set(
        {
          address: {
            ...address,
          },
        },
        { merge: true }
      )
      .catch((err) => {
        console.error(err);
      });
  };
  const handleSubmit = async (values) => {
    try {
      delete values.isAddressSelected;
      if (!userData?.uid) {
        message.error("User ID is not available");
        return;
      }
      // Ensure lat, lng, placeId, and country are not null or undefined
      if (!placeId || !lat || !lng || !address || !country) {
        message.error("Please select a valid address from the suggestions.");
        return;
      }

      const addressesRef = collection(db, "users", userData?.uid, "addresses");
      const batch = writeBatch(db);
      if (isEditMode) {
        const docRef = doc(db, "users", userData?.uid, "addresses", docId);
        const originalDoc = await getDoc(docRef);
        if (originalDoc.exists()) {
          const originalData = originalDoc.data();
          // Handle isDefault logic if editing an address
          const defaultAddress = {
            addressLineOne: values?.addressLineOne,
            placeId,
            lat,
            lng,
            country,
            updatedAt: Timestamp.now(),
            isDefault: values.isDefault,
            city: values?.city,
            state: values?.state,
            zipCode: values?.zipCode,
            addressLineTwo: values?.addressLineTwo,
            name: values?.name === "Other" ? values?.customName : values?.name,
          };
          if (originalData.isDefault && values.isDefault) {
            // Do nothing, as the current address is still the default
            await updateUserDefaultAddress(defaultAddress);
          } else if (values.isDefault) {
            const querySnapshot = await getDocs(addressesRef);
            querySnapshot.forEach((doc) => {
              if (doc.data().isDefault) {
                batch.update(doc.ref, { isDefault: false });
              }
            });
            await updateUserDefaultAddress(defaultAddress);
          }
          // Update the existing address
          await updateDoc(docRef, {
            addressLineOne: values?.addressLineOne,
            placeId,
            lat,
            lng,
            country,
            updatedAt: Timestamp.now(),
            isDefault: values.isDefault,
            city: values?.city,
            state: values?.state,
            zipCode: values?.zipCode,
            addressLineTwo: values?.addressLineTwo,
            name: values?.name === "Other" ? values?.customName : values?.name,
          });
          dispatch(resetFormTouched());
          message.success("Address updated successfully");
          navigate("/profile?page=addresses&layout=address");
        }
      } else {
        const newAddressRef = doc(addressesRef);
        const newAddressId = newAddressRef.id;
        // If creating a new address and it's marked as default
        if (values.isDefault) {
          const querySnapshot = await getDocs(addressesRef);
          querySnapshot.forEach((doc) => {
            if (doc.data().isDefault) {
              batch.update(doc.ref, { isDefault: false });
            }
          });
          const defaultAddress = {
            id: newAddressId,
            addressLineOne: values?.addressLineOne,
            placeId,
            lat,
            lng,
            country,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            isDefault: values.isDefault,
            city: values?.city,
            state: values?.state,
            zipCode: values?.zipCode,
            addressLineTwo: values?.addressLineTwo,
            name: values?.name === "Other" ? values?.customName : values?.name,
          };
          await updateUserDefaultAddress(defaultAddress);
        }
        await setDoc(newAddressRef, {
          id: newAddressId, // Store the document's ID in the data
          addressLineOne: values?.addressLineOne,
          placeId,
          lat,
          lng,
          country,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          isDefault: values.isDefault,
          city: values?.city,
          state: values?.state,
          zipCode: values?.zipCode,
          addressLineTwo: values?.addressLineTwo,
          name: values?.name === "Other" ? values?.customName : values?.name,
        });
        dispatch(resetFormTouched());
        message.success("Address added successfully");
        navigate("/profile?page=addresses&layout=address");
      }
      await batch.commit();
    } catch (error) {
      message.error(`Error saving address: ${error.message}`);
      console.error("Error saving address:", error);
    }
  };

  const handleClearAddress = (setFieldValue) => {
    setAddress("");
    setIsAddressSelected(false);
    setFieldValue("addressLineOne", "");
    setFieldValue("isAddressSelected", false);
  };
  if (!apiLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnMount={true}
    >
      {({
        errors,
        touched,
        handleChange,
        values,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <Stack spacing={3}>
            <Box
              sx={{
                borderRadius: "8px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: {
                  xs: "10px",
                  sm: "20px",
                  lg: "30px",
                },
                gridTemplateAreas: `
                  "name name name"
                  "addressLineOne addressLineOne addressLineTwo"
                  "city state zipCode"
                `,
                "@media (max-width: 600px)": {
                  gridTemplateColumns: "1fr",
                  gridTemplateAreas: `
                    "name"
                     "addressLineOne"
                     "addressLineTwo"
                    "city"
                    "state"
                    "zipCode"
                   
                  `,
                },
              }}
            >
              {/* Name */}
              <Box sx={{ gridArea: "name" }}>
                <InputLabel sx={inputStyle} htmlFor="name">
                  address Name{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="name"
                    name="name"
                    value={values.name}
                    onChange={(e) => {
                      handleInputChange(e);
                      handleChange(e);
                      if (e.target.value !== "Other") {
                        setFieldValue("name", e.target.value);
                        setFieldValue("customName", "");
                      } else {
                        setFieldValue("name", "Other");
                      }
                    }}
                  >
                    <FormControlLabel
                      value="Home"
                      control={<Radio />}
                      label="Home"
                    />
                    <FormControlLabel
                      value="Work"
                      control={<Radio />}
                      label="Work"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
                {values.name !== "Home" && values.name !== "Work" && (
                  <TextField
                    id="customName"
                    name="customName"
                    fullWidth
                    variant="outlined"
                    value={values.customName || ""}
                    onBlur={handleBlur("customName")}
                    onChange={(e) => {
                      handleChange(e);
                      handleInputChange(e);
                    }}
                    sx={sharedStyles}
                    error={touched.customName && Boolean(errors.customName)}
                    placeholder="Enter address name"
                  />
                )}

                {touched.customName && errors?.customName && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.customName}
                  </Typography>
                )}
              </Box>

              {/* Address Line One */}
              <Box sx={{ gridArea: "addressLineOne" }}>
                <InputLabel
                  htmlFor="addressLineOne"
                  sx={{ marginBottom: "8px" }}
                >
                  Address{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <PlacesAutocomplete
                  value={address}
                  disabled={isAddressSelected}
                  onChange={(value) => {
                    setAddress(value);
                    handleInputChange(); // Mark form as touched on input change
                    setIsAddressSelected(false);
                    setFieldValue("isAddressSelected", false);
                  }}
                  onSelect={(value) => {
                    handleSelect(value, setFieldValue);
                  }}
                  searchOptions={{
                    types: ["address"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div style={{ position: "relative", width: "100%" }}>
                      <TextField
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                        })}
                        id="addressLineOne"
                        name="addressLineOne"
                        fullWidth
                        disabled={isAddressSelected}
                        variant="outlined"
                        sx={{ ...theme.sharedStyles }}
                        error={
                          touched.addressLineOne &&
                          Boolean(errors.addressLineOne)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {address && (
                                <IconButton
                                  onClick={() =>
                                    handleClearAddress(setFieldValue)
                                  }
                                  aria-label="clear address"
                                >
                                  <IoCloseCircleOutline />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Paper
                        sx={{
                          marginTop: "-15px !important",
                          position: "absolute",
                          zIndex: 2100,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          borderRadius: "4px",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const { key, ...suggestionProps } =
                            getSuggestionItemProps(suggestion);
                          const isActive = suggestion.active;
                          return (
                            <Box
                              key={suggestion.description}
                              {...suggestionProps}
                              sx={{
                                padding: "10px 16px",
                                backgroundColor: isActive
                                  ? "rgba(0, 0, 0, 0.1)"
                                  : "white",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                                },
                                borderBottom: "1px solid #eee",
                              }}
                            >
                              <Typography variant="body2">
                                {suggestion.description}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Paper>
                      {/* <Card id="search-address" >
              <CardContent>
                {suggestions?.length === 0 ? (
                  <div>loading ...</div>
                ) : (
                  suggestions?.map((s, index) => {
                    return (
                      <div
                        {...getSuggestionItemProps(s)}
                        key={index}
                
                      >
                        <Grid container alignItems="center">
                          <Grid item>
                            <IoLocation  />
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="body1"
                              color="textPrimary"
                         
                            >
                              {s?.formattedSuggestion?.mainText}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {s?.formattedSuggestion?.secondaryText}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })
                )}
              </CardContent>
            </Card> */}
                    </div>
                  )}
                </PlacesAutocomplete>

                {touched.addressLineOne && errors.addressLineOne && (
                  <Typography variant="caption" color="error">
                    {errors.addressLineOne}
                  </Typography>
                )}
              </Box>

              {/* addressLineTwo */}
              <Box sx={{ gridArea: "addressLineTwo" }}>
                <InputLabel sx={inputStyle} htmlFor="addressLineTwo">
                  Apt./suite (optional)
                </InputLabel>
                <TextField
                  id="addressLineTwo"
                  name="addressLineTwo"
                  fullWidth
                  variant="outlined"
                  value={values.addressLineTwo}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e); // Mark form as touched on input change
                  }}
                  onBlur={handleBlur("addressLineTwo")}
                  sx={sharedStyles}
                  error={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  placeholder="Enter Apt./Suite"
                />
                {touched.addressLineTwo && errors?.addressLineTwo && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.addressLineTwo}
                  </Typography>
                )}
              </Box>

              {/* City */}
              <Box sx={{ gridArea: "city" }}>
                <InputLabel sx={inputStyle} htmlFor="city">
                  City{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="city"
                  name="city"
                  fullWidth
                  variant="outlined"
                  value={values.city}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e); // Mark form as touched on input change
                  }}
                  onBlur={handleBlur("city")}
                  sx={sharedStyles}
                  error={touched.city && Boolean(errors.city)}
                  placeholder="Enter city"
                />
                {touched.city && errors?.city && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.city}
                  </Typography>
                )}
              </Box>

              {/* State */}
              <Box sx={{ gridArea: "state" }}>
                <InputLabel sx={inputStyle} htmlFor="state">
                  State{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="state"
                  name="state"
                  fullWidth
                  variant="outlined"
                  value={values.state}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e); // Mark form as touched on input change
                  }}
                  onBlur={handleBlur("state")}
                  sx={sharedStyles}
                  error={touched.state && Boolean(errors.state)}
                  placeholder="Enter state"
                />
                {touched.state && errors?.state && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.state}
                  </Typography>
                )}
              </Box>

              {/* Zip Code */}
              <Box sx={{ gridArea: "zipCode" }}>
                <InputLabel sx={inputStyle} htmlFor="zipCode">
                  Zip Code{" "}
                  <Box component="span" color="red">
                    *
                  </Box>
                </InputLabel>
                <TextField
                  id="zipCode"
                  name="zipCode"
                  fullWidth
                  variant="outlined"
                  value={values.zipCode}
                  onChange={(e) => {
                    handleChange(e);
                    handleInputChange(e); // Mark form as touched on input change
                  }}
                  onBlur={handleBlur("zipCode")}
                  sx={sharedStyles}
                  error={touched.zipCode && Boolean(errors.zipCode)}
                  placeholder="Enter zip code"
                />
                {touched.zipCode && errors?.zipCode && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors?.zipCode}
                  </Typography>
                )}
              </Box>

              {/* Checkboxes */}
              <Box sx={{ gridColumn: "1 / span 3" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isDefault}
                        onChange={(e) => {
                          if (
                            isDefeultSelectedData ||
                            userAddressData?.length === 0
                          ) {
                            message.warning(
                              "Please choose a new default address before deselecting the current one."
                            );
                          } else {
                            handleChange(e);
                            handleInputChange(e);
                          }
                        }}
                        name="isDefault"
                      />
                    }
                    label="Use as my default Delivering address"
                  />
                </FormGroup>
              </Box>

              {/* Save Changes Button */}
              <Box sx={{ gridColumn: "1 / span 3" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    paddingY: "16px",
                    paddingX: "32px",
                    borderRadius: "8px",
                  }}
                >
                  {docId ? "edit address" : "add address"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AddressForm;
