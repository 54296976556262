import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

import {
  setCurrentProduct,
  setItemsCategories,
  setProducts,
} from "../../../redux/slices/appStateSlice";
import { db } from "../../../configs/firebase";
import Loader from "../../../components/common/Loader";
import ItemsProducts from "../../../components/common/ItemsProducts";
import StoreButtons from "../../../components/common/StoreButton";

const Products = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.appState.userData);
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  
  const [displayedProducts, setdisplayedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const slider = React.useRef(null);
  const [activeArrow, setActiveArrow] = useState("right");
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isLoggedIn =
    userData &&
    !(Array.isArray(userData) && userData.length === 0) &&
    typeof userData === "object" &&
    userData.uid;

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const docs = db.collection("itemCategories").orderBy("index", "asc");

      let fetchedCategories = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedCategories.push(doc.data());
        });

        if (fetchedCategories?.length > 0) {
          dispatch(setItemsCategories(fetchedCategories));
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!itemsCategories || itemsCategories.length <= 1) {
      getCategories();
    }
  }, [itemsCategories]);

  const handlePrev = () => {
    setActiveArrow("left");
    slider?.current?.slickPrev();
  };

  const handleNext = () => {
    setActiveArrow("right");
    slider?.current?.slickNext();
  };

  const goToProductPage = (id, product, nameToDisplay) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setCurrentProduct(product));
    navigate(`/product/${id}`, { state: { nameToDisplay } });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setIsLoading(true);
    try {
      const productsRef = db
        .collection("products")
        .where("isActive", "==", true)
       
        .where("isDeleted", "==", false)
        .orderBy("createdAt", "desc");

      const querySnapshot = await productsRef.get();

      let products = [];
      querySnapshot.forEach((doc) => {
        products.push(doc.data());
      });
      setdisplayedProducts(products);
      dispatch(setProducts(products));
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (location.state && location.state.scrollToRequests) {
      const requestsSection = document.getElementById("products-section");
      if (requestsSection) {
        requestsSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section
        style={{
          background: theme.palette.primary.light,
          borderRadius: "32px",
          padding: "30px 0px",
          margin: "0 25px",
        }}
        id="products-section"
      >
        {displayedProducts && displayedProducts?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0 20px",
              padding: "20px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: theme.typography.headerFont,
                fontWeight: 400,
                lineHeight: "1.65rem",
                color: theme.palette.text.primary,
              }}
            >
              Our Parts
            </Typography>

            <Box
              sx={{
                backgroundColor: theme.palette.primary.background,
                width: "115px",
                height: "43px",
                borderRadius: "70px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  background:
                    activeArrow === "left"
                      ? theme.palette.primary.main
                      : "transparent",
                  width: "48px",
                  height: "33px",
                  borderRadius: "40px",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handlePrev}
              >
                <HiOutlineArrowNarrowLeft
                  style={{
                    fontSize: "x-large",
                    height: "25px",
                    color:
                      activeArrow === "left"
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                  }}
                />
              </button>
              <button
                style={{
                  background:
                    activeArrow === "right"
                      ? theme.palette.primary.main
                      : "transparent",
                  width: "47px",
                  height: "27px",
                  borderRadius: "40px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleNext}
              >
                <HiOutlineArrowNarrowRight
                  style={{
                    fontSize: "x-large",
                    height: "25px",
                    color:
                      activeArrow === "right"
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                  }}
                />
              </button>
            </Box>
          </Box>
        )}
        <Slider {...settings} ref={slider}>
          {isLoading && !displayedProducts?.length ? (
            <Grid
              container
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <Loader />
            </Grid>
          ) : (
            displayedProducts?.map((product) => {
              const categoryName =
              itemsCategories?.find((cat) => cat?.id === product?.categoryId)
                  ?.name || "Unknown Category";

              return (
                <ItemsProducts
                  key={product?.id}
                  item={product}
                  index={product?.id}
                  goToPage={goToProductPage}
                  isLoggedIn={isLoggedIn}
                  categoryHomeName={categoryName}
                  isMediumScreen={isMediumScreen}
                  isSmallScreen={isSmallScreen}
                />
              );
            })
          )}
        </Slider>
        <Box
          sx={{
            height: { xs: "100%", md: "136px" },
            textAlign: { xs: "center", md: "left" },
            padding: "40px",
            borderRadius: "32px",
            display: "flex",
            flexDirection: { lg: "row", md: "row", xs: "column" },
            justifyContent: "space-between",
            alignItems: "center",
            background: theme.palette.primary.background,
            margin: {
              sm: "0px 10px 30px",
              md: "30px 30px 30px",
              lg: "60px 30px 30px",
            },
          }}
        >
          <Box>
            <div>
              <Typography
                variant="h6"
                sx={{
                  fontWeigh: 900,
                  lineHeight: "1.375rem",
                  color: theme.palette.text.primary,
                }}
              >
                Download Apps
              </Typography>
            </div>
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.primary.dark.dustyBlue,
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              Download Apps on Google Play Store and Apple Store
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: theme.spacing(1),
                marginTop: { xs: "15px", sm: "15px", md: "0" },
              }}
            >
              <StoreButtons />
            </Box>
          </Box>
        </Box>
      </section>
    </>
  );
};

export default Products;
