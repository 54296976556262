import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../configs/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import MobileStipper from "./components/MobileStipper";
import Loader from "../../components/common/Loader";
import { FaRegCheckCircle, FaBoxOpen, FaTruck, FaHome } from "react-icons/fa";
import { useSelector } from "react-redux";
import { message, Modal } from "antd";
import profilePlacholder from "../../assets/Images/profilePlaceholder.png";
import VerifiedBox from "../../components/common/VerifiedBox";
import useFormattedDate from "../../utils/useFormattedDate";
import { styled } from "@mui/system";
import ReviewModal from "../../components/common/ReviewModal";
import LocationMapModal from "../../components/common/LocationMapModal";

const TrackOrder = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [isMapOpen, setMapOpen] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const statusSteps = ["pending", "shipped", "completed"];
  const userData = useSelector((state) => state.appState.userData);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [buyerName, setBuyerName] = useState(null);
  const [sellerName, setSellerName] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      setOrderLoading(true);
      if (orderId) {
        setOrderLoading(true);
        try {
          const orderDoc = doc(db, "orders", orderId);
          const orderSnapshot = await getDoc(orderDoc);
          if (orderSnapshot?.exists()) {
            const orderData = orderSnapshot.data();
            if (
              orderData?.buyer_id !== userData?.uid &&
              orderData?.seller_id !== userData?.uid
            ) {
              message.error("You are not authorized to view this order.");
              navigate("/profile?page=orders");
              return;
            }
            setOrder({ id: orderSnapshot?.id, ...orderSnapshot?.data() });
          } else {
            console.error("No such document!");
          }
        } catch (error) {
          console.error("Error fetching order: ", error);
        } finally {
          setOrderLoading(false);
        }
      }
    };

    fetchOrder();
  }, [navigate, orderId, userData?.uid]);

  // Fetch buyer and seller names
  useEffect(() => {
    const fetchUserInfo = async (userId, isBuyer) => {
      try {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData?.userType === "buyer") {
            return `${userData?.firstName} ${userData?.lastName}`;
          } else if (
            userData?.userType === "seller" ||
            userData?.userType === "serviceProvider" ||
            userData?.userType === "all"
          ) {
            return userData?.businessInfo?.name;
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    const fetchNames = async () => {
      if (order) {
        if (order?.buyer_info?.id) {
          const name = await fetchUserInfo(order?.buyer_info?.id, true);
          setBuyerName(name);
        }

        if (order?.seller_info?.id) {
          const name = await fetchUserInfo(order?.seller_info?.id, false);
          setSellerName(name);
        }
      }
    };

    fetchNames();
  }, [order]);

  const handleReviewModalCancel = () => setIsReviewModalVisible(false);

  const handleOpenMap = () => {
    setMapOpen(true);
  };

  const handleCloseMap = () => {
    setMapOpen(false);
  };

  const handleReviewSubmit = () => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      isReviewed: true,
    }));
    setIsReviewModalVisible(false);
  };
  // Handle the actual status update after confirmation

  const getStepColor = (step) => {
    const currentStepIndex = statusSteps.indexOf(order?.status);
    const stepIndex = statusSteps.indexOf(step);

    if (stepIndex < currentStepIndex) {
      return theme.palette.status.success; // colors of completed steps
    } else if (stepIndex === currentStepIndex) {
      return theme.palette.status.success; // colors of active step
    } else {
      return theme.palette.neutral.MoustacheGrey; // colors of future steps
    }
  };

  if (orderLoading) {
    return <Loader height="100vh" />;
  }

  if (!orderLoading && !order) {
    return (
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography variant="body1">there is no order</Typography>
      </Stack>
    );
  }

  const goToSellerPage = (uid) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/sellers/seller-details/${uid}`);
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.primary.background,
        // minHeight: "100vh",
        paddingX: {
          xs: "16px",
          sm: "24px",
          xl: "40px",
        },
      }}
      alignItems="center"
      justifyContent="center"
      gap="40px"
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          border: "1px solid",
          borderColor: theme.palette.action.selected,
          borderRadius: "8px",
          width: {
            xs: "100%",
            sm: "85%",
            xl: "70rem",
          },
        }}
      >
        {/* Track header */}
        <Box
          sx={{
            backgroundColor: theme.palette.primary.whiteSmoke,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            padding: "10px 24px",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: theme.palette.primary.lightDoveGrey,
                textTransform: "capitalize",
              }}
            >
              order
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.primary.lightDoveGrey }}
            >
              #{order?.order_no}
            </Typography>
          </Stack>
        </Box>

        {/* Track status for larger screens */}
        <Stack
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
        >
          {statusSteps?.map((step, index) => (
            <Stack
              key={step}
              alignItems="center"
              justifyContent="center"
              sx={{
                flex: 1,
                borderBottom: `2px solid ${getStepColor(step)}`,
                paddingY: "15px",
              }}
            >
              <Stack direction="row" gap={1} alignItems="center">
                {index === 0 && <FaBoxOpen color={getStepColor(step)} />}
                {index === 1 && <FaTruck color={getStepColor(step)} />}
                {index === 2 && <FaHome color={getStepColor(step)} />}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    color: getStepColor(step),
                  }}
                >
                  {step === "pending" ? "Preparing" : step}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>

        {/* Mobile Stepper for smaller screens */}
        <MobileStipper
          currentStatus={order?.status}
          sellerId={order?.seller_id}
          userData={userData}
        />

        {/* Track details */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr",
            },
            gap: 2,
            padding: "40px 30px",
            alignItems: "stretch",
          }}
        >
          {/* Left Section - Customer Info */}
          <Stack gap={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                boxShadow: `0px 0px 36px 0px ${theme.palette.customShadows.lightGrey}`,
                minHeight: "190px",
                borderRadius: "10px",
                padding: 2,
                height: "100%",
              }}
            >
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "600", textTransform: "capitalize" }}
                >
                  {order?.seller_id === userData?.uid
                    ? "purchased by:"
                    : "sold by:"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.neutral.MoustacheGrey,
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    goToSellerPage(
                      order?.seller_id === userData?.uid
                        ? order?.buyer_info?.id
                        : order?.seller_info?.id
                    )
                  }
                >
                  {order?.seller_id === userData?.uid
                    ? order?.buyer_info?.name?.length > 20
                      ? `${order?.buyer_info?.name.substring(0, 20)}...`
                      : order?.buyer_info?.name
                    : order?.seller_info?.fetchNames?.length > 20
                    ? `${(order?.seller_info?.businessName).substring(
                        0,
                        20
                      )}...`
                    : order?.seller_info?.businessName}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  shipping address:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.neutral.MoustacheGrey,
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenMap}
                >
                  {order?.delivery_address?.addressLineOne},{" "}
                  {order?.delivery_address?.city},{" "}
                  {order?.delivery_address?.state},{" "}
                  {order?.delivery_address?.country}
                </Typography>
              </Stack>

              <Typography
                variant="body2"
                sx={{ color: theme.palette.neutral.MoustacheGrey }}
              >
                <strong
                  style={{
                    color: theme.palette.primary.lightDoveGrey,
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  Payment method:
                </strong>{" "}
                {order?.payment_method}
              </Typography>
            </Box>
            {order?.status === "completed" &&
              !order?.isReviewed &&
              order?.seller_id !== userData?.uid && (
                <Stack
                  sx={{
                    boxShadow: `0px 0px 36px 0px ${theme.palette.customShadows.lightGrey}`,
                    borderRadius: "10px",
                    padding: 2,
                  }}
                  gap={1}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                      color: theme.palette.primary.lightDoveGrey,
                      textTransform: "capitalize",
                    }}
                  >
                    seller
                  </Typography>
                  <Divider
                    sx={{
                      borderColor: theme.palette.gray.lightGray,
                      marginBottom: "10px",
                    }}
                  />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" alignItems="center" gap={2}>
                      <img
                        src={
                          order?.seller_info?.profilePicture &&
                          order?.seller_info?.profilePicture.length > 0
                            ? order?.seller_info?.profilePicture
                            : profilePlacholder
                        }
                        alt={order?.seller_info?.name}
                        loading="lazy"
                        style={{
                          width: "48px",
                          height: "48px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(
                            `/sellers/seller-details/${order?.seller_id}`
                          );
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      />

                      <Stack gap={1}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography
                            variant="body2"
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(
                                `/sellers/seller-details/${order?.seller_id}`
                              );
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                          >
                            {order?.seller_info?.name
                              ? order.seller_info.name.length > 10
                                ? `${order.seller_info.name.substring(
                                    0,
                                    18
                                  )}...`
                                : order.seller_info.name
                              : ""}
                          </Typography>
                          <VerifiedBox />
                        </Stack>
                      </Stack>
                    </Stack>
                    <>
                      <Button
                        sx={{
                          backgroundColor: theme.palette.primary.light,
                          color: theme.palette.primary.main,
                          textTransform: "capitalize",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          fontSize: "12px",
                          fontWeight: "600",
                          border: "1px solid",
                          width: "100px",
                          borderColor: theme.palette.neutral.blueCyan,
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.main,
                            boxShadow: "none",
                          },
                        }}
                        onClick={() => setIsReviewModalVisible(true)}
                      >
                        review
                      </Button>
                      <ReviewModal
                        isVisible={isReviewModalVisible}
                        onClose={handleReviewModalCancel}
                        order={order}
                        currentUser={userData}
                        onReviewSubmit={handleReviewSubmit}
                      />
                    </>
                  </Stack>
                </Stack>
              )}
          </Stack>

          {/* Right Section - Order Summary */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              boxShadow: `0px 0px 36px 0px ${theme.palette.customShadows.lightGrey}`,
              borderRadius: "10px",
              padding: 2,
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.lightDoveGrey,
                fontSize: "600",
                letterSpacing: "-3%",
                textTransform: "capitalize",
              }}
            >
              Order Summary
            </Typography>
            <Divider />
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "repeat(5, auto)",
                gap: 2,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.neutral.greyBlue,
                    fontWeight: "700",
                    lineHeight: "20.8px",
                    textTransform: "capitalize",
                  }}
                >
                  subtotal:
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.neutral.greyBlue,
                    fontWeight: "700",
                    lineHeight: "20.8px",
                  }}
                >
                  $
                  {Number.isInteger(order?.subtotal)
                    ? `${order.subtotal}.00`
                    : order?.subtotal?.toFixed(2) || "0.00"}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.neutral.greyBlue,
                    lineHeight: "20.8px",
                    textTransform: "capitalize",
                  }}
                >
                  shipping cost:
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.neutral.greyBlue,
                    lineHeight: "20.8px",
                  }}
                >
                  {order?.total_shipping_cost === 0
                    ? "Free"
                    : `$${
                        Number.isInteger(order?.total_shipping_cost)
                          ? `${order.total_shipping_cost}.00`
                          : order?.total_shipping_cost?.toFixed(2) || "0.00"
                      }`}
                  {/* ${order?.total_shipping_cost} */}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.main,
                    lineHeight: "20.8px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    fontSize: "18px",
                  }}
                >
                  total price:
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    lineHeight: "20.8px",
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                  ${order?.total?.toFixed(2)}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>

      <Button
        variant="contained"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate("/explore?page=items");
        }}
        sx={{
          padding: "12px 40px",
          borderRadius: "8px",
          textTransform: "capitalize",
          boxShadow: "none",
        }}
      >
        continue shopping
      </Button>
      <LocationMapModal
        open={isMapOpen}
        onClose={handleCloseMap}
        lat={order?.delivery_address?.lat}
        lng={order?.delivery_address?.lng}
        address={order?.delivery_address}
      />
    </Stack>
  );
};

export default TrackOrder;
