
import { Box, LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../configs/firebase";
import noItems from "../../../assets/Images/no-Items.png";

const RatingsComponent = ({ filteredUser }) => {
  const theme = useTheme();

  const [ratingPercentages, setRatingPercentages] = useState({
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  });
  const [totalReviews, setTotalReviews] = useState(0);

  useEffect(() => {
    const fetchRatings = async () => {
      const reviewsCollectionRef = collection(
        db,
        "users",
        filteredUser?.uid,
        "reviews"
      );

      const reviewDocs = await getDocs(reviewsCollectionRef);
      const ratingsCount = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
      let totalReviews = 0;

      reviewDocs.forEach((doc) => {
        const rating = doc.data().rating;
        if (ratingsCount[rating] !== undefined) {
          ratingsCount[rating] += 1;
          totalReviews += 1;
        }
      });

      const calculatedPercentages = {};
      for (let star = 1; star <= 5; star++) {
        calculatedPercentages[star] =
          totalReviews > 0 ? (ratingsCount[star] / totalReviews) * 100 : 0;
      }

      setRatingPercentages(calculatedPercentages);
      setTotalReviews(totalReviews);
    };

    if (filteredUser?.uid) {
      fetchRatings();
    }
  }, [filteredUser]);

  const renderStars = (count) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <FaStar
          key={i}
          style={{
            color:
              i < count
                ? theme.palette.status.star
                : theme.palette.gray.overlayGray,
            width: "25px",
            height: "25px",
            marginRight: "5px",
          }}
        />
      );
    }
    return stars;
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: theme.palette.primary.light,
        borderRadius: "10px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          color: theme.palette.dark.charcoalBlack,
          textAlign: "left",
          marginBottom: "20px",
        }}
      >
        Rating
      </Typography>

      {totalReviews > 0 ? (
        <>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: theme.palette.dark.colorCart }}
          >
            Overall Rating
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: theme.palette.primary.main,
              textAlign: "center",
            }}
          >
            {filteredUser?.reviewsRate?.toFixed(1)}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            {renderStars(Math.round(filteredUser?.reviewsRate))}
          </Box>

          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: theme.palette.gray.overlayGray }}
          >
            Based on {filteredUser?.reviewsCount} ratings
          </Typography>

          <Box sx={{ flexGrow: 1 }}>
            {[5, 4, 3, 2, 1].map((star) => (
              <Box
                key={star}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                  padding: "0 15px",
                  mb: 3,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.gray.overlayGray,
                  }}
                >
                  {star} Star
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={ratingPercentages[star]}
                  sx={{
                    flexGrow: 1,
                    height: "20px",
                    margin: "0 10px",
                    borderRadius: "5px",
                    backgroundColor: theme.palette.neutral.rating,
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                />
                <Typography variant="body2" sx={{ width: "30px" }}>
                  {ratingPercentages[star]?.toFixed(0)}%
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <Stack justifyContent="center" alignItems="center" gap={1}>
          <img src={noItems} alt="no reviews yet"  loading="lazy"  style={{width:"100px",height:"100px",objectFit:"cover"}}/>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              color: theme.palette.gray.overlayGray,
              textTransform: "capitalize",
            }}
          >
            no reviews yet
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default RatingsComponent;
