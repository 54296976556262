import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { LuPhone } from "react-icons/lu";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaStar } from "react-icons/fa";
import Avatar from "./Avatar";
import Loader from "../../../components/common/Loader";
import { BiSolidUser } from "react-icons/bi";

const UserCard = React.memo(({ user, key, goToPage }) => {
  const theme = useTheme();
  const [imageLoaded, setImageLoaded] = useState(false);


  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <Box
      key={key}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "100%",
        backgroundColor: theme.palette.primary.light,
        borderRadius: "15px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "280px",
          borderRadius: "24px",
          margin: "auto",
          boxSizing: "border-box",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {user?.profilePicture ? (
          <>
            {!imageLoaded && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: theme.palette.grey[200],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">
                  <Loader />
                </Typography>
              </Box>
            )}

            <img
              src={user?.profilePicture}
              alt={user?.businessInfo?.name}
              loading="lazy"
              onLoad={handleImageLoad}
              style={{
                borderRadius: "24px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => goToPage(user?.uid)}
            />
          </>
        ) : (
          <Avatar
          name={
            user?.businessInfo?.name
              ? user.businessInfo.name.length > 10
                ? `${user.businessInfo.name.substring(0, 10)}...`
                : user.businessInfo.name
              : "User"
          }
            uid={user?.uid}
            goToPage={goToPage}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            backgroundColor: theme.palette.dark.black08Opacity,
            color: theme.palette.primary.light,
            padding: "5px 10px",
            borderRadius: "12px",
            fontSize: ".9rem",
          }}
        >
          <BiSolidUser
            style={{ color: theme.palette.primary.light, fontSize: "1.7rem" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "left",
          color: theme.palette.primary.darkMain,
          padding: "15px 10px 0",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
              lineHeight: "1.4rem",
              color: theme.palette.primary.darkMain,
            }}
          >
           
            {
            user?.businessInfo?.name
              ? user.businessInfo.name.length > 20
                ? `${user.businessInfo.name.substring(0, 20)}...`
                : user.businessInfo.name
              : "Business name"
          }
          </Typography>
          {(user?.userType === "seller" || user?.userType === "all") && <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
              lineHeight: "1.4rem",
              color: theme.palette.primary.darkMain,
            }}
          >
            <FaStar
              style={{
                color: theme.palette.status.warning,
              }}
            />{" "}
            {user?.reviewsRate || 0}
          </Typography>}  
        </Box>
        <Typography
          variant="body1"
          sx={{
            lineHeight: "1.4rem",
            color: theme.palette.dark.dustyBlue,
            marginBottom: "5px",
          }}
        >
          <LuPhone
            style={{ color: theme.palette.primary.main, marginRight: "10px" }}
          />{" "}
          {user?.businessInfo?.phoneNumber || "No phone number"}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            lineHeight: "1.4rem",
            color: theme.palette.dark.dustyBlue,
            marginBottom: "5px",
          }}
        >
          <HiOutlineLocationMarker
            style={{ color: theme.palette.primary.main, marginRight: "10px" }}
          />{" "}
          {user?.businessInfo?.address?.city ||
          user?.businessInfo?.address?.state ||
          user?.businessInfo?.address?.country
            ? `${user?.businessInfo?.address?.city || ""}${
                user?.businessInfo?.address?.city &&
                user?.businessInfo?.address?.state
                  ? ", "
                  : ""
              }${user?.businessInfo?.address?.state || ""}${
                (user?.businessInfo?.address?.city ||
                  user?.businessInfo?.address?.state) &&
                user?.businessInfo?.address?.country
                  ? ", "
                  : ""
              }${user?.businessInfo?.address?.country || ""}`
            : "No Address"}
        </Typography>
      </Box>
    </Box>
  );
}
);

export default UserCard;
