import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const userData = useSelector((state) => state.appState.userData);
  let location = useLocation();

  if (
    !userData ||
    (Array.isArray(userData) && userData.length === 0) ||
    (typeof userData === "object" && !userData.uid) 
  ) {
    return <Navigate to="/logIn" state={{ from: location }} replace />;
  }

  return element;
};

export default ProtectedRoute;
