import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { db } from "../../configs/firebase";
import Loader from "../../components/common/Loader";
import { setItemsCategories } from "../../redux/slices/appStateSlice";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ItemSection from "../../components/common/ItemSection";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/common/SearchBar";
import { message } from "antd";
import CustomPagination from "../../components/common/CustomPagention";

const Categories = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productsPerPage = 12;
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrderControle, setSortOrderControle] = useState("default");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSortOpen, setIsSortOpen] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    filterCategories();
  }, [searchTerm, categories, sortOrderControle]);

  const getCategories = async () => {
    try {
      const docs = db.collection("itemCategories").orderBy("index", "asc");

      let categories = [];
      await docs.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          categories.push(doc.data());
        });
        setCategories(categories);
        dispatch(setItemsCategories(categories));
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
      message.error(
        "There was an error fetching the categories. Please try again later."
      );
      setIsLoading(false);
    }
  };

  const filterCategories = () => {
    let filtered = categories;

    // Filter by search term
    if (searchTerm?.trim() !== "") {
      filtered = categories?.filter((category) =>
        category?.name.toLowerCase().includes(searchTerm?.toLowerCase())
      );
    }

    // Create a shallow copy of the array before sorting
    filtered = [...filtered]; // or filtered.slice()

    // Sort by importance (default) or alphabetically
    if (sortOrderControle === "alphabetically") {
      filtered.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      filtered.sort((a, b) => a.index - b.index); // assuming importance is determined by 'index'
    }

    setDisplayedCategories(filtered);
  };

  const handleSortOrderChange = (value) => {
    setSortOrderControle(value);
  };
  
  
  const handleSortDropdownToggle = (isOpen) => {
    setIsSortOpen(isOpen);
  };

  const goToCategoryPage = (id, name) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // navigate(`/categories/${id}`, { state: { name } });
    navigate(`/filtered-categories?category-Id=${id}&category-name=${name}`);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = displayedCategories?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const handlePageChange = (event, value) => {
    window.scrollTo({ top: 500, behavior: "smooth" });
    setCurrentPage(value);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section style={{ margin: "0px 25px 0px 25px" }}>
        <Box
          sx={{
            marginBottom: displayedCategories?.length > 0 && "20px",
            width: "100%",
            maxWidth: "729px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            sortOrderControle={sortOrderControle}
            handleSortOrderChange={handleSortOrderChange}
            handleSortDropdownToggle={handleSortDropdownToggle}
            placeholder="Search Categories..."
          />
        </Box>

        {isLoading ? (
          <Grid
            container
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <Loader />
          </Grid>
        ) : currentProducts.length > 0 ? (
          <Grid
            container
            sx={{
              justifyContent: "center",
              position: "relative",
              bottom: "50px",
              marginTop: isSmallScreen ? (isSortOpen ? "80px" : "0px") : "80px",
            }}
          >
            {currentProducts.map((item, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                xl={1.7}
                key={item?.id}
                sx={{
                  justifyConent: "center",
                  paddingRight: "0",
                }}
              >
                <ItemSection
                  key={item?.id}
                  item={item}
                  index={index}
                  widthCategorie
                  paddingCategories
                  marginRightCategorie
                  goToPage={goToCategoryPage}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            No categories available.
          </Typography>
        )}

        {/* Pagination Component */}
        {displayedCategories.length > 0 && (
          <CustomPagination
            totalPages={Math.max(1, Math.ceil(displayedCategories.length / 12))}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        )}
      </section>
    </>
  );
};

export default Categories;
