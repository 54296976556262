import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import {
  setCurrentProduct,
  setProducts,
} from "../../../redux/slices/appStateSlice";
import { db } from "../../../configs/firebase";
import Loader from "../../../components/common/Loader";
import ItemsProducts from "../../../components/common/ItemsProducts";

const SavedForLater = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const userData = useSelector((state) => state.appState.userData);
  const [displayedProducts, setdisplayedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const slider = React.useRef(null);
  const [activeArrow, setActiveArrow] = useState("right");
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const itemsCategories = useSelector(
    (state) => state.appState.itemsCategories
  );
  const servicesCategories = useSelector(
    (state) => state.appState.servicesCategories
  );
  
  const isLoggedIn =
    userData &&
    !(Array.isArray(userData) && userData.length === 0) &&
    typeof userData === "object" &&
    userData.uid;

    const getCategoryName = (categoryId) => {
      const category = itemsCategories?.find((cat) => cat?.id === categoryId);
      
      return category ? category?.name : "Unknown Category";
    };
  
    const getServiceName = (categoryId) => {
      if (!servicesCategories.length) return "Loading...";
   
      const category = servicesCategories.find(
        (service) => service.id === categoryId
      );
      return category ? category.name : "Unknown service";
    };
    
    
  const handlePrev = () => {
    setActiveArrow("left");
    slider?.current?.slickPrev();
  };

  const handleNext = () => {
    setActiveArrow("right");
    slider?.current?.slickNext();
  };

  const goToProductPage = (id, product, nameToDisplay) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setCurrentProduct(product));
    navigate(`/product/${id}`, { state: { nameToDisplay } });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const productsRef = db
        .collection("products")
        .where("isActive", "==", true)
        .where("isDraft", "==", false)
        .where("isSold", "==", false)
      .where('isDeleted', '==', false);

      const querySnapshot = await productsRef.get();

      let products = [];
      querySnapshot.forEach((doc) => {
        products.push(doc.data());
      });
      setdisplayedProducts(products);
      dispatch(setProducts(products));
      
    } catch (error) {
      console.error("Error fetching categories:", error);
      setIsLoading(false);
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section
        style={{
          background: theme.palette.primary.light,
          borderRadius: "32px",
          padding: "30px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0 20px",
            padding: "20px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.typography.headerFont,
              fontWeight: 400,
              lineHeight: "1.65rem",
              color: theme.palette.text.primary,
            }}
          >
            saved for later
          </Typography>

          <Box
            sx={{
              backgroundColor: theme.palette.primary.background,
              width: "115px",
              height: "43px",
              borderRadius: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                background:
                  activeArrow === "left"
                    ? theme.palette.primary.main
                    : "transparent",
                width: "48px",
                height: "33px",
                borderRadius: "40px",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handlePrev}
            >
              <HiOutlineArrowNarrowLeft
                style={{
                  fontSize: "x-large",
                  height: "25px",
                  color:
                    activeArrow === "left"
                      ? theme.palette.primary.light
                      : theme.palette.primary.main,
                }}
              />
            </button>
            <button
              style={{
                background:
                  activeArrow === "right"
                    ? theme.palette.primary.main
                    : "transparent",
                width: "47px",
                height: "27px",
                borderRadius: "40px",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleNext}
            >
              <HiOutlineArrowNarrowRight
                style={{
                  fontSize: "x-large",
                  height: "25px",
                  color:
                    activeArrow === "right"
                      ? theme.palette.primary.light
                      : theme.palette.primary.main,
                }}
              />
            </button>
          </Box>
        </Box>
        <Slider {...settings} ref={slider}>
          {displayedProducts.map((product, index) => (
            <ItemsProducts
              product={product}
              key={product?.id || index}
              item={product}
              index={product?.id}
              goToPage={goToProductPage}
              isLoggedIn={isLoggedIn}
              isMediumScreen={isMediumScreen}
              isSmallScreen={isSmallScreen}
              categorySavedForLaterName={getCategoryName(product?.categoryId)}
              serviseSavedForLaterName={getServiceName(product?.categoryId)}
            />
          ))}
        </Slider>
      </section>
    </>
  );
};

export default SavedForLater;
