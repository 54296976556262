import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./redux/store";
import appRoutes from "./routes/appRoutes";
import theme from "./configs/theme";
import { restUserData, setUserData } from "./redux/slices/appStateSlice";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./configs/firebase";
import { message } from "antd";

function App() {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false); 
  const [lastLogin, setLastLogin] = useState(null); // Store the last login time

  // Firebase Authentication listener to track user status
  const user = useSelector((state) => state.appState.userData);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);

        // Fetch user data from Firestore including lastLogin
        const userDocRef = db.collection("users").doc(user.uid);
        userDocRef.get().then((snapshot) => {
          if (snapshot.exists) {
            const userData = snapshot.data();
            
            if (userData?.isDeleted) {
              message.warning("Your account has been deleted. You have been signed out.");
              auth.signOut();
              return;
            }
            
            const lastLoginTime =
              userData?.lastLoginInfo?.lastLogin?.seconds * 1000; // Convert seconds to milliseconds
            setLastLogin(lastLoginTime);
            dispatch(setUserData(userData));
          } else {
            console.error("User document does not exist.");
          }
        });
      } else {
        // User is not authenticated, clear Redux and localStorage
        setIsAuthenticated(false);
        dispatch(restUserData());
        localStorage.removeItem("userData");
      }
    });

    return () => unsubscribe();
  }, [dispatch]);
  useEffect(() => {
    if (!user?.uid) return;
    const userDocRef = db.collection("users").doc(user?.uid);
    const unsubscribe = userDocRef.onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          const userData = snapshot.data();
          
          if (userData?.isDeleted) {
            message.warning("Your account has been deleted. You have been signed out.");
            auth.signOut();
            return;
          }
          
          dispatch(setUserData(userData));
        } else {
          console.error("User document does not exist.");
        }
      },
      (error) => {
        console.error("Error fetching user data: ", error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [user?.uid]);

  // Calculate if session has expired based on last login time
  const checkSessionExpiry = () => {
    const currentTime = Date.now();
    const inactivityLimit = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds
    // const inactivityLimit = 1 * 60 * 1000; // 1 minute for testing (adjust as needed)

    if (lastLogin && currentTime - lastLogin > inactivityLimit) {
      if (isAuthenticated) {
        auth.signOut(); // Sign the user out if session expired
        setIsAuthenticated(false); 
        message.warning("Session expired due to inactivity, signing out...");
      }
    }
    
    if (user?.isDeleted) {
      auth.signOut(); // Sign out if user is deleted
      setIsAuthenticated(false);
      message.warning("Your account has been deleted. You have been signed out.");
    }
    
  };
  // Function to check if the session stored in sessionStorage has expired after 24 hours
const checkSessionExpiryFor24Hours = () => {
  const sessionStartTime = sessionStorage.getItem('sessionStartTime');
  const persistenceType = sessionStorage.getItem('persistenceType'); // Should be 'session' for sessionStorage
  const inactivityLimit = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

  // Ensure we're only checking for session storage and session start time exists
  if (persistenceType === 'session' && sessionStartTime) {
    const currentTime = Date.now();
    const sessionDuration = currentTime - sessionStartTime;

    if (sessionDuration > inactivityLimit) {
      // If more than 24 hours have passed, sign the user out
      if (isAuthenticated) {
        auth.signOut(); 
        setIsAuthenticated(false); 
        message.warning("Session expired after 24 hours, signing out...");
      }
    }
  }
  
  if (user?.isDeleted) {
    auth.signOut(); // Sign out if user is deleted
    setIsAuthenticated(false);
    message.warning("Your account has been deleted. You have been signed out.");
  }
};
  // Periodically check if user login with unchecked remember me and make sure that he stays logged in only for 24 hours
  useEffect(() => {
    if (isAuthenticated) {
      const intervalId = setInterval(() => {
        checkSessionExpiryFor24Hours();
      }, 24 * 60 * 60 * 1000); 

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [isAuthenticated]);

  // Periodically check if the session should expire based on last login
  useEffect(() => {
    if (isAuthenticated) {
      const intervalId = setInterval(() => {
        checkSessionExpiry();
      }, 14 * 24 * 60 * 60 * 1000); 

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [isAuthenticated, lastLogin]);

  return (
    <ThemeProvider theme={theme}>
      <PersistGate persistor={persistor} loading={null}>
        <RouterProvider router={appRoutes}>
          <useScrollManager />
        </RouterProvider>
      </PersistGate>
    </ThemeProvider>
  );
}

export default App;
