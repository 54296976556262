import React, { useState } from "react";

import CircleProgress from "../../../components/common/CircularProgress";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import ShippingAddressSelector from "../../cart/components/ShippingAddressSelector";
import map from "../../../assets/Images/map.png";
import { formatAddress } from "../../../utils/reusableFunctions";
import Loader from "../../../components/common/Loader";
import { message } from "antd";
import {
  setFormAddItemData,
  setNewItemAdded,
} from "../../../redux/slices/appStateSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../../configs/firebase";

const ConfirmCSV = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialSetup = sessionStorage.getItem("initialSetupCSV");
  const csvProducts = useSelector((state) => state.appState.csvProducts);
  const userData = useSelector((state) => state.appState.userData);
  const { businessInfo, uid, profilePicture, registeredSince } = userData;
  const [chooseAddressEnabled, setChooseAddressEnabled] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);

  // const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [addressSelectorMode, setAddressSelectorMode] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [selectedAddressId, setSelectedAddressId] = useState(
    userData?.businessInfo?.address ? userData?.businessInfo?.address?.id : null
  );

  const displayedCategories = useSelector(
    (state) => state.appState.itemsCategories
  );

  function getCategoryId(catName) {
    let catId = "";
    displayedCategories?.forEach((ca) => {
      if (catName === ca?.name) {
        catId = ca?.id;
      }
    });
    return catId;
  }

  const formattedAddress = chooseAddressEnabled
    ? selectedAddress?.addressLineOne ||
      userData?.businessInfo?.address?.addressLineOne
      ? formatAddress(selectedAddress || userData?.businessInfo?.address)
      : "No Address Available"
    : userData?.businessInfo?.address?.addressLineOne
    ? formatAddress(userData?.businessInfo?.address)
    : "No Address Available";

  const handleNewAddressModalOpen = () => {
    setIsNewAddressModalOpen(true);
  };

  const getAddressLineTwo = () => {
    if (chooseAddressEnabled) {
      return (
        selectedAddress?.addressLineTwo ||
        userData?.businessInfo?.address?.addressLineTwo
      );
    } else {
      return (
        userData?.businessInfo?.address?.addressLineTwo ||
        "No Address Line Two Available"
      );
    }
  };

  const addressLineTwo = getAddressLineTwo();

  const handleSaveAsDraft = async () => {
    setIsLoadingDraft(true);

    // Ensure there are products in the CSV
    if (csvProducts?.length > 0) {
      setIsLoadingDraft(true);

      // Iterate through csvProducts to process and save them as drafts
      for (let pro of csvProducts) {
        try {
          const images = [pro?.photos];
          const categoryId = getCategoryId(pro?.category);
          const companies = [
            {
              name: pro?.shipping_company_name,
              price: Number(pro?.shipping_company_price),
            },
          ];

          const finalData = {
            images,
            categoryId: categoryId,
            description: pro?.description,
            quantity: Number(pro?.quantity),
            price: Number(pro?.price),
            title: pro?.title,

            isActive: false,
            isDraft: true,
            isDeleted: false,
            isSold: false,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            uid: uid,
            profilePicture: profilePicture || null,
            registeredSince: registeredSince,
            isLocalPickup: pro?.local_pickup?.toLowerCase() === "yes",
            isOnline: pro?.online_payment?.toLowerCase() === "yes",
            shippingAddress: selectedAddress
              ? selectedAddress
              : userData?.businessInfo?.address,
            carsList: [],
            condition: pro?.condition,
            contactForPrice:
              pro?.online_payment?.toLowerCase() === "no" ? true : false,
            dimentions: "",
            name: businessInfo?.name || "",
            video: null,
            warranty: "",
            _geoloc: {
              lat: chooseAddressEnabled
                ? selectedAddress?.lat || businessInfo?.address?.lat
                : businessInfo?.address?.lat || 0,
              lng: chooseAddressEnabled
                ? selectedAddress?.lng || businessInfo?.address?.lng
                : businessInfo?.address?.lng || 0,
            },
            companies,
          };

          // Call handleAddProduct to save the final product data
          await handleAddProduct(finalData);

          message.success(
            "Your items have been successfully saved as drafts. Items are not published yet. To make them public please change their status from draft to selling."
          );

          // Reset data and redirect
          dispatch(setFormAddItemData({}));
          dispatch(setNewItemAdded(true));
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate("/selling?type=items");
        } catch (error) {
          setIsLoadingDraft(false);
          console.error(error);
          message.error("There was an error saving your draft.");
        }
      }
    } else {
      message.error("No products to save.");
      setIsLoadingDraft(false);
    }
  };

  const handleAddProduct = async (finalData) => {
    if (
      userData?.userType === "serviceProvider" &&
      userData?.isDeleted === false
    ) {
      // Update the user's type if they are a service provider
      const userDocRef = db.collection("users").doc(userData?.uid);
      await userDocRef.update({
        userType: "all",
      });
    }

    const docRef = doc(collection(db, "products"));
    finalData.id = docRef.id; // Set the generated ID from Firestore

    await setDoc(docRef, finalData); // Save the product data in Firestore
  };

  return (
    <Box
      sx={{
        padding: "20px",
        margin: "0px 25px",
        background: theme.palette.primary.light,
        borderRadius: "10px",
        position: "relative",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h2"
          sx={{
            color: theme.palette.dark.colorCart,
            fontSize: "24px",
            fontFamily: theme.typography.headerFont,
          }}
        >
          Import CSV file
        </Typography>
        <CircleProgress step={initialSetup === "true" ? 3 : 1} />
      </Stack>
      <Box sx={{ my: 4 }}>
        <Typography
          style={{
            marginBottom: ".3rem",
            color: theme.palette.primary.darkMain,
            fontWeight: 700,
          }}
        >
          Please select an address for your items.
        </Typography>

        <Typography
          style={{ color: theme.palette.primary.darkMain, fontWeight: 400 }}
        >
          You have uploaded {csvProducts?.length} items.
        </Typography>
      </Box>

      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginBottom: "20px" }}
        >
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.dark.charcoalBlack,
              fontWeight: 600,
              marginBottom: "16px",
              textTransform: "capitalize",
            }}
          >
            shipping address
          </Typography>
          <Button
            onClick={() => setChooseAddressEnabled(!chooseAddressEnabled)}
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              color: theme.palette.primary.main,
            }}
          >
            {chooseAddressEnabled ? "Cancel" : "Choose Address"}
          </Button>
        </Stack>
        {chooseAddressEnabled && (
          <>
            <ShippingAddressSelector
              currentUser={userData}
              onSelectAddress={(address) => setSelectedAddressId(address?.id)}
              isModalOpen={isNewAddressModalOpen}
              setIsModalOpen={setIsNewAddressModalOpen}
              selectValue={addressSelectorMode}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              selectedAddressId={selectedAddressId}
            />
            <Button
              variant="outlined"
              onClick={handleNewAddressModalOpen}
              sx={{
                width: "fit-content",
                margin: "10px 0",
                textTransform: "capitalize",
              }}
            >
              add new address
            </Button>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: theme.palette.primary.light,
            boxShadow: "0 0 5px rgba(0,0,0,0.1)",
            padding: "10px 10px 5px 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box>
              <img
                loading="lazy"
                src={map}
                alt="map"
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: theme.palette.dark.charcoalBlack,
                  fontWeight: 600,
                  marginBottom: 1,
                }}
              >
                {chooseAddressEnabled
                  ? selectedAddress?.name ||
                    userData?.businessInfo?.address?.name
                  : userData?.businessInfo?.address?.name || ""}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.gray.colorSummary,
                  fontWeight: 500,
                  marginBottom: 1.5,
                  fontSize: ".9rem",
                }}
              >
                {formattedAddress}
              </Typography>

              {addressLineTwo && (
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.gray.colorSummary,
                    fontWeight: "500",
                  }}
                >
                  Apt/Suite: {addressLineTwo}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>

      <Box>
        <Button
          sx={{ mt: 4, textTransform: "capitalize" }}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            isLoadingDraft || selectedAddress === "" || !csvProducts
              ? true
              : false
          }
          onClick={handleSaveAsDraft}
        >
          {isLoadingDraft ? (
            <Loader color={theme.palette.primary.light} />
          ) : (
            "Save Items as Draft"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmCSV;
