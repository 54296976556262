import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import carWash from "../../../assets/Icons/car-wash.png";
import stashQuestion from "../../../assets/Icons/stash_question.png";
import time from "../../../assets/Icons/time.png";
import Group from "../../../assets/Icons/why_truk_part-_icon.png";
import { useSelector } from "react-redux";

// Reusable Card component
const InfoCard = ({ imageSrc, title, description }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        mb: { xs: 2, sm: 3, md: 4 },
        display: "flex",
        justifyContent: { xs: "center", sm: "flex-start" },
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
        <img src={imageSrc} alt="logo" loading="lazy" />
        <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 800,
              color: theme.palette.text.primary,
              fontSize: "15px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: ".9rem",
              fontWeight: 400,
              lineHeight: "1.6rem",
              color: theme.palette.primary.darkMain,
              marginTop: "15px",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
const WhyTruck = ({ background }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = theme.palette;
  const xdDown = useMediaQuery("(max-width: 443px)");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userData = useSelector((state) => state.appState.userData);

  const styles = {
    headerTitle: {
      fontFamily: theme.typography.headerFont,
      marginBottom: "35px",
    },

    descriptionText: {
      fontFamily: theme.typography.headerFont,
      lineHeight: "2rem",
      fontWeight: "500",
      color: theme.palette.primary.darkMain,
      marginBottom: "60px",
    },
    startSelling: {
      padding: "18px 50px 18px 50px",
      borderRadius: "16px",
      background: colors.primary.main,
      color: colors.primary.light,
      border: "none",
      cursor: "pointer",
      margin: "0 10px",
      opacity: 1,
      marginBottom: xdDown ? "15px" : "0px",
      textTransform: "capitalize",
      "&:hover": {
        background: (theme) => theme.palette.primary.main,
      },
    },
    orderNow: {
      padding: "18px 50px 18px 50px",
      borderRadius: "16px",
      color: theme.palette.text.primary,
      border: `2px solid ${theme.palette.text.border}`,
      cursor: "pointer",
      margin: "0 10px",
      marginBottom: xdDown ? "15px" : "0px",
      opacity: 1,
      textTransform: "capitalize",
    },
  };

  useEffect(() => {
    if (
      userData &&
      !(Array.isArray(userData) && userData?.length === 0) &&
      typeof userData === "object" &&
      userData?.uid
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  return (
    <Box
      sx={{
        pt: 9,
        background:
          background === "about" ? theme.palette.primary.light : "none",
      }}
    >
      <Container sx={{ maxWidth: { xs: "100%", sm: "100%", md: "1240px" } }}>
        <Grid container spacing={{ xs: 5, md: 10, lg: 16 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h3" sx={styles.headerTitle}>
              Why Truck Parts
            </Typography>
            <Typography variant="subtitle2" sx={styles.descriptionText}>
              TRUCKPARTSMART simplifies buying and selling truck parts while
              connecting users with essential trucking services. With features
              like the HOTLINE REQUEST, real-time chat, and location-based
              filters, finding the right parts and services has never been
              easier. Save time, reduce costs, and keep your trucks on the road
              with TRUCKPARTSMART!
            </Typography>
            <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
              <Button
                onClick={() => {
                  navigate(isLoggedIn ? "/selling" : "/login");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                sx={styles.startSelling}
              >
                Start Selling
              </Button>

              <Button
                onClick={() => {
                  navigate("/categories");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                sx={styles.orderNow}
              >
                Order now
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={3}>
              <InfoCard
                imageSrc={carWash}
                title="Find the Right Parts Easily"
                description="Get access to a vast marketplace of truck parts from trusted sellers near you."
              />
              <InfoCard
                imageSrc={stashQuestion}
                title="Instant HOTLINE Requests"
                description="Post your needs and let suppliers come to you with the best offers in real time."
              />
              <InfoCard
                imageSrc={time}
                title="Save Time & Money"
                description="No more long searches—compare offers instantly and get the best deals fast."
              />
              <InfoCard
                imageSrc={Group}
                title="Connect with Service Providers"
                description="Find repair shops, towing services, and maintenance experts in your area."
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WhyTruck;
