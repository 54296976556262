
import React from 'react';
import { Typography } from "@mui/material";

const Section = ({ title, summary, details, id }) => (
    <>
      <Typography variant="h6" sx={{ mb: 1, mt: 1, fontWeight: "600" }}>
        {typeof title === "string" ? title : JSON.stringify(title)}
      </Typography>
      {summary && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          {typeof summary === "string" ? summary : JSON.stringify(summary)}
        </Typography>
      )}
      {details && Array.isArray(details) ? (
        <ul>
          {details.map((item, index) => (
            <li key={`item-${index}`} style={{ marginBottom: "15px" }}>
              {typeof item === "string" ? item : JSON.stringify(item)}
            </li>
          ))}
        </ul>
      ) : (
        <Typography variant="body1" sx={{ mb: 4, mt: 4 }}>
          {typeof details === "string" ? details : JSON.stringify(details)}
        </Typography>
      )}
    </>
  );


export default Section;