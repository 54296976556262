import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Box, Stack,  Typography,  useMediaQuery } from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.darkMain,
    padding: "8px 16px",
    width: "16rem",
    border: "none", 
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: "8px",
  display: "block", // Make the row block-level to allow margins

  marginBottom: "12px", // Add spacing between rows
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.aliceBlue,
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.primary.lightAliceBlue,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));



const TableDetails = ({currentProduct}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTabletOrAbove = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const {carsList
  }=currentProduct
  const tableWidth = carsList.length <= 1 && isTabletOrAbove ? "50%" : "100%";
  const rowWidth = carsList.length > 1  && isTabletOrAbove ? "49%" : "100%";
  if (!carsList || carsList.length === 0) {
    return <Typography variant="body1">No Vehicle info</Typography>;
  }
  return (
    <TableContainer component={Box} sx={{width:tableWidth}}>
      <Stack
        direction={isMobile ? "column" : "row"}
        gap={isMobile ? "12px" : "88px"}
      >
        {isTabletOrAbove
          ? // If the screen is tablet or larger, render rows in groups of 6 beside each other
          // carsList.map((item, index) =>
          //     index % 6 === 0 ? (
          //       <Table key={index} aria-label="customized table" >
          //         <TableBody >
          //           {carsList.map((subItem, subIndex) => (
          //             <StyledTableRow key={subIndex}>
          //               <StyledTableCell>
          //                  {subItem.brand}
          //               </StyledTableCell>
          //               <StyledTableCell
          //                 sx={{
          //                   textAlign: {
          //                     sm: "center", 
          //                     lg: "center", 
          //                   },
                            
          //                 }}
          //               >
          //                 {subItem.model}
          //               </StyledTableCell>
          //               <StyledTableCell
          //                 sx={{
          //                   textAlign: {
          //                     sm: "right", 
          //                     lg: "end", 
          //                   },
                    
          //                 }}
          //               >
          //                 {subItem.year}
          //               </StyledTableCell>
          //             </StyledTableRow>
          //           ))}
          //         </TableBody>
          //       </Table>
          //     ) : null
          //   )
          <Table>
          <TableBody sx={{display:"flex", flexWrap:"wrap" , gap:"10px"}}>
                {carsList.map((subItem, subIndex) => (
                  <StyledTableRow sx={{width:rowWidth}} key={subIndex}>
                    <StyledTableCell>
                       {subItem.brand}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        textAlign: {
                          sm: "center", 
                          lg: "center", 
                        },
                        
                      }}
                    >
                      {subItem.model}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        textAlign: {
                          sm: "right", 
                          lg: "end", 
                        },
                
                      }}
                    >
                      {subItem?.year}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
          : // If the screen is mobile, render all rows in a single table
            <Table aria-label="customized table">
              <TableBody>
                {carsList.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell >
                          {item.brand}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        textAlign: {
                          sm: "center", 
                          lg: "center", 
                        },
                      }}
                    >
                      {item.model}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        textAlign: {
                          sm: "right", 
                          lg: "end", 
                        },
                      }}
                    >
                      {item.year}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>}
      </Stack>
    </TableContainer>
  );
};

export default TableDetails;

