import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import truckDriver from "../../../assets/Images/truck_driver.png";
import sms from "../../../assets/Icons/sms.png";

const Subscribe = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        height: "450px",
        background: `url(${truckDriver}) center center / cover no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        textAlign: "center",
        margin: "25px",
        borderRadius: "30px",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          // padding: theme.spacing(4),
          borderRadius: "10px",
          position: "absolute",
          right: "100px",
          padding: "40px 60px 30px",
          width: "582px",
          height: "273px",
          "@media (max-width: 600px)": {
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            width: "96%",
            height: "285px",
            padding: "20px 15px 15px",
          },
          "@media (max-width: 800px)": {
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            width: "96%",
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: theme.typography.headerFont,
            fontWeight: 400,
            lineHeight: "1.5rem",
          }}
        >
          Subscribe on our newsletter
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Poppins, sans-serif",

            textAlign: "center",

            marginTop: "16px",
          }}
        >
          Get daily news on upcoming offers from many sellers all over the
          world.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "32px",
            flexDirection: "row",
            "@media (max-width: 600px)": {
              flexDirection: "column",
              marginTop: "15px",
            },
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Email"
            sx={{
              marginRight: "8px",
              flex: 1,
              backgroundColor: theme.palette.neutral.paleGray,
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderColor: "transparent", // Remove the border
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.primary.main, // Remove the border on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary.main, // Remove the border when focused
                },
              },
              "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                height: "40px",
                padding: "0px",
              },
              "@media (max-width: 600px)": {
                marginRight: 0,
                marginBottom: theme.spacing(1.5),
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={sms}
                    alt="email icon"
                    style={{ width: 24, height: 24 }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.light,
              padding: "0px 16px",
              gap: "10px",
              borderRadius: "8px",
              width: "105px",
              height: "40px",
              textTransform: "capitalize",
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            Subscribe
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Subscribe;
